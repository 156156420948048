import { Reducer } from 'redux'
import { IRankingsStore } from '../constants/store-interfaces';
import { 
    LOAD_RANKING_COUNTRY,
    LOAD_RANKING_WORLD,
    RESET_STATE,
    SET_LOGOUT_USER_DATA,
    SET_LOGOUT_USER_DATA_WRONG,
    SET_LOGIN_USER_DATA_WRONG,
    LOAD_RANKING_MONTH
} from '../constants/action-types';

const initialState: IRankingsStore = {
    countryRankings: {
        user: null,
        world: []
    },
    worldRankings: {
        user: null,
        world: []
    },
    monthRankings: {}
}

const reducer: Reducer<IRankingsStore> = (state = initialState, action) => {
    let was = false

    switch(action.type) {
        case LOAD_RANKING_WORLD:
            state = {
                ...state,
                worldRankings: action.payload
            }

            was = true
            break
        case LOAD_RANKING_COUNTRY:
            state = {
                ...state,
                countryRankings: action.payload
            }

            was = true
            break
        case LOAD_RANKING_MONTH:
            state = {
                ...state,
                monthRankings: action.payload
            }

            was = true
            break
        case SET_LOGOUT_USER_DATA:
        case SET_LOGOUT_USER_DATA_WRONG:
        case SET_LOGIN_USER_DATA_WRONG:
        case RESET_STATE:
            state = initialState
            was = true
            break
    }

    if(action.type.endsWith("_FAILED") && was) {
        state = initialState
        //TODO show error
    }

    return state
}

export { reducer as rankingsReducer }