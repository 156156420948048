import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { IApplicationStore } from '../../../../constants/store-interfaces';
import { IUSerInfo, IRelease } from '../../../../constants/types';
import ModalContainer from '../../containers/ModalContainer';
import ModalHeader from '../../containers/ModalElements/ModalHeader';
import { i18n } from '../../../../App';
import ModalBody from '../../containers/ModalElements/ModalBody';
import { Trans } from '@lingui/macro';
import ReleasesClass from '../../../simple/classes/releasesClass';
import { updateLoginTime } from '../../../../actions/global/updateLoginTime';
import AuthTeacherWrapper from '../../containers/Authentification/AuthTeacherWrapper';
import AuthUserWrapper from '../../containers/Authentification/AuthUserWrapper';
import { FacebookIcon } from 'react-share';
import InformationBox from '../InformationBox';

interface IProps {

}

const ModalNewRelease: React.FC<IProps> = props => {
    const dispatch = useDispatch()

    const userInfo = useSelector<IApplicationStore, IUSerInfo | null>(state => state.globalStore.userInfo)

    const [showModal, setShowModal] = useState<boolean>(false)
    const [releases, setReleases] = useState<IRelease[]>([])
    

    if(userInfo !== null) {
    }

    const hideModal = () => {
        setShowModal(false)
        dispatch(updateLoginTime())
    }

    useEffect(() => {
        if(userInfo !== null) {
            let releasesToSet = new ReleasesClass(null).getReleaseAfterDate(userInfo.last_login)
            setReleases(releasesToSet)
            setShowModal((releasesToSet.length && userInfo.training_status > 0)?true:false)
        }
        
    }, [userInfo])

    return (
        <section id="mainSection">
            <ModalContainer show={(showModal && releases.length>0)} customHide={() => hideModal()}>
                <ModalHeader title={i18n._('Novinky')}/>
                
                <ModalBody>
                    <div className="widget releases">
                        <div className="widget-body">
                            <div className="_part">
                                {releases.map((r,i) => (
                                    <div className="release" key={i}>
                                        <h4><strong><Trans>Vydaná nová verzia</Trans> {r.versionNumber}</strong></h4>
                                        <h5><strong><Trans>Všeobecné novinky</Trans></strong></h5>
                                        <ul>
                                            {r.notes.map((n,j) => (
                                                <li key={j}>{n}</li>
                                            ))}
                                        </ul>
                                        <AuthTeacherWrapper>
                                            {r.notesTeacher?(
                                                <>
                                                    <h5><strong><Trans>Novinky pre učiteľov</Trans></strong></h5>
                                                    <ul>
                                                        {r.notesTeacher.map((n,j) => (
                                                            <li key={j}>{n}</li>
                                                        ))}
                                                    </ul>
                                                </>
                                            ):(null)}
                                        </AuthTeacherWrapper>
                                        <AuthUserWrapper>
                                            {r.notesUser?(
                                                <>
                                                    <h5><strong><Trans>Novinky pre žiakov</Trans></strong></h5>
                                                    <ul>
                                                        {r.notesUser.map((n,j) => (
                                                            <li key={j}>{n}</li>
                                                        ))}
                                                    </ul>
                                                </>
                                            ):(null)}
                                        </AuthUserWrapper>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="row">
                            <div className="row-part">
                                <div className="small-widgets --share">
                                    <h4>
                                        <strong>
                                            <Trans>Ak chcete byť informovaní o všetkých novinkách aplikácie Alphie, pripravovaných súťažiach a mnoho ďalších užitočných informácií, ponáhľajte sa na Facebook, a pridajte si stránku Alphie :)</Trans>
                                        </strong>
                                    </h4>
                                    <div className="share">
                                        <a 
                                            href="https://facebook.com/alphieapp"
                                            title={i18n._("Aplikácia Alphie - učenie slovíčok z ľahkosťou")}
                                            target="_blank"
                                            className="wrapper bttn bttn-white"
                                            rel="noreferrer"
                                        >
                                            <FacebookIcon size={36} />
                                            <Trans>Alphie na Facebooku</Trans>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <InformationBox showBell={true} type='warning'>
                            <Trans>Narazil si na akýkoľvek problém v aplikácii Alphie? Napíš nám na Facebook, alebo na e-mail info@alphie.app. Problém sa pokúsime opraviť čo najskôr.</Trans>
                        </InformationBox>
                    </div>
                    
                
                </ModalBody>
            </ModalContainer>
        </section>
    )
}

export default withRouter(ModalNewRelease)