import React from 'react';
import { useDispatch } from 'react-redux';
import { Trans } from '@lingui/macro';
import { ETestUgType, ICurrentTest } from '../../../../constants/types';
import TestClass from '../../../simple/classes/testClass';
import CircularGraph from '../../../simple/widgets/CircularGraph';
import { NavLink } from 'react-router-dom';
import { i18n } from '../../../../App';
import ResultList from './results/ResultList';
import {FacebookShareButton, FacebookIcon} from "react-share";
import { Redirect } from 'react-router';
import { updateCurrentTest } from '../../../../actions/global/updateCurrentTest';

interface IProps {
    currentTest: ICurrentTest
}


const TestResult: React.FC<IProps> = ({currentTest}) => {
    const dispatch = useDispatch()

    let test = currentTest.test

    let testPath = '/app/trainings/' + test.id

    if(test.usergroup_id !== null) {
        if(test.settings.ugType === ETestUgType.homework) {
            testPath = '/app/classes/' + test.usergroup_id + '/homeworks/' + test.id
        } else {
            testPath ='/app/classes/' + test.usergroup_id + '/tests/' + test.id
        }
    }
    
    if(typeof currentTest.testResult === 'undefined') {
        return (<Redirect to={testPath}/>)
    }
    
    let runningTestObject = new TestClass(currentTest.test, currentTest.testResult)

    let percentage = runningTestObject.getPercentage()

    let finishText = i18n._('Dokončili ste cvičenie')
    let successText = i18n._('Úspešnosť v cvičení')
    let detailText = i18n._('Detail cvičenia')
    let repeatText = i18n._('Zopakovať cvičenie')

    if(test.usergroup_id !== null) {
        if(test.settings.ugType === ETestUgType.homework) {
            finishText = i18n._('Dokončili ste domácu úlohu')
            successText = i18n._('Úspešnosť v domácej úlohe')
            detailText = i18n._('Detail domácej úlohy')
            repeatText = i18n._('Zopakovať domácu úlohu')
        } else {
            finishText = i18n._('Dokončili ste písomku')
            successText = i18n._('Úspešnosť v písomke')
            detailText = i18n._('Detail písomky')
            repeatText = i18n._('Zopakovať písomku')
        }
    }

    let sharePath = btoa(encodeURI(JSON.stringify({
        'p': runningTestObject.getTestRate(),
        'n': percentage,
        't': test.name,
        'i': 't'
    })))

    return (
        <div className="rows">
            <div className="row">
                <div className="row-part">
                    
                    <div id="test">
                        
                        <div className="test-content">
                        
                            <div className="wrapper">
                                
                                <div className="test-block">
                                    <h1>{ finishText }: <span>{test.name}</span></h1>
                                    <br/>
                                    
                                    <div className="rows">
                                        <div className="row">
                                            <div className="row-part">
                                                <div className="small-widgets _alt">
                                                    <ul>
                                                        <li>
                                                        
                                                            <div className="small-widget">
                                                                <div className="_part _left">
                                                                    
                                                                    <CircularGraph percentage={percentage}/>
                                                                    
                                                                </div>
                                                                <div className="_part _body">
                                                                
                                                                    <h3>{ successText }</h3>
                                                                
                                                                </div>
                                                            </div>
                                                            
                                                        </li>
                                                        <li>
                                                            
                                                            <div className="small-widget _points">
                                                                <div className="_part _left">
                                                                    
                                                                    <div className="points">
                                                                        <i className="la la-certificate"/>
                                                                    </div>
                                                                    
                                                                </div>
                                                                <div className="_part _body">
                                                                
                                                                    <h3>+ {runningTestObject.getTestRate()}</h3>
                                                                    {(runningTestObject.getTestRate() === 1)?(
                                                                        <p><Trans>bod do svetového rebríčka</Trans></p>
                                                                    ):(runningTestObject.getTestRate() < 5)?(
                                                                        <p><Trans>body do svetového rebríčka</Trans></p>    
                                                                    ):(
                                                                        <p><Trans>bodov do svetového rebríčka</Trans></p>
                                                                    )}                                                                        
                                                                </div>
                                                            </div>
                                                        
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="row-part">
                                                <div className="small-widgets --share">
                                                    <div className="share">
                                                        <FacebookShareButton 
                                                            url={'https://certificate.alphie.app/share.php?c=' + sharePath}
                                                            quote={i18n._("Aplikácia Alphie - učenie slovíčok z ľahkosťou")}
                                                            hashtag="#alphieapp"
                                                            className="wrapper bttn"
                                                        >
                                                            <FacebookIcon size={36} />
                                                            <Trans>Zdieľať výsledok s priateľmi</Trans>
                                                        </FacebookShareButton>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <ResultList
                                        test={test}
                                        testResult={currentTest.testResult}
                                    />

                                    {(test.settings.ugType !== ETestUgType.test)?(
                                        <div className="test-nav">
                                            <ul>
                                                <li className="text-center">
                                                    <NavLink to={testPath + '/detail'} className="bttn _primary _next _full">{ detailText }</NavLink>
                                                </li>
                                                <li className="text-center">
                                                    <div 
                                                        className="bttn _green _next _full"
                                                        onClick={() => {
                                                            dispatch(updateCurrentTest(null, testPath))
                                                        }}
                                                    >
                                                        { repeatText }
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    ):(
                                        <div className="test-nav">
                                            <ul>
                                                <li className="text-center">
                                                    <NavLink to={testPath + '/detail'} className="bttn _primary _next _full">{ detailText }</NavLink>
                                                </li>
                                            </ul>
                                        </div>
                                    )}
                                </div>
                                
                            </div>
                        
                        </div>
                    
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

export default TestResult