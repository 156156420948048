import { setLoadingCreatedWordGroups } from '../loadings/setLoading';
import config from '../../config';
import { handleErrors } from '../../utils/handleErrors';
import { DELETE_USERGROUP, DELETE_USERGROUP_STARTED, DELETE_USERGROUP_FAILED } from '../../constants/action-types';
import { store } from '../../store';
import { addFloatingNotification } from '../notifications/floating';
import { i18n } from '../../App';

export function deleteUserGroup(id:number, name: string) {
    return (dispatch: any) => {

        dispatch(setLoadingCreatedWordGroups(true))
        dispatch(deleteUserGroupStarted())

        return fetch(config.api.usergroups + '/' + id, {
            method: 'DELETE',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + store.getState().globalStore.token
            }            
        })
            .then(handleErrors)
            .then(res => res.json())
            .then(res => {

                dispatch(deleteUserGroupSuccess(id))
                
                dispatch(addFloatingNotification({
                    type:"warning",
                    text:i18n._('Úspešne ste odstránili triedu') + ' - ' + name,
                    showBell: true
                }))

                dispatch(setLoadingCreatedWordGroups(false))

            })
            .catch((error: any)  => {
                dispatch(deleteUserGroupFailed(error))
                dispatch(setLoadingCreatedWordGroups(false))
            });
    };
}

export const deleteUserGroupStarted = () => ({
    type: DELETE_USERGROUP_STARTED
});

export const deleteUserGroupSuccess = (id: number) => ({
    type: DELETE_USERGROUP,
    payload: id
});

export const deleteUserGroupFailed = (error: any) => ({
    type: DELETE_USERGROUP_FAILED,
    payload: { error }
});