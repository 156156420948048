import { setLoadingCreatedWords } from '../loadings/setLoading';
import config from '../../config';
import { handleErrors } from '../../utils/handleErrors';
import { 
    GET_CREATED_WORDS, 
    GET_CREATED_WORDS_STARTED, 
    GET_CREATED_WORDS_FAILED } from '../../constants/action-types';
import { store } from '../../store';

export function loadCreatedWords() {
    return (dispatch: any) => {

        dispatch(setLoadingCreatedWords(true))
        dispatch(loadCreatedWordsStarted())

        return fetch(config.api.words, {
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + store.getState().globalStore.token
            }
            
        })
            .then(handleErrors)
            .then(res => res.json())
            .then(res => {
                if((typeof res.data !== 'undefined') && Array.isArray(res.data)) {
                    dispatch(loadCreatedWordsSuccess(res.data))
                }
                
                dispatch(setLoadingCreatedWords(false))
            })
            .catch((error: any)  => {
                dispatch(loadCreatedWordsFailed(error))
                dispatch(setLoadingCreatedWords(false))
            });
    };
}

export const loadCreatedWordsStarted = () => ({
    type: GET_CREATED_WORDS_STARTED
});

export const loadCreatedWordsSuccess = (data:any) => ({
    type: GET_CREATED_WORDS,
    payload: data
});

export const loadCreatedWordsFailed = (error: any) => ({
    type: GET_CREATED_WORDS_FAILED,
    payload: { error }
});