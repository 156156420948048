import React, {useEffect } from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import { Trans } from '@lingui/macro';
import ReactGA from 'react-ga';

interface IProps {

}

const NoMatchPage: React.FC<IProps> = props => {

    useEffect(() => {
        ReactGA.pageview('404')
    }, [])
    
    return (
        <div id="e404">
            <div className="wrapper">
                <h1>404</h1>
                <p><Trans>Stránka sa nenašla</Trans></p>
                <NavLink to="/app" className="bttn _primary"><Trans>späť</Trans></NavLink>
            </div>
        </div>
    )
}

export default withRouter(NoMatchPage)