import config from '../../config';
import { handleErrors } from '../../utils/handleErrors';
import { i18n } from '../../App';
import { addFloatingNotification } from '../notifications/floating';
import { store } from '../../store';

export function checkUserName(username: string, sendId?: boolean) {
    // store.dispatch(setLoadingRegister(true))
    return fetch(config.api.checkUser, {
        method: 'post',
        credentials: 'include',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            username: username
        })
    })
        .then(handleErrors)
        .then(res => res.json())
        .then(json => {
            // store.dispatch(setLoadingRegister(false))

            if(sendId) {
                return json
            }

            return json.message
        })
        .catch((error: any)  => {
            // store.dispatch(setLoadingRegister(false))
            store.dispatch(addFloatingNotification({
                type:"warning",
                text:i18n._('Nastala neočakávaná chyba!'),
                showBell: true
            }))
        });
}