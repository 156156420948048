import React, {Component} from 'react';
import { connect } from 'react-redux';
import { IApplicationStore } from '../../../../constants/store-interfaces';
import TimelineContainer from '../../containers/TimelineContainer';
import { IUWordGroupType, ITest, ITestResult, ITimelineItem } from '../../../../constants/types';
import { i18n } from '../../../../App';
import moment from 'moment';
import { history } from '../../../../../index';

interface IStateProps {
}

interface IDispatchProps {
}

const mapStateToProps = (state: IApplicationStore): IStateProps => ({
})

const mapDispatchToProps = (dispatch: any): IDispatchProps => ({ 
})


interface IProps extends IStateProps, IDispatchProps {
    wordGroups: IUWordGroupType[],
    homeWorks: ITest[],
    tests: ITest[],
    results: ITestResult[],
    classId: number
}

interface ICompareValues {
    text: string,
    date: string,
    link?: string,
    subtitle?: string,
    button: string,
    buttonColor?: 'primary'|'green'|'orange'|'red'|'silent'
}

interface IState {
}

class RecentUserGroupActivity extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props)

        this.state = {
        }
    }

    getAllValues(classId: number, wordGroups: IUWordGroupType[], homeWorks: ITest[], tests: ITest[], results: ITestResult[]): ICompareValues[] {
        let items: ICompareValues[] = []

        //Get all wordGroups
        wordGroups.forEach(wG => {
            if((wG.valid_from !== null)&&(moment(wG.valid_from).isBefore(moment()))) {
                items.push({
                    date: wG.valid_from,
                    text: i18n._('Máte k dispozícii skupinu slovíčok na učenie') + ' - ' + wG.name,
                    link: '/app/wordgroups/assigned/' + wG.id,
                    button: i18n._('Detail'),
                    buttonColor: 'primary'
                })
            }
        })

        //Get all homeworks
        homeWorks.forEach(t => {
            let item: ICompareValues = {
                date: t.valid_from,
                text: i18n._('Dostali ste domácu úlohu na učenie') + ' - ' + t.name,
                subtitle: (t.valid_until !== null)?i18n._('Domáca úloha je platná do') + ' ' + moment(new Date(t.valid_until)).format('DD.MM.YYYY HH:mm'):undefined,
                button: i18n._('Spustiť'),
                buttonColor: 'orange'
            }

            if(
                moment(t.valid_from).isAfter(moment()) || 
                (
                    (t.valid_until !== null) &&
                    moment(t.valid_until).isAfter(moment())
                )
            ) {
                item.link =  '/app/classes/' + classId + '/homeworks/' + t.id
            }

            items.push(item)
                 
        })

        //Get all tests
        tests.forEach(t => {
            items.push({
                date: t.created_at,
                text: i18n._('Čaká Vás písomka') + ' - ' + t.name,
                subtitle: i18n._('Písomku budete môcť vyplniť od') + ' ' + moment(new Date(t.valid_from)).format('DD.MM.YYYY HH:mm'),
                button: ''
            })
        })

        items = items.sort((a, b) => moment(new Date(b.date)).valueOf() -  moment(new Date(a.date)).valueOf())

        return items
    }

    render() {
        const { wordGroups, homeWorks, tests, results, classId } = this.props

        let items = this.getAllValues(classId, wordGroups, homeWorks, tests, results)

        let timelineItems: ITimelineItem[] = []

        items.forEach(i => {
            timelineItems.push({
                date: i.date,
                content: (
                    <div className="timeline-content">
                        <p>{i.text}</p>
                        <p><small>{i.subtitle}</small></p>
                    </div>
                ),
                button: ((typeof i.link !== 'undefined') && (typeof i.button !== 'undefined'))?i.button:undefined,
                buttonAction: () => history.push({pathname:i.link}),
                buttonColor: i.buttonColor
            })
        })
        
        return (
            <TimelineContainer show={true} items={timelineItems}/>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RecentUserGroupActivity)