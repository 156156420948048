import React, {Component} from 'react';
import { connect } from 'react-redux';
import { IApplicationStore } from '../../../../../constants/store-interfaces';
import { setShowCreateTestModal } from '../../../../../actions/modals/setShowModal';
import { IStepperStepType, IWordType, ITestSettings, ETestDifficulty, ETestType, ITestCreate, IWordGroupMapping } from '../../../../../constants/types';
import { i18n } from '../../../../../App';
import ModalContainer from '../../../containers/ModalContainer';
import ModalHeader from '../../../containers/ModalElements/ModalHeader';
import ModalBody from '../../../containers/ModalElements/ModalBody';
import Stepper from '../../../containers/Stepper';
import TestWordSelection from '../modalParts/TestWordSelection';
import TestSettings from '../modalParts/TestSettings';
import TestSummary from '../modalParts/TestSummary';
import { createTest } from '../../../../../actions/tests/createTest';

interface IDispatchProps {
    setShowCreateTestModal: typeof setShowCreateTestModal,
    createTest: typeof createTest
}

interface IStateProps {
    showModal: boolean
}

const mapStateToProps = (state: IApplicationStore): IStateProps => ({
    showModal: state.modalsStore.showAddTest
})

const mapDispatchToProps = (dispatch: any): IDispatchProps => ({
    setShowCreateTestModal: (value: boolean) => dispatch(setShowCreateTestModal(value)),
    createTest: (test: ITestCreate, type: 'ug'|'self') => dispatch(createTest(test, type))
})

interface IState {
    allowPrev: boolean,
    allowNext: boolean,
    test: ITestCreate
}

interface IProps extends IDispatchProps, IStateProps {
    
}

const initState: IState = {
    allowPrev: true,
    allowNext: false,
    test: {
        valid_from: new Date().toISOString(),
        valid_until: null,
        name: '',
        usergroup_id: null,
        language_ids: {
            language_from_id: 1,
            language_to_id: 1
        },
        settings: {
            difficulty: ETestDifficulty.easy,
            type: ETestType.random,
            time: null,
            words: [],
            wordGroups: [],
            diacritic: true
        }
    }
}

class ModalAddNewTraining extends Component<IProps, IState> {

    constructor(props:any) {
        super(props)

        this.state = initState

        this.handleSaveTest = this.handleSaveTest.bind(this)
        this.handleWords = this.handleWords.bind(this)
        this.handleWordGroups = this.handleWordGroups.bind(this)
        this.handleTestSettings = this.handleTestSettings.bind(this)
        this.handleTest = this.handleTest.bind(this)
    }

    componentDidMount() {
        this.setState(initState)
    }

    componentDidUpdate(prevProps: IProps, ) {
        if(prevProps.showModal && !this.props.showModal) {
            this.setState(initState)
        }
    }

    submitFormHandler(e:  any)  {
        e.preventDefault()
    }

    handleSaveTest() {
        this.props.createTest(this.state.test, 'self')
        this.props.setShowCreateTestModal(false)
    }

    handleWordGroups(wordgroups: IWordGroupMapping[]|null){

        let newName = i18n._('Cvičenie') + ' '

        if(wordgroups !== null) {
            wordgroups.forEach(wg => {
                newName += wg.label + ', '
            })
        }

        newName = newName.slice(0, -2)

        if(wordgroups === null || (wordgroups.length === 0)) {
            this.setState({
                ...this.state,
                test: {
                    ...this.state.test,
                    settings: {
                        ...this.state.test.settings,
                        wordGroups: [],
                        words: []
                    },
                    name: newName
                },
                allowNext: false
            })
        } else {

            let wordgroupsId = wordgroups.map(i => i.object.id)

            let language_ids = {
                language_from_id: 1,
                language_to_id: 1
            }

            if(wordgroups.length) {
                language_ids.language_from_id = wordgroups[0].object.language_from.id
                language_ids.language_to_id = wordgroups[0].object.language_to.id
            }

            this.setState({
                ...this.state,
                test: {
                    ...this.state.test,
                    settings: {
                        ...this.state.test.settings,
                        wordGroups: wordgroupsId,
                        words: []
                    },
                    name: newName,
                    language_ids: language_ids
                },
                allowNext: false
            })
        }
        
    }

    handleWords(words:  IWordType[] | null){
        if(words === null) {
            this.setState({
                ...this.state,
                test: {
                    ...this.state.test,
                    settings: {
                        ...this.state.test.settings,
                        words: []
                    }
                },
                allowNext: false
            })
        } else {
            let wordsAdd: {from: string, to: string}[] = words.map(w => { return {from: w.word_text, to: w.translation}})

            this.setState({
                ...this.state,
                test: {
                    ...this.state.test,
                    settings: {
                        ...this.state.test.settings,
                        words: wordsAdd
                    }
                },
                allowNext: (words.length)?true:false
            })
        }
    }

    handleTestSettings(testSettings: ITestSettings) {
        this.setState({
            ...this.state,
            test: {
                ...this.state.test,
                settings: testSettings
            }
        })
    }

    handleTest(test: ITestCreate) {
        this.setState({
            ...this.state,
            test: test
        })
    }

    render() {
        let { showModal } = this.props
        let { allowPrev, allowNext, test } = this.state

        const steps:IStepperStepType[] = [
            {
                name: i18n._('Výber slov'),
                step: (
                    <TestWordSelection
                        handleWordGroups={this.handleWordGroups}
                        test={test}
                        handleWords={this.handleWords}
                        isTraining={true}
                    />
                )
            },
            {
                name: i18n._('Nastavenia cvičenia'),
                step: (
                    <TestSettings
                        test={test}
                        handleTest={this.handleTest}
                        isTraining={true}
                    />
                )
            },
            {
                name: i18n._('Zhrnutie'),
                step: (
                    <TestSummary
                        test={test}
                    />
                )
            }
        ]

        return (
            <section id="mainSection">
                <ModalContainer show={showModal}>
                    <ModalHeader title={i18n._('Nové cvičenie')}/>
                    
                    <ModalBody>
                    
                        <form className="form _alt" onSubmit={this.submitFormHandler}>
                            <Stepper 
                                steps={steps} 
                                finishText={i18n._('Vytvoriť cvičenie')}
                                allowPrev={allowPrev}
                                allowNext={allowNext}
                                finalSubmit={this.handleSaveTest}
                                showInModal={true}
                            />
                        </form>
                    
                    </ModalBody>
                </ModalContainer>
            </section>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalAddNewTraining)