import React, {Component } from 'react'
import { ITestResult } from '../../../constants/types';
import Chart from "react-apexcharts";
import { i18n } from '../../../App';
import { Trans } from '@lingui/macro';

interface IProps {
    results: ITestResult[]
    myId: number
}

const primaryColor = '#4D7CFE'
const activeColor = '#6DD230'

class ResultsGraphTestWidget extends Component<IProps, any> {
    
    render() {
        const { results, myId }  = this.props

        let categories: string[] = []
        let values: number[] = []
        let colors: string[] = []

        let myResult = 0

        results.forEach((r,i) => {
            let validAnswers = 0
    
            if(r.result !== null) {
                r.result.questions.forEach(q => {
                    validAnswers += (q.valid)?1:0
                })

                categories.push(i18n._('Spolužiak') + ' ' + (i+1))

                values.push(Math.round(validAnswers/r.result.questions.length*100))

                if(typeof r.user_id !== 'undefined') {
                    if(r.user_id === myId) {
                        myResult = Math.round(validAnswers/r.result.questions.length*100)
                    }
                }

                colors.push(primaryColor)

            }
        })

        values.sort((a, b) => a-b)

        let was = false

        colors.forEach((e:string, i:number) => {
            if(values[i] === myResult) {
                if(!was) {
                    colors[i] = activeColor
                    categories[i] = i18n._('Ja')
                    was = true
                }
            }
        })

        let options = {
            xaxis: {
                categories: categories
            },
            yaxis: {
                min: 0,
                max: 100,
                tickAmount: 10,
                labels: {
                    formatter: (value:number) => value.toFixed(0) +' %',
                }
            },
            chart: {
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false
                }
            },
            colors: colors,
            markers: {
                size: 4
            },
            dataLabels: {
                enabled: false
            },
            plotOptions: {
                bar: {
                    columnWidth: '50%',
                    distributed: true,
                }
            },
            legend: {
                show: false
            }
        }

        return (
            <div className="small-widget">
                <div className="_part _left">
                    <h3><Trans>Môj výsledok v porovnaní so spolužiakmi</Trans></h3>
                    <Chart
                        options={options}
                        series={[{
                            name: i18n._('Výsledok'),
                            data: values
                        }]}
                        type="bar"
                        height="300"
                    />
                </div>
            </div>
        )
    }
}

export default ResultsGraphTestWidget
