import React, {Component, PropsWithChildren} from 'react'
import { connect } from 'react-redux'
import LeftSideBar from '../global/LeftSideBar';
import Header from '../global/Header';
import Footer from '../global/Footer';
import { IApplicationStore } from '../../../constants/store-interfaces';
import { hideAllModals } from '../../../actions/modals/hideAllModals';
import ModalAddWord from '../parts/modals/word/ModalAddWord';
import ModalAddWordGroup from '../parts/modals/wordGroup/ModalAddWordGroup';
import ModalAddUserGroup from '../parts/modals/userGroup/ModalAddUserGroup';
import ModalAddNewTraining from '../parts/modals/tests/ModalAddNewTraining';
import Feedback from '../../forms/Feedback';
import ModalNewRelease from '../parts/modals/ModalNewRelease';

interface IStateProps {
}

interface IDispatchProps {
    hideModals: typeof hideAllModals
}

const mapStateToProps = (state: IApplicationStore):IStateProps => ({
})

const mapDispatchToProps = (dispatch: any):IDispatchProps => ({
    hideModals: ()  =>  dispatch(hideAllModals())
})

interface IProps extends IStateProps, IDispatchProps {

}

class AppPageContainer extends Component<PropsWithChildren<IProps>> {
    constructor(props:any){
        super(props)

        this.handleKeyPress = this.handleKeyPress.bind(this)
    }

    handleKeyPress(event:any){
        if(event.keyCode === 27) {
            //Hide overlays, hide modals
            this.props.hideModals()
        }
    }

    //Handle keypresses
    componentDidMount(){
        document.addEventListener("keydown", this.handleKeyPress, false);
    }

    componentWillUnmount(){
        document.removeEventListener("keydown", this.handleKeyPress, false);
    }

    render() {
        return (
            <div className="new-design">
            
                <div id="appWrapper" className="app-wrapper">

                    <Header/>
                    <LeftSideBar />

                    <main className="app-body">
                        <div className="app-wrapper">
                        
                            {this.props.children}
                            
                        </div>
                    </main>
                    
                    <Footer />

                    <Feedback/>

                    <ModalAddWord/>
                    <ModalAddWordGroup/>
                    <ModalAddUserGroup/>
                    <ModalAddNewTraining/>
                    <ModalNewRelease/>
                </div>
                
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppPageContainer)
