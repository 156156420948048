import React, {Component} from 'react'
import { ITest, ETestUgType } from '../../../../../constants/types';
import ConfirmModal from '../../../../simple/ConfirmModal';
import { IApplicationStore } from '../../../../../constants/store-interfaces';
import { connect } from 'react-redux';
import { Trans } from '@lingui/macro';
import { setShowDeleteTestUserGroupModal } from '../../../../../actions/modals/setShowModal';
import InformationBox from '../../InformationBox';
import { i18n } from '../../../../../App';
import TestSummary from '../modalParts/TestSummary';
import LoadingElement from '../../../../simple/LoadingElement';


interface IStateProps {
    showModal: boolean,
    loading: boolean
}

interface IDispatchProps {
    setShowDeleteTestUserGroupModal: typeof setShowDeleteTestUserGroupModal,
}

const mapStateToProps = (state: IApplicationStore): IStateProps => ({
    showModal: state.modalsStore.showDeleteTestUserGroup,
    loading: state.loadingStore.loadingCreatedTests
})

const mapDispatchToProps = (dispatch: any): IDispatchProps => ({
    setShowDeleteTestUserGroupModal: (value: boolean) =>  dispatch(setShowDeleteTestUserGroupModal(value)),
})

interface IProps extends IStateProps, IDispatchProps {
    test: ITest,
    confirmFunction: () => any
}

interface IState {
}

class ModalDeleteTestUserGroup extends Component<IProps, IState> {
    constructor(props:  any)  {
        super(props)

        this.deleteTest = this.deleteTest.bind(this)
    }

    deleteTest() {
        this.props.confirmFunction()
    }

    render() {
        const { test, showModal, loading } = this.props

        let deleteText = i18n._('Naozaj chcete odstrániť túto písomku?')
        let warningText = i18n._('Upozornenie! Po odstránení písomky nebudete mať prístup k štatistikám svojich študentov z danej písomky!')

        if((typeof test.settings.ugType !== 'undefined') && (test.settings.ugType === ETestUgType.homework)) {
            deleteText = i18n._('Naozaj chcete odstrániť túto domácu úlohu?')
            warningText = i18n._('Upozornenie! Po odstránení domácej úlohy nebudete mať prístup k štatistikám svojich študentov z danej úlohy!')
        }

        return (
            <ConfirmModal
                confirmFunction={this.deleteTest}
                customShow={showModal}
                custom={true}
                customFunctionToggle={() => this.props.setShowDeleteTestUserGroupModal(false)}
                closeOnConfirm={false}
            >
                <LoadingElement loadingValue={loading}/>
                <div className="input-row">
                    <h4 className="headline-divider">
                        {deleteText}
                    </h4>
                </div>
                
                <TestSummary test={test} short={true}/>
                    
                <InformationBox type="warning" showBell={true}>
                    {warningText}
                    <br/>
                    <strong>
                        <Trans>Tieto dáta budú nenávratne preč</Trans>
                    </strong>
                </InformationBox>
            </ConfirmModal>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalDeleteTestUserGroup)