import React, {Component} from 'react'
import { ITest } from '../../../../../constants/types';
import ConfirmModal from '../../../../simple/ConfirmModal';
import { IApplicationStore } from '../../../../../constants/store-interfaces';
import { connect } from 'react-redux';
import InformationBox from '../../InformationBox';
import TestSummary from '../modalParts/TestSummary';
import LoadingElement from '../../../../simple/LoadingElement';
import { i18n } from '../../../../../App';


interface IStateProps {
    loading: boolean
}

interface IDispatchProps {
}

const mapStateToProps = (state: IApplicationStore): IStateProps => ({
    loading: state.loadingStore.loadingCreatedTests
})

const mapDispatchToProps = (dispatch: any): IDispatchProps => ({
})

interface IProps extends IStateProps, IDispatchProps {
    test: ITest,
    confirmFunction: () => any
}

interface IState {
}

class ModalDeleteTest extends Component<IProps, IState> {
    constructor(props:  any)  {
        super(props)

        this.deleteTest = this.deleteTest.bind(this)
    }

    deleteTest() {
        this.props.confirmFunction()
    }

    render() {
        const { test, loading } = this.props
        
        let title = i18n._('Naozaj chcete odstrániť toto cvičenie?')
        let info = i18n._('Upozornenie! Po odstránení cvičenia nebudete mať prístup k svojím štatistikám z daného cvičenia!')

        return (
            <ConfirmModal
                confirmFunction={this.deleteTest}
                closeOnConfirm={false}
            >
                <LoadingElement loadingValue={loading}/>
                
                <div className="input-row">
                    <h4 className="headline-divider">
                        {title}
                    </h4>
                </div>
                <TestSummary test={test} isTraining={true}/>
                    
                <InformationBox type="warning" showBell={true}>
                    {info}
                </InformationBox>
            </ConfirmModal>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalDeleteTest)