import React, {Component} from 'react';
import { IApplicationStore } from "../../../constants/store-interfaces";
import { IUserGroupType, ITestResult, ITest, ITabs, ITabsAction, ETestUgType } from '../../../constants/types';
import { Trans } from '@lingui/macro';
import LoadingElement from '../../simple/LoadingElement';
import { i18n } from '../../../App';
import {RouteComponentProps} from "react-router";
import { loadUserGroup } from '../../../actions/userGroups/loadUserGroup';
import IconTextWidget from '../../simple/widgets/IconTextWidget';
import { connect } from 'react-redux';
import { withRouter, NavLink } from 'react-router-dom';
import PercentageSmallWidget from '../../simple/widgets/PercentageSmallWidget';
import ModalDeleteUserFromUserGroup from '../parts/modals/userGroup/ModalDeleteUserFromUserGroup';
import { loadUserGroupTests } from '../../../actions/tests/loadUserGroupTests';
import moment from 'moment';
import TestClass from '../../simple/classes/testClass';
import { loadTestResultsUserGroup } from '../../../actions/tests/loadTestResultsUserGroup';
import UserActivity from '../parts/user/UserActivity';
import TabsContainer from '../containers/TabsContainer';
import UserWords from '../parts/user/UserWords';
import UserTestsHomeworks from '../parts/user/UserTestsHomeworks';
import NoMatchPage from './NoMatchPage';
import ReactGA from 'react-ga';
import UserActivityGraph from '../parts/user/UserActivityGraph';


interface IStateProps {
    loading: boolean,
    loadingAssigned: boolean,
    loadingTestResults: boolean,
    usergroupResults: {[key: number]: ITestResult[]},
    createdUserGroups: IUserGroupType[],
    ugTests:  {[key: number]: ITest[]}
}

interface IDispatchProps {
    loadCreatedUserGroup: typeof loadUserGroup
    loadUserGroupTests: typeof loadUserGroupTests
    loadTestResultsUserGroup: typeof loadTestResultsUserGroup
}

const mapStateToProps = (state: IApplicationStore): IStateProps => ({
    loading: state.loadingStore.loadingCreatedUserGroups,
    loadingAssigned: state.loadingStore.loadingAssignedTests,
    loadingTestResults: state.loadingStore.loadingTestResults,
    usergroupResults: state.testsStore.usergroupResults,
    createdUserGroups: state.userGroupsStore.createdUserGroups,
    ugTests: state.testsStore.usergroupTests
})

const mapDispatchToProps = (dispatch: any): IDispatchProps => ({ 
    loadCreatedUserGroup: (id: number) => dispatch(loadUserGroup(id)),
    loadUserGroupTests: (id: number) => dispatch(loadUserGroupTests(id)),
    loadTestResultsUserGroup: (ugId: number) => dispatch(loadTestResultsUserGroup(ugId))
})

type PathParamsType = {
    id: string,
    user: string
}

interface IProps extends IStateProps, IDispatchProps, RouteComponentProps<PathParamsType> {

}

interface IState {
    showModalDelete: boolean
}

class UserDetailPage extends Component<IProps, IState> {

    constructor(props: any) {
        super(props)

        this.state = {
            showModalDelete: false
        }

        this.toggleModalDelete = this.toggleModalDelete.bind(this)

        ReactGA.pageview('Detail žiaka')
    }

    componentDidMount() {
        this.props.loadCreatedUserGroup(Number(this.props.match.params.id))
        this.props.loadUserGroupTests(Number(this.props.match.params.id))
        this.props.loadTestResultsUserGroup(Number(this.props.match.params.id))
    }

    toggleModalDelete() {
        this.setState({
            ...this.state,
            showModalDelete: !this.state.showModalDelete
        })
    }

    render() {
        const { loading, loadingTestResults, loadingAssigned, createdUserGroups, usergroupResults, ugTests } = this.props
        const { showModalDelete } = this.state

        const userGroupId = Number(this.props.match.params.id)
        const userId = Number(this.props.match.params.user)

        let userGroup = createdUserGroups.find((userGroup: IUserGroupType)  => userGroup.id === userGroupId)
        
        if((typeof userGroup === 'undefined') && !loading && !loadingTestResults && !loadingAssigned) {
            return (<NoMatchPage/>)
        }

        if(typeof userGroup === 'undefined')  {
            return (null)
        }

        let user = userGroup.assigned_users.find(user => user.id === userId)

        if((typeof user === 'undefined') && !loading && !loadingTestResults && !loadingAssigned) {
            return (<NoMatchPage/>)
        }

        let results: ITestResult[] = usergroupResults[userGroupId]
        let tests = ugTests[userGroupId]

        if(typeof results === 'undefined') {
            results = []
        }

        if(typeof tests === 'undefined') {
            tests = []
        }

        let userResults = results.filter(uR => uR.user_id === userId)

        userResults.sort((a, b) => moment(((b.date_finished !== null)?b.date_finished:b.date_started)).valueOf() - moment(((a.date_finished !== null)?a.date_finished:a.date_started)).valueOf())

        let userPoints = 0
        let totalPoints = 0

        userResults.forEach(r => {
            let test = tests.find(t=> t.id === r.test_id)

            if(typeof test !== 'undefined') {
                let testObject = new TestClass(test, r)

                userPoints += Number(testObject.getTestRate())
                totalPoints += testObject.getMaximumRate()
            }
        })

        let average = 0

        if(totalPoints > 0) {
            average = Math.round(Number(userPoints)/totalPoints*100)
        }

        let latestDate = '-'

        if(userResults.length) {
            if(userResults[0].date_finished !== null) {
                latestDate = moment(userResults[0].date_finished).format('DD. MM. YYYY HH:mm')
            } else {
                latestDate = moment(userResults[0].date_started).format('DD. MM. YYYY HH:mm')
            }
        }

        let tableTabs:ITabs[] = [
            {
                header: {
                    icon: 'la-history',
                    title: i18n._('Aktivita'),
                    titleTab: i18n._('Aktivita žiaka') + ' (' + userResults.length + ')'
                },
                content: (
                    <UserActivity userResults={userResults} tests={tests}/>
                )
            },
            {
                header: {
                    icon: 'la-bar-chart',
                    title: i18n._('Štatistika'),
                    titleTab: i18n._('Štatistika zo všetkých priradených slovíčok')
                },
                content: (
                    <UserWords userResults={userResults} tests={tests}/>
                )
            },
            {
                header: {
                    icon: 'la la-mortar-board',
                    title: i18n._('Domáce úlohy'),
                    titleTab: i18n._('Štatistika zo všetkých priradených domácich úloh')
                },
                content: (
                    <UserTestsHomeworks 
                        userResults={userResults}
                        user={user}
                        tests={tests}
                        type={ETestUgType.homework}
                    />
                )
            },
            {
                header: {
                    icon: 'la la-edit',
                    title: i18n._('Písomky'),
                    titleTab: i18n._('Štatistika zo všetkých priradených písomiek')
                },
                content: (
                    <UserTestsHomeworks
                        userResults={userResults}
                        user={user}
                        tests={tests}
                        type={ETestUgType.test}
                    />
                )
            }
        ]
        let tableFooterActions:ITabsAction[] = []
        
        return (
            <section id="mainSection">
                <LoadingElement loadingValue={loading || loadingTestResults || loadingAssigned} />
                {(typeof user !== 'undefined')?(
                    <div>
                        <div className="mainSectionBody">
                        
                            <div className="page-header">
                                <div className="part">
                                    <h1>{userGroup.name} - {user.name} {user.surname}</h1>
                                </div>
                                <div className="part">
                                    <NavLink to={"/app/classes/" + userGroup.id} className="bttn _small _green _icon">
                                        <i className="la la-undo"/>
                                        <Trans>Späť do triedy</Trans>
                                    </NavLink>
                                    <div className="bttn _small _red _icon" onClick={() => this.toggleModalDelete()} data-tooltip={i18n._("Odstrániť žiaka z triedy")}>
                                        <i className="la la-user-times"/>
                                    </div>
                                </div>
                            </div>
                            <div className="rows">
                                <div className="row">
                                    <div className="row-part">
                                        <div className="small-widgets">
                                            <ul>
                                                <li>
                                                    <IconTextWidget
                                                        showIcon={true}
                                                        icon="la-calendar"
                                                    >
                                                        <h2>{latestDate}</h2>
                                                        <p><Trans>posledná aktivita</Trans></p>
                                                    </IconTextWidget>
                                                </li>
                                                <li>
                                                    <IconTextWidget
                                                        showIcon={true}
                                                        icon="la-line-chart"
                                                    >
                                                        <h2>{userResults.length}</h2>
                                                        <p><Trans>počet vykonaných testov</Trans></p>
                                                    </IconTextWidget>
                                                </li>
                                                <li>
                                                    <PercentageSmallWidget
                                                        percentage={average}
                                                    >
                                                        <h3><Trans>priemerná úspešnosť testov</Trans></h3>
                                                    </PercentageSmallWidget>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="row-part">
                                        <UserActivityGraph userResults={userResults} tests={tests}/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="row-part">
                                        <TabsContainer
                                            tabs={tableTabs}
                                            footerActions={tableFooterActions}
                                        />
                                    </div>
                                </div>
                            </div>
                        
                        </div>

                        <ModalDeleteUserFromUserGroup
                            showModal={showModalDelete}
                            userName={user.f_name}
                            userId={user.id}
                            userGroupId={userGroup.id}
                            hideModalFunction={this.toggleModalDelete}
                            redirect={true}
                        />
                    </div>
                ):(null)}
            </section>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UserDetailPage))