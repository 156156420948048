import React from 'react'
import { i18n } from '../../../../App';
import { Trans } from '@lingui/macro';
import { NavLink } from 'react-router-dom';
import { IUSerInfo, IRelease } from '../../../../constants/types';
import ReleasesClass from '../../classes/releasesClass';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { IApplicationStore } from '../../../../constants/store-interfaces';
import AuthTeacherWrapper from '../../../app/containers/Authentification/AuthTeacherWrapper';
import AuthUserWrapper from '../../../app/containers/Authentification/AuthUserWrapper';

interface IProps {
    type: 'all'|'hp'
}

const ReleasesWidget: React.FC<IProps> = props => {
    const userInfo = useSelector<IApplicationStore, IUSerInfo | null>(state => state.globalStore.userInfo)

    let title = i18n._('Novinky aplikácie')

    let releasesClass = new ReleasesClass(userInfo)

    let releases: IRelease[] = releasesClass.getLastNReleasesReversed(2)

    if(props.type === 'all') {
        releases = releasesClass.getReleasesReversed()
    }

    return (
        <div className="widget-holder">
            
            {(props.type === 'hp')?(
                <div className="widget-holder-header">
                    <div className="widget-part">
                    
                        <h2>{title}</h2>
                    
                    </div>
                    <div className="widget-part">
                    
                        <NavLink to="/app/releases" target="_self"><Trans>Zobraziť viac</Trans></NavLink>
                    
                    </div>
                </div>
            ):(null)}
            
            <div className="widget-holder-body">
                <div className="widget-part">
                    {releases.map((r,i) => (
                        <section className="widget" key={i}>
                        
                            <div className="widget-body">
                                <div className="_part">
                                
                                    <h2 className="_icon"><i className="la la-bullseye"/> <Trans>Vydaná verzia </Trans> {r.versionNumber}</h2>
                                    <h5><strong><Trans>Všeobecné novinky</Trans></strong></h5>
                                    <ul>
                                        {r.notes.map((n,j) => (
                                            <li key={j}>{n}</li>
                                        ))}
                                    </ul>
                                    <AuthTeacherWrapper>
                                        {r.notesTeacher?(
                                            <>
                                                <h5><strong><Trans>Novinky pre učiteľov</Trans></strong></h5>
                                                <ul>
                                                    {r.notesTeacher.map((n,j) => (
                                                        <li key={j}>{n}</li>
                                                    ))}
                                                </ul>
                                            </>
                                        ):(null)}
                                    </AuthTeacherWrapper>
                                    <AuthUserWrapper>
                                        {r.notesUser?(
                                            <>
                                                <h5><strong><Trans>Novinky pre žiakov</Trans></strong></h5>
                                                <ul>
                                                    {r.notesUser.map((n,j) => (
                                                        <li key={j}>{n}</li>
                                                    ))}
                                                </ul>
                                            </>
                                        ):(null)}
                                    </AuthUserWrapper>
                                
                                </div>
                            </div>
                            <footer className="widget-footer">
                                <div className="_part">
                                
                                    <div className="block-link">
                                        <i className="la la-calendar"/> <Trans>Dátum vydania</Trans>: <NavLink to="/app/releases">{moment(r.date).format('DD.MM.YYYY')}</NavLink>
                                    </div>
                                
                                </div>
                            </footer>
                        
                        </section>
                    ))}
                
                </div>

            </div>
            
        </div>
    )
}

export default ReleasesWidget