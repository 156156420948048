import React, {Component} from 'react';
import { connect } from 'react-redux';
import { ITest, ITableRowType, ITableCellType, ITableHeaderCellType, IActionType, ITestResult, IUWordGroupType } from '../../../../constants/types';
import { IApplicationStore } from '../../../../constants/store-interfaces';
import { i18n } from '../../../../App';
import { Trans } from '@lingui/macro';
import TableContainer from '../../containers/TableContainer';
import Percentage from '../../../simple/tableElements/Percentage';
import TestClass from '../../../simple/classes/testClass';
import { NavLink } from 'react-router-dom';
import { setShowCreateTestWGModal } from '../../../../actions/modals/setShowModal';


interface IStateProps {
}

interface IDispatchProps {
    showModalAddTest: typeof setShowCreateTestWGModal
}

const mapStateToProps = (state: IApplicationStore): IStateProps => ({
})

const mapDispatchToProps = (dispatch: any): IDispatchProps => ({ 
    showModalAddTest: (val: boolean) => dispatch(setShowCreateTestWGModal(val))
})


interface IProps extends IStateProps, IDispatchProps {
    currentWordGroup?: IUWordGroupType,
    loading: boolean,
    tests: ITest[],
    results: ITestResult[]
}

interface IState {
}

class AssignedWordGroupTrainings extends Component<IProps, IState> {
    
    getRows(tests:  ITest[], results: ITestResult[]): ITableRowType[] {
        let rows: ITableRowType[] = []

        for(let test  of tests)  {
            let cells: ITableCellType[] = []

            let percentage:number|null = null
            let percentageContent = (null)

            //Find percentage
            let testResults = results.filter(tR => tR.test_id === test.id)

            if(testResults.length) {
                let cntPoints = 0
                let maxPoints = 0

                testResults.forEach(tR => {
                    let testObject = new TestClass(test, tR)
                    
                    cntPoints += testObject.getTestRate()
                    maxPoints += testObject.getMaximumRate()
                })

                percentage = Math.round(Number(cntPoints)/maxPoints*100)
            }

            if(percentage !== null) {
                percentageContent = (<Percentage percentage={percentage} title={i18n._('Moja úspešnosť')}/>)
            }
            
            cells = [
                {
                    content: (<div className="part-title">{test.name}</div>),
                    stringToOrder:  test.name,
                    filterString:  test.name,
                    filterByString:  true
                },
                {
                    content: percentageContent,
                    stringToOrder: test.created_at,
                    filterString: test.created_at,
                    filterByString: false
                },
                {
                    content: (
                        <div className="part-list">
                            <NavLink to={"/app/trainings/" + test.id} target="_self" className="bttn _small _green _icon">
                                <Trans>Spustiť</Trans>
                            </NavLink>
                            <NavLink to={"/app/trainings/" + test.id + "/detail"} target="_self" className="bttn _small _primary _icon" data-tooltip={i18n._("Detail domácej úlohy")}>
                                <i className="la la-search-plus"/>
                            </NavLink>
                        </div>
                    ),
                    stringToOrder: test.updated_at,
                    filterString: test.updated_at,
                    filterByString: false
                }
            ]

            rows.push({
                cells: cells,
                catchString: '_' +  test.id
            })
        }

        return rows
    }

    getHeaders(): ITableHeaderCellType[] {
        return [
            {
                content: (<span><Trans>Názov</Trans></span>),
                sortBy: false,
                sortable: true
            },
            {
                content: (<span><Trans>Úspešnosť</Trans></span>),
                sortBy: false,
                sortable: true,
                sortableType: 'number'
            },
            {
                content: (<span></span>),
                sortBy: false,
                sortable: false
            },
        ]
    }

    render() {
        const { loading, tests, results } = this.props
        
        let headers: ITableHeaderCellType[] = this.getHeaders()
        let rows: ITableRowType[] = this.getRows(tests, results)
        let actions: IActionType[] = []
        
        let emptyText = i18n._('Zatiaľ nemáte evidované žiadne cvičenia z tejto skupiny slovíčok. Kliknutím na vytvoriť nové cvičenie si vytvoríte vlastné cvičenia kde si môžete precvičovať slovíčka.')

        return (
            <div>
                <div className="rows">
                    <div className="row">
                        <div className="row-part">
                            <TableContainer
                                headers={headers}
                                rows={rows}
                                actions={actions}
                                showActions={false}
                                showCheckbox={false}
                                showFilter={true}
                                pageSize={12}
                                grid={false}
                                showGrid={false}
                                title={i18n._('Cvičenia')}
                                showNew={true}
                                newTitle={i18n._('Vytvoriť nové cvičenie')}
                                newFunction={() => this.props.showModalAddTest(true)}
                                emptyText={emptyText}
                                loading={loading}
                                defaultSort='asc'
                                mobileFixedGrid={true}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AssignedWordGroupTrainings)