import React, {Component} from 'react'
import { IWordgroupType } from '../../../../../constants/types';
import ConfirmModal from '../../../../simple/ConfirmModal';
import { IApplicationStore } from '../../../../../constants/store-interfaces';
import { connect } from 'react-redux';
import { Trans } from '@lingui/macro';
import { setShowConfirmModal } from '../../../../../actions/modals/setShowModal';
import TranslateLanguages from '../../../../simple/TranslateLanguages';
import InformationBox from '../../InformationBox';


interface IStateProps {
}

interface IDispatchProps {
    setShowConfirmModal: typeof setShowConfirmModal,
}

const mapStateToProps = (state: IApplicationStore): IStateProps => ({
})

const mapDispatchToProps = (dispatch: any): IDispatchProps => ({
    setShowConfirmModal: (value: boolean) =>  dispatch(setShowConfirmModal(value)),
})

interface IProps extends IStateProps, IDispatchProps {
    wordGroups: IWordgroupType[],
    confirmFunction: () => any
}

interface IState {
}

class ModalDeleteWordGroups extends Component<IProps, IState> {
    constructor(props:  any)  {
        super(props)

        this.deleteWordGroups = this.deleteWordGroups.bind(this)
    }

    deleteWordGroups() {
        this.props.confirmFunction()
        this.props.setShowConfirmModal(false)
    }

    render() {
        const { wordGroups } = this.props
        
        return (
            <ConfirmModal
                confirmFunction={this.deleteWordGroups}
            >
                <div className="input-row">
                    <h4 className="headline-divider">
                        {(wordGroups.length === 1)?(
                            <Trans>Naozaj chcete odstrániť túto skupinu slovíčok?</Trans>
                        ):(
                            <Trans>Naozaj chcete odstrániť tieto skupiny slovíčok?</Trans>
                        )}
                    </h4>
                </div>
                {wordGroups.map((wordGroup:IWordgroupType, index: number) => (
                    <div className="input-row" key={index}>
                        <div className="lang margin-center">
                            <div className="part">
                                <TranslateLanguages
                                    languageFrom={wordGroup.language_from}
                                    languageTo={wordGroup.language_to}
                                />
                            </div>
                            <div className="part">
                                {wordGroup.name}
                            </div>
                        </div>
                    </div>
                ))}  
                    
                <InformationBox type="warning" showBell={true}>
                    <Trans>Upozornenie! Pri odstránení skupín slovíčok sa Vám odstránia všetky slovíčka, ktoré sa v nich nachádzajú!</Trans>
                </InformationBox>
            </ConfirmModal>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalDeleteWordGroups)