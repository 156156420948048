import React, { Component } from 'react';

interface IProps {
    title: string
}

class ModalHeader extends Component<IProps>{
    render() {
        return (
            <div className="modal-window-header">
                <div className="part">
                    <h3>{this.props.title}</h3>
                </div>
            </div>
        )
    }
}

export default ModalHeader