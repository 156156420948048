import React, {Component} from 'react';
import { connect } from 'react-redux';
import { Trans } from '@lingui/macro';
import { IApplicationStore } from '../../../../../constants/store-interfaces';
import { IQuestionVariantFilling } from '../../../../../constants/types';
import posed from 'react-pose';

interface IStateProps {

}

const mapStateToProps = (state: IApplicationStore) => ({
})

interface IDispatchProps {

}

const mapDispatchToProps = (dispatch: any) => ({
})

interface IProps extends IStateProps, IDispatchProps{
    question: IQuestionVariantFilling,
    response: (answer: string) => any,
    questionId: number,
    disabled: boolean
}

interface IState {
    filledValues: {value: string, fillable: boolean}[],
    refs: React.RefObject<HTMLInputElement>[]
    answer: string
}

const Item = posed.div({
    enter: { y: 0, opacity: 1},
    exit: { y: 50, opacity: 0 }
})

const ItemTitle = posed.div({
    enter: { opacity: 1},
    exit: { opacity: 0 }
})

class FillingQuestion extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props)

        this.state = {
            filledValues: [],
            refs: [],
            answer: ''
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleKeyPress = this.handleKeyPress.bind(this)
        this.updateQuestion = this.updateQuestion.bind(this)
    }

    componentDidUpdate(prevProps: IProps) {
        if(prevProps.questionId !== this.props.questionId) {
            this.updateQuestion(true)
        }
    }

    componentDidMount() {
        this.updateQuestion(false)
    }

    updateQuestion(setState: boolean) {
        let answer = (this.props.question.wType === 'from')?this.props.question.word.to:this.props.question.word.from
        let filledValues: {value: string, fillable: boolean}[] = []
        let refsValues: React.RefObject<HTMLInputElement>[] = []
        let values = Array.from(answer.replace(/ /g,''))
        
        for(let i = 0; i < values.length; i++) {
            if(!this.props.question.prefilled.includes(i)) {
                let character = this.props.question.response.charAt(i)
                filledValues.push({
                    value: character.replace(/ /g, ''),
                    fillable: true
                })
            } else {
                filledValues.push({
                    value: values[i],
                    fillable: false
                })
            }

            refsValues.push(React.createRef())
        }

        this.setState({
            ...this.state,
            filledValues: filledValues,
            refs: refsValues,
            answer: answer
        })

        let firstAutofocus = filledValues.length-1

        for(let i = 0; i < filledValues.length; i++) {
            if(filledValues[i].fillable && (filledValues[i].value === '')) {
                firstAutofocus = i
                break
            }
        }

        setTimeout(() => {
            let element = document.getElementById(this.props.questionId + '_' + firstAutofocus)

            if(element !== null) {
                element.focus()
            }
        },200)
    }

    handleKeyPress = (name:string) => (event: React.KeyboardEvent<HTMLInputElement>) => {
        if(event.key === 'Backspace') {
            let values = this.state.filledValues

            for(let i = 0; i<values.length; i++) {
                if(values[i].fillable && (values[i].value === '')) {
                    if(document.activeElement?.id === this.props.questionId + '_' + i) {
                        let focusable = 0

                        for(let j = Math.max(0,i-1); j >= 0; j--) {
                            if(values[j].fillable) {
                                focusable = j
                                break
                            }
                        }

                        this.state.refs[focusable].current?.focus()
                    }
                }
            }
        }
    }

    handleChange = (name:string) => (event: React.FormEvent<HTMLInputElement>) => {
        let index = Number(name.substr(6))
        let value = event.currentTarget.value
        let values = this.state.filledValues
        
        values[index].value = value

        this.setState({
            ...this.state,
            filledValues: values
        })

        for(let i = 0; i<values.length;i++) {
            if(values[i].fillable && (values[i].value === '')) {
                this.state.refs[i].current?.focus()
                break
            }
        }

        let response = ''

        values.forEach(e => {
            if(e.value === '') {
                response += ' '
            } else {
                response += e.value
            }
        })

        this.props.response(response)
    }

    handleChangeWhole = (event: React.FormEvent<HTMLInputElement>) => {
        let value = event.currentTarget.value
        
        this.props.response(value)
    }

    render() {
        const { question, questionId, disabled } = this.props
        const { filledValues, refs } = this.state

        let translation = (question.wType === 'from')?question.word.to:question.word.from

        let realI = 0

        return (
            <>
                <ItemTitle>
                    <h1><Trans>Preložte slovo</Trans> <span>{(question.wType === 'from')?question.word.from:question.word.to}</span> ?</h1>
                </ItemTitle>
                <Item>
                    <div className="test-answer">
                        {(question.prefilled.length === 0)?(
                            <div className="simple-input">
                                <input 
                                    id="filled_input" 
                                    type="text" 
                                    onChange={this.handleChangeWhole} 
                                    autoFocus={true}
                                    disabled={(disabled)?true:false} 
                                    autoComplete="off"
                                    autoCapitalize="off" 
                                    autoCorrect="off"
                                />
                            </div>
                        ):(
                            <div className="multi-input">
                                <ul>
                                    {Array.from(translation).map((w:string,i:number) => {
                                        if(/\s/.test(w)) {
                                            return (
                                                <li key={i}>&nbsp;&nbsp;</li>
                                            )
                                        } else {
                                            return (
                                                <li key={i}>
                                                    <input 
                                                        id={questionId + '_' + realI} 
                                                        type="text" 
                                                        ref={refs[realI]}
                                                        value={(typeof filledValues[realI] !== 'undefined')?filledValues[realI].value.replace(/ /g, ''):''} 
                                                        disabled={((typeof filledValues[realI] !== 'undefined') && filledValues[realI].fillable)?(disabled)?true:false:true} 
                                                        maxLength={1} 
                                                        // autoFocus={(realI===firstAutofocus)?true:false} 
                                                        onChange={this.handleChange("input_" + realI)}
                                                        onKeyDown={this.handleKeyPress("input_" + realI++)}
                                                        autoComplete="off"
                                                        autoCapitalize="off" 
                                                        autoCorrect="off"
                                                    />
                                                </li>
                                            )
                                        }
                                    })}
                                </ul>
                            </div>
                        )}
                    </div>
                </Item>
            </>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FillingQuestion)