import React, { useRef } from 'react';
import Swiper from 'react-id-swiper';
import { IoIosArrowRoundBack, IoIosArrowRoundForward } from "react-icons/io";
 
interface IProps {
    params: any,
    mainTitle?: string,
    mainTitleComponent?: JSX.Element,
    showPagination?: boolean
}

const SwiperContainer: React.FC<IProps> = props => { 
    const ref = useRef<any>(null);
    
    const goNext = () => {
        let current = ref.current

        if (current !== null && current.swiper !== null) {
            current.swiper.slideNext();
        }
    };
    
    const goPrev = () => {
        let current = ref.current

        if (current !== null && current.swiper !== null) {
            current.swiper.slidePrev();
        }
    };
        
    let params = {
        ...props.params
    }

    if(typeof props.showPagination !== 'undefined' && props.showPagination) {
        params = {
            ...params,
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true
            }
        }
    }

    let allowSlidePrev = false
    let allowSlideNext = false

    return (
        <div className="widget-holder">
            {(typeof props.mainTitle !== 'undefined' || typeof props.mainTitleComponent !== 'undefined')?(      
                <div className="widget-holder-header">
                    <div className="widget-part">
                    
                        {(typeof props.mainTitle !== 'undefined')?(
                            <h2>{props.mainTitle}</h2>
                        ):(
                            props.mainTitleComponent
                        )}
                    
                    </div>
                    <div className="widget-part">
                        <div className="arrows">
                            <button onClick={goPrev} className={("arrow " + ((allowSlidePrev)?'_active':'_hidden'))}><IoIosArrowRoundBack className="icon"/></button>
                            <button onClick={goNext} className={("arrow " + ((allowSlideNext)?'_active':'_hidden'))}><IoIosArrowRoundForward className="icon"/></button>
                        </div>
                    </div>
                    {/* <div className="widget-part">
                    
                        <a href="/" target="_self">all results</a>
                    
                    </div> */}
                </div>
            ):(null)}
            <div className="widget-holder-body">
                <Swiper ref={ref} {...params} shouldSwiperUpdate>
                    {props.children}
                </Swiper>
                {((typeof props.mainTitle === 'undefined') && (typeof props.mainTitleComponent === 'undefined'))?(
                    <div className="widget-part -arrows-after">
                        <div className="arrows">
                            <button onClick={goPrev} className={("arrow " + ((allowSlidePrev)?'_active':'_hidden'))}><IoIosArrowRoundBack className="icon"/></button>
                            <button onClick={goNext} className={("arrow " + ((allowSlideNext)?'_active':'_hidden'))}><IoIosArrowRoundForward className="icon"/></button>
                        </div>
                    </div>
                ):(null)}
            </div>
            
        </div>
    );
};
 
export default SwiperContainer;