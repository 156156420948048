import React,{Component, PropsWithChildren} from 'react';
import { connect } from 'react-redux';
import { IApplicationStore } from '../../../../constants/store-interfaces';

interface IStateProps {
}

interface IDispatchProps {
}

const mapStateToProps = (state: IApplicationStore): IStateProps => ({
})

const mapDispatchToProps = (dispatch: any): IDispatchProps => ({
})

interface IProps extends IStateProps, IDispatchProps{
    title?: string,
    iconName?: string,
    className?: string
}


class SummaryBlock extends Component<PropsWithChildren<IProps>> {
    render() {
        const { title, iconName, children, className } = this.props

        return (
            <div className={"summary-block " + ((typeof className !== 'undefined')?className:'')}>
                {(typeof title !== 'undefined')?(
                    <h4>
                        {(typeof iconName !== 'undefined')?(
                            <i className={"la la-" + iconName}>
                            </i>
                        ):(null)}
                        {title}
                    </h4>
                ):(null)}
                <div className="summary-content">
                    { children }
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SummaryBlock)