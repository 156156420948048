import config from '../../config';
import { handleErrors } from '../../utils/handleErrors';
import { store } from '../../store';
import { setLoadingAssignedWordGroups } from '../loadings/setLoading';
import { GET_ASSIGNED_USERGROUP_WORDGROUPS_STARTED, GET_ASSIGNED_USERGROUP_WORDGROUPS, GET_ASSIGNED_USERGROUP_WORDGROUPS_FAILED } from '../../constants/action-types';

export function loadAssignedWordGroups(userGroupId:number) {

    return (dispatch: any) => {

        dispatch(setLoadingAssignedWordGroups(true))
        dispatch(loadAssignedWordGroupsStarted())

        fetch(config.api.usergroups + '/' + userGroupId + '/wordgroups', {
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + store.getState().globalStore.token
            }
            
        })
            .then(handleErrors)
            .then(res => res.json())
            .then(res => {
                dispatch(loadAssignedWordGroupsSuccess({userGroupId: userGroupId, userGroups: res.data}))

                dispatch(setLoadingAssignedWordGroups(false))
            })
            .catch((error: any)  => {
                dispatch(loadAssignedWordGroupsFailed(error))
                dispatch(setLoadingAssignedWordGroups(false))
            });
    };
}

export const loadAssignedWordGroupsStarted = () => ({
type: GET_ASSIGNED_USERGROUP_WORDGROUPS_STARTED
});

export const loadAssignedWordGroupsSuccess = (data:any) => ({
type: GET_ASSIGNED_USERGROUP_WORDGROUPS,
payload: data
});

export const loadAssignedWordGroupsFailed = (error: any) => ({
type: GET_ASSIGNED_USERGROUP_WORDGROUPS_FAILED,
payload: { error }
});