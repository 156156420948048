import React, {Component, PropsWithChildren } from 'react'
import CircularGraph from './CircularGraph';

interface IProps {
    percentage: number,
}

class PercentageSmallWidget extends Component<PropsWithChildren<IProps>, any> {

    render() {
        const { percentage, children }  = this.props

        return (
            <div className="small-widget _keepLeft">
                <div className="_part _left">
                    
                    <CircularGraph percentage={percentage} disableClasses={true}/>
                    
                </div>
                <div className="_part _body">
                
                    {children}
                
                </div>
            </div>
        )
    }
}

export default PercentageSmallWidget
