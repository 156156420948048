import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Trans } from '@lingui/macro';
import ReleasesWidget from '../../simple/widgets/dashboard/ReleasesWIdget';
import ReactGA from 'react-ga';

interface IProps {

}

const ReleasesPage: React.FC<IProps> = props => {    
    useEffect(() => {
        ReactGA.pageview('Zoznam verzií')
    }, [])
    

    return (
        <div className="rows">
            <div className="row">
                <div className="row-part">
                    <div className="page-header">
                        <div className="part">
                            <h2><Trans>Zoznam verzií aplikácie Alphie</Trans></h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="row-part">
                    <ReleasesWidget type={'all'} />
                </div>
            </div>
        </div>
    )
}

export default withRouter(ReleasesPage)