import { createStore, applyMiddleware, combineReducers } from 'redux'
import promise from 'redux-promise-middleware'
import thunk from 'redux-thunk';
import { modalReducer } from '../reducers/modals';
import { globalReducer } from '../reducers/global';
import { loadingReducer } from '../reducers/loading';
import { languageReducer } from '../reducers/languages';
import { wordgroupsReducer } from '../reducers/wordgroups';
import { wordsReducer } from '../reducers/words';
import { userGroupReducer } from '../reducers/userGroups';
import { notificationsReducer } from '../reducers/notifications';
import { testsReducer } from '../reducers/tests';
import { persistStore, persistReducer } from 'redux-persist'
import { rankingsReducer } from '../reducers/rankings';
import localforage from 'localforage';

const globalPersistConfig = {
    key: 'globalStore',
    storage: localforage,
    blacklist: ['showNotificationMenu', 'showAddMenu', 'showUserMenu'],
}

const languagePersistConfig = {
    key: 'languageStore',
    storage: localforage,
    blacklist: []
}

const wordgroupsPersistConfig = {
    key: 'wordGroupsStore',
    storage: localforage,
    blacklist: []
}

const wordsPersistConfig = {
    key: 'wordsStore',
    storage: localforage,
    blacklist: []
}

const userGroupPersistConfig = {
    key: 'userGroupsStore',
    storage: localforage,
    blacklist: []
}

const notificationsPersistConfig = {
    key: 'notificationsStore',
    storage: localforage,
    blacklist: ['floatingNotificationId', 'floatingNotifications']
}

const testsPersistConfig = {
    key: 'testsStore',
    storage: localforage,
    blacklist: []
}

const rakingPersistConfig = {
    key: 'rankingsStore',
    storage: localforage,
    blacklist: []
}

const rootReducer = combineReducers({
    globalStore: persistReducer(globalPersistConfig, globalReducer),
    modalsStore: modalReducer,
    loadingStore: loadingReducer,
    languageStore: persistReducer(languagePersistConfig, languageReducer),
    wordGroupsStore: persistReducer(wordgroupsPersistConfig, wordgroupsReducer),
    wordsStore: persistReducer(wordsPersistConfig, wordsReducer),
    userGroupsStore: persistReducer(userGroupPersistConfig, userGroupReducer),
    notificationsStore: persistReducer(notificationsPersistConfig, notificationsReducer),
    testsStore: persistReducer(testsPersistConfig, testsReducer),
    rankingStore: persistReducer(rakingPersistConfig, rankingsReducer)
})

let store = createStore(rootReducer, applyMiddleware(promise, thunk))


let persistor = persistStore(store)

export {
    store,
    persistor
}