import React, {Component} from 'react';
import { connect } from 'react-redux';
import ReactCountryFlag from 'react-country-flag';
import { IApplicationStore } from '../../../../constants/store-interfaces';
import { ILanguageType } from '../../../../constants/types';
import CircularGraph from '../../../simple/widgets/CircularGraph';

const mapStateToProps = (state: IApplicationStore) => ({
})

const mapDispatchToProps = (dispatch: any) => ({
})

interface IProps {
    word: string,
    translation: string,
    languageFrom: ILanguageType,
    languageTo: ILanguageType,
    showResult?: boolean,
    result?: number
}

interface IState {
}

class SummaryWordFromTo extends Component<IProps, IState> {

    render() {
        const { word, translation, languageFrom, languageTo, showResult, result } = this.props
        
        return (
            <div className={("langs-block" + ((typeof showResult !== 'undefined' && showResult)?' _result':''))}>
                <div className="langs-part">
                    <div className="lang">
                        <div className="part">
                            <ReactCountryFlag className="flag" countryCode={languageFrom.flag_name} svg/>
                        </div>
                        <div className="part">
                            {word}
                        </div>
                    </div>
                </div>
                <div className="langs-part">
                    <div className="lang">
                        <div className="part">
                            <ReactCountryFlag className="flag" countryCode={languageTo.flag_name} svg/>
                        </div>
                        <div className="part">
                            {translation}
                        </div>
                    </div>
                </div>
                {(typeof showResult !== 'undefined' && showResult && typeof result !== 'undefined')?(
                    <div className="result">
                        <CircularGraph percentage={result} big={false}/>
                    </div>
                ):(null)}
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SummaryWordFromTo)