import React from 'react'
import { Switch, Route, useLocation } from 'react-router';

import MainApp from './MainApp';
// import NewDesign from './pages/html/NewDesign';
import FloatingNotificationsContainer from './containers/FloatingNotificationsContainer';
// import Icons from './pages/html/Icons';
import MainHome from './MainHome';
// import Icons from './pages/html/Icons';

interface IProps {

}

const Main: React.FC<IProps> = props => {
    const location = useLocation()

    return (
        <div className="alphie-content-wrapper">
            <Switch location={location}>
                <Route path='/app' component={MainApp}/>
 
                {/* <Route exact path='/new-design' component={NewDesign}/> */}
                {/* <Route exact path='/icons' component={Icons}/> */}

                <Route path='/' component={MainHome}/>
            </Switch>
            <FloatingNotificationsContainer/>

        </div>
    )
}

export default Main
