import { INotification, INotificationFull } from '../../constants/types';
import { 
    ADD_FLOATING_NOTIFICATION,
    REMOVE_FLOATING_NOTIFICATION,
    UPDATE_NOTIFICATIONS
} from '../../constants/action-types';


export function addFloatingNotification(payload: INotification) {
    return { type: ADD_FLOATING_NOTIFICATION, payload }
}

export  function updateNotifications(notifications: INotificationFull[]) {

    let newNotifications = new Array<INotificationFull>()

    notifications.map((value: INotificationFull, index: number) => {
        let newDate = new Date()

        let diff = Number(newDate.getTime() - new Date(value.validFrom).getTime())
        
        if(diff < 4500) {
            value.goingToShow = true
        } else if (diff > 4500) {
            value.goingToHide = true
        }

        if(diff < 5200) { //More then 5.2s
            newNotifications.push(value)
        }

        return null
    })

    return { type: UPDATE_NOTIFICATIONS, payload: newNotifications }
}

export function deleteFloatingNotification(payload: number) {
    return { type: REMOVE_FLOATING_NOTIFICATION, payload }
}
