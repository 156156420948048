import config from '../../config';
import { handleErrors } from '../../utils/handleErrors';
import { i18n } from '../../App';
import { addFloatingNotification } from '../notifications/floating';
import { store } from '../../store';

export function getNotifications() {
    return fetch(config.api.notifications, {
        method: 'get',
        credentials: 'include',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + store.getState().globalStore.token
        },
    })
        .then(handleErrors)
        .then(res => res.json())
        .then(json => {
            if(json.status === 'success' && (typeof json.data !== 'undefined')) {
                return json.data
            } else {
                return []
            }

        })
        .catch((error: any)  => {
            store.dispatch(addFloatingNotification({
                type:"warning",
                text:i18n._('Nepodarilo sa načítať notifikácie!'),
                showBell: true
            }))
        });
}