import React, {Component} from 'react';
import { connect } from 'react-redux';
import { IApplicationStore } from "../../../constants/store-interfaces";
import RegisterForm from '../../forms/RegisterForm';
import { Trans } from '@lingui/macro';
import { NavLink } from 'react-router-dom';
import { Redirect, withRouter, RouteComponentProps } from 'react-router';
import { IUSerInfo } from '../../../constants/types';
import ReactGA from 'react-ga';
import Logo from '../global/Logo';


interface IStateProps {
    userInfo: IUSerInfo | null,
    userLoggedIn: boolean
}
interface IProps extends IStateProps, RouteComponentProps {
}

const mapStateToProps = (state: IApplicationStore): IStateProps => ({
    userInfo: state.globalStore.userInfo,
    userLoggedIn: state.globalStore.userLoggedIn
})

const mapDispatchToProps = (dispatch: any) => ({
})

class RegisterPage extends Component<IProps> {
    constructor(props: IProps) {
        super(props)

        ReactGA.pageview('Registrácia')
    }
    render() {
        const { userLoggedIn } = this.props

        let redirect = null
        if(userLoggedIn) {
            redirect = (<Redirect
                to={{
                    pathname:"/app"
                }}
            />)
        }
    
        return (
            <div className="inner _radius">
                <div className="login-head">
                    <div className="login-logo">
                        <Logo/>
                    </div>
                    {redirect}
                    <h1 className="text-center"><Trans>Registrácia</Trans></h1>
                    <p><Trans>Stačí vyplniť pár údajov a okamžite získaš prístup k celej aplikácii </Trans> <NavLink to="/" className="link">Alphie</NavLink>!</p>

                </div>
                <RegisterForm/>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RegisterPage))
