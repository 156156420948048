import React, {Component} from 'react';
import { connect } from 'react-redux';
import { IUserGroupType, ITableRowType, ITableCellType, ITableHeaderCellType, IActionType } from '../../../../constants/types';
import { loadCreatedUserGroups } from '../../../../actions/userGroups/loadCreatedUserGroups';
import { setShowConfirmModal, setShowCreateUserGroupModal, setShowEditUserGroupModal } from '../../../../actions/modals/setShowModal';
import { deleteUserGroup } from '../../../../actions/userGroups/deleteUserGroup';
import { IApplicationStore } from '../../../../constants/store-interfaces';
import UserList from '../../../simple/tableElements/UserList';
import NumberText from '../../../simple/tableElements/NumberText';
import { NavLink } from 'react-router-dom';
import { Trans } from '@lingui/macro';
import { i18n } from '../../../../App';
import TableContainer from '../../containers/TableContainer';
import ModalDeleteUserGroup from '../modals/userGroup/ModalDeleteUserGroup';
import ModalEditUserGroup from '../modals/userGroup/ModalEditUserGroup';

interface IStateProps {
    createdUserGroups: IUserGroupType[]
    loadingCreated: boolean
}

interface IDispatchProps {
    loadCreatedUserGroups: typeof loadCreatedUserGroups
    setShowDeleteUserGroupModal: typeof setShowConfirmModal,
    setShowCreateUserGroupModal: typeof setShowCreateUserGroupModal,
    setShowEditUserGroupModal: typeof setShowEditUserGroupModal
    deleteUserGroup: typeof deleteUserGroup
}

const mapStateToProps = (state: IApplicationStore): IStateProps => ({
    createdUserGroups: state.userGroupsStore.createdUserGroups,
    loadingCreated: state.loadingStore.loadingCreatedUserGroups
})

const mapDispatchToProps = (dispatch: any): IDispatchProps => ({
    loadCreatedUserGroups: () => dispatch(loadCreatedUserGroups()),
    setShowDeleteUserGroupModal: (value: boolean) => dispatch(setShowConfirmModal(value)),
    setShowCreateUserGroupModal: (value: boolean) => dispatch(setShowCreateUserGroupModal(value)),
    setShowEditUserGroupModal: (value: boolean) => dispatch(setShowEditUserGroupModal(value)),
    deleteUserGroup: (id: number, name: string) => dispatch(deleteUserGroup(id, name))
})

interface IProps extends IStateProps, IDispatchProps {

}

interface IState {
    checkedUserGroups: IUserGroupType[],
    selectedEditUserGroup: IUserGroupType | null,
    selectedDeleteUserGroup: IUserGroupType | null
}

class CreatedUserGroupTable extends Component<IProps, IState> {
    constructor(props: any) {
        super(props) 

        this.state = {
            checkedUserGroups: [],
            selectedEditUserGroup: null,
            selectedDeleteUserGroup: null
        }

        this.handleCreateUserGroup = this.handleCreateUserGroup.bind(this)
        this.handleEditUserGroup = this.handleEditUserGroup.bind(this)
        this.handleDeleteUserGroup = this.handleDeleteUserGroup.bind(this)
        this.confirmDeleteUserGroup = this.confirmDeleteUserGroup.bind(this)
    }

    componentDidMount() {
        this.props.loadCreatedUserGroups()
    }

    handleCreateUserGroup() {
        this.props.setShowCreateUserGroupModal(true)
    }

    handleEditUserGroup(catchString: string) {
        const { createdUserGroups } = this.props

        let userGroupId = Number(catchString.split('_')[1])

        let userGroupObject = createdUserGroups.find((item:IUserGroupType) => item.id === userGroupId)

        if(typeof userGroupObject !== 'undefined') {
            this.setState({
                ...this.state,
                selectedEditUserGroup: userGroupObject
            })  
            
            this.props.setShowEditUserGroupModal(true)
        }
    }

    handleDeleteUserGroup(catchString: string)  {

        const { createdUserGroups } = this.props

        let userGroupId = Number(catchString.split('_')[1])

        let userGroupObject = createdUserGroups.find((item:IUserGroupType) => item.id === userGroupId)

        if(typeof userGroupObject !== 'undefined') {
            this.setState({
                ...this.state,
                selectedDeleteUserGroup: userGroupObject
            })  
            
            this.props.setShowDeleteUserGroupModal(true)
        }
    }

    confirmDeleteUserGroup() {
        const { selectedDeleteUserGroup } = this.state

        if(selectedDeleteUserGroup !== null) {
            this.props.deleteUserGroup(selectedDeleteUserGroup.id, selectedDeleteUserGroup.name)
        }
    
    }

    getRows(userGroups:  IUserGroupType[]): ITableRowType[] {
        let rows: ITableRowType[] = []

        for(let userGroup  of userGroups)  {
            let cells: ITableCellType[] = []

            cells = [
                {
                    content: (<div className="part-title">{userGroup.name}</div>),
                    stringToOrder:  userGroup.name,
                    filterString:  userGroup.name,
                    filterByString:  true
                },
                {
                    content: (<UserList users={userGroup.assigned_users} limit={5} title={i18n._('Žiaci')}/>),
                    stringToOrder:  userGroup.name,
                    filterString:  userGroup.name,
                    filterByString:  true //TODO správny názov navyhľadávanie (mená žiakov!)
                },
                {
                    content: (<NumberText number={userGroup.assigned_wordgroups.length} text='' title={i18n._('Skupiny slovíčok')}/>),
                    stringToOrder:  userGroup.name,
                    filterString:  userGroup.name,
                    filterByString:  false
                },
                {
                    content: (
                        <NavLink to={"/app/classes/" + userGroup.id} target="_self" className="bttn _small _primary _icon">
                            <Trans>Detail</Trans>
                        </NavLink>
                    ),
                    stringToOrder: userGroup.name,
                    filterString: userGroup.name,
                    filterByString: false
                }
            ]

            rows.push({
                cells: cells,
                catchString: '_' +  userGroup.id
            })
        }

        return rows
    }

    getHeaders(): ITableHeaderCellType[] {
        return [
            {
                content: (<span><Trans>Názov</Trans></span>),
                sortBy: true,
                sortable: true
            },
            {
                content: (<span><Trans>Žiaci</Trans></span>),
                sortBy: false,
                sortable: false
            },
            {
                content: (<span><Trans>Skupiny slovíčok</Trans></span>),
                sortBy: false,
                sortable: false
            },
            {
                content: (<span></span>),
                sortBy: false,
                sortable: false
            },
        ]
    }

    getActions(): IActionType[] {

        return [
            {
                action: this.handleEditUserGroup,
                name: i18n._('Upraviť')
            },
            {
                action: this.handleDeleteUserGroup,
                name: i18n._('Odstrániť'),
                customClass: 'remove'
            }
        ]
    }

    render() {
        const { createdUserGroups, loadingCreated } = this.props
        const { selectedDeleteUserGroup, selectedEditUserGroup } = this.state

        let createdHeaders: ITableHeaderCellType[] = this.getHeaders()
        let createdRows: ITableRowType[] = this.getRows(createdUserGroups)
        let createdActions: IActionType[] = this.getActions()


        return (
            <div>
                <TableContainer
                    headers={createdHeaders}
                    rows={createdRows}
                    actions={createdActions}
                    showActions={true}
                    showCheckbox={false}
                    showFilter={true}
                    pageSize={12}
                    title={i18n._('Zoznam tried')}
                    showNew={true}
                    grid={true}
                    newTitle={i18n._('Pridať triedu')}
                    newFunction={this.handleCreateUserGroup}
                    emptyText={i18n._('Zatiaľ nemáte vytvorenú žiadnu triedu')}
                    emptySubtitle={i18n._('Ak chcete vytvoriť novú triedu, stačí stlačiť tlačidlo "pridať triedu".')}
                    loading={loadingCreated}
                    mobileFixedGrid={true}
                />

                {(selectedDeleteUserGroup !== null)?(
                    <ModalDeleteUserGroup
                        userGroup={selectedDeleteUserGroup}
                        confirmFunction={this.confirmDeleteUserGroup}
                    />
                ):(null)}
                {(selectedEditUserGroup !== null)?(
                <ModalEditUserGroup 
                    id={selectedEditUserGroup.id}
                    name={selectedEditUserGroup.name}
                    note={selectedEditUserGroup.comment}
                />):(null)}
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreatedUserGroupTable)