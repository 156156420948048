import React, { Component } from 'react';
import { ILanguageType } from '../../constants/types';
import ReactCountryFlag from 'react-country-flag';

interface IProps {
    languageFrom: ILanguageType,
    languageTo: ILanguageType
}
class TranslateLanguages extends Component<IProps> {
    render() {
        const { languageFrom, languageTo } = this.props

        return  (
        <div className="part-langs">
            <ul>
                <li><ReactCountryFlag className="flag" countryCode={languageFrom.flag_name} svg /></li>
                <li><i className="la la-long-arrow-right"/></li>
                <li><ReactCountryFlag className="flag" countryCode={languageTo.flag_name} svg /></li>
            </ul>
        </div>
        )
    }
}

export default TranslateLanguages