import { ReactNode } from 'react';

//Interface used in stepper
export interface IStepperStepType {
    name: string,
    step: any
}

//Word mapping on creation
export interface IAddWordMapping {
    from: string,
    to: string
}

//Message type
export type IMessageType = 'success' |  'warning' | 'error'

//Language
export interface ILanguageType {
    id: number,
    flag_name: string,
    name: string
}

//Word
export interface IWordType {
    id: number,
    translation: string,
    word_text: string
}

//User
export interface IUSerInfo {
    created_at: string,
    created_wordgroups: IWordgroupType[],
    wordgroups: IWordgroupType[],
    email: string,
    f_name: string,
    id: number,
    language: string,
    login: string,
    my_words: IWordType[],
    name: string,
    country: string,
    last_login: string,
    school: string | null,
    role: {
        id: number,
        name: string
    },
    surname: string,
    telephone: string,
    updated_at: string,
    training_status: number
}

//Wordgroup
export interface IWordgroup {
    id: number,
    language_from: ILanguageType,
    language_to:  ILanguageType,
    name: string,
    words: IWordType[]
}

export interface IWordgroupType extends IWordgroup {
    created_at: string,
    created_by: {id:number, login:string},
    updated_at: string
}

//Wordgrorup of usergroup
export interface IUWordGroupType extends IWordgroupType {
    valid_from: string | null,
    valid_until: string | null,
    usergroup: {
        usergroup_id: number,
        usergroup_name: string
    } | null
}

//Word group mapping on creation 
export interface IWordGroupMapping {
    value: number,
    object: IWordgroupType,
    label: string,
    __isNew__?: boolean    
}

//Table structure
export  interface IActionType {
    name: string,
    action: (catchString: string) => any,
    customClass?: string
}

export interface ITableCellType {
    stringToOrder: string,
    content: ReactNode,
    filterByString?: boolean,
    filterString: string |  string[],
    selectableFilter?: boolean,
    selectableName?: string,
    selectableAllText?: string,
    selectableTitle?: string,
    hideClass?: 'hide-m' | 'hide-s',
    selectableObject?: {
        object: ReactNode,
        value: string
    }[]
}

export interface ITableRowType {
    cells: ITableCellType[],
    catchString: string,
    showAction?: boolean
}

export interface ITableHeaderCellType {
    sortBy: boolean,
    sortable: boolean,
    content: ReactNode,
    sortableType?: 'text' | 'date' | 'number',
    hideClass?: 'hide-m' | 'hide-s'
}

//Word created
export interface IWordCreatedType extends IWordType {
    created_at: string,
    is_active: boolean,
    language_from: ILanguageType,
    language_to: ILanguageType,
    updated_at: string,
    wordgroups: IWordgroupType[]
}

//Word selected / checkboxes
export interface IWordChecked {
    id: number,
    word_text: string,
    translation: string,
    language_from: ILanguageType,
    language_to: ILanguageType
}

//Modify wordgroup
export interface IModifyWordGroup {
    wordgroup_name: string,
    language_ids: {
        language_from_id: number,
        language_to_id: number
    }
    words: {
        word_text: string,
        translation: string
    }[]
}

//Basic user
export interface IUserBasic {
    id: number,
    login: string,
    email: string,
    f_name: string,
    name: string,
    surname: string,
    telephone: string
}

//UserGroup
export interface IUserGroupType {
    id: number,
    name: string,
    comment: string,
    access_code: string,
    assigned_users: IUserBasic[],
    created_at: string,
    created_by: {
        id: number,
        login: string
    },
    creator_id: number,
    assigned_wordgroups: IWordgroupType[]
}

export interface ICreateUserGroup {
    name:  string,
    comment?: string
}

export interface INotification {
    text: string,
    type: 'success' | 'info' |  'warning' | 'error',
    showBell?: boolean
}

export interface INotificationFull  extends INotification {
    id: number,
    validFrom: Date,
    goingToHide: boolean,
    goingToShow: boolean
}

//Container for tabs header
export interface ITabsHeader {
    title: string,
    icon: string | JSX.Element,
    customIcon?: boolean,
    titleTab: string,
    tutorialClass?: string,
    description?: string
}

export interface ITabs {
    header: ITabsHeader,
    content: ReactNode,
    footer?: ReactNode,
    onFirstOpen?: () => any,
    onOpen?: () => any
}

export interface ITabsAction {
    title: string,
    icon: string,
    action: () => any
}

//Tests
export enum ETestType {
    select = "select",
    folding = "folding",
    connect = "connect",
    filling = "filling",
    random = "random"
}

export enum ETestDifficulty {
    easy = "easy",
    medium = "medium",
    hard = "hard"
}

export enum ETestUgType {
    homework = 'homework',
    test = 'test'
}

export interface ITestSettings {
    type: ETestType,
    difficulty: ETestDifficulty,
    time: number | null,
    words: IAddWordMapping[],
    wordGroups: number[],
    justOnce?: boolean,
    isUserGroup?: boolean,
    ugType?: ETestUgType,
    diacritic?: boolean,
    email?: boolean
}

export interface ITest {
    created_at: string,
    created_by: {
        id: number,
        login: string
    },
    id: number,
    language_from: ILanguageType,
    language_to: ILanguageType,
    language_from_id: number,
    language_to_id: number,
    name: string,
    updated_at: string,
    settings: ITestSettings,
    usergroup_id: number | null,
    valid: boolean,
    valid_from: string,
    valid_until: string | null
}

export interface ITestCreate {
    language_ids: {
        language_from_id: number,
        language_to_id: number
    },
    name: string,
    settings: ITestSettings,
    valid_from: string,
    valid_until: string | null,
    usergroup_id: number | null
}

export interface IQuestionVariantSelect {
    type: ETestType.select,
    word: IAddWordMapping,
    wType: 'from'|'to',
    answers: string[],
    response: string,
    valid?: boolean
}

// export interface IQuestionVariantConnect {
//     type: ETestType.connect,
//     words: IAddWordMapping[],
//     wType: 'from' | 'to',
//     response: {left: string, right: string}[],
//     valid?: boolean
// }

export interface IQuestionVariantFilling {
    type: ETestType.filling,
    word: IAddWordMapping,
    wType: 'from'|'to',
    prefilled: number[],
    response: string,
    valid?: boolean
}

export interface IQuestionVariantFolding {
    type: ETestType.folding,
    word: IAddWordMapping,
    wType: 'from'|'to',
    characters: string[],
    response: string,
    valid?: boolean
}

export interface ITestResultResult {
    questions: (IQuestionVariantFilling | /*IQuestionVariantConnect |*/ IQuestionVariantFolding | IQuestionVariantSelect)[],
    current: number
}

export interface ITestResult {
    id?: number,
    test_id: number,
    result: ITestResultResult | null,
    date_started: string,
    date_finished: string | null,
    test_rate: number,
    test_type: string,
    user_id?: number
}

export interface ICurrentTest {
    test: ITest,
    testResult?: ITestResult
}

export interface ITimelineItem {
    date: string,
    content: ReactNode,
    button?: string,
    buttonAction?: any,
    icon?: ReactNode,
    buttonColor?: 'primary'|'green'|'orange'|'red'|'silent'
}

export interface IRanking {
    country: string,
    login: string,
    rank: number,
    ranking: number,
    user_id: number
}

export interface IActivity {
    text: string,
    subText?: string,
    link?: string,
    linkTitle?: string,
    widget: 'text'|'circle',
    widgetValue: string | number,
    dateTime: string
}

export interface IRelease {
    date: string,
    versionNumber: string,
    type: 'small' | 'medium' | 'big',
    notes: string[],
    notesTeacher?: string[],
    notesUser?: string[]
}

export interface INotificationApp {
    created_at: string,
    updated_at: string,
    valid_from: string,
    valid_to: string,
    notif_type: 'info' | 'warning' | 'error' | 'success',
    content: {
        [key: string]: string
    }
}

export interface IModalUser {
    id: number,
    name: string,
    surname: string,
    email: string,
    results: {
        date: string,
        points: number
    }[]
}

export interface IRankingMonth {
    [key: string]: {
        world: IRanking[],
        user: IRanking
    }
}