import React, { useState, useEffect } from 'react';
import Tour, { ReactourStep } from 'reactour'

interface IProps {
    showTutorial: boolean,
    hideTutorial: () => any,
    steps: ReactourStep[],
    pathname: string
}

const TutorialContainer: React.FC<IProps> = props => {
    const [width, setWidth] = useState(window.innerWidth)
    const [update, setUpdate] = useState(0)

    useEffect(() => { 
        const timer = setTimeout(() => {
            setUpdate(update+1)
        }, 500);
        return () => clearTimeout(timer);
    }, [update])

    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth)
    
        window.addEventListener('resize', handleResize);
    
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [])

    if(!props.showTutorial || width < 768) {
        return (null)
    }

    return (
        <>
            <Tour
                steps={props.steps}
                isOpen={props.showTutorial}
                disableInteraction={true}
                closeWithMask={false}
                onRequestClose={() => props.hideTutorial()} 
                showNavigation={false}
                update={props.pathname}
                updateDelay={700}
            />
        </>
    )
}

export default TutorialContainer