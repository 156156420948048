import React, {useEffect} from 'react'
import { loadWorld } from '../../../../actions/rankings/loadWorld';
import { IApplicationStore } from '../../../../constants/store-interfaces';
import { useDispatch, useSelector } from 'react-redux';
import { Trans } from '@lingui/macro';
import LoadingElement from '../../../simple/LoadingElement';
import RankingRow from './parts/RankingRow';
import { FacebookShareButton, FacebookIcon } from 'react-share';
import { i18n } from '../../../../App';

interface IProps {
    refresh: boolean,
    hardNone?: boolean,
    showHeader?: boolean
}

const WorldRankings: React.FC<IProps> = props => {
    const dispatch = useDispatch()

    const loading = useSelector((state: IApplicationStore) => state.loadingStore.loadingRankingWorld)
    const rankings = useSelector((state: IApplicationStore) => state.rankingStore.worldRankings)
    const userInfo = useSelector((state: IApplicationStore) => state.globalStore.userInfo)
    
    useEffect(() => {
        if(typeof props.hardNone === 'undefined' || !props.hardNone) {
            if(rankings.world.length === 0 || props.refresh) {
                dispatch(loadWorld())
            }
        }
    }, [dispatch, props.hardNone, rankings.world.length, props.refresh])

    let top = 0

    if(rankings.world.length > 0) {
        top = rankings.world[0].ranking
    }

    let is = false

    let position = '-'
    let points = 0

    rankings.world.forEach(r => {
        if(rankings.user !== null) {
            if(r.user_id === rankings.user.user_id) {
                is = true
                position = r.rank.toString()
                points = r.ranking
            }
        }
    })

    if((position === '-' || points !== 0) && rankings.user !== null) {
        position = rankings.user.rank.toString()
        points = rankings.user.ranking
    }

    let sharePath = ''

    if(userInfo !== null) {
        sharePath = btoa(encodeURI(JSON.stringify({
            'r': position,
            'p': points,
            'i': 'w'
        })))
    }

    let shareBtn = (
        <FacebookShareButton
            url={'https://certificate.alphie.app/share.php?c=' + sharePath}
            quote={i18n._("Aplikácia Alphie - učenie slovíčok z ľahkosťou")}
            hashtag="#alphieapp"
            className="bttn --share"
        >
            <FacebookIcon size={36} />
        </FacebookShareButton>
    )

    return (
        <div className="widget-part">
            
            <section className="widget">
                <LoadingElement loadingValue={loading}/>
                
                {((typeof props.showHeader === 'undefined') || props.showHeader)?(
                    <header className="widget-header">
                        <div className="_part">
                        
                            <h3><Trans>Najlepší na svete</Trans></h3>
                        
                        </div>
                        <div className="_part --sharing">
                            <div className="share --part" data-tooltip={i18n._('Zdieľať')}>
                                {shareBtn}
                            </div>
                            <button onClick={() => dispatch(loadWorld())} className="tag _primary _alt"><Trans>Obnoviť</Trans></button>
                        
                        </div>
                    </header>
                ):(<div className="share --part --right"  data-tooltip={i18n._('Zdieľať')}>
                    {shareBtn}
                </div>)}
                
                <div className="widget-body n-p-m">
                    <div className="_part">
                    
                        <div className="table _full">

                            <div className="table-body">
                                {rankings.world.map((r,i) => {
                                    let isMe = false

                                    if(rankings.user !== null) {
                                        if(r.user_id === rankings.user.user_id) {
                                            isMe = true
                                        }
                                    }
                                    return (
                                        <RankingRow key={i} maxRate={top} ranking={r} isMe={isMe}/>
                                    )
                                })}
                                {(!is && (rankings.user !== null)?(
                                    <>
                                        <span className="delimiter"></span>
                                        <RankingRow maxRate={top} ranking={rankings.user} isMe={true}/>
                                    </>
                                ):(null))}
                                {((rankings.user === null) && (userInfo !== null))?(
                                    <>
                                        <span className="delimiter"></span>
                                        <RankingRow 
                                            maxRate={top} 
                                            ranking={{
                                                country: userInfo.country,
                                                login: userInfo.login,
                                                rank: -1,
                                                ranking: 0,
                                                user_id: userInfo.id
                                            }} 
                                            isMe={true}
                                        />
                                    </>
                                ):(null)}
                            </div>
                        
                        </div>
                    
                    </div>
                </div>
            
            </section>

        </div>
    )
}

export default WorldRankings
