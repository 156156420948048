import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { IApplicationStore } from "../../../constants/store-interfaces";
import { ITest } from '../../../constants/types';
import { RouteComponentProps, withRouter } from 'react-router';
import { loadTest } from '../../../actions/tests/loadTest';
import TestContainer from '../containers/TestContainer';
import ReactGA from 'react-ga';
import InformationBox from '../parts/InformationBox';
import { Trans } from '@lingui/macro';

type PathParamsType = {
    id: string
}

interface IProps extends RouteComponentProps<PathParamsType> {

}


const TestDetailPage: React.FC<IProps> = props => {
    const dispatch = useDispatch()

    const [test, setTest] = useState<ITest | null>(null)
    const loadingCreatedTests = useSelector((state: IApplicationStore) => state.loadingStore.loadingCreatedTests)
    
    useEffect(() => {
        ReactGA.pageview('Cvičenie')
    }, [])

    useEffect(() => {
        let isMounted = true;

        loadTest(Number(props.match.params.id))
            .then((res) => {
                if (isMounted) {
                    setTest(res)
                }
            })

        return () => { isMounted = false };

    }, [dispatch, props.match.params.id])

    if(test == null && loadingCreatedTests) {
        return (null)
    } else {
        if(test == null) {
            return (
                <InformationBox type='warning' showBell={true}>
                    <Trans>Uvedený test nie je možné spustiť.</Trans>
                </InformationBox>
            ) 
        }
    }

    return (
        <TestContainer
            test={test}
            allowed={true}
            loading={loadingCreatedTests}
        />
    )
}

export default withRouter(TestDetailPage)