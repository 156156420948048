import React from 'react';
import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import WorldRankings from '../parts/rankings/WorldRankings';
import CountryRankings from '../parts/rankings/CountryRankings';
import UserGroupsWidget from '../../simple/widgets/dashboard/UserGroupsWidget';
import ActivityWidget from '../../simple/widgets/dashboard/ActivityWidget';
import ReleasesWidget from '../../simple/widgets/dashboard/ReleasesWIdget';
import ReactGA from 'react-ga';
import WorldPlaceWidget from '../../simple/widgets/dashboard/WorldPlaceWidget';
import TotalPointsWidget from '../../simple/widgets/dashboard/TotalPointsWidget';
import CountryPlaceWidget from '../../simple/widgets/dashboard/CountryPlaceWidget';
import DashboardHeader from '../../simple/widgets/dashboard/parts/DashboardHeader';
import NotificationsApp from '../../simple/widgets/dashboard/parts/NotificationsApp';
import DataLoader from '../../simple/widgets/dashboard/parts/DataLoader';
import RankingMonthWidget from '../../simple/widgets/dashboard/RankingMonthWidget';
import TotalPointsThisMonthWidget from '../../simple/widgets/dashboard/TotalPointsThisMonthWidget';

interface IProps {

}

const AppPage: React.FC<IProps> = props => {
    useEffect(() => {
        ReactGA.pageview('Prehľad')
    }, [])

    return (
        <section id="mainSection">
            <DataLoader/>
            <section id="dashboard" data-tour="step4">
                
                <DashboardHeader/>

                <div className="dashboard_body">
                    
                    <NotificationsApp/>

                    <div className="rows">    
                    
                    <div className="row">
                        <div className="row-part">
                        
                            <div className="small-widgets">
                                <ul data-tour="step5">
                                    <li>
                                        <TotalPointsThisMonthWidget/>
                                    </li>
                                    <li>
                                        <TotalPointsWidget/>
                                    </li>
                                    <li>
                                        <WorldPlaceWidget/>
                                    </li>
                                    <li>
                                        <CountryPlaceWidget/>
                                    </li>
                                </ul>
                            </div>
                        
                        </div>
                    </div>
                    <div className="row">
                        <div className="row-part">
                            <RankingMonthWidget/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="row-part" data-tour="step6">
                            <UserGroupsWidget/>
                        </div>
                    </div>

                    <div className="row" data-tour="step7">
                        <div className="row-part">
                        
                            <WorldRankings refresh={true}/>
                            
                        </div>
                        <div className="row-part">

                            <CountryRankings refresh={true}/>

                        </div>
                        
                    </div>
            
                    <div className="row">
                        <div className="row-part" data-tour="step8">
                            <ActivityWidget/>
                        </div>
                        <div className="row-part">
                            <ReleasesWidget type={'hp'}/>
                            
                        </div>
                    </div>
                    
                </div>

                </div>

            </section>
        </section>
    )
}

export default withRouter(AppPage)