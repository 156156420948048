import React, {Component} from 'react';
import { connect } from 'react-redux';
import { ITest, IUserGroupType, ITestResult, IUSerInfo, ETestType, ETestUgType } from '../../../../constants/types';
import { loadTestResults } from '../../../../actions/tests/loadTestResults';
import { loadAssignedTests } from '../../../../actions/tests/loadAssignedTests';
import { loadAssignedUserGroups } from '../../../../actions/userGroups/loadAssignedUserGroups';
import { IApplicationStore } from '../../../../constants/store-interfaces';
import getTestTypeString from '../../../simple/classes/getTestTypeString';
import TestClass from '../../../simple/classes/testClass';
import moment from 'moment';
import LoadingElement from '../../../simple/LoadingElement';
import { Trans } from '@lingui/macro';
import { NavLink } from 'react-router-dom';
import { i18n } from '../../../../App';
import IconTextWidget from '../../../simple/widgets/IconTextWidget';
import TranslateLanguages from '../../../simple/TranslateLanguages';
import PercentageSmallWidget from '../../../simple/widgets/PercentageSmallWidget';
import TestDetailBasic from '../../containers/TestElements/TestDetailBasic';
import NoMatchPage from '../../pages/NoMatchPage';

interface IStateProps {
    assignedTests: ITest[],
    assignedUserGroups: IUserGroupType[],
    testResults: ITestResult[],
    loadingAssigned: boolean,
    loadingUserGroups: boolean,
    loadingResults: boolean
    userInfo: IUSerInfo | null
}

interface IDispatchProps {
    loadTestResults: typeof loadTestResults,
    loadAssignedTests: typeof loadAssignedTests,
    loadAssignedUserGroups: typeof loadAssignedUserGroups
}

const mapStateToProps = (state: IApplicationStore): IStateProps => ({
    assignedTests: state.testsStore.assignedTests,
    assignedUserGroups: state.userGroupsStore.assignedUserGroups,
    testResults: state.testsStore.testResults,
    loadingAssigned: state.loadingStore.loadingAssignedTests,
    loadingUserGroups: state.loadingStore.loadingAssignedUserGroups,
    loadingResults: state.loadingStore.loadingTestResult,
    userInfo: state.globalStore.userInfo
})

const mapDispatchToProps = (dispatch: any): IDispatchProps => ({
    loadTestResults: () => dispatch(loadTestResults()),
    loadAssignedTests: () => dispatch(loadAssignedTests()),
    loadAssignedUserGroups:() => dispatch(loadAssignedUserGroups())
})

interface IProps extends IStateProps, IDispatchProps {
    hwId: number,
    classId: number
}

interface IState {
}

class HomeWorkDetailUser extends Component<IProps, IState> {

    componentDidMount() {
        this.props.loadAssignedUserGroups()
        this.props.loadTestResults()
        this.props.loadAssignedTests()
    }

    render() {
        const { assignedTests, assignedUserGroups, loadingUserGroups, loadingAssigned, loadingResults, testResults, hwId, classId } = this.props

        let test = assignedTests.find(t => t.id === hwId)
        let userGroup = assignedUserGroups.find(t => t.id === classId)

        if(((typeof test === 'undefined') || (typeof userGroup === 'undefined')) && !loadingAssigned && !loadingResults) {
            return (<NoMatchPage/>)
        }

        if(typeof test === 'undefined') {
            return (null)
        }
        
        const typeSelect: { label: string; value: string }[] = []

        Object.keys(ETestType).forEach(value => {
            typeSelect.push({
                label: getTestTypeString(ETestType[(value as ETestType)]),
                value: ETestType[(value as ETestType)]
            })
        })

        const typeValue = typeSelect.find(value => ((typeof test !== 'undefined') && (value.value === test.settings.type)))

        const results = testResults.filter(r => ((typeof test !== 'undefined') && (r.test_id === test.id)))
        
        let percentage:number = 0
        let totalPoints:number = 0

        if(results.length) {
            let maxPoints = 0

            results.forEach(tR => {
                if(typeof test !== 'undefined') {
                    let testObject = new TestClass(test, tR)
                    
                    totalPoints += testObject.getTestRate()
                    maxPoints += testObject.getMaximumRate()
                }
            })

            percentage = Math.round(Number(totalPoints)/maxPoints*100)
        }
        
        let isValid = true

        if(moment(test.valid_from).isAfter(moment())) {
            isValid = false
        }   

        if((test.valid_until !== null) && (moment(test.valid_until).isBefore(moment()))) {
            isValid = false
        }

        return (
            <section id="mainSection">
                <LoadingElement loadingValue={loadingAssigned || loadingResults || loadingUserGroups} />

                <div className="mainSectionBody">
                
                    <div className="rows">
                        <div className="row">
                            <div className="row-part">
                                <div className="page-header">
                                    <div className="part">
                                        <h2>
                                            <Trans>Domáca úloha</Trans>{(typeof userGroup !== 'undefined')?' (' + userGroup.name + ')':''} - {test.name}
                                        </h2>
                                        <small><Trans>Platnosť:</Trans> {moment(test.valid_from).format('DD.MM.YYYY HH:mm')} - {(test.valid_until !== null)?moment(test.valid_until).format('DD.MM.YYYY HH:mm'):(null)}</small>
                                        <p><Trans>Detail domácej úlohy s Vašími výsledkami a štatistikami</Trans></p>
                                    </div>
                                    <div className="part">
                                        <NavLink to={"/app/classes/" + classId} target="_self" className="bttn _small _orange _icon" data-tooltip={i18n._("Späť do triedy")}>
                                            <i className="la la-angle-left"/>
                                        </NavLink>
                                        {(isValid)?(
                                            (test.settings.ugType === ETestUgType.homework)?(
                                                <NavLink to={"/app/classes/" + classId + "/homeworks/" + test.id} target="_self" className="bttn _small _green _icon">
                                                    <Trans>Spustiť</Trans>
                                                </NavLink>
                                            ):(
                                                <NavLink to={"/app/classes/" + classId + "/tests/" + test.id} target="_self" className="bttn _small _green _icon">
                                                    <Trans>Spustiť</Trans>
                                                </NavLink>
                                            )
                                        ):(null)}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="row-part">
                                <div className="small-widgets">
                                    <ul>
                                        <li>
                                            <IconTextWidget
                                                showIcon={true}
                                                icon="la-gear"
                                            >
                                                <h3>{(typeof typeValue !== 'undefined')?typeValue.label:''}{(typeof test.settings.diacritic !== 'undefined')?(test.settings.diacritic)?', ' + i18n._('s diakritikou'):', ' + i18n._('bez diakritiky'):', ' + i18n._('bez diakritiky')} {(test.settings.time !== null)?'(' + test.settings.time + ' ' + i18n._('min') + ')':''}</h3>
                                                <p><Trans>Typ domácej úlohy</Trans></p>
                                            </IconTextWidget>
                                        </li>
                                        <li>
                                            <IconTextWidget
                                                showIcon={true}
                                                icon="la-flag"
                                            >
                                                <TranslateLanguages
                                                    languageFrom={test.language_from}
                                                    languageTo={test.language_to}
                                                />
                                            </IconTextWidget>
                                        </li>
                                        <li>
                                            <IconTextWidget
                                                showIcon={true}
                                                icon="la-bar-chart"
                                            >
                                                <h3>+ { totalPoints }</h3>
                                                {(totalPoints === 1)?(
                                                    <p><Trans>Získaný bod</Trans></p>
                                                ):((totalPoints > 1) && (totalPoints <= 4))?(
                                                    <p><Trans>Získané body</Trans></p>
                                                ):(
                                                    <p><Trans>Získaných bodov</Trans></p>
                                                )}
                                                
                                            </IconTextWidget>
                                        </li>
                                        <li>
                                            <PercentageSmallWidget
                                                percentage={percentage}
                                            >
                                                <h3><Trans>Priemerná úspešnosť</Trans></h3>
                                            </PercentageSmallWidget>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="row-part">
                                <TestDetailBasic
                                    test={test}
                                />
                            </div>
                        </div>
                    </div>
                
                </div>

            </section>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeWorkDetailUser)