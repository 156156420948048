import React, {Component} from 'react'
import CircularGraph from '../widgets/CircularGraph';

interface IProps {
    percentage: number
    title: string
}

class Percentage extends Component <IProps, any> {

    render() {
        const { percentage, title }  = this.props

        return (
            <div className="part-text" data-title={title}>
            
                <div className="knobs">
                    <ul>
                        <li>
                            <CircularGraph percentage={percentage}/>
                        </li>
                    </ul>
                </div>
            
            </div>
        )
    }
}

export default Percentage
