import React from 'react'
import { IUSerInfo } from '../../../../../constants/types';
import { Trans } from '@lingui/macro';
import { useDispatch, useSelector } from 'react-redux';
import { IApplicationStore } from '../../../../../constants/store-interfaces';
import { setShowCreateTestModal, setShowCreateWordGroupModal, setShowCreateUserGroupModal } from '../../../../../actions/modals/setShowModal';
import AuthTeacherWrapper from '../../../../app/containers/Authentification/AuthTeacherWrapper';

interface IProps {

}

const DashboardHeader: React.FC<IProps> = props => {
    const dispatch = useDispatch()
    const userInfo = useSelector<IApplicationStore, IUSerInfo | null>(state => state.globalStore.userInfo)

    return (   
        <>
            {(userInfo !== null)?(
                <header className="dashboard_header">
                    <div className="part">
                        <h1><Trans>Vitaj</Trans>, {userInfo.name}!</h1>
                    </div>
                    <div className="part">

                        <div className="bttns">
                            <button 
                                className="bttn _green _small"
                                onClick={() => dispatch(setShowCreateTestModal(true))}
                            >
                                <Trans>Vytvoriť cvičenie</Trans>
                            </button>
                            <button 
                                className="bttn _primary _small"
                                onClick={() => dispatch(setShowCreateWordGroupModal(true))}
                            >
                                <Trans>Vytvoriť skupinu slovíčok</Trans>
                            </button>
                            <AuthTeacherWrapper>
                                <button 
                                    className="bttn _orange _small"
                                    onClick={() => dispatch(setShowCreateUserGroupModal(true))}
                                >
                                    <Trans>Vytvoriť triedu</Trans>
                                </button>
                            </AuthTeacherWrapper>
                        </div>

                    </div>
                </header>
            ):(null)}
        </>
    )
}

export default DashboardHeader