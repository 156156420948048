import React, { useState, useEffect } from 'react';
import { IoIosRemoveCircleOutline, IoIosAddCircleOutline, IoIosArrowForward } from 'react-icons/io';
import { IAddWordMapping } from '../../../../../constants/types';
import { Trans } from '@lingui/macro';
import { i18n } from '../../../../../App';
import ReactCountryFlag from 'react-country-flag';
import { ReactNode } from 'react';
import InformationBox from '../../InformationBox';
import { loadTranslations } from '../../../../../actions/words/loadTranslations';

interface IProps {
    words: IAddWordMapping[],
    handleWords: any,
    languageFrom: any,
    languageTo: any,
    isValid: boolean
}

interface ITranslateAlternative {
    word: string,
    options: string[],
    loading: boolean,
    used: boolean
}

const AddWordRepeater: React.FC<IProps> = props => {    
    const [wordsFrom, setWordsFrom] = useState<string[]>([])
    const [wordsTo, setWordsTo] = useState<string[]>([])
    const [wordTranslations, setWordTranslations] = useState<ITranslateAlternative[]>([])
    const [showWordHelp, setShowWordHelp] = useState<boolean>(false)

    const handleToParent = (wordsFrom: string[], wordsTo: string[]) => {
        let words: IAddWordMapping[] = []

        for (var _i = 0; _i < wordsFrom.length; _i++) {
            words.push({
                from:  wordsFrom[_i],
                to: wordsTo[_i]
            })
        }

        props.handleWords(words)
    }

    useEffect(() => {
        let wordsFrom: string[] = []
        let wordsTo: string[] = []
        let words = props.words

        for(var _i = 0; _i < words.length; _i++) {
            wordsFrom.push(words[_i].from)
            wordsTo.push(words[_i].to)
        }

        setWordsFrom(wordsFrom)
        setWordsTo(wordsTo)
    }, [props.words])

    const addWords = () => {
        wordsFrom.push('')
        wordsTo.push('')

        setWordsFrom(wordsFrom)
        setWordsTo(wordsTo)

        handleToParent(wordsFrom, wordsTo)
    }

    const deleteWords = (e:any) => {
        let id = e.currentTarget.dataset.index

        wordsFrom.splice(id, 1)
        wordsTo.splice(id, 1)

        setWordsFrom(wordsFrom)
        setWordsTo(wordsTo)

        handleToParent(wordsFrom, wordsTo)
    }

    const handleKeyPress = (e:any) => {
        if((e.keyCode ===  9) && !(e.shiftKey)) {  //If  is TAB
            e.stopPropagation()
            e.preventDefault()
            addWords()
        }
    }

    const handleChange = (index: number,  e:any) => {
        let parameters = e.target.name.split('_') //0 = name, 1 = from/to, 2 = index

        if(parameters[1] === 'from') {
            wordsFrom[parameters[2]] = e.target.value
        } else {
            wordsTo[parameters[2]] = e.target.value
        }

        setWordsFrom(wordsFrom)
        setWordsTo(wordsTo)

        handleToParent(wordsFrom, wordsTo)
    }

    const handleBlurFromInput = (word: string) => {
        if(word.length > 2 && showWordHelp) {
            if(typeof wordTranslations.find(w => w.word === word) === 'undefined') {
                wordTranslations.push({
                    word: word,
                    loading: true,
                    options: [],
                    used: false
                })
                setWordTranslations(wordTranslations)
            }

            loadTranslations(word, props.languageFrom.flag_name, props.languageTo.flag_name)
                .then(res => {
                    let w = wordTranslations.find(w => w.word === word)

                    if(typeof w !== 'undefined') {
                        let newWT = wordTranslations.filter(wt => wt.word !== word)

                        if(res.status === 'success') {
                            newWT.push({
                                word: word,
                                loading: false,
                                options: (typeof res.data === 'string')?[res.data]:res.data,
                                used: false
                            })
                        }

                        setWordTranslations(newWT)
                    }
                })
        }
    }

    let fromInputs:ReactNode[] = []
    let toInputs: ReactNode[] = []

    let wordClasses: string[] = []

    let _i = 0

    for(let word  of wordsFrom) {
        fromInputs.push((<input
            name={"name_from_" + _i}
            id={"name_from_" + _i}
            type="text" 
            placeholder={i18n._('Originál')} 
            onChange={handleChange.bind(this, _i)}
            onBlur={(e) => handleBlurFromInput(e.currentTarget.value)}
            value={word}
            autoComplete='off'
            autoFocus
        />))

        _i++
    }

    _i = 0

    for(let word  of wordsTo) {
        toInputs.push((<input
            name={"name_to_" + _i}
            id={"name_to_" + _i}
            type="text" 
            placeholder={i18n._('Preklad')} 
            onChange={handleChange.bind(this, _i)}
            value={word}
            onKeyDown={(wordsFrom.length === _i+1)?handleKeyPress:() =>  null} 
            autoComplete="off"
        />))
        
        _i++
    }

    let allSpecial = true

    wordsFrom.forEach((w,i) => {
        let wordFrom = w
        let wordTo = wordsTo[i]

        let classNames = ''

        if((wordFrom !== '') && (wordTo !== '')) {
            wordsFrom.forEach((w2, i2) => {
                if(i2 !== i) {
                    if((wordFrom === w2) && (wordTo === wordsTo[i2])) {
                        classNames = 'has-error'
                        allSpecial = false
                    }
                }
            })
        }

        wordClasses.push(classNames)
    })

    return (
        <div className="input-row">
            <div className="add-words">
                <div className="input-row">
                    <div className="part">
                        <label htmlFor="test_type"><strong><Trans>Zobrazovať návrhy prekladov</Trans></strong></label>
                        <small><Trans>Po zadaní slova sa automaticky načítajú preklady zadaného slova</Trans></small>
                        <ul className="inline-list _full form">
                            <li>
                                <div className={"check toggle " + (showWordHelp?'success':'error')}>
                                    <br/>
                                    <input 
                                        type="checkbox" 
                                        id="showHelp"
                                        onChange={() => setShowWordHelp(!showWordHelp)}
                                        checked={showWordHelp}
                                    />
                                    <label htmlFor="showHelp"></label>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <InformationBox type="info" showClose={false} showBell={true}>
                    <Trans>Odporúčame zadávať slová bez interpunkčných znamienok.</Trans>
                </InformationBox>   
                {wordsFrom.map((value:string, index:number) => (
                    <div className="add-words-row" key={index}>
                        <div className="editPart" key={index}>
                            <div className="wordToTranslate input-holder">
                                <div className={"part " + wordClasses[index]}>
                                    {(props.languageFrom !== null)?(
                                        <ReactCountryFlag className="flag" countryCode={props.languageFrom.flag_name} svg/>
                                    ):(null)
                                    }
                                    {fromInputs[index]}
                                </div>
                                <div className={"part " + wordClasses[index]}>
                                    {(props.languageTo !== null)?(
                                        <ReactCountryFlag className="flag" countryCode={props.languageTo.flag_name} svg/>
                                    ):(null)
                                    }
                                    {toInputs[index]}
                                    {showWordHelp && wordTranslations.filter(w => w.word === value && !w.used).map((w,i) => (
                                        <div className="helpers" key={i}>
                                            <ul>
                                                {w.options.map((o,oKey) => (
                                                    <li key={oKey}>
                                                        <span data-tooltip={i18n._('Použiť')} onClick={(e) => {
                                                            
                                                            let id = e.currentTarget.parentElement?.parentElement?.parentElement?.previousElementSibling?.getAttribute('id')

                                                            if(typeof id === 'string' && id.length > 0) {
                                                                document.getElementById(id)?.focus()
                                                            }

                                                            if(typeof wordsTo[index] !== 'undefined') {
                                                                let newWordsTo = [...wordsTo]
                                                                let newWordTranslations = wordTranslations.map(wt => {
                                                                    if(wt.word === value) {
                                                                        return {
                                                                            ...wt,
                                                                            used: true
                                                                        }
                                                                    } else return wt
                                                                })

                                                                newWordsTo[index] = o

                                                                setWordsTo(newWordsTo)
                                                                handleToParent(wordsFrom, newWordsTo)
                                                                setWordTranslations(newWordTranslations)
                                                            }
                                                        }}>
                                                            {o}<IoIosArrowForward/>
                                                        </span>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        
                        {(index !== 0)?(
                            <div  className="removePart">
                                <IoIosRemoveCircleOutline className="icon" onClick={deleteWords} data-index={index}/>
                            </div>
                        ):(
                            <div className="addPart">
                                <IoIosAddCircleOutline className="icon" onClick={addWords} data-index={index}/>
                            </div>
                        )}
                    </div>
                ))}
                <div className="add-more" onClick={addWords}>
                    <span><Trans>Pridaj nové slovíčko</Trans></span>
                </div>
            
                {(props.isValid)?(null):(
                    <InformationBox type="warning" showClose={false} showBell={true}>
                        <Trans>Pre pokračovanie  musíte vyplniť všetky polia</Trans>
                    </InformationBox>
                )}

                {(!allSpecial)?(
                    <InformationBox type="warning" showClose={false} showBell={true}>
                        <Trans>Zadali ste rovnaké slovíčka. Upravte, alebo odstráňte duplicity.</Trans>
                    </InformationBox>
                ):(null)}
            </div>
        </div>
    )
}

export default AddWordRepeater