import { Redirect, Route } from 'react-router';
import React from 'react';
import { store } from '../../store';
import config from '../../config';
import { IApplicationStore } from '../../constants/store-interfaces';
import { useSelector } from 'react-redux';

export const PrivateRoute = ({component: Component, ...rest}: any) => {

    const { requiredRole } = rest
    const state = store.getState();
    const user = useSelector((store: IApplicationStore)=>store.globalStore.userInfo)

    let isAuthenticated = state.globalStore.userLoggedIn

    let userRoles = [config.roles.user]

    if(user?.role.name === config.roles.teacher) {
        userRoles.push(config.roles.teacher)
    }

    if(user?.role.name === config.roles.admin) {
        userRoles.push(config.roles.user)
    }


    if(userRoles.includes(requiredRole)) {
        return (<Route
            {...rest}
            render={(props:any) => {
                if(isAuthenticated) {
                    return (
                        <Component {...props} />
                    )
                } else {
                    return (
                        <Redirect
                            to={{
                                pathname:"/login",
                                state: { from: props.location}
                            }}
                        />
                    )
                }
            }}
        />)
    } else {
        return(<Route
            {...rest}
            render={(props:any) => {
                if(isAuthenticated) {
                    return(<Redirect
                        to={{
                            pathname:"/",
                            state: { from: props.location}
                        }}
                    />)
                } else {
                    return (
                        <Redirect
                            to={{
                                pathname:"/login",
                                state: { from: props.location}
                            }}
                        />
                    )
                }
            }}
        />)
    }
};