import React, { useState, useEffect } from 'react';
import { ReactourStep } from 'reactour'
import { Trans } from '@lingui/macro';
import InformationBox from '../InformationBox';
import { useSelector, useDispatch } from 'react-redux';
import { IApplicationStore } from '../../../../constants/store-interfaces';
import { IUSerInfo } from '../../../../constants/types';
import TutorialContainer from './TutorialContainer';
import TutorialButton from './TutorialButton';
import { useHistory } from 'react-router-dom';
import { setTutorial } from '../../../../actions/global/setTutorial';
import { setShowCreateWordGroupModal, setShowTutorial } from '../../../../actions/modals/setShowModal';
import AuthUserWrapper from '../../containers/Authentification/AuthUserWrapper';
import AuthTeacherWrapper from '../../containers/Authentification/AuthTeacherWrapper';
import ModalContainer from '../../containers/ModalContainer';
import { i18n } from '../../../../App';
import ModalHeader from '../../containers/ModalElements/ModalHeader';
import ModalBody from '../../containers/ModalElements/ModalBody';
import ModalFooter from '../../containers/ModalElements/ModalFooter';

interface IProps {
}

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    }
}

const TourDashboard: React.FC<IProps> = props => {
    const dispatch = useDispatch()

    const userInfo = useSelector<IApplicationStore, IUSerInfo | null>(state => state.globalStore.userInfo)
    const showTour = useSelector<IApplicationStore, boolean>(state => state.modalsStore.showTutorial)

    const history = useHistory()

    const [showModalInfo, setShowModalInfo] = useState(false)
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

    useEffect(() => {
        if((userInfo !== null) && (userInfo.training_status === 0)) {
            dispatch(setShowTutorial(true))
        }
    }, [dispatch, userInfo])

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions())
        }
    
        window.addEventListener('resize', handleResize)
        
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    useEffect(() => {
        if(windowDimensions.width < 768) {
            document.body.style.overflow = 'auto'
        } else {
            document.body.style.overflow = showTour?"hidden":'auto'
        }
    }, [showTour, windowDimensions])

    if(userInfo === null) {
        return (null)
    }

    const hideTour = () => {
        dispatch(setTutorial())
        setShowModalInfo(true)
        dispatch(setShowTutorial(false))
    }

    const startApp = () => {
        hideTour()
        dispatch(setShowCreateWordGroupModal(true))
    }

    const teacherSteps:ReactourStep[] = [
        {
            content: (
                <div>
                    <h2><Trans>Vitaj</Trans> {userInfo.f_name}!</h2>
                    <hr/>
                    <p><Trans>Práve ste sa dostali k aplikácii Alphie, ktorá Vám pomôže zlepšiť slovnú zásobu Vaších žiakov, zvýšiť ich záujem o učenie slovíčok.</Trans></p>
                    <p><Trans>Aplikácia aj Vám umožňuje učenie slovíčok. Jednoducho si vytvoríte vlastné skupiny slovíčok, aby ste zlepšili svoju vlastnú slovnú zásobu.</Trans></p>
                    <InformationBox type='info' showBell={true} showClose={false}><Trans>Po prejdení tohto jednoduchého tutoriálu získate základný prehľad, ako sa pohybovať v aplikácii</Trans> <strong>Alphie</strong>.</InformationBox>
                    <InformationBox type='warning' showBell={true} showClose={false}><Trans>Tutoriál môžete kedykoľvek ukončiť krížikom v jeho pravom hornom rohu</Trans></InformationBox>
                </div>
            ),
            action: node => {
                if(history.location.pathname !== '/app') {
                    history.push('/app')
                }
            }
        },
        {
            selector: '[data-tour="step1"]',
            content: (
                <div>
                    <h3><Trans>Hlavné menu</Trans></h3>
                    <hr/>
                    <p><Trans>Umožňuje základné pohybovanie v aplikácii. V spodnej časti sa Vám zobrazí zoznam Vašich tried pre rýchly prístup.</Trans></p>
                </div>
            )
        },
        {
            selector: '[data-tour="step2"]',
            content: (
                <div>
                    <h3><Trans>Rýchle voľby</Trans></h3>
                    <hr/>
                    <p><Trans>Umožňuje rýchle vytváranie slov, tried a vlastných cvičení.</Trans></p>
                </div>
            )
        },
        {
            selector: '[data-tour="step3"]',
            content: (
                <div>
                    <h3><Trans>Menu používateľa</Trans></h3>
                    <hr/>
                    <p><Trans>Odhlásenie používateľa a rýchly prístup k Vaším nastaveniam.</Trans></p>
                </div>
            )
        },
        {
            selector: '[data-tour="step4"]',
            content: (
                <div>
                    <h2><Trans>Stránka prehľad</Trans></h2>
                    <p><Trans>Aktuálne sa nachádzame na domovskej obrazovke celej aplikácie, ktorá obsahuje stručný prehľad o Vás a Vašich aktivitách.</Trans></p>
                    <p><Trans>Prejdeme si jej jednotlivé časti</Trans></p>
                </div>
            )
        },
        {
            selector: '[data-tour="step5"]',
            content: (
                <div>
                    <h3><Trans>Prehľad vlastných štatistík</Trans></h3>
                    <ul>
                        <li>
                            <Trans>po vykonaní testov získavate body, na základe ktorých sa pohybujete v rebríčkoch</Trans>
                        </li>
                        <li>
                            <Trans>môžete sem vidieť Vaše aktuálne umiestnenie vo svete a vo svojej krajine</Trans>
                        </li>
                    </ul>
                </div>
            )
        },
        {
            selector: '[data-tour="step6"]',
            content: (
                <div>
                    <h3><Trans>Prehľad mojich tried</Trans></h3>
                    <p><Trans>Keďže ste učiteľom, môžete zefektívniť výučbu svojích žiakov prostredníctvom vytvorenia tried, do ktorých pozvete svojich žiakov.</Trans></p>
                    <InformationBox type="info" showBell={true} showClose={false}><Trans>Žiakom viete priradzovať slovíčka na učenie, priradzovať im domáce úlohy alebo zadávať písomky</Trans></InformationBox>
                    <p><Trans>Po tom, čo si vytvoríte vlastnú triedu na tomto mieste budete vidieť prehľad všetkých Vašich tried s priradenými domácimi úlohami a písomkami.</Trans></p>
                </div>
            )
        },
        {
            selector: '[data-tour="step7"]',
            content: (
                <div>
                    <h3><Trans>Najlepší z najlepších</Trans></h3>
                    <p><Trans>Vykonávaním cvičení získavate Vy, ale aj Vaši žiaci body, ktoré sa zarátavajú do rebríčkov. Na tomto mieste vidíte najlepších používateľov vo vašej krajine a vo svete.</Trans></p>
                </div>
            )
        },
        {
            selector: '[data-tour="step8"]',
            content: (
                <div>
                    <h3><Trans>Vaša aktivita</Trans></h3>
                    <p><Trans>Na tomto mieste vidíte Vašu poslednú aktivitu. Či už ste vyplnili nejaké cvičenie, prípadne si vytvorili novú skupinu slovíčok, na tomto mieste nájdete tieto informácie.</Trans></p>
                </div>
            ),
            action: node => {
                if(history.location.pathname === '/app/wordgroups') {
                    history.push('/app')
                }
            }
        },
        {
            selector: '[data-tour="step9"]',
            content: (
                <div>
                    <h2><Trans>Stránka všetkých skupín slovíčok</Trans></h2>
                    <p><Trans>Na tomto mieste máte zhromaždené všetky Vaše skupiny slovíčok</Trans></p>
                </div>
            ),
            action: node => {
                if(history.location.pathname === '/app') {
                    history.push('/app/wordgroups')
                }
            }
        },
        {
            selector: '[data-tour="step10"]',
            content: (
                <div>
                    <h2><Trans>Zoznam skupín slovíčok</Trans></h2>
                    <p><Trans>Na tomto mieste môžete vidieť svoje vytvorené skupiny slovíčok</Trans></p>
                    <p><Trans>Slovíčka viete triediť do ľubovoľných skupín, ktorých názvy sú len na Vás.</Trans></p>
                    <p><Trans>Tieto skupiny slovíčok môžete použiť len pre seba na svoje učenie, prípadne ich môžete priradiť svojim triedam a následne z nich môžete žiakom vytvárať domáce úlohy alebo písomky.</Trans></p>
                    <p><Trans>Príklady použitia:</Trans></p>
                    <ul>
                        <li>
                            <Trans>Slovíčka podľa učebnice - 1. lekcia, 2. lekcia</Trans>
                        </li>
                        <li>
                            <Trans>Slovíčka podľa kategórie - príroda, cestovanie, škola</Trans>
                        </li>
                        <li>
                            <Trans>Ľubovoľné iné názvy - Moje technické slovíčka, Slovíčka na varenie</Trans>
                        </li>
                    </ul>
                    <InformationBox type="info" showBell={true}>
                        <Trans>Skupiny slovíčok sú základom pre úspešné učenie slovíčok. Zo skupín slovíčok si viete jednoducho vytvárať cvičenia, pomocou ktorých si zlepšujete svoju slovnú zásobu.</Trans>
                    </InformationBox>
                </div>
            ),
        },
        {
            selector: '.add-new',
            content: (
                <div>
                    <h3><Trans>Pridávanie skupín slovíčok</Trans></h3>
                    <p><Trans>Prostredníctvom tohto tlačidla viete jednoducho pridať nové skupiny slovíčok</Trans></p>
                    <p><Trans>Pri pridávaní skupín slovíčok je nutné zadať</Trans></p>
                    <ul>
                        <li>
                            <Trans>jazyk slovíčok a prekladu, ktoré budú vo vytvorenej skupine</Trans>
                        </li>
                        <li>
                            <Trans>zadať aspoň jedno slovíčko</Trans>
                        </li>
                    </ul>
                </div>
            ),
            action: node => {
                if(history.location.pathname === '/app/words') {
                    history.push('/app/wordgroups')
                }
            }
        },
        {
            selector: '[data-tour="step11"]',
            content: (
                <div>
                    <h2><Trans>Stránka všetkých slovíčok</Trans></h2>
                    <p><Trans>Na tomto mieste máte zhromaždené všetky Vaše slovíčka, zo všetkých skupín ktoré ste si vytvorili.</Trans></p>
                </div>
            ),
            action: node => {
                if(history.location.pathname === '/app/wordgroups') {
                    history.push('/app/words')
                }
            }
        },
        {
            selector: '[data-tour="step13"]',
            content: (
                <div>
                    <h3><Trans>Pridávanie slovíčok</Trans></h3>
                    <p><Trans>Prostredníctvom tohto tlačidla viete jednoducho pridať slovíčka</Trans></p>
                    <p><Trans>Pri pridávaní slovíčok je nutné zadať</Trans></p>
                    <ul>
                        <li>
                            <Trans>jazyk slovíčka a jeho prekladu</Trans>
                        </li>
                        <li>
                            <Trans>vybrať skupinu, do ktorej budú tieto slovíčka patriť</Trans>
                        </li>
                    </ul>
                    <InformationBox type="info" showBell={true}><Trans>Slovíčka sa následne viete učiť pomocou cvičení</Trans></InformationBox>
                </div>
            )
        },
        {
            selector: '[data-tour="step14"]',
            content: (
                <div>
                    <h3><Trans>Štatistika slovíčok</Trans></h3>
                    <p><Trans>Na tomto mieste uvidíte svoju štatistiku úspešnosti. Slovíčka s ktorými máte problém ale aj tie ktoré sú pre vás ľahké. Viete tak jednoducho upraviť svoje učenie tak, aby ste dosiahli čo najlepšie výsledky a zdokonalili sa v slovíčkach, ktoré Vám robia problém.</Trans></p>
                    <InformationBox type="info" showBell={true}><Trans>Štatistika sa generuje na základe výsledkov s Vašich cvičení</Trans></InformationBox>
                </div>
            ),
            action: node => {
                if(history.location.pathname === '/app/trainings') {
                    history.push('/app/words')
                }
            }
        },
        {
            selector: '[data-tour="step15"]',
            content: (
                <div>
                    <h2><Trans>Moje cvičenia</Trans></h2>
                    <p><Trans>Aj keď ste učiteľ, nič Vám nebráni vzdelávať sám seba a posúvať sa dopredu. Slovíčka sa viete učiť prostredníctvom cvičení. Na tomto mieste máte všetky Vaše doteraz vytvorené cvičenia na jednom mieste.</Trans></p>
                    <InformationBox type="info" showBell={true}><Trans>Ak si vytvoríte cvičenie z viacerých slovíčok, a toto cvičenie opakujete viac krát, môžete vidieť priebeh Vášho učenia v prehľadných grafoch.</Trans></InformationBox>
                </div>
            ),
            action: node => {
                if(history.location.pathname === '/app/words') {
                    history.push('/app/trainings')
                }
            }
        },
        {
            selector: '.add-new',
            content: (
                <div>
                    <h3><Trans>Vytvorenie cvičenia/testu/domácej úlohy</Trans></h3>
                    <p><Trans>Pre lepšie učenie môžete vytvárať cvičenia. Prostredníctvom cvičení si zlepšujete svoje znalosti z vybraných slov.</Trans></p>
                    <p><Trans>Svojim cvičeniam môžete zadať náročnosť (ľahkú, strednú alebo ťažkú). Na výber je viacero typov cvičení:</Trans></p>
                    <ul>
                        <li>
                            <Trans>Výber z možností</Trans> - <Trans>Učenie slovíčok prebieha formou výberu z viacerých ponúkaných možností. Podľa náročnosti sú slová ktoré máte na výber príbuznejšie správnej odpovedi (ťažká) alebo sa jedná o rôzne slovíčka (ľahká)</Trans>
                        </li>
                        <li>
                            <Trans>Skladanie slov</Trans> - <Trans>Slovíčka skladáte so zobrazených písmen. Podľa náročnosti je týchto písmen viac (ťažká) alebo menej (ľahká)</Trans>
                        </li>
                        <li>
                            <Trans>Dopĺňanie slov</Trans> - <Trans>Slovíčka musíte ručne zadať. Podľa zadanej náročnosti dopĺňate celé slová, prípadne iba časti slov.</Trans>
                        </li>
                        <li>
                            <Trans>Náhodný typ</Trans> - <Trans>Pri tomto type sa slovíčka učíte rôznymi spôsobmi. Otázky dostávate rôzne, buď sa jedná o skladanie slovíčok z písmen, prípadne dopĺňanie slovíčok alebo výber slovíčok z viacerých možností.</Trans>
                        </li>
                    </ul>
                    <InformationBox type="info" showBell={true}><Trans>Každému cvičeniu môžete dať špecifický názov, ktorý Vám zabezpečí, že vo svojom učení budete mať prehľad. Opakovaním cvičení budete vidieť svoj priebeh učenia a slovíčka ktoré Vám robia problémy.</Trans></InformationBox>
                </div>
            )
        },
        {
            content: (
                <div>
                    <h3><Trans>Body za cvičenia</Trans></h3>
                    <p><Trans>Vypĺňaním cvičení môžete aj Vy získavať body do rebríčka. Za každé cvičenie získate istý počet bodov, ktoré sa Vám zarátavajú do rebríčka.</Trans></p>
                    <p><Trans>Čím viac sa učíte, tým viac bodov získate a tým viac zlepšíte svoju slovnú zásobu.</Trans></p>
                    <InformationBox type="info" showBell={true}><Trans>Čím náročnejšie cvičenie si vytvoríte, tým viac bodov získate!</Trans></InformationBox>
                </div>
            ),
            action: node => {
                if(history.location.pathname === '/app/classes') {
                    history.push('/app/trainings')
                }
            }
        },
        {
            selector: '[data-tour="step17"]',
            content: (
                <div>
                    <h2><Trans>Zoznam tried</Trans></h2>
                    <p><Trans>Ako sme spomínali v predošlých krokoch, môžete si vytvoriť triedy, do ktorých pozvete svojich žiakov. Zoznam týchto tried uvidíte na tomto mieste.</Trans></p>
                    <p><Trans>Triedam viete priradiť Vami vytvorené skupiny slovíčok a vytvárať im z nich domáce úlohy alebo písomky, podobne ako si vytvárate cvičenia pre Vás.</Trans></p>
                    <p><Trans>Domáce úlohy vedia žiaci vypĺňať donekonečna a trénovať si tak svoju slovnú zásobu. Vy získate prehľad ktorí žiaci domácu úlohu splnili, či to bolo na poslednú chvíľu, alebo poctivo cvičili slovíčka celé dni.</Trans></p>
                    <p><Trans>Písomkami získate prehľad, koľko sa toho Vaši žiaci naplnili. Keďže písomku môžu vyplniť len raz, viete ich jednoducho oznámkovať.</Trans></p>
                    <InformationBox type="info" showBell={true}><Trans>Vytváraním domácich úloh pre žiakov získate prehľad o ich aktivite. Vidíte koľko bodov získali v domácich úlohach. Žiaci vidia svoje porovnanie so spolužiakmi a motivujú sa navzájom.</Trans></InformationBox>
                </div>
            ),
            action: node => {
                if(history.location.pathname === '/app/trainings') {
                    history.push('/app/classes')
                }
            }
        },
        {
            content: (
                <div>
                    <h3><Trans>Pozývanie žiakov do tried</Trans></h3>
                    <p><Trans>Každá vytvorená trieda má unikátny kód, ktorý je prihlasovacím kľúčom do Vašej vytvorenej triedy. Žiakov môžete pozvať do triedy viacerými spôsobmi:</Trans></p>
                    <ul>
                        <li><Trans>Odpíšete si kód, a na hodine ho žiakom poviete. Žiaci sa musia do aplikácie zaregistrovať a tento kód uviesť pri registrácii.</Trans></li>
                        <li><Trans>V detaile vytvorenej triede máte možnosť "pozvať žiakov do triedy". Na tomto mieste si môžete skopírovať odkaz ktorý zazdieľate svojim žiakom prostredníctvom komunikačného kanála, kde ste spojení (e-mail, Facebook, ...).</Trans></li>
                    </ul>
                    <InformationBox type="info" showBell={true}><Trans>V prípade ak kód Vašej triedy využil žiak ktorý do Vašej triedy nepatrí viete ho jednoducho odstrániť. Prípadne viete zmeniť kód triedy na nový.</Trans></InformationBox>
                </div>
            )
        },
        {
            selector: '.add-new',
            content: (
                <div>
                    <h3><Trans>Vytvorenie novej triedy</Trans></h3>
                    <p><Trans>Vytvoriť triedu je veľmi jednoduché, stačí zadať názov triedy.</Trans></p>
                    <InformationBox type="info" showBell={true}><Trans>V prípade potreby môžete zadať poznámku, ktorá Vám identifikuje triedu. Táto poznámka je viditeľná len pre Vás a nie pre žiakov</Trans></InformationBox>
                </div>
            ),
            action: node => {
                if(history.location.pathname === '/app') {
                    history.push('/app/classes')
                }
            }
        },
        {
            content: (
                <div>
                    <h1><Trans>Poďme do toho!</Trans></h1>
                    <p><Trans>Gratulujeme! Úspešne ste ukončili tutoriál a je čas pustiť sa do učenia slovíčok</Trans></p>
                    <p><Trans>Krátka rekapitulácia:</Trans></p>
                    <ol>
                        <li><Trans>Vytvoriť si skupinu slovíčok</Trans></li>
                        <li><Trans>Začať sa učiť slovíčka prostredníctvom cvičení</Trans></li>
                        <li><Trans>Vytvoriť triedu a pozvať do nej žiakov</Trans></li>
                        <li><Trans>Zadať im skupiny slovíčok na učenie</Trans></li>
                        <li><Trans>Zadávať žiakom domáce úlohy a písomky</Trans></li>
                        <li><Trans>... baviť sa :)</Trans></li>
                    </ol>
                    <InformationBox type="info" showBell={true}>
                        <Trans>Keby ste si chceli tutoriál zopakovať, stačí kliknúť na "otáznik" v pravom hornom rohu. Ak by ste mali ďalšie otázky, alebo si nevedeli rady, v pravom dolnom rohu využite možnosť kontaktu. Radi Vám pomôžeme!</Trans>
                    </InformationBox>

                    <h3><Trans>Tím aplikácie Alphie :)</Trans></h3>
                    <br/>
                    <div className="bttn _green" onClick={() => startApp()}><Trans>Poďme začať!</Trans></div>
                </div>
            ),
            action: node => {
                if(history.location.pathname === '/app/classes') {
                    history.push('/app')
                }
            }
        }
    ]

    const userSteps:ReactourStep[] = [
        {
            content: (
                <div>
                    <h2><Trans>Vitaj</Trans> {userInfo.f_name}!</h2>
                    <hr/>
                    <p><Trans>Práve si sa dostal k aplikácii Alphie, ktorá Ti pomôže zlepšiť Tvoju slovnú zásobu prostredníctvom moderných spôsobov učenia.</Trans></p>
                    <InformationBox type='info' showBell={true} showClose={false}><Trans>Po prejdení tohto jednoduchého tutoriálu získaš základný prehľad, ako sa pohybovať v aplikácii</Trans> <strong>Alphie</strong>.</InformationBox>
                    <InformationBox type='warning' showBell={true} showClose={false}><Trans>Tutoriál môžeš kedykoľvek ukončiť krížikom v jeho pravom hornom rohu</Trans></InformationBox>
                </div>
            ),
            action: node => {
                if(history.location.pathname !== '/app') {
                    history.push('/app')
                }
            }
        },
        {
            selector: '[data-tour="step1"]',
            content: (
                <div>
                    <h3><Trans>Hlavné menu</Trans></h3>
                    <hr/>
                    <p><Trans>Umožňuje základné pohybovanie v aplikácii. V spodnej časti sa Ti zobrazí zoznam Tvojich tried pre rýchle zobrazenie.</Trans></p>
                    <InformationBox showBell={true} type="info"><Trans>Ak si pri registrácii zadal registračný kód, ktorý si dostal od svojho učiteľa, v zozname tried by si mal vidieť svoju triedu.</Trans></InformationBox>
                </div>
            )
        },
        {
            selector: '[data-tour="step2"]',
            content: (
                <div>
                    <h3><Trans>Rýchle voľby</Trans></h3>
                    <hr/>
                    <p><Trans>Umožňuje rýchle vytváranie slov, skupín slov a vlastných cvičení.</Trans></p>
                </div>
            )
        },
        {
            selector: '[data-tour="step3"]',
            content: (
                <div>
                    <h3><Trans>Menu používateľa</Trans></h3>
                    <hr/>
                    <p><Trans>Odhlásenie používateľa a rýchly prístup k Vaším nastaveniam.</Trans></p>
                </div>
            )
        },
        {
            selector: '[data-tour="step4"]',
            content: (
                <div>
                    <h2><Trans>Stránka prehľad</Trans></h2>
                    <p><Trans>Aktuálne sa nachádzame na domovskej obrazovke celej aplikácie, ktorá obsahuje stručný prehľad o Tebe a Tvojich aktivitách.</Trans></p>
                    <p><Trans>Prejdeme si jej jednotlivé časti</Trans></p>
                </div>
            )
        },
        {
            selector: '[data-tour="step5"]',
            content: (
                <div>
                    <h3><Trans>Prehľad vlastných štatistík</Trans></h3>
                    <p>Počas učenia získavaš body, ktoré ťa zaradia do rebríčka najlepších. Body môžeš získať nasledovne:</p>
                    <ul>
                        <li>
                            <Trans>vykonávaním cvičení, ktoré si sám vytvoríš</Trans>
                        </li>
                        <li>
                            <Trans>za každé vyplnenie domácej úlohy (čím viac krát si precvičíš úlohu, tým viac bodov získaš)</Trans>
                        </li>
                    </ul>
                </div>
            )
        },
        {
            selector: '[data-tour="step6"]',
            content: (
                <div>
                    <h3><Trans>Prehľad mojich tried</Trans></h3>
                    <p><Trans>Ak si patríš do nejakej triedy, tak na tomto mieste môžeš vidieť prehľad tried do ktorých patríš. Na jednom mieste tak môžeš vidieť aktuálne platné domáce úlohy, čí písomky, ktoré môžeš vyplniť.</Trans></p>
                    <InformationBox type="info" showBell={true} showClose={false}><Trans>Ak sa ešte v triede nenachádzaš, neváhaj a oslov svojho učiteľa, aby začal používať aplikáciu Alphie pre Tvoju triedu.</Trans></InformationBox>
                </div>
            )
        },
        {
            selector: '[data-tour="step7"]',
            content: (
                <div>
                    <h3><Trans>Najlepší z najlepších</Trans></h3>
                    <p><Trans>Prehľad používateľov s najviac nazbieranými bodmi. Do budúcna na tomto mieste budú rôzne súťaže za rôzne atraktívne ceny.</Trans></p>
                    <InformationBox type="info" showBell={true}><Trans>Získaj viac bodov vypĺňaním cvičení s ťažkou náročnosťou</Trans></InformationBox>
                </div>
            )
        },
        {
            selector: '[data-tour="step8"]',
            content: (
                <div>
                    <h3><Trans>Tvoja aktivita</Trans></h3>
                    <p><Trans>Na tomto mieste vidíš svoju poslednú aktivitu. Či už si vyplnil nejaké cvičenie, domácu úlohu alebo písomku, prípadne si vytvoril novú skupinu slovíčok, na tomto mieste nájdeš tieto informácie.</Trans></p>
                </div>
            ),
            action: node => {
                if(history.location.pathname === '/app/wordgroups') {
                    history.push('/app')
                }
            }
        },
        {
            selector: '[data-tour="step9"]',
            content: (
                <div>
                    <h2><Trans>Stránka všetkých skupín slovíčok</Trans></h2>
                    <p><Trans>Na tomto mieste máš zhromaždené všetky skupiny slovíčok, či už sú to vytvorené alebo priradené skupiny slovíčok z Tvojich tried.</Trans></p>
                </div>
            ),
            action: node => {
                if(history.location.pathname === '/app') {
                    history.push('/app/wordgroups')
                }
            }
        },
        {
            selector: '[data-tour="step10"]',
            content: (
                <div>
                    <h2><Trans>Zoznam skupín slovíčok</Trans></h2>
                    <p><Trans>Na tomto mieste môžeš vidieť svoje vytvorené skupiny slovíčok</Trans></p>
                    <p><Trans>Slovíčka vieš triediť do ľubovoľných skupín, ktorých názvy sú len na Tebe.</Trans></p>
                    <p><Trans>Príklady použitia:</Trans></p>
                    <ul>
                        <li>
                            <Trans>Slovíčka podľa učebnice - 1. lekcia, 2. lekcia</Trans>
                        </li>
                        <li>
                            <Trans>Slovíčka podľa kategórie - príroda, cestovanie, škola</Trans>
                        </li>
                        <li>
                            <Trans>Ľubovoľné iné názvy - Moje technické slovíčka, Slovíčka na varenie</Trans>
                        </li>
                    </ul>
                    <InformationBox type="info" showBell={true}>
                        <Trans>Skupiny slovíčok sú základom pre úspešné učenie slovíčok. Zo skupín slovíčok si vieš vytvárať cvičenia, pomocou ktorých si zlepšuješ svoju slovnú zásobu.</Trans>
                    </InformationBox>
                </div>
            ),
        },
        {
            selector: '.add-new',
            content: (
                <div>
                    <h3><Trans>Pridávanie skupín slovíčok</Trans></h3>
                    <p><Trans>Prostredníctvom tohto tlačidla vieš jednoducho pridať nové skupiny slovíčok</Trans></p>
                    <p><Trans>Pri pridávaní skupín slovíčok je nutné zadať</Trans></p>
                    <ul>
                        <li>
                            <Trans>jazyk slovíčok a prekladu, ktoré budú vo vytvorenej skupine</Trans>
                        </li>
                        <li>
                            <Trans>zadať aspoň jedno slovíčko</Trans>
                        </li>
                    </ul>
                </div>
            ),
            action: node => {
                if(history.location.pathname === '/app/words') {
                    history.push('/app/wordgroups')
                }
            }
        },
        {
            selector: '[data-tour="step11"]',
            content: (
                <div>
                    <h2><Trans>Stránka všetkých slovíčok</Trans></h2>
                    <p><Trans>Na tomto mieste máš zhromaždené všetky slovíčka. Tie ktoré si si vytvoril ale aj tie ktoré máš priradené zo svojich tried.</Trans></p>
                </div>
            ),
            action: node => {
                if(history.location.pathname === '/app/wordgroups') {
                    history.push('/app/words')
                }
            }
        },
        {
            selector: '[data-tour="step13"]',
            content: (
                <div>
                    <h3><Trans>Pridávanie slovíčok</Trans></h3>
                    <p><Trans>Prostredníctvom tohto tlačidla vieš jednoducho pridať slovíčka</Trans></p>
                    <p><Trans>Pri pridávaní slovíčok je nutné zadať</Trans></p>
                    <ul>
                        <li>
                            <Trans>jazyk slovíčka a jeho prekladu</Trans>
                        </li>
                        <li>
                            <Trans>vybrať skupinu, do ktorej budú tieto slovíčka patriť</Trans>
                        </li>
                    </ul>
                    <InformationBox type="info" showBell={true}><Trans>Slovíčka sa následne vieš učiť pomocou cvičení</Trans></InformationBox>
                </div>
            )
        },
        {
            selector: '[data-tour="step14"]',
            content: (
                <div>
                    <h3><Trans>Štatistika slovíčok</Trans></h3>
                    <p><Trans>Na tomto mieste uvidíš svoju štatistiku úspešnosti. Slovíčka s ktorými máš problém ale aj tie ktoré sú pre Teba ľahké. Vieš tak jednoducho upraviť svoje učenie tak, aby si dosiahol čo najlepšie výsledky a zdokonalil sa v slovíčkach, ktoré Ti robia problém.</Trans></p>
                    <InformationBox type="info" showBell={true}><Trans>Štatistika sa generuje na základe výsledkov s Tvojich cvičení, domácich úloh a písomiek</Trans></InformationBox>
                </div>
            ),
            action: node => {
                if(history.location.pathname === '/app/trainings') {
                    history.push('/app/words')
                }
            }
        },
        {
            selector: '[data-tour="step15"]',
            content: (
                <div>
                    <h2><Trans>Moje cvičenia</Trans></h2>
                    <p><Trans>Slovíčka sa vieš učiť prostredníctvom cvičení. Na tomto mieste máš všetky Tvoje doteraz vytvorené cvičenia na jednom mieste.</Trans></p>
                    <InformationBox type="info" showBell={true}><Trans>Ak si vytvoríš cvičenie z viacerých slovíčok, a toto cvičenie opakuješ viac krát, môžeš vidieť priebeh svojho učenia v prehľadných grafoch.</Trans></InformationBox>
                </div>
            ),
            action: node => {
                if(history.location.pathname === '/app/words') {
                    history.push('/app/trainings')
                }
            }
        },
        {
            selector: '.add-new',
            content: (
                <div>
                    <h3><Trans>Vytvorenie cvičenia</Trans></h3>
                    <p><Trans>Svojim cvičeniam môžeš zadať náročnosť (ľahkú, strednú alebo ťažkú). Na výber je viacero typov cvičení:</Trans></p>
                    <ul>
                        <li>
                            <Trans>Výber z možností</Trans> - <Trans>Učenie slovíčok prebieha formou výberu z viacerých ponúkaných možností. Podľa náročnosti sú slová ktoré máte na výber príbuznejšie správnej odpovedi (ťažká) alebo sa jedná o rôzne slovíčka (ľahká)</Trans>
                        </li>
                        <li>
                            <Trans>Skladanie slov</Trans> - <Trans>Slovíčka skladáte so zobrazených písmen. Podľa náročnosti je týchto písmen viac (ťažká) alebo menej (ľahká)</Trans>
                        </li>
                        <li>
                            <Trans>Dopĺňanie slov</Trans> - <Trans>Slovíčka musíte ručne zadať. Podľa zadanej náročnosti dopĺňate celé slová, prípadne iba časti slov.</Trans>
                        </li>
                        <li>
                            <Trans>Náhodný typ</Trans> - <Trans>Pri tomto type sa slovíčka učíte rôznymi spôsobmi. Otázky dostávate rôzne, buď sa jedná o skladanie slovíčok z písmen, prípadne dopĺňanie slovíčok alebo výber slovíčok z viacerých možností.</Trans>
                        </li>
                    </ul>
                    <InformationBox type="info" showBell={true}><Trans>Každému cvičeniu môžeš dať špecifický názov, ktorý Ti zabezpečí, že vo svojom učení budeš mať prehľad. Opakovaním cvičení budeš vidieť svoj priebeh učenia a slovíčka ktoré Ti robia problémy.</Trans></InformationBox>
                </div>
            )
        },
        {
            content: (
                <div>
                    <h3><Trans>Body za cvičenia</Trans></h3>
                    <p><Trans>Ako si sa dozvedel na začiatku, vypĺňaním cvičení môžete získavaš body do rebríčka. Za každé cvičenie získaš istý počet bodov, ktoré sa Ti zarátavajú do rebríčka.</Trans></p>
                    <p><Trans>Čím viac sa učíš, tým viac bodov získaš a tým viac zlepšíš svoju slovnú zásobu.</Trans></p>
                    <InformationBox type="info" showBell={true}><Trans>Čím náročnejšie cvičenie si vytvoríš, tým viac bodov získaš!</Trans></InformationBox>
                </div>
            ),
            action: node => {
                if(history.location.pathname === '/app/classes') {
                    history.push('/app/trainings')
                }
            }
        },
        {
            selector: '[data-tour="step17"]',
            content: (
                <div>
                    <h2><Trans>Zoznam tried</Trans></h2>
                    <p><Trans>Na tomto mieste vidíš zoznam svojich tried do ktorých patríš. Či už je to trieda zo školy alebo z jazykovej školy, všetky vidíš na jednom mieste.</Trans></p>
                    <p><Trans>Prostredníctvom tried vieš od svojich učiteľov dostávať skupiny slovíčok na učenie, domáce úlohy alebo písomky.</Trans></p>
                    <p><Trans>Domáce úlohy v triede si vieš opakovať viackrát, získavať viac bodov a zlepšovať svoju slovnú zásobu. Tvoju aktivitu učiteľ vidí a vie ju náležite ohodnotiť.</Trans></p>
                    <p><Trans>Písomky sa dajú vyplniť len raz, tak sa poctivo uč, aby si dostal dobré hodnotenie!</Trans></p>
                    <InformationBox type="info" showBell={true}><Trans>Neváhaj a uč sa domáce úlohy ako drak! Za každú vyplnenú domácu úlohu získaš body do triedneho rebríčka. Porovnaj sa so svojimi spolužiakmi!</Trans></InformationBox>
                </div>
            ),
            action: node => {
                if(history.location.pathname === '/app/trainings') {
                    history.push('/app/classes')
                }
            }
        },
        {
            selector: '[data-tour="step18"]',
            content: (
                <div>
                    <h3><Trans>Pridanie sa do triedy</Trans></h3>
                    <p><Trans>Popýtaj si od svojho učiteľa registračný kód, pomocou ktorého sa vieš pridať do triedy.</Trans></p>
                    <InformationBox type="info" showBell={true}><Trans>Tvoj učiteľ nepoužíva aplikáciu Alphie? Prečo mu o nej nepovedať? ;)</Trans></InformationBox>
                </div>
            ),
            action: node => {
                if(history.location.pathname === '/app') {
                    history.push('/app/classes')
                }
            }
        },
        {
            content: (
                <div>
                    <h1><Trans>Poďme do toho!</Trans></h1>
                    <p><Trans>Gratulujeme! Úspešne si ukončil tutoriál a je čas pustiť sa do učenia slovíčok</Trans></p>
                    <p><Trans>Krátka rekapitulácia:</Trans></p>
                    <ol>
                        <li><Trans>Vytvoriť si skupinu slovíčok</Trans></li>
                        <li><Trans>Začať sa učiť slovíčka a získavať prvé body prostredníctvom cvičení</Trans></li>
                        <li><Trans>Pridať sa do triedy</Trans></li>
                        <li><Trans>Čakať na svojho učiteľa a prvé úlohy</Trans></li>
                        <li><Trans>... baviť sa :)</Trans></li>
                    </ol>
                    <InformationBox type="info" showBell={true}>
                        <Trans>Keby si si chcel tutoriál zopakovať, stačí kliknúť na "otáznik" v pravom hornom rohu. Ak by si mal ďalšie otázky, alebo si si nevedel rady, v pravom dolnom rohu využi možnosť kontaktu. Radi Ti pomôžeme!</Trans>
                    </InformationBox>

                    <h3><Trans>Tím aplikácie Alphie :)</Trans></h3>
                    <br/>
                    <div className="bttn _green" onClick={() => startApp()}><Trans>Poďme začať!</Trans></div>
                </div>
            ),
            action: node => {
                if(history.location.pathname === '/app/classes') {
                    history.push('/app')
                }
            }
        }
    ]

    if(windowDimensions.width < 768) {
        return null
    }

    return (
        <>
            <TutorialButton clickAction={() => dispatch(setShowTutorial(true))}/>
            <AuthTeacherWrapper>
                <TutorialContainer
                    showTutorial={showTour}
                    hideTutorial={() => hideTour()}
                    steps={teacherSteps}
                    pathname={history.location.pathname}
                />
            </AuthTeacherWrapper>
            <AuthUserWrapper>
                <TutorialContainer
                    showTutorial={showTour}
                    hideTutorial={() => hideTour()}
                    steps={userSteps}
                    pathname={history.location.pathname}
                />
            </AuthUserWrapper>

            <ModalContainer show={showModalInfo} customHide={() => setShowModalInfo(false)}>
                <ModalHeader title={i18n._('Kedykoľvek sa môžete vrátiť')}/>
                
                <ModalBody>
                    <div className="widget releases">
                        <div className="widget-body">
                            <div className="_part">
                                <p>
                                    <Trans>Stačí kliknúť na symbol otáznika v hlavičke aplikácie.</Trans>
                                </p>
                            </div>
                        </div>
                    </div>
                    
                
                </ModalBody>
                <ModalFooter>
                    <div className="part">
                        <button className="bttn _full  href _green" onClick={() => setShowModalInfo(false)}><Trans>Rozumiem</Trans></button>
                    </div>
                </ModalFooter>
            </ModalContainer>
        </>
    )
}

export default TourDashboard