import React, {Component} from 'react';
import { connect } from 'react-redux';
import { IApplicationStore } from "../../../constants/store-interfaces";
import { RouteComponentProps, withRouter } from 'react-router';
import HomeWorkDetailUser from '../parts/tests/HomeWorkDetailUser';
import HomeWorkDetailTeacher from '../parts/tests/HomeWorkDetailTeacher';
import { IUSerInfo } from '../../../constants/types';
import config from '../../../config';
import ReactGA from 'react-ga';

interface IStateProps {
    userLoggedIn: boolean,
    userInfo: IUSerInfo | null
}

interface IDispatchProps {
}

const mapStateToProps = (state: IApplicationStore): IStateProps => ({
    userLoggedIn: state.globalStore.userLoggedIn,
    userInfo: state.globalStore.userInfo
})

const mapDispatchToProps = (dispatch: any): IDispatchProps => ({
})

type PathParamsType = {
    id: string,
    hwId: string
}

interface IProps extends IStateProps, IDispatchProps, RouteComponentProps<PathParamsType> {

}

interface IState {
    testId: number,
    classId: number
}

class HomeworkDetailInfoPage extends Component<IProps, IState> {

    constructor(props: IProps) {
        super(props)

        this.state = {
            testId: Number(this.props.match.params.hwId),
            classId: Number(this.props.match.params.id)
        }

        ReactGA.pageview('Domáca úloha - detail')
        
    }

    componentDidUpdate(prevProps: IProps) {
        if(this.props.match.params.hwId !== prevProps.match.params.hwId) {
            this.setState({
                ...this.state,
                testId: Number(this.props.match.params.hwId),
                classId: Number(this.props.match.params.id)
            })
        } else if(this.props.match.params.id !== prevProps.match.params.id) {
            this.setState({
                ...this.state,
                testId: Number(this.props.match.params.hwId),
                classId: Number(this.props.match.params.id)
            })
        }
    }

    render() {
        const { userInfo, userLoggedIn } = this.props
        const { testId, classId } = this.state

        if(userLoggedIn && (userInfo !== null) && (userInfo.role.name === config.roles.teacher)) {
            return (
                <HomeWorkDetailTeacher
                    hwId={testId}
                    classId={classId}
                />
            )
        }

        return (
            <HomeWorkDetailUser
                hwId={testId}
                classId={classId}
            />
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HomeworkDetailInfoPage))