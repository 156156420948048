import React, { useState, useRef } from 'react';
import { ISelectableFilterType, IFilterValueType } from '../TableContainer';
import useOnClickOutside from '../../../../hooks/useOnClickOutside';

interface IProps {
    filter: ISelectableFilterType,
    handleSelectFilter: (values: IFilterValueType[], id: number, value: string | null) => any
}

const TableFilter: React.FC<IProps> = props => {    
    const [ show, setShow ] = useState(false)

    const ref = useRef<HTMLDivElement>(null);

    useOnClickOutside(ref, () => {
        setShow(false)
    })

    const { filter } = props

    return (
        <div className={"filter " + (show?'__active':'')} onClick={() => setShow(!show)} ref={ref}>
            <div className="filter-type sort">
                <span>{filter.title}:</span>
                <div className="sort-content">
                    <i className="la la-angle-down"/>
                    <div className="sort-current">
                        {filter.filtered.map(
                            (filterName: string, filterIndex:  number) => {
                                let item = filter.values.find((filterValue: IFilterValueType)=>(filterValue.value === filterName))

                                if(typeof item !== 'undefined') {
                                    return (<span key={filterIndex}>{item.label}</span>)
                                } else {
                                    return (<span key={filterIndex}>{filterName}</span>)
                                }
                            }
                        )}
                        {(filter.filtered.length === 0)?(<span>{filter.placeholder}</span>):(null)}
                    </div>
                    <div className="sort-list">
                        <ul>
                            <li 
                                onClick={() => {
                                    setShow(false)
                                    props.handleSelectFilter(filter.values, filter.id, null)
                                }}
                            >
                                {filter.allValues}
                            </li>
                            {filter.values.map((filterValue: IFilterValueType, filterIndex: number) => (
                                <li 
                                    onClick={() => {
                                        setShow(false)
                                        props.handleSelectFilter(filter.values, filter.id, filterValue.value)
                                    }}
                                    key={filterIndex}
                                >
                                    {filterValue.label}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TableFilter