import { setLoadingCreatedWordGroups } from '../loadings/setLoading';
import config from '../../config';
import { handleErrors } from '../../utils/handleErrors';
import { 
    GET_WORDGROUPS_CREATED, 
    GET_WORDGROUPS_CREATED_STARTED, 
    GET_WORDGROUPS_CREATED_FAILED } from '../../constants/action-types';
import { store } from '../../store';

export function loadCreatedWordGroups() {
    return (dispatch: any) => {

        dispatch(setLoadingCreatedWordGroups(true))
        dispatch(loadCreatedWordGroupsStarted())

        return fetch(config.api.wordgroups_created, {
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + store.getState().globalStore.token
            }
            
        })
            .then(handleErrors)
            .then(res => res.json())
            .then(res => {
                dispatch(loadCreatedWordGroupsSuccess(res.data.created_wordgroups))

                dispatch(setLoadingCreatedWordGroups(false))
            })
            .catch((error: any)  => {
                dispatch(loadCreatedWordGroupsFailed(error))
                dispatch(setLoadingCreatedWordGroups(false))
            });
    };
}

export const loadCreatedWordGroupsStarted = () => ({
    type: GET_WORDGROUPS_CREATED_STARTED
});

export const loadCreatedWordGroupsSuccess = (data:any) => ({
    type: GET_WORDGROUPS_CREATED,
    payload: data
});

export const loadCreatedWordGroupsFailed = (error: any) => ({
    type: GET_WORDGROUPS_CREATED_FAILED,
    payload: { error }
});