import React, {Component} from 'react';
import { connect } from 'react-redux';
import { IApplicationStore } from '../../../../../constants/store-interfaces';
import { ILanguageType, IQuestionVariantFolding } from '../../../../../constants/types';
import ReactCountryFlag from 'react-country-flag';

interface IStateProps {

}

const mapStateToProps = (state: IApplicationStore) => ({
})

interface IDispatchProps {

}

const mapDispatchToProps = (dispatch: any) => ({
})

interface IProps extends IStateProps, IDispatchProps{
    question: IQuestionVariantFolding,
    languageFrom: ILanguageType,
    languageTo: ILanguageType,
    valid: boolean
}

interface IState {
}


class FoldingResult extends Component<IProps, IState> {

    render() {
        const { question, languageFrom, languageTo, valid } = this.props
        
        let answerFrom = (question.wType === 'to')?question.word.to:question.word.from
        let answerTo = (question.wType === 'to')?question.word.from:question.word.to
        let langNameFrom = (question.wType === 'to')?languageTo:languageFrom
        let langNameTo = (question.wType === 'to')?languageFrom:languageTo
        
        let modifiedResponse = question.response.replace(/_/g, ' ')

        return (
            <div className={"lang-result " + ((valid)?'_valid':'_invalid')}>
                <div className="langs-block">
                    <div className="langs-part">
                        <div className="lang">
                            <div className="part">
                                <ReactCountryFlag className="flag" countryCode={langNameFrom.flag_name} svg/>
                            </div>
                            <div className="part">
                                {answerFrom}
                            </div>
                        </div>
                    </div>
                    <div className="langs-part">
                        <div className="lang">
                            <div className="part">
                                <ReactCountryFlag className="flag" countryCode={langNameTo.flag_name} svg/>
                            </div>
                            <div className="part">
                                {(valid)?(null):(
                                    ((modifiedResponse.split(" ").length - 1) !== modifiedResponse.length)?(
                                        <span style={{ textDecorationLine: 'line-through' }}>{modifiedResponse}</span>
                                    ):(null)
                                )}
                                {answerTo}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FoldingResult)