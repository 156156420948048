import React, {Component} from 'react';
import { connect } from 'react-redux';
import { ITest, IUserGroupType, ITestResult, ETestType, ITableRowType, ITableCellType, ITableHeaderCellType, IActionType, ILanguageType, IUserBasic } from '../../../../constants/types';
import { IApplicationStore } from '../../../../constants/store-interfaces';
import getTestTypeString from '../../../simple/classes/getTestTypeString';
import TestClass from '../../../simple/classes/testClass';
import moment from 'moment';
import LoadingElement from '../../../simple/LoadingElement';
import { Trans } from '@lingui/macro';
import { NavLink } from 'react-router-dom';
import { i18n } from '../../../../App';
import IconTextWidget from '../../../simple/widgets/IconTextWidget';
import TranslateLanguages from '../../../simple/TranslateLanguages';
import PercentageSmallWidget from '../../../simple/widgets/PercentageSmallWidget';
import { loadUserGroup } from '../../../../actions/userGroups/loadUserGroup';
import { loadTestResultsUserGroup } from '../../../../actions/tests/loadTestResultsUserGroup';
import { loadUserGroupTests } from '../../../../actions/tests/loadUserGroupTests';
import TestDifficulty from '../../../simple/tableElements/TestDifficulty';
import TableContainer from '../../containers/TableContainer';
import { ReactNode } from 'react';
import CircularGraph from '../../../simple/widgets/CircularGraph';
import VoiceClass from '../../../simple/classes/voiceClass';
import ResultsGraphUsersWidget from '../../../simple/widgets/ResultsGraphUsersWidget';
import NumberText from '../../../simple/tableElements/NumberText';
import InfoModal from '../../../simple/InfoModal';
import ModalUserDetailHomework from '../modals/userGroup/ModalUserDetailHomework';
import DateText from '../../../simple/tableElements/DateText';
import NoMatchPage from '../../pages/NoMatchPage';

interface IStateProps {
    loadingCreated: boolean,
    loadingTests: boolean,
    loadingResults: boolean,
    createdUserGroups: IUserGroupType[],
    ugTests: {[key: number]: ITest[]},
    usergroupResults: {[key: number]: ITestResult[]}
}

interface IDispatchProps {
    loadCreatedUserGroup: typeof loadUserGroup,
    loadTestResultsUserGroup: typeof loadTestResultsUserGroup,
    loadUserGroupTests: typeof loadUserGroupTests
    
}

const mapStateToProps = (state: IApplicationStore): IStateProps => ({
    loadingCreated: state.loadingStore.loadingCreatedUserGroups,
    loadingTests: state.loadingStore.loadingAssignedTests,
    loadingResults: state.loadingStore.loadingTestResults,
    createdUserGroups: state.userGroupsStore.createdUserGroups,
    ugTests: state.testsStore.usergroupTests,
    usergroupResults: state.testsStore.usergroupResults
})

const mapDispatchToProps = (dispatch: any): IDispatchProps => ({
    loadCreatedUserGroup: (id: number) => dispatch(loadUserGroup(id)),
    loadTestResultsUserGroup: (id: number) => dispatch(loadTestResultsUserGroup(id)),
    loadUserGroupTests:(id: number) => dispatch(loadUserGroupTests(id))
})

interface IProps extends IStateProps, IDispatchProps {
    hwId: number,
    classId: number
}

interface IState {
    selectedUser: IUserBasic | null,
    showModalDetailUser: boolean
}

class HomeWorkDetailTeacher extends Component<IProps, IState> {

    constructor(props: any) {
        super(props)

        this.state = {
            selectedUser: null,
            showModalDetailUser: false
        }

        this.handleToggleUserModal = this.handleToggleUserModal.bind(this)
        this.openModalUser = this.openModalUser.bind(this)
        this.refreshData = this.refreshData.bind(this)
    }

    componentDidMount() {
        this.refreshData()
    }

    refreshData() {
        this.props.loadCreatedUserGroup(this.props.classId)
        this.props.loadTestResultsUserGroup(this.props.classId)
        this.props.loadUserGroupTests(this.props.classId)
    }

    handleToggleUserModal() {
        this.setState({
            ...this.state,
            showModalDetailUser: !this.state.showModalDetailUser
        })
    }

    openModalUser(id: number) {
        let userGroup = this.props.createdUserGroups.find(t => t.id === this.props.classId)

        if(typeof userGroup !== 'undefined') {
            let selected = userGroup.assigned_users.find(wg => wg.id === Number(id))

            if(typeof selected !== 'undefined') {
                this.setState({
                    ...this.state,
                    selectedUser: selected,
                    showModalDetailUser: true
                })
            }
        }
    }

    getRows(test:  ITest, results: ITestResult[]): ITableRowType[] {
        let rows: ITableRowType[]  = []

        if(typeof test !== 'undefined') {
            for (let i = 0; i < test.settings.words.length; i++) {
                const word = test.settings.words[i]

                let cells: ITableCellType[] = []

                let percentage = 0
                let percentageResult: ReactNode = (null)
                let valid = 0
                let total = 0

                results.forEach(r => {
                    if(r.result !== null) {
                        r.result.questions.forEach(q => {
                            if(
                                (q.word.from === word.from) &&
                                (q.word.to === word.to) 
                            ) {
                                if(q.valid) {
                                    valid += 1
                                }

                                total += 1
                            }
                        })
                    }
                })

                if(total > 0) {
                    percentage = Math.round(valid/total*100)
                    percentageResult = (<CircularGraph percentage={percentage}/>)
                }
    
                let availableVoiceFrom = true
                let availableVoiceTo = true
                let speechColumnFrom: ReactNode = (null)
                let speechColumnTo: ReactNode = (null)

                if(VoiceClass.getInstance(test.language_from.flag_name, '').isAvailable()) {
                    speechColumnFrom = (
                        <i className="la la-volume-up" onClick={() => this.playWord(word.from, test.language_from)} data-tooltip={i18n._('Prehrať výslovnosť')}/>
                    )
                } else {
                    speechColumnFrom = (
                        <i className="la la-frown-o" data-tooltip={i18n._('Výslovnosť nedostupná')}/>
                    )
                }
                
                if(VoiceClass.getInstance(test.language_to.flag_name, '').isAvailable()) {
                    speechColumnTo = (
                        <i className="la la-volume-up" onClick={() => this.playWord(word.to, test.language_to)} data-tooltip={i18n._('Prehrať výslovnosť')}/>
                    )
                } else {
                    speechColumnTo = (
                        <i className="la la-frown-o" data-tooltip={i18n._('Výslovnosť nedostupná')}/>
                    )
                }

                cells = [
                    {
                        content: (<div className={"word " + ((availableVoiceFrom)?'_voice':'')}>{word.from} {speechColumnFrom}</div>),
                        stringToOrder:  word.from,
                        filterString:  word.from,
                        filterByString:  true
                    },
                    {
                        content: (<div className={"word " + ((availableVoiceTo)?'_voice':'')}>{word.to} {speechColumnTo}</div>),
                        stringToOrder:  word.to,
                        filterString:  word.to,
                        filterByString: true
                    },
                    {
                        content: (percentageResult),
                        stringToOrder: percentage.toString(),
                        filterByString: true,
                        filterString: percentage.toString()
                    }
                ]

                rows.push({
                    cells: cells,
                    catchString: i.toString()
                })
            }
        }

        return rows
    }

    getHeaders(): ITableHeaderCellType[] {
        return  [
            {
                content: (<span><Trans>Slovo</Trans></span>),
                sortBy: false,
                sortable: true
            },
            {
                content: (<span><Trans>Preklad</Trans></span>),
                sortBy: false,
                sortable: true
            },
            {
                content: (<span><Trans>Úspešnosť</Trans></span>),
                sortBy: false,
                sortable: true,
                sortableType: 'number'
            }
        ]
    }

    getActions(): IActionType[] {
        return [
        ]
    }

    getRowsUsers(test:  ITest, results: ITestResult[], users: IUserBasic[] | undefined): ITableRowType[] {
        let rows: ITableRowType[]  = []

        if(typeof users === 'undefined') {
            return rows
        }

        users.forEach(user => {

            let cells: ITableCellType[] = []

            let percentage = 0
            let percentageResult: ReactNode = (null)
            let valid = 0
            let total = 0
            let latestDate = moment(new Date('1970-01-01Z00:00:00:000'))
            let latestDateObject = null

            results.forEach(r => {
                if(
                    (r.result !== null) &&
                    (r.user_id === user.id) &&
                    (r.test_id === test.id)
                ) {
                    r.result.questions.forEach(q => {
                        if(q.valid) {
                            valid += 1
                        }

                        total += 1
                    })

                    let date = (r.date_finished !== null)?r.date_finished:r.date_started

                    if(latestDate.isBefore(moment(date))) {
                        latestDate = moment(date)
                        latestDateObject = (<DateText date={latestDate.toString()} formatDate={'DD.MM.YYYY'} formatTime={'HH:mm'} title={i18n._('Posledná aktivita')}/>)
                    }
                }
            })

            if(total > 0) {
                percentage = Math.round(valid/total*100)
                percentageResult = (<CircularGraph percentage={percentage}/>)
            }

            let rateTitle = i18n._('bodov')

            if((valid > 1) && (valid <= 4)) {
                rateTitle = i18n._('body')
            } else if (valid === 1) {
                rateTitle = i18n._('bod')
            }
            
            cells = [
                {
                    content: (
                        <div className="part-text part-user _user-alt">
                            <div className="part avatar">
                                <div className="avatar-photo">
                                    {user.name.charAt(0)}{user.surname.charAt(0)}
                                </div>
                            </div>
                            <div className="part name">
                                {user.f_name}
                            </div>
                        </div>
                    ),
                    stringToOrder:  user.surname + user.name,
                    filterByString: false,
                    filterString: user.surname + user.name,
                    selectableFilter: true,
                    selectableName: i18n._('vybrať'),
                    selectableAllText: i18n._('Všetci'),
                    selectableTitle: i18n._('Žiak'),
                    selectableObject: [
                        {
                            object: (<span>{user.f_name}</span>),
                            value: user.surname + user.name
                        }
                    ]
                },
                {
                    content: (latestDateObject),
                    stringToOrder: user.login,
                    filterByString: false,
                    filterString: user.login,
                    hideClass: 'hide-s'
                },
                {
                    content: (
                        <NumberText text={rateTitle} number={valid} title={i18n._('Získané body')}/>
                    ),
                    stringToOrder:  valid.toString(),
                    filterByString: false,
                    filterString: valid.toString(),
                    hideClass: 'hide-m'
                },
                {
                    content: (percentageResult),
                    stringToOrder: percentage.toString(),
                    filterByString: true,
                    filterString: percentage.toString()
                },
                {
                    content: ( 
                        <div className="bttn _small _primary  _icon" onClick={() => this.openModalUser(user.id)}> 
                            <i className="la la-search-plus"/>
                        </div>
                    ),
                    stringToOrder:  user.login,
                    filterByString: true,
                    filterString: user.login
                }
            ]

            rows.push({
                cells: cells,
                catchString: user.id.toString()
            })
        })

        return rows

    }

    getHeadersUsers(): ITableHeaderCellType[] {
        return  [
            {
                content: (<span><Trans>Meno a priezvisko</Trans></span>),
                sortBy: true,
                sortable: true
            },
            {
                content: (<span><Trans>Posledná aktivita</Trans></span>),
                sortBy: false,
                sortable: true,
                hideClass: 'hide-s'
            },
            {
                content: (<span><Trans>Body</Trans></span>),
                sortBy: false,
                sortable: true,
                sortableType: 'number',
                hideClass: 'hide-m'
            },
            {
                content: (<span><Trans>Úspešnosť</Trans></span>),
                sortBy: false,
                sortable: true,
                sortableType: 'number'
            },
            {
                content: (<span><Trans>Detail</Trans></span>),
                sortBy: false,
                sortable: false
            }
        ]
    }

    getActionsUsers(): IActionType[] {
        return [
        ]
    }

    playWord = (word: string, language: ILanguageType) => {
        let voice: VoiceClass = VoiceClass.getInstance(language.flag_name, word)

        voice.speak(true)
    }

    render() {
        const { loadingCreated, loadingTests, loadingResults, createdUserGroups, ugTests, usergroupResults, hwId, classId } = this.props
        const { selectedUser, showModalDetailUser } = this.state

        let tests = ugTests[classId]
        let allResults = usergroupResults[classId]
        let userGroup = createdUserGroups.find(t => t.id === classId)

        if(typeof allResults === 'undefined') {
            allResults = []
        }

        if(typeof tests === 'undefined') {
            tests = []
        }

        let test = tests.find(t => t.id === hwId)

        if(((typeof test === 'undefined') || (typeof userGroup === 'undefined')) && !loadingCreated && !loadingTests && !loadingResults) {
            return (<NoMatchPage/>)
        }

        if(
            (typeof test === 'undefined') &&
            (typeof userGroup === 'undefined')
         ) {
            return (null)
        }
        
        const typeSelect: { label: string; value: string }[] = []

        Object.keys(ETestType).forEach(value => {
            typeSelect.push({
                label: getTestTypeString(ETestType[(value as ETestType)]),
                value: ETestType[(value as ETestType)]
            })
        })

        const typeValue = typeSelect.find(value => ((typeof test !== 'undefined') && (value.value === test.settings.type)))

        const results = allResults.filter(r => ((typeof test !== 'undefined') && (r.test_id === test.id)))
        
        let percentage:number = 0
        let totalPoints:number = 0

        if(results.length) {
            let maxPoints = 0

            results.forEach(tR => {
                if(typeof test !== 'undefined') {
                    let testObject = new TestClass(test, tR)
                    
                    totalPoints += testObject.getTestRate()
                    maxPoints += testObject.getMaximumRate()
                }
            })

            percentage = Math.round(Number(totalPoints)/maxPoints*100)
        }

        if(typeof test === 'undefined') {
            return (null)
        }

        //Table of words
        let headers: ITableHeaderCellType[] = this.getHeaders()
        let rows: ITableRowType[] = this.getRows(test, results)
        let actions: IActionType[] = this.getActions()

        //Table of users
        let headersUsers: ITableHeaderCellType[] = this.getHeadersUsers()
        let rowsUsers: ITableRowType[] = this.getRowsUsers(test, results, userGroup?.assigned_users)
        let actionsUsers: IActionType[] = this.getActionsUsers()
        
        let selectedUserResults: ITestResult[] = []

        if(selectedUser !== null) {
            selectedUserResults = results.filter(r => r.user_id === selectedUser.id)
        }
        
        return (
            <section id="mainSection">
                <LoadingElement loadingValue={loadingCreated || loadingResults || loadingTests} />

                <div className="mainSectionBody">
                
                    <div className="rows">
                        <div className="row">
                            <div className="row-part">
                                <div className="page-header">
                                    <div className="part">
                                        <h2>
                                            <Trans>Domáca úloha</Trans>{(typeof userGroup !== 'undefined')?' (' + userGroup.name + ')':''} - {test.name}
                                        </h2>
                                        <small><Trans>Platnosť:</Trans> {moment(test.valid_from).format('DD.MM.YYYY HH:mm')} - {(test.valid_until !== null)?moment(test.valid_until).format('DD.MM.YYYY HH:mm'):(null)}</small>
                                        <p><Trans>Detail domácej úlohy s výsledkami a štatistikami Vašich žiakov</Trans></p>
                                    </div>
                                    <div className="part">
                                        <NavLink to={"/app/classes/" + classId} target="_self" className="bttn _small _orange _icon" data-tooltip={i18n._("Späť do triedy")}>
                                            <i className="la la-angle-left"/>
                                        </NavLink>
                                        <div 
                                            className="bttn _small _primary _icon" 
                                            data-tooltip={i18n._("Obnoviť dáta")}
                                            onClick={() => this.refreshData()}
                                        >
                                            <i className="la la-repeat"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="row-part">
                                <div className="small-widgets">
                                    <ul>
                                        <li>
                                            <IconTextWidget
                                                showIcon={true}
                                                icon="la-gear"
                                            >
                                                <h3>{(typeof typeValue !== 'undefined')?typeValue.label:''}{(typeof test.settings.diacritic !== 'undefined')?(test.settings.diacritic)?', ' + i18n._('s diakritikou'):', ' + i18n._('bez diakritiky'):', ' + i18n._('bez diakritiky')} {(test.settings.time !== null)?'(' + test.settings.time + ' ' + i18n._('min') + ')':''}</h3>
                                                <p><Trans>Typ domácej úlohy</Trans></p>
                                            </IconTextWidget>
                                        </li>
                                        <li>
                                            <IconTextWidget
                                                showIcon={true}
                                                icon="la-area-chart"
                                            >
                                                <h3><TestDifficulty difficulty={test.settings.difficulty} /></h3>
                                                <p><Trans>Náročnosť</Trans></p>
                                            </IconTextWidget>
                                        </li>
                                        <li>
                                            <IconTextWidget
                                                showIcon={true}
                                                icon="la-flag"
                                            >
                                                <TranslateLanguages
                                                    languageFrom={test.language_from}
                                                    languageTo={test.language_to}
                                                />
                                            </IconTextWidget>
                                        </li>
                                        <li>
                                            <PercentageSmallWidget
                                                percentage={percentage}
                                            >
                                                <h3><Trans>Priemerná úspešnosť</Trans></h3>
                                            </PercentageSmallWidget>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="row-part">
                                <div className="test-content">
                                    <div className="mainSectionBody">
                                    
                                        <div className="rows">

                                            <div className="row">
                                                    <div className="row-part">
                                                        {(
                                                            (typeof userGroup !== 'undefined') && 
                                                            (typeof userGroup.assigned_users !== 'undefined') 
                                                        )?(
                                                            <ResultsGraphUsersWidget
                                                                results={results}
                                                                openModal={(r: ITestResult) => {
                                                                    if(typeof r.user_id !== 'undefined') {
                                                                        this.openModalUser(r.user_id)
                                                                    }
                                                                }}
                                                                users={userGroup?.assigned_users}
                                                            />
                                                        ):(null)}
                                                    </div>
                                                </div>
                                            <div className="row">
                                                <div className="row-part">
                                                    <div className="widget-holdeer">
                                                        <div className="widget-holder-header">
                                                            <div className="widget-part">
                                                                <h2><Trans>Slovíčka</Trans> ({test.settings.words.length}) <Trans>a priemerná úspešnosť všetkých žiakov</Trans></h2>
                                                            </div>
                                                        </div>
                                                        <div className="widget-holder-body">
                                                            <div className="widget-part">
                                                                <section className="widget">
                                                                    <div className="widget-body">
                                                                        <TableContainer
                                                                            headers={headers}
                                                                            rows={rows}
                                                                            actions={actions}
                                                                            showActions={false}
                                                                            showCheckbox={false}
                                                                            showFilter={false}
                                                                            pageSize={20}
                                                                            emptyText={i18n._('Táto domáca úloha neobsahuje žiadne slová')}
                                                                            showNew={false}
                                                                            showGrid={false}
                                                                            title={ i18n._('Slovíčka') }
                                                                        />
                                                                    </div>
                                                                </section>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row-part">
                                                    {(
                                                        (typeof userGroup !== 'undefined') && 
                                                        (typeof userGroup.assigned_users !== 'undefined') 
                                                    )?(
                                                        <div className="widget-holdeer">
                                                            <div className="widget-holder-header">
                                                                <div className="widget-part">
                                                                    <h2><Trans>Žiaci</Trans> ({userGroup.assigned_users.length}) <Trans>a ich výsledky</Trans></h2>
                                                                </div>
                                                            </div>
                                                            <div className="widget-holder-body">
                                                                <div className="widget-part">
                                                                    <section className="widget">
                                                                        <div className="widget-body">
                                                                            <TableContainer
                                                                                headers={headersUsers}
                                                                                rows={rowsUsers}
                                                                                actions={actionsUsers}
                                                                                showActions={false}
                                                                                showCheckbox={false}
                                                                                showFilter={true}
                                                                                pageSize={20}
                                                                                emptyText={i18n._('Vo Vašej triede zatiaľ nemáte žiadných žiakov')}
                                                                                showNew={false}
                                                                                showGrid={false}
                                                                                title={ i18n._('Žiaci') }
                                                                            />
                                                                        </div>
                                                                    </section>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ):(null)}
                                                </div>
                                            </div>
                                        
                                        </div>
                                    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                
                </div>

                {(selectedUser !== null)?(
                    <div>
                        <InfoModal
                            title={i18n._('Žiak') + ' - ' + selectedUser.f_name}
                            custom={true}
                            customShow={showModalDetailUser}
                            customShowCloseFunction={this.handleToggleUserModal}
                        >
                            <ModalUserDetailHomework
                                user={selectedUser}
                                test={test}
                                results={selectedUserResults}
                            />
                        </InfoModal>
                    </div>
                ):(null)}
            </section>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeWorkDetailTeacher)