import { setLoadingCreatedTests } from '../loadings/setLoading';
import config from '../../config';
import { handleErrors } from '../../utils/handleErrors';
import { MODIFY_TRAINING, MODIFY_TRAINING_FAILED, MODIFY_TRAINING_STARTED } from '../../constants/action-types';
import { store } from '../../store';
import { ITestCreate } from '../../constants/types';
import { addFloatingNotification } from '../notifications/floating';
import { i18n } from '../../App';
import { setShowEditTrainingModal } from '../modals/setShowModal';

export function editTraining(id: number, data:ITestCreate) {
    return (dispatch: any) => {

        dispatch(setLoadingCreatedTests(true))
        dispatch(editTrainingStarted())

        return fetch(config.api.tests + '/' + id, {
            method: 'PUT',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + store.getState().globalStore.token
            },
            body: JSON.stringify(data)
            
        })
            .then(handleErrors)
            .then(res => res.json())
            .then(res => {
                
                dispatch(setShowEditTrainingModal(false))

                dispatch(addFloatingNotification({
                    type:"success",
                    text:i18n._('Cvičenie ste úspešne premenovali'),
                    showBell: true
                }))
                
                dispatch(editTrainingSuccess({test: res.data}))
                dispatch(setLoadingCreatedTests(false))
                
            })
            .catch((error: any)  => {
                dispatch(editTrainingFailed(error))
                dispatch(setLoadingCreatedTests(false))
            });
    };
}

export const editTrainingStarted = () => ({
    type: MODIFY_TRAINING_STARTED
});

export const editTrainingSuccess = (data:  any) => ({
    type: MODIFY_TRAINING,
    payload: data
});

export const editTrainingFailed = (error: any) => ({
    type: MODIFY_TRAINING_FAILED,
    payload: { error }
});