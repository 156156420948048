import { 
    SET_LOADING_REGISTER,
    SET_LOADING_LOGIN,
    SET_LOADING_PAGE,
    SET_LOADING_LANGUAGES,
    SET_LOADING_ASSIGNED_WORDGROUPS,
    SET_LOADING_CREATED_WORDGROUPS,
    SET_LOADING_CREATED_WORDS,
    SET_LOADING,
    SET_LOADING_WORDGROUP,
    SET_LOADING_ASSIGNED_USERGROUPS,
    SET_LOADING_CREATED_USERGROUPS,
    SET_LOADING_CREATED_TESTS,
    SET_LOADING_TEST_RESULT,
    SET_LOADING_RANKING_COUNTRY,
    SET_LOADING_RANKING_WORLD,
    SET_LOADING_TEST_RESULTS,
    SET_LOADING_ASSIGNED_TESTS,
    SET_LOADING_GENERATE_TEST,
    SET_LOADING_RANKING_MONTH,
    RESET_STATE
} from "../constants/action-types"
import { Reducer } from 'redux'
import { ILoadingStore } from '../constants/store-interfaces';

const initialState: ILoadingStore = {
    loading: false,
    loadingLogin: false,
    loadingRegister: false,
    loadingPage: false,
    loadingLanguages: false,
    loadingWordGroup: false,
    loadingCreatedWordGroups: false,
    loadingAssignedWordGroups: false,
    loadingCreatedWords: false,
    loadingCreatedUserGroups: false,
    loadingAssignedUserGroups: false,
    loadingCreatedTests: false,
    loadingTestResult: false,
    loadingAssignedTests: false,
    loadingTestResults: false,
    loadingRankingCountry: false,
    loadingRankingWorld: false,
    loadingGenerateTest: false,
    loadingMonthRanking: false
}

const reducer: Reducer<ILoadingStore> = (state = initialState, action) => {
    let was = false

    switch(action.type) {
        //Set of loading elements
        case SET_LOADING:
            state = {
                ...state,
                loading: action.payload
            }
            was = true
            break
        //Set of loading page
        case SET_LOADING_PAGE:
            state = {
                ...state,
                loadingPage: action.payload
            }
            was = true
            break
        //Set loading register
        case SET_LOADING_REGISTER:
            state = {
                ...state,
                loadingRegister: action.payload
            }
            was = true
            break
        //Set loading login
        case SET_LOADING_LOGIN:
            state = {
                ...state,
                loadingLogin: action.payload
            }
            was = true
            break
        //Set loading created wordgroups
        case SET_LOADING_CREATED_WORDGROUPS:
            state = {
                ...state,
                loadingCreatedWordGroups: action.payload
            }
            was = true
            break
        //Set loading assigned wordgroups
        case SET_LOADING_ASSIGNED_WORDGROUPS:
            state = {
                ...state,
                loadingAssignedWordGroups: action.payload
            }
            was = true
            break
        //Set loading created words
        case SET_LOADING_CREATED_WORDS:
            state = {
                ...state,
                loadingCreatedWords: action.payload
            }
            was = true
            break
        //Set loading languages
        case SET_LOADING_LANGUAGES:
            state = {
                ...state,
                loadingLanguages: action.payload
            }
            was = true
            break
        //Set loading wordgroup
        case SET_LOADING_WORDGROUP:
            state = {
                ...state,
                loadingWordGroup: action.payload
            }
            was = true
            break
        //Set loading created usergroups
        case SET_LOADING_CREATED_USERGROUPS:
            state = {
                ...state,
                loadingCreatedUserGroups: action.payload
            }
            was = true
            break
        //Set loading assigned usergroups
        case SET_LOADING_ASSIGNED_USERGROUPS:
            state = {
                ...state,
                loadingAssignedUserGroups: action.payload
            }
            was = true
            break
        //Set loading created tests
        case SET_LOADING_CREATED_TESTS:
            state = {
                ...state,
                loadingCreatedTests: action.payload
            }
            was = true
            break
        //Set loading created test results
        case SET_LOADING_TEST_RESULT:
            state = {
                ...state,
                loadingTestResult: action.payload
            }
            was = true
            break
        //Set loading assigned tests
        case SET_LOADING_ASSIGNED_TESTS:
            state = {
                ...state,
                loadingAssignedTests: action.payload
            }
            was = true
            break
        //Set loading of test results for usergroup
        case SET_LOADING_TEST_RESULTS:
            state = {
                ...state,
                loadingTestResults: action.payload
            }
            was = true
            break
        //Set loading of ranking world
        case SET_LOADING_RANKING_WORLD:
            state = {
                ...state,
                loadingRankingWorld: action.payload
            }
            was = true
            break
        //Set loading of ranking country
        case SET_LOADING_RANKING_COUNTRY:
            state = {
                ...state,
                loadingRankingCountry: action.payload
            }
            was = true
            break
        //Set loading of generate test
        case SET_LOADING_GENERATE_TEST:
            state = {
                ...state,
                loadingGenerateTest: action.payload
            }
            was = true
            break
        //Set loading for ranking month
        case SET_LOADING_RANKING_MONTH:
            state = {
                ...state,
                loadingMonthRanking: action.payload
            }
            was = true
            break
        case RESET_STATE:
            state = initialState
            was = true
            break
    }

    if(action.type.endsWith("_FAILED") && was) {
        //TODO show error
    }

    return state
}

export { reducer as loadingReducer }