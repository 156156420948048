import React, {Component} from 'react';
import { connect } from 'react-redux';
import { IApplicationStore } from "../../../constants/store-interfaces";
import { ITableHeaderCellType, ITableRowType, ITableCellType, IActionType, IUWordGroupType, ITestResult, ILanguageType, ITest, ETestUgType } from '../../../constants/types';
import TableContainer from '../containers/TableContainer';
import { Trans } from '@lingui/macro';
import LoadingElement from '../../simple/LoadingElement';
import { i18n } from '../../../App';
import { withRouter } from 'react-router';
import {RouteComponentProps} from "react-router";
import TranslateLanguages from '../../simple/TranslateLanguages';
import IconTextWidget from '../../simple/widgets/IconTextWidget';
import { loadAssignedWordGroups } from '../../../actions/wordGroups/loadAssignedWordGroups';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import { loadTestResults } from '../../../actions/tests/loadTestResults';
import { loadTests } from '../../../actions/tests/loadTests';
import VoiceClass from '../../simple/classes/voiceClass';
import { ReactNode } from 'react';
import CircularGraph from '../../simple/widgets/CircularGraph';
import { loadAssignedTests } from '../../../actions/tests/loadAssignedTests';
import AssignedWordGroupTrainings from '../parts/userGroups/AssignedWordGroupTrainings';
import TabsContainer from '../containers/TabsContainer';
import ModalAddTestWordgroup from '../parts/modals/tests/ModalAddTestWordgroup';
import AssignedWordGroupHomeWorks from '../parts/userGroups/AssignedWordGroupHomeWorks';
import { setShowCreateTestWGModal } from '../../../actions/modals/setShowModal';
import ResultsGraphWidget from '../../simple/widgets/ResultsGraphWidget';
import NoMatchPage from './NoMatchPage';
import ReactGA from 'react-ga';


interface IStateProps {
    loading: boolean,
    loadingResults: boolean,
    loadingTests: boolean,
    loadingAssigned: boolean,
    testResults: ITestResult[],
    createdTests: ITest[],
    assignedTests: ITest[],
    assignedWordGroups: IUWordGroupType[]
}

interface IDispatchProps {
    loadAssignedWordGroups: typeof loadAssignedWordGroups,
    loadTestResults: typeof loadTestResults,
    loadTests: typeof loadTests,
    loadAssignedTests: typeof loadAssignedTests,
    showModalAddTest: typeof setShowCreateTestWGModal
}

const mapStateToProps = (state: IApplicationStore): IStateProps => ({
    loading: state.loadingStore.loadingCreatedWordGroups,
    loadingResults: state.loadingStore.loadingTestResult,
    loadingTests: state.loadingStore.loadingCreatedTests,
    loadingAssigned: state.loadingStore.loadingAssignedTests,
    testResults: state.testsStore.testResults,
    createdTests: state.testsStore.createdTests,
    assignedTests: state.testsStore.assignedTests,
    assignedWordGroups: state.wordGroupsStore.assignedWordgroups
})

const mapDispatchToProps = (dispatch: any): IDispatchProps => ({ 
    loadAssignedWordGroups: () => dispatch(loadAssignedWordGroups()),
    loadTestResults: () => dispatch(loadTestResults()),
    loadTests: () => dispatch(loadTests()),
    loadAssignedTests: () => dispatch(loadAssignedTests()),
    showModalAddTest: (val: boolean) => dispatch(setShowCreateTestWGModal(val))
})

type PathParamsType = {
    id: string,
}

interface IProps extends IStateProps, IDispatchProps, RouteComponentProps<PathParamsType> {

}

interface IState {
    wordGroupId: number,
    showResult: boolean,
    selectedResult: ITestResult | null
}

class WordGroupAssignedDetailPage extends Component<IProps, IState> {

    constructor(props: any) {
        super(props)

        this.state = {
            wordGroupId: Number(this.props.match.params.id),
            showResult: false,
            selectedResult: null
        }

        ReactGA.pageview('Skupina slovíčok - priradená detail')

        this.handleToggleResult = this.handleToggleResult.bind(this)
        this.showResultInfo = this.showResultInfo.bind(this)
    }

    componentDidMount() {
        this.props.loadAssignedWordGroups()
        this.props.loadTestResults()
        this.props.loadAssignedTests()
    }

    handleToggleResult() {
        this.setState({
            ...this.state,
            showResult: !this.state.showResult
        })
    }

    showResultInfo(tr: ITestResult) {
        this.props.loadTests()

        this.setState({
            ...this.state,
            selectedResult: tr,
            showResult: true
        })
    }

    playWord = (word: string, language: ILanguageType) => {
        let voice: VoiceClass = VoiceClass.getInstance(language.flag_name, word)

        voice.speak(true)
    }

    getRows(wordGroup:  IUWordGroupType | undefined): ITableRowType[] {
        let rows: ITableRowType[]  = []

        if(typeof wordGroup !== 'undefined') {
            for(let word of wordGroup.words) {
                let cells: ITableCellType[] = []

                let availableVoiceFrom = true
                let availableVoiceTo = true
                let speechColumnFrom: ReactNode = (null)
                let speechColumnTo: ReactNode = (null)

                if(VoiceClass.getInstance(wordGroup.language_from.flag_name, '').isAvailable()) {
                    speechColumnFrom = (
                        <i className="la la-volume-up" onClick={() => this.playWord(word.word_text, wordGroup.language_from)} data-tooltip={i18n._('Prehrať výslovnosť')}/>
                    )
                } else {
                    speechColumnFrom = (
                        <i className="la la-frown-o" data-tooltip={i18n._('Výslovnosť nedostupná')}/>
                    )
                }
                
                if(VoiceClass.getInstance(wordGroup.language_to.flag_name, '').isAvailable()) {
                    speechColumnTo = (
                        <i className="la la-volume-up" onClick={() => this.playWord(word.translation, wordGroup.language_to)} data-tooltip={i18n._('Prehrať výslovnosť')}/>
                    )
                } else {
                    speechColumnTo = (
                        <i className="la la-frown-o" data-tooltip={i18n._('Výslovnosť nedostupná')}/>
                    )
                }

                let percentage = 0
                let percentageResult: ReactNode = (null)
                let valid = 0
                let total = 0

                this.props.testResults.forEach(r => {
                    if(r.result !== null) {
                        r.result.questions.forEach(q => {
                            if(
                                (q.word.from === word.word_text) &&
                                (q.word.to === word.translation) 
                            ) {
                                if(q.valid) {
                                    valid += 1
                                }

                                total += 1
                            }
                        })
                    }
                })

                if(total > 0) {
                    percentage = Math.round(valid/total*100)
                    percentageResult = (<div data-title={i18n._('Úspešnosť')}><CircularGraph percentage={percentage}/></div>)
                }

                cells = [
                    {
                        content: (<div className={"word " + ((availableVoiceFrom)?'_voice':'')} data-title={i18n._('Slovo')}>{word.word_text} {speechColumnFrom}</div>),
                        stringToOrder:  word.word_text,
                        filterString:  word.word_text,
                        filterByString:  true
                    },
                    {
                        content: (<div className={"word " + ((availableVoiceTo)?'_voice':'')} data-title={i18n._('Preklad')}>{word.translation} {speechColumnTo}</div>),
                        stringToOrder:  word.translation,
                        filterString:  word.translation,
                        filterByString: true
                    },
                    {
                        content: (percentageResult),
                        stringToOrder: percentage.toString(),
                        filterByString: true,
                        filterString: percentage.toString()
                    }
                ]

                rows.push({
                    cells: cells,
                    catchString: word.id.toString()
                })
            }
        }

        return rows
    }

    getHeaders(): ITableHeaderCellType[] {
        return  [
            {
                content: (<span><Trans>Slovo</Trans></span>),
                sortBy: false,
                sortable: true
            },
            {
                content: (<span><Trans>Preklad</Trans></span>),
                sortBy: false,
                sortable: true
            },
            {
                content: (<span><Trans>Úspešnosť</Trans></span>),
                sortBy: false,
                sortable: true,
                sortableType: 'number'
            }
        ]
    }

    getActions(): IActionType[] {
        return [
        ]
    }

    render() {
        const { loading, loadingResults, loadingTests, loadingAssigned, assignedWordGroups, createdTests, assignedTests, testResults } = this.props
        const { wordGroupId } = this.state


        let wordGroup = assignedWordGroups.find((wordGroup: IUWordGroupType)  => wordGroup.id === wordGroupId)

        if((typeof wordGroup === 'undefined') && !loading && !loadingResults && !loadingTests && !loadingAssigned) {
            return (<NoMatchPage/>)
        }

        if((typeof wordGroup !== 'undefined' && moment(wordGroup.valid_from).isAfter(moment()))) {
            return (<NoMatchPage/>)
        }

        //Table of words
        let headers: ITableHeaderCellType[] = this.getHeaders()
        let rows: ITableRowType[] = this.getRows(wordGroup)
        let actions: IActionType[] = this.getActions()

        let wgTrainings: ITest[] = []
        let homeworks: ITest[] = []

        if(typeof wordGroup !== 'undefined') {
            createdTests.forEach(t => {
                let add = false

                t.settings.words.forEach(w => {

                    if(typeof wordGroup !== 'undefined') {
                        let word = wordGroup.words.find(wGw => (((wGw.word_text === w.from) && (wGw.translation === w.to)) || ((wGw.translation === w.from) && (wGw.word_text === w.to))))

                        if(typeof word !== 'undefined') {
                            add = true
                        }
                    }
                })

                if(add) {
                    wgTrainings.push(t)
                }
            })

            assignedTests.forEach(t => {
                let add = false

                t.settings.words.forEach(w => {

                    if(typeof wordGroup !== 'undefined') {
                        let word = wordGroup.words.find(wGw => (((wGw.word_text === w.from) && (wGw.translation === w.to)) || ((wGw.translation === w.from) && (wGw.word_text === w.to))))

                        if(typeof word !== 'undefined') {
                            add = true
                        }
                    }
                })

                if(add) {
                    if((typeof t.settings.ugType !== 'undefined') && (t.settings.ugType === ETestUgType.homework)) {
                        homeworks.push(t)
                    }
                }
            })
        }

        let name = i18n._('Skupina slovíčok')

        if(typeof wordGroup !== 'undefined') {
            if(wordGroup.usergroup !== null) {
                name += ' (' + wordGroup.usergroup.usergroup_name + ')'
            }

            name += ' - ' + wordGroup.name
        }

        return (
            <section id="mainSection">
                <LoadingElement loadingValue={loading} />

                <div className="mainSectionBody">
                
                    <div className="rows">
                        <div className="row">
                            <div className="row-part">
                                <div className="page-header">
                                    <div className="part">
                                        <h2>{name}</h2>
                                        <p><Trans>Zoznam priradených slovíčok a moja štatistika</Trans></p>
                                    </div>
                                    {(typeof wordGroup !== 'undefined')?(
                                        <div className="part">
                                            {(wordGroup.usergroup !== null)?(
                                                <NavLink to={"/app/classes/" + wordGroup.usergroup.usergroup_id} target="_self" className="bttn _small _orange _icon" data-tooltip={i18n._("Späť do triedy")}>
                                                    <i className="la la-angle-left"/>
                                                </NavLink>
                                            ):(null)}

                                            {((typeof wordGroup !== 'undefined') && (wordGroup?.words.length > 0))?(
                                                <div className="bttn _small _green _icon" onClick={() => this.props.showModalAddTest(true)}>Vytvoriť cvičenie</div>
                                            ):(null)}
                                        </div>
                                        
                                    ):(null)}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="row-part">
                                <div className="small-widgets">
                                    {(typeof wordGroup !== 'undefined')?(
                                        <ul>
                                            <li>
                                                <IconTextWidget
                                                    showIcon={true}
                                                    icon="la-align-justify"
                                                >
                                                    <h2>{wordGroup.words.length}</h2>
                                                    <p><Trans>počet slovíčok</Trans></p>
                                                </IconTextWidget>
                                            </li>
                                            <li>
                                                <IconTextWidget
                                                    showIcon={true}
                                                    icon="la-flag"
                                                >
                                                    <TranslateLanguages
                                                        languageFrom={wordGroup.language_from}
                                                        languageTo={wordGroup.language_to}
                                                    />
                                                </IconTextWidget>
                                            </li>
                                            {(wordGroup.valid_from !== null)?(
                                                <li>
                                                    <IconTextWidget
                                                        showIcon={true}
                                                        icon="la-calendar-o"
                                                    >
                                                        <h3>
                                                            {(wordGroup.valid_until !== null)?(
                                                                <>
                                                                    {moment(wordGroup.valid_from).format('DD.MM.YYYY HH:mm')} - {moment(wordGroup.valid_until).format('DD.MM.YYYY HH:mm')}
                                                                </>
                                                            ): (
                                                                <>
                                                                    {moment(wordGroup.valid_from).format('DD.MM.YYYY HH:mm')}
                                                                </>
                                                            )}
                                                        </h3>
                                                        <p><Trans>dátum priradenia</Trans></p>
                                                    </IconTextWidget>
                                                </li>
                                            ):(null)}
                                        </ul>
                                    ):(null)}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <ResultsGraphWidget
                                results={testResults}  
                            />
                        </div>
                        <div className="row">
                            <div className="row-part">
                                <TableContainer
                                    headers={headers}
                                    rows={rows}
                                    actions={actions}
                                    showActions={false}
                                    showCheckbox={false}
                                    showFilter={false}
                                    pageSize={20}
                                    emptyText={i18n._('Táto skupina neobsahuje žiadne slová')}
                                    showNew={false}
                                    showGrid={false}
                                    mobileFixedGrid={true}
                                    title={i18n._('Slovíčka skupiny')}
                                />
                            </div>
                            <div className="row-part">
                                <TabsContainer
                                    tabs={[
                                        {
                                            header: {
                                                icon: 'la-list',
                                                title: i18n._('Cvičenia'),
                                                description: i18n._('Moje cvičenia'),
                                                titleTab: i18n._('Moje cvičenia súvisiace s touto skupinou slovíčok')
                                            },
                                            content: (
                                                <AssignedWordGroupTrainings
                                                    loading={loading || loadingResults || loadingTests}
                                                    currentWordGroup={wordGroup}
                                                    tests={wgTrainings}
                                                    results={testResults}
                                                />
                                            )
                                        },
                                        {
                                            header: {
                                                icon: 'la-list-alt',
                                                title: i18n._('Domáce úlohy'),
                                                description: i18n._('Priradené domáce úlohy'),
                                                titleTab: i18n._('Priradené domáce úlohy so slovíčkami z tejto skupiny slovíčok')
                                            },
                                            content: (
                                                <AssignedWordGroupHomeWorks
                                                    loading={loading || loadingResults || loadingTests || loadingAssigned}
                                                    currentWordGroup={wordGroup}
                                                    tests={homeworks}
                                                    results={testResults}
                                                />
                                            )
                                        }
                                    ]}
                                    footerActions={[]}
                                />
                            </div>
                        </div>
                    
                    </div>
                    {(typeof wordGroup !== 'undefined')?(
                        <ModalAddTestWordgroup
                            wordgroup={wordGroup}
                        />
                    ):(null)}
                </div>

            </section>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(WordGroupAssignedDetailPage))