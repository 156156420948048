import React, {Component} from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { ITestResult, IUSerInfo, ITest } from '../../../../constants/types';
import { IApplicationStore } from '../../../../constants/store-interfaces';
import ResultList from './results/ResultList';
import ResultsGraphTestWidget from '../../../simple/widgets/ResultsGraphTestWidget';

interface IStateProps {
    userInfo: IUSerInfo | null
}

interface IDispatchProps {
}

const mapStateToProps = (state: IApplicationStore): IStateProps => ({
    userInfo: state.globalStore.userInfo
})

const mapDispatchToProps = (dispatch: any): IDispatchProps => ({
})


interface IProps extends IStateProps, IDispatchProps {
    test: ITest,
    myResult?: ITestResult,
    testResults: ITestResult[]
}

interface IState {
}

class TestDetailTest extends Component<IProps, IState> {

    render() {
        const { testResults, test, myResult, userInfo } = this.props

        if(test === null) {
            return (null)
        }
        
        const results = testResults.filter(e => e.test_id === test.id)

        results.sort((a, b) => moment(((b.date_finished !== null)?b.date_finished:b.date_started)).valueOf() - moment(((a.date_finished !== null)?a.date_finished:a.date_started)).valueOf())
        
        return (
            <div className="test-content">
                <div className="mainSectionBody">
                
                    <div className="rows">
                        {(userInfo !== null)?(
                            <div className="row">
                                <div className="row-part">
                                    <ResultsGraphTestWidget
                                        results={results}
                                        myId={userInfo.id}
                                    />
                                </div>
                            </div>
                        ):(null)}
                        <div className="row">
                            <div className="row-part test-results _alone">
                                <ResultList
                                    test={test}
                                    testResult={myResult}
                                />
                            </div>
                        </div>
                    
                    </div>
                
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TestDetailTest)