import React, {useState, useEffect} from 'react'
import { ITest, ITestResult, IUserGroupType, IUSerInfo, ETestUgType } from '../../../../constants/types';
import SwiperContainer from '../../../app/containers/SwiperContainer';
import { i18n } from '../../../../App';
import { IApplicationStore } from '../../../../constants/store-interfaces';
import { useSelector } from 'react-redux';
import config from '../../../../config';
import { Trans } from '@lingui/macro';
import { NavLink } from 'react-router-dom';
import moment from 'moment';
import HomeWorkDetailUser from './parts/HomeWorkDetailUser';
import { history } from '../../../../..';
import LoadingElement from '../../LoadingElement';
import HomeWorkDetailTeacher from './parts/HomeWorkDetailTeacher';
import TestDetailTeacher from './parts/TestDetailTeacher';
import TestDetailUser from './parts/TestDetailUser';
import AuthTeacherWrapper from '../../../app/containers/Authentification/AuthTeacherWrapper';
import InformationBox from '../../../app/parts/InformationBox';
import AuthUserWrapper from '../../../app/containers/Authentification/AuthUserWrapper';

interface IProps {
}

interface ISlideData {
    ug: IUserGroupType,
    hwSlides: JSX.Element[],
    testSlides: JSX.Element[],
    hwLength: number,
    testLength: number
}

const UserGroupsWidget: React.FC<IProps> = props => {
    const loadingAssignedUsergroups = useSelector<IApplicationStore, boolean>(state => state.loadingStore.loadingAssignedUserGroups)
    const loadingCreatedUsergroups = useSelector<IApplicationStore, boolean>(state => state.loadingStore.loadingCreatedUserGroups)
    const loadingAssignedTests = useSelector<IApplicationStore, boolean>(state => state.loadingStore.loadingAssignedTests)
    const loadingTestResults = useSelector<IApplicationStore, boolean>(state => state.loadingStore.loadingTestResults)
    const assignedUserGroups = useSelector<IApplicationStore, IUserGroupType[]>(state => state.userGroupsStore.assignedUserGroups)
    const createdUserGroups = useSelector<IApplicationStore, IUserGroupType[]>(state => state.userGroupsStore.createdUserGroups)
    const userGroupsTests = useSelector<IApplicationStore, {[key: number]: ITest[]}>(state => state.testsStore.usergroupTests)
    const userGroupResults = useSelector<IApplicationStore, {[key: number]: ITestResult[]}>(state => state.testsStore.usergroupResults)
    const userInfo = useSelector<IApplicationStore, IUSerInfo | null>(state => state.globalStore.userInfo)

    const [userGroups, setUserGroups] = useState<IUserGroupType[]>([])
    const [isTeacher, setIsTeacher] = useState(false)
    const [slideData, setSlideData] = useState<ISlideData[]>([])

    useEffect(() => {
        let newIsTeacher = false
        let newUserGroups = assignedUserGroups
        
        if((userInfo !== null) && (userInfo.role.name === config.roles.teacher)) {
            newIsTeacher = true
            newUserGroups = createdUserGroups
        }

        setIsTeacher(newIsTeacher)
        setUserGroups(newUserGroups)
    }, [assignedUserGroups, userInfo, createdUserGroups])

    useEffect(() => {
        let newData: ISlideData[] = []

        userGroups.forEach(uG => {
            let ugTests = userGroupsTests[uG.id]
            let ugResults = userGroupResults[uG.id]

            let homeWorks: ITest[] = []
            let tests: ITest[] = []      
            let results: ITestResult[] = []

            if(typeof ugResults !== 'undefined') {
                results = ugResults
            }

            if(typeof ugTests !== 'undefined') {
                tests = ugTests.filter(t => (
                    (typeof t.settings.ugType !== 'undefined') && 
                    (t.usergroup_id === uG.id) &&
                    (t.settings.ugType === ETestUgType.test)
                ))

                homeWorks = ugTests.filter(t => (
                    (typeof t.settings.ugType !== 'undefined') && 
                    (t.settings.ugType === ETestUgType.homework) &&
                    (t.usergroup_id === uG.id)
                ))

                if(!isTeacher) {
                    //Get just active tests
                    tests = tests.filter((t: ITest) => {
                        if(
                            (t.valid_until !== null) &&
                            (moment(new Date(t.valid_until)).isBefore(moment()))
                        ) {
                            return false
                        }
            
                        return true
                    })

                    homeWorks = homeWorks.filter((t: ITest) => {
                        if(moment(new Date(t.valid_from)).isAfter(moment())) {
                            return false
                        }
                        
                        if(
                            (t.valid_until !== null) &&
                            (moment(new Date(t.valid_until)).isBefore(moment()))
                        ) {
                            return false
                        }
            
                        return true
                    })
                }

                homeWorks.sort((b, a) => moment(((a.valid_until !== null)?a.valid_until:a.valid_from)).valueOf() - moment(((b.valid_until !== null)?b.valid_until:b.valid_from)).valueOf())
                tests.sort((b, a) => moment(((a.valid_until !== null)?a.valid_until:a.valid_from)).valueOf() - moment(((b.valid_until !== null)?b.valid_until:b.valid_from)).valueOf())
            }

            let slidesHomeWorks: JSX.Element[] = []
            let slidesTests: JSX.Element[] = []

            homeWorks.forEach((hW: ITest, j) => {
                slidesHomeWorks.push((<div key={j}>
                    {(isTeacher)?(
                        <HomeWorkDetailTeacher
                            results={results}
                            test={hW}
                            userCount={uG.assigned_users.length}
                        />
                    ):(
                        <HomeWorkDetailUser
                            results={results}
                            test={hW}
                        />
                    )}
                </div>))
            })

            tests.forEach((hW: ITest, j) => {
                slidesTests.push((<div key={j}>
                    {(isTeacher)?(
                        <TestDetailTeacher
                            results={results}
                            test={hW}
                            userCount={uG.assigned_users.length}
                        />
                    ):(
                        <TestDetailUser
                            results={results}
                            test={hW}
                        />
                    )}
                </div>))
            })
                                                    
            if (isTeacher) {
                slidesHomeWorks.push((
                    <div key={slidesHomeWorks.length.toString()}>

                        <section className="item_new">
                            <div className="item_inner" onClick={() => history.push('/app/classes/' + uG.id)}>
                                +
                            </div>
                        </section>

                    </div>
                ))
                slidesTests.push((
                    <div key={slidesTests.length.toString()}>

                        <section className="item_new">
                            <div className="item_inner" onClick={() => history.push('/app/classes/' + uG.id)}>
                                +
                            </div>
                        </section>

                    </div>
                ))
            }

            newData.push({
                ug: uG,
                hwSlides: slidesHomeWorks,
                testSlides: slidesTests,
                hwLength: homeWorks.length,
                testLength: tests.length
            })
        })

        setSlideData(newData)
        
    }, [userGroups, userGroupsTests, userGroupResults, isTeacher])

    let title = i18n._('Moje triedy')

    if(createdUserGroups.length === 0 && (assignedUserGroups.length === 0)) {
        return (
            <>     
                <AuthTeacherWrapper>
                    <InformationBox type='warning' showBell={true} showClose={false}>
                        <Trans>Zatiaľ nemáte vytvorenú žiadnu triedu. Pre pridanie prvej triedy stlačte vpravo hore tlačidlo vytvoriť triedu.</Trans>
                    </InformationBox>
                </AuthTeacherWrapper>
                <AuthUserWrapper>
                    <InformationBox type='warning' showBell={true} showClose={false}>
                        <Trans>Nepatríš do žiadnej triedy. Ak máš záujem, popros svojho učiteľa aby začal používať aplikáciu Alphie a zefektívnil tak svoju výučbu.</Trans>
                    </InformationBox>
                </AuthUserWrapper>
            </>
        )
    }

    return (
        <div className="tests-wrapper">
            <LoadingElement loadingValue={loadingCreatedUsergroups || loadingAssignedUsergroups} />

            <SwiperContainer
                params={{
                    slidesPerView: 1,
                    spaceBetween: 20,
                    noSwiping: true,
                    autoHeight: true
                }}
                mainTitle={title}
                showPagination={(slideData.length > 1)?true:false}
            >
                {slideData.map((s, i) => (
                    <div key={i}>
                        <div className="widget-holder">
                        
                            <div className="widget-holder-body">
                                <div className="widget-part">
                                
                                    <section className="widget">
                
                                        <header className="widget-header">
                                            <div className="_part">
                                            
                                                <h3>{s.ug.name}</h3>
                                            
                                            </div>
                                            <div className="_part">
                                            
                                                <NavLink to={'/app/classes/' + s.ug.id} className="tag _primary _alt"><Trans>Detail triedy</Trans></NavLink>
                                            
                                            </div>
                                        </header>
                                        <div className="widget-body">
                                            <div className="_part">
                                                {(isTeacher)?(
                                                    <>
                                                        <div className="items-list">
                
                                                            <LoadingElement loadingValue={((loadingCreatedUsergroups || loadingAssignedUsergroups) === false) && (loadingAssignedTests || loadingTestResults)} />
                                                            
                                                            <SwiperContainer
                                                                params={{
                                                                    slidesPerView: 1,
                                                                    spaceBetween: 20,
                                                                    autoHeight: true,
                                                                    breakpoints: {
                                                                        640: {
                                                                        slidesPerView: 2,
                                                                        },
                                                                        991: {
                                                                        slidesPerView: 3,
                                                                        },
                                                                        1600: {
                                                                        slidesPerView: 4,
                                                                        },
                                                                    }
                                                                }}
                                                                showPagination={true}
                                                                mainTitleComponent={(
                                                                    <h2 className="_icon">
                                                                        <i className="la la-bullseye"></i>
                                                                        <Trans>Domáce úlohy</Trans>
                                                                        <strong> ({s.hwLength})</strong>
                                                                    </h2>
                                                                )}
                                                            >
                                                                
                                                                {s.hwSlides}
                                                            </SwiperContainer>
                                                        </div>

                                                        <div className="items-list">

                                                            <LoadingElement loadingValue={((loadingCreatedUsergroups || loadingAssignedUsergroups) === false) && (loadingAssignedTests || loadingTestResults)} />
                                                            <SwiperContainer
                                                                params={{
                                                                    slidesPerView: 1,
                                                                    spaceBetween: 20,
                                                                    autoHeight: true,
                                                                    breakpoints: {
                                                                        640: {
                                                                        slidesPerView: 2,
                                                                        },
                                                                        991: {
                                                                        slidesPerView: 3,
                                                                        },
                                                                        1600: {
                                                                        slidesPerView: 4,
                                                                        },
                                                                    }
                                                                }}
                                                                showPagination={true}
                                                                mainTitleComponent={(
                                                                    <h2 className="_icon">
                                                                        <i className="la la-bullseye"></i>
                                                                        <Trans>Písomky</Trans>
                                                                        <strong> ({s.testLength})</strong>
                                                                    </h2>
                                                                )}
                                                            >
                                                                
                                                                {s.testSlides}
                                                            </SwiperContainer>
                                                        </div>
                                                    </>
                                                ):(
                                                    <>
                                                        <div className="items-list inline">
                                                            <LoadingElement loadingValue={((loadingCreatedUsergroups || loadingAssignedUsergroups) === false) && (loadingAssignedTests || loadingTestResults)} />
                                                            
                                                            <SwiperContainer
                                                                params={{
                                                                    slidesPerView: 1,
                                                                    spaceBetween: 20,
                                                                    breakpoints: {
                                                                        680: {
                                                                            slidesPerView: 2,
                                                                        },
                                                                        767: {
                                                                            slidesPerView: 1,
                                                                        },
                                                                        1360: {
                                                                            slidesPerView: 2,
                                                                        }
                                                                    }
                                                                }}
                                                                showPagination={true}
                                                                mainTitleComponent={(
                                                                    <h2 className="_icon">
                                                                        <i className="la la-bullseye"></i>
                                                                        <Trans>Aktuálne domáce úlohy</Trans>
                                                                        <strong> ({s.hwLength})</strong>
                                                                    </h2>
                                                                )}
                                                            >
                                                                
                                                                {s.hwSlides}
                                                            </SwiperContainer>
                                                        </div>
                                                        <div className="items-list inline">
                                                            <SwiperContainer
                                                                params={{
                                                                    slidesPerView: 1,
                                                                    spaceBetween: 20,
                                                                    autoHeight: true,
                                                                    breakpoints: {
                                                                        680: {
                                                                            slidesPerView: 2,
                                                                        },
                                                                        767: {
                                                                            slidesPerView: 1,
                                                                        },
                                                                        1360: {
                                                                            slidesPerView: 2,
                                                                        }
                                                                    }
                                                                }}
                                                                showPagination={true}
                                                                mainTitleComponent={(
                                                                    <h2 className="_icon">
                                                                        <i className="la la-bullseye"></i>
                                                                        <Trans>Aktuálne písomky</Trans>
                                                                        <strong> ({s.testLength})</strong>
                                                                    </h2>
                                                                )}
                                                            >
                                                                
                                                                {s.testSlides}
                                                            </SwiperContainer>
                                                        </div>
                                                    </>
                                                )}
                
                                            </div>
                                        </div>
                                        <footer className="widget-footer">
                                            <div className="_part">
                                            
                                                <div className="part-users">
                                                <ul>
                                                    {(s.ug.assigned_users.slice(0,5).map((u,i) => (
                                                        <li key={i}>
                                                            <div className="avatar">{u.name.charAt(0)}{u.surname.charAt(0)}</div>
                                                        </li>
                                                    )))}
                                                    {(s.ug.assigned_users.length > 5)?(
                                                        <li>
                                                            <div className="avatar _more">+{s.ug.assigned_users.length - 5}</div>
                                                        </li>
                                                    ):(null)}
                                                </ul>
                                            </div>
                                            
                                            </div>
                                            <div className="_part">
                                            
                                                <div className="block-link"><i className="la la-calendar"></i> <Trans>Vytvorená</Trans>: <span className="href">{moment(s.ug.created_at).format('DD. MM. YYYY')}</span></div>
                                            
                                            </div>
                                        </footer>
                                    
                                    </section>
                                    
                                </div>
                            </div>
                            
                        </div>
                    </div>)
                )}
            </SwiperContainer>
        </div>
    )
}

export default UserGroupsWidget