import { setLoadingGenerateTest } from '../loadings/setLoading';
import config from '../../config';
import { handleErrors } from '../../utils/handleErrors';
import { store } from '../../store';
import { i18n } from '../../App';
import { loadTestsFailed } from './loadTests';
import { ITest } from '../../constants/types';

export function generateTest(test: ITest) {
    store.dispatch(setLoadingGenerateTest(true))

    return fetch(config.api.testGenerate, {
        method: 'POST',
        credentials: 'include',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + store.getState().globalStore.token
        },
        body: JSON.stringify(test)
    })
        .then(handleErrors)
        .then(res => res.json())
        .then(res => {
            if(res.status === 'valid') {
                store.dispatch(setLoadingGenerateTest(false))

                return res.testResult
            } else {
                throw i18n._('Snažíte sa načítať neaktívny test/cvičenie/domácu úlohu!')
            }
        })
        .catch((error: any)  => {
            store.dispatch(loadTestsFailed(error))
            store.dispatch(setLoadingGenerateTest(false))
        });
}