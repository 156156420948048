import React, {Component} from 'react';
import { connect } from 'react-redux';
import { IApplicationStore } from "../../../constants/store-interfaces";
import { ITest, IUSerInfo, ITestResult, ETestType, ETestUgType } from '../../../constants/types';
import { RouteComponentProps, withRouter } from 'react-router';
import { loadTest } from '../../../actions/tests/loadTest';
import { i18n } from '../../../App';
import { Trans } from '@lingui/macro';
import { loadTestResults } from '../../../actions/tests/loadTestResults';
import LoadingElement from '../../simple/LoadingElement';
import { NavLink } from 'react-router-dom';
import IconTextWidget from '../../simple/widgets/IconTextWidget';
import TranslateLanguages from '../../simple/TranslateLanguages';
import moment from 'moment';
import getTestTypeString from '../../simple/classes/getTestTypeString';
import TestDetailBasic from '../containers/TestElements/TestDetailBasic';
import PercentageSmallWidget from '../../simple/widgets/PercentageSmallWidget';
import TestClass from '../../simple/classes/testClass';
import NoMatchPage from './NoMatchPage';
import ReactGA from 'react-ga';

interface IStateProps {
    loadingCreated: boolean,
    loadingTestResults: boolean,
    testResults: ITestResult[],
    userInfo: IUSerInfo | null
}

interface IDispatchProps {
    loadTestResults: typeof loadTestResults
}

const mapStateToProps = (state: IApplicationStore): IStateProps => ({
    loadingCreated: state.loadingStore.loadingCreatedTests,
    loadingTestResults: state.loadingStore.loadingTestResult,
    testResults: state.testsStore.testResults,
    userInfo: state.globalStore.userInfo
})

const mapDispatchToProps = (dispatch: any): IDispatchProps => ({
    loadTestResults: () => dispatch(loadTestResults())
})

type PathParamsType = {
    id: string
}

interface IProps extends IStateProps, IDispatchProps, RouteComponentProps<PathParamsType> {

}

interface IState {
    test: ITest | null
}

class TrainingDetailInfoPage extends Component<IProps, IState> {
    constructor(props: any) {
        super(props)

        this.state = {
            test: null
        }

        ReactGA.pageview('Cvičenie - detail')
    }

    componentDidMount() {
        this.props.loadTestResults()
        loadTest(Number(this.props.match.params.id))
            .then((res) => {
                this.setState({
                    ...this.state,
                    test: res
                })
            })
    }

    render() {
        const { loadingCreated, loadingTestResults, testResults } = this.props
        const { test } = this.state

        if (!loadingCreated && !loadingTestResults && typeof test === 'undefined') {
            return (<NoMatchPage/>)
        }
        
        if(test === null || typeof test === 'undefined') {
            return (null)
        }
        
        const typeSelect: { label: string; value: string }[] = []

        Object.keys(ETestType).forEach(value => {
            typeSelect.push({
                label: getTestTypeString(ETestType[(value as ETestType)]),
                value: ETestType[(value as ETestType)]
            })
        })

        const typeValue = typeSelect.find(value => value.value === test.settings.type)

        const results = testResults.filter(r => r.test_id === test.id)
        
        let percentage:number = 0
        let totalPoints:number = 0

        if(results.length) {
            let maxPoints = 0

            results.forEach(tR => {
                let testObject = new TestClass(test, tR)
                
                totalPoints += testObject.getTestRate()
                maxPoints += testObject.getMaximumRate()
            })

            percentage = Math.round(Number(totalPoints)/maxPoints*100)
        }
        
        return (
            <section id="mainSection">
                <LoadingElement loadingValue={loadingCreated || loadingTestResults} />

                <div className="mainSectionBody">
                
                    <div className="rows">
                        <div className="row">
                            <div className="row-part">
                                <div className="page-header">
                                    <div className="part">
                                        <h2><Trans>Cvičenie</Trans> - {test.name}</h2>
                                        <small><Trans>Vytvorené:</Trans> {moment(test.created_at).format('DD.MM.YYYY HH:mm')}</small>
                                        <p><Trans>Detail cvičenia s Vašími výsledkami a štatistikami</Trans></p>
                                    </div>
                                    <div className="part">
                                        
                                    {(test.usergroup_id === null)?(
                                        <NavLink to={"/app/trainings/" + test.id} target="_self" className="bttn _small _green _icon">
                                            <Trans>Spustiť</Trans>
                                        </NavLink>
                                    ):(
                                        (test.settings.ugType === ETestUgType.homework)?(
                                            <NavLink to={"/app/homeworks/" + test.id} target="_self" className="bttn _small _green _icon">
                                                <Trans>Spustiť</Trans>
                                            </NavLink>
                                        ):(
                                            null
                                        )
                                    )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="row-part">
                                <div className="small-widgets">
                                    <ul>
                                        <li>
                                            <IconTextWidget
                                                showIcon={true}
                                                icon="la-gear"
                                            >
                                                <h3>{(typeof typeValue !== 'undefined')?typeValue.label:''}{(typeof test.settings.diacritic !== 'undefined')?(test.settings.diacritic)?', ' + i18n._('s diakritikou'):', ' + i18n._('bez diakritiky'):', ' + i18n._('bez diakritiky')} {(test.settings.time !== null)?'(' + test.settings.time + ' ' + i18n._('min') + ')':''}</h3>
                                                <p><Trans>Typ cvičenia</Trans></p>
                                            </IconTextWidget>
                                        </li>
                                        <li>
                                            <IconTextWidget
                                                showIcon={true}
                                                icon="la-flag"
                                            >
                                                <TranslateLanguages
                                                    languageFrom={test.language_from}
                                                    languageTo={test.language_to}
                                                />
                                            </IconTextWidget>
                                        </li>
                                        <li>
                                            <IconTextWidget
                                                showIcon={true}
                                                icon="la-bar-chart"
                                            >
                                                <h3>+ { totalPoints }</h3>
                                                {(totalPoints === 1)?(
                                                    <p><Trans>Získaný bod</Trans></p>
                                                ):((totalPoints > 1) && (totalPoints <= 4))?(
                                                    <p><Trans>Získané body</Trans></p>
                                                ):(
                                                    <p><Trans>Získaných bodov</Trans></p>
                                                )}
                                                
                                            </IconTextWidget>
                                        </li>
                                        <li>
                                            <PercentageSmallWidget
                                                percentage={percentage}
                                            >
                                                <h3><Trans>Priemerná úspešnosť</Trans></h3>
                                            </PercentageSmallWidget>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="row-part">
                                <TestDetailBasic
                                    test={test}
                                />
                            </div>
                        </div>
                    </div>
                
                </div>

            </section>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TrainingDetailInfoPage))