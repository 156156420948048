import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { IApplicationStore } from "../../../constants/store-interfaces";
import { RouteComponentProps, withRouter } from 'react-router';
import config from '../../../config';
import TestDetailTeacher from '../parts/tests/TestDetailTeacher';
import TestDetailUser from '../parts/tests/TestDetailUser';
import ReactGA from 'react-ga';

type PathParamsType = {
    id: string,
    testId: string
}

interface IProps extends RouteComponentProps<PathParamsType> {

}


const TestDetailInfoPage: React.FC<IProps> = props => {
    const [testId, setTestId] = useState<number>(Number(props.match.params.testId))
    const [classId, setClassId] = useState<number>(Number(props.match.params.id))

    const userLoggedIn = useSelector((state: IApplicationStore) => state.globalStore.userLoggedIn)
    const userInfo = useSelector((state: IApplicationStore) => state.globalStore.userInfo)

    useEffect(() => {
        ReactGA.pageview('Detail testu')
    }, [])

    useEffect(() => {
        setTestId(Number(props.match.params.testId))
    }, [props.match.params.testId])

    useEffect(() => {
        setClassId(Number(props.match.params.id))
    }, [props.match.params.id])

    
    if(userLoggedIn && (userInfo !== null) && (userInfo.role.name === config.roles.teacher)) {
        return (
            <TestDetailTeacher
                testId={testId}
                classId={classId}
            />
        )
    }

    return (
        <TestDetailUser
            testId={testId}
            classId={classId}
        />
    )
}

export default withRouter(TestDetailInfoPage)