import React, {Component} from 'react'
import { connect } from 'react-redux'
import { IApplicationStore } from '../../../../../constants/store-interfaces';
import ModalContainer from '../../../containers/ModalContainer';
import ModalHeader from '../../../containers/ModalElements/ModalHeader';
import { i18n } from '../../../../../App';
import ModalBody from '../../../containers/ModalElements/ModalBody';
import ModalFooter from '../../../containers/ModalElements/ModalFooter';
import { Trans } from '@lingui/macro';
import ReactCountryFlag from 'react-country-flag';
import InformationBox from '../../InformationBox';
import { setShowEditWordModal } from '../../../../../actions/modals/setShowModal';
import { ILanguageType } from '../../../../../constants/types';
import { IoIosArrowRoundForward } from 'react-icons/io';
import { editWord } from '../../../../../actions/words/editWord';

interface IStateProps {
    showEditWord: boolean
}

const mapStateToProps = (state: IApplicationStore): IStateProps => ({
    showEditWord: state.modalsStore.showEditWord
})


interface IDispatchProps {
    setShowEditWordModal: typeof setShowEditWordModal,
    editWord: typeof editWord,
}

const mapDispatchToProps = (dispatch: any): IDispatchProps => ({
    setShowEditWordModal: (value:boolean) => dispatch(setShowEditWordModal(value)),
    editWord: (id: number, data:{word_text: string, translation: string}) => dispatch(editWord(id, data))
})

interface IProps extends  IStateProps, IDispatchProps{
    id: number,
    word_text: string,
    translation: string,
    language_from: ILanguageType,
    language_to: ILanguageType,
    confirmFunction: (id: number, word_text: string, translation: string) => any
}

interface IState {
    id: number
    word_text: string,
    translation: string,
    isValid: boolean
}

class ModalEditWord extends Component<IProps, IState> {
    constructor(props:  any)  {
        super(props)
            
        this.state = {
            id: this.props.id,
            word_text: this.props.word_text,
            translation: this.props.translation,
            isValid: true
        }

        this.editWord = this.editWord.bind(this)
        this.hideModal = this.hideModal.bind(this)
        this.handleFromChange = this.handleFromChange.bind(this)
        this.handleToChange = this.handleToChange.bind(this)
    }

    componentDidUpdate(prevProps:  IProps) {
        if(prevProps.id !== this.props.id) {
            this.setState({
                id: this.props.id,
                word_text: this.props.word_text,
                translation: this.props.translation,
                isValid: true
            })
        }
    }

    editWord() {
        const { word_text, translation, id, isValid } = this.state

        if(isValid) {
            this.props.confirmFunction(id, word_text, translation)

            this.props.setShowEditWordModal(false)
        }
    }

    handleFromChange(e:any) {
        this.setState({
            ...this.state,
            word_text: e.target.value,
            isValid: this.validate(e.target.value, this.state.translation)
        })
    }

    handleToChange(e:any) {
        this.setState({
            ...this.state,
            translation: e.target.value,
            isValid: this.validate(this.state.word_text, e.target.value)
        })
    }

    validate(from:string, to: string) {
        let isValid = true

        if((from.length === 0) || (to.length === 0)) {
            isValid = false
        }
        
        return isValid
    }

    hideModal() {
        this.props.setShowEditWordModal(false)
    }

    render() {
        const { showEditWord, language_from, language_to }  = this.props
        const { word_text, translation, isValid } =  this.state

        return (
            <ModalContainer show={showEditWord}>
                <ModalHeader title={i18n._('Editácia slovíčka')} />

                <ModalBody className="form _alt">
                    <div className="input-row">
                        <div className="part add-words">
                            <div className="language-selects">
                                <div className="language-selects-part">
                                    <div className="wordToTranslate">
                                        <ReactCountryFlag className="flag" countryCode={language_from.flag_name} svg/>
                                        <input
                                            name={"name_from"}
                                            type="text" 
                                            placeholder={i18n._('Originál')} 
                                            onChange={this.handleFromChange}
                                            value={word_text}
                                            autoFocus={true}
                                        />
                                    </div>
                                </div>
                                <div className="language-selects-part _arrow">
                                    <IoIosArrowRoundForward className="icon"/>
                                </div>
                                <div className="language-selects-part">
                                    <div className="wordToTranslate">
                                        <ReactCountryFlag className="flag" countryCode={language_to.flag_name} svg/>
                                        <input
                                            name={"name_to"}
                                            type="text" 
                                            placeholder={i18n._('Preklad')} 
                                            onChange={this.handleToChange}
                                            value={translation}
                                        />   
                                    </div>
                                </div> 
                            </div>

                                {(isValid)?(null):(
                                    <InformationBox type="warning" showClose={false} showBell={true}>
                                        <Trans>Pre pokračovanie  musíte zadať originál aj preklad!</Trans>
                                    </InformationBox>
                                )}
                            
                        </div>
                    </div>
                </ModalBody>

                <ModalFooter>
                    <div className="part">
                        <button className="bttn _red href" onClick={this.hideModal}><Trans>Zrušiť</Trans></button>
                    </div>
                    <div className="part">
                        <button className={"bttn _full  href " + ((!isValid)?"_dark":"_green")} onClick={this.editWord} disabled={!isValid}><Trans>Uložiť</Trans></button>
                    </div>
                </ModalFooter>
            </ModalContainer>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalEditWord)
