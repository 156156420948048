import React, {Component} from 'react';
import { connect } from 'react-redux';
import { IApplicationStore } from "../../../constants/store-interfaces";
import { ITest, ITableRowType, ITableCellType, ITableHeaderCellType, IActionType, ITestResult, ETestUgType, IUserGroupType } from '../../../constants/types';
import TranslateLanguages from '../../simple/TranslateLanguages';
import { i18n } from '../../../App';
import NumberText from '../../simple/tableElements/NumberText';
import { NavLink, withRouter } from 'react-router-dom';
import { Trans } from '@lingui/macro';
import TableContainer from '../containers/TableContainer';
import { loadTestResults } from '../../../actions/tests/loadTestResults';
import Percentage from '../../simple/tableElements/Percentage';
import TestClass from '../../simple/classes/testClass';
import { loadAssignedTests } from '../../../actions/tests/loadAssignedTests';
import { loadAssignedUserGroups } from '../../../actions/userGroups/loadAssignedUserGroups';
import TabsContainer from '../containers/TabsContainer';
import AssignedHomeworkInfoPart from '../parts/userGroups/parts/AssignedHomeworkInfoPart';
import moment from 'moment';
import DateTextBetween from '../../simple/tableElements/DateTextBetween';
import { RouteComponentProps } from 'react-router';
import ReactGA from 'react-ga';

interface IStateProps {
    assignedTests: ITest[],
    assignedUserGroups: IUserGroupType[],
    testResults: ITestResult[],
    loadingAssigned: boolean,
    loadingAssignedUserGroups: boolean
    loadingResults: boolean
}

interface IDispatchProps {
    loadTestResults: typeof loadTestResults,
    loadAssignedTests: typeof loadAssignedTests,
    loadAssignedUserGroups: typeof loadAssignedUserGroups
}

const mapStateToProps = (state: IApplicationStore): IStateProps => ({
    assignedTests: state.testsStore.assignedTests,
    assignedUserGroups: state.userGroupsStore.assignedUserGroups,
    testResults: state.testsStore.testResults,
    loadingAssigned: state.loadingStore.loadingAssignedTests,
    loadingResults: state.loadingStore.loadingTestResult,
    loadingAssignedUserGroups: state.loadingStore.loadingAssignedUserGroups
})

const mapDispatchToProps = (dispatch: any): IDispatchProps => ({
    loadTestResults: () => dispatch(loadTestResults()),
    loadAssignedTests: () => dispatch(loadAssignedTests()),
    loadAssignedUserGroups:() => dispatch(loadAssignedUserGroups())
})

interface IProps extends IStateProps, IDispatchProps, RouteComponentProps {

}

interface IState {
    
}

class HomeWorksPage extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props)
        
        ReactGA.pageview('Domáce úlohy')
    }

    componentDidMount() {
        this.props.loadAssignedUserGroups()
        this.props.loadTestResults()
        this.props.loadAssignedTests()
    }

    getRows(tests:  ITest[], results: ITestResult[], usergroups: IUserGroupType[]): ITableRowType[] {
        let rows: ITableRowType[] = []

        for(let test  of tests)  {
            let cells: ITableCellType[] = []

            let userGroup: IUserGroupType|undefined = undefined

            if(test.usergroup_id !== null) {
                userGroup = usergroups.find(u => u.id === test.usergroup_id)
            }

            let percentage:number|null = null
            let percentageContent = (null)

            //Find percentage
            let testResults = results.filter(tR => tR.test_id === test.id)

            if(testResults.length) {
                let cntPoints = 0
                let maxPoints = 0

                testResults.forEach(tR => {
                    let testObject = new TestClass(test, tR)
                    
                    cntPoints += testObject.getTestRate()
                    maxPoints += testObject.getMaximumRate()
                })

                percentage = Math.round(Number(cntPoints)/maxPoints*100)
            }

            if(percentage !== null) {
                percentageContent = (<Percentage percentage={percentage} title={i18n._('Úspešnosť')}/>)
            }

            let isValid = true

            if(moment(test.valid_from).isAfter(moment())) {
                isValid = false
            }   

            if((test.valid_until !== null) && (moment(test.valid_until).isBefore(moment()))) {
                isValid = false
            }

            let tooltipShow = i18n._('Neaktívna')
            let tooltipColor = '_red'
            
            let actions = (
                <div className="part-list">
                    <NavLink to={"/app/classes/" + test.usergroup_id + "/homeworks/" + test.id + "/detail"} target="_self" className="bttn _small _primary _icon" data-tooltip={i18n._("Detail domácej úlohy")}>
                        <i className="la la-search-plus"/>
                    </NavLink>
                </div>
            )

            if(isValid) {
                tooltipColor = '_silent'
                tooltipShow = i18n._('Aktívna')

                actions = (
                    <div className="part-list">
                        <NavLink to={"/app/classes/" + test.usergroup_id + "/homeworks/" + test.id} target="_self" className="bttn _small _green _icon">
                            <Trans>Spustiť</Trans>
                        </NavLink>
                        <NavLink to={"/app/classes/" + test.usergroup_id + "/homeworks/" + test.id + "/detail"} target="_self" className="bttn _small _primary _icon" data-tooltip={i18n._("Detail domácej úlohy")}>
                            <i className="la la-search-plus"/>
                        </NavLink>
                    </div>
                )
            }
            
            cells = [
                {
                    content: (<div className="part-title">{test.name}</div>),
                    stringToOrder:  test.name,
                    filterString:  test.name,
                    filterByString:  true
                },
                {
                    content: (
                        <TranslateLanguages
                            languageFrom={test.language_from}
                            languageTo={test.language_to}
                        />
                    ),
                    stringToOrder:  test.language_from.flag_name + test.language_to.flag_name,
                    filterString: test.language_from.flag_name + test.language_to.flag_name,
                    filterByString: false,
                    selectableFilter: true,
                    selectableName: i18n._('vybrať'),
                    selectableAllText: i18n._('Všetky'),
                    selectableTitle: i18n._('Preklad'),
                    selectableObject: [
                        {
                            object: (
                                    <TranslateLanguages
                                        languageFrom={test.language_from}
                                        languageTo={test.language_to}
                                    />
                            ),
                            value: test.language_from.flag_name + test.language_to.flag_name
                        }
                    ]
                },
                {
                    content: (
                        (typeof userGroup !== 'undefined')?(
                            <NavLink to={'/app/classes/' + userGroup.id} className="tag _orange">{userGroup.name}</NavLink>
                        ):(null)
                    ),
                    stringToOrder: (typeof userGroup !== 'undefined')?userGroup.name:'',
                    filterString: (typeof userGroup !== 'undefined')?userGroup.name:'',
                    filterByString:  true
                },
                {
                    content: (<NumberText 
                                    number={(test.settings.time !== null)?test.settings.time:undefined} 
                                    text={(test.settings.time !== null)?i18n._('min'):''}
                                    title={i18n._('Časový limit')}/>),
                    stringToOrder:  (test.settings.time !== null)?test.settings.time.toString():"0",
                    filterString:  (test.settings.time !== null)?test.settings.time.toString():"0",
                    filterByString:  true
                },
                {
                    content: percentageContent,
                    stringToOrder: test.created_at,
                    filterString: test.created_at,
                    filterByString: false
                },
                {
                    content: (
                        <>
                            {(test.valid_until !== null)?(
                                <DateTextBetween
                                    dateFrom={test.valid_from} 
                                    dateTo={test.valid_until}
                                    title={i18n._('Priradené')}
                                    tag={tooltipColor}
                                    tooltip={tooltipShow}
                                />
                            ):(null)}
                        </>),
                    stringToOrder: test.valid_from,
                    filterString: test.valid_from,
                    filterByString: false
                },
                {
                    content: (
                        actions
                    ),
                    stringToOrder: test.updated_at,
                    filterString: test.updated_at,
                    filterByString: false
                }
            ]

            rows.push({
                cells: cells,
                catchString: '_' +  test.id
            })
        }

        return rows
    }

    getHeaders(): ITableHeaderCellType[] {
        return [
            {
                content: (<span><Trans>Názov</Trans></span>),
                sortBy: false,
                sortable: true
            },
            {
                content: (<span><Trans>Preklad</Trans></span>),
                sortBy: false,
                sortable: true
            },
            {
                content: (<span><Trans>Trieda</Trans></span>),
                sortBy: false,
                sortable: true
            },
            {
                content: (<span><Trans>Časový limit</Trans></span>),
                sortBy: false,
                sortable: false,
                sortableType: "number"
            },
            {
                content: (<span><Trans>Výsledok</Trans></span>),
                sortBy: false,
                sortable: false
            },
            {
                content: (<span><Trans>Platnosť</Trans></span>),
                sortBy: true,
                sortableType: 'date',
                sortable: true,
            },
            {
                content: (<span></span>),
                sortBy: false,
                sortable: false
            },
        ]
    }

    render() {
        const { loadingAssigned, loadingAssignedUserGroups, loadingResults, testResults, assignedTests, assignedUserGroups } = this.props

        let tests = assignedTests.filter(t => (
                                                (typeof t.settings.ugType !== 'undefined') && 
                                                (t.settings.ugType === ETestUgType.homework) &&
                                                (moment(new Date(t.valid_from)).isBefore(moment()))
                                            ))

        let activeTests = tests.filter((t: ITest) => {
            if(moment(new Date(t.valid_from)).isAfter(moment())) {
                return false
            }
            
            if(
                (t.valid_until !== null) &&
                (moment(new Date(t.valid_until)).isBefore(moment()))
            ) {
                return false
            }

            return true
        })

        let inactiveTests = tests.filter((t: ITest) => (typeof activeTests.find(iT => t.id === iT.id) === 'undefined'))

        let createdHeaders: ITableHeaderCellType[] = this.getHeaders()
        let activeRows: ITableRowType[] = this.getRows(activeTests, testResults, assignedUserGroups)
        let inactiveRows: ITableRowType[] = this.getRows(inactiveTests, testResults, assignedUserGroups)
        let createdActions: IActionType[] = []

        let activeHomeWorks = (<TableContainer
            headers={createdHeaders}
            rows={activeRows}
            actions={createdActions}
            showActions={false}
            showCheckbox={false}
            showFilter={false}
            showHeader={false}
            pageSize={12}
            title={i18n._('Aktuálne domáce úlohy')}
            showNew={false}
            grid={true}
            showGrid={false}
            emptyText={i18n._('Zatiaľ nemáte priradené žiadne domáce úlohy')}
            emptySubtitle={i18n._('Ak chcete mať domáce úlohy, požiadajte svojho učiteľa o vytvorenie domácich úloh. Ak nie ste členom žiadnej triedy, oslovte svojho učiteľa s možnosťou učenia prostredníctvom tejto aplikácie.')}
            loading={loadingAssigned || loadingAssignedUserGroups || loadingResults}
            defaultSort='asc'
            mobileFixedGrid
        />)

        let inactiveHomeWorks = (<TableContainer
            headers={createdHeaders}
            rows={inactiveRows}
            actions={createdActions}
            showActions={false}
            showCheckbox={false}
            showFilter={false}
            showHeader={false}
            pageSize={12}
            title={i18n._('Staršie (neaktívne) domáce úlohy')}
            showNew={false}
            grid={true}
            showGrid={false}
            emptyText={i18n._('Zatiaľ nemáte staršie (neaktívne) domáce úlohy')}
            emptySubtitle={i18n._('V tejto tabuľke uvidíte všetky domáce úlohy ktoré ste v minulosti mali priradené.')}
            loading={loadingAssigned || loadingAssignedUserGroups || loadingResults}
            defaultSort='asc'
            mobileFixedGrid
        />)

        return (
            <div className="rows">
                <div className="row">
                    <div className="row-part">
                        <div className="page-header">
                            <div className="part">
                                <h2><Trans>Domáce úlohy</Trans></h2>
                                <p><Trans>Zoznam domácich úloh, ktoré ste dostali od svojich učiteľov na učenie sa slovíčok.</Trans></p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="row-part">
                        <TabsContainer
                            tabs={[
                                {
                                    header: {
                                        icon: 'la-list',
                                        title: i18n._('Aktuálne'),
                                        description: i18n._('domáce úlohy'),
                                        titleTab: i18n._('Aktuálne domáce úlohy')
                                    },
                                    content: (
                                        <div className="rows">
                                            <AssignedHomeworkInfoPart isTeacher={false}/>
                                            <div className="row">
                                                <div className="row-part">
                                                    {activeHomeWorks}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                },
                                {
                                    header: {
                                        icon: 'la-list-alt',
                                        title: i18n._('Staršie'),
                                        description: i18n._('domáce úlohy'),
                                        titleTab: i18n._('Staršie domáce úlohy (neaktívne)')
                                    },
                                    content: (
                                        <div className="rows">
                                            <AssignedHomeworkInfoPart isTeacher={false} isHistory={true}/>
                                            <div className="row">
                                                <div className="row-part">
                                                    {inactiveHomeWorks}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            ]}
                            footerActions={[]}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HomeWorksPage))