import { setLoadingCreatedUserGroups } from '../loadings/setLoading';
import config from '../../config';
import { handleErrors } from '../../utils/handleErrors';
import { 
    GENERATE_NEW_CODE_FAILED, 
    GENERATE_NEW_CODE, 
    GENERATE_NEW_CODE_STARTED } from '../../constants/action-types';
import { store } from '../../store';
import { setShowConfirmModal } from '../modals/setShowModal';

export function regenerateUserGroupKey(groupId: number) {
    return (dispatch: any) => {

        dispatch(setLoadingCreatedUserGroups(true))
        dispatch(regenerateUserGroupKeyStarted())

        return fetch(config.api.usergroups + '/' + groupId + '/generate', {
            method: 'POST',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + store.getState().globalStore.token
            }
            
        })
            .then(handleErrors)
            .then(res => res.json())
            .then(res => {
                dispatch(regenerateUserGroupKeySuccess({ugId: groupId, code: res.data}))

                dispatch(setLoadingCreatedUserGroups(false))
                dispatch(setShowConfirmModal(false))
            })
            .catch((error: any)  => {
                dispatch(regenerateUserGroupKeyFailed(error))
                dispatch(setLoadingCreatedUserGroups(false))
            });
    };
}

export const regenerateUserGroupKeyStarted = () => ({
    type: GENERATE_NEW_CODE_STARTED
});

export const regenerateUserGroupKeySuccess = (data:any) => ({
    type: GENERATE_NEW_CODE,
    payload: data
});

export const regenerateUserGroupKeyFailed = (error: any) => ({
    type: GENERATE_NEW_CODE_FAILED,
    payload: { error }
});