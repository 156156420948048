import React, {Component} from 'react';
import { connect } from 'react-redux';
import { IApplicationStore } from '../../../../../constants/store-interfaces';
import { ITestResult, ITest } from '../../../../../constants/types';
import { Trans } from '@lingui/macro';
import CircularGraph from '../../../../simple/widgets/CircularGraph';
import ResultList from '../../../containers/TestElements/results/ResultList';

interface IDispatchProps {
}

interface IStateProps {
}

const mapStateToProps = (state: IApplicationStore): IStateProps => ({
})

const mapDispatchToProps = (dispatch: any): IDispatchProps => ({
})

interface IState {
}

interface IProps extends IDispatchProps, IStateProps {
    test: ITest
    result: ITestResult
}

class TestResultInfo extends Component<IProps, IState> {

    render() {
        const { test, result } = this.props

        if(result.result === null) {
            return (null)
        }

        let validAnswers = 0

        result.result.questions.forEach(q => {
            validAnswers += (q.valid)?1:0
        })

        return (
            <div>
                <div className="summary">
                    <div className="summary-block">
                        <h4>
                            <i className="la la-bar-chart-o"/>
                            <Trans>Výsledok</Trans> - {validAnswers}/{result.result.questions.length}
                        </h4>
                        <div className="summary-content">
                            <CircularGraph percentage={Math.round(validAnswers/result.result.questions.length*100)}/>
                        </div>
                    </div>
                    <div className="summary-block">
                        <h4>
                            <i className="la la-language"/>
                            {result.result.questions.length + ' '}
                            {(result.result.questions.length === 1)?(<Trans>otázka</Trans>):(result.result.questions.length <= 4)?(<Trans>otázky</Trans>):(<Trans>otázok</Trans>)}
                        </h4>
                        <div className="summary-content results-in-modal">
                                <ResultList
                                    test={test}
                                    testResult={result}
                                />
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TestResultInfo)