import { setLoadingCreatedTests } from '../loadings/setLoading';
import config from '../../config';
import { handleErrors } from '../../utils/handleErrors';
import { 
    GET_TESTS, 
    GET_TESTS_STARTED, 
    GET_TESTS_FAILED } from '../../constants/action-types';
import { store } from '../../store';
import { ITest } from '../../constants/types';

export function loadTests() {
    return (dispatch: any) => {

        dispatch(setLoadingCreatedTests(true))
        dispatch(loadTestsStarted())

        return fetch(config.api.tests, {
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + store.getState().globalStore.token
            }
            
        })
            .then(handleErrors)
            .then(res => res.json())
            .then(res => {

                let results = res.data.filter((r:ITest) => r.valid)

                results = results.filter((r: ITest) => r.usergroup_id === null )

                dispatch(loadTestsSuccess(results))

                dispatch(setLoadingCreatedTests(false))
            })
            .catch((error: any)  => {
                dispatch(loadTestsFailed(error))
                dispatch(setLoadingCreatedTests(false))
            });
    };
}

export const loadTestsStarted = () => ({
    type: GET_TESTS_STARTED
});

export const loadTestsSuccess = (data:any) => ({
    type: GET_TESTS,
    payload: data
});

export const loadTestsFailed = (error: any) => ({
    type: GET_TESTS_FAILED,
    payload: { error }
});