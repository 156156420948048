import React, {Component} from 'react';
import { connect } from 'react-redux';
import { IApplicationStore } from '../../../../../constants/store-interfaces';
import { Trans } from '@lingui/macro';
import { i18n } from '../../../../../App';
import { ETestType, ETestDifficulty, ITestCreate, ETestUgType } from '../../../../../constants/types';
import SummaryBlock from '../../small/SummaryBlock';
import Select from 'react-select';
import TestTypeInfo from './summary/TestTypeInfo';
import getTestTypeString from '../../../../simple/classes/getTestTypeString';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import InformationBox from '../../InformationBox';

interface IStateProps {
    language: string
}

interface IDispatchProps {
}

const mapStateToProps = (state: IApplicationStore): IStateProps => ({
    language: state.globalStore.language
})

const mapDispatchToProps = (dispatch: any): IDispatchProps => ({
})

interface IProps extends IStateProps, IDispatchProps{
    test: ITestCreate,
    handleTest: (test: ITestCreate) => any,
    isTraining?: boolean
}

interface IState {
    showTime: boolean,
    defaultTime: number
}


class TestSettings extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props)

        this.state = {
            showTime: (this.props.test.settings.time !== null)?true:false,
            defaultTime: 10
        }

        this.handleChangeName = this.handleChangeName.bind(this)
        this.handleChangeType = this.handleChangeType.bind(this)
        this.handleChangeDifficulty = this.handleChangeDifficulty.bind(this)
        this.handleChangeTime = this.handleChangeTime.bind(this)
        this.handleChangeShowTime = this.handleChangeShowTime.bind(this)
        this.handleChangeDateFrom = this.handleChangeDateFrom.bind(this)
        this.handleChangeDateTo = this.handleChangeDateTo.bind(this)
        this.handleChangeDiacritic = this.handleChangeDiacritic.bind(this)
        this.handleChangeEmail = this.handleChangeEmail.bind(this)
    }

    handleChangeName(e: React.ChangeEvent<HTMLInputElement>) {
        let test = {
            ...this.props.test,
            name: e.currentTarget.value
        }

        this.props.handleTest(test)
    }

    handleChangeType = (inputValue:any) => {
        let test = {
            ...this.props.test,
            settings: {
                ...this.props.test.settings,
                type: inputValue.value as ETestType
            }
        }

        this.props.handleTest(test)
    }

    handleChangeTime = (inputValue:any) => {
        let test = {
            ...this.props.test,
            settings: {
                ...this.props.test.settings,
                time: Number(inputValue.value)
            }
        }

        this.props.handleTest(test)
    }

    handleChangeDifficulty(e: React.ChangeEvent<HTMLInputElement>) {
        let test = {
            ...this.props.test,
            settings: {
                ...this.props.test.settings,
                difficulty: e.currentTarget.value as ETestDifficulty
            }
        }

        this.props.handleTest(test)
    }

    handleChangeDiacritic(e: React.ChangeEvent<HTMLInputElement>) {
        let test = {
            ...this.props.test,
            settings: {
                ...this.props.test.settings,
                diacritic: e.currentTarget.checked
            }
        }

        this.props.handleTest(test)
    }

    handleChangeEmail(e: React.ChangeEvent<HTMLInputElement>) {
        let test = {
            ...this.props.test,
            settings: {
                ...this.props.test.settings,
                email: e.currentTarget.checked
            }
        }

        this.props.handleTest(test)
    }

    handleChangeShowTime(e: React.ChangeEvent<HTMLInputElement>) {
        let newState = !this.state.showTime
        this.setState({
            ...this.state,
            showTime: newState
        })

        let test = {
            ...this.props.test,
            settings: {
                ...this.props.test.settings,
                time: (newState)?this.state.defaultTime:null
            }
        }

        this.props.handleTest(test)
    }

    handleChangeDateFrom(date: any) {
        let test = {
            ...this.props.test,
            valid_from: (date === null)?new Date().toISOString():moment(date).toISOString()
        }

        this.props.handleTest(test)
    }

    handleChangeDateTo(date: any) {
        let validUntil = new Date()
        validUntil.setDate(new Date().getDate() + 7)

        let test = {
            ...this.props.test,
            valid_until: (date === null)?validUntil.toISOString():moment(date).toISOString()
        }

        this.props.handleTest(test)
    }

    render() {
        const { test, isTraining } = this.props
        const { showTime } = this.state

        let showTraining = false

        if(typeof isTraining !== 'undefined') {
            showTraining = isTraining
        }

        const typeSelect: { label: string; value: string }[] = []

        Object.keys(ETestType).forEach(value => {
            if(value !== ETestType.connect) {
                typeSelect.push({
                    label: getTestTypeString(ETestType[(value as ETestType)]),
                    value: ETestType[(value as ETestType)]
                })
            }
        })

        const typeValue = typeSelect.find(value => value.value === test.settings.type)

        let availableTimes: number[] = [1,2,3,4,5,6,7,8,9,10,12,14,16,18,20,25,30,35,40,45,50,55,60,70,80,90,100,110,120,130,140,150,160]

        let selectedTimes = availableTimes.map(e => { return {label: String(e) + ' ' + i18n._('min'), value: String(e)}})

        let testTime = selectedTimes[0]

        if(test.settings.time !== null) {
            let selectedTime = selectedTimes.find(e => String(e.value) === String(test.settings.time))

            if(typeof selectedTime !== 'undefined') {
                testTime = selectedTime
            }
        }

        let title = i18n._('Názov písomky')
        let defaultTitle = i18n._('Písomka č.1')
        let type = i18n._('Typ písomky')
        let notification = i18n._('Odoslať žiakom e-mailovú notifikáciu o novej písomke (1h pred písomkou)')
        let testType = ETestUgType.test

        if((typeof test.settings.ugType !== 'undefined') && (test.settings.ugType === ETestUgType.homework)) {
            title = i18n._('Názov domácej úlohy')
            defaultTitle = i18n._('Domáca úloha č.1')
            type = i18n._('Typ domácej úlohy')
            notification = i18n._('Odoslať žiakom e-mailovú notifikáciu o novej domácej úlohe (e-mail príde v čase "Zobraziť žiakom od"")')
            testType = ETestUgType.homework
        }

        if(showTraining) {
            title = i18n._('Názov cvičenia')
            defaultTitle = i18n._('Cvičenie č.1')
            type = i18n._('Typ cvičenia')
            notification = ''
            testType = ETestUgType.homework
        }

        return (
            <div className="form _alt">
                <div className="summary">
                    <div className="input-row">
                        <div className="part">
                            <label htmlFor="test_name">{title}</label>
                            <div className="input-holder">
                                <input
                                    name={"test_name"}
                                    type="text"
                                    placeholder={defaultTitle}
                                    onChange={this.handleChangeName}
                                    value={test.name}
                                />
                            </div>
                        </div>
                    </div>
                    <SummaryBlock title={i18n._('Nastavenia')} iconName={'cog'}>
                        <div className="input-row">
                            <div className="part">
                                <label htmlFor="test_type">{type} *</label>
                                <div className="input-holder">
                                    <Select
                                        className="customSelect"
                                        classNamePrefix="customSelectWrapper"
                                        value={typeValue}
                                        name="test_type"
                                        options={typeSelect}
                                        onChange={this.handleChangeType}
                                        isSearchable={false}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="input-row">
                            <div className="part">
                                {(typeof typeValue !== 'undefined')?(
                                    <TestTypeInfo type={typeValue.value} />
                                ):(null)}
                            </div>
                        </div>
                        <div className="input-row">
                            <div className="part">
                                <label htmlFor="test_type"><Trans>Náročnosť *</Trans></label>
                                <ul className="inline-list _full form">
                                    <li>
                                        <div className="check radio">
                                            <input 
                                                type="radio" 
                                                name="difficulty" 
                                                id="difficulty_easy" 
                                                value={ETestDifficulty.easy} 
                                                checked={test.settings.difficulty === ETestDifficulty.easy}
                                                onChange={this.handleChangeDifficulty}
                                            />
                                            <label htmlFor="difficulty_easy"><Trans>Ľahká</Trans></label>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="check radio">
                                            <input 
                                                type="radio" 
                                                name="difficulty" 
                                                id="difficulty_medium" 
                                                value={ETestDifficulty.medium} 
                                                checked={test.settings.difficulty === ETestDifficulty.medium}
                                                onChange={this.handleChangeDifficulty}
                                            />
                                            <label htmlFor="difficulty_medium"><Trans>Stredná</Trans></label>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="check radio">
                                            <input 
                                                type="radio" 
                                                name="difficulty" 
                                                id="difficulty_hard" 
                                                value={ETestDifficulty.hard} 
                                                checked={test.settings.difficulty === ETestDifficulty.hard}
                                                onChange={this.handleChangeDifficulty}
                                            />
                                            <label htmlFor="difficulty_hard"><Trans>Ťažká</Trans></label>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="input-row">
                            <div className="part">
                                <ul className="inline-list _full form">
                                    <li>
                                        <div className="check checkbox">
                                            <input 
                                                type="checkbox" 
                                                name="diacritic" 
                                                id="diacritic" 
                                                checked={test.settings.diacritic?test.settings.diacritic:false}
                                                onChange={this.handleChangeDiacritic}
                                            />
                                            <label htmlFor="diacritic"><Trans>Kontrolovať, či je správna diakritika a veľké/malé písmená</Trans></label>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        {(testType === ETestUgType.test)?(
                            <div className="input-row">
                                <div className="part">
                                    <label htmlFor="test_type"><Trans>Časový limit</Trans></label>
                                    <ul className="inline-list _full form">
                                        <li>
                                            <div className="check toggle">
                                                <input 
                                                    type="checkbox" 
                                                    id="showTime"
                                                    onChange={this.handleChangeShowTime}
                                                    checked={showTime}
                                                />
                                                <label htmlFor="showTime"></label>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                {(showTime)?(
                                    <div className="part">
                                        <div className="input-row">
                                            <div className="part">
                                                <label htmlFor="time"><Trans>Trvanie testu (min)</Trans></label>
                                                <div className="input-holder">
                                                <Select
                                                        className="customSelect"
                                                        classNamePrefix="customSelectWrapper"
                                                        value={testTime}
                                                        name="test_time"
                                                        options={selectedTimes}
                                                        onChange={this.handleChangeTime}
                                                        isSearchable={false}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ):(null)}
                            </div>
                        ):(null)}

                        {(test.valid_until !== null)?(
                            <div className="input-row">
                                <div className="part">
                                    <label htmlFor="date_from"><Trans>Zobraziť žiakom od</Trans></label>
                                    <DatePicker
                                        selected={moment(test.valid_from).toDate()}
                                        onChange={this.handleChangeDateFrom}
                                        showTimeSelect
                                        timeFormat="HH:mm"
                                        timeIntervals={15}
                                        timeCaption="Čas"
                                        dateFormat="dd. MM. yyyy HH:mm"
                                        popperClassName="with-time"
                                        shouldCloseOnSelect={true}
                                        id={'date_from'}
                                        locale={this.props.language}
                                    />
                                </div>
                                <div className="part">
                                    <label htmlFor="date_to"><Trans>Zobraziť žiakom do</Trans></label>
                                    <DatePicker
                                        selected={moment(test.valid_until).toDate()}
                                        onChange={this.handleChangeDateTo}
                                        showTimeSelect
                                        timeFormat="HH:mm"
                                        timeIntervals={15}
                                        timeCaption="Čas"
                                        dateFormat="dd. MM. yyyy HH:mm"
                                        popperClassName="with-time"
                                        shouldCloseOnSelect={true}
                                        id={'date_to'}
                                        locale={this.props.language}
                                    />
                                </div>
                            </div>
                        ):(null)}
                        {(test.valid_until !== null && moment(test.valid_from).isAfter(test.valid_until))?(
                            <InformationBox type={"warning"} showBell={true} showClose={false}>
                                <Trans>Dátum a čas zadaný pri "Zobraziť žiakom od" musí byť skôr ako "Zobraziť žiakom do"</Trans>
                            </InformationBox>
                        ):(null)}
                        {(notification !== '')?(
                            <div className="input-row">
                                <div className="part">
                                    <ul className="inline-list _full form">
                                        <li>
                                            <div className="check checkbox">
                                                <input 
                                                    type="checkbox" 
                                                    name="emailNotification" 
                                                    id="emailNotification" 
                                                    checked={test.settings.email?test.settings.email:false}
                                                    onChange={this.handleChangeEmail}
                                                />
                                                <label htmlFor="emailNotification">{notification}</label>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        ):(null)}
                    </SummaryBlock>
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TestSettings)