import React, {Component} from 'react'
import { ITest, ETestUgType, ITestCreate } from '../../../../../constants/types';
import { IApplicationStore } from '../../../../../constants/store-interfaces';
import { connect } from 'react-redux';
import { Trans } from '@lingui/macro';
import { setShowEditTestUserGroupModal } from '../../../../../actions/modals/setShowModal';
import { i18n } from '../../../../../App';
import LoadingElement from '../../../../simple/LoadingElement';
import { editUserGroupTest } from '../../../../../actions/tests/editUserGroupTest';
import moment from 'moment';
import ModalContainer from '../../../containers/ModalContainer';
import ModalHeader from '../../../containers/ModalElements/ModalHeader';
import ModalBody from '../../../containers/ModalElements/ModalBody';
import DatePicker from 'react-datepicker';
import ModalFooter from '../../../containers/ModalElements/ModalFooter';
import InformationBox from '../../InformationBox';


interface IStateProps {
    showModal: boolean,
    loading: boolean,
    language: string
}

interface IDispatchProps {
    setShowEditTestUserGroupModal: typeof setShowEditTestUserGroupModal,
    editTest: typeof editUserGroupTest
}

const mapStateToProps = (state: IApplicationStore): IStateProps => ({
    showModal: state.modalsStore.showEditTestUserGroup,
    loading: state.loadingStore.loadingCreatedTests,
    language: state.globalStore.language
})

const mapDispatchToProps = (dispatch: any): IDispatchProps => ({
    setShowEditTestUserGroupModal: (value: boolean) =>  dispatch(setShowEditTestUserGroupModal(value)),
    editTest: (id: number, data:ITestCreate) => dispatch(editUserGroupTest(id, data))
})

interface IProps extends  IStateProps, IDispatchProps{
    test: ITest
}

interface IState {
    id: number
    new_name: string,
    new_valid_from: string,
    new_valid_to: string | null,
    isValid: boolean
}

class ModalEditTestUserGroup extends Component<IProps, IState> {
    constructor(props:  any)  {
        super(props)
        
        let until = this.props.test.valid_until
            
        this.state = {
            id: this.props.test.id,
            new_name: this.props.test.name,
            new_valid_from: moment(this.props.test.valid_from).toISOString(),
            new_valid_to: (until !== null)?moment(until).toISOString():null,
            isValid: true
        }

        this.editTest = this.editTest.bind(this)
        this.hideModal = this.hideModal.bind(this)
        this.handleNameChange = this.handleNameChange.bind(this)
        this.handleChangeDateFrom = this.handleChangeDateFrom.bind(this)
        this.handleChangeDateTo = this.handleChangeDateTo.bind(this)
    }

    componentDidUpdate(prevProps:  IProps) {
        if(
            (prevProps.test.id !== this.props.test.id) ||
            (prevProps.test.name  !== this.props.test.name) ||
            (prevProps.test.valid_from  !== this.props.test.valid_from) ||
            (prevProps.test.valid_until  !== this.props.test.valid_until)
        ){
            let until = this.props.test.valid_until
            this.setState({
                id: this.props.test.id,
                new_name: this.props.test.name,
                new_valid_from: moment(this.props.test.valid_from).toISOString(),
                new_valid_to: (until !== null)?moment(until).toISOString():null,
                isValid: true
            })
        }
    }

    editTest() {
        const { new_name, new_valid_from, new_valid_to, id, isValid } = this.state
        const { test } = this.props

        if(isValid) {
            let updatedTest: ITestCreate = {
                language_ids: {
                    language_from_id: test.language_from_id,
                    language_to_id: test.language_to_id
                },
                name: new_name,
                settings: test.settings,
                valid_from: new_valid_from,
                valid_until: new_valid_to,
                usergroup_id: test.usergroup_id
            }

            this.props.editTest(id, updatedTest)
        }
    }

    handleNameChange(e:any) {
        this.setState({
            ...this.state,
            new_name: e.target.value,
            isValid: (e.target.value.length > 0)
        })
    }

    handleChangeDateFrom(date: any) {
        this.setState({
            ...this.state,
            new_valid_from: (date === null)?new Date().toISOString():moment(date).toISOString()
        })
    }

    handleChangeDateTo(date: any) {
        let validUntil = new Date()
        validUntil.setDate(new Date().getDate() + 7)

        this.setState({
            ...this.state,
            new_valid_to: (date === null)?validUntil.toISOString():moment(date).toISOString()
        })
    }

    hideModal() {
        this.props.setShowEditTestUserGroupModal(false)
    }

    submitFormHandler(e:  any)  {
        e.preventDefault()
        this.editTest()
    }

    render() {
        const { showModal, loading, test }  = this.props
        const { isValid, new_name, new_valid_to, new_valid_from } =  this.state

        let editText = i18n._('Úprava testu')
        let title = i18n._('Názov testu')

        if((typeof test.settings.ugType !== 'undefined') && (test.settings.ugType === ETestUgType.homework)) {
            editText = i18n._('Úprava domácej úlohy')
            title = i18n._('Názov domácej úlohy')
        }

        let invalidDates = (new_valid_to !== null && moment(new_valid_from).isAfter(new_valid_to))

        return (
            <section id="mainSection">
                <ModalContainer show={showModal}>
                    <ModalHeader title={editText + ' - ' + test.name }/>
                    
                    <ModalBody>
                        <LoadingElement loadingValue={loading}/>

                        <form className="form _alt" onSubmit={this.submitFormHandler}>
                            <div className="form _alt">
                                <div className="input-row">
                                    <div className="part">
                                        <label htmlFor="wordgroup-name">{title} <i>*</i></label>
                                        <div className="input-holder">
                                            <input
                                                name={"testName"}
                                                type="text"
                                                placeholder={i18n._('Test č.1')}
                                                onChange={this.handleNameChange}
                                                value={new_name}
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                                {(new_valid_to !== null)?(
                                    <div className="input-row">
                                        <div className="part">
                                            <label htmlFor="date_from"><Trans>Zobraziť žiakom od</Trans></label>
                                            <DatePicker
                                                selected={moment(new_valid_from).toDate()}
                                                onChange={this.handleChangeDateFrom}
                                                showTimeSelect
                                                timeFormat="HH:mm"
                                                timeIntervals={15}
                                                timeCaption="Čas"
                                                dateFormat="dd. MM. yyyy HH:mm"
                                                popperClassName="with-time"
                                                shouldCloseOnSelect={true}
                                                id={'date_from'}
                                                locale={this.props.language}
                                            />
                                        </div>
                                        <div className="part">
                                            <label htmlFor="date_to"><Trans>Zobraziť žiakom do</Trans></label>
                                            <DatePicker
                                                selected={moment(new_valid_to).toDate()}
                                                onChange={this.handleChangeDateTo}
                                                showTimeSelect
                                                timeFormat="HH:mm"
                                                timeIntervals={15}
                                                timeCaption="Čas"
                                                dateFormat="dd. MM. yyyy HH:mm"
                                                popperClassName="with-time"
                                                shouldCloseOnSelect={true}
                                                id={'date_to'}
                                                locale={this.props.language}
                                            />
                                        </div>
                                    </div>
                                ):(null)}
                                {invalidDates?(
                                    <InformationBox type={"warning"} showBell={true} showClose={false}>
                                        <Trans>Dátum a čas zadaný pri "Zobraziť žiakom od" musí byť skôr ako "Zobraziť žiakom do"</Trans>
                                    </InformationBox>
                                ):(null)}
                                {(isValid)?(null):(
                                    <div className="input-row">
                                        <div className="part">
                                            <div className="input-holder">
                                                <InformationBox type="warning" showClose={false} showBell={true}>
                                                    <Trans>Pre pokračovanie musíte zadať meno!</Trans>
                                                </InformationBox>
                                            </div>
                                        </div>
                                    </div>
                                )}          
                            </div>
                        </form>
                    
                    </ModalBody>

                    <ModalFooter>
                        <div className="part">
                            <button type="button" className="ant-btn bttn _silent href" onClick={this.hideModal}><Trans>Zavrieť</Trans></button>
                        </div>
                        <div className="part">
                            <button type="button" className="ant-btn bttn _primary href" onClick={this.editTest} disabled={!isValid||invalidDates}><Trans>Uložiť zmeny</Trans></button>
                        </div>
                    </ModalFooter>
                </ModalContainer>
            </section>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalEditTestUserGroup)