import React from 'react'
import { ITestResult, ITest, ETestType } from '../../../../../constants/types';
import getTestTypeString from '../../../classes/getTestTypeString';
import { Trans } from '@lingui/macro';
import TestClass from '../../../classes/testClass';
import Percentage from '../../../tableElements/Percentage';
import { i18n } from '../../../../../App';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import TestDifficulty from '../../../tableElements/TestDifficulty';
import TranslateLanguages from '../../../TranslateLanguages';

interface IProps {
    results: ITestResult[]
    test: ITest
    userCount: number
}

const TestDetailTeacher: React.FC<IProps> = props => {
    const typeSelect: { label: string; value: string }[] = []

    Object.keys(ETestType).forEach(value => {
        typeSelect.push({
            label: getTestTypeString(ETestType[(value as ETestType)]),
            value: ETestType[(value as ETestType)]
        })
    })

    let results = props.results.filter(r => (r.test_id === props.test.id))

    const typeValue = typeSelect.find(value => ((typeof props.test !== 'undefined') && (value.value === props.test.settings.type)))

    let percentage: number = 0
    let percentageUsers: number = 0

    if(results.length) {
        let cntPoints = 0
        let maxPoints = 0
        let users: number[] = []

        results.forEach(tR => {
            let testObject = new TestClass(props.test, tR)
            
            cntPoints += testObject.getTestRate()
            maxPoints += testObject.getMaximumRate()

            if((typeof tR.user_id !== 'undefined') && (!users.includes(tR.user_id))) {
                users.push(tR.user_id)
            }
        })

        percentage = Math.round(Number(cntPoints)/maxPoints*100)

        if(props.userCount > 0) {
            percentageUsers = Math.round(Number(users.length)/props.userCount*100)
        }
    }

    let valid = false

    if(moment(props.test.valid_from).isBefore(moment()) && (props.test.valid_until !== null) && (moment(props.test.valid_until).isAfter(moment()))) {
        valid = true
    }

    return (    
        <section className="item">
            <header className="item_header">
                <div className="part">
                    <h3>
                        {props.test.name}
                    </h3>
                </div>
                <div className="part">
                    <span className={"tag " + ((valid)?'_green':'_red')}>
                        {(valid)?(
                            <Trans>Aktívna</Trans>
                        ):(
                            <Trans>Neaktívna</Trans>
                        )}
                    </span>
                </div>
            </header>
            <div className="item_body">

                <div className="item-types">
                    <ul>
                        <li>
                            <div className="type">
                                <div className="type_title">
                                    <Trans>Preklad</Trans>
                                </div>
                                <div className="type_desc type-languages">
                                    <TranslateLanguages
                                        languageFrom={props.test.language_from}
                                        languageTo={props.test.language_to}
                                    />
                                </div>
                            </div>
                        </li>
                        <li>
                            
                            <div className="type">
                                <div className="type_title">
                                    <Trans>Počet slovíčok</Trans>
                                </div>
                                <div className="type_desc">
                                    {props.test.settings.words.length}
                                </div>
                            </div>

                        </li>
                        <li>

                            <div className="type">
                                <div className="type_title">
                                    <Trans>Typ písomky</Trans>
                                </div>
                                <div className="type_desc">
                                    {(typeof typeValue !== 'undefined')?typeValue.label:''}{(typeof props.test.settings.diacritic !== 'undefined')?(props.test.settings.diacritic)?', ' + i18n._('s diakritikou'):', ' + i18n._('bez diakritiky'):', ' + i18n._('bez diakritiky')} {(props.test.settings.time !== null)?'(' + props.test.settings.time + ' ' + i18n._('min') + ')':''}
                                </div>
                            </div>

                        </li>
                        <li>

                            <div className="type">
                                <div className="type_title">
                                    <Trans>Náročnosť</Trans>
                                </div>
                                <div className="type_desc">
                                    <TestDifficulty difficulty={props.test.settings.difficulty} />
                                </div>
                            </div>

                        </li>
                    </ul>
                </div>

                <div className="item-graphs">
                    <ul>
                        <li>

                            <div className="item-graph">
                                <div className="item-graph_title">
                                    Priemerná úspešnosť
                                </div>
                                <Percentage percentage={percentage} title={i18n._('Priemerná úspešnosť')}/>
                            </div>
                            
                        </li>
                        <li>

                            <div className="item-graph">
                                <div className="item-graph_title">
                                    Pomer žiakov ktorí ukončili písomku
                                </div>
                                <Percentage percentage={percentageUsers} title={i18n._('Žiaci ktorí ukončili písomku')}/>
                                
                            </div>
                            
                        </li>
                    </ul>
                </div>

            </div>
            <footer className="item_footer">
                <div className="part">
                    
                    <div className="part-list">
                        <NavLink to={"/app/classes/" + props.test.usergroup_id + "/tests/" + props.test.id + "/detail"} target="_self" className="tag _primary _alt">
                            <Trans>Detail</Trans>
                        </NavLink>
                    </div>

                </div>
                <div className="part">
                    
                    <div className="date">
                        <Trans>platí do</Trans>&nbsp;
                        <span>
                            {(props.test.valid_until !== null)?moment(props.test.valid_until).format('DD.MM.YYYY'):moment(props.test.valid_from).format('DD.MM.YYYY')}
                            <br/>
                            {(props.test.valid_until !== null)?moment(props.test.valid_until).format('HH:mm'):moment(props.test.valid_from).format('HH:mm')}
                        </span>
                    </div>

                </div>
            </footer>
        </section>
    )
}

export default TestDetailTeacher