import React, { useState, useRef } from 'react';
import { Trans } from '@lingui/macro';
import { IQuestionVariantFolding } from '../../../../../constants/types';
import posed from 'react-pose';
import { useEffect } from 'react';
import Draggable, { DraggableData } from 'react-draggable';

const Item = posed.div({
    enter: { y: 0, opacity: 1},
    exit: { y: 50, opacity: 0 }
})

const ItemTitle = posed.div({
    enter: { opacity: 1},
    exit: { opacity: 0 }
})

interface IProps {
    question: IQuestionVariantFolding,
    response: (answer: string) => any,
    questionId: number,
    disabled: boolean,
    answer: string
}

export default function FoldingQuestion(props: IProps) {
    const itemsRef = useRef<HTMLDivElement[]>([])
    const charactersRef = useRef<HTMLDivElement[]>([])
    const [, updateState] = React.useState<any>();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [focused, setFocused] = useState<number|null>(null)
    const [zIndexes, setZIndexes] = useState<number[]>(Array(props.question.response.length+1).fill(1))
    const [positions, setPositions] = useState<{i:number|null, x:number, y:number}[]>([])
    const [shouldUpdate, setShouldUpdate] = useState(true)
    
    useEffect(() => {
        
        let newArray: {i:number|null, x:number, y:number}[] = []

        props.question.characters.forEach((e,i) => {
            newArray.push({
                i: null,
                x: 0,
                y: 0
            })
        })
        
        setPositions(newArray)
    }, [props.question.characters])

    useEffect(() => {
        itemsRef.current = itemsRef.current.slice(0, props.question.response.length)
    }, [props.question.response])

    useEffect(() => {
        setShouldUpdate(true)
    }, [props.questionId])

    useEffect(() => {
        window.addEventListener('resize', () => setShouldUpdate(true))
    }, [])

    useEffect(() => {
        if(!shouldUpdate) {
            return
        }

        setTimeout(() => {
            let response = props.question.response

            let was:number[] = []

            itemsRef.current.forEach((ref, i) => {

                if((response.length >= i) && (response[i] !== '_')) {
                    let index = -1

                    props.question.characters.forEach((c,j) => {
                        if(!was.includes(j) && (index === -1)) {
                            if(c === response[i]) {
                                index = j
                                was.push(j)
                                return
                            }
                        }
                    })

                    if(index !== -1) {
                        let characterRef = charactersRef.current[index]

                        let newX = ref.offsetLeft - characterRef.offsetLeft
                        let newY = ref.offsetTop - characterRef.offsetTop

                        positions[index] = {
                            i: i,
                            x: newX,
                            y: newY
                        }
                    }
                }
            })
            
            setPositions(positions)

            setShouldUpdate(false)
            
            forceUpdate()
        }, 450)

    }, [shouldUpdate, props.question.response, props.question.characters, positions, forceUpdate])

    const onControlledDrag = (e: any, position: DraggableData) => {
        let rect1 = position.node.getBoundingClientRect()
        let overlapX = (rect1.right - ((rect1.right - rect1.left)/2))
        let overlapY = (rect1.bottom - ((rect1.bottom - rect1.top)/2))

        positions[Number(position.node.id.substr(5))] = {
            i: null,
            x: position.x,
            y: position.y
        }

        setPositions(positions)

        itemsRef.current.forEach((ref, i) => {
            let rect2 = ref.getBoundingClientRect()
            
            var overlap = (
                (rect2.left <= overlapX) && 
                (rect2.right >= overlapX) &&
                (rect2.bottom >= overlapY) && 
                (rect2.top <= overlapY)
            )

            if(overlap) {
                setFocused(i)
            }
        })
    }

    const onDragStart = (e: any, position: DraggableData) => {
        let id = Number(position.node.id.substr(5))
        let newIndex = Math.max.apply(Math, zIndexes)

        zIndexes[id] = (isNaN(newIndex))?1:newIndex+1

        setZIndexes(zIndexes)
    }

    const onDragStop = (e: any, position: DraggableData) => {
        setFocused(null)

        positions[Number(position.node.id.substr(5))] = {
            i: null,
            x: position.x,
            y: position.y
        }

        setPositions(positions)
        
        forceUpdate()

        let rect1 = position.node.getBoundingClientRect()
        let overlapX = (rect1.right - ((rect1.right - rect1.left)/2))
        let overlapY = (rect1.bottom - ((rect1.bottom - rect1.top)/2))

        let newPositions = false

        itemsRef.current.forEach((ref, i) => {
            
            let rect2 = ref.getBoundingClientRect()
            
            // var overlap = !(rect1.right < rect2.left || 
            //     rect1.left > rect2.right || 
            //     rect1.bottom < rect2.top || 
            //     rect1.top > rect2.bottom)
            var overlap = (
                (rect2.left <= overlapX) && 
                (rect2.right >= overlapX) &&
                (rect2.bottom >= overlapY) && 
                (rect2.top <= overlapY)
            )

            if(overlap) {

                let newX = ref.offsetLeft - position.node.offsetLeft
                let newY = ref.offsetTop - position.node.offsetTop
                let newI = Number(position.node.id.substr(5))

                positions.forEach((e,index) => {
                    if(e.i === i) {
                        positions[index] = {
                            i: null,
                            x: 0,
                            y: 0
                        }
                    }
                })

                positions[newI] = {
                    i: i,
                    x: newX,
                    y: newY
                }

                newPositions = true
                
                setPositions(positions)

                forceUpdate()
            }
        })

        if(!newPositions) {
            positions[Number(position.node.id.substr(5))] = {
                i: null,
                x: 0,
                y: 0
            }
    
            setPositions(positions)
            
            forceUpdate()
        }

        let result = Array.from(props.question.response).fill('_')

        positions.forEach((p,i) => {
            if(p.i !== null) {
                result[p.i] = props.question.characters[i]
            }
        })

        props.response(result.join(''))
    }

    let wordToFind = (props.question.wType === 'from')?props.question.word.to:props.question.word.from

    let wordsToFind = wordToFind.split(/(\s+)/).filter( e => e.trim().length > 0)

    return (
        <>
            <ItemTitle>
                <h1><Trans>Preložte slovo</Trans> <span>{(props.question.wType === 'from')?props.question.word.from:props.question.word.to}</span> ?</h1>
            </ItemTitle>
            <Item>
                <div 
                    className="test-answer folding-question"
                >
                    {props.question.characters.map((c: string, i: number) => (
                            <Draggable
                                defaultPosition={{x: 0, y: 0}}
                                bounds="parent"
                                onDrag={onControlledDrag}
                                onStart={onDragStart}
                                onStop={onDragStop}
                                key={i}
                                position={positions[i]}
                            >
                                <div 
                                    className="character"
                                    id={'char_' + i}
                                    ref={el => {
                                        if(el !== null) {
                                            charactersRef.current[i] = el
                                        }
                                    }} 
                                    style={{
                                        zIndex: zIndexes[i]
                                    }}  
                                >
                                    <span>{c}</span>
                                </div>
                            </Draggable>
                    ))}

                    <div className="answer">
                        <div className="multi-input">
                            {wordsToFind.map((word: string, index: number) => (
                                <ul className="word" key={index}>
                                    {Array.from(word).map((w:string,i:number) => {
                                        let idChar = i
                                        
                                        wordsToFind.forEach((k,l) => {
                                            if(l >= index) {
                                                return
                                            }

                                            idChar += k.length
                                        })

                                        return (
                                            <li key={i}>
                                                <div 
                                                    className={"folding-answer-field " + ((focused === idChar)?'focused':'')}
                                                    ref={el => {
                                                        if(el !== null) {
                                                            itemsRef.current[idChar] = el
                                                        }
                                                    }} 
                                                >
                                                    _
                                                </div>
                                            </li>
                                        )
                                    })}
                                </ul>
                            ))}
                        </div>
                    </div>
                </div>
            </Item>
        </>
    )
}