import React, { PropsWithChildren, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { hideAllModals } from '../../../actions/modals/hideAllModals';

interface IProps {
    show: boolean,
    customHide?: () => any
}

const ModalContainer: React.FC<PropsWithChildren<IProps>> = props => {    
    const dispatch = useDispatch()

    useEffect(() => {
        if(props.show) {
            document.body.classList.add('modal-open')
        } else {
            document.body.classList.remove('modal-open')
        }
    }, [props.show])

    const hideModal = () => {
        if(typeof props.customHide !== 'undefined') {
            props.customHide()
        } else {
            dispatch(hideAllModals())
        }
    }

    const handleOnClick = (event:any) => {
        // if(event.target === event.currentTarget) {
        //     event.preventDefault()

        //     hideModal()
        
        // }
    }
    
    if(props.show) {
        return (
            <div className="modalWrapper" onMouseDown={handleOnClick}>
                <div className="close -wrapper" onClick={() => hideModal()}><i className="la la-times-circle"/></div>

                <div className="modal-window _shadow _radius modal">
                    
                    <div className="close" onClick={() => hideModal()}><i className="la la-times-circle"/></div>
                    
                    {props.children}
                    
                </div>
            </div>
        )
    } else  {
        return (null)
    }
}

export default ModalContainer