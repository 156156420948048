import React, {Component} from 'react'
import { connect } from 'react-redux'
import { Switch, Route, RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { IApplicationStore } from '../../constants/store-interfaces';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

//Pages component
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import HomePage from './pages/HomePage';
import RestorePasswordPage from './pages/RestorePasswordPage';
import HeaderLogin from './global/HeaderLogin';
import { v4 as uuidv4 } from 'uuid';
import ShareTestPage from './pages/ShareTestPage';

interface IStateProps {
    authenticated: boolean,
    loading: boolean,
    language: string
}
const mapStateToProps = (state: IApplicationStore): IStateProps => ({
    authenticated: state.globalStore.userLoggedIn,
    loading: state.loadingStore.loading,
    language: state.globalStore.language
})

const mapDispatchToProps = (dispatch: any) => ({
})

interface IProps extends IStateProps, RouteComponentProps<any> {

}

interface IState {
    key: string
}

class MainHome extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props)

        this.state = {
            key: uuidv4()
        }
    }

    componentDidUpdate(prevProps: IProps, prevState: IState) {
        if(prevProps.language !== this.props.language) {
            this.setState({
                ...this.state,
                key: uuidv4()
            })   
        }
    }

    render() {
        const {location} = this.props
        
        return (
            <div className="login-form-holder _bgSilent" key={this.state.key}>
                <HeaderLogin/>
                <div className="wrapper">
                    <div className="login-form">
                        <div className="bubbleBig"></div>
                        
                            <TransitionGroup>
                                <CSSTransition
                                    key={location.key}
                                    timeout={{ enter: 300, exit: 300 }}
                                    classNames={'fade'}
                                >
                                    <div className="wrapper">
                                        <Switch location={location}>
                                            <Route exact path='/' component={HomePage}/>
                                            <Route exact path={[
                                                '/login',
                                                '/login/:type'
                                            ]} component={LoginPage}/>
                                            <Route exact path='/register' component={RegisterPage}/>
                                            <Route exact path='/restore-password' component={RestorePasswordPage}/>
                                            <Route exact path='/share' component={ShareTestPage}/>
                                        </Switch>
                                    </div>
                                </CSSTransition>
                            </TransitionGroup>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MainHome))
