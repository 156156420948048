import { IRelease, IUSerInfo } from '../../../constants/types';
import { i18n } from '../../../App';
import moment from 'moment';

class ReleasesClass {
    private releases: IRelease[] = [
        {
            date: '2020-02-01T10:30:56',
            versionNumber: '0.0.1',
            type: 'small',
            notes: [
                i18n._('Prvá verzia')
            ]
        },
        {
            date: '2020-02-10T15:38:32',
            versionNumber: '0.0.2',
            type: 'small',
            notes: [
                i18n._('Pridaná časť pre slovíčka'),
                i18n._('Práca so slovíčkami, ich vytváranie, úpravy, vymazávanie')
            ]
        },
        {
            date: '2020-02-21T07:32:24',
            versionNumber: '0.0.3',
            type: 'small',
            notes: [
                i18n._('Pridané skupiny slovíčok, ich editácie, úpravy, modifikácie')
            ]
        },
        {
            date: '2020-03-03T12:28:11',
            versionNumber: '0.0.4',
            type: 'small',
            notes: [
                i18n._('Upravené a pridané triedy, modifikácie tried, vymazávania, pridávanie/odoberanie žiakov')
            ]
        },
        {
            date: '2020-03-18T16:52:11',
            versionNumber: '0.0.5',
            type: 'small',
            notes: [
                i18n._('Upravené a pridané testy, ich modifikácie, vymazávania')
            ]
        },
        {
            date: '2020-04-10T20:29:11',
            versionNumber: '0.0.6',
            type: 'small',
            notes: [
                i18n._('Pridaná prvá verzia testov s výsledkami'),
                i18n._('Pridaný detail testu'),
                i18n._('Štatistiky z výsledkov testu, pridané grafy'),
                i18n._('Pridané prvé typy testov - skladanie, vyberanie, dopĺňanie')
            ]
        },
        {
            date: '2020-05-01T11:55:11',
            versionNumber: '0.0.7',
            type: 'small',
            notes: [
                i18n._('Opravené vytváranie testu cez "plus" tlačidlo, slovíčka sa automaticky predvyberú'),
                i18n._('Pridaný modal pre vytváranie testov priamo z detailu skupiny slovíčok (vlastných)'),
                i18n._('Pridaná štatistika do detailu skupiny slovíčok ku každému slovíčku + list mojej aktivity v danej skupine slovíčok s modalom'),
                i18n._('Pridaná možnosť vytvárať testy zo stránky slovíčok'),
                i18n._('Opravený jazyk pre výslovnosť na mobilných zariadeniach, kde niektoré prehliadače využívajú iné názvy jazykov'),
                i18n._('Štatistika v prehľade slovíčok - používateľ vidí slovíčka ktoré mu idú ľahšie a ktoré ťažšie'),
                i18n._('Optimalizovaný prehľad v štatistike pri slovíčkach - lazy loading'),
                i18n._('Fixnutý modal pri regenerovaní kódu v rámci triedy'),
                i18n._('Opravené pridávanie slovíčok do tried'),
                i18n._('Možnosť pridávať testy a domáce úlohy do tried '),
                i18n._('Pridaný responsivevoice pre čítanie slov'),
                i18n._('Pridaná možnosť editovať/odstraňovať domáce úlohy/testy z tried'),
                i18n._('Fixnuté zobrazenie menu a klik mimo neho na mobiloch'),
                i18n._('Rozdelené slovíčka, testy do podkategórií v menu'),
                i18n._('Možnosť vytvárať cvičenia/písomky/domáce úlohy')
            ]
        },
        {
            date: '2020-05-22T07:58:11',
            versionNumber: '0.0.8',
            type: 'small',
            notes: [
                i18n._('Zobrazenie písomiek/cvičení/domácich úloh pre žiaka'),
                i18n._('Možnosť vykonať domácu úlohu a test za žiaka'),
                i18n._('Pridaný a upravený detail testu a domácej úlohy pre študenta'),
                i18n._('Možnosť zobraziť si písomky a domáce úlohy v rámci triedy pre študentov'),
                i18n._('Pridané zobrazenie priemernej úspešnosti pri žiakoch'),
                i18n._('Pridané zobrazenie úspešnosti pri testoch a domácich úlohach pre učiteľa'),
                i18n._('Pridaná zobrazenie prehľadu domácej úlohy pre učiteľov spolu s detailnými štatistikami pre jednotlivých žiakov a ich slovíčka'),
                i18n._('Pridaný prehľad testu pre učiteľov spolu s detailnými štatistikami pre jednotlivých žiakov a ich slovíčka'),
                i18n._('Pridané zobrazenie detailu žiaka pre učiteľa'),
                i18n._('Pridaný prehľad aktualít z jednotlivých tried na domovskej stránke'),
                i18n._('Pridaná ranking tabuľka pre štát/svet'),
                i18n._('Pridaný prehľad aktivity pre používateľa + samostatná stránka pre aktivity'),
                i18n._('Pridaný prehľad verzií aplikácie na domovskej stránke + samostatná stránka pre verzie aplikácie')
            ]
        },
        {
            date: '2020-06-11T18:32:11',
            versionNumber: '0.0.9',
            type: 'small',
            notes: [
                i18n._('Opravy chýb'),
                i18n._('Pridané zobrazenie detailu priradenej skupiny slovíčok z triedy a možnosť vytvárať si cvičenia sám pre seba'),
                i18n._('Vizuálne úpravy, úpravy vytvárania skupín, zapracovanie množstva chýb'),
                i18n._('Pridaná možnosť kopírovať link/kód z triedy pre učiteľa'),
                i18n._('Pridaná možnosť zmeny názvu svojho vytvoreného cvičenia'),
                i18n._('Pridaný modal pri ukončení testu, a ukladanie písomky pre učiteľa, ak ju žiak ukončí uprostred písomky'),
                i18n._('Pridaný tutoriál pre užívateľov aj učiteľov'),
                i18n._('Pridaná možnosť kontaktovania - feedback/kontaktný formulár'),
                i18n._('Pridaná možnosť zmeny hesla používateľa'),
                i18n._('Pridaný formulár na obnovenie hesla'),
                i18n._('Zobrazovanie nových verzií aplikácie pre používateľov')
            ]
        },
        {
            date: '2020-08-17T05:32:11',
            versionNumber: '0.1.0',
            type: 'medium',
            notes: [
                i18n._('Opravy chýb'),
                i18n._('Opravené zobrazenie prekladov podľa prihláseného používateľa'),
                i18n._('Pridaný jazyk zobrazenia podľa jazyku prehliadača'),
                i18n._('Pridaná možnosť meniť jazyk v prihlasovaní/registrácii'),
                i18n._('Pridané notifikácie v iných jazykoch'),
                i18n._('Opravené animácie'),
                i18n._('Pridaný názov školy pre učiteľov'),
                i18n._('Zlepšené vykonávanie testov'),
                i18n._('Pridané e-maily'),
                i18n._('Možnosť vytvárať testy zo slovíčok ktoré nemajú skupinu')
            ]
        },
        {
            date: '2020-09-23T01:03:11',
            versionNumber: '0.1.1',
            type: 'small',
            notes: [
                i18n._('Opravy chýb'),
                i18n._('Pridané informácie o úspešnosti slovíčok jednotlivých tried v skupine slov pre učiteľa'),
                i18n._('Opravené preklady'),
                i18n._('Nový spôsob generovania testov s výberom možností'),
                i18n._('Opravená štatistika spolu so slovíčkami ktoré máme priradené')
            ]
        },
        {
            date: '2020-11-17T21:28:33',
            versionNumber: '0.1.2',
            type: 'small',
            notes: [
                i18n._('Opravy chýb'),
                i18n._('Upravené vytváranie domácej úlohy/písomky pre učiteľa. Odteraz je možné zadávať písomku/domácu úlohu tak, aby ju žiaci videli a mali možnosť ju vyplniť len určitý čas v rámci jedného dňa.')
            ]
        },
        {
            date: '2020-11-23T06:27:33',
            versionNumber: '0.1.3',
            type: 'small',
            notes: [
                i18n._('Upravené hodnotenia výsledkov cvičení/domácich úloh na základe náročnosti odpovedí.')
            ]
        },
        {
            date: '2021-01-19T04:37:22',
            versionNumber: '0.2',
            type: 'big',
            notes: [
                i18n._('Pridaná možnosť zdielať svoj výsledok z testu/domácej úlohy alebo vlastného cvičenia na sociálnej sieti Facebook'),
                i18n._('Možnosť zdieľať na Facebook svoje umiestnenie v triede, v krajine a na svete. Ukážte svojim kamarátom, kto je tu šéf! :)'),
                i18n._('Učitelia majú možnosť generovať certifikáty a diplomy pre svojich žiakov. Nová funkcionalita sa nachádza v triede v zozname žiakov.'),
                i18n._('Pridaná možnosť automatického automatického našepkávania slov pri vytváraní skupín slovíčok. Stačí zapnúť "Zobrazovať návrhy prekladov" pri vytváraní novej skupiny slov.'),
                i18n._('Učitelia môžu odstrániť výsledky jednotlivých žiakov z testov. Stačí prejsť do detailu testu a pri zozname žiakov majú novú môžnosť odstrániť výsledok.'),
                i18n._('Možnosť vytvárať nové skupiny vybratím slov z existujúcich skupín slov. V detaile skupiny slov sa po zaškrtnutí vybratých slov zobrazí v spodnej časti nové tlačidlo pre vytvorenie novej skupiny slov.'),
                i18n._('Apostrofy je možné zadávať rôznymi spôsobmi, nebudú vyhodnocované ako chyby ak sa použije nesprávny symbol, ktorý reprezentuje apostrof.'),
                i18n._('Pri registrácii žiakov je automaticky overovaný zadávaný registračný kód, kde žiaci hneď vidia do akej triedy budú prihlásení. Ak zadajú nesprávny kód, budú na to upozornení.'),
                i18n._('Pridaný pomocník pri prihlasovaní ktorý našepká možné alternatívy prihlasovacieho mena, v prípade ak žiak zabudol svoje meno.'),
                i18n._('Odstránené automatické zapínanie veľkých písmen na mobilných zariadeniach'),
                i18n._('Rozsiahle opravy chýb'),
                i18n._('Upravený server pre rýchlejšie fungovanie aplikácie'),
                i18n._('Zmenšenie aplikácie a jej rýchlejšie načitávanie'),
                i18n._('Zlepšenie generovania testov'),
                i18n._('Pridané preklady jazykov'),
                i18n._('Zlepšená bezpečnosť aplikácie.'),
                i18n._('Opravené slovníky z ktorých sa generujú testy.'),
                i18n._('Opravený problém pri pop-up oknách a ich nechcenom vypínaní.'),
            ]
        },
        {
            date: '2021-01-31T00:30:22',
            versionNumber: '0.2.1',
            type: 'medium',
            notes: [
                i18n._('Aplikácia po novom poskytuje jednoduché prihlásenie a registráciu prostredníctvom Facebook/Google účtu.'),
                i18n._('Odteraz máte možnosť získavať body, ktoré sa Vám počítajú v špeciálnych mesačných rebríčkoch. Môžete sa tešiť na zaujímavé súťaže. Viac info na našom Facebooku Alphie už čoskoro.'),
                i18n._('Opravy chýb.')
            ],
            notesTeacher: [
                i18n._('Po kliknutí na detail žiaka v zozname žiakov máte možnosť priamo generovať diplomy pre jednotlivcov.'),
                i18n._('Ak máte vytvorené balíky slovíčok, ktoré chcete poslať svojim kolegom učiteľom, a ušetriť im tak čas vytvárania rovnakých skupín slovíčok, stačí si otvoriť vybranú skupinu slovíčok a napravo hore je nové tlačidlo "Odoslať skupinu kolegovi".'),
                i18n._('V novej verzii je automaticky zapnutá možnosť odosielať notifikácie študentom, ktorí tak budú dostávať e-mailové notifikácie týkajúce sa tried do ktorých sú pridaní. Vždy tak budú vedieť, či dostali novú domácu úlohu, alebo im bola pridelená nová skupina slovíčok. V správny čas dostanú upozornenie o pripravovanom teste, aby sa stihli čím skôr pripraviť. Takýmto spôsobom sa nebudú môcť vyhovoriť že "zabudli" :)'),
                i18n._('Pridali sme nový graf aktivity žiaka. Po kliknutí na zobrazenie detailu žiaka (klik na "Detail žiaka" v zozname žiakov a  potom opäť na "Lupu"), sa dostanete na detailný prehľad aktivity žiaka, kde pribudol prehľadný graf o posledných aktivitách Vašich žiakov. Na jednom mieste tak vidíte, či sa žiaci pripravujú systematicky alebo nárazovo.')
            ],
            notesUser: [
                i18n._('Začni súťažiť s novými mesačnými výzvami. Už v mesiaci február Ťa čaká zaujímavá súťaž, a víťaz z najväčším množstvom získaných bodov bude odmenený zaujímavou cenou. Viac informácií už čoskoro na našom Facebooku.')
            ]
        },
        {
            date: '2021-02-01T22:20:22',
            versionNumber: '0.2.2',
            type: 'small',
            notes: [
                i18n._('Opravy chýb najmä pri vypĺňaní cvičení, domácich úloh a testov'),
                i18n._('Pridaná možnosť v prípade potreby priamo komunikovať s tímom Alphie (vpravo dole pribudol Messenger :) )'),
            ],
            notesTeacher: [
                i18n._('Zaznamenali sme chyby pri vypĺňaní domácich úloh/testov pre žiakov. Najmä tieto chyby by mali byť odstránené. Ak by ste však aj naďalej takúto chybu evidovali, prosíme Vás aby ste nám ju nahlásili na našu FB stránku, alebo priamo na e-mail info@alphie.app')
            ]
        }
    ]
    
    private currentRelease: IRelease

    constructor(userInfo: IUSerInfo | null) {
        if(userInfo === null) {
            this.currentRelease = this.releases[this.releases.length-1]
        } else {
            this.currentRelease = this.releases[this.releases.length-1]
        }
    }

    public getLastNReleasesReversed(n: number): IRelease[] {
        let index = this.releases.indexOf(this.currentRelease)

        if(index > 0) {
            let start = Math.max(index - n, 0)
            let end = index

            return this.releases.slice(start+1, end+1).reverse()
        } else {
            return []
        }
    }

    public getReleasesReversed(): IRelease[] {
        return this.releases.reverse()
    }

    public getLastRelease(): IRelease {
        return this.releases[this.releases.length-1]
    }

    public getCurrentRelease(): IRelease {
        return this.currentRelease
    }

    public getCurrentReleaseID(): number {
        return this.releases.indexOf(this.currentRelease)
    }

    public getReleaseAfterDate(date: string): IRelease[] {
        let returnReleases:IRelease[] = []

        this.releases.forEach(r => {
            if(moment(r.date).isAfter(moment(date))) {
                returnReleases.push(r)
            }
        })

        return returnReleases
    }
}

export default ReleasesClass