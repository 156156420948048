import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { IApplicationStore } from '../../../../constants/store-interfaces';
import { setShowMenu } from '../../../../actions/global/setShowMenu';
import { Trans } from '@lingui/macro';
import { IUSerInfo, IUserGroupType } from '../../../../constants/types';
import config from '../../../../config';

interface IProps  {

}

const SidebarMenu: React.FC<IProps> = props => {
    const dispatch = useDispatch()
    const showMenu = useSelector<IApplicationStore, boolean>(state => state.globalStore.showMenu)
    const userLoggedIn = useSelector<IApplicationStore, boolean>(state => state.globalStore.userLoggedIn)
    const userInfo = useSelector<IApplicationStore, IUSerInfo | null>(state => state.globalStore.userInfo)
    const createdUserGroups = useSelector<IApplicationStore, IUserGroupType[]>(state => state.userGroupsStore.createdUserGroups)
    const assignedUserGroups = useSelector<IApplicationStore, IUserGroupType[]>(state => state.userGroupsStore.assignedUserGroups)

    const hideMenu = () => {
        if(showMenu) {
            dispatch(setShowMenu(false))
        }
    }
    
    return (
        <div className="app-nav">
            <nav>
                <ul>
                    <li>
                        <NavLink to={"/app"} exact  onClick={() => hideMenu()}>
                            <div>
                                <i className="la la-home"/>
                                <Trans>Prehľad</Trans>
                            </div>
                        </NavLink>
                    </li>
                    <li data-tour="step11">
                        <NavLink to={"/app/words"}  onClick={() => hideMenu()}>
                            <div>
                                <i className="la la-list-alt"/>
                                <Trans>Slovíčka</Trans>
                            </div>
                        </NavLink>
                    </li>
                    <li data-tour="step9">
                        <NavLink to={"/app/wordgroups"}  onClick={() => hideMenu()}>
                            <div>
                                <i className="la la-th-list"/>
                                <Trans>Skupiny slovíčok</Trans>
                            </div>
                        </NavLink>
                    </li>
                    <li data-tour="step15">
                        <NavLink to={"/app/trainings"}  onClick={() => hideMenu()}>
                            <div>
                                <i className="la la-laptop"/>
                                <Trans>Moje cvičenia</Trans>
                            </div>
                        </NavLink>
                    </li>
                    {(userLoggedIn && (userInfo !== null) && (userInfo.role.name === config.roles.user) && ((assignedUserGroups.length > 0) || (createdUserGroups.length > 0)))?(
                        <>
                            <li>
                                <NavLink to={"/app/homeworks"} onClick={() => hideMenu()}>
                                    <div>
                                        <i className="la la-mortar-board"/>
                                        <Trans>Domáce úlohy</Trans>
                                    </div>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={"/app/tests"} onClick={() => hideMenu()}>
                                    <div>
                                        <i className="la la-edit"/>
                                        <Trans>Písomky</Trans>
                                    </div>
                                </NavLink>
                            </li>
                        </>
                    ):(
                        null
                    )}
                    <li data-tour="step17">
                        <NavLink to={"/app/classes"} onClick={() => hideMenu()}>
                            <div>
                                <i className="la la-group"/> 
                                <Trans>Triedy</Trans>
                            </div>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to={"/app/rankings"} onClick={() => hideMenu()}>
                            <div>
                                <i className="la la-area-chart"/> 
                                <Trans>Rebríčky</Trans>
                            </div>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to={"/app/settings"} onClick={() => hideMenu()}>
                            <div>
                                <i className="la la-gear"/> 
                                <Trans>Nastavenia</Trans>
                            </div>
                        </NavLink>
                    </li>
                </ul>
            </nav>
        </div>
    )
}

export default SidebarMenu
