import { setLoadingCreatedWordGroups } from '../loadings/setLoading';
import config from '../../config';
import { handleErrors } from '../../utils/handleErrors';
import { STORE_WORDGROUPS_STARTED, STORE_WORDGROUPS, STORE_WORDGROUPS_FAILED } from '../../constants/action-types';
import { store } from '../../store';
import { addFloatingNotification } from '../notifications/floating';
import { i18n } from '../../App';
import { setShowCreateWordModal } from '../modals/setShowModal';
import { loadCreatedWords } from '../words/loadCreatedWords';
import { loadCreatedWordGroups } from './loadCreatedWordGroups';
import { history } from '../../..';

export function storeWordGroups(data:any, redirectTo?: boolean) {
    return (dispatch: any) => {

        dispatch(setLoadingCreatedWordGroups(true))
        dispatch(storeWordGroupsStarted())

        return fetch(config.api.wordgroups, {
            method: 'POST',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + store.getState().globalStore.token
            },
            body: JSON.stringify(data)
            
        })
            .then(handleErrors)
            .then(res => res.json())
            .then(res => {

                dispatch(setLoadingCreatedWordGroups(false))
                dispatch(setShowCreateWordModal(false))
                dispatch(loadCreatedWords())
                dispatch(loadCreatedWordGroups())

                for(let wordGroup of  res.data) {
                    dispatch(storeWordGroupsSuccess(wordGroup))

                    dispatch(addFloatingNotification({
                        type:"success",
                        text:i18n._('Úspešne ste pridali slovíčka do skupiny') + ' ' + wordGroup.name,
                        showBell: true
                    }))
                }

                if(res.data.length === 1) {
                    if(redirectTo) {
                        history.push({
                            pathname: '/app/wordgroups/' + res.data[0].id
                        })
                    }
                }

            })
            .catch((error: any)  => {
                dispatch(storeWordGroupsFailed(error))
                dispatch(setLoadingCreatedWordGroups(false))
            });
    };
}

export const storeWordGroupsStarted = () => ({
    type: STORE_WORDGROUPS_STARTED
});

export const storeWordGroupsSuccess = (data: any[]) => ({
    type: STORE_WORDGROUPS,
    payload: data
});

export const storeWordGroupsFailed = (error: any) => ({
    type: STORE_WORDGROUPS_FAILED,
    payload: { error }
});