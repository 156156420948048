import React, {Component, PropsWithChildren} from 'react'
import { IRanking } from '../../../../../constants/types';
import ReactCountryFlag from 'react-country-flag';
import { i18n } from '../../../../../App';


interface IProps {
    ranking: IRanking,
    maxRate: number,
    isMe: boolean,
    hideLanguage?: boolean
}

class RankingRow extends Component<PropsWithChildren<IProps>> {

    render() {
        const { ranking, maxRate, isMe, hideLanguage } = this.props

        let rateTitle = i18n._('bodov')

        if((ranking.ranking > 1) && (ranking.ranking <= 4)) {
            rateTitle = i18n._('body')
        } else if (ranking.ranking === 1) {
            rateTitle = i18n._('bod')
        }

        let medal = null

        if(ranking.rank === 1) {
            medal = (<span role="img" aria-label={i18n._('1. miesto')}>🥇</span>)
        } else if (ranking.rank === 2) {
            medal = (<span role="img" aria-label={i18n._('2. miesto')}>🥈</span>)
        } else if (ranking.rank === 3) {
            medal = (<span role="img" aria-label={i18n._('3. miesto')}>🥉</span>)
        }

        let percentage = 0
        
        if(maxRate > 0) {
            percentage = Math.round(ranking.ranking/maxRate*100)
        }

        let color = 'red';

        if(percentage > 30) {
            color = 'orange'
        }

        if(percentage > 55) {
            color = 'blue'
        }

        if(percentage > 80) {
            color = 'green'
        }

        return (
            <div className={"table-row" + ((isMe)?' _isme':'')}>
                <div className="table-col n-p">
                    <div className="part-position">
                    
                        <div className="position">{(ranking.rank === -1)?'-':ranking.rank}</div>
                    
                    </div>
                </div>
                <div className="table-col _full">
                    <div className="part-text part-user">
                        {((typeof hideLanguage === 'undefined') || !hideLanguage)?(
                            <div className="part avatar _flag">
                                <ReactCountryFlag className="flag" countryCode={ranking.country} svg/>
                            </div>
                        ):(null)}
                        <div className="part name">
                            {medal} {ranking.login}
                        </div>
                    </div>
                </div>
                <div className="table-col hide-s">
                    <div className="part-text">
                    
                        <div className={"progress-bar _" + color}>
                            <div className="runner" style={{width: percentage}}></div>
                        </div>
                    
                    </div>
                </div>
                <div className="table-col">
                    <div className="part-points">
                    
                        {ranking.ranking} <strong>{rateTitle}</strong>
                    
                    </div>
                </div>
            </div>
        )
    }
}

export default RankingRow