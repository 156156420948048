import { setLoadingCreatedUserGroups } from '../loadings/setLoading';
import config from '../../config';
import { handleErrors } from '../../utils/handleErrors';
import { ICreateUserGroup, IUserGroupType } from '../../constants/types';
import { STORE_USERGROUP_FAILED, STORE_USERGROUP, STORE_USERGROUP_STARTED } from '../../constants/action-types';
import { store } from '../../store';
import { history } from '../../../index';
import { addFloatingNotification } from '../notifications/floating';
import { i18n } from '../../App';


export function createUserGroup(data:ICreateUserGroup) {
    return (dispatch: any) => {

        dispatch(setLoadingCreatedUserGroups(true))
        dispatch(createUserGroupStarted())

        return fetch(config.api.usergroups, {
            method: 'POST',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + store.getState().globalStore.token
            },
            body: JSON.stringify(data)
            
        })
            .then(handleErrors)
            .then(res => res.json())
            .then(res => {

                dispatch(createUserGroupSuccess(res.data))
                dispatch(setLoadingCreatedUserGroups(false))

                dispatch(addFloatingNotification({
                    type:"success",
                    text:i18n._('Nová trieda bola úspešne vytvorená. Pre registráciu Vašich žiakov môžete využiť kód')  + ' "'  +  res.data.access_code + '".',
                    showBell: true
                }))

                history.push({
                    pathname: '/app/classes/' + res.data.id
                })

            })
            .catch((error: any)  => {
                dispatch(createUserGroupFailed(error))
                dispatch(setLoadingCreatedUserGroups(false))
            });
    };
}

export const createUserGroupStarted = () => ({
    type: STORE_USERGROUP_STARTED
});

export const createUserGroupSuccess = (data:IUserGroupType) => ({
    type: STORE_USERGROUP,
    payload: data
});

export const createUserGroupFailed = (error: any) => ({
    type: STORE_USERGROUP_FAILED,
    payload: { error }
});