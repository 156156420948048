import React, {Component} from 'react';
import { connect } from 'react-redux';
import { IApplicationStore } from '../../../../../constants/store-interfaces';
import { i18n } from '../../../../../App';
import CreatableSelect from 'react-select/creatable';
import { Trans } from '@lingui/macro';
import { loadCreatedWordGroups } from '../../../../../actions/wordGroups/loadCreatedWordGroups';
import { IWordgroupType } from '../../../../../constants/types';
import { createFilter } from 'react-select';

interface IStateProps {
    wordGroups: IWordgroupType[],
    loadingWordGroups: boolean
}

interface IDispatchProps {
    loadCreatedWordGroups: typeof loadCreatedWordGroups
}

const mapStateToProps = (state: IApplicationStore): IStateProps => ({
    wordGroups:  state.wordGroupsStore.createdWordgroups,
    loadingWordGroups: state.loadingStore.loadingCreatedWordGroups
})

const mapDispatchToProps = (dispatch: any): IDispatchProps => ({
    loadCreatedWordGroups: () =>  dispatch(loadCreatedWordGroups())
})

interface IProps extends IStateProps, IDispatchProps{
    languageFromId: number | null,
    languageToId: number | null,
    selectedWordGroups: any[],
    handleWordGroups: any
}


class WordGroupSelection extends Component<IProps, any> {

    componentDidMount() {
        this.props.loadCreatedWordGroups()
    }

    onWordGroupChange = (inputValue:any) => {
        this.props.handleWordGroups(inputValue)
    }

    render() {
        const  { selectedWordGroups,  wordGroups, languageFromId, languageToId, loadingWordGroups } = this.props
        
        let defaultOptions:any = []

        for(let wordGroup of wordGroups) {
            if(
                (wordGroup.language_from.id === languageFromId) &&
                (wordGroup.language_to.id === languageToId)
            ) {
                defaultOptions.push({
                    value: wordGroup.id,
                    object: wordGroup,
                    label:  wordGroup.name
                })
            }
        }

        return (
            <div className="input-row">
                    
                <h4 className="headline-divider"><Trans>Skupina slovíčok</Trans></h4>

                <p className="note">
                    <Trans>
                        Zadajte názov skupiny, alebo viacerých skupín, do ktorých budú nové slovíčka patriť. 
                    </Trans>
                </p>
                <p className="note">
                    <Trans>
                        Triedenie do skupín uľahčuje organizovanie Vaších slovíčok a ich následné učenie.
                    </Trans>
                </p>
                
                <div className="customSelects">
                    <div className="part">
                        
                        <CreatableSelect
                            isMulti
                            autoFocus={true}
                            options={defaultOptions}
                            className="customSelect"
                            classNamePrefix="customSelectWrapper"
                            onChange={this.onWordGroupChange}
                            value={selectedWordGroups}
                            filterOption={createFilter({
                                matchFrom: 'any',
                                stringify: option => `${option.label}`,
                            })}
                            formatCreateLabel={(inputValue:any) => i18n._('Nová skupina') + ' - "' + inputValue + '"'}
                            noOptionsMessage={() => i18n._('Začnite písať pre vytvorenie novej skupiny')}
                            placeholder={i18n._("Vyberte skupinu, alebo začnite písať")}
                            isLoading={loadingWordGroups}
                        />                                                  
                        
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WordGroupSelection)