import React from 'react'
import LoadingElement from '../../LoadingElement';

interface IProps {
    title: string | React.ReactNode,
    subtitle: string,
    icon: string,
    widgetColor: 'primary' | 'orange' | 'green',
    loading: boolean,
    raise?: string
    raiseType?: 'green'|'red',
    raiseTooltip?: string,
    afterIcon?: 'up'|'down',
    afterIconColor?: 'green'|'red',
    subContent?: React.ReactNode
}

const IconWidget: React.FC<IProps> = props => {
    return (
        <div className="small-widget">
            <LoadingElement loadingValue={props.loading}/>

            <div className="_part _left">
                
                <div className={"widget-type _" + props.widgetColor}>
                    <i className={"la " + props.icon}/>
                </div>
                
            </div>
            <div className="_part _body">
            
                <h2>
                    {props.title}
                    {((typeof props.afterIcon !== 'undefined') && (typeof props.afterIconColor !== 'undefined'))?(
                        <i className={"la la-arrow-" + props.afterIcon + " " + props.afterIconColor + "-text"} />
                    ):(null)}
                </h2>
                <p>{props.subtitle}</p>
            
            </div>
            {((typeof props.raise !== 'undefined') && (typeof props.raiseType !== 'undefined'))?(
                <div className="_part _right _align-bottom">
                    
                    {(typeof props.raiseTooltip !== 'undefined')?(
                        <small className={ props.raiseType + "-text"} data-tooltip={props.raiseTooltip}>{props.raise}</small>
                    ):(
                        <small className={ props.raiseType + "-text"}>{props.raise}</small>
                    )}
                    
                </div>
            ):(null)}
            {(typeof props.subContent !== 'undefined')?(
                <div className="_part _right _after">
                    {props.subContent}
                </div>
            ):(null)}
        </div>
    )
}

export default IconWidget