import React, {Component} from 'react'
import { connect } from 'react-redux'
import { IApplicationStore } from '../../../constants/store-interfaces';
import { IStepperStepType } from '../../../constants/types';
import { Trans } from '@lingui/macro';

const mapStateToProps = (state: IApplicationStore) => ({
})

const mapDispatchToProps = (dispatch: any) => ({
})

interface IProps {
    steps: IStepperStepType[],
    allowNext: boolean,
    allowPrev: boolean,
    finishText?: string,
    finalSubmit: any,
    showInModal: boolean,
    showFooter?: boolean,
    stepChanged?: (type: 'prev'|'next', stepNumber: number) => any
}

interface IState {
    currentStep: number
}

class Stepper extends Component<IProps, IState> {
    constructor(props:any) {
        super(props)

        this.state = {
            currentStep: 0
        }

        this.increaseStep = this.increaseStep.bind(this)
        this.decreaseStep = this.decreaseStep.bind(this)
    }

    increaseStep() {
        this.setState({
            ...this.state,
            currentStep: this.state.currentStep+1
        })

        if(typeof this.props.stepChanged !== 'undefined') {
            this.props.stepChanged('next', this.state.currentStep+1)
        }
    }

    decreaseStep() {
        this.setState({
            ...this.state,
            currentStep: this.state.currentStep-1
        })

        if(typeof this.props.stepChanged !== 'undefined') {
            this.props.stepChanged('prev', this.state.currentStep-1)
        }
    }

    render() {
        const { steps, finishText, allowPrev, allowNext, showInModal, showFooter } =  this.props
        const { currentStep } =  this.state

        let currentStepObject = steps.find((step:IStepperStepType, index:number) => (index === currentStep))

        let footer: boolean = true

        if(typeof showFooter !== 'undefined') {
            footer = showFooter
        }

        return (
            <div className={"stepper " + ((showInModal)?'_inModal':'')}>
                
                <div className="stepper-nav">
                
                    <ul>
                        {steps.map((step:IStepperStepType, index:number)  => (
                            <li key={index}>
                                <div  className={'step ' + ((index === currentStep)?"current ":"") + (((index+1) <= currentStep)?" finished ":"")}>
                                    <div className="step-num">
                                        {((index+1) <= currentStep ) ? (<i className="la la-check"/>):(index+1)}
                                    </div>
                                    <div className="step-title">
                                        {step.name}
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                
                </div>
                
                <div className="steps">
                    <div className="step">
                        {(typeof currentStepObject !== 'undefined')?currentStepObject.step:(null)}

                        {(footer)?(
                            <div className="modal-footer">
                                <div className="part">
                                    
                                    {(currentStep === 0)?(null):(
                                        <button 
                                            className={"ant-btn bttn " + ((allowPrev)?'_primary':'_silent')}
                                            disabled={!allowPrev}
                                            onClick={this.decreaseStep}
                                        >
                                            <Trans>Predchádzajúci krok</Trans>
                                        </button>
                                    )}
                                    
                                </div>
                                <div className="part text-right">
                                    
                                    {(currentStep === steps.length-1)?(
                                        <button  
                                            className={"ant-btn bttn " + ((allowNext)?'_primary':'_silent')}
                                            disabled={!allowNext}
                                            onClick={this.props.finalSubmit}
                                        >
                                            {(typeof finishText !== 'undefined')?finishText:(<Trans>Nasledujúci krok</Trans>)}
                                        </button>
                                        ):(
                                        <button 
                                            className={"ant-btn bttn " + ((allowNext)?'_primary':'_silent')}
                                            disabled={!allowNext}
                                            onClick={this.increaseStep}
                                        >
                                            <span className="loading"></span><Trans>Nasledujúci krok</Trans>
                                        </button>
                                    )}
                                    
                                </div>
                            </div>
                        ):(null)}
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Stepper)
