import { setLoginUserData } from './loginUser';
import { history } from '../../../index';

export function loginAuthUser(token: string, user: any) {
    return (dispatch: any) => {

        dispatch(setLoginUserData({
            token: token,
            user: user
        }))

        history.push({
            pathname: '/app'
        })
    };
}