import React, { useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { storeWordGroups } from '../../../../../actions/wordGroups/storeWordGroups';
import { ILanguageType,  IWordChecked } from '../../../../../constants/types';
import { IApplicationStore } from '../../../../../constants/store-interfaces';
import { i18n } from '../../../../../App';
import ModalContainer from '../../../containers/ModalContainer';
import ModalHeader from '../../../containers/ModalElements/ModalHeader';
import ModalBody from '../../../containers/ModalElements/ModalBody';
import { Trans } from '@lingui/macro';
import ModalFooter from '../../../containers/ModalElements/ModalFooter';
import SummaryBlock from '../../small/SummaryBlock';
import AllWordsSummary from '../modalParts/summary/AllWordsSummary';
import InformationBox from '../../InformationBox';
import LoadingElement from '../../../../simple/LoadingElement';

interface IProps {
    words: IWordChecked[],
    languageFrom: ILanguageType,
    languageTo: ILanguageType,
    showModal: boolean,
    onClose: () => any
}

const ModalAddWordGroupSimple: React.FC<IProps> = props => {    
    const dispatch = useDispatch()

    const loading = useSelector((state:IApplicationStore) => state.loadingStore.loadingCreatedWordGroups)

    const [name, setName] = useState<string>('')

    const handleSaveWordGroups = () => {
        let data = {}

        let wordGroupsArray = [
            {
                wordgroup_id: null,
                name: name
            }
        ]

        let languages = {
            language_from_id: props.languageFrom.id,
            language_to_id: props.languageTo.id
        }

        let wordsArray = []

        for (let word of props.words) {
            wordsArray.push({
                word_text: word.word_text,
                translation: word.translation
            })
        }

        data = {
            wordgroups: wordGroupsArray,
            language_ids: languages,
            words: wordsArray
        }
    
        dispatch(storeWordGroups(data, true))
    }

    let words = props.words.map(w => {
        return {
            from: w.word_text,
            to: w.translation
        }
    })

    return (
        <section id="mainSection">
            <ModalContainer show={props.showModal} customHide={props.onClose}>
                <LoadingElement loadingValue={loading}/>
                <ModalHeader title={i18n._('Vytvoriť novú skupinu slovíčok')}/>
                
                <ModalBody>
                    <form className="form _alt" onSubmit={handleSaveWordGroups} autoComplete="off">
                        <div className="input-row">
                            <div className="part">
                            
                                <div className="summary">
                                    <SummaryBlock title={i18n._('Názov skupiny')} iconName='map-signs'>
                                        <input type="text" placeholder={i18n._('Matrix, 1. lekcia')} onChange={(e) => setName(e.currentTarget.value)} value={name} autoFocus/>
                                    </SummaryBlock>
                                    <SummaryBlock title={(words.length === 1)?(i18n._('slovíčko')):(words.length <= 4)?(i18n._('slovíčka')):(i18n._('slovíčok'))} iconName='language'>
                                        <AllWordsSummary
                                            languageFrom={props.languageFrom}
                                            languageTo={props.languageTo}
                                            words={words}
                                        />
                                    </SummaryBlock>
                                    {(name.length === 0)?(
                                        <InformationBox type="info" showClose={false} showBell={true}>
                                            <Trans>Pre pokračovanie musíte zadať názov skupiny.</Trans>
                                        </InformationBox>   
                                    ):(null)}
                                </div>
                                
                            </div>
                        </div>
                    </form>
                
                </ModalBody>

                <ModalFooter>
                    <div className="part">
                        <button className="bttn _red href" onClick={props.onClose}><Trans>Zrušiť</Trans></button>
                    </div>
                    <div className="part">
                        <button className={"bttn _full  href " + ((!name.length)?"_silent":"_green")} onClick={handleSaveWordGroups} disabled={name.length===0}><Trans>Uložiť</Trans></button>
                    </div>
                </ModalFooter>
            </ModalContainer>
        </section>
    )
}

export default ModalAddWordGroupSimple