import React, {FormEvent, useEffect, useState} from 'react';
import { i18n } from '../../../../../App';
import ModalContainer from '../../../containers/ModalContainer';
import ModalHeader from '../../../containers/ModalElements/ModalHeader';
import ModalBody from '../../../containers/ModalElements/ModalBody';
import { Trans } from '@lingui/macro';
import ModalFooter from '../../../containers/ModalElements/ModalFooter';
import InformationBox from '../../InformationBox';
import { checkUserName } from '../../../../../actions/global/checkUserName';
import LoadingElement from '../../../../simple/LoadingElement';
import config from '../../../../../config';
import { useSelector } from 'react-redux';
import { IApplicationStore } from '../../../../../constants/store-interfaces';
import { handleErrors } from '../../../../../utils/handleErrors';
import { store } from '../../../../../store';
import { addFloatingNotification } from '../../../../../actions/notifications/floating';

interface IProps {
    createWGData: {
        language_ids: {
            language_from_id: number,
            language_to_id: number
        },
        wordgroups: {
            wordgroup_id: null,
            name: string
        }[],
        words: {
            translation: string,
            word_text: string
        }[]
    }
}

const ModalShareWordGroup: React.FC<IProps> = props => {
    const initialText = i18n._('Zdieľam Vám skupinu slovíčok') + ' - ' + props.createWGData.wordgroups[0].name

    const [customText, setCustomText] = useState(initialText)
    const [username, setUserName] = useState('')
    const [user, setUser] = useState<null|number>(null)
    const [loading, setLoading] = useState<boolean>(false)
    const [showModal, setShowModal] = useState(false)
    const [isFirst, setIsFirst] = useState(true)

    const token = useSelector((state: IApplicationStore) => state.globalStore.token)
    
    useEffect(() => {
        setCustomText(initialText)
        setUser(null)
        setUserName('')
    }, [showModal, initialText])

    useEffect(() => {
        const timeout = setTimeout(() => {
            if(username.length > 0) {
                setLoading(true)
    
                checkUserName(username, true)
                    .then((res) => {
                        setLoading(false)
                        setIsFirst(false)
    
                        if(res.message === 'true' && typeof res.data !== 'undefined') {
                            setUser(Number(res.data))
                        } else {
                            setUser(null)
                        }
                    })
            }
        }, 500)
        return () => clearTimeout(timeout)
    }, [username
    ])

    const submitFormHandler = (e?: FormEvent) => {
        e?.preventDefault()

        console.log(user)
        if(user !== null) {
            setLoading(true)

            fetch(config.api.wordGroupShare, {
                method: 'post',
                credentials: 'include',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                body: JSON.stringify({
                    to: user,
                    data: props.createWGData,
                    message: customText
                })
            })
                .then(handleErrors)
                .then(res => res.json())
                .then(json => {
                    setLoading(false)

                    if(json.status === 'success') {
                        setShowModal(false)
                        store.dispatch(addFloatingNotification({
                            type:"success",
                            text:i18n._('Skupina slovíčok bola odoslaná.'),
                            showBell: true
                        }))
                    } else {
                        store.dispatch(addFloatingNotification({
                            type:"warning",
                            text:i18n._('Nastala chyba pri odosielaní, skúste to neskôr, alebo nás kontaktujte e-mailom na info@alphie.app.'),
                            showBell: true
                        }))
                    }
                })
                .catch((error: any)  => {
                    setLoading(false)
                    store.dispatch(addFloatingNotification({
                        type:"warning",
                        text:i18n._('Nepodarilo sa odoslať formulár, skúste neskôr, alebo sa na nás obráťte prostredníctvom e-mailu na info@alphie.app'),
                        showBell: true
                    }))
                })
        }
    }

    return (
        <>
            <button className="bttn _small _red _icon" onClick={() => setShowModal(true)}>
                <i className="la la-envelope"/>
                <Trans>Odoslať skupinu kolegovi</Trans>
            </button>

            <ModalContainer show={showModal} customHide={() => setShowModal(false)}>
                <ModalHeader title={i18n._('Zdieľanie skupiny slovíčok')}/>
                
                <ModalBody>
                    <LoadingElement loadingValue={loading}/>
                    <form className="form _alt" onSubmit={submitFormHandler}>
                        <div className="form _alt">
                            <div className="input-row">
                                <p>
                                    <Trans>V tomto okne je možné odoslať Vami vytvorenú skupinu slovíčok inému používateľovi.</Trans>
                                </p>

                                <InformationBox type='info' showBell={true}>
                                    <Trans>Zadaný používateľ bude o novej skupine slovíčok informovaný e-mailom. Do tohto e-mailu môžete vložiť vlastný text.</Trans>
                                </InformationBox>
                            </div>
                            <div className="input-row">
                                <div className="part">
                                    <label htmlFor="wordgroup-name"><Trans>Vlastný text</Trans></label>
                                    <div className="input-holder">
                                        <input 
                                            name={"customText"}
                                            type="text"
                                            placeholder={i18n._('Vlastný text')}
                                            onChange={(e) => setCustomText(e.currentTarget.value)}
                                            value={customText}
                                            autoFocus={true}
                                        />
                                    </div>
                                </div>
                            </div>
                            {(user === null && !isFirst)?(
                                <div className="input-row">
                                    <InformationBox type="error" showBell={true}>
                                        <Trans>Zadaný používateľ neexistuje.</Trans>
                                    </InformationBox>
                                </div>
                            ):(null)}
                            <div className="input-row">
                                <div className="part">
                                    <label htmlFor="wordgroup-name"><Trans>Komu odoslať skupinu slovíčok</Trans> <i>*</i></label>
                                    <div className="input-holder">
                                        <input 
                                            name={"className"}
                                            type="text"
                                            placeholder={i18n._('Prihlasovacie meno')}
                                            onChange={(e) => setUserName(e.currentTarget.value)}
                                            value={username}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="input-row">
                                <InformationBox type='warning' showBell={true}>
                                    <Trans>Po odoslaní skupiny slovíčok ju musí používateľ prijať na stránke "Skupiny slovíčok"</Trans>
                                </InformationBox>
                            </div>
                        </div>
                    </form>
                
                </ModalBody>

                <ModalFooter>
                    <div className="part">
                        <button type="button" className="ant-btn bttn _silent href" onClick={() => setShowModal(false)}><Trans>Zavrieť</Trans></button>
                    </div>
                    <div className="part">
                        <button type="button" className={("ant-btn bttn href " + ((user !== null)?'_primary':'_silent'))} onClick={() => submitFormHandler()} disabled={((user !== null)?false:true)}><Trans>Odoslať</Trans></button>
                    </div>
                </ModalFooter>
            </ModalContainer>
        </>
    )
}

export default ModalShareWordGroup