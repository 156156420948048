import React, { useEffect } from 'react';
import { Trans } from '@lingui/macro';
import { Redirect, withRouter, RouteComponentProps } from 'react-router';
import RestoreRequestForm from '../../forms/RestoreRequestForm';
import RestoreForm from '../../forms/RestoreForm';
import { useSelector } from 'react-redux';
import { IApplicationStore } from '../../../constants/store-interfaces';
import ReactGA from 'react-ga';

interface IProps extends RouteComponentProps {
}

const RestorePasswordPage: React.FC<IProps> = props => {
    const userLoggedIn = useSelector<IApplicationStore, boolean>(state => state.globalStore.userLoggedIn)

    useEffect(() => {
        ReactGA.pageview('Obnovenie hesla')
    }, [])

    const params = new URLSearchParams(window.location.search);

    const code = params.get('code');

    let redirect = null
    if(userLoggedIn) {
        redirect = (<Redirect
            to={{
                pathname:"/app"
            }}
        />)
    }

    if(code === null) {
        return (
            <div className="inner _radius">
                <div className="login-head">
                    <div className="login-logo">
                        <a href="https://alphie.app" target="_self">
                            <svg version="1.1" viewBox="0 0 317 287" xmlns="http://www.w3.org/2000/svg">
                                <g fill="none" fillRule="evenodd">
                                    <path transform="translate(175 269.02) scale(-1 1) translate(-175 -269.02)" d="m152 252v30c1.3333 3 3.5 4.3333 6.5 4s4.8333-2.3333 5.5-6l5-1.5c1 5 3.6667 7 8 6s6-4.1667 5-9.5l4-1c1 5 3.1667 7.3333 6.5 7s5.1667-3 5.5-8v-8l-46-13z" fill="#000"/>
                                    <path d="m76.5 252v30c1.3333 3 3.5 4.3333 6.5 4s4.8333-2.3333 5.5-6l5-1.5c1 5 3.6667 7 8 6 4.3333-1 6-4.1667 5-9.5l4-1c1 5 3.1667 7.3333 6.5 7s5.1667-3 5.5-8v-8l-46-13z" fill="#000"/>
                                    <circle cx="136" cy="136" r="136" fill="#4D7CFE"/>
                                    <path transform="translate(247.27 220) scale(-1 1) translate(-247.27 -220)" d="m236.04 185.5c0.66667 17.333-0.33333 30.333-3 39-3.6667 11.667-5.3333 21.667-5 30 8.6667-2.6667 16-6.5 22-11.5 4.6667-4 10.167-9.6667 16.5-17l-30.5-40.5z" fill="#4D7CFE"/>
                                    <path d="m19 190c-4.3333 9-6.8333 18.833-7.5 29.5-0.33333 13 1 22.833 4 29.5 3.2556-1.7318 6.4223-4.3985 9.5-8s7.7444-10.102 14-19.5l-20-31.5z" fill="#4D7CFE"/>
                                    <circle cx="135" cy="136" r="121" fill="#89A5F4"/>
                                    <path transform="translate(118.57 213.12) rotate(7) translate(-118.57 -213.12)" d="m118.57 256.7c38.936 0 70.5-37.608 70.5-84-21.484-2.7971-43.536-3.3108-66.155-1.541-28.192-2.5836-53.14-2.0699-74.845 1.541 0 46.392 31.564 84 70.5 84z" fill="#fff"/>
                                    <polygon points="112.34 214.09 100.32 169.63 136.03 136.63 147.24 180.32" fill="#FFAB2B"/>
                                    <circle cx="81" cy="109" r="65" fill="#2954CB"/>
                                    <circle cx="196" cy="146" r="65" fill="#2954CB"/>
                                    <path d="m0 21.5 9.5 1.5c9.5606 4.581 17.061 7.581 22.5 9s25.773 6.0856 61 14l-62 19.5c-8.3333-2.3333-15.5-8-21.5-17-4.3333-7.3333-7.5-16.333-9.5-27z" fill="#2954CB"/>
                                    <path transform="translate(263.45 111.18) scale(-1 1) rotate(-35) translate(-263.45 -111.18)" d="m215.62 86.585 9.5 1.5c9.5606 4.581 17.061 7.581 22.5 9 5.4394 1.419 26.656 7.4401 63.651 18.063l-55.956 20.637c-14.13-5.8002-24.194-13.2-30.194-22.2-4.3333-7.3333-7.5-16.333-9.5-27z" fill="#2954CB"/>
                                    <circle cx="81" cy="110" r="47" fill="#fff"/>
                                    <circle cx="197" cy="145" r="47" fill="#fff"/>
                                    <circle cx="97.5" cy="115.5" r="26.5" fill="#4D7CFE"/>
                                    <circle cx="212.5" cy="147.5" r="26.5" fill="#4D7CFE"/>
                                    <circle id="a" cx="98.372" cy="117.2" r="13" fill="#000"/>
                                    <circle cx="213.34" cy="148.75" r="13" fill="#000"/>
                                </g>
                            </svg>
                        </a>
                    </div>
                    {redirect}
                    <h1 className="text-center"><Trans>Obnovenie hesla</Trans></h1>
                    <p><Trans>Zabudol si svoje heslo? Nevadí, požiadaj o obnovenie hesla, pošleme ti nové.</Trans></p>
                </div>
                <RestoreRequestForm/>
            </div>
        )
    } else {
        return (
            <div className="inner _radius">
                <div className="login-head">
                    {redirect}
                    <h1 className="text-center"><Trans>Proces obnovenia hesla</Trans></h1>
                    <p><Trans>Zadaním nového hesla si obnovíte Vaše heslo</Trans></p>
                </div>
                <RestoreForm/>
            </div>
        )
    }
}

export default withRouter(RestorePasswordPage)
