import React from 'react';
import { useSelector } from 'react-redux';
import { IUserGroupType, IUSerInfo, ITestResult, ITest, IRanking } from '../../../../constants/types';
import { IApplicationStore } from '../../../../constants/store-interfaces';
import { Trans } from '@lingui/macro';
import LoadingElement from '../../../simple/LoadingElement';
import TestClass from '../../../simple/classes/testClass';
import RankingRow from '../rankings/parts/RankingRow';
import { FacebookShareButton, FacebookIcon } from 'react-share';
import { i18n } from '../../../../App';

interface IProps {
    userGroup: IUserGroupType
}

const SchoolMatesTable: React.FC<IProps> = props => {
    const loading = useSelector<IApplicationStore, boolean>(state => state.loadingStore.loadingCreatedUserGroups)
    const loadingTestResults = useSelector<IApplicationStore, boolean>(state => state.loadingStore.loadingTestResults)
    const loadingAssignedTests = useSelector<IApplicationStore, boolean>(state => state.loadingStore.loadingAssignedTests)
    const usergroupResults = useSelector<IApplicationStore, {[key: number]: ITestResult[]}>(state => state.testsStore.usergroupResults)
    const ugTests = useSelector<IApplicationStore, {[key: number]: ITest[]}>(state => state.testsStore.usergroupTests)
    const userInfo = useSelector<IApplicationStore, IUSerInfo | null>(state => state.globalStore.userInfo)

    let rankings: IRanking[]  = []
    let testResults = usergroupResults[props.userGroup.id]
    let tests = ugTests[props.userGroup.id]

    if(typeof props.userGroup !== 'undefined') {
        for(let user of props.userGroup.assigned_users) {
            let totalPoints = 0

            if((typeof testResults !== 'undefined') && (typeof tests !== 'undefined')) {

                testResults.forEach(tR => {
                    if((typeof tR.user_id !== 'undefined') && (tR.user_id === user.id)) {
                        let test = tests.find(t => t.id === tR.test_id)

                        if(typeof test !== 'undefined') {
                            let testObject = new TestClass(test, tR)
                            
                            totalPoints += testObject.getTestRate()
                        }
                    }
                })
            }

            rankings.push({
                country: '',
                login: user.f_name,
                rank: 1,
                ranking: totalPoints,
                user_id: user.id
            })
        }
    }

    rankings.sort((a,b) => b.ranking - a.ranking)

    let userRanking = '-'
    let userPoints = 0

    rankings.forEach((r,i) => {
        r.rank = i+1

        if(r.user_id === userInfo?.id) {
            userRanking = r.rank.toString()
            userPoints = r.ranking
        }
    })

    let top = 0

    if(rankings.length > 0) {
        top = rankings[0].ranking
    }

    let sharePath = ''

    if(userInfo !== null) {
        sharePath = btoa(encodeURI(JSON.stringify({
            'r': userRanking,
            'p': userPoints,
            'c': props.userGroup.name,
            'i': 'cl'
        })))
    }
    
    return (
        <div className="widget-part">
                
            <section className="widget">
                <LoadingElement loadingValue={loading || loadingTestResults || loadingAssignedTests}/>

                <header className="widget-header">
                    <div className="_part">
                    
                        <h3><Trans>Moji spolužiaci</Trans></h3>
                    
                    </div>
                    <div className="_part --sharing">
                        <div className="share --part" data-tooltip={i18n._('Zdieľať')}>
                            <FacebookShareButton
                                url={'https://certificate.alphie.app/share.php?c=' + sharePath}
                                quote={i18n._("Aplikácia Alphie - učenie slovíčok z ľahkosťou")}
                                hashtag="#alphieapp"
                                className="bttn --share"
                            >
                                <FacebookIcon size={36} />
                            </FacebookShareButton>
                        </div>                    
                    </div>
                </header>
                <div className="widget-body">
                    <div className="_part">
                    
                        <div className="table _full">

                            <div className="table-body">
                                {rankings.map((r,i) => {
                                    let isMe = false

                                    if(userInfo !== null) {
                                        if(r.user_id === userInfo.id) {
                                            isMe = true
                                        }
                                    }
                                    return (
                                        <RankingRow key={i} maxRate={top} ranking={r} isMe={isMe} hideLanguage={true}/>
                                    )
                                })}
                            </div>
                        
                        </div>
                    
                    </div>
                </div>
            </section>

        </div>
    )
}

export default SchoolMatesTable