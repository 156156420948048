import React, {Component} from 'react';
import { connect } from 'react-redux';
import { IApplicationStore } from '../../../../../constants/store-interfaces';
import { Trans } from '@lingui/macro';
import { i18n } from '../../../../../App';
import { ILanguageType, ITestCreate, IWordgroupType, ITest, ETestUgType } from '../../../../../constants/types';
import SummaryBlock from '../../small/SummaryBlock';
import AllWordsSummary from './summary/AllWordsSummary';
import TestTypeInfo from './summary/TestTypeInfo';
import getTestTypeString from '../../../../simple/classes/getTestTypeString';
import TestDifficulty from '../../../../simple/tableElements/TestDifficulty';
import moment from 'moment';

interface IStateProps {
    wordGroups: IWordgroupType[]
}

interface IDispatchProps {
}

const mapStateToProps = (state: IApplicationStore): IStateProps => ({
    wordGroups:  state.wordGroupsStore.createdWordgroups
})

const mapDispatchToProps = (dispatch: any): IDispatchProps => ({
})

interface IProps extends IStateProps, IDispatchProps{
    test: ITestCreate | ITest,
    short?: boolean,
    showTitle?: boolean,
    isTraining?: boolean
}

interface IState {
}


class TestSummary extends Component<IProps, IState> {
    
    render() {
        const { test, short, showTitle, isTraining } = this.props

        let showTraining = false

        if(typeof isTraining !== 'undefined') {
            showTraining = isTraining
        }

        let typeTest = getTestTypeString(test.settings.type)

        let showAll = true

        if(typeof short !== 'undefined') {
            showAll = !short
        }
        
        let languageFrom: ILanguageType | null = null
        let languageTo: ILanguageType | null = null

        if(test.settings.wordGroups.length) {
            let wg = this.props.wordGroups.find(wg => wg.id === test.settings.wordGroups[0])

            if(typeof wg !== 'undefined') {
                languageFrom = wg.language_from
                languageTo = wg.language_to
            }
        }

        let wordGroups = this.props.wordGroups.filter(wg => test.settings.wordGroups.includes(wg.id))

        let title = i18n._('Názov písomky')
        let type = i18n._('Typ písomky')
        let wordSelected = i18n._('Skupiny slovíčok (z ktorých je písomka)')

        if((typeof test.settings.ugType !== 'undefined') && (test.settings.ugType === ETestUgType.homework)) {
            title = i18n._('Názov domácej úlohy')
            type = i18n._('Typ domácej úlohy')
            wordSelected = i18n._('Skupiny slovíčok (z ktorých je domáca úloha)')
        }

        if(showTraining) {
            title = i18n._('Názov cvičenia')
            type = i18n._('Typ cvičenia')
            wordSelected = i18n._('Skupiny slovíčok (z ktorých je cvičenie)')
        }

        return (
            <div className="input-row">
                    
                {(showAll)?(
                    <div className="part">
                        {((typeof showTitle === 'undefined') || showTitle)?(
                            <h4 className="headline-divider"><Trans>Súhrn</Trans></h4>
                        ):(null)}

                        <div className="summary">
                            {(test.name.length > 0)?(
                                <SummaryBlock title={title} iconName='map-signs'>
                                    {test.name}
                                </SummaryBlock>
                            ):(null)}
                            <SummaryBlock title={type} iconName='chevron-circle-right'>
                                {typeTest}
                                <TestTypeInfo type={test.settings.type} />
                            </SummaryBlock>
                            <SummaryBlock title={i18n._('Kontrola diakritiky, veľkých a malých písmen')} iconName='chevron-circle-right'>
                                {(typeof test.settings.diacritic !== 'undefined')?(test.settings.diacritic)?i18n._('áno'):i18n._('nie'):i18n._('nie')}
                            </SummaryBlock>
                            <div className="input-row">
                                <div className="part">
                                    <SummaryBlock title={i18n._('Náročnosť')} iconName='bar-chart'>
                                        <TestDifficulty difficulty={test.settings.difficulty} />
                                    </SummaryBlock>            
                                </div>
                                <div className="part">
                                    {(test.settings.time !== null)?(
                                        <SummaryBlock title={i18n._('Časový limit')} iconName='clock-o'>
                                            {test.settings.time} <Trans>min</Trans>
                                        </SummaryBlock>
                                    ):(null)}
                                </div>
                                <div className="part"></div>
                            </div>
                            {(test.valid_until !== null)?(
                                <SummaryBlock title={i18n._('Zobrazené študentom')} iconName='chevron-circle-right'>
                                    <span className="tag _primary _alt">
                                        <strong>{moment(test.valid_from).format('DD.MM.YYYY')}</strong> <small>{moment(test.valid_from).format('HH:mm')}</small>
                                    </span>
                                     - 
                                    <span className="tag _primary _alt">
                                        <strong>{moment(test.valid_until).format('DD.MM.YYYY')}</strong> <small>{moment(test.valid_until).format('HH:mm')}</small>
                                    </span>
                                </SummaryBlock>
                            ):(null)}
                            {(wordGroups.length > 0)?(
                                <SummaryBlock title={wordSelected} iconName='tags'>
                                    {wordGroups.map((wordGroup:IWordgroupType, index: number) => {

                                        return (
                                        <span className="tag _orange" key={index}>
                                            {wordGroup.name}
                                        </span>
                                        )}
                                    )}
                                </SummaryBlock>
                            ):(null)}

                            {((languageFrom !== null) && (languageTo !== null))?(
                                <SummaryBlock title={(test.settings.words.length + ' ' + ((test.settings.words.length === 1)?(i18n._('slovíčko')):(test.settings.words.length <= 4)?(i18n._('slovíčka')):(i18n._('slovíčok'))))} iconName='language'>
                                    <AllWordsSummary
                                        languageFrom={languageFrom}
                                        languageTo={languageTo}
                                        words={test.settings.words}
                                    />
                                </SummaryBlock>
                            ):(null) }
                        </div>
                        
                    </div>
                ):(
                    <div className="part">
                        <div className="summary">
                            {(test.name.length > 0)?(
                                <SummaryBlock title={title} iconName='map-signs'>
                                    {test.name}
                                </SummaryBlock>
                            ):(null)}
                            <SummaryBlock title={type} iconName='chevron-circle-right'>
                                {typeTest}
                            </SummaryBlock>
                            {((languageFrom !== null) && (languageTo !== null))?(
                                <SummaryBlock title={(test.settings.words.length + ' ' + ((test.settings.words.length === 1)?(i18n._('slovíčko')):(test.settings.words.length <= 4)?(i18n._('slovíčka')):(i18n._('slovíčok'))))} iconName='language'>
                                    <AllWordsSummary
                                        languageFrom={languageFrom}
                                        languageTo={languageTo}
                                        words={test.settings.words}
                                    />
                                </SummaryBlock>
                            ):(null) }
                        </div>
                        
                    </div>
                )}
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TestSummary)