let apiUri = process.env.REACT_APP_API_URL +  '/api'
let dev = process.env.REACT_APP_DEV

let config = {
    api: {
        registerUser:               apiUri + "/register-user",
        checkUser:                  apiUri + "/username-available",
        loginUser:                  apiUri + "/login",
        loginGoogle:                apiUri + "/login/google",
        loginFacebook:              apiUri + "/login/facebook",
        logoutUser:                 apiUri + "/logout",
        updateUser:                 apiUri + "/user",
        createAuthUser:             apiUri + "/user/auth",
        increaseTraining:           apiUri + "/increase-training",
        languages:                  apiUri + "/languages",
        words:                      apiUri + "/words",
        words_delete:               apiUri + "/words/delete",
        words_translation:          apiUri + "/words/translation",
        wordgroups:                 apiUri + "/wordgroups",
        wordgroups_created:         apiUri + "/wordgroups/created",
        wordgroups_assigned:        apiUri + "/wordgroups/assigned",
        usergroups:                 apiUri + "/usergroups",
        usergroups_check_code:      apiUri + "/usergroups/code-check",
        usergroups_created:         apiUri + "/usergroups/created",
        usergroups_assigned:        apiUri + "/usergroups/assigned",
        usergroups_add_user:        apiUri + "/usergroups/user-assign",
        tests:                      apiUri + "/tests",
        testGenerate:               apiUri + "/tests/generate",
        assigned:                   apiUri + "/tests/assigned",
        ugTest:                     apiUri + "/tests/usergroups",
        testResults:                apiUri + "/tests/results",
        testResultsAll:             apiUri + "/tests/results/usergroup/tests",
        testResultsUserGroup:       apiUri + "/tests/results/usergroup",
        rankingWorld:               apiUri + "/ranking",
        rankingCountry:             apiUri + "/ranking/country",
        rankingMonths:              apiUri + "/ranking/months",
        notifications:              apiUri + "/notifications",
        changePassword:             apiUri + "/change-password",
        restorationRequest:         apiUri + "/restore-password-request",
        restoration:                apiUri + "/restore-password",
        wordGroupShare:             apiUri + "/wordgroups/shares"
    },
    roles: {
        admin:      'admin',
        teacher:    'teacher',
        user:       'student'
    },
    version: process.env.REACT_APP_VERSION_NAME || '0.2.0',
    environment: dev
}

export default config