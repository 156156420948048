import React, {Component} from 'react';
import { IApplicationStore } from '../../../../constants/store-interfaces';
import { ITestResult, ITest, ITableRowType, ILanguageType, ITableCellType, ITableHeaderCellType } from '../../../../constants/types';
import moment from 'moment';
import { i18n } from '../../../../App';
import CircularGraph from '../../../simple/widgets/CircularGraph';
import { Trans } from '@lingui/macro';
import { connect } from 'react-redux';
import TableContainer from '../../containers/TableContainer';
import { ReactNode } from 'react';
import TranslateLanguages from '../../../simple/TranslateLanguages';
import Chart from 'react-apexcharts';
import NumberText from '../../../simple/tableElements/NumberText';

interface IStateProps {
    loading: boolean,
    loadingAssigned: boolean,
    loadingTestResults: boolean
}

interface IDispatchProps {
}

const mapStateToProps = (state: IApplicationStore): IStateProps => ({
    loading: state.loadingStore.loadingCreatedUserGroups,
    loadingAssigned: state.loadingStore.loadingAssignedTests,
    loadingTestResults: state.loadingStore.loadingTestResults
})

const mapDispatchToProps = (dispatch: any): IDispatchProps => ({ 
})

interface IProps extends IStateProps, IDispatchProps {
    userResults: ITestResult[]
    tests: ITest[]
}

interface IState {
}

class UserWords extends Component<IProps, IState> {

    constructor(props: any) {
        super(props)
        this.getRows = this.getRows.bind(this)
        this.getHeaders = this.getHeaders.bind(this)
    }

    getRows(results: ITestResult[], tests: ITest[]): ITableRowType[] {
        let rows: ITableRowType[] = []

        interface IStatisticType {from: string, to: string, languageFrom: ILanguageType, languageTo: ILanguageType, valid: number, count: number, dataX: string[], dataY: number[]}

        let words: IStatisticType[] = []

        results.forEach(r => {
            let test = tests.find(t => t.id === r.test_id)

            if(r.result !== null) {
                r.result.questions.forEach(q => {
                    if(typeof test !== 'undefined') {
                        let possible: IStatisticType = {
                            from: q.word.from,
                            to: q.word.to,
                            languageFrom: test.language_from,
                            languageTo: test.language_to,
                            valid: 0,
                            count: 0,
                            dataX: [],
                            dataY: []
                        }

                        let inserted = words.find(w => 
                            (w.from === possible.from) && 
                            (w.to === possible.to) && 
                            (w.languageFrom.id === possible.languageFrom.id) && 
                            (w.languageTo.id === possible.languageTo.id)
                        )

                        if(typeof inserted === 'undefined') {
                            words.push(possible)
                        }
                    }
                })
            }
        })

        results.forEach(r => {
            if(r.result !== null) {
                let test = tests.find(t => t.id === r.test_id)

                if(typeof test !== 'undefined') {
                    r.result.questions.forEach(q => {
                        let word = words.find(w => (w.from === q.word.from) && (w.to === q.word.to) && (w.languageFrom.id === test?.language_from.id) && (w.languageTo.id === test?.language_to.id))

                        if(typeof word !== 'undefined') {
                            word.count += 1
                            word.valid += (q.valid)?1:0

                            word.dataX.push((r.date_finished !== null)?moment(r.date_finished).format('DD. MM. YYYY HH:mm'):moment(r.date_started).format('DD. MM. YYYY HH:mm'))
                            word.dataY.push((q.valid)?1:0)
                        }
                    })
                }
            }
        })

        let percentages: number[] = []

        words.forEach(wS => {
            if(wS.count > 0) {
                percentages.push(Math.round(wS.valid/wS.count*100))
            }
        })

        for(let word  of words)  {
            let cells: ITableCellType[] = []

            let percentage = 0
            let percentageResult: ReactNode = (null)

            if(word.count > 0) {
                percentage = Math.round(word.valid/word.count*100)
                percentageResult = (<CircularGraph percentage={percentage}/>)

                let color = '#F54029'

                if(percentage > 30) {
                    color = '#FFAB2B'
                }
                
                if (percentage >= 55) {
                    color = '#4D7CFE'
                }
                
                if (percentage >= 80) {
                    color = '#6DD230'
                }

                let graphOptions = {
                    xaxis: {
                        categories: word.dataX,
                        labels: {
                            show: false
                        }
                    },
                    yaxis: {
                        min: 0,
                        max: 1,
                        tickAmount: 1,
                        labels: {
                            show: false,
                            formatter: (value:number) => (value === 0)?i18n._('Nesprávne'):i18n._('Správne')
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    chart: {
                        toolbar: {
                            show: false
                        },
                        zoom: {
                            enabled: false
                        }
                    },
                    markers: {
                        size: 2
                    },
                    tooltip: {
                        shared: false,
                        intersect: true
                    },
                    colors: [color]
                }

                cells = [
                    {
                        content: (<div className="word">{word.from}</div>),
                        stringToOrder:  word.from,
                        filterString:  word.from,
                        filterByString:  true
                    },
                    {
                        content: (<div className="word">{word.to}</div>),
                        stringToOrder:  word.to,
                        filterString:  word.to,
                        filterByString: true
                    },
                    {
                        content: (
                            <TranslateLanguages
                                languageFrom={word.languageFrom}
                                languageTo={word.languageTo}
                            />
                        ),
                        stringToOrder:  word.languageFrom.flag_name + word.languageTo.flag_name,
                        filterString: word.languageFrom.flag_name + word.languageTo.flag_name,
                        filterByString: false,
                        selectableFilter: true,
                        selectableName: i18n._('vybrať'),
                        selectableAllText: i18n._('Všetky'),
                        selectableTitle: i18n._('Preklad'),
                        selectableObject: [
                            {
                                object: (
                                        <TranslateLanguages
                                            languageFrom={word.languageFrom}
                                            languageTo={word.languageTo}
                                        />
                                ),
                                value: word.languageFrom.flag_name + word.languageTo.flag_name
                            }
                        ],
                        hideClass: 'hide-m'
                    },
                    {
                        content: (percentageResult),
                        stringToOrder: percentage.toString(),
                        filterByString: false,
                        filterString: percentage.toString()
                    },
                    {
                        content: (
                            <NumberText number={word.count} text={''} title={i18n._('Pokusy')}/>
                        ),
                        stringToOrder: word.count.toString(),
                        filterByString: false,
                        filterString: word.count.toString(),
                        hideClass: 'hide-m'
                    },
                    {
                        content: (
                            <Chart
                                options={graphOptions}
                                series={[{
                                    name: i18n._('Výsledok'),
                                    data: word.dataY
                                }]}
                                type="area"
                                height="80"
                            />
                        ),
                        stringToOrder: percentage.toString(),
                        filterByString: false,
                        filterString: percentage.toString(),
                        hideClass: 'hide-m'
                    }
                ]

                rows.push({
                    cells: cells,
                    catchString: '_' +  word.from + '_' + word.to
                })
            }
        }

        return rows
    }

    getHeaders(): ITableHeaderCellType[] {
        return [
            {
                content: (<span><Trans>Slovo</Trans></span>),
                sortBy: false,
                sortable: false
            },
            {
                content: (<span><Trans>Preklad</Trans></span>),
                sortBy: false,
                sortable: false
            },
            {
                content: (<span><Trans>Jazyk</Trans></span>),
                sortBy: false,
                sortable: false,
                hideClass: 'hide-m'
            },
            {
                content: (<span><Trans>Úspešnosť</Trans></span>),
                sortBy: true,
                sortable: true,
                sortableType: 'number'
            },
            {
                content: (<span><Trans>Pokusy</Trans></span>),
                sortBy: false,
                sortable: true,
                sortableType: 'number',
                hideClass: 'hide-m'
            },
            {
                content: (<span><Trans>Priebeh</Trans></span>),
                sortBy: false,
                sortable: true,
                sortableType: 'number',
                hideClass: 'hide-m'
            },
        ]
    }

    render() {
        const { loading, loadingTestResults, loadingAssigned, userResults, tests } = this.props

        let rows = this.getRows(userResults, tests)
        let headers = this.getHeaders()

        return (
            <TableContainer
                headers={headers}
                rows={rows}
                actions={[]}
                showActions={false}
                showCheckbox={false}
                showFilter={true}
                showHeader={false}
                pageSize={12}
                title={i18n._('Priradené slovíčka')}
                showNew={false}
                emptyText={i18n._('Žiak nemá priradené žiadne slovíčka')}
                loading={(loadingTestResults || loading || loadingAssigned)}
                showGrid={false}
            />
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserWords)