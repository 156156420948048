import React, {Component} from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import { IApplicationStore } from '../../../constants/store-interfaces';
import config from '../../../config';

const mapStateToProps = (state: IApplicationStore) => ({
})

const mapDispatchToProps = (dispatch: any) => ({
})

class Footer extends Component<any> {
    render() {
        
        return (
            <footer className="app-footer">
                <div className="part">
                    <p>&copy; {new Date().getFullYear()} Alphie (v.{config.version}) - All Rights Reserved.</p>
                </div>
                <div className="part">
                    <p>created with <i className="la la-heart-o"/> in Slovakia</p>
                </div>
            </footer>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Footer))
