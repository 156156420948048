import React, {useEffect} from 'react'
import { loadCountry } from '../../../../actions/rankings/loadCountry';
import { IApplicationStore } from '../../../../constants/store-interfaces';
import { useSelector, useDispatch } from 'react-redux';
import { Trans } from '@lingui/macro';
import LoadingElement from '../../../simple/LoadingElement';
import RankingRow from './parts/RankingRow';
import { FacebookShareButton, FacebookIcon } from 'react-share';
import { i18n } from '../../../../App';

var countries = require("i18n-iso-countries");

interface IProps {
    refresh: boolean,
    hardNone?: boolean,
    showHeader?: boolean
}

const CountryRankings: React.FC<IProps> = props => {
    const dispatch = useDispatch()

    const loading = useSelector((state: IApplicationStore) => state.loadingStore.loadingRankingCountry)
    const rankings = useSelector((state: IApplicationStore) => state.rankingStore.countryRankings)
    const userInfo = useSelector((state: IApplicationStore) => state.globalStore.userInfo)
    
    useEffect(() => {
        if(typeof props.hardNone === 'undefined' || !props.hardNone) {
            if(((userInfo !== null) && (rankings.world.length === 0)) || ((userInfo !== null) && props.refresh)) {
                dispatch(loadCountry(userInfo.country))
            }
        }
    }, [dispatch, props.hardNone, userInfo, rankings.world.length, props.refresh])

    useEffect(() => {
        if(userInfo !== null) {
            countries.registerLocale(require("i18n-iso-countries/langs/" + userInfo.language + ".json"))
        }
    }, [userInfo])

    let top = 0

    if(rankings.world.length > 0) {
        top = rankings.world[0].ranking
    }

    let is = false

    let position = '-'
    let points = 0

    rankings.world.forEach(r => {
        if(rankings.user !== null) {
            if(r.user_id === rankings.user.user_id) {
                is = true
                position = r.rank.toString()
                points = r.ranking
            }
        }
    })

    if((position === '-' || points !== 0) && rankings.user !== null) {
        position = rankings.user.rank.toString()
        points = rankings.user.ranking
    }

    let sharePath = ''

    if(userInfo !== null) {
        let cntrList = countries.getNames(userInfo.language)

        sharePath = btoa(encodeURI(JSON.stringify({
            'r': position,
            'p': points,
            'c': cntrList[userInfo.language.toUpperCase()],
            'i': 'c'
        })))
    }
    
    let shareBtn = (
        <FacebookShareButton
            url={'https://certificate.alphie.app/share.php?c=' + sharePath}
            quote={i18n._("Aplikácia Alphie - učenie slovíčok z ľahkosťou")}
            hashtag="#alphieapp"
            className="bttn --share"
        >
            <FacebookIcon size={36} />
        </FacebookShareButton>
    )

    return (
        <div className="widget-part">
            
            <section className="widget">
                <LoadingElement loadingValue={loading}/>

                {((typeof props.showHeader === 'undefined') || props.showHeader)?(
                    <header className="widget-header">
                        <div className="_part">
                        
                            <h3><Trans>Najlepší v mojej krajine</Trans></h3>
                        
                        </div>
                        <div className="_part --sharing">
                            <div className="share --part" data-tooltip={i18n._('Zdieľať')}>
                                {shareBtn}
                            </div>
                            <button 
                                onClick={() => {
                                    if(userInfo !== null) {
                                        dispatch(loadCountry(userInfo.country))
                                    }
                                }}
                                className="tag _primary _alt"
                            >
                                <Trans>Obnoviť</Trans>
                            </button>                        
                        </div>
                    </header>
                ):(<div className="share --part --right" data-tooltip={i18n._('Zdieľať')}>
                    {shareBtn}
                </div>)}
                <div className="widget-body n-p-m">
                    <div className="_part">
                    
                        <div className="table _full">

                            <div className="table-body">
                                {rankings.world.map((r,i) => {
                                    let isMe = false

                                    if(rankings.user !== null) {
                                        if(r.user_id === rankings.user.user_id) {
                                            isMe = true
                                        }
                                    }
                                    return (
                                        <RankingRow key={i} maxRate={top} ranking={r} isMe={isMe}/>
                                    )
                                })}
                                {(!is && (rankings.user !== null)?(
                                    <>
                                        <span className="delimiter"></span>
                                        <RankingRow maxRate={top} ranking={rankings.user} isMe={true}/>
                                    </>
                                ):(null))}
                                {((rankings.user === null) && (userInfo !== null))?(
                                    <>
                                        <span className="delimiter"></span>
                                        <RankingRow 
                                            maxRate={top} 
                                            ranking={{
                                                country: userInfo.country,
                                                login: userInfo.login,
                                                rank: -1,
                                                ranking: 0,
                                                user_id: userInfo.id
                                            }} 
                                            isMe={true}
                                        />
                                    </>
                                ):(null)}
                            </div>
                        
                        </div>
                    
                    </div>
                </div>
            
            </section>

        </div>
    )
}

export default CountryRankings
