import React, {Component} from 'react';
import { connect } from 'react-redux';
import { IApplicationStore } from '../../../../../constants/store-interfaces';
import { Trans } from '@lingui/macro';
import { ILanguageType, IAddWordMapping, IWordGroupMapping } from '../../../../../constants/types';
import { i18n } from '../../../../../App';
import AllWordsSummary from './summary/AllWordsSummary';


const mapStateToProps = (state: IApplicationStore) => ({
})

const mapDispatchToProps = (dispatch: any) => ({
})

interface IProps {
    languageFrom: ILanguageType,
    languageTo: ILanguageType,
    words: IAddWordMapping[],
    wordGroups: IWordGroupMapping[]
}

interface IState {
}

class SummaryAddWords extends Component<IProps, IState> {

    render() {
        const { languageFrom, languageTo, words, wordGroups } = this.props

        return (
            <div className="input-row">
                <div className="part">
                    
                    <h4 className="headline-divider"><Trans>Súhrn</Trans></h4>

                    <div className="summary">
                        <div className="summary-block">
                            <h4>
                                <i className="la la-language"/>
                                {words.length + ' '}
                                {(words.length === 1)?(<Trans>slovíčko</Trans>):(words.length <= 4)?(<Trans>slovíčka</Trans>):(<Trans>slovíčok</Trans>)}
                            </h4>
                            <div className="summary-content">
                                <AllWordsSummary
                                    languageFrom={languageFrom}
                                    languageTo={languageTo}
                                    words={words}
                                />
                            </div>
                        </div>
                        <div className="summary-block">
                            <h4>
                                <i className="la la-tags"/>
                                <Trans>Skupiny slovíčok</Trans>
                            </h4>

                            <div className="summary-content">
                                {wordGroups.map((wordGroup:IWordGroupMapping, index: number) => {
                                    let nameClass:string = 'tag '
                                    let title:string = ''
                                    if(
                                        (typeof wordGroup.__isNew__ !==  'undefined') &&
                                        wordGroup.__isNew__
                                    ) {
                                        nameClass += '_primary'
                                        title = i18n._('Slovíčka budú pridelené do existujúcej skupiny') + ' ' + wordGroup.label
                                    } else {
                                        nameClass += '_green'
                                        title = i18n._('Vytvorí sa nová skupina') + ' - ' + wordGroup.label
                                    }

                                    return (
                                    <span className={nameClass} key={index} title={title}>
                                        {wordGroup.label}
                                    </span>
                                    )}
                                )}
                            </div>
                        </div>
                    
                    </div>
                    
                </div>
            </div>
            
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SummaryAddWords)