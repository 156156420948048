import React, { useState } from 'react'
import { IUserGroupType, ITest, ITestResult, IUSerInfo, IActivity, ETestUgType } from '../../../constants/types';
import { IApplicationStore } from '../../../constants/store-interfaces';
import { useSelector } from 'react-redux';
import moment from 'moment';
import PercentageSmallWidget from '../../simple/widgets/PercentageSmallWidget';
import { NavLink } from 'react-router-dom';
import { i18n } from '../../../App';
import TestClass from '../../simple/classes/testClass';
import Pagination from './TableElements/Pagination';
import LoadingElement from '../../simple/LoadingElement';
import { Trans } from '@lingui/macro';

interface IProps {
    loading: boolean,
    createdUserGroups: IUserGroupType[],
    createdTrainings: ITest[],
    assignedUserGroups: IUserGroupType[],
    userGroupTests: {[key: number]: ITest[]},
    trainingResults: ITestResult[]
    activityLimit: number,
    pagination?: boolean
}

const ActivityContainer: React.FC<IProps> = props => {

    const userInfo = useSelector<IApplicationStore, IUSerInfo | null>(state => state.globalStore.userInfo)
    const [page, setPage] = useState(1)

    let results: ITestResult[] = props.trainingResults
    let tests: ITest[] = props.createdTrainings

    props.assignedUserGroups.forEach(uG => {
        let ugTests = props.userGroupTests[uG.id]

        if(typeof ugTests !== 'undefined') {
            tests = tests.concat(ugTests)
        }
    })

    if(userInfo !== null) {
        results = results.filter(r => r.user_id === userInfo.id)
    }

    results.sort((a, b) => moment(((b.date_finished !== null)?b.date_finished:b.date_started)).valueOf() - moment(((a.date_finished !== null)?a.date_finished:a.date_started)).valueOf())

    let rows: IActivity[] = []

    results.forEach(r => {
        let test = tests.find(t => t.id === r.test_id)

        if(typeof test !== 'undefined') {
            let text = i18n._('Ukončili ste cvičenie') + ' ' + test.name
            let link = "/app/trainings/" + test.id + "/detail"

            if(test.usergroup_id !== null) {
                text = i18n._('Ukončili ste domácu úlohu') + ' ' + test.name
                link = "/app/classes/" + test.usergroup_id + "/homeworks/" + test.id + "/detail"

                if(typeof test.settings.ugType !== 'undefined') {
                    if(test.settings.ugType === ETestUgType.test) {
                        text = i18n._('Ukončili ste písomku') + ' ' + test.name
                        link = "/app/classes/" + test.usergroup_id + "/tests/" + test.id + "/detail"
                    }
                }
            }

            let testObject = new TestClass(test, r)

            rows.push({
                dateTime: (r.date_finished !== null)?r.date_finished:r.date_started,
                text: text,
                widget: 'circle',
                widgetValue: testObject.getPercentage(),
                link: link,
                linkTitle: i18n._('Detail')
            })
        }
    })

    tests.forEach(test => {
        let text = i18n._('Vytvorili ste si cvičenie') + ' ' + test.name
        let link: string | undefined = "/app/trainings/" + test.id + "/detail"
        let widgetText = i18n._('Cvičenie')

        if(test.usergroup_id !== null) {
            text = i18n._('Dostali ste domácu úlohu') + ' ' + test.name
            link = "/app/classes/" + test.usergroup_id + "/homeworks/" + test.id + "/detail"
            widgetText = i18n._('Domáca úloha')

            if(typeof test.settings.ugType !== 'undefined') {
                if(test.settings.ugType === ETestUgType.test) {
                    text = i18n._('Dostali ste písomku') + ' ' + test.name
                    link = undefined
                    widgetText = i18n._('Písomka')
                }
            }
        }

        rows.push({
            dateTime: test.valid_from,
            text: text,
            widget: 'text',
            widgetValue: widgetText.substring(0,2),
            link: link,
            linkTitle: i18n._('Detail')
        })
    })

    rows = rows.filter(r => moment(r.dateTime).isBefore(moment()))

    rows.sort((a,b) => (moment(b.dateTime).valueOf() - moment(a.dateTime).valueOf()))

    let modifiedRows:IActivity[] = []

    if(props.pagination === true) {
        modifiedRows = rows.slice((page-1) * props.activityLimit, (page) * props.activityLimit)
    } else {
        modifiedRows = rows.slice(0,props.activityLimit)
    }

    return (
        <div className="timeline">
            <LoadingElement loadingValue={props.loading}/>
            {modifiedRows.map((r,i) => (
                <div className="timeline-entry" key={i}>
                    <div className="timeline-part _date">
                        {moment(r.dateTime).format('DD.MM.YYYY')}, <small>{moment(r.dateTime).format('HH:mm')}</small>
                    </div>
                    <div className="timeline-part _text">
                        <div className="wrapper">
                            <div className="part">
                            
                                <div className="timeline-status">
                                    <div className="status-left">
                                        {(r.widget === 'text')?(
                                            <div className="status-image">{r.widgetValue}</div>
                                        ):(
                                            <PercentageSmallWidget percentage={Number(r.widgetValue)}/>
                                        )}
                                    </div>
                                    <div className="status-body">
                                        {r.text} {(typeof r.subText !== 'undefined')?(<small>{r.subText}</small>):(null)}
                                    </div>
                                </div>
                            
                            </div>
                            {((typeof r.link !== 'undefined') && (typeof r.linkTitle !== 'undefined'))?(
                                <div className="part _part-options">
                                    <NavLink to={r.link} target="_self" className="bttn _primary _small">{r.linkTitle}</NavLink>
                                </div>
                            ):(null)}
                        </div>
                    </div>
                </div>
            ))}

            {(modifiedRows.length === 0)?(
                <Trans>Zatiaľ neevidujeme žiadnu Vašu aktivitu. Vytvorte si skupiny slovíčok ktoré sa chcete učiť a vykonajte niekoľko cvičení. Zlepšíte tak svoje zručnosti v akomkoľvek cudzom jazyku!</Trans>
            ):(null)}
            
            {(props.pagination === true)?(
                <Pagination
                    pages={Math.ceil(rows.length/props.activityLimit)}
                    currentPage={page}
                    pageClick={setPage}
                />     
            ):(null)}
        </div>
    )
}

export default ActivityContainer