import React, {Component} from 'react';
import { IApplicationStore } from '../../../../constants/store-interfaces';
import { ITestResult, ITest, ITableRowType, ITableCellType, ITableHeaderCellType, ETestUgType, IUserBasic } from '../../../../constants/types';
import { i18n } from '../../../../App';
import { Trans } from '@lingui/macro';
import { connect } from 'react-redux';
import TableContainer from '../../containers/TableContainer';
import TranslateLanguages from '../../../simple/TranslateLanguages';
import NumberText from '../../../simple/tableElements/NumberText';
import TestClass from '../../../simple/classes/testClass';
import Percentage from '../../../simple/tableElements/Percentage';
import InfoModal from '../../../simple/InfoModal';
import ModalUserDetailHomework from '../modals/userGroup/ModalUserDetailHomework';

interface IStateProps {
    loading: boolean,
    loadingAssigned: boolean,
    loadingTestResults: boolean
}

interface IDispatchProps {
}

const mapStateToProps = (state: IApplicationStore): IStateProps => ({
    loading: state.loadingStore.loadingCreatedUserGroups,
    loadingAssigned: state.loadingStore.loadingAssignedTests,
    loadingTestResults: state.loadingStore.loadingTestResults
})

const mapDispatchToProps = (dispatch: any): IDispatchProps => ({ 
})

interface IProps extends IStateProps, IDispatchProps {
    userResults: ITestResult[]
    user: IUserBasic | undefined
    tests: ITest[]
    type: ETestUgType
}

interface IState {
    showModalTest: boolean,
    selectedTest: ITest | null
}

class UserTestsHomeworks extends Component<IProps, IState> {

    constructor(props: any) {
        super(props)

        this.state = {
            showModalTest: false,
            selectedTest: null
        }

        this.getRows = this.getRows.bind(this)
        this.getHeaders = this.getHeaders.bind(this)
        this.handleToggleTestModal = this.handleToggleTestModal.bind(this)
        this.openModalTest = this.openModalTest.bind(this)
    }

    handleToggleTestModal() {
        this.setState({
            ...this.state,
            showModalTest: !this.state.showModalTest
        })
    }

    openModalTest(id: number) {
        let test = this.props.tests.find(t => t.id === id)

        if(typeof test !== 'undefined') {
            this.setState({
                ...this.state,
                selectedTest: test,
                showModalTest: true
            })
        }
    }

    getRows(results: ITestResult[], tests: ITest[]): ITableRowType[] {
        let rows: ITableRowType[] = []

        tests.filter(t => t.settings.ugType === this.props.type).forEach(test => {
            let cells: ITableCellType[] = []

            let percentage:number|null = null
            let percentageContent = (null)

            //Find percentage
            let testResults = results.filter(tR => tR.test_id === test.id)

            if(testResults.length) {
                let cntPoints = 0
                let maxPoints = 0

                testResults.forEach(tR => {
                    let testObject = new TestClass(test, tR)
                    
                    cntPoints += testObject.getTestRate()
                    maxPoints += testObject.getMaximumRate()
                })

                percentage = Math.round(Number(cntPoints)/maxPoints*100)
            }

            if(percentage !== null) {
                percentageContent = (<Percentage percentage={percentage} title={i18n._('Úspešnosť')}/>)
            }
            
            let actions = (
                <div className="part-list">
                    <div className="bttn _small _primary  _icon" onClick={() => this.openModalTest(test.id)}> 
                        <i className="la la-search-plus"/>
                    </div>
                </div>
            )
            
            cells = [
                {
                    content: (<div className="part-title">{test.name}</div>),
                    stringToOrder:  test.name,
                    filterString:  test.name,
                    filterByString:  true
                },
                {
                    content: (
                        <TranslateLanguages
                            languageFrom={test.language_from}
                            languageTo={test.language_to}
                        />
                    ),
                    stringToOrder:  test.language_from.flag_name + test.language_to.flag_name,
                    filterString: test.language_from.flag_name + test.language_to.flag_name,
                    filterByString: false,
                    selectableFilter: true,
                    selectableName: i18n._('vybrať'),
                    selectableAllText: i18n._('Všetky'),
                    selectableTitle: i18n._('Preklad'),
                    selectableObject: [
                        {
                            object: (
                                    <TranslateLanguages
                                        languageFrom={test.language_from}
                                        languageTo={test.language_to}
                                    />
                            ),
                            value: test.language_from.flag_name + test.language_to.flag_name
                        }
                    ]
                },
                {
                    content: (<NumberText 
                                    number={testResults.length} 
                                    text={''}
                                    title={i18n._('Pokusy')}/>),
                    stringToOrder:  testResults.length.toString(),
                    filterString:  testResults.length.toString(),
                    filterByString:  false
                },
                {
                    content: percentageContent,
                    stringToOrder: test.created_at,
                    filterString: test.created_at,
                    filterByString: false
                },
                {
                    content: (
                        actions
                    ),
                    stringToOrder: test.updated_at,
                    filterString: test.updated_at,
                    filterByString: false
                }
            ]

            rows.push({
                cells: cells,
                catchString: '_' +  test.id
            })
        })

        return rows
    }

    getHeaders(): ITableHeaderCellType[] {
        return [
            {
                content: (<span><Trans>Názov</Trans></span>),
                sortBy: false,
                sortable: true
            },
            {
                content: (<span><Trans>Preklad</Trans></span>),
                sortBy: false,
                sortable: true
            },
            {
                content: (<span><Trans>Pokusy</Trans></span>),
                sortBy: false,
                sortable: true,
                sortableType: 'number'
            },
            {
                content: (<span><Trans>Úspešnosť</Trans></span>),
                sortBy: false,
                sortable: false
            },
            {
                content: (<span></span>),
                sortBy: false,
                sortable: false
            }
        ]
    }

    render() {
        const { loading, loadingTestResults, loadingAssigned, userResults, tests, type, user } = this.props
        const { selectedTest, showModalTest } = this.state

        let rows = this.getRows(userResults, tests)
        let headers = this.getHeaders()

        return (
            <>
                <TableContainer
                    headers={headers}
                    rows={rows}
                    actions={[]}
                    showActions={false}
                    showCheckbox={false}
                    showFilter={true}
                    pageSize={12}
                    grid={true}
                    showGrid={false}
                    title={(type === ETestUgType.homework)?i18n._('Domáce úlohy'):i18n._('Písomky')}
                    showNew={false}
                    emptyText={(type === ETestUgType.homework)?i18n._('Aktuálne žiak nemá priradené žiadne domáce úlohy'):i18n._('Aktuálne žiak nemá priradené žiadne písomky')}
                    loading={loading || loadingTestResults || loadingAssigned}
                    defaultSort='asc'
                />
                {((selectedTest !== null) && (typeof user !== 'undefined'))?(
                    <div>
                        <InfoModal
                            title={((selectedTest.settings.ugType === ETestUgType.homework)?i18n._('Domáca úloha'):i18n._('Test')) + ' - ' + selectedTest.name}
                            custom={true}
                            customShow={showModalTest}
                            customShowCloseFunction={this.handleToggleTestModal}
                        >
                            <ModalUserDetailHomework
                                user={user}
                                test={selectedTest}
                                results={userResults}
                            />
                        </InfoModal>
                    </div>
                ):(null)}
            </>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserTestsHomeworks)