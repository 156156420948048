import React, {Component} from 'react';
import { connect } from 'react-redux';
import { IApplicationStore } from '../../../../../constants/store-interfaces';
import { Trans } from '@lingui/macro';
import { i18n } from '../../../../../App';
import { ITest } from '../../../../../constants/types';
import SummaryBlock from '../../small/SummaryBlock';
import getTestTypeString from '../../../../simple/classes/getTestTypeString';
import InformationBox from '../../InformationBox';

interface IStateProps {
}

interface IDispatchProps {
}

const mapStateToProps = (state: IApplicationStore): IStateProps => ({
})

const mapDispatchToProps = (dispatch: any): IDispatchProps => ({
})

interface IProps extends IStateProps, IDispatchProps{
    tests: ITest[],
    openTest: (id: number) => any
}

interface IState {
}


class TestAlternatives extends Component<IProps, IState> {

    render() {
        const { tests } = this.props

        return (
            <div className="form _alt">
                <div className="summary">
                    <SummaryBlock title={i18n._('Upozornenie')} iconName='warning'>
                        <InformationBox type='warning'>
                            <Trans>Už máte vytvorené cvičenia ktoré obsahujú slovíčka z tejto skupiny. Ak chcete, môžete sa otestovať prostredníctvom už vytvorených cvičení.</Trans>
                            <br/>
                            <strong>
                                <Trans>Pre vytvorenie nového cvičenia kliknite na "nasledujúci krok".</Trans>
                            </strong>
                        </InformationBox>
                    </SummaryBlock>
                    <SummaryBlock title={i18n._('Cvičenia so slovíčkami z tejto skupiny')} iconName={'reorder'}>
                        { tests.map((t,i) => (
                            <div 
                                className="tag _primary" 
                                data-tooltip={getTestTypeString(t.settings.type) + ((t.settings.time !== null)?', ' + t.settings.time + i18n._('min'):'')} 
                                key={i}
                                onClick={() => this.props.openTest(t.id)}
                            >
                                {t.name}
                            </div>
                        ))}
                    </SummaryBlock>
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TestAlternatives)