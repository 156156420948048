import { 
    SET_LOADING, 
    SET_LOADING_LOGIN, 
    SET_LOADING_REGISTER,
    SET_LOADING_LANGUAGES,
    SET_LOADING_WORDGROUP,
    SET_LOADING_CREATED_WORDS,
    SET_LOADING_CREATED_WORDGROUPS,
    SET_LOADING_ASSIGNED_WORDGROUPS,
    SET_LOADING_ASSIGNED_USERGROUPS,
    SET_LOADING_CREATED_USERGROUPS,
    SET_LOADING_CREATED_TESTS,
    SET_LOADING_TEST_RESULT,
    SET_LOADING_TEST_RESULTS,
    SET_LOADING_RANKING_COUNTRY,
    SET_LOADING_RANKING_WORLD,
    SET_LOADING_ASSIGNED_TESTS,
    SET_LOADING_GENERATE_TEST,
    SET_LOADING_RANKING_MONTH
} from '../../constants/action-types';


export function setLoading(payload: boolean) {
    return { type: SET_LOADING, payload }
}

export function setLoadingLogin(payload: boolean) {
    return { type: SET_LOADING_LOGIN, payload }
}

export function setLoadingRegister(payload: boolean) {
    return { type: SET_LOADING_REGISTER, payload }
}

export function setLoadingLanguages(payload: boolean) {
    return { type: SET_LOADING_LANGUAGES, payload }
}

export function setLoadingCreatedWordGroups(payload: boolean) {
    return { type: SET_LOADING_CREATED_WORDGROUPS, payload }
}

export function setLoadingAssignedWordGroups(payload: boolean) {
    return { type: SET_LOADING_ASSIGNED_WORDGROUPS, payload }
}

export function setLoadingCreatedWords(payload: boolean) {
    return { type: SET_LOADING_CREATED_WORDS, payload }
}

export function setLoadingWordGroup(payload: boolean) {
    return { type: SET_LOADING_WORDGROUP, payload }
}

export function setLoadingAssignedUserGroups(payload: boolean) {
    return { type: SET_LOADING_ASSIGNED_USERGROUPS, payload }
}

export function setLoadingCreatedUserGroups(payload: boolean) {
    return { type: SET_LOADING_CREATED_USERGROUPS, payload }
}

export function setLoadingCreatedTests(payload: boolean) {
    return { type: SET_LOADING_CREATED_TESTS, payload}
}

export function setLoadingTestResult(payload: boolean) {
    return { type: SET_LOADING_TEST_RESULT, payload}
}

export function setLoadingAssignedTests(payload: boolean) {
    return { type: SET_LOADING_ASSIGNED_TESTS, payload}
}

export function setLoadingTestResults(payload: boolean) {
    return { type: SET_LOADING_TEST_RESULTS, payload}
}

export function setLoadingRankingWorld(payload: boolean) {
    return { type: SET_LOADING_RANKING_WORLD, payload}
}

export function setLoadingRankingCountry(payload: boolean) {
    return { type: SET_LOADING_RANKING_COUNTRY, payload}
}

export function setLoadingGenerateTest(payload: boolean) {
    return { type: SET_LOADING_GENERATE_TEST, payload: payload }
}

export function setLoadingMonthRanking(payload: boolean) {
    return { type: SET_LOADING_RANKING_MONTH, payload: payload }
}