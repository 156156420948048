import config from '../../config';
import { handleErrors } from '../../utils/handleErrors';
import { setLoading } from '../loadings/setLoading';
import { history } from '../../../index';
import { i18n } from '../../App';
import { 
    RESET_STATE
} from '../../constants/action-types';
import { store } from '../../store';

//Functions to send logout request
export function logoutUser(uname: string) {
    return (dispatch: any) => {

        dispatch(setLoading(true))
        
        return fetch(config.api.logoutUser, {
            method: 'post',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + store.getState().globalStore.token
            }
        })
            .then(handleErrors)
            .then(res => res.json())
            .then(json => {
                dispatch(setLoading(false))

                if(json.status  === 'success') {
                    dispatch(setLogout())

                    history.push({
                        pathname: '/login',
                        state: {
                            message: i18n._('Používateľ') +  ' "' + uname + '" '  + i18n._('bol úspešne odhlásený'),
                            type: 'warning'
                        }
                    });
                    
                } else {
                    throw Error(json.message);
                }
            })
            .catch((error: any)  => {
                dispatch(setLoading(false))
                dispatch(setLogoutUserError())
            });
    };
}

export function setLogout() {
    return { type: RESET_STATE, payload: null }
}

export function setLogoutUserError() {
    return { type: RESET_STATE, payload: null }
}