import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { IApplicationStore } from '../../constants/store-interfaces';
import { Trans } from '@lingui/macro';
import { i18n } from '../../App';
import LoadingElement from '../simple/LoadingElement';
import { loginUser } from '../../actions/global/loginUser';
import InformationBox from '../app/parts/InformationBox';
import AppInput from './AppInput';
import { IFormData, validationService, getValuesObject } from '../simple/classes/validationService';
import config from '../../config';

interface  IProps {
}

const LoginForm: React.FC<IProps> = props => {
    const dispatch = useDispatch()
    
    //Form attributes
    const [form, setForm] = useState<IFormData>({
        values: [
            {
                valid: true,
                name: 'username',
                value: '',
                invalidMessages: [],
                rules: [
                    {
                        type: 'required',
                        message: i18n._('Je potrebné zadať prihlasovacie meno!')
                    }
                ]
            },
            {
                valid: true,
                name: 'password',
                value: '',
                invalidMessages: [],
                rules: [
                    {
                        type: 'required',
                        message: i18n._('Prosím zadajte Vaše  heslo!')
                    }
                ]
            }
        ],
        valid: true
    })

    const [loading, setLoading] = useState(false)
    const loadingLogin = useSelector((state: IApplicationStore) => state.loadingStore.loadingLogin)
    const loginError = useSelector((state: IApplicationStore) => state.globalStore.userError)
            
    const handleSubmit = (e:any) => {

        e.preventDefault();

        setLoading(true)
        validationService(form).then(response => {
            setForm(response)

            setLoading(false)
            if(response.valid) {
                let values = getValuesObject(['username', 'password'], form)
                dispatch(loginUser(values))
            }
        })
    }

    // const handleRememberChange = () => {
    //     setStoreRemember(!storeRemember)
    // } //TODO:

    return (
        <form onSubmit={handleSubmit} className="form">
            <LoadingElement loadingValue={loadingLogin || loading}/>

            <div className="input-row">
                <div className="part">
                    <label htmlFor="username"><Trans>Prihlasovacie meno</Trans> <i>*</i></label>
                    <div className="input-holder">
                        <AppInput
                            type="text"
                            id="username"
                            name="username"
                            formData={form}
                            setFormData={setForm}
                            placeholder={i18n._('John')}
                            autoFocus={true}
                        />
                    </div>
                </div>
            </div>
            <div className="input-row">
                <div className="part">
                    
                    <label htmlFor="password"><Trans>Heslo</Trans> <i>*</i></label>
                        
                    <div className="input-holder">
                        <AppInput
                            type="password"
                            id="password"
                            name="password"
                            formData={form}
                            setFormData={setForm}
                            placeholder={i18n._('John')}
                        />
                    </div>
                </div>
            </div>

            {((loginError === '') || (loginError ===  null) || (typeof loginError === 'undefined'))?'':(
                <InformationBox type="error">
                    {loginError}
                </InformationBox>
            )}

            <div className="input-row">
                <div className="part">
                    {/* <div className="check checkbox">
                        <Form.Item>
                            <Input type="checkbox" name="checkbox" id="remember-checkbox" onChange={this.handleRememberChange}/>
                            <label htmlFor="remember-checkbox"><Trans>Zapamätaj si ma</Trans></label>
                        </Form.Item>
                    </div> */}
                </div>
                <div className="part">
                    <NavLink to="/restore-password" className="link"><Trans>Obnovenie hesla</Trans></NavLink>
                </div>
            </div>

            <div className="input-row">
                <div className="part">
                    
                    <input type="submit" value={i18n._("Prihlás sa")} className="ant-input bttn _primary _full"/>
                    
                </div>
            </div>
            
            <div className="divider"><span><Trans>alebo použi</Trans></span></div>
            
            <div className="input-row">
                <div className="part">
                
                    <div className="social-login text-center">
                        <ul>
                            <li><a href={config.api.loginFacebook} target="_self" className="fb">Facebook</a></li>
                            <li><a href={config.api.loginGoogle} target="_self" className="gp">Google</a></li>
                            {/* <li><NavLink to="/" target="_self" className="tw">Twitter</NavLink></li> */}
                        </ul>
                    </div>
                    
                </div>
            </div>
            <div className="input-row">
                <div className="part text-center">
                    <p>
                        <Trans>Nemáš účet?</Trans> <NavLink to="/register" className="link"><Trans>Zaregistruj sa</Trans></NavLink>
                    </p>
                </div>
            </div>
            
        </form>
    )
}

export default LoginForm
