import { setLoadingAssignedTests } from '../loadings/setLoading';
import config from '../../config';
import { handleErrors } from '../../utils/handleErrors';
import { 
    GET_ASSIGNED_TESTS_STARTED, 
    GET_ASSIGNED_TESTS, 
    GET_ASSIGNED_TESTS_FAILED } from '../../constants/action-types';
import { store } from '../../store';
import { ITest } from '../../constants/types';

export function loadAssignedTests() {
    return (dispatch: any) => {

        dispatch(setLoadingAssignedTests(true))
        dispatch(loadAssignedTestsStarted())

        return fetch(config.api.assigned, {
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + store.getState().globalStore.token
            }
            
        })
            .then(handleErrors)
            .then(res => res.json())
            .then(res => {

                let tests: ITest[] = []

                if(typeof res.data !== 'undefined') {
                    res.data.forEach((t:any) => {
                        t.tests.forEach((test:any) => {
                            if(test.valid) {
                                tests.push(test)
                            }
                        })
                    })
                }
                
                dispatch(loadAssignedTestsSuccess(tests))

                dispatch(setLoadingAssignedTests(false))
            })
            .catch((error: any)  => {
                dispatch(loadAssignedTestsFailed(error))
                dispatch(setLoadingAssignedTests(false))
            });
    };
}

export const loadAssignedTestsStarted = () => ({
    type: GET_ASSIGNED_TESTS_STARTED
});

export const loadAssignedTestsSuccess = (data:any) => ({
    type: GET_ASSIGNED_TESTS,
    payload: data
});

export const loadAssignedTestsFailed = (error: any) => ({
    type: GET_ASSIGNED_TESTS_FAILED,
    payload: { error }
});