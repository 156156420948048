import React, {Component} from 'react';
import { connect } from 'react-redux';
import { IApplicationStore } from '../../../../../constants/store-interfaces';
import { ITestResult, ITest } from '../../../../../constants/types';
import { Trans } from '@lingui/macro';
import moment from 'moment';
import ResultList from '../../../containers/TestElements/results/ResultList';
import CircularGraph from '../../../../simple/widgets/CircularGraph';

interface IDispatchProps {
}

interface IStateProps {
}

const mapStateToProps = (state: IApplicationStore): IStateProps => ({
})

const mapDispatchToProps = (dispatch: any): IDispatchProps => ({
})

interface IState {
}

interface IProps extends IDispatchProps, IStateProps {
    test: ITest
    result: ITestResult | undefined
}

class ModalUserDetailTest extends Component<IProps, IState> {
    
    render() {
        const { test, result } = this.props
        
        let validAnswers = 0

        if(typeof result !== 'undefined') {
            if(result.result === null) {
                return (null)
            }
            result.result.questions.forEach(q => {
                validAnswers += (q.valid)?1:0
            })
        }

        return (
            <div className="summary">
                <div className="summary-block">
                    <h4>
                        <i className="la la-history"/>
                        <Trans>Dátum vyplnenia testu</Trans>
                    </h4>
                    <div className="summary-content">
                        {(typeof result === 'undefined')?(
                            <Trans>Žiak nemá žiadne výsledky z tejto úlohy</Trans>
                        ):(result.date_finished !== null)?moment(result.date_finished).format('DD. MM. YYYY HH:mm'):moment(result.date_started).format('DD. MM. YYYY HH:mm')}
                    </div>
                </div>
                {((typeof result !== 'undefined') && (result.result !== null))?(
                    <div className="summary-block">
                        <h4>
                            <i className="la la-bar-chart-o"/>
                            <Trans>Výsledok</Trans> - {validAnswers}/{result.result.questions.length}
                        </h4>
                        <div className="summary-content">
                            <CircularGraph percentage={Math.round(validAnswers/result.result.questions.length*100)}/>
                        </div>
                    </div>
                ):(null)}
                {(typeof result !== 'undefined')?(
                    <div className="summary-block">
                        <h4>
                            <i className="la la-bars"/>
                            <Trans>Výsledok testu</Trans>
                        </h4>
                        <div className="summary-content results-in-modal">
                            <ResultList
                                test={test}
                                testResult={result}
                            />
                        </div>
                    </div>
                ):(null)}
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalUserDetailTest)