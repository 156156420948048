import React, { useEffect, useState} from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { IWordgroupType, ILanguageType, ITableHeaderCellType, ITableRowType, IActionType, IWordType, ITableCellType, ITestCreate, ETestUgType, IUWordGroupType, IWordCreatedType } from '../../../../../constants/types';
import { loadCreatedWordGroups } from '../../../../../actions/wordGroups/loadCreatedWordGroups';
import { IApplicationStore } from '../../../../../constants/store-interfaces';
import { Trans } from '@lingui/macro';
import Select, { createFilter } from 'react-select';
import { i18n } from '../../../../../App';
import TableContainer from '../../../containers/TableContainer';
import testdo  from '../../../../../../assets/img/table/testdo.svg';
import TranslateLanguages from '../../../../simple/TranslateLanguages';
import NumberText from '../../../../simple/tableElements/NumberText';
import { loadAssignedUserGroups } from '../../../../../actions/userGroups/loadAssignedUserGroups';
import { loadCreatedWords } from '../../../../../actions/words/loadCreatedWords';
import moment from 'moment';
import config from '../../../../../config';
import { loadCreatedUserGroups } from '../../../../../actions/userGroups/loadCreatedUserGroups';

interface IStateProps {
    wordGroups: IWordgroupType[],
    assignedWordGroups: IUWordGroupType[],
    loadingWordGroups: boolean,
    loadingWords: boolean,
    createdWords: IWordCreatedType[]
}

interface IDispatchProps {
    loadCreatedWordGroups: typeof loadCreatedWordGroups,
    loadAssignedUserGroups: typeof loadAssignedUserGroups,
    loadCreatedWords: typeof loadCreatedWords
}

const mapStateToProps = (state: IApplicationStore): IStateProps => ({
    wordGroups:  state.wordGroupsStore.createdWordgroups,
    assignedWordGroups: state.wordGroupsStore.assignedWordgroups,
    loadingWordGroups: state.loadingStore.loadingCreatedWordGroups,
    loadingWords: state.loadingStore.loadingCreatedWords,
    createdWords: state.wordsStore.createdWords
})

const mapDispatchToProps = (dispatch: any): IDispatchProps => ({
    loadCreatedWordGroups: () =>  dispatch(loadCreatedWordGroups()),
    loadAssignedUserGroups: () => dispatch(loadAssignedUserGroups()),
    loadCreatedWords: () => dispatch(loadCreatedWords())
})

interface IProps {
    handleWordGroups: any,
    handleWords: (words: IWordType[]) => any,
    test: ITestCreate,
    definedWordGroups?: IWordgroupType[],
    isTraining?: boolean
}


const TestWordSelection: React.FC<IProps> = props => {
    const dispatch = useDispatch()

    const wordGroups = useSelector((state: IApplicationStore) => state.wordGroupsStore.createdWordgroups)
    const assignedWordGroups = useSelector((state: IApplicationStore) => state.wordGroupsStore.assignedWordgroups)
    const loadingWordGroups = useSelector((state: IApplicationStore) => state.loadingStore.loadingCreatedWordGroups)
    const loadingWords = useSelector((state: IApplicationStore) => state.loadingStore.loadingCreatedWords)
    const createdWords = useSelector((state: IApplicationStore) => state.wordsStore.createdWords)

    const userInfo = useSelector((state: IApplicationStore) => state.globalStore.userInfo)
    
    const [ rows, setRows ] = useState<ITableRowType[]>([])
    const [ headers, setHeaders ] = useState<ITableHeaderCellType[]>([])
    const [ actions ] = useState<IActionType[]>([])
    const [ showTraining, setShowTraining ] = useState(false)
    const [ finalWordGroups, setFinalWordGroups ] = useState<(IWordgroupType | IUWordGroupType)[]>([])
    const [ languageFrom, setLanguageFrom ] = useState<null | ILanguageType>(null)
    const [ languageTo, setLanguageTo ] = useState<null | ILanguageType>(null)
    const [ defaultOptions, setDefaultOptions ] = useState<any>([])
    const [ selectedWordGroups, setSelectedWordGroups ] = useState<(IWordgroupType | IUWordGroupType)[]>([])
    const [ checkedWords, setCheckedWords ] = useState<string[]>([])

    useEffect(() => {
        dispatch(loadCreatedWordGroups())

        if(userInfo?.role.name === config.roles.user) {
            dispatch(loadAssignedUserGroups())
        } else {
            dispatch(loadCreatedUserGroups())
        }
        dispatch(loadCreatedWords())

        setHeaders([
            {
                content: (<span><Trans>Slovo</Trans></span>),
                sortBy: false,
                sortable: true
            },
            {
                content: (<span><Trans>Preklad</Trans></span>),
                sortBy: false,
                sortable: true
            }
        ])
    }, [dispatch, userInfo])

    useEffect(() => {
        if(typeof props.isTraining !== 'undefined') {
            setShowTraining(props.isTraining)
        }
    }, [props.isTraining])

    useEffect(() => {
        let newTypeWordGroups: IWordgroupType[] = []

        if(createdWords.length > 0) {

            createdWords.forEach(w => {
                if(w.wordgroups.length === 0) {
                    let alreadyNewWordGroup = newTypeWordGroups.find(wG => wG.language_from.id === w.language_from.id && wG.language_to.id === w.language_to.id)

                    if(typeof alreadyNewWordGroup === 'undefined') {
                        alreadyNewWordGroup = {
                            id: 0 - (newTypeWordGroups.length+1),
                            name: i18n._('Nezaradené slovíčka') + ' (' + w.language_from.name + '->' + w.language_to.name + ')',
                            language_from: w.language_from,
                            language_to: w.language_to,
                            words: [],
                            created_by: {
                                id: 0,
                                login: ''
                            },
                            created_at: moment().toISOString(),
                            updated_at: moment().toISOString()
                        }

                        newTypeWordGroups.push(alreadyNewWordGroup)
                    }

                    alreadyNewWordGroup.words.push(w)
                }
            })
        }

        let finalWordGroupsNew: (IWordgroupType | IUWordGroupType)[] = [
            ...wordGroups,
            ...assignedWordGroups,
            ...newTypeWordGroups
        ]

        if(typeof props.definedWordGroups !== 'undefined') { 
            finalWordGroupsNew = props.definedWordGroups
        }

        setFinalWordGroups(finalWordGroupsNew)
    }, [props.definedWordGroups, assignedWordGroups, wordGroups, createdWords])

    useEffect(() => {
        if(props.test.settings.wordGroups.length > 0) {
            let selectedWGIds = props.test.settings.wordGroups[0]

            let selectedWG = finalWordGroups.find(object => object.id === selectedWGIds)
            
            if(typeof selectedWG !== 'undefined') {
                setLanguageFrom(selectedWG.language_from)
                setLanguageTo(selectedWG.language_to)
            }
        } else {
            setLanguageFrom(null)
            setLanguageTo(null)
        }
    }, [props.test.settings.wordGroups, finalWordGroups])

    useEffect(() => {
        let defaultOptionsNew:any = []

        for(let wordGroup of finalWordGroups) {
            let add = false
            if( (languageFrom !== null) && (languageTo !== null) ){
                if( (languageFrom.id === wordGroup.language_from.id) && (languageTo.id === wordGroup.language_to.id) ) {
                    add = true
                }
            } else {
                add = true
            }

            if(add) {
                let label = wordGroup.name

                let wg = (wordGroup as IUWordGroupType)
                if(typeof wg.usergroup !== 'undefined' && wg.usergroup !== null) {
                    label += ' (' + wg.usergroup.usergroup_name + ')'
                }

                defaultOptionsNew.push({
                    value: wordGroup.id,
                    object: wordGroup,
                    label: label
                })
            }
        }

        setDefaultOptions(defaultOptionsNew)
    }, [finalWordGroups, languageFrom, languageTo])

    useEffect(() => {
        let selectedWordGroupsNew = finalWordGroups.filter((wg: IWordgroupType) => props.test.settings.wordGroups.includes(wg.id))

        let checkedWordsNew: string[] = []

        selectedWordGroupsNew.forEach((wG: IWordgroupType) => {
            wG.words.forEach(w => {
                checkedWordsNew.push(w.word_text + w.translation)
            })
        })

        setCheckedWords(checkedWordsNew)
        setSelectedWordGroups(selectedWordGroupsNew)
    }, [finalWordGroups, props.test.settings.wordGroups])

    useEffect(() => {
        let rows: ITableRowType[]  = []

        let words: IWordType[] = []

        selectedWordGroups.forEach(wg => {
            wg.words.forEach(word => {
                let foundWord = words.find(w => (((w.translation === word.translation) && (w.word_text === word.word_text))||((w.word_text === word.translation) && (w.translation === word.word_text) ) ))

                if(typeof foundWord === 'undefined') {
                    words.push(word)
                }
            })
        })

        for(let word of words) {
            let cells: ITableCellType[] = []

            cells = [
                {
                    content: (<div className="word text-left">{word.word_text}</div>),
                    stringToOrder:  word.word_text,
                    filterByString: true,
                    filterString: word.word_text
                },
                {
                    content: (<div className="word text-left">{word.translation}</div>),
                    stringToOrder:  word.translation,
                    filterByString: true,
                    filterString: word.translation
                }
            ]

            rows.push({
                cells: cells,
                catchString: word.word_text + word.translation
            })
        }
        
        setRows(rows)
    }, [selectedWordGroups])

    const onWordGroupChange = (inputValue:any) => {
        props.handleWordGroups(inputValue)
    }

    const handleCheckedWords = (catchStrings:  string[]) => {
        const { test } = props

        let wordsIds = catchStrings

        let words:IWordType[] = []
        
        for(let wgId of test.settings.wordGroups) {

            let wg = undefined
            
            if(typeof props.definedWordGroups === 'undefined') {
                wg = finalWordGroups.find(object => object.id === wgId)
            } else {
                wg = props.definedWordGroups.find(object => object.id === wgId)
            }

            if(typeof wg !== 'undefined') {
                let wds = wg.words.filter(value => wordsIds.indexOf(value.word_text + value.translation) > -1)
                
                wds.forEach(w => {
                    let alreadyInserted = words.find(wd => (((w.translation === wd.translation) && (w.word_text === wd.word_text)) || ((w.translation === wd.word_text) && w.word_text === wd.translation)))

                    if(typeof alreadyInserted === 'undefined') {
                        words.push(w)
                    }
                })
            }
        }

        props.handleWords(words)
    }

    let selectedValue = selectedWordGroups.map((wg: IWordgroupType) => {
        return {
            value: wg.id,
            object: wg,
            label: wg.name
        }
    })

    let emptyString = i18n._('Ďalšie skupiny si musíte najskôr vytvoriť')

    if(typeof props.definedWordGroups !== 'undefined') {
        emptyString = i18n._('Najprv priraďte triede skupinu slovíčok.')
    }

    let title = i18n._('Slovíčka v písomke')
    let subtitle = i18n._('Vyberte skupiny slovíčok, z ktorých chcete vytvoriť novú písomku.')
    
    if((typeof props.test.settings.ugType !== 'undefined') && (props.test.settings.ugType === ETestUgType.homework)) {
        title = i18n._('Slovíčka na precvičovanie')
        subtitle = i18n._('Vyberte skupiny slovíčok, ktoré si budú môcť žiaci precvičovať ako domáce úlohy.')
    }

    if(showTraining) {
        title = i18n._('Slovíčka na cvičenie')
        subtitle = i18n._('Vyberte skupiny slovíčok, ktoré si chcete precvičovať.')
    }

    return (
        <div>
            <h4 className="headline-divider">{title}</h4>
            <p className="note">
                {subtitle}
            </p>
            
            <div className="input-row">
                <div className="customSelects">
                    <div className="part">
                        
                        <Select
                            closeMenuOnSelect={true}
                            isMulti
                            options={defaultOptions}
                            className="customSelect"
                            classNamePrefix="customSelectWrapper"
                            onChange={onWordGroupChange}
                            value={selectedValue}
                            noOptionsMessage={() => emptyString}
                            placeholder={i18n._("Vyberte skupinu/y slovíčok")}
                            isLoading={loadingWordGroups || loadingWords}
                            filterOption={createFilter({
                                matchFrom: 'any',
                                stringify: option => `${option.label}`,
                            })}
                        />                                                  
                        
                    </div>
                </div>
            </div>
            {(
                (selectedWordGroups.length > 0) &&
                (languageFrom !== null) &&
                (languageTo !== null)
            )?(
                <div className="input-row">
                    <div className="part">
                        <h4 className="headline-divider"><Trans>Jazyky</Trans></h4>
                        <div className="part">
                            <TranslateLanguages
                                languageFrom={languageFrom}
                                languageTo={languageTo}
                            />
                        </div>
                    </div>
                    <div className="part">
                        <h4 className="headline-divider text-left"><Trans>Počet slov</Trans></h4>
                        <div className="part">
                            <NumberText
                                number={props.test.settings.words.length}
                                title={(props.test.settings.words.length === 1)?(i18n._('slovíčko')):(props.test.settings.words.length <= 4)?(i18n._('slovíčka')):(i18n._('slovíčok'))}
                                text={(props.test.settings.words.length === 1)?(i18n._('slovíčko')):(props.test.settings.words.length <= 4)?(i18n._('slovíčka')):(i18n._('slovíčok'))}
                            />
                        </div>
                    </div>
                </div>
            ):(null)}
            {(selectedWordGroups.length > 0)?(
                <div className="input-row">
                    <TableContainer
                        headers={headers}
                        rows={rows}
                        actions={actions}
                        showActions={false}
                        showCheckbox={true}
                        checkboxFunction={handleCheckedWords}
                        showFilter={false}
                        showHeader={false}
                        pageSize={10}
                        emptyText={i18n._('Vybrané skupiny slovíčok neobsahujú žiadne slová')}
                        showNew={false}
                        showGrid={false}
                        title={i18n._('Vybrané slová')}
                        showForm={false}
                        customImage={testdo}
                        checkedValues={checkedWords}
                    />
                </div>
            ):(null)}
        </div>  
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(TestWordSelection)