import { i18n } from '../../../App';
import AlphieLanguages from './alphieLanguages';

class VoiceClass {
    private static instance: VoiceClass
    private language: string
    private text: string

    private constructor(language: string, text: string) {
        this.language = language
        this.text = text
    }

    public static initialize() {
        if(!VoiceClass.instance) {
            VoiceClass.instance = new VoiceClass('GB', '')
        }
    }

    public static getInstance(language: string, text: string): VoiceClass {
        if(!VoiceClass.instance) {
            VoiceClass.instance = new VoiceClass(language, text)
        } else {
            VoiceClass.instance.setData(language,text)
        }
        return VoiceClass.instance
    }

    public setData(language: string, text: string) {
        this.language = language
        this.text = text
    }

    public speak(stop?: boolean) {

        let language = AlphieLanguages.getLanguageVoiceName(this.language)

        let success = true

        if(window.responsiveVoice.voiceSupport() && (language !== null)) {
            if((typeof stop !== 'undefined') && stop) {
                window.responsiveVoice.cancel()
            }

            window.responsiveVoice.setDefaultVoice(language)
            window.responsiveVoice.speak(
                this.text,
                language
            )
        } else {
            success = false
        }
        
        if(!success) {
            alert(i18n._('Žiaľ preklad v tomto jazyku nevieme prečítať.'))
        }
    }

    public isAvailable() {
        let language = AlphieLanguages.getLanguageVoiceName(this.language)

        if(language !== null) {
            return true
        }

        return false
    }
}

export default VoiceClass