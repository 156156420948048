import React, {useState, useEffect} from 'react';
import { ILanguageType, IAddWordMapping, ITestResult } from '../../../../../../constants/types';
import SummaryWordFromTo from '../../../small/SummaryWordFromTo';


interface IProps {
    words: IAddWordMapping[],
    languageFrom: ILanguageType,
    languageTo: ILanguageType,
    currentUserGroup?: number
    results?: ITestResult[],
}

interface IWordResults {
    from: string,
    to: string,
    result?: number
}

const AllWordsSummary: React.FC<IProps> = props => {
    const [words, setWords] = useState<IWordResults[]>([])

    useEffect(() => {
        let newWords: IWordResults[] = []
        
        props.words.forEach(w => {
            let newWord: IWordResults = {
                from: w.from,
                to: w.to
            }

            let corrects = 0
            let total = 0

            if(typeof props.results !== 'undefined') {
                props.results.forEach(r => {
                    if(r.result !== null) {
                        r.result.questions.forEach(q => {
                            if(q.word.from === w.from && q.word.to === w.to) {
                                total += 1
                                corrects += (typeof q.valid !== 'undefined' && q.valid)?1:0
                            }
                        })
                    }
                })
            }

            if(total > 0) {
                newWord.result = Math.round(corrects/total*100)
            }

            newWords.push(newWord)
        })

        setWords(newWords)
    }, [props.words, props.languageFrom, props.languageTo, props.results])

    return (
        <div className="langs">
            {words.map((word:IWordResults, index: number) => (
                <SummaryWordFromTo
                    word={word.from}
                    translation={word.to}
                    languageFrom={props.languageFrom}
                    languageTo={props.languageTo}
                    key={index}
                    showResult={true}
                    result={word.result}
                />
            ))}
        </div>
    )
}

export default AllWordsSummary