import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { IApplicationStore } from '../../constants/store-interfaces';
import { Trans } from '@lingui/macro';
import { i18n } from '../../App';
import LoadingElement from '../simple/LoadingElement';
import student from '../../../assets/img/icons8-user-male-80.png'
import teacher from '../../../assets/img/icons8-student-male-80.png'
import { checkUserName } from '../../actions/global/checkUserName';
import ReactCountryFlag from 'react-country-flag';
import Select, { createFilter } from 'react-select';
import { IFormData, getValue, addValue, removeValue, validationService, getValuesObject } from '../simple/classes/validationService';
import AppInput from './AppInput';
import { registerUser } from '../../actions/global/registerUser';
import { checkCode } from '../../actions/userGroups/checkCode';
import InformationBox from '../app/parts/InformationBox';

var countries = require("i18n-iso-countries");


const getLanguageOptions = (lng: string) => {
    countries.registerLocale(require("i18n-iso-countries/langs/" + lng + ".json"))

    let languagesOptions: any[] = []

    let cntrList = countries.getNames(lng)

    for (var key of Object.keys(cntrList)) {
        languagesOptions.push({
            value: key.toLowerCase(),
            flagName: key.toLowerCase(),
            label: (typeof cntrList[key] === 'string')?cntrList[key]:cntrList[key][0]
        })
    }

    languagesOptions.sort((a:any, b:any) => a.label.localeCompare(b.label))

    return languagesOptions
}


const formatOptionLabel = ({ value, label, flagName }:any) => (
    <div>
        <ReactCountryFlag className="flag" countryCode={flagName} svg/>{label}
    </div>
)

interface IProps {}

const RegisterForm: React.FC<IProps> = props => {
    const dispatch = useDispatch()

    const params = new URLSearchParams(window.location.search)
    const code = params.get('code')

    const loadingRegister = useSelector((state: IApplicationStore) => state.loadingStore.loadingRegister)
    const language = useSelector((state: IApplicationStore) => state.globalStore.language)

    const [showTeacherBlock, setShowTeacherBlock] = useState<boolean>(false)
    const [showRegisterCode, setShowRegisterCode] = useState<boolean>(( (code !== null) && (code.length > 0) ) ? true : false)
    const [country, setCountry] = useState<any>(getLanguageOptions(language).find(l => l.value === 'sk'))
    const [toAssignUG, setToAssignUG] = useState<string|null>(null)

    const [form, setForm] = useState<IFormData>({
        values: [
            {
                valid: true,
                name: 'login',
                value: '',
                invalidMessages: [],
                rules: [
                    {
                        type: 'required',
                        message: i18n._('Je potrebné zadať prihlasovacie meno!')
                    },
                    {
                        type: 'function',
                        message: i18n._('Používateľ so zadaným menom už existuje. Vyberte si iné meno.'),
                        validFunction: async (value: string) => {
                            return new Promise((resolve, reject) => {
                                try {
                                    if(value.length > 0) {
                                        
                                        checkUserName(value)
                                            .then((res) => {
                                                let ret = (res === 'true')?true:false

                                                if(ret) {
                                                    resolve(false)
                                                } else {
                                                    resolve(true)
                                                }
                                            })
                                            
                                    } else {
                                        resolve(true)
                                    }
                                } catch (err) {
                                    resolve(true)
                                }
                            })
                        }
                    },
                    {
                        type: 'regex',
                        regex: /^[a-zA-Z0-9_-]+$/,
                        message: i18n._('Musíte zadať prihlasovacie meno bez medzery, ktoré obsahuje iba malé a veľké písmená, čísla a znak _ alebo -.')
                    }
                ]
            },
            {
                valid: true,
                name: 'password',
                value: '',
                invalidMessages: [],
                rules: [
                    {
                        type: 'required',
                        message: i18n._('Je potrebné zadať nové heslo!')
                    },
                    {
                        type: 'regex',
                        message: i18n._('Je potrebné zadať heslo, ktoré obsahuje aspoň jedno veľké písmeno, jedno malé písmeno, aspoň jedno číslo a má minimálne 5 znakov.'),
                        regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d[\]{};:=<>/_+^#$@!%*?&]{5,}$/
                    },
                    {
                        type: 'function',
                        message: i18n._('Musíte zadať rovnaké heslá!'),
                        validFunction: async (value: string) => {
                            return new Promise((resolve, reject) => {
                                let password = getValue('password', form)
                                let passwordCheck = getValue('password2', form)

                                if(
                                    typeof password === 'undefined' ||
                                    typeof passwordCheck === 'undefined'
                                ) {
                                    resolve(true)
                                    return
                                }

                                if(typeof passwordCheck.value === 'string' && passwordCheck.value.length === 0) {
                                    resolve(true)
                                    return
                                }

                                if(passwordCheck.value !== password.value) {
                                    resolve(false)
                                } else {
                                    resolve(true)
                                }
                            })
                        }
                    }
                ]
            },
            {
                valid: true,
                name: 'password2',
                value: '',
                invalidMessages: [],
                rules: [
                    {
                        type: 'required',
                        message: i18n._('Prosím zopakujte Vaše zadané heslo!')
                    },
                    {
                        type: 'regex',
                        message: i18n._('Je potrebné zadať heslo, ktoré obsahuje aspoň jedno veľké písmeno, jedno malé písmeno, aspoň jedno číslo a má minimálne 5 znakov.'),
                        regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d[\]{};:=<>/_+^#$@!%*?&]{5,}$/
                    },
                    {
                        type: 'function',
                        message: i18n._('Musíte zadať rovnaké heslá!'),
                        validFunction: async (value: string) => {
                            return new Promise((resolve, reject) => {
                                let password = getValue('password', form)
                                let passwordCheck = getValue('password2', form)

                                if(
                                    typeof password === 'undefined' ||
                                    typeof passwordCheck === 'undefined'
                                ) {
                                    resolve(true)
                                    return
                                }

                                if(typeof password.value === 'string' && password.value.length === 0) {
                                    resolve(true)
                                    return
                                }

                                if(passwordCheck.value !== password.value) {
                                    resolve(false)
                                } else {
                                    resolve(true)
                                }
                            })
                        }
                    }
                ]
            },
            {
                valid: true,
                name: 'name',
                value: '',
                invalidMessages: [],
                rules: [
                    {
                        type: 'required',
                        message: i18n._('Je potrebné zadať meno!')
                    }
                ]
            },
            {
                valid: true,
                name: 'surname',
                value: '',
                invalidMessages: [],
                rules: [
                    {
                        type: 'required',
                        message: i18n._('Je potrebné zadať priezvisko!')
                    }
                ]
            },
            {
                valid: true,
                name: 'email',
                value: '',
                invalidMessages: [],
                rules: [
                    {
                        type: 'required',
                        message: i18n._('Prosím zadajte Váš e-mail!')
                    },
                    {
                        type: 'email',
                        message: i18n._('Nezadali ste e-mail!'),
                    }
                ]
            },
            {
                valid: true,
                name: 'telephone',
                value: '',
                invalidMessages: [],
                rules: []
            },
            {
                valid: true,
                name: 'privacy-checkbox',
                value: false,
                invalidMessages: [],
                rules: [
                    {
                        type: 'required',
                        message: i18n._('Pre pokračovanie je potrebné súhlasiť so spracovaním údajov!')
                    }
                ]
            }
        ],
        valid: true
    })

    useEffect(() => {
        if(showRegisterCode) {
            setForm(f => {

                let newForm = addValue(f, {
                    valid: true,
                    name: 'access_code',
                    value: code !== null?code:'',
                    disabled: code !== null?true:false,
                    invalidMessages: [],
                    rules: [
                        {
                            type: 'required',
                            message: i18n._('Musíte zadať registračný kód')
                        },
                        {
                            type: 'function',
                            message: i18n._('Registračný kód je neplatný, skontrolujte, či zadávate správny kód.'),
                            validFunction: async (value: string) => {
                                return new Promise((resolve, reject) => {
                                    try {
                                        if(value.length > 0) {
                                            checkCode(value.toUpperCase().replace(/ /g,''))
                                                .then((res) => {
                                                    if(res.status === 'success' && res.message === "true") {
                                                        resolve(true)
                                                        setToAssignUG(res.data)
                                                    } else {
                                                        setToAssignUG(null)
                                                        resolve(false)
                                                    }
                                                })
                                                
                                        } else {
                                            resolve(true)
                                        }
                                    } catch (err) {
                                        resolve(true)
                                    }
                                })
                            }
                        }
                    ]
                })
                return {...newForm}
            })
        } else {
            setForm(f => {
                return {...removeValue(f, 'access_code')}
            })
        }
    }, [code,showRegisterCode])

    useEffect(() => {
        if(showTeacherBlock) {
            setForm(f => {
                return {...addValue(f, {
                    valid: true,
                    name: 'school',
                    value: '',
                    invalidMessages: [],
                    rules: [
                        {
                            type: 'required',
                            message: i18n._('Zadajte prosím názov školy')
                        }
                    ]
                })}
            })
        } else {
            setForm(f => {
                return {...removeValue(f, 'school')}
            })
        }
    }, [showTeacherBlock])

    const handleSubmit = (e:any) => {

        e.preventDefault();

        validationService(form).then(response => {
            setForm(f => {
                return {...response}
            })
            if(response.valid) {
                let values = getValuesObject(['login', 'password', 'password2', 'name', 'surname', 'email', 'telephone', 'privacy-checkbox', 'access_code', 'school'], form)

                delete values['password2']
                delete values['privacy-checkbox']

                if(showTeacherBlock) {
                    values['is_teacher']  = true
                } else {
                    values['is_teacher'] = false
                }

                if(!showRegisterCode) {
                    values['access_code'] = ''
                }

                values['access_code'] = values['access_code'].toUpperCase().replace(/ /g,'')

                values['language'] = language
                values['country'] = country.value

                if(typeof values['telephone'] == 'undefined') {
                    values['telephone'] = ''
                }

                if(typeof values['school'] === 'undefined') {
                    values['school'] = null
                }

                dispatch(registerUser(values))
            }
        })
    }

    const handleTeacherChange = (e: any) =>  {
        if(e.target.id ===  'type-02') {
            setShowTeacherBlock(true)
        } else {
            setShowTeacherBlock(false)
        }
    }

    const handleRegisterChange = () => {
        setShowRegisterCode(!showRegisterCode)
    }

    let languageOptions = getLanguageOptions(language)

    return (
        <form onSubmit={handleSubmit} className="form _register">
            
            <LoadingElement loadingValue={loadingRegister}/>
            
            <div className="divider"><span><Trans>Prihlasovacie údaje</Trans></span></div>
            
            <div className="input-row">
                <div className="part">
                    <label htmlFor="login"><Trans>Prihlasovacie meno</Trans> <i>*</i></label>
                    
                    <div className="input-holder">
                        <AppInput
                            type="text"
                            id="login"
                            name="login"
                            formData={form}
                            setFormData={setForm}
                            placeholder={i18n._('Prihlasovacie meno')}
                            autoFocus={true}
                            autoComplete={false}
                        />                        
                    </div>
                    
                </div>
            </div>

            <div className="input-row">
                <div className="part">
                    <label htmlFor="password"><Trans>Heslo</Trans> <i>*</i></label>
                    
                    <div className="input-holder">

                        <AppInput
                            type="password"
                            id="password"
                            name="password"
                            formData={form}
                            setFormData={setForm}
                            autoComplete={false}
                            checkOthers={['password2']}
                        />
                    
                    </div>
                    
                </div>
                <div className="part">
                    <label htmlFor="password2"><Trans>Zopakovať heslo</Trans> <i>*</i></label>
                    
                    <div className="input-holder">

                        <AppInput
                            type="password"
                            id="password2"
                            name="password2"
                            formData={form}
                            setFormData={setForm}
                            autoComplete={false}
                            checkOthers={['password']}
                        />
                    
                    </div>
                    
                </div>
            </div>

            <div className="divider">
                <span><Trans>Registračný kód</Trans></span>
            </div>

            <div className="input-row">
                <div className="part input-checkbox _checkbox-center">
                    <div className="check toggle">
                        <div className="input-holder">

                            <input type="checkbox" name="checkbox" id="code-checkbox" onChange={handleRegisterChange} defaultChecked={showRegisterCode} disabled={(code !== null)}/>
                            <label htmlFor="code-checkbox"><Trans>Mám registračný kód</Trans></label>
                        </div>
                    </div>
                </div>
            </div>
            
            {(showRegisterCode)?(
            <div className="input-row">

                    <div className="part">
                        <div className="input-holder">
                            <AppInput
                                type="text"
                                id="access_code"
                                name="access_code"
                                formData={form}
                                setFormData={setForm}
                                placeholder={i18n._('Ak máš dostupný kód, môžete ho zadať.')}
                                autoFocus={true}
                                autoComplete={false}
                            />                        
                        </div>
                        <div className="input-holder">
                            {(toAssignUG !== null)?(
                                <InformationBox type="info">
                                    <Trans>Budete priradený do triedy</Trans>:<strong>{toAssignUG}</strong>
                                </InformationBox>
                            ):(null)}
                        </div>
                    </div>
            </div>
            ):(null)}

            <div className="divider"><span><Trans>Osobné údaje</Trans></span></div>
            
            <div className="input-row">
                <div className="part">
                    <label htmlFor="name"><Trans>Vaše meno</Trans> <i>*</i></label>
                    
                    <div className="input-holder">
                        <AppInput
                            type="text"
                            id="name"
                            name="name"
                            formData={form}
                            setFormData={setForm}
                            placeholder={i18n._('Meno')}
                        />                    
                    </div>
                    
                </div>
                <div className="part">
                    <label htmlFor="surname"><Trans>Vaše priezvisko</Trans> <i>*</i></label>
                    
                    <div className="input-holder">
                        <AppInput
                            type="text"
                            id="surname"
                            name="surname"
                            formData={form}
                            setFormData={setForm}
                            placeholder={i18n._('Priezvisko')}
                        />                    
                    </div>
                    
                </div>
            </div>
            <div className="input-row">
                <div className="part">
                    <label htmlFor="email"><Trans>Váš e-mail</Trans> <i>*</i></label>
                    
                    <div className="input-holder">
                        <AppInput
                            type="text"
                            id="email"
                            name="email"
                            formData={form}
                            setFormData={setForm}
                            placeholder={i18n._('E-mail')}
                            enableAutoCapitalize={false}
                        />                        
                    </div>
                    
                </div>
                <div className="part">
                    <label htmlFor="telephone"><Trans>Telefón</Trans></label>
                    
                    <div className="input-holder">
                        <AppInput
                            type="text"
                            id="telephone"
                            name="telephone"
                            formData={form}
                            setFormData={setForm}
                            placeholder={i18n._('Telefón')}
                        />                        
                    </div>
                    
                </div>
            </div>

            <div className="input-row">
                <div className="part">
                    <label htmlFor="country"><Trans>Krajina</Trans></label>

                    <Select
                        id='country'
                        formatOptionLabel={formatOptionLabel}
                        options={languageOptions}
                        className="customSelect"
                        classNamePrefix="customSelectWrapper"
                        defaultMenuIsOpen={false} 
                        onChange={setCountry}
                        isSearchable={false}
                        value={country}
                        filterOption={createFilter({
                            matchFrom: 'any',
                            stringify: option => `${option.label}`,
                        })}
                    />
                    
                </div>
            </div>
            
            <div className="divider"><span><Trans>Typ účtu</Trans></span></div>
            
            <div className="input-row">
                <div className="part">
                    <div className="type-selects">

                        <ul>
                            <li>
                            
                                <div className="type-select">
                                    <input type="radio" id="type-01" name="type" onChange={handleTeacherChange} defaultChecked/>
                                    <label htmlFor="type-01">
                                        <img src={student} alt=""/>
                                        <Trans>Študent / samouk</Trans>
                                    </label>
                                </div>
                            
                            </li>
                            <li>
                                
                                <div className="type-select">
                                    <input type="radio" id="type-02" name="type" onChange={handleTeacherChange}/>
                                    <label htmlFor="type-02">
                                        <img src={teacher} alt=""/>
                                        <Trans>Učiteľ</Trans>
                                    </label>
                                </div>
                            
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            {(showTeacherBlock)?(
                <>
                    <div className="input-row">
                        <div className="part">
                            <label htmlFor="school"><Trans>Názov školy</Trans> <i>*</i></label>
                            
                            <div className="input-holder">
                                <AppInput
                                    type="text"
                                    id="school"
                                    name="school"
                                    formData={form}
                                    setFormData={setForm}
                                    placeholder={i18n._('Názov školy')}
                                />                                
                            </div>
                            
                        </div>
                    </div>
                </>
            ):(null)}
            
            <div className="divider"><span><Trans>Registrácia</Trans></span></div>
            
            
            <div className="input-row">
                <div className="part">
                    <div className="check checkbox">
                        <div className="input-holder">
                            <AppInput
                                type="checkbox"
                                id="privacy-checkbox"
                                name="privacy-checkbox"
                                formData={form}
                                setFormData={setForm}
                                label={i18n._('Súhlasím so spracovaním údajov!')}
                            />
                        </div>
                    </div>
                    
                </div>
            </div>
            
            <div className="input-row">
                <div className="part">
                    
                    <input type="submit" value={i18n._("Zaregistruj sa")} className="ant-input bttn _primary _full"/>
                    
                </div>
            </div>
            
            <div className="input-row">
                <div className="part text-center">
                    
                    <p><Trans>Už máš účet?</Trans> <NavLink to="/login" className="link"><Trans>Prihlás sa!</Trans></NavLink></p>
                    
                    
                    <p><Trans>Zabudol si svoje heslo?</Trans>&nbsp;<NavLink to="/restore-password" className="link"><Trans>Obnov si ho tu</Trans></NavLink>!</p>
                    
                </div>
            </div>
            
        </form>
    )
}

export default RegisterForm
