import React, {Component} from 'react';
import { connect } from 'react-redux';
import { IApplicationStore } from "../../../constants/store-interfaces";
import { Trans } from '@lingui/macro';
import ModalAddUserToUserGroup from '../parts/modals/userGroup/ModalAddUserToUserGroup';
import CreatedUserGroupsTable from '../parts/userGroups/CreatedUserGroupsTable';
import AssignedUserGroupsTable from '../parts/userGroups/AssignedUserGroupsTable';
import AuthUserWrapper from '../containers/Authentification/AuthUserWrapper';
import AuthTeacherWrapper from '../containers/Authentification/AuthTeacherWrapper';
import { withRouter, RouteComponentProps } from 'react-router';
import ReactGA from 'react-ga';

interface IStateProps {
}

interface IDispatchProps {
}

const mapStateToProps = (state: IApplicationStore): IStateProps => ({
})

const mapDispatchToProps = (dispatch: any): IDispatchProps => ({
})

interface IProps extends IStateProps, IDispatchProps, RouteComponentProps {

}

interface IState {
    showAddToUserGroupModal: boolean
}

class UserGroupsPage extends Component<IProps, IState> {
    constructor(props: any) {
        super(props) 

        this.state = {
            showAddToUserGroupModal: false
        }

        ReactGA.pageview('Zoznam tried')

        this.toggleAddToUserGroupModal = this.toggleAddToUserGroupModal.bind(this)
    }

    toggleAddToUserGroupModal() {
        this.setState({
            ...this.state,
            showAddToUserGroupModal: !this.state.showAddToUserGroupModal
        })
    }

    render() {
        const { showAddToUserGroupModal } = this.state

        return (
            <div className="rows">
                <div className="row">
                    <div className="row-part">
                        <div className="page-header">
                            <div className="part">
                                <h1><Trans>Zoznam tried</Trans></h1>
                                <AuthUserWrapper>
                                    <p><Trans>Všetky triedy, do ktorých patrím</Trans></p>
                                </AuthUserWrapper>
                                <AuthTeacherWrapper>
                                    <p><Trans>Všetky moje vytvorené triedy</Trans></p>
                                </AuthTeacherWrapper>
                            </div>
                            <div className="part">
                                <AuthUserWrapper>
                                    <div className="bttn _small _green _icon" onClick={this.toggleAddToUserGroupModal} data-tour="step18">
                                        <i className="la la-plus-circle"/>
                                        <Trans>Pridať sa do triedy</Trans>
                                    </div>
                                </AuthUserWrapper>
                            </div>
                        </div>  
                    </div>
                </div>

                <div className="row">
                    <div className="row-part">
                        <AuthUserWrapper>
                            <AssignedUserGroupsTable/>
                        </AuthUserWrapper>
                        
                        <AuthTeacherWrapper>
                            <CreatedUserGroupsTable/>
                        </AuthTeacherWrapper>
                    </div>
                </div>

                
                <ModalAddUserToUserGroup
                    showModal={showAddToUserGroupModal}
                    hideModalFunction={() => this.toggleAddToUserGroupModal()}
                />

            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UserGroupsPage))