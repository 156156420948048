import React from 'react';
import MessengerCustomerChat from 'react-messenger-customer-chat';
import { useSelector } from 'react-redux';
import { IApplicationStore } from '../../constants/store-interfaces';
import AlphieLanguages from '../simple/classes/alphieLanguages';
import { i18n } from '../../App';

interface IProps {
}

const Feedback: React.FC<IProps> = props => {
    const language = useSelector((state: IApplicationStore) => state.globalStore.language)

    return (
        <MessengerCustomerChat
            pageId="237793310897045"
            appId="325782531948949"
            htmlRef="test"
            shouldShowDialog={false}
            minimized={true}
            themeColor={'#4D7CFE'}
            loggedInGreeting={i18n._('Ahoj, ako ti môžeme pomôcť?')}
            loggedOutGreeting={i18n._('Ahoj, ako ti môžeme pomôcť?')}
            debug={true}
            language={AlphieLanguages.convertTwoCharToFullLang(language.toUpperCase())?.replace('-','_')}
        />
    )
}

export default Feedback