import { setLoadingAssignedUserGroups } from '../loadings/setLoading';
import config from '../../config';
import { handleErrors } from '../../utils/handleErrors';
import { 
    GET_USERGROUPS_ASSIGNED, 
    GET_USERGROUPS_ASSIGNED_FAILED, 
    GET_USERGROUPS_ASSIGNED_STARTED } from '../../constants/action-types';
import { store } from '../../store';

export function loadAssignedUserGroups() {
    return (dispatch: any) => {

        dispatch(setLoadingAssignedUserGroups(true))
        dispatch(loadAssignedUserGroupsStarted())

        return fetch(config.api.usergroups_assigned, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + store.getState().globalStore.token
            }
            
        })
            .then(handleErrors)
            .then(res => res.json())
            .then(res => {
                dispatch(loadAssignedUserGroupsSuccess(res.data))

                dispatch(setLoadingAssignedUserGroups(false))
            })
            .catch((error: any)  => {
                dispatch(loadAssignedUserGroupsFailed(error))
                dispatch(setLoadingAssignedUserGroups(false))
            });
    };
}

export const loadAssignedUserGroupsStarted = () => ({
    type: GET_USERGROUPS_ASSIGNED_STARTED
});

export const loadAssignedUserGroupsSuccess = (data:any) => ({
    type: GET_USERGROUPS_ASSIGNED,
    payload: data
});

export const loadAssignedUserGroupsFailed = (error: any) => ({
    type: GET_USERGROUPS_ASSIGNED_FAILED,
    payload: { error }
});