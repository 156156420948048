import React, { useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { IApplicationStore } from '../../../constants/store-interfaces';
import { IUSerInfo } from '../../../constants/types';
import { Trans } from '@lingui/macro';
import { i18n } from '../../../App';
import { useEffect } from 'react';
import Select from 'react-select';
import ReactCountryFlag from 'react-country-flag';
import { updateUser } from '../../../actions/global/updateUser';
import LoadingElement from '../../simple/LoadingElement';
import config from '../../../config';
import { store } from '../../../store';
import { addFloatingNotification } from '../../../actions/notifications/floating';
import { handleErrors } from '../../../utils/handleErrors';
import InformationBox from '../parts/InformationBox';
import { checkUserName } from '../../../actions/global/checkUserName';
import { IFormData, validationService, getValuesObject, getValue, setValues } from '../../simple/classes/validationService';
import AppInput from '../../forms/AppInput';
import ReactGA from 'react-ga';

const formatOptionLabel = ({ value, label, flagName }:any) => (
    <div>
        <ReactCountryFlag className="flag" countryCode={flagName} svg/>{label}
    </div>
)

interface IProps {

}

const SettingsPage: React.FC<IProps> = props => {    
    const dispatch = useDispatch()

    const languageOptions = useMemo(() => [
        // {
        //     value: 'en',
        //     flagName: 'gb',
        //     label: i18n._('Anglický')
        // },
        {
            value: 'sk',
            flagName: 'sk',
            label: i18n._('Slovenský')
        }
    ], [])

    const userInfo = useSelector<IApplicationStore, IUSerInfo | null>(state => state.globalStore.userInfo)
    const loading = useSelector<IApplicationStore, boolean>(state => state.loadingStore.loadingLogin)

    const [language, setLanguage] = useState<{value: string, flagName: string, label: string}|null>(null)
    const [loadingPassword, setLoadingPassword] = useState<boolean>(false)

    const [formSettings, setFormSettings] = useState<IFormData>({
        values: [
            {
                valid: true,
                name: 'login',
                value: userInfo?userInfo.login:'',
                invalidMessages: [],
                rules: [
                    {
                        type: 'required',
                        message: i18n._('Je potrebné zadať prihlasovacie meno!')
                    },
                    {
                        type: 'function',
                        message: i18n._('Používateľ so zadaným menom už existuje. Vyberte si iné meno.'),
                        validFunction: async (value: string) => {
                            return new Promise((resolve, reject) => {
                                try {
                                    if(value.length > 0) {
                                        if(userInfo && value !== userInfo.login) {
                                            checkUserName(value)
                                                .then((res) => {
                                                    let ret = (res === 'true')?true:false

                                                    if(ret) {
                                                        resolve(false)
                                                    } else {
                                                        resolve(true)
                                                    }
                                                })
                                        } else {
                                            resolve(true)
                                        }
                                    } else {
                                        resolve(true)
                                    }
                                } catch (err) {
                                    resolve(true)
                                }
                            })
                        }
                    },
                    {
                        type: 'regex',
                        regex: /^[a-zA-Z0-9_-]+$/,
                        message: i18n._('Musíte zadať prihlasovacie meno bez medzery, ktoré obsahuje iba malé a veľké písmená, čísla a znak _ alebo -.')
                    }
                ]
            },
            {
                valid: true,
                name: 'name',
                value: userInfo?userInfo.name:'',
                invalidMessages: [],
                rules: [
                    {
                        type: 'required',
                        message: i18n._('Je potrebné zadať meno!')
                    }
                ]
            },
            {
                valid: true,
                name: 'surname',
                value: userInfo?userInfo.surname:'',
                invalidMessages: [],
                rules: [
                    {
                        type: 'required',
                        message: i18n._('Je potrebné zadať priezvisko!')
                    }
                ]
            },
            {
                valid: true,
                name: 'email',
                value: userInfo?userInfo.email:'',
                invalidMessages: [],
                rules: [
                    {
                        type: 'required',
                        message: i18n._('Prosím zadajte Váš e-mail!')
                    },
                    {
                        type: 'email',
                        message: i18n._('Nezadali ste e-mail!'),
                    }
                ]
            },
            {
                valid: true,
                name: 'telephone',
                value: userInfo?userInfo.telephone:'',
                invalidMessages: [],
                rules: []
            },
            {
                valid: true,
                name: 'school',
                value: userInfo&&userInfo?.school?userInfo.school:'',
                invalidMessages: [],
                rules: [
                    {
                        type: 'required',
                        message: i18n._('Prosím zadajte názov Vašej školy!')
                    }
                ]
            },
        ],
        valid: true
    })

    const [formPassword, setFormPassword] = useState<IFormData>({
        values: [
            {
                valid: true,
                name: 'old_password',
                value: '',
                invalidMessages: [],
                rules: [
                    {
                        type: 'required',
                        message: i18n._('Je potrebné zadať staré heslo!')
                    }
                ]
            },
            {
                valid: true,
                name: 'new_password',
                value: '',
                invalidMessages: [],
                rules: [
                    {
                        type: 'required',
                        message: i18n._('Je potrebné zadať nové heslo!')
                    },
                    {
                        type: 'regex',
                        message: i18n._('Je potrebné zadať heslo, ktoré obsahuje aspoň jedno veľké písmeno, jedno malé písmeno, aspoň jedno číslo a má minimálne 5 znakov.'),
                        regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d[\]{};:=<>/_+^#$@!%*?&]{5,}$/
                    },
                    {
                        type: 'function',
                        message: i18n._('Musíte zadať rovnaké heslá!'),
                        validFunction: async (value: string) => {
                            return new Promise((resolve, reject) => {
                                let newPassword = getValue('new_password', formPassword)
                                let newPasswordCheck = getValue('new_password_check', formPassword)

                                if(
                                    typeof newPassword === 'undefined' ||
                                    typeof newPasswordCheck === 'undefined'
                                ) {
                                    resolve(true)
                                    return
                                }

                                if(typeof newPasswordCheck.value === 'string' && newPasswordCheck.value.length === 0) {
                                    resolve(true)
                                    return
                                }

                                if(newPasswordCheck.value !== newPassword.value) {
                                    resolve(false)
                                } else {
                                    resolve(true)
                                }
                            })
                        }
                    }
                ]
            },
            {
                valid: true,
                name: 'new_password_check',
                value: '',
                invalidMessages: [],
                rules: [
                    {
                        type: 'required',
                        message: i18n._('Prosím zopakujte Vaše zadané heslo!')
                    },
                    {
                        type: 'regex',
                        message: i18n._('Je potrebné zadať heslo, ktoré obsahuje aspoň jedno veľké písmeno, jedno malé písmeno, aspoň jedno číslo a má minimálne 5 znakov.'),
                        regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d[\]{};:=<>/_+^#$@!%*?&]{5,}$/
                    },
                    {
                        type: 'function',
                        message: i18n._('Musíte zadať rovnaké heslá!'),
                        validFunction: async (value: string) => {
                            return new Promise((resolve, reject) => {
                                let newPassword = getValue('new_password', formPassword)
                                let newPasswordCheck = getValue('new_password_check', formPassword)

                                if(
                                    typeof newPassword === 'undefined' ||
                                    typeof newPasswordCheck === 'undefined'
                                ) {
                                    resolve(true)
                                    return
                                }

                                if(typeof newPassword.value === 'string' && newPassword.value.length === 0) {
                                    resolve(true)
                                    return
                                }

                                if(newPasswordCheck.value !== newPassword.value) {
                                    resolve(false)
                                } else {
                                    resolve(true)
                                }
                            })
                        }
                    }
                ]
            }
        ],
        valid: true
    })

    useEffect(() => {
        ReactGA.pageview('Nastavenia')
    }, [])

    useEffect(() => {
        if(userInfo !== null) {
            if(language === null) {
                let newLng = languageOptions.find(l => l.value === userInfo.language)

                if(typeof newLng !== 'undefined') {
                    setLanguage(newLng)
                } 
            }
        }
    }, [languageOptions, userInfo, language])

    if(userInfo === null) {
        return (null)
    }

    const submit = (e: any) => {
        e.preventDefault()

        validationService(formSettings).then(response => {
            setFormSettings(response)

            if(response.valid) {
                let values = getValuesObject(['name', 'surname', 'login', 'email', 'telephone', 'school'], formSettings)
                
                if(language !== null) {
                    values['language'] = language.value
                    dispatch(updateUser(values))
                }
            }
        })
    }

    const submitPassword = (e:any) => {
        e.preventDefault()

        validationService(formPassword).then(response => {
            setFormPassword(response)

            if(response.valid) {
                let values = getValuesObject(['old_password', 'new_password', 'new_password_check'], formPassword)

                setLoadingPassword(true)

                delete values['new_password_check']

                fetch(config.api.changePassword, {
                    method: 'post',
                    credentials: 'include',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + store.getState().globalStore.token
                    },
                    body: JSON.stringify(values)
                })
                    .then(handleErrors)
                    .then(res => res.json())
                    .then(json => {
                        setLoadingPassword(false)

                        if(json.status === 'failed') {
                            store.dispatch(addFloatingNotification({
                                type:"warning",
                                text:i18n._('Zadali ste nesprávne staré heslo!'),
                                showBell: true
                            }))
                        } else {
                            store.dispatch(addFloatingNotification({
                                type:"success",
                                text:i18n._('Heslo bolo úspešne zmenené!'),
                                showBell: true
                            }))

                            let newFormPassword = setValues(formPassword, [
                                {
                                    name: 'old_password',
                                    value: ''
                                },
                                {
                                    name: 'new_password',
                                    value: ''
                                },
                                {
                                    name: 'new_password_check',
                                    value: ''
                                }
                            ])

                            setFormPassword(newFormPassword)
                        }
            
                    })
                    .catch((error: any)  => {
                        setLoadingPassword(false)
                        store.dispatch(addFloatingNotification({
                            type:"warning",
                            text:i18n._('Nepodarilo sa odoslať formulár, skúste neskôr, alebo sa na nás obráťte prostredníctvom e-mailu na info@alphie.app'),
                            showBell: true
                        }))
                    })
            }
        })
    }

    return (
        <div className="rows">
            <form onSubmit={submit}>
                <LoadingElement loadingValue={loading}/>

                <div className="row">
                    <div className="row-part">
                        <div className="page-header">
                            <div className="part">
                                <h2><Trans>Nastavenia používateľa</Trans></h2>
                                <p><Trans>Na tomto mieste si môžete upraviť vlastnosti svojho konta</Trans></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="row-part">
                        <hr/>
                        <h5><Trans>Základné informácie</Trans></h5>
                        <ul className="inline-list _full form">
                            <li>
                                <div className="input-row">
                                    <div className="part">
                                        <label htmlFor="login"><Trans>Prihlasovacie meno</Trans></label>
                                        <div className="input-holder">
                                            <AppInput
                                                type="text"
                                                id="login"
                                                name="login"
                                                formData={formSettings}
                                                setFormData={setFormSettings}
                                                placeholder={i18n._('Prihlasovacie meno')}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <ul className="inline-list _full form">
                            <li>
                                <div className="input-row">
                                    <div className="part">
                                        <label htmlFor="name"><Trans>Meno</Trans></label>
                                        <div className="input-holder">
                                            <AppInput
                                                type="text"
                                                id="name"
                                                name="name"
                                                formData={formSettings}
                                                setFormData={setFormSettings}
                                                placeholder={i18n._('Meno')}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="input-row">
                                    <div className="part">
                                        <label htmlFor="surname"><Trans>Priezvisko</Trans></label>
                                        <div className="input-holder">
                                            <AppInput
                                                type="text"
                                                id="surname"
                                                name="surname"
                                                formData={formSettings}
                                                setFormData={setFormSettings}
                                                placeholder={i18n._('Priezvisko')}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <hr/>
                        <h5><Trans>Kontaktné informácie</Trans></h5>
                        <ul className="inline-list _full form">
                            <li>
                                <div className="input-row">
                                    <div className="part">
                                        <label htmlFor="email"><Trans>E-mail</Trans></label>
                                        <div className="input-holder">
                                            <AppInput
                                                type="text"
                                                id="email"
                                                name="email"
                                                formData={formSettings}
                                                setFormData={setFormSettings}
                                                placeholder={i18n._('E-mail')}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="input-row">
                                    <div className="part">
                                        <label htmlFor="telephone"><Trans>Telefón</Trans></label>
                                        <div className="input-holder">
                                            <AppInput
                                                type="text"
                                                id="telephone"
                                                name="telephone"
                                                formData={formSettings}
                                                setFormData={setFormSettings}
                                                placeholder={i18n._('Telefón')}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="input-row">
                                    <div className="part">
                                        <label htmlFor="school"><Trans>Škola</Trans></label>
                                        <div className="input-holder">
                                            <AppInput
                                                type="text"
                                                id="school"
                                                name="school"
                                                formData={formSettings}
                                                setFormData={setFormSettings}
                                                placeholder={i18n._('Názov školy')}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <hr/>
                        <h5><Trans>Nastavenia</Trans></h5>
                        <ul className="inline-list _full form">
                            <li>
                                <div className="input-row">
                                    <div className="part">
                                        <label htmlFor="country"><Trans>Preddefinovaný jazyk aplikácie</Trans></label>

                                        <Select
                                            id='language'
                                            formatOptionLabel={formatOptionLabel}
                                            options={languageOptions}
                                            className="customSelect"
                                            classNamePrefix="customSelectWrapper"
                                            defaultMenuIsOpen={false} 
                                            isSearchable={false}
                                            onChange={(e:any) => {
                                                setLanguage(e)
                                            }}
                                            value={language}
                                        />
                                        
                                    </div>
                                </div>
                            </li>
                            <li>
                                <InformationBox type="info"><Trans>Dostupnosť anglického jazyka je plánovaná na začiatok roka 2021.</Trans></InformationBox>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="row-part">
                        <button className={"bttn " + (formSettings.valid?'_primary':'_silent')} type="submit" disabled={!formSettings.valid}><Trans>Uložiť</Trans></button>
                    </div>
                </div>
            </form>

            <form onSubmit={submitPassword}>
                <LoadingElement loadingValue={loadingPassword}/>

                <div className="row">
                    <div className="row-part">
                        <div className="page-header">
                            <div className="part">
                                <br/>
                                <br/>
                                <br/>
                                <hr/>
                                <br/>
                                <h2><Trans>Zmena hesla</Trans></h2>
                                <p><Trans>Pre zmenu hesla je potrebné zadať pôvodné heslo používateľa.</Trans></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="row-part">
                        <ul className="inline-list _full form">
                            <li>
                                <div className="input-row">
                                    <div className="part">
                                        <label htmlFor="old_password"><Trans>Staré heslo</Trans></label>
                                        <div className="input-holder">
                                            <AppInput
                                                type="password"
                                                id="old_password"
                                                name="old_password"
                                                formData={formPassword}
                                                setFormData={setFormPassword}
                                                autoComplete={false}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <ul className="inline-list _full form">
                            <li>
                                <div className="input-row">
                                    <div className="part">
                                        <label htmlFor="new_password"><Trans>Nové heslo</Trans></label>
                                        <div className="input-holder">
                                            <AppInput
                                                type="password"
                                                id="new_password"
                                                name="new_password"
                                                formData={formPassword}
                                                setFormData={setFormPassword}
                                                autoComplete={false}
                                                checkOthers={['new_password_check']}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="input-row">
                                    <div className="part">
                                        <label htmlFor="new_password_check"><Trans>Zopakovať nové heslo</Trans></label>
                                        <div className="input-holder">
                                            <AppInput
                                                type="password"
                                                id="new_password_check"
                                                name="new_password_check"
                                                formData={formPassword}
                                                setFormData={setFormPassword}
                                                autoComplete={false}
                                                checkOthers={['new_password']}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="row-part">
                        <button className={"bttn " + (formPassword.valid?'_primary':'_silent')} type="submit" disabled={!formPassword.valid}><Trans>Zmeniť heslo</Trans></button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default SettingsPage