    
//GLOBAL INTERFACE
export const SET_LOADING                                = "SET_LOADING"
export const SET_LANGUAGE                               = "SET_LANGUAGE"
export const SET_SHOW_ADD_MENU                          = "SET_SHOW_ADD_MENU"
export const SET_SHOW_NOTIFICATION_MENU                 = "SET_SHOW_NOTIFICATION_MENU"
export const SET_SHOW_USERMENU                          = "SET_SHOW_USERMENU"
export const SET_LOGIN_USER_DATA                        = "SET_LOGIN_USER_DATA"
export const SET_LOGIN_USER_DATA_WRONG                  = "SET_LOGIN_USER_DATA_WRONG"
export const SET_LOGOUT_USER_DATA                       = "SET_LOGOUT_USER_DATA"
export const SET_LOGOUT_USER_DATA_WRONG                 = "SET_LOGOUT_USER_DATA_WRONG"
export const SET_MENU                                   = "SET_MENU"
export const SET_SHOW_LANGUAGE                          = "SET_SHOW_LANGUAGE"
export const UPDATE_TEST_RESULT                         = "UPDATE_TEST_RESULT"
export const FINISH_TEST                                = "FINISH_TEST"
export const RESET_STATE                                = "RESET_STATE"
export const UPDATE_USER_STARTED                        = "UPDATE_USER_STARTED"
export const UPDATE_USER                                = "UPDATE_USER"
export const UPDATE_USER_FAILED                         = "UPDATE_USER_FAILED"
export const SET_TUTORIAL_STARTED                       = "SET_TUTORIAL_STARTED"
export const SET_TUTORIAL                               = "SET_TUTORIAL"
export const SET_TUTORIAL_FAILED                        = "SET_TUTORIAL_FAILED"
export const UPDATE_LOGIN_TIME                          = "UPDATE_LOGIN_TIME"
export const UPDATE_KEY                                 = "UPDATE_KEY"
    
//MODALS INTERFACE
export const SET_HIDE_ALL_MODALS                        = "SET_HIDE_ALL_MODALS"
export const SET_SHOW_CONFIRM_MODAL                     = "SET_SHOW_CONFIRM_MODAL"
export const SET_SHOW_INFO_MODAL                        = "SET_SHOW_INFO_MODAL"
export const SET_SHOW_CREATE_WORD_MODAL                 = "SET_SHOW_CREATE_WORD_MODAL"
export const SET_SHOW_CREATE_WORD_MODAL_SIMPLE          = "SET_SHOW_CREATE_WORD_MODAL_SIMPLE"
export const SET_SHOW_CREATE_USERGROUP_MODAL            = "SET_SHOW_CREATE_USERGROUP_MODAL"
export const SET_SHOW_CREATE_TEST_MODAL                 = "SET_SHOW_CREATE_TEST_MODAL"
export const SET_SHOW_CREATE_TEST_WG_MODAL              = "SET_SHOW_CREATE_TEST_WG_MODALP"    
export const SET_SHOW_CREATE_TEST_UG_MODAL              = "SET_SHOW_CREATE_TEST_UG_MODAL"   
export const SET_SHOW_EDIT_WORD_MODAL                   = "SET_SHOW_EDIT_WORD_MODAL"
export const SET_SHOW_CREATE_WORDGROUP_MODAL            = "SET_SHOW_CREATE_WORDGROUP_MODAL"
export const SET_SHOW_EDIT_WORDGROUP_MODAL              = "SET_SHOW_EDIT_WORDGROUP_MODAL"
export const SET_SHOW_EDIT_USERGROUP                    = "SET_SHOW_EDIT_USERGR OUP"       
export const SET_SHOW_EDIT_TEST_USERGROUP               = "SET_SHOW_EDIT_TEST_USERGROUP"
export const SET_SHOW_DELETE_TEST_USERGROUP             = "SET_SHOW_DELETE_TEST_USERGROUP"
export const SET_SHOW_EDIT_TRAINING                     = "SET_SHOW_EDIT_TRAINING"
export const SET_SHOW_TUTORIAL                          = "SET_SHOW_TUTORIAL"
    
//LOADING INTERFACE
export const SET_LOADING_TEST_RESULT                    = "SET_LOADING_TEST_RESULT"
export const SET_LOADING_REGISTER                       = "SET_LOADING_REGISTER"
export const SET_LOADING_LOGIN                          = "SET_LOADING_LOGIN"
export const SET_LOADING_PAGE                           = "SET_LOADING_PAGE"
export const SET_LOADING_CREATED_WORDGROUPS             = "SET_LOADING_CREATED_WORDGROUPS"
export const SET_LOADING_ASSIGNED_WORDGROUPS            = "SET_LOADING_ASSIGNED_WORDGROUPS"
export const SET_LOADING_CREATED_WORDS                  = "SET_LOADING_CREATED_WORDS"
export const SET_LOADING_LANGUAGES                      = "SET_LOADING_LANGUAGES"
export const SET_LOADING_WORDGROUP                      = "SET_LOADING_WORDGROUP"
export const SET_LOADING_CREATED_USERGROUPS             = "SET_LOADING_CREATED_USERGROUPS"
export const SET_LOADING_ASSIGNED_USERGROUPS            = "SET_LOADING_ASSIGNED_USERGROUPS"
export const SET_LOADING_CREATED_TESTS                  = "SET_LOADING_CREATED_TESTS"
export const SET_LOADING_ASSIGNED_TESTS                 = "SET_LOADING_ASSIGNED_TESTS"
export const SET_LOADING_TEST_RESULTS                   = "SET_LOADING_TEST_RESULTS"
export const SET_LOADING_RANKING_WORLD                  = "SET_LOADING_RANKING_WORLD"
export const SET_LOADING_RANKING_COUNTRY                = "SET_LOADING_RANKING_COUNTRY"
export const SET_LOADING_GENERATE_TEST                  = "SET_LOADING_GENERATE_TEST"
export const SET_LOADING_RANKING_MONTH                  = "SET_LOADING_RANKING_MONTH"
    
//LANGUAGES INTERFACE
export const GET_LANGUAGES_STARTED                      = "GET_LANGUAGES_STARTED"
export const GET_LANGUAGES                              = "GET_LANGUAGES"
export const GET_LANGUAGES_FAILED                       = "GET_LANGUAGES_FAILED"
    
//WORDS INTERFACE
export const GET_CREATED_WORDS_STARTED                  = "GET_CREATED_WORDS_STARTED"
export const GET_CREATED_WORDS                          = "GET_CREATED_WORDS"
export const GET_CREATED_WORDS_FAILED                   = "GET_CREATED_WORDS_FAILED"
export const EDIT_CREATED_WORD_STARTED                  = "EDIT_CREATED_WORD_STARTED"
export const EDIT_CREATED_WORD                          = "EDIT_CREATED_WORD"
export const EDIT_CREATED_WORD_FAILED                   = "EDIT_CREATED_WORD_FAILED"
export const DELETE_CREATED_WORD_STARTED                = "DELETE_CREATED_WORD_STARTED"
export const DELETE_CREATED_WORD                        = "DELETE_CREATED_WORD"
export const DELETE_CREATED_WORD_FAILED                 = "DELETE_CREATED_WORD_FAILED"
    
//WORDGROUPS INTERFACE
export const GET_WORDGROUP_STARTED                      = "GET_WORDGROUP_STARTED"
export const GET_WORDGROUP                              = "GET_WORDGROUP"
export const GET_WORDGROUP_FAILED                       = "GET_WORDGROUP_FAILED"
export const GET_WORDGROUPS_CREATED_STARTED             = "GET_WORDGROUPS_CREATED_STARTED"
export const GET_WORDGROUPS_CREATED                     = "GET_WORDGROUPS_CREATED"
export const GET_WORDGROUPS_CREATED_FAILED              = "GET_WORDGROUPS_CREATED_FAILED"
export const GET_WORDGROUPS_ASSIGNED_STARTED            = "GET_WORDGROUPS_ASSIGNED_STARTED"
export const GET_WORDGROUPS_ASSIGNED                    = "GET_WORDGROUPS_ASSIGNED"
export const GET_WORDGROUPS_ASSIGNED_FAILED             = "GET_WORDGROUPS_ASSIGNED_FAILED"
export const STORE_WORDGROUPS_STARTED                   = "STORE_WORDGROUPS_STARTED"
export const STORE_WORDGROUPS                           = "STORE_WORDGROUPS"
export const STORE_WORDGROUPS_FAILED                    = "STORE_WORDGROUPS_FAILED"
export const MODIFY_WORDGROUP_STARTED                   = "MODIFY_WORDGROUP_STARTED"
export const MODIFY_WORDGROUP                           = "MODIFY_WORDGROUP"
export const MODIFY_WORDGROUP_FAILED                    = "MODIFY_WORDGROUP_FAILED"
export const DELETE_WORDGROUP_STARTED                   = "DELETE_WORDGROUP_STARTED"
export const DELETE_WORDGROUP                           = "DELETE_WORDGROUP"
export const DELETE_WORDGROUP_FAILED                    = "DELETE_WORDGROUP_FAILED"
    
//USERGROUPS INTERFACE
export const GET_USERGROUP_STARTED                      = "GET_USERGROUP_STARTED"
export const GET_USERGROUP                              = "GET_USERGROUP"
export const GET_USERGROUP_FAILED                       = "GET_USERGROUP_FAILED"
export const GET_USERGROUPS_CREATED_STARTED             = "GET_USERGROUPS_CREATED_STARTED"
export const GET_USERGROUPS_CREATED                     = "GET_USERGROUPS_CREATED"
export const GET_USERGROUPS_CREATED_FAILED              = "GET_USERGROUPS_CREATED_FAILED"
export const GET_USERGROUPS_ASSIGNED_STARTED            = "GET_USERGROUPS_ASSIGNED_STARTED"
export const GET_USERGROUPS_ASSIGNED                    = "GET_USERGROUPS_ASSIGNED"
export const GET_USERGROUPS_ASSIGNED_FAILED             = "GET_USERGROUPS_ASSIGNED_FAILED"
export const GET_USERGROUP_ASSIGNED_STARTED             = "GET_USERGROUP_ASSIGNED_STARTED"
export const GET_USERGROUP_ASSIGNED                     = "GET_USERGROUP_ASSIGNED"
export const GET_USERGROUP_ASSIGNED_FAILED              = "GET_USERGROUP_ASSIGNED_FAILED"
export const STORE_USERGROUP_STARTED                    = "STORE_USERGROUP_STARTED"
export const STORE_USERGROUP                            = "STORE_USERGROUP"
export const STORE_USERGROUP_FAILED                     = "STORE_USERGROUP_FAILED"
export const MODIFY_USERGROUP_STARTED                   = "MODIFY_USERGROUP_STARTED"
export const MODIFY_USERGROUP                           = "MODIFY_USERGROUP"
export const MODIFY_USERGROUP_FAILED                    = "MODIFY_USERGROUP_FAILED"
export const DELETE_USERGROUP_STARTED                   = "DELETE_USERGROUP_STARTED"
export const DELETE_USERGROUP                           = "DELETE_USERGROUP"
export const DELETE_USERGROUP_FAILED                    = "DELETE_USERGROUP_FAILED"
export const DELETE_USER_FROM_USERGROUP_STARTED         = "DELETE_USER_FROM_USERGROUP_STARTED"
export const DELETE_USER_FROM_USERGROUP                 = "DELETE_USER_FROM_USERGROUP"
export const DELETE_USER_FROM_USERGROUP_FAILED          = "DELETE_USER_FROM_USERGROUP_FAILED"
export const GENERATE_NEW_CODE_STARTED                  = "GENERATE_NEW_CODE_STARTED"
export const GENERATE_NEW_CODE                          = "GENERATE_NEW_CODE"
export const GENERATE_NEW_CODE_FAILED                   = "GENERATE_NEW_CODE_FAILED"
export const ASSIGN_WORD_GROUP_TO_USERGROUP_STARTED     = "ASSIGN_WORD_GROUP_TO_USERGROUP_STARTED"
export const ASSIGN_WORD_GROUP_TO_USERGROUP             = "ASSIGN_WORD_GROUP_TO_USERGROUP"
export const ASSIGN_WORD_GROUP_TO_USERGROUP_FAILED      = "ASSIGN_WORD_GROUP_TO_USERGROUP_FAILED"
export const DELETE_WORDGROUP_FROM_USERGROUP_STARTED    = "DELETE_WORDGROUP_FROM_USERGROUP_STARTED"
export const DELETE_WORDGROUP_FROM_USERGROUP            = "DELETE_WORDGROUP_FROM_USERGROUP"
export const DELETE_WORDGROUP_FROM_USERGROUP_FAILED     = "DELETE_WORDGROUP_FROM_USERGROUP_FAILED"
export const MODIFY_WORDGROUP_FROM_USERGROUP_STARTED    = "MODIFY_WORDGROUP_FROM_USERGROUP_STARTED"
export const MODIFY_WORDGROUP_FROM_USERGROUP            = "MODIFY_WORDGROUP_FROM_USERGROUP"
export const MODIFY_WORDGROUP_FROM_USERGROUP_FAILED     = "MODIFY_WORDGROUP_FROM_USERGROUP_FAILED"
export const GET_ASSIGNED_USERGROUP_WORDGROUPS_STARTED  = "GET_ASSIGNED_USERGROUP_WORDGROUPS_STARTED"
export const GET_ASSIGNED_USERGROUP_WORDGROUPS          = "GET_ASSIGNED_USERGROUP_WORDGROUPS"
export const GET_ASSIGNED_USERGROUP_WORDGROUPS_FAILED   = "GET_ASSIGNED_USERGROUP_WORDGROUPS_FAILED"
    
//NOTIFICATION STORE
export const ADD_FLOATING_NOTIFICATION                  = "ADD_FLOATING_NOTIFICATION"
export const UPDATE_NOTIFICATIONS                       = "UPDATE_NOTIFICATIONS"
export const REMOVE_FLOATING_NOTIFICATION               = "REMOVE_FLOATING_NOTIFICATION"
    
//TESTS STORE
export const CREATE_TEST_STARTED                        = "CREATE_TEST_STARTED"
export const CREATE_TEST                                = "CREATE_TEST"
export const CREATE_TEST_FAILED                         = "CREATE_TEST_FAILED"
export const GET_TESTS_STARTED                          = "GET_TESTS_STARTED"
export const GET_TESTS                                  = "GET_TESTS"
export const GET_TESTS_FAILED                           = "GET_TESTS_FAILED"
export const GET_ASSIGNED_TESTS_STARTED                 = "GET_ASSIGNED_TESTS_STARTED"
export const GET_ASSIGNED_TESTS                         = "GET_ASSIGNED_TESTS"
export const GET_ASSIGNED_TESTS_FAILED                  = "GET_ASSIGNED_TESTS_FAILED"
export const DELETE_TEST_STARTED                        = "DELETE_TEST_STARTED"
export const DELETE_TEST                                = "DELETE_TEST"
export const DELETE_TEST_FAILED                         = "DELETE_TEST_FAILED"
export const SAVE_TEST_RESULT_STARTED                   = "SAVE_TEST_RESULT_STARTED"
export const SAVE_TEST_RESULT                           = "SAVE_TEST_RESULT"
export const SAVE_TEST_RESULT_FAILED                    = "SAVE_TEST_RESULT_FAILED"
export const GET_TEST_RESULTS_STARTED                   = "GET_TEST_RESULTS_STARTED"
export const GET_TEST_RESULTS                           = "GET_TEST_RESULTS"
export const GET_TEST_RESULTS_FAILED                    = "GET_TEST_RESULTS_FAILED"
export const GET_TESTS_USERGROUP_STARTED                = "GET_TESTS_USERGROUP_STARTED"
export const GET_TESTS_USERGROUP                        = "GET_TESTS_USERGROUP"
export const GET_TESTS_USERGROUP_FAILED                 = "GET_TESTS_USERGROUP_FAILED"
export const GET_TEST_RESULTS_USERGROUP_STARTED         = "GET_TEST_RESULTS_USERGROUP_STARTED"
export const GET_TEST_RESULTS_USERGROUP                 = "GET_TEST_RESULTS_USERGROUP"
export const GET_TEST_RESULTS_USERGROUP_FAILED          = "GET_TEST_RESULTS_USERGROUP_FAILED"
export const MODIFY_USERGROUP_TEST_STARTED              = "MODIFY_USERGROUP_TEST_STARTED"
export const MODIFY_USERGROUP_TEST                      = "MODIFY_USERGROUP_TEST"
export const MODIFY_USERGROUP_TEST_FAILED               = "MODIFY_USERGROUP_TEST_FAILED"
export const MODIFY_TRAINING_STARTED                    = "MODIFY_TRAINING_STARTED"
export const MODIFY_TRAINING                            = "MODIFY_TRAINING"
export const MODIFY_TRAINING_FAILED                     = "MODIFY_TRAINING_FAILED"

//Ranking interface
export const LOAD_RANKING_WORLD_STARTED                 = "LOAD_RANKING_WORLD_STARTED"
export const LOAD_RANKING_WORLD                         = "LOAD_RANKING_WORLD"
export const LOAD_RANKING_WORLD_FAILED                  = "LOAD_RANKING_WORLD_FAILED"
export const LOAD_RANKING_COUNTRY_STARTED               = "LOAD_RANKING_COUNTRY_STARTED"
export const LOAD_RANKING_COUNTRY                       = "LOAD_RANKING_COUNTRY"
export const LOAD_RANKING_COUNTRY_FAILED                = "LOAD_RANKING_COUNTRY_FAILED"
export const LOAD_RANKING_MONTH_STARTED                 = "LOAD_RANKING_MONTH_STARTED"
export const LOAD_RANKING_MONTH                         = "LOAD_RANKING_MONTH"
export const LOAD_RANKING_MONTH_FAILED                  = "LOAD_RANKING_MONTH_FAILED"