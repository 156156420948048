import { 
    GET_LANGUAGES,
    SET_LOGIN_USER_DATA,
    RESET_STATE
} from "../constants/action-types"
import { Reducer } from 'redux'
import { ILanguagesStore } from '../constants/store-interfaces';

const initialState: ILanguagesStore = {
    languages: []
}

const reducer: Reducer<ILanguagesStore> = (state = initialState, action) => {
    let was = false

    switch(action.type) {
        case SET_LOGIN_USER_DATA:
            state = initialState
            was = true
            break
        case GET_LANGUAGES: 
            state = {
                ...state,
                languages:  action.payload
            }
            was = true
            break
        case RESET_STATE:
            state = initialState
            was = true
            break
    }

    if(action.type.endsWith("_FAILED") && was) {
        state = {
            ...state,
            languages: [] 
        }
        //TODO show error
    }

    return state
}

export { reducer as languageReducer }