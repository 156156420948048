import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IUserGroupType, ITableRowType, ITableCellType, ITableHeaderCellType, IActionType, IUserBasic, IModalUser } from '../../../../constants/types';
import { IApplicationStore } from '../../../../constants/store-interfaces';
import { NavLink } from 'react-router-dom';
import { Trans } from '@lingui/macro';
import { i18n } from '../../../../App';
import TableContainer from '../../containers/TableContainer';
import InfoModal from '../../../simple/InfoModal';
import NumberText from '../../../simple/tableElements/NumberText';
import TestClass from '../../../simple/classes/testClass';
import Percentage from '../../../simple/tableElements/Percentage';
import moment from 'moment';
import DateText from '../../../simple/tableElements/DateText';
import AuthTeacherWrapper from '../../containers/Authentification/AuthTeacherWrapper';
import ModalGenerateCertificate from '../modals/userGroup/ModalGenerateCertificate';
import ModalGenerateCertificateStudent from '../modals/userGroup/ModalGenerateCertificateStudent';

interface IProps {
    userGroup: IUserGroupType,
    showModalDeleteUserFromUserGroup: (id: string) => any
}

const CreatedUsersTable: React.FC<IProps> = props => {
    const [selectedUser, setSelectedUser] = useState<IUserBasic | null>(null)
    const [showModalDetailUser, setShowModalDetailUser] = useState<boolean>(false)
    const [rows, setRows] = useState<ITableRowType[]>([])
    const [userResults, setUserResults] = useState<IModalUser[]>([])

    const loading = useSelector((state: IApplicationStore) => state.loadingStore.loadingCreatedUserGroups)
    const loadingTestResults = useSelector((state: IApplicationStore) => state.loadingStore.loadingTestResults)
    const loadingAssignedTests = useSelector((state: IApplicationStore) => state.loadingStore.loadingAssignedTests)
    const usergroupResults = useSelector((state: IApplicationStore) => state.testsStore.usergroupResults)
    const ugTests = useSelector((state: IApplicationStore) => state.testsStore.usergroupTests)

    const openModalUser = useCallback((id: number) => {
        let selected = props.userGroup.assigned_users.find(wg => wg.id === Number(id))

        if(typeof selected !== 'undefined') {
            setSelectedUser(selected)
            setShowModalDetailUser(true)
        }
    }, [props.userGroup])

    useEffect(() => {
        let testResults = usergroupResults[props.userGroup.id]
        let tests = ugTests[props.userGroup.id]

        let userGroup = props.userGroup

        let newRows: ITableRowType[]  = []
        let newUserResults: IModalUser[] = []

        if(typeof userGroup !== 'undefined') {
            for(let user of userGroup.assigned_users) {
                let cells: ITableCellType[] = []

                let totalPoints = 0
                let maxPoints = 0

                let lastActivity: string = ''

                let userResults: {date: string, points: number}[] = []

                if((typeof testResults !== 'undefined') && (typeof tests !== 'undefined')) {

                    testResults.forEach(tR => {
                        if((typeof tR.user_id !== 'undefined') && (tR.user_id === user.id)) {
                            let test = tests.find(t => t.id === tR.test_id)

                            if(typeof test !== 'undefined') {
                                let testObject = new TestClass(test, tR)

                                let points = testObject.getTestRate()
                                
                                totalPoints += points
                                maxPoints += testObject.getMaximumRate()

                                if(tR.date_finished !== null) {
                                    userResults.push({
                                        date: tR.date_finished,
                                        points: points
                                    })
                                }
                            }

                            if(lastActivity === '') {
                                lastActivity = (tR.date_finished !== null)?tR.date_finished:tR.date_started
                            } else {
                                if(moment(lastActivity).isBefore(moment((tR.date_finished !== null)?tR.date_finished:tR.date_started))) {
                                    lastActivity = (tR.date_finished !== null)?tR.date_finished:tR.date_started
                                }
                            }
                        }
                    })
                }

                let rateTitle = i18n._('bodov')

                if((totalPoints > 1) && (totalPoints <= 4)) {
                    rateTitle = i18n._('body')
                } else if (totalPoints === 1) {
                    rateTitle = i18n._('bod')
                }

                let percentage = 0
                
                if(maxPoints > 0) {
                    percentage = Math.round(Number(totalPoints)/maxPoints*100)
                }

                newUserResults.push({
                    id: user.id,
                    name: user.name,
                    surname: user.surname,
                    email: user.email,
                    results: userResults
                })

                cells = [
                    {
                        content: (
                            <div className="part-text part-user _user-alt">
                                <div className="part avatar">
                                    <div className="avatar-photo">
                                        {user.name.charAt(0)}{user.surname.charAt(0)}
                                    </div>
                                </div>
                                <div className="part name">
                                    {user.f_name}
                                </div>
                            </div>
                        ),
                        stringToOrder:  user.surname + user.name,
                        filterByString: true,
                        filterString: user.f_name
                    },
                    {
                        content: (
                            (lastActivity !== '')?(
                                <DateText date={lastActivity} formatDate={'DD.MM.YYYY'} formatTime={'HH:mm'} title={i18n._('Posledná aktivita')}/>
                            ):(null)
                        ),
                        stringToOrder: lastActivity!== ''?moment(lastActivity).unix().toString():'0',
                        filterByString: false,
                        filterString: user.login,
                    },
                    {
                        content: (
                            <Percentage percentage={percentage} title={i18n._('Úspešnosť')}/>
                        ),
                        stringToOrder: percentage.toString(),
                        filterByString: false,
                        filterString: percentage.toString()
                    },
                    {
                        content: (
                            <NumberText text={rateTitle} number={totalPoints} title={i18n._('Získané body')}/>
                        ),
                        stringToOrder:  totalPoints.toString(),
                        filterByString: false,
                        filterString: totalPoints.toString()
                    },
                    {
                        content: ( 
                            <div className="bttn _small _primary  _icon" onClick={() => openModalUser(user.id)}> 
                                <i className="la la-search-plus"/>
                                <Trans>Detail</Trans>
                            </div>
                        ),
                        stringToOrder:  user.login,
                        filterByString: true,
                        filterString: user.login
                    }
                ]

                newRows.push({
                    cells: cells,
                    catchString: user.id.toString()
                })
            }
        }
        
        setRows(newRows)
        setUserResults(newUserResults)
    }, [usergroupResults, props.userGroup, ugTests, openModalUser])

    const handleRemoveUserFromGroup = (catchString: string) => {
        props.showModalDeleteUserFromUserGroup(catchString)
    }

    const handleToggleUserModal = () => {
        setShowModalDetailUser(!showModalDetailUser)
    }

    const getHeaders = (): ITableHeaderCellType[] => {
        return  [
            {
                content: (<span><Trans>Meno žiaka</Trans></span>),
                sortBy: true,
                sortable: true
            },
            {
                content: (<span><Trans>Posledná aktivita</Trans></span>),
                sortBy: false,
                sortable: true
            },
            {
                content: (<span><Trans>Priemerna úspešnosť</Trans></span>),
                sortBy: false,
                sortableType: 'number',
                sortable: true
            },
            {
                content: (<span><Trans>Získané body</Trans></span>),
                sortBy: false,
                sortableType: 'number',
                sortable: true
            },
            {
                content: (<span><Trans>Detail</Trans></span>),
                sortBy: false,
                sortable: false
            }
        ]
    }

    const getActions = (): IActionType[] => {
        return [
            {
                name: i18n._('Odstrániť žiaka'),
                action: handleRemoveUserFromGroup
            }
        ]
    }

    let headers: ITableHeaderCellType[] = getHeaders()
    let actions: IActionType[] = getActions()

    let lastActivityUser: string = ''
    let userTotalPoints: number = 0

    if(selectedUser !== null) {
        let testResults = usergroupResults[props.userGroup.id]
        let tests = ugTests[props.userGroup.id]

        if((typeof testResults !== 'undefined') && (typeof tests !== 'undefined')) {

            testResults.forEach(tR => {
                if((typeof tR.user_id !== 'undefined') && (tR.user_id === selectedUser.id)) {
                    let test = tests.find(t => t.id === tR.test_id)

                    if(typeof test !== 'undefined') {
                        let testObject = new TestClass(test, tR)

                        let points = testObject.getTestRate()
                        
                        userTotalPoints += points
                    }

                    if(lastActivityUser === '') {
                        lastActivityUser = (tR.date_finished !== null)?tR.date_finished:tR.date_started
                    } else {
                        if(moment(lastActivityUser).isBefore(moment((tR.date_finished !== null)?tR.date_finished:tR.date_started))) {
                            lastActivityUser = (tR.date_finished !== null)?tR.date_finished:tR.date_started
                        }
                    }
                }
            })
        }
    }
    
    return (
        <div>
            <AuthTeacherWrapper>
                <ModalGenerateCertificate
                    title={i18n._('Vygenerovať diplomy')}
                    titleModal={i18n._('Generovanie diplomov')}
                    titleCertificate={i18n._('Diplom')}
                    icon={'la-trophy'}
                    defaultText={i18n._('za usilovnú prácu v aplikácii Alphie')}
                    placeholderText={i18n._('Za čo má byť udelený diplom')}
                    groupName={props.userGroup.name}
                    users={userResults}
                />
            </AuthTeacherWrapper>
            <TableContainer
                headers={headers}
                rows={rows}
                actions={actions}
                showActions={true}
                showCheckbox={false}
                showFilter={true}
                pageSize={30}
                emptyText={i18n._('Zatiaľ sa do Vašej triedy nepridal žiaden žiak.')}
                showNew={false}
                showGrid={false}
                loading={loading || loadingTestResults || loadingAssignedTests}
                title={i18n._('Žiaci')}
                mobileFixedGrid={true}
            />

            {(selectedUser !== null)?(
                <div>
                    <InfoModal
                        title={i18n._('Žiak') + ' - ' + selectedUser.f_name}
                        custom={true}
                        customShow={showModalDetailUser}
                        customShowCloseFunction={handleToggleUserModal}
                    >
                        <div className="rows summary">
                            <div className="row">
                                <div className="row-part">
                                    <div className="summary-block">
                                        <h4>
                                            <i className="la la-user"/>
                                            <Trans>Meno</Trans>
                                        </h4>
                                        <div className="summary-content">
                                            {selectedUser.name} {selectedUser.surname}
                                        </div>
                                    </div>
                                </div>
                                <div className="row-part">
                                    <div className="summary-block">
                                        <h4>
                                            <Trans>Prihlasovacie meno</Trans>
                                        </h4>
                                        <div className="summary-content">
                                            {selectedUser.login}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="row-part">
                                    <div className="summary-block">
                                        <h4>
                                            <i className="la la-at"/>
                                            <Trans>E-mail</Trans>
                                        </h4>
                                        <div className="summary-content">
                                            <a href={"mailto:" + selectedUser.email + '?subject=' + i18n._('Správa z portálu ALPHIE')}>{selectedUser.email}</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="row-part">
                                    <div className="summary-block">
                                        <h4>
                                            <i className="la la-phone"/>
                                            <Trans>Telefón</Trans>
                                        </h4>
                                        <div className="summary-content">
                                            <a href={"tel:" + selectedUser.telephone}>{selectedUser.telephone}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="row-part">
                                    <div className="summary-block">
                                        <h4>
                                            <i className="la la-search-plus"/>
                                            <Trans>Detail žiaka</Trans>
                                        </h4>
                                        <div className="summary-content">
                                            <NavLink to={"/app/classes/" + props.userGroup.id + '/students/' + selectedUser.id} className="bttn _small _primary _icon">
                                                <i className="la la-search-plus"/>
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                                <div className="row-part">
                                    <div className="summary-block">
                                        <h4>
                                            <i className="la la-phone"/>
                                            <Trans>Posledná aktivita</Trans>
                                        </h4>
                                        <div className="summary-content">
                                        <DateText date={lastActivityUser} formatDate={'DD.MM.YYYY'} formatTime={'HH:mm'} title={i18n._('Posledná aktivita')}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="row-part">
                                    <div className="summary-block">
                                        <h4>
                                            <i className="la la-trophy"/>
                                            <Trans>Generovanie diplomu</Trans>
                                        </h4>
                                        <div className="summary-content">
                                            <ModalGenerateCertificateStudent
                                                groupName={props.userGroup.name}
                                                points={userTotalPoints}
                                                username={selectedUser.f_name}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </InfoModal>
                </div>
            ):(null)}
        </div>
    )
}

export default CreatedUsersTable