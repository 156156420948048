import { setLoadingCreatedUserGroups } from '../loadings/setLoading';
import config from '../../config';
import { handleErrors } from '../../utils/handleErrors';
import { MODIFY_USERGROUP, MODIFY_USERGROUP_STARTED, MODIFY_USERGROUP_FAILED } from '../../constants/action-types';
import { store } from '../../store';
import { ICreateUserGroup } from '../../constants/types';
import { setShowEditUserGroupModal } from '../modals/setShowModal';

export function editUserGroup(id: number, data:ICreateUserGroup) {
    return (dispatch: any) => {

        dispatch(setLoadingCreatedUserGroups(true))
        dispatch(editUserGroupStarted())

        return fetch(config.api.usergroups + '/' + id, {
            method: 'PUT',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + store.getState().globalStore.token
            },
            body: JSON.stringify(data)
            
        })
            .then(handleErrors)
            .then(res => res.json())
            .then(res => {

                dispatch(setShowEditUserGroupModal(false))
                dispatch(editUserGroupSuccess(res.data))
                dispatch(setLoadingCreatedUserGroups(false))
                
            })
            .catch((error: any)  => {
                dispatch(editUserGroupFailed(error))
                dispatch(setLoadingCreatedUserGroups(false))
            });
    };
}

export const editUserGroupStarted = () => ({
    type: MODIFY_USERGROUP_STARTED
});

export const editUserGroupSuccess = (data:  any) => ({
    type: MODIFY_USERGROUP,
    payload: data
});

export const editUserGroupFailed = (error: any) => ({
    type: MODIFY_USERGROUP_FAILED,
    payload: { error }
});