import React from 'react'
import { useSelector } from 'react-redux';
import { Switch, RouteComponentProps, Redirect } from 'react-router';
import { withRouter } from 'react-router-dom';
import { IApplicationStore } from '../../constants/store-interfaces';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

//Pages component
import AppPageContainer from './containers/AppPageContainer';
import TestDetailPage from './pages/TestDetailPage';
import AppPage from './pages/AppPage';
// import ClassListPage from './pages/html/ClassListPage';
// import MyWords from './pages/html/MyWords';
import { PrivateRoute } from '../stateless/PrivateRoute';
import config from '../../config';
import WordsPage from './pages/WordsPage';
// import TablesPage from './pages/html/TablesPage';
// import WidgetsPage from './pages/html/WidgetsPage';
import WordGroupsPage from './pages/WordGroupsPage';
import WordGroupDetailPage from './pages/WordGroupDetailPage';
import UserGroupPage from './pages/UserGroupPage';
import UserGroupDetailPage from './pages/UserGroupDetailPage';
// import Blocks from './pages/html/Blocks';
import UserDetailPage from './pages/UserDetailPage';
import WordGroupAssignedDetailPage from './pages/WordGroupAssignedDetailPage';
import TrainingsPage from './pages/TrainingsPage';
import { ETestUgType } from '../../constants/types';
import TestResultPage from './pages/TestResultPage';
import TrainingDetailInfoPage from './pages/TrainingDetailInfoPage';
import HomeWorksPage from './pages/HomeWorksPage';
import TestsPage from './pages/TestsPage';
import HomeworkDetailInfoPage from './pages/HomeworkDetailInfoPage';
import TestDetailUserGroupPage from './pages/TestDetailUserGroupPage';
import TestResultUserGroupPage from './pages/TestResultUserGroupPage';
import TestDetailInfoPage from './pages/TestDetailInfoPage';
import RankingPage from './pages/RankingPage';
import ActivityPage from './pages/ActivityPage';
import ReleasesPage from './pages/ReleasesPage';
import SettingsPage from './pages/SettingsPage';
import NoMatchPage from './pages/NoMatchPage';
import LoaderMainApp from './parts/small/LoaderMainApp';

interface IProps extends RouteComponentProps<any> {

}

const MainApp: React.FC<IProps> = props => {
    const currentTest = useSelector((state: IApplicationStore) => state.globalStore.currentTest)
    const key = useSelector((state: IApplicationStore) => state.globalStore.appKey)

    if(
        currentTest !== null &&
        (
            typeof currentTest.testResult === 'undefined' ||
            currentTest.testResult.date_finished === null ||
            typeof currentTest.testResult.date_finished === 'undefined'
        ) 
    ) {
        let test = currentTest.test

        let startString = '/app/trainings/' + test.id

        if(test.usergroup_id !== null) {
            if(test.settings.ugType === ETestUgType.homework) {
                startString = '/app/classes/' + test.usergroup_id + '/homeworks/' + test.id
            } else if(test.settings.ugType === ETestUgType.test) {
                startString = '/app/classes/' + test.usergroup_id + '/tests/' + test.id                   
            }
        }
        
        if(!props.location.pathname.startsWith(startString)) {
            return (<Redirect to={startString}/>)
        }
    }

    return (
        <AppPageContainer key={key}>
            <TransitionGroup>
                <CSSTransition
                    key={props.location.key}
                    timeout={{ enter: 300, exit: 300 }}
                    classNames={'fade'}
                >
                    <div className="wrapper" id="appPageContent">
                        <LoaderMainApp/>
                        <Switch location={props.location}>
                            <PrivateRoute 
                                exact 
                                path='/app' 
                                component={AppPage}
                                requiredRole={config.roles.user}
                            /> 
                            <PrivateRoute
                                exact
                                path="/app/rankings"
                                component={RankingPage}
                                requiredRole={config.roles.user}
                            />
                            <PrivateRoute 
                                exact 
                                path='/app/activity' 
                                component={ActivityPage}
                                requiredRole={config.roles.user}
                            />   
                            <PrivateRoute 
                                exact 
                                path='/app/releases' 
                                component={ReleasesPage}
                                requiredRole={config.roles.user}
                            />   
                            <PrivateRoute 
                                exact 
                                path='/app/settings' 
                                component={SettingsPage}
                                requiredRole={config.roles.user}
                            />   
                            <PrivateRoute 
                                exact 
                                path='/app/words' 
                                component={WordsPage}
                                requiredRole={config.roles.user}
                            />   
                            <PrivateRoute 
                                exact 
                                path='/app/wordgroups' 
                                component={WordGroupsPage}
                                requiredRole={config.roles.user}
                            />      
                            <PrivateRoute 
                                exact 
                                path='/app/wordgroups/assigned/:id' 
                                component={WordGroupAssignedDetailPage}
                                requiredRole={config.roles.user}
                            />
                            <PrivateRoute 
                                exact 
                                path={[
                                    '/app/wordgroups/:id',
                                    '/app/wordgroups/:id/:usegroupId',
                                ]}
                                component={WordGroupDetailPage}
                                requiredRole={config.roles.user}
                            />
                            <PrivateRoute 
                                exact 
                                path='/app/classes' 
                                component={UserGroupPage}
                                requiredRole={config.roles.user}
                            />   
                            <PrivateRoute 
                                exact 
                                path='/app/classes/:id' 
                                component={UserGroupDetailPage}
                                requiredRole={config.roles.user}
                            />
                            <PrivateRoute 
                                exact 
                                path='/app/classes/:id/students/:user' 
                                component={UserDetailPage}
                                requiredRole={config.roles.user}
                            />   
                            <PrivateRoute
                                exact
                                path="/app/trainings"
                                component={TrainingsPage}
                                requiredRole={config.roles.user}
                            />
                            <PrivateRoute
                                exact
                                path="/app/tests"
                                component={TestsPage}
                                requiredRole={config.roles.user}
                            />
                            <PrivateRoute
                                exact
                                path="/app/homeworks"
                                component={HomeWorksPage}
                                requiredRole={config.roles.user}
                            />
                            <PrivateRoute
                                exact
                                path={"/app/trainings/:id"}
                                component={TestDetailPage}
                                requiredRole={config.roles.user}
                            />
                            <PrivateRoute
                                exact
                                path={["/app/classes/:id/homeworks/:testId","/app/classes/:id/tests/:testId"]}
                                component={TestDetailUserGroupPage}
                                requiredRole={config.roles.user}
                            />
                            <PrivateRoute
                                exact
                                path={"/app/trainings/:id/result"}
                                component={TestResultPage}
                                requiredRole={config.roles.user}
                            />
                            <PrivateRoute
                                exact
                                path={["/app/classes/:id/homeworks/:testId/result","/app/classes/:id/tests/:testId/result"]}
                                component={TestResultUserGroupPage}
                                requiredRole={config.roles.user}
                            />
                            <PrivateRoute
                                exact
                                path="/app/trainings/:id/detail"
                                component={TrainingDetailInfoPage}
                                requiredRole={config.roles.user}
                            />
                            <PrivateRoute
                                exact
                                path="/app/classes/:id/homeworks/:hwId/detail"
                                component={HomeworkDetailInfoPage}
                                requiredRole={config.roles.user}
                            />
                            <PrivateRoute
                                exact
                                path="/app/classes/:id/tests/:testId/detail"
                                component={TestDetailInfoPage}
                                requiredRole={config.roles.user}
                            />

                            {/* <Route exact path='/app/class-list' component={ClassListPage}/>
                            <Route exact path='/app/MyWords' component={MyWords}/>
                            <Route exact path='/app/tables' component={TablesPage}/>
                            <Route exact path='/app/widgets' component={WidgetsPage}/>
                            <Route exact path='/app/blocks' component={Blocks}/> */}
                            <PrivateRoute component={NoMatchPage} requiredRole={config.roles.user}/>
                        </Switch>
                    </div>
                </CSSTransition>
            </TransitionGroup>
        </AppPageContainer>
    )
}

export default withRouter(MainApp)
