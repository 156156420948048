import { setLoadingTestResult } from '../loadings/setLoading';
import config from '../../config';
import { handleErrors } from '../../utils/handleErrors';
import { 
    GET_TEST_RESULTS, 
    GET_TEST_RESULTS_STARTED, 
    GET_TEST_RESULTS_FAILED } from '../../constants/action-types';
import { store } from '../../store';

export function loadTestResults() {
    return (dispatch: any) => {

        dispatch(setLoadingTestResult(true))
        dispatch(loadTestResultsStarted())

        return fetch(config.api.testResults, {
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + store.getState().globalStore.token
            }
            
        })
            .then(handleErrors)
            .then(res => res.json())
            .then(res => {
                if(typeof res.data !== 'undefined') {
                    dispatch(loadTestResultsSuccess(res.data))
                }

                dispatch(setLoadingTestResult(false))
            })
            .catch((error: any)  => {
                dispatch(loadTestResultsFailed(error))
                dispatch(setLoadingTestResult(false))
            });
    };
}

export const loadTestResultsStarted = () => ({
    type: GET_TEST_RESULTS_STARTED
});

export const loadTestResultsSuccess = (data:any) => ({
    type: GET_TEST_RESULTS,
    payload: data
});

export const loadTestResultsFailed = (error: any) => ({
    type: GET_TEST_RESULTS_FAILED,
    payload: { error }
});