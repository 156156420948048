import React, {Component} from 'react';
import { ITestResult, ITest, IQuestionVariantFolding, IQuestionVariantSelect, IQuestionVariantFilling, ETestType } from '../../../../../constants/types';
import TestClass from '../../../../simple/classes/testClass';
import FillingResult from './FillingResult';
import SelectingResult from './SelectingResult';
import FoldingResult from './FoldingResult';
import { Trans } from '@lingui/macro';
import InformationBox from '../../../parts/InformationBox';

interface IProps {
    test: ITest,
    testResult?: ITestResult
}

interface IState {
}

class ResultList extends Component<IProps, IState> {
    render() {
        const { test, testResult } = this.props
        
        let resultObjects: any[] = []

        if(typeof testResult === 'undefined') {
            return (
                <div className="question-results">
                    <InformationBox type="error" showBell={true}>
                        <Trans>Žiaľ nemáte žiadne výsledky!</Trans>
                    </InformationBox>
                </div>
            )
        }

        let runningTestObject = new TestClass(test, testResult)
        let result = runningTestObject.getResult()

        if((result.result !== null)) {
            result.result.questions.forEach((v: IQuestionVariantFilling | IQuestionVariantFolding | IQuestionVariantSelect,i: number) => {
                let answer = runningTestObject.validAnswer(i)

                if(v.type === ETestType.filling) {

                    resultObjects.push(
                        <FillingResult question={v} languageFrom={test.language_from} languageTo={test.language_to} valid={answer}/>
                    )
                }
                
                if(v.type === ETestType.select) {

                    resultObjects.push(
                        <SelectingResult question={v} languageFrom={test.language_from} languageTo={test.language_to} valid={answer}/>
                    )
                }

                if(v.type === ETestType.folding) {

                    resultObjects.push(
                        <FoldingResult question={v} languageFrom={test.language_from} languageTo={test.language_to} valid={answer}/>
                    )
                }
            })
        }

        return (
            <div className="question-results">
                <h3><Trans>Výsledky</Trans></h3>
                {resultObjects.map((r,i) => (
                    <div className="question-result" key={i}>
                        <div className="order">{(i+1)}.</div>
                        {r}
                    </div>
                ))}
            </div>
        )
    }
}

export default ResultList