import { setLoadingAssignedTests } from '../loadings/setLoading';
import config from '../../config';
import { handleErrors } from '../../utils/handleErrors';
import { store } from '../../store';
import { GET_TESTS_USERGROUP, GET_TESTS_USERGROUP_STARTED, GET_TESTS_USERGROUP_FAILED } from '../../constants/action-types';
import { ITest } from '../../constants/types';

export function loadUserGroupTests(id: number) {
    return (dispatch: any) => {

        dispatch(setLoadingAssignedTests(true))
        dispatch(loadUserGroupTestsStarted())

        return fetch(config.api.ugTest + '/' + id, {
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + store.getState().globalStore.token
            }
            
        })
            .then(handleErrors)
            .then(res => res.json())
            .then(res => {
                let results = res.data.filter((r:ITest) => r.valid)

                dispatch(loadUserGroupTestsSuccess({userGroupId: id, tests: results}))

                dispatch(setLoadingAssignedTests(false))
            })
            .catch((error: any)  => {
                dispatch(loadUserGroupTestsFailed(error))
                dispatch(setLoadingAssignedTests(false))
            });
    };
}

export const loadUserGroupTestsStarted = () => ({
    type: GET_TESTS_USERGROUP_STARTED
});

export const loadUserGroupTestsSuccess = (data:any) => ({
    type: GET_TESTS_USERGROUP,
    payload: data
});

export const loadUserGroupTestsFailed = (error: any) => ({
    type: GET_TESTS_USERGROUP_FAILED,
    payload: { error }
});

export function loadUserGroupTestsAll() {
    return (dispatch: any) => {

        dispatch(setLoadingAssignedTests(true))
        dispatch(loadUserGroupTestsStarted())

        return fetch(config.api.ugTest, {
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + store.getState().globalStore.token
            }
            
        })
            .then(handleErrors)
            .then(res => res.json())
            .then(res => {
                res.data.forEach((r:any) => {
                    dispatch(loadUserGroupTestsSuccess({userGroupId: r.id, tests: r.tests}))
                })

                dispatch(setLoadingAssignedTests(false))
            })
            .catch((error: any)  => {
                dispatch(loadUserGroupTestsFailed(error))
                dispatch(setLoadingAssignedTests(false))
            });
    };
}

export const loadUserGroupTestsAllStarted = () => ({
    type: GET_TESTS_USERGROUP_STARTED
});

export const loadUserGroupTestsAllSuccess = (data:any) => ({
    type: GET_TESTS_USERGROUP,
    payload: data
});

export const loadUserGroupTestsAllFailed = (error: any) => ({
    type: GET_TESTS_USERGROUP_FAILED,
    payload: { error }
});