import React, {FormEvent, useEffect, useState} from 'react';
import { i18n } from '../../../../../App';
import { Trans } from '@lingui/macro';
import { useSelector } from 'react-redux';
import { IApplicationStore } from '../../../../../constants/store-interfaces';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import InformationBox from '../../InformationBox';

interface IProps {
    username: string,
    points: number,
    groupName: string
}

const ModalGenerateCertificateStudent: React.FC<IProps> = props => {
    const [customText, setCustomText] = useState(i18n._('za usilovnú prácu v aplikácii Alphie'))
    const [className, setClassName] = useState(props.groupName)
    const [schoolName, setSchoolName] = useState('')
    const [exportDate, setExportDate] = useState(new Date())
    const [showDetail, setShowDetail] = useState(false)
    
    const userInfo = useSelector((state: IApplicationStore) => state.globalStore.userInfo)
    const language = useSelector((state: IApplicationStore) => state.globalStore.language)

    useEffect(() => {
        setSchoolName(userInfo?.school?userInfo.school:'')
    }, [userInfo])

    const submitFormHandler = (e?: FormEvent) => {
        e?.preventDefault()
    }

    let title = i18n._('Žiadosť o diplom')
    let text = i18n._('Mám záujem o diplomy pre svojich žiakov. Prosím o viac informácii.')
    let mailToString = "mailto:info@alphie.app?subject=" + encodeURI(title) + "&body=" + encodeURI(text)
    
    let certificateId:string = btoa(encodeURI(JSON.stringify({
        u: props.username,
        p: props.points,
        t: customText,
        uc: userInfo?userInfo.name + ' ' + userInfo.surname:'',
        c: className,
        s: schoolName,
        d: moment(exportDate)
    })))

    return (
        <>
            <div className="check toggle">
                <input type="checkbox" id="generateDiploma" checked={showDetail} onChange={() => setShowDetail(!showDetail)}/>
                <label htmlFor="generateDiploma"><Trans>Vygenerovať diplom</Trans></label>
            </div>
            <br/>
            {showDetail?(
                <form className="form _alt" onSubmit={submitFormHandler}>
                    <div className="form _alt">
                        <div className="input-row">
                            <div className="part">
                                <label htmlFor="wordgroup-name"><Trans>Názov školy</Trans> <i>*</i></label>
                                <div className="input-holder">
                                    <input 
                                        name={"schoolName"}
                                        type="text"
                                        placeholder={i18n._('Názov školy')}
                                        onChange={(e) => setSchoolName(e.currentTarget.value)}
                                        value={schoolName}
                                        autoFocus={true}
                                    />
                                </div>
                            </div>
                            <div className="part">
                                <label htmlFor="wordgroup-name"><Trans>Názov triedy</Trans> <i>*</i></label>
                                <div className="input-holder">
                                    <input 
                                        name={"className"}
                                        type="text"
                                        placeholder={i18n._('Názov triedy')}
                                        onChange={(e) => setClassName(e.currentTarget.value)}
                                        value={className}
                                        autoFocus={true}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="input-row">
                            <div className="part">

                                <label htmlFor="wordgroup-name"><Trans>Vlastný text</Trans> <i>*</i></label>
                                <div className="input-holder">
                                    <input 
                                        name={"customText"}
                                        type="text"
                                        placeholder={i18n._('za usilovnú prácu v aplikácii Alphie')}
                                        onChange={(e) => setCustomText(e.currentTarget.value)}
                                        value={customText}
                                        autoFocus={true}
                                    />
                                </div>
                            </div>
                            <div className="part">
                                <label htmlFor="date-start"><Trans>Dátum vytvorenia diplomu</Trans></label>
                                <div className="input-holder">
                                    <DatePicker
                                        selected={exportDate}
                                        onChange={(date:any) => setExportDate(date)}
                                        dateFormat="dd. MM. yyyy"
                                        locale={language}
                                        popperClassName="with-time"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="input-row">
                            <InformationBox type='warning' showBell={true}>
                                <Trans>Ak máte záujem o tlačené diplomy, neváhajte nás kontaktovať na e-mail info@alphie.app. Obratom Vám poskytneme bližšie informácie.</Trans>
                                <br/>
                                <br/>
                                <a href={mailToString} className="bttn _primary _small"><Trans>Viac informácií</Trans></a>
                            </InformationBox>
                        </div>
                        <div className="input-row">
                            <div className="part">
                                <div className="part-list">
                                    <a rel="noreferrer" href={process.env.REACT_APP_PDF_SERVER + '/' + certificateId} target="_blank" className="bttn _small _green _icon" data-tooltip={i18n._('Stiahnúť diplom')}>
                                        <Trans>Stiahnúť diplom</Trans>
                                        <i className="la la-download"/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            ):(null)}
        </>
    )
}

export default ModalGenerateCertificateStudent