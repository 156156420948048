import { ITest, ITestResult, ETestType, IQuestionVariantFilling, ETestDifficulty, IQuestionVariantFolding, IQuestionVariantSelect, ETestUgType, ICurrentTest } from '../../../constants/types';
var accents = require('remove-accents');

let replaceQuotes = (val: string) => {
    let newVal = val.replace("'",'')           
    newVal = newVal.replace('"','')
    newVal = newVal.replace('`','')
    newVal = newVal.replace('.','')
    newVal = newVal.replace('ˆ','')
    newVal = newVal.replace('´','')
    newVal = newVal.replace('‘','')
    newVal = newVal.replace('’','')
    newVal = newVal.replace('“','')
    newVal = newVal.replace('˜','')

    return newVal
}
class TestClass {
    private test: ITest
    private testResult: ITestResult

    constructor(test: ITest, testResult: ITestResult) {
        this.test = test
        this.testResult = testResult
    }

    public getCurrentTest(): ICurrentTest {
        return {
            test: this.test,
            testResult: this.testResult
        }
    }

    public getResult(): ITestResult {
        return this.testResult
    }

    public getTest(): ITest {
        return this.test
    }

    public setResult(result: ITestResult) {
        this.testResult = result
    }

    public setTest(test: ITest) {
        this.test = test
    }

    public getTestRate():number {
        let difficulty = this.test.settings.difficulty

        let resultPoints = 0

        if(this.testResult.result !== null) {
            this.testResult.result.questions.forEach((q:IQuestionVariantFilling | IQuestionVariantFolding | IQuestionVariantSelect) => {
                let resultOne = 0
                switch(difficulty) {
                    case ETestDifficulty.medium: 
                        resultOne += ((typeof q.valid !== 'undefined') && q.valid)?2:0
                        break
                    case ETestDifficulty.hard:
                        resultOne += ((typeof q.valid !== 'undefined') && q.valid)?3:0
                        break
                    default:
                        resultOne += ((typeof q.valid !== 'undefined') && q.valid)?1:0
                        break
                }

                //Upraviť podľa počtu znakov v slove, ak je to vlastné cvičenie/domáca úloha
                if(this.test.settings.ugType === ETestUgType.homework || typeof this.test.settings.ugType === 'undefined') {
                    let responseCorrect = (q.wType === 'from')?q.word.to:q.word.from

                    let percentageHard = responseCorrect.length / 10

                    resultOne *= percentageHard
                }

                if(typeof q.valid !== 'undefined' && q.valid) {
                    if(q.word.from === q.word.to) {
                        resultOne = 1
                    }

                    resultPoints += Math.max(Math.round(resultOne),1)
                }
            });
        }

        return resultPoints
    }

    public getMaximumRate():number {
        let difficulty = this.test.settings.difficulty

        let resultPoints = 0

        if(this.testResult.result !== null) {
            this.testResult.result.questions.forEach((q:IQuestionVariantFilling | IQuestionVariantFolding | IQuestionVariantSelect) => {
                let resultOne = 0
                switch(difficulty) {
                    case ETestDifficulty.medium: 
                        resultOne += 2
                        break
                    case ETestDifficulty.hard:
                        resultOne += 3
                        break
                    default:
                        resultOne += 1
                        break
                }

                //Upraviť podľa počtu znakov v slove, ak je to vlastné cvičenie/domáca úloha
                if(this.test.settings.ugType === ETestUgType.homework || typeof this.test.settings.ugType === 'undefined') {
                    let responseCorrect = (q.wType === 'from')?q.word.to:q.word.from

                    let percentageHard = responseCorrect.length / 10

                    resultOne *= percentageHard
                }

                if(q.word.from === q.word.to) {
                    resultOne = 1
                }
                
                resultPoints += Math.max(Math.round(resultOne),1)
            });
        }

        return resultPoints
    }

    //Update test result by valid answer
    public saveAnswer(id: number) {
        if(this.testResult.result !== null) {
            if(typeof this.testResult.result.questions[id] !== 'undefined') {
                this.testResult.result.questions[id].valid = this.validAnswer(id)
            }
        }
    }

    //Function to valid current answer
    public validAnswer(id: number): boolean {

        if((this.testResult !== null) && (this.testResult.result !== null)) {
            let question = this.testResult.result.questions[id]

            if(question.type === ETestType.filling) {
                let diacritic = this.test.settings.diacritic || false

                let questionSuccess = (question.wType === 'from')?question.word.to:question.word.from
                let answer = question.response

                questionSuccess = replaceQuotes(questionSuccess)
                answer = replaceQuotes(answer)

                if(diacritic) {
                    questionSuccess = questionSuccess.replace(/ /g,'')
                    answer = answer.replace(/ /g,'')

                    if(questionSuccess === answer) {
                        return true
                    } else {
                        return false
                    }
                } else {
                    //Fix strings
                    questionSuccess = questionSuccess.toLocaleLowerCase().replace(/ /g,'')
                    answer = answer.toLocaleLowerCase().replace(/ /g,'')

                    if(accents.remove(questionSuccess) === accents.remove(answer)) {
                        return true
                    } else {
                        return false
                    }
                }
            }

            if(question.type === ETestType.select) {
                let questionSuccess = (question.wType === 'from')?question.word.to:question.word.from
                let answer = question.response

                //Fix strings
                questionSuccess = questionSuccess.toLocaleLowerCase().replace(/ /g,'')
                answer = answer.toLocaleLowerCase().replace(/ /g,'')

                if(accents.remove(questionSuccess) === accents.remove(answer)) {
                    return true
                } else {
                    return false
                }
            }

            if(question.type === ETestType.folding) {
                let questionSuccess = (question.wType === 'from')?question.word.to:question.word.from
                let answer = question.response

                //Fix strings
                questionSuccess = questionSuccess.toLocaleLowerCase().replace(/_/g, ' ').replace(/ /g,'')
                answer = answer.toLocaleLowerCase().replace(/_/g, ' ').replace(/ /g,'')

                if(accents.remove(questionSuccess) === accents.remove(answer)) {
                    return true
                } else {
                    return false
                }
            }
        }
         
        return false
    }

    //Get percentage result by resule
    public getPercentage() {
        return Math.round(Number(this.getTestRate())/this.getMaximumRate()*100)
    }
}

export default TestClass