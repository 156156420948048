import React, {useRef, useState, useEffect } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { IApplicationStore } from '../../../constants/store-interfaces';
import SidebarMenu from '../parts/sidebar/SidebarMenu';
import AuthTeacherWrapper from '../containers/Authentification/AuthTeacherWrapper';
import SidebarWidgetClasses from '../parts/sidebar/SidebarWidgetClasses';
import AuthUserWrapper from '../containers/Authentification/AuthUserWrapper';
import useOnClickOutside from '../../../hooks/useOnClickOutside';
import { setShowMenu } from '../../../actions/global/setShowMenu';
import { ICurrentTest } from '../../../constants/types';
import SimpleBar from 'simplebar-react';

const mapStateToProps = (state: IApplicationStore ) => ({
})

function LeftSideBar(): JSX.Element {
    const dispatch = useDispatch()

    const [showMenu, setShowSidebar] = useState(true)

    const currentTest = useSelector<IApplicationStore, ICurrentTest | null>(store => store.globalStore.currentTest)

    const ref = useRef<HTMLInputElement>(null);

    useOnClickOutside(ref, () => {
        dispatch(setShowMenu(false))
    })

    useEffect(() => {
        if(currentTest !== null) {
            if(typeof currentTest.testResult !== 'undefined') {
                if(currentTest.testResult.date_finished === null) {
                    setShowSidebar(false)
                } else {
                    setShowSidebar(true)
                }
            } else {
                setShowSidebar(true)
            }
        } else {
            setShowSidebar(true)
        }
    }, [currentTest])

    return (
        <header className="app-header" ref={ref} data-tour="step1" >

            <div className="app-logo">
                <NavLink to="/app">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1006 287">
                        <g fill="none" fillRule="evenodd">
                            <path d="M198 252v30c-1.333 3-3.5 4.333-6.5 4-3-.333-4.833-2.333-5.5-6l-5-1.5c-1 5-3.667 7-8 6s-6-4.167-5-9.5l-4-1c-1 5-3.167 7.333-6.5 7-3.333-.333-5.167-3-5.5-8v-8l46-13zM76.5 252v30c1.333 3 3.5 4.333 6.5 4 3-.333 4.833-2.333 5.5-6l5-1.5c1 5 3.667 7 8 6s6-4.167 5-9.5l4-1c1 5 3.167 7.333 6.5 7 3.333-.333 5.167-3 5.5-8v-8l-46-13z" fill="#000"/>
                            <circle fill="#4D7CFE" cx="136" cy="136" r="136"/>
                            <path d="M258.5 185.5c-.667 17.333.333 30.333 3 39 3.667 11.667 5.333 21.667 5 30-8.667-2.667-16-6.5-22-11.5-4.667-4-10.167-9.667-16.5-17l30.5-40.5zM19 190c-4.333 9-6.833 18.833-7.5 29.5-.333 13 1 22.833 4 29.5 3.256-1.732 6.422-4.398 9.5-8 3.078-3.602 7.744-10.102 14-19.5L19 190z" fill="#4D7CFE"/>
                            <circle fill="#89A5F4" cx="135" cy="136" r="121"/>
                            <path d="M113.263 256.374c38.646 4.745 74.558-28.736 80.212-74.782C172.492 176.197 150.667 173 128 172c-27.667-6-52.492-8.53-74.475-7.592-5.653 46.046 21.092 87.22 59.738 91.966z" fill="#FFF"/>
                            <path fill="#FFAB2B" d="M112.345 214.09l-12.027-44.459 35.709-33.002 11.217 43.689z"/>
                            <circle fill="#2954CB" cx="81" cy="109" r="65"/>
                            <circle fill="#2954CB" cx="196" cy="146" r="65"/>
                            <path d="M0 21.5L9.5 23c9.56 4.581 17.06 7.581 22.5 9 5.44 1.419 25.773 6.086 61 14L31 65.5c-8.333-2.333-15.5-8-21.5-17C5.167 41.167 2 32.167 0 21.5zM316.736 118.465l-8.643-4.22c-10.459-1.731-18.323-3.576-23.593-5.533-5.27-1.958-26.103-9.195-62.5-21.712l34 49c14.901 3.353 27.39 3.064 37.467-.867 7.756-3.521 15.512-9.077 23.269-16.668z" fill="#2954CB"/>
                            <circle fill="#FFF" cx="81" cy="110" r="47"/>
                            <circle fill="#FFF" cx="197" cy="145" r="47"/>
                            <circle fill="#4D7CFE" cx="97.5" cy="115.5" r="26.5"/>
                            <circle fill="#4D7CFE" cx="212.5" cy="147.5" r="26.5"/>
                            <circle fill="#000" cx="98.372" cy="117.205" r="13"/>
                            <circle fill="#000" cx="213.336" cy="148.751" r="13"/>
                            <path className="logo-text" d="M384.246 192l6.152-19.922h35.23L431.854 192h27.612L420.062 85.36h-24.17L356.78 192h27.466zm35.23-39.77H396.55l11.426-36.988 11.499 36.987zM557.688 192v-19.775h-44.75V85.359h-25.709V192h70.46zm56.548 0v-36.182h16.407c12.988 0 23.205-3.112 30.651-9.338 7.447-6.226 11.17-14.539 11.17-24.939 0-7.031-1.721-13.318-5.164-18.86-3.442-5.542-8.3-9.814-14.575-12.817-6.274-3.003-13.489-4.505-21.643-4.505h-42.554V192h25.708zm16.846-56.03h-16.846v-30.762h17.139c4.639.049 8.3 1.563 10.986 4.541 2.686 2.979 4.029 6.958 4.029 11.939 0 4.54-1.307 8.056-3.919 10.546s-6.409 3.736-11.389 3.736zM731.868 192v-44.531h37.866V192h25.562V85.36h-25.562v42.333h-37.866V85.36H706.16V192h25.708zm127.08 0V85.36h-25.635V192h25.635zm111.333 0v-19.775h-47.754V146.81h40.43v-19.117h-40.43v-22.485h47.9V85.359H896.82V192h73.462z" fill="#4D7CFE" fillRule="nonzero"/>
                        </g>
                    </svg>
                </NavLink>
            </div>
            

            {(showMenu)?(
                <SimpleBar className="scrollbar">
                    <SidebarMenu/>
                    
                    <div className="app-widgets">
                        <AuthUserWrapper>
                            <SidebarWidgetClasses assigned={true}/>
                        </AuthUserWrapper>
                        
                        <AuthTeacherWrapper>
                            <SidebarWidgetClasses assigned={false}/>
                        </AuthTeacherWrapper>
                    </div>
                </SimpleBar>
            ):(null)}
            <div className="outside" onClick={() => dispatch(setShowMenu(false))}></div>
        </header>
        
    )
}

export default connect(mapStateToProps)(LeftSideBar)
