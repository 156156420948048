import { setLoadingAssignedWordGroups } from '../loadings/setLoading';
import config from '../../config';
import { handleErrors } from '../../utils/handleErrors';
import { 
    GET_WORDGROUPS_ASSIGNED, 
    GET_WORDGROUPS_ASSIGNED_STARTED, 
    GET_WORDGROUPS_ASSIGNED_FAILED } from '../../constants/action-types';
import { store } from '../../store';

export function loadAssignedWordGroups() {
    return (dispatch: any) => {

        dispatch(setLoadingAssignedWordGroups(true))
        dispatch(loadAssignedWordGroupsStarted())

        return fetch(config.api.wordgroups_assigned, {
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + store.getState().globalStore.token
            }
            
        })
            .then(handleErrors)
            .then(res => res.json())
            .then(res => {
                let response = res.data

                if(typeof response === 'undefined') {
                    response = []
                }

                dispatch(loadAssignedWordGroupsSuccess(response))

                dispatch(setLoadingAssignedWordGroups(false))
            })
            .catch((error: any)  => {
                dispatch(loadAssignedWordGroupsFailed(error))
                dispatch(setLoadingAssignedWordGroups(false))
            });
    };
}

export const loadAssignedWordGroupsStarted = () => ({
    type: GET_WORDGROUPS_ASSIGNED_STARTED
});

export const loadAssignedWordGroupsSuccess = (data:any) => ({
    type: GET_WORDGROUPS_ASSIGNED,
    payload: data
});

export const loadAssignedWordGroupsFailed = (error: any) => ({
    type: GET_WORDGROUPS_ASSIGNED_FAILED,
    payload: { error }
});