import React, {Component} from 'react';
import InformationBox from '../../InformationBox';
import { Trans } from '@lingui/macro';

interface IProps {
    isTeacher: boolean
    isHistory?: boolean
}

interface IState {
}

class AssignedTestInfoPart extends Component<IProps, IState> {

    render() {
        const { isTeacher, isHistory } = this.props


        let showText = (
            <InformationBox type='info' showBell={true}>
                <Trans>Pre zlepšenie učenia slovíčok môžete Vaším žiakom priradiť písomky vytvorené zo skupín slovíčok ktoré sú danej triede priradené.</Trans>
                <br/>
                <strong>
                    <Trans>Môžete vidieť ich úspešnosť v písomkách a preveriť tak ich znalosti z daných skupín slovíčok.</Trans>
                </strong>
            </InformationBox>
        )

        if(!isTeacher) {
            showText = (
                <InformationBox type='info' showBell={true}>
                    <Trans>Zoznam písomiek, ktoré ste dostali pridelené od svojho učiteľa. Prostredníctvom písomiek ukážete svojemu učiteľovi ako ste sa naučili slovíčka.</Trans>
                    <br/>
                    <strong>
                        <Trans>Písomku môžete vykonať len raz!</Trans>   
                    </strong> 
                </InformationBox>
            )

            if( (typeof isHistory !== 'undefined') && (isHistory) ) {
                showText = (
                    <InformationBox type='info' showBell={true}>
                        <Trans>Zoznam starších písomiek, ktoré ste dostali pridelené od svojho učiteľa. Môžete si pozrieť svoje staršie výsledky, ako sa Vám darilo v písomkách.</Trans>
                        <br/>
                        <strong>
                            <Trans>Tieto písomky sú už neaktívne.</Trans>   
                        </strong> 
                    </InformationBox>
                )
            }
        }

        return (
            <div className="row">
                <div className="row-part">
                    {showText}
                </div>
            </div>
        )
    }
}

export default AssignedTestInfoPart