import { setLoadingCreatedWords } from '../loadings/setLoading';
import config from '../../config';
import { handleErrors } from '../../utils/handleErrors';
import { EDIT_CREATED_WORD_STARTED, EDIT_CREATED_WORD, EDIT_CREATED_WORD_FAILED } from '../../constants/action-types';
import { store } from '../../store';
import { i18n } from '../../App';
import { addFloatingNotification } from '../notifications/floating';

export function editWord(id: number, data:{word_text: string,translation:string}) {
    return (dispatch: any) => {

        dispatch(setLoadingCreatedWords(true))
        dispatch(editWordStarted())

        return fetch(config.api.words + '/' + id, {
            method: 'PUT',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + store.getState().globalStore.token
            },
            body: JSON.stringify(data)
            
        })
            .then(handleErrors)
            .then(res => res.json())
            .then(res => {

                dispatch(editWordSuccess({id: id, data: data}))

                dispatch(addFloatingNotification({
                    type:"success",
                    text:i18n._('Slovo sa úspešne uložilo'),
                    showBell: true
                }))

                dispatch(setLoadingCreatedWords(false))
                
            })
            .catch((error: any)  => {
                dispatch(editWordFailed(error))
                dispatch(setLoadingCreatedWords(false))
            });
    };
}

export const editWordStarted = () => ({
    type: EDIT_CREATED_WORD_STARTED
});

export const editWordSuccess = (data:  any) => ({
    type: EDIT_CREATED_WORD,
    payload: data
});

export const editWordFailed = (error: any) => ({
    type: EDIT_CREATED_WORD_FAILED,
    payload: { error }
});