import React, {Component } from 'react'
import { ITestResult } from '../../../constants/types';
import Chart from "react-apexcharts";
import moment from 'moment';
import { i18n } from '../../../App';
import { Trans } from '@lingui/macro';

interface IProps {
    results: ITestResult[]
    openModal?: (tr: ITestResult) => any
}

class ResultsGraphWidget extends Component<IProps, any> {
    
    render() {
        const { results, openModal }  = this.props

        let categories: string[] = []
        let values: number[] = []

        results.sort((a, b) => moment(((a.date_finished !== null)?a.date_finished:a.date_started)).valueOf() - moment(((b.date_finished !== null)?b.date_finished:b.date_started)).valueOf())

        results.forEach(r => {
            let date = (r.date_finished !== null)?moment(r.date_finished).format('DD.MM.YYYY HH:mm'):moment(r.date_started).format('DD.MM.YYYY HH:mm')
            let validAnswers = 0
    
            if(r.result !== null) {
                r.result.questions.forEach(q => {
                    validAnswers += (q.valid)?1:0
                })

                categories.push(date)
                values.push(Math.round(validAnswers/r.result.questions.length*100))
            }
        })

        let options = {
            xaxis: {
                categories: categories
            },
            yaxis: {
                min: 0,
                max: 100,
                tickAmount: 5,
                labels: {
                    formatter: (value:number) => value.toFixed(0) +' %',
                }
            },
            stroke: {
                curve: 'smooth'
            },
            dataLabels: {
                enabled: false
            },
            chart: {
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false
                },
                events: {
                    dataPointSelection: function (event:any, chartContext:any, config:any) {
                        if(typeof openModal !== 'undefined') {
                            let result = results[config.dataPointIndex]

                            openModal(result)
                        }
                    }
                }
            },
            fill: {
                type: 'gradient',
                gradient: {
                    shade: 'light',
                    shadeIntensity: .2,
                    type: 'vertical',
                    opacityFrom: 1,
                    opacityTo: .3,
                    colorStops: [
                        [
                            {
                                offset: 0,
                                color: '#6DD230',
                                opacity: 1
                            },
                            {
                              offset: 30,
                              color: '#4D7CFE',
                              opacity: 1
                            },
                            {
                              offset: 55,
                              color: '#FFAB2B',
                              opacity: 1
                            },
                            {
                                offset: 80,
                                color: '#F54029',
                                opacity: 1
                            }
                        ]
                    ]
                },
            },
            colors: ['#6DD230'],
            markers: {
                size: 4
            },
            tooltip: {
                shared: false,
                intersect: true
            }
        }

        return (
            <div className="small-widget">
                <div className="_part _left">
                    <h3><Trans>Moje výsledky</Trans></h3>
                    <Chart
                        options={options}
                        series={[{
                            name: i18n._('Výsledok'),
                            data: values
                        }]}
                        // type="area"
                        height="200"
                    />
                </div>
            </div>
        )
    }
}

export default ResultsGraphWidget
