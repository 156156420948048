import { setLoadingCreatedUserGroups } from '../loadings/setLoading';
import config from '../../config';
import { handleErrors } from '../../utils/handleErrors';
import { DELETE_USER_FROM_USERGROUP, DELETE_USER_FROM_USERGROUP_FAILED, DELETE_USER_FROM_USERGROUP_STARTED } from '../../constants/action-types';
import { store } from '../../store';
import { history } from '../../../index';
import { addFloatingNotification } from '../notifications/floating';
import { i18n } from '../../App';

export function deleteUserFromUserGroup(id: number, data:any, redirect: boolean|undefined) {
    return (dispatch: any) => {

        dispatch(setLoadingCreatedUserGroups(true))
        dispatch(deleteUserFromUserGroupStarted())

        return fetch(config.api.usergroups + '/' + id + '/students/delete', {
            method: 'POST',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + store.getState().globalStore.token
            },
            body: JSON.stringify(data)
            
        })
            .then(handleErrors)
            .then(res => res.json())
            .then(res => {

                dispatch(deleteUserFromUserGroupSuccess({ug: id, uId: data.students[0]}))
                dispatch(setLoadingCreatedUserGroups(false))

                store.dispatch(addFloatingNotification({
                    type:"success",
                    text:i18n._('Žiaka ste odstránili z triedy'),
                    showBell: true
                }))

                if(redirect) {
                    history.push({
                        pathname: '/app/classes/' + id
                    })
                }
                
            })
            .catch((error: any)  => {
                dispatch(deleteUserFromUserGroupFailed(error))
                dispatch(setLoadingCreatedUserGroups(false))
            });
    };
}

export const deleteUserFromUserGroupStarted = () => ({
    type: DELETE_USER_FROM_USERGROUP_STARTED
});

export const deleteUserFromUserGroupSuccess = (data:  any) => ({
    type: DELETE_USER_FROM_USERGROUP,
    payload: data
});

export const deleteUserFromUserGroupFailed = (error: any) => ({
    type: DELETE_USER_FROM_USERGROUP_FAILED,
    payload: { error }
});