import React, {Component} from 'react';
import { connect } from 'react-redux';
import { IApplicationStore } from '../../../../../constants/store-interfaces';
import { i18n } from '../../../../../App';
import ModalContainer from '../../../containers/ModalContainer';
import ModalHeader from '../../../containers/ModalElements/ModalHeader';
import ModalBody from '../../../containers/ModalElements/ModalBody';
import { setShowCreateUserGroupModal } from '../../../../../actions/modals/setShowModal';
import InformationBox from '../../InformationBox';
import { Trans } from '@lingui/macro';
import ModalFooter from '../../../containers/ModalElements/ModalFooter';
import { ICreateUserGroup } from '../../../../../constants/types';
import { createUserGroup } from '../../../../../actions/userGroups/createUserGroup';

interface IDispatchProps {
    createUserGroup: typeof createUserGroup
    setShowAddUserGroupModal: typeof setShowCreateUserGroupModal
}

interface IStateProps {
    loading: boolean,
    showModal: boolean
}

const mapStateToProps = (state: IApplicationStore): IStateProps => ({
    loading: state.loadingStore.loading,
    showModal: state.modalsStore.showAddUserGroup
})

const mapDispatchToProps = (dispatch: any): IDispatchProps => ({
    createUserGroup: (data: any) => dispatch(createUserGroup(data)),
    setShowAddUserGroupModal: (value: boolean) => dispatch(setShowCreateUserGroupModal(value))
})

interface IState {
    userGroupName: string,
    userGroupNote: string,
    valid: boolean
}

interface IProps extends IDispatchProps, IStateProps {
    
}

const initState: IState = {
    userGroupName: '',
    userGroupNote: '',
    valid: false
}

class ModalAddUserGroup extends Component<IProps, IState> {

    constructor(props:any) {
        super(props)

        this.state = initState

        this.handleUserGroupName = this.handleUserGroupName.bind(this)
        this.handleUserGroupNote = this.handleUserGroupNote.bind(this)
        this.handleSaveUserGroup = this.handleSaveUserGroup.bind(this)
    }

    componentDidMount() {
        this.setState(initState)
    }

    componentDidUpdate(prevProps: IProps, ) {
        if(prevProps.showModal && !this.props.showModal) {
            this.setState(initState)
        }
    }

    handleUserGroupName(e:any) {
        this.setState({
            ...this.state,
            userGroupName: e.target.value,
            valid: (e.target.value.length > 0)?true:false
        })
    }

    handleUserGroupNote(e:any) {
        this.setState({
            ...this.state,
            userGroupNote: e.target.value
        })
    }

    submitFormHandler(e:  any)  {
        e.preventDefault()
        this.handleSaveUserGroup()
    }

    handleSaveUserGroup() {

        let data:ICreateUserGroup

        data = {
            name: this.state.userGroupName,
            comment: this.state.userGroupNote
        }

        this.props.createUserGroup(data)
        this.props.setShowAddUserGroupModal(false)
    }

    render() {
        let { showModal } = this.props
        let { valid, userGroupNote, userGroupName } = this.state

        return (
            <section id="mainSection">
                <ModalContainer show={showModal}>
                    <ModalHeader title={i18n._('Nová trieda')}/>
                    
                    <ModalBody>
                    
                        <form className="form _alt" onSubmit={this.submitFormHandler}>
                            <div className="form _alt">
                                <div className="input-row">
                                    <div className="part">
                                        <label htmlFor="wordgroup-name"><Trans>Názov triedy</Trans> <i>*</i></label>
                                        <div className="input-holder">
                                            <input 
                                                name={"userGroupName"}
                                                type="text"
                                                placeholder={i18n._('1.C')}
                                                onChange={this.handleUserGroupName}
                                                value={userGroupName}
                                                autoFocus={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="input-row">
                                    <div className="part">
                                        <label htmlFor="wordgroup-name"><Trans>Poznámka</Trans></label>
                                        <div className="input-holder">
                                            <input 
                                                name={"userGroupNote"}
                                                type="text"
                                                placeholder={i18n._('2. skupina')}
                                                onChange={this.handleUserGroupNote}
                                                value={userGroupNote}
                                            />
                                            {(valid)?(null):(
                                                <InformationBox type="warning" showClose={false} showBell={true}>
                                                    <Trans>Pre pokračovanie musíte zadať názov triedy!</Trans>
                                                </InformationBox>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    
                    </ModalBody>

                    <ModalFooter>
                        <div className="part">
                            <button type="button" className="ant-btn bttn _silent href" onClick={() => this.props.setShowAddUserGroupModal(false)}><Trans>Zavrieť</Trans></button>
                        </div>
                        <div className="part">
                            <button type="button" className="ant-btn bttn _primary href" onClick={this.handleSaveUserGroup} disabled={!valid}><Trans>Vytvoriť triedu</Trans></button>
                        </div>
                    </ModalFooter>
                </ModalContainer>
            </section>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalAddUserGroup)