import React, {useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IApplicationStore } from '../../../constants/store-interfaces';
import { RouteComponentProps, withRouter } from 'react-router';
import TestResult from '../containers/TestElements/TestResult';
import { loadAssignedTests } from '../../../actions/tests/loadAssignedTests';
import ReactGA from 'react-ga';
import { updateCurrentTest } from '../../../actions/global/updateCurrentTest';
import NoMatchPage from './NoMatchPage';


type PathParamsType = {
    id: string,
    testId: string
}

interface IProps extends RouteComponentProps<PathParamsType> {

}

const TestResultUserGroupPage: React.FC<IProps> = props => {
    const dispatch = useDispatch()

    const currentTest = useSelector((state: IApplicationStore) => state.globalStore.currentTest)

    useEffect(() => {
        dispatch(loadAssignedTests())
        
        ReactGA.pageview('Výsledok testu/domácej úlohy')

        return () => {
            console.log('3')
            dispatch(updateCurrentTest(null))
        }
    }, [dispatch])

    if(currentTest === null) {
        return (<NoMatchPage/>)
    }

    return (
        <section>
            <TestResult currentTest={currentTest}/>
        </section>
    )
}

export default withRouter(TestResultUserGroupPage)