import React, {Component, PropsWithChildren} from 'react'
import moment from 'moment';
import { i18n } from '../../../App';

interface IProps {
    date: string,
    finalText: string,
    title: string,
    color: 'primary'|'orange',
    showBefore?: boolean
}

interface IState {
    currentTime: Date
}

class CountDownDate extends Component<PropsWithChildren<IProps>, IState> {
    private interval: any = undefined

    constructor(props: IProps) {
        super(props)

        this.state = {
            currentTime: new Date()
        }

        this.getDiff = this.getDiff.bind(this)
    }

    componentDidMount() {
        this.interval = setInterval(() => this.setState({ currentTime: new Date() }), 1000);
    }
    
    componentWillUnmount() {
        clearInterval(this.interval);
    }

    getDiff(date: string):string|null {
        let currentDate = moment(this.state.currentTime)
        let oldDate = moment(new Date(date))

        let miliSeconds = oldDate.diff(currentDate)

        let seconds = Math.floor(miliSeconds / 1000)

        var d = Math.floor(seconds / 86400)
        var h = Math.floor(seconds % 86400 / 3600)
        var m = Math.floor(seconds % 86400 % 3600 / 60)
        var s = Math.floor(seconds % 86400 % 3600 % 60)

        var dDisplay = d > 0 ? d + i18n._('d') + ' ': ""
        var hDisplay = h > 0 ? h + i18n._('h') + ' ': ""
        var mDisplay = m > 0 ? m + i18n._('m') + ' ': ""
        var sDisplay = s >= 0 ? s + i18n._('s') + ' ': ""

        if((d < 0 ) || (h < 0) || (m < 0) || (s < 0)) {
            return null
        }

        return dDisplay + hDisplay + mDisplay + sDisplay
    }

    render() {
        const { date, color, finalText, title, showBefore }  = this.props

        let resString = this.getDiff(date)

        return (
            <div className="part-countdown" data-title={title}>
                <div className={"_" + color}>
                    {((typeof showBefore !== 'undefined') && showBefore)?(
                        <div>{title + ' '}</div>
                    ):(null)}
                    {(resString === null)?finalText:resString}
                </div>
            </div>
        )
    }
}

export default CountDownDate
