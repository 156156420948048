import { addFloatingNotification } from '../actions/notifications/floating';
import { store } from '../store/index';
import { i18n } from '../App';
import { setLogout } from '../actions/global/logoutUser';
import { history } from '../..';
// import { logoutUser } from '../actions/global/logoutUser';

export function handleErrors(response: any) {
    if(response.status === 409 || response.status === 204)  {
        return response
    }

    if (!response.ok) {
        if(response.status ===  401 || response.status === 403) {

            store.dispatch(setLogout())

            throw Error(response.statusText);

        } else if(response.status > 400 && response.status < 500) {
            console.error('Request warning', response);

            if(response.status !== 404) {
                store.dispatch(addFloatingNotification({
                    type:"warning",
                    text:i18n._('Nastala chyba v komunikácii so serverom!') + '( ' + response.statusText + ' )',
                    showBell: true
                }))
            }

            history.push('/app/404')

            throw Error(response.statusText);
        }
        
        store.dispatch(addFloatingNotification({
            type:"error",
            text:i18n._('Nastala neočakávaná chyba!'),
            showBell: true
        }))

        console.error('Request error', response)
        throw Error(response.statusText);
    }
    return response;
}