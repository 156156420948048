import { setLoadingCreatedWordGroups } from '../loadings/setLoading';
import config from '../../config';
import { handleErrors } from '../../utils/handleErrors';
import { IModifyWordGroup } from '../../constants/types';
import { MODIFY_WORDGROUP_STARTED, MODIFY_WORDGROUP, MODIFY_WORDGROUP_FAILED } from '../../constants/action-types';
import { store } from '../../store';
import { addFloatingNotification } from '../notifications/floating';
import { i18n } from '../../App';

export function modifyWordGroup( id: number, data:IModifyWordGroup ) {
    return (dispatch: any) => {

        dispatch(setLoadingCreatedWordGroups(true))
        dispatch(modifyWordGroupStarted())

        return fetch(config.api.wordgroups + '/' + id, {
            method: 'PUT',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + store.getState().globalStore.token
            },
            body: JSON.stringify(data)
            
        })
            .then(handleErrors)
            .then(res => res.json())
            .then(res => {

                dispatch(modifyWordGroupSuccess(res.data))

                dispatch(setLoadingCreatedWordGroups(false))

                dispatch(addFloatingNotification({
                    type:"success",
                    text:i18n._('Skupinu slov ste úspešne aktualizovali'),
                    showBell: true
                }))

            })
            .catch((error: any)  => {
                dispatch(modifyWordGroupFailed(error))
                dispatch(setLoadingCreatedWordGroups(false))
            });
    };
}

export const modifyWordGroupStarted = () => ({
    type: MODIFY_WORDGROUP_STARTED
});

export const modifyWordGroupSuccess = (data: any) => ({
    type: MODIFY_WORDGROUP,
    payload: data
});

export const modifyWordGroupFailed = (error: any) => ({
    type: MODIFY_WORDGROUP_FAILED,
    payload: { error }
});