import { setLoadingCreatedTests } from '../loadings/setLoading';
import config from '../../config';
import { handleErrors } from '../../utils/handleErrors';
import { DELETE_TEST, DELETE_TEST_FAILED, DELETE_TEST_STARTED } from '../../constants/action-types';
import { store } from '../../store';
import { addFloatingNotification } from '../notifications/floating';
import { i18n } from '../../App';
import { ITest, ETestUgType } from '../../constants/types';
import { setShowDeleteTestUserGroupModal, setShowConfirmModal } from '../modals/setShowModal';

export function deleteTest(test: ITest, type: 'ug' | 'self') {
    return (dispatch: any) => {

        dispatch(setLoadingCreatedTests(true))
        dispatch(deleteTestStarted())

        return fetch(config.api.tests + '/' + test.id, {
            method: 'DELETE',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + store.getState().globalStore.token
            }            
        })
            .then(handleErrors)
            .then(res => res.json())
            .then(res => {

                dispatch(deleteTestSuccess({test: test, type: type}))
                
                if(type === 'ug') {
                    dispatch(setShowDeleteTestUserGroupModal(false))

                    if((typeof test.settings.ugType !== 'undefined') && (test.settings.ugType === ETestUgType.homework)) {
                        dispatch(addFloatingNotification({
                            type:"success",
                            text:i18n._('Úspešne ste odstránili domácu úlohu') + ' - ' + test.name,
                            showBell: true
                        }))
                    } else {
                        dispatch(addFloatingNotification({
                            type:"success",
                            text:i18n._('Úspešne ste odstránili písomku') + ' - ' + test.name,
                            showBell: true
                        }))
                    }
                } else {

                    dispatch(setShowConfirmModal(false))
                    
                    dispatch(addFloatingNotification({
                        type:"warning",
                        text:i18n._('Úspešne ste odstránili cvičenie') + ' - ' + test.name,
                        showBell: true
                    }))
                }

                dispatch(setLoadingCreatedTests(false))

            })
            .catch((error: any)  => {
                dispatch(deleteTestFailed(error))
                dispatch(setLoadingCreatedTests(false))
            });
    };
}

export const deleteTestStarted = () => ({
    type: DELETE_TEST_STARTED
});

export const deleteTestSuccess = (data:any) => ({
    type: DELETE_TEST,
    payload: data
});

export const deleteTestFailed = (error: any) => ({
    type: DELETE_TEST_FAILED,
    payload: { error }
});