import React, {Component, PropsWithChildren} from 'react'
import moment from 'moment';

interface IProps {
    date: string
}

interface IState {
    currentTime: Date
}

class CountDownAction extends Component<PropsWithChildren<IProps>, IState> {
    private interval: any = undefined

    constructor(props: IProps) {
        super(props)

        this.state = {
            currentTime: new Date()
        }

        this.getDiff = this.getDiff.bind(this)
    }

    componentDidMount() {
        this.interval = setInterval(() => this.setState({ currentTime: new Date() }), 1000);
    }
    
    componentWillUnmount() {
        clearInterval(this.interval);
    }

    getDiff(date: string): number {
        let currentDate = moment(this.state.currentTime)
        let oldDate = moment(new Date(date))

        let miliSeconds = oldDate.diff(currentDate)

        let seconds = Math.floor(miliSeconds / 1000)

        return seconds
    }

    render() {
        const { date, children }  = this.props

        let diffTime = this.getDiff(date)

        return (
            <div className="part-list">
                {(diffTime > 0)?null:children}
            </div>
        )
    }
}

export default CountDownAction
