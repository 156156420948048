import React, {FormEvent, useEffect, useState} from 'react';
import { i18n } from '../../../../../App';
import ModalContainer from '../../../containers/ModalContainer';
import ModalHeader from '../../../containers/ModalElements/ModalHeader';
import ModalBody from '../../../containers/ModalElements/ModalBody';
import { Trans } from '@lingui/macro';
import ModalFooter from '../../../containers/ModalElements/ModalFooter';
import { IModalUser, ITableRowType, ITableCellType } from '../../../../../constants/types';
import TableContainer from '../../../containers/TableContainer';
import NumberText from '../../../../simple/tableElements/NumberText';
import { useSelector } from 'react-redux';
import { IApplicationStore } from '../../../../../constants/store-interfaces';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import InformationBox from '../../InformationBox';

interface IProps {
    title: string,
    titleModal: string,
    titleCertificate: string,
    icon: string
    defaultText: string,
    placeholderText: string,
    users: IModalUser[],
    groupName: string
}

const ModalGenerateCertificate: React.FC<IProps> = props => {
    const [customText, setCustomText] = useState(props.defaultText)
    const [className, setClassName] = useState(props.groupName)
    const [schoolName, setSchoolName] = useState('')
    const [exportDate, setExportDate] = useState(new Date())
    const [showModal, setShowModal] = useState(false)
    const [ rows, setRows ] = useState<ITableRowType[]>([])
    
    const userInfo = useSelector((state: IApplicationStore) => state.globalStore.userInfo)
    const language = useSelector((state: IApplicationStore) => state.globalStore.language)

    useEffect(() => {
        setCustomText(props.defaultText)
        setClassName(props.groupName)
    }, [showModal, props.defaultText, props.groupName])

    useEffect(() => {
        setSchoolName(userInfo?.school?userInfo.school:'')
    }, [userInfo])

    useEffect(() => {
        let rows: ITableRowType[]  = []

        for(let user of props.users) {
            let cells: ITableCellType[] = []

            let points = 0

            user.results.forEach(r => {
                points+= r.points
            })

            let rateTitle = i18n._('bodov')

            if((points > 1) && (points <= 4)) {
                rateTitle = i18n._('body')
            } else if (points === 1) {
                rateTitle = i18n._('bod')
            }

            let certificateId:string = btoa(encodeURI(JSON.stringify({
                u: user.name + ' ' + user.surname,
                p: points,
                t: customText,
                uc: userInfo?userInfo.name + ' ' + userInfo.surname:'',
                c: className,
                s: schoolName,
                d: moment(exportDate)
            })))

            cells = [
                {
                    content: (<div className="word text-left">{user.name} {user.surname}</div>),
                    stringToOrder:  user.surname + user.name,
                    filterByString: true,
                    filterString: user.surname + user.name
                },
                {
                    content: (
                        <NumberText text={rateTitle} number={points} title={i18n._('Získané body')}/>
                    ),
                    stringToOrder:  points.toString(),
                    filterByString: false,
                    filterString: points.toString()
                },
                {
                    content: (
                        <div className="part-list">
                            <a rel="noreferrer" href={process.env.REACT_APP_PDF_SERVER + '/' + certificateId} target="_blank" className="bttn _small _green _icon" data-tooltip={i18n._('Stiahnúť')}>
                                <i className="la la-download"/>
                            </a>
                        </div>
                    ),
                    stringToOrder:  points.toString(),
                    filterByString: false,
                    filterString: points.toString()
                },
            ]

            rows.push({
                cells: cells,
                catchString: user.id.toString()
            })
        }
        
        setRows(rows)
    }, [props.users, customText, userInfo, className, schoolName, exportDate])

    const submitFormHandler = (e?: FormEvent) => {
        e?.preventDefault()
    }

    let title = i18n._('Žiadosť o diplomy')
    let text = i18n._('Mám záujem o diplomy pre svojich žiakov. Prosím o viac informácii.')
    let mailToString = "mailto:info@alphie.app?subject=" + encodeURI(title) + "&body=" + encodeURI(text)
    
    return (
        <>
            <button className="ant-btn bttn _green _icon" onClick={() => setShowModal(true)}>
                <i className="la la-trophy"/>
                {props.title}
            </button>

            <ModalContainer show={showModal} customHide={() => setShowModal(false)}>
                <ModalHeader title={props.titleModal}/>
                
                <ModalBody>
                    <form className="form _alt" onSubmit={submitFormHandler}>
                        <div className="form _alt">
                            <div className="input-row">
                                <div className="part">
                                    <label htmlFor="wordgroup-name"><Trans>Názov školy</Trans> <i>*</i></label>
                                    <div className="input-holder">
                                        <input 
                                            name={"schoolName"}
                                            type="text"
                                            placeholder={i18n._('Názov školy')}
                                            onChange={(e) => setSchoolName(e.currentTarget.value)}
                                            value={schoolName}
                                            autoFocus={true}
                                        />
                                    </div>
                                </div>
                                <div className="part">
                                    <label htmlFor="wordgroup-name"><Trans>Názov triedy</Trans> <i>*</i></label>
                                    <div className="input-holder">
                                        <input 
                                            name={"className"}
                                            type="text"
                                            placeholder={i18n._('Názov triedy')}
                                            onChange={(e) => setClassName(e.currentTarget.value)}
                                            value={className}
                                            autoFocus={true}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="input-row">
                                <div className="part">

                                    <label htmlFor="wordgroup-name"><Trans>Vlastný text</Trans> <i>*</i></label>
                                    <div className="input-holder">
                                        <input 
                                            name={"customText"}
                                            type="text"
                                            placeholder={props.placeholderText}
                                            onChange={(e) => setCustomText(e.currentTarget.value)}
                                            value={customText}
                                            autoFocus={true}
                                        />
                                    </div>
                                </div>
                                <div className="part">
                                    <label htmlFor="date-start"><Trans>Dátum vytvorenia diplomu</Trans></label>
                                    <div className="input-holder">
                                        <DatePicker
                                            selected={exportDate}
                                            onChange={(date:any) => setExportDate(date)}
                                            dateFormat="dd. MM. yyyy"
                                            locale={language}
                                            popperClassName="with-time"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="input-row">
                                <InformationBox type='success' showBell={true}>
                                    <Trans>Ak máte záujem o tlačené diplomy, neváhajte nás kontaktovať na e-mail info@alphie.app. Obratom Vám poskytneme bližšie informácie.</Trans>
                                    <br/>
                                    <br/>
                                    <a href={mailToString} className="bttn _primary _small"><Trans>Viac informácií</Trans></a>
                                </InformationBox>
                            </div>
                            <div className="input-row">
                                <div className="part">
                                    <TableContainer
                                        headers={[
                                            {
                                                content: (<span><Trans>Meno</Trans></span>),
                                                sortBy: false,
                                                sortable: true
                                            },
                                            {
                                                content: (<span><Trans>Získané body</Trans></span>),
                                                sortBy: false,
                                                sortable: true
                                            },
                                            {
                                                content: (<span>{props.titleCertificate}</span>),
                                                sortBy: false,
                                                sortable: false
                                            }
                                        ]}
                                        rows={rows}
                                        actions={[]}
                                        showActions={false}
                                        showFilter={false}
                                        showHeader={false}
                                        showCheckbox={false}
                                        pageSize={10}
                                        emptyText={i18n._('Zatiaľ sa do Vašej triedy nepridal žiaden žiak.')}
                                        showNew={false}
                                        showGrid={false}
                                        title={i18n._('Žiaci')}
                                        showForm={false}
                                    />
                                </div>
                            </div>
                        </div>
                    </form>
                
                </ModalBody>

                <ModalFooter>
                    <div>
                        <button type="button" className="ant-btn bttn _silent href" onClick={() => setShowModal(false)}><Trans>Zavrieť</Trans></button>
                    </div>
                </ModalFooter>
            </ModalContainer>
        </>
    )
}

export default ModalGenerateCertificate