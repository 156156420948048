import React from 'react';

interface IProps {
    clickAction: () => any
}

const TutorialButton: React.FC<IProps> = props => {
    return (
        <div className="qBttn" onClick={() => props.clickAction()}>
            <i className="la la-question"/>
        </div>
    )
}

export default TutorialButton