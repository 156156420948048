import React from 'react'
import { ITestResult, ITest, ETestType, IUSerInfo } from '../../../../../constants/types';
import getTestTypeString from '../../../classes/getTestTypeString';
import { IApplicationStore } from '../../../../../constants/store-interfaces';
import { useSelector } from 'react-redux';
import { Trans } from '@lingui/macro';
import TestClass from '../../../classes/testClass';
import Percentage from '../../../tableElements/Percentage';
import { i18n } from '../../../../../App';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import TranslateLanguages from '../../../TranslateLanguages';

interface IProps {
    results: ITestResult[]
    test: ITest
}

const HomeWorkDetailUser: React.FC<IProps> = props => {
    const userInfo = useSelector<IApplicationStore, IUSerInfo | null>(state => state.globalStore.userInfo)

    const typeSelect: { label: string; value: string }[] = []

    Object.keys(ETestType).forEach(value => {
        typeSelect.push({
            label: getTestTypeString(ETestType[(value as ETestType)]),
            value: ETestType[(value as ETestType)]
        })
    })

    const typeValue = typeSelect.find(value => ((typeof props.test !== 'undefined') && (value.value === props.test.settings.type)))

    let results: ITestResult[] = []
    
    if(userInfo !== null) {
        results = props.results.filter(r => (r.user_id === userInfo.id) && (r.test_id === props.test.id))
    }

    let percentage:number|null = null
    let percentageContent = (null)

    if(results.length) {
        let cntPoints = 0
        let maxPoints = 0

        results.forEach(tR => {
            let testObject = new TestClass(props.test, tR)
            
            cntPoints += testObject.getTestRate()
            maxPoints += testObject.getMaximumRate()
        })

        percentage = Math.round(Number(cntPoints)/maxPoints*100)
    }

    if(percentage !== null) {
        percentageContent = (<Percentage percentage={percentage} title={i18n._('Úspešnosť')}/>)
    }

    return (    
        <section className="item">
            <header className="item_header">
                <div className="part">
                    <h3>{props.test.name}</h3>
                </div>
            </header>
            <div className="item_body">

                <div className="item-types">
                    <ul>
                        <li>
                            <div className="type">
                                <div className="type_title">
                                    <Trans>Preklad</Trans>
                                </div>
                                <div className="type_desc type-languages">
                                    <TranslateLanguages
                                        languageFrom={props.test.language_from}
                                        languageTo={props.test.language_to}
                                    />
                                </div>
                            </div>
                        </li>
                        <li>
                            
                            <div className="type">
                                <div className="type_title">
                                    <Trans>Počet slovíčok</Trans>
                                </div>
                                <div className="type_desc">
                                    {props.test.settings.words.length}
                                </div>
                            </div>

                        </li>
                        <li>

                            <div className="type">
                                <div className="type_title">
                                    <Trans>Typ domácej úlohy</Trans>
                                </div>
                                <div className="type_desc">
                                    {(typeof typeValue !== 'undefined')?typeValue.label:''}{(typeof props.test.settings.diacritic !== 'undefined')?(props.test.settings.diacritic)?', ' + i18n._('s diakritikou'):', ' + i18n._('bez diakritiky'):', ' + i18n._('bez diakritiky')} {(props.test.settings.time !== null)?'(' + props.test.settings.time + ' ' + i18n._('min') + ')':''}
                                </div>
                            </div>

                        </li>
                        <li>
                            <div className="type --graph">
                                <div className="type_title">
                                    <Trans>Moja úspešnosť</Trans>
                                </div>
                                <div className="type_desc">
                                    {percentageContent}
                                </div>
                            </div>
                            
                        </li>
                    </ul>
                </div>

            </div>
            <footer className="item_footer">
                <div className="part">
                    
                    <div className="part-list">
                        <NavLink to={"/app/classes/" + props.test.usergroup_id + "/homeworks/" + props.test.id} target="_self" className="tag _green _alt">
                            <Trans>Spustiť</Trans>
                        </NavLink>
                        <NavLink to={"/app/classes/" + props.test.usergroup_id + "/homeworks/" + props.test.id + "/detail"} target="_self" className="tag _primary _alt">
                            <Trans>Detail</Trans>
                        </NavLink>
                    </div>

                </div>
                <div className="part">
                    
                    <div className="date">
                        <Trans>platí do</Trans>&nbsp;
                        <span>
                            {(props.test.valid_until !== null)?moment(props.test.valid_until).format('DD.MM.YYYY'):moment(props.test.valid_from).format('DD.MM.YYYY')}
                            <br/>
                            {(props.test.valid_until !== null)?moment(props.test.valid_until).format('HH:mm'):moment(props.test.valid_from).format('HH:mm')}
                        </span>
                    </div>

                </div>
            </footer>
        </section>
    )
}

export default HomeWorkDetailUser