import React, {Component} from 'react';
import { connect } from 'react-redux';
import { ETestType } from '../../../../../../constants/types';
import { IApplicationStore } from '../../../../../../constants/store-interfaces';
import InformationBox from '../../../InformationBox';
import { Trans } from '@lingui/macro';

interface IStateProps {
}

interface IDispatchProps {
}

const mapStateToProps = (state: IApplicationStore): IStateProps => ({
})

const mapDispatchToProps = (dispatch: any): IDispatchProps => ({
})

interface IProps extends IStateProps, IDispatchProps{
    type: string
}

interface IState {
}


class TestTypeInfo extends Component<IProps, IState> {
    render() {
        const { type } = this.props

        return (
            <InformationBox type="success" showBell={true} showClose={false}>
                {(type === ETestType.random)?(
                    <Trans>Pri tomto type sa slovíčka učíte rôznymi spôsobmi. Otázky dostávate rôzne, buď sa jedná o skladanie slovíčok z písmen, prípadne dopĺňanie slovíčok alebo výber slovíčok z viacerých možností.</Trans>
                ):(type === ETestType.select)?(
                    <Trans>Učenie slovíčok prebieha formou výberu z viacerých ponúkaných možností. Podľa náročnosti sú slová ktoré máte na výber príbuznejšie správnej odpovedi (ťažká) alebo sa jedná o rôzne slovíčka (ľahká)</Trans>
                ):(type === ETestType.folding)?(
                    <Trans>Slovíčka skladáte so zobrazených písmen. Podľa náročnosti je týchto písmen viac (ťažká) alebo menej (ľahká)</Trans>
                ):(type === ETestType.connect)?(
                    <Trans>Slovíčka na ľavej strane obrazovky prepájate so slovíčkami na pravej strane.</Trans>
                ):(type === ETestType.filling)?(
                    <Trans>Slovíčka musíte ručne zadať. Podľa zadanej náročnosti dopĺňate celé slová, prípadne iba časti slov.</Trans>
                ):(null)}
            </InformationBox>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TestTypeInfo)