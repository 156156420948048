import React, {Component} from 'react';
import { connect } from 'react-redux';
import { Trans } from '@lingui/macro';
import moment from 'moment';
import { ReactNode } from 'react';
import { ITestResult, IUSerInfo, ITest, ITableRowType, ITableCellType, ITableHeaderCellType, IActionType, ITimelineItem, ETestType, ETestUgType, ILanguageType } from '../../../../constants/types';
import { IApplicationStore } from '../../../../constants/store-interfaces';
import { i18n } from '../../../../App';
import CircularGraph from '../../../simple/widgets/CircularGraph';
import VoiceClass from '../../../simple/classes/voiceClass';
import TestClass from '../../../simple/classes/testClass';
import getTestTypeString from '../../../simple/classes/getTestTypeString';
import TableContainer from '../TableContainer';
import TimelineContainer from '../TimelineContainer';
import InfoModal from '../../../simple/InfoModal';
import TestResultInfo from '../../parts/modals/tests/TestResultInfo';
import ResultsGraphWidget from '../../../simple/widgets/ResultsGraphWidget';

interface IStateProps {
    testResults: ITestResult[],
    userInfo: IUSerInfo | null
}

interface IDispatchProps {
}

const mapStateToProps = (state: IApplicationStore): IStateProps => ({
    testResults: state.testsStore.testResults,
    userInfo: state.globalStore.userInfo
})

const mapDispatchToProps = (dispatch: any): IDispatchProps => ({
})


interface IProps extends IStateProps, IDispatchProps {
    test: ITest
}

interface IState {
    showResult: boolean,
    selectedResult: ITestResult | null
}

class TestDetailBasic extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props)

        this.state = {
            showResult: false,
            selectedResult: null
        }

        this.handleToggleResult = this.handleToggleResult.bind(this)
        this.showResultInfo = this.showResultInfo.bind(this)
    }

    handleToggleResult() {
        this.setState({
            ...this.state,
            showResult: !this.state.showResult
        })
    }

    showResultInfo(tr: ITestResult) {
        this.setState({
            ...this.state,
            selectedResult: tr,
            showResult: true
        })
    }

    getRows(test:  ITest, results: ITestResult[]): ITableRowType[] {
        let rows: ITableRowType[]  = []

        if(typeof test !== 'undefined') {
            for (let i = 0; i < test.settings.words.length; i++) {
                const word = test.settings.words[i]

                let cells: ITableCellType[] = []

                let percentage = 0
                let percentageResult: ReactNode = (null)
                let valid = 0
                let total = 0

                results.forEach(r => {
                    if(r.result !== null) {
                        r.result.questions.forEach(q => {
                            if(
                                (q.word.from === word.from) &&
                                (q.word.to === word.to) 
                            ) {
                                if(q.valid) {
                                    valid += 1
                                }

                                total += 1
                            }
                        })
                    }
                })

                if(total > 0) {
                    percentage = Math.round(valid/total*100)
                    percentageResult = (<div  data-title={i18n._('Úspešnosť')}><CircularGraph percentage={percentage}/></div>)
                }
    
                let availableVoiceFrom = true
                let availableVoiceTo = true
                let speechColumnFrom: ReactNode = (null)
                let speechColumnTo: ReactNode = (null)

                if(VoiceClass.getInstance(test.language_from.flag_name, '').isAvailable()) {
                    speechColumnFrom = (
                        <i className="la la-volume-up" onClick={() => this.playWord(word.from, test.language_from)} data-tooltip={i18n._('Prehrať výslovnosť')}/>
                    )
                } else {
                    speechColumnFrom = (
                        <i className="la la-frown-o" data-tooltip={i18n._('Výslovnosť nedostupná')}/>
                    )
                }
                
                if(VoiceClass.getInstance(test.language_to.flag_name, '').isAvailable()) {
                    speechColumnTo = (
                        <i className="la la-volume-up" onClick={() => this.playWord(word.to, test.language_to)} data-tooltip={i18n._('Prehrať výslovnosť')}/>
                    )
                } else {
                    speechColumnTo = (
                        <i className="la la-frown-o" data-tooltip={i18n._('Výslovnosť nedostupná')}/>
                    )
                }

                cells = [
                    {
                        content: (<div className={"word " + ((availableVoiceFrom)?'_voice':'')} data-title={i18n._('Slovo')}>{word.from} {speechColumnFrom}</div>),
                        stringToOrder:  word.from,
                        filterString:  word.from,
                        filterByString:  true
                    },
                    {
                        content: (<div className={"word " + ((availableVoiceTo)?'_voice':'')} data-title={i18n._('Preklad')}>{word.to} {speechColumnTo}</div>),
                        stringToOrder:  word.to,
                        filterString:  word.to,
                        filterByString: true
                    },
                    {
                        content: (percentageResult),
                        stringToOrder: percentage.toString(),
                        filterByString: true,
                        filterString: percentage.toString()
                    }
                ]

                rows.push({
                    cells: cells,
                    catchString: i.toString()
                })
            }
        }

        return rows
    }

    getHeaders(): ITableHeaderCellType[] {
        return  [
            {
                content: (<span><Trans>Slovo</Trans></span>),
                sortBy: false,
                sortable: true
            },
            {
                content: (<span><Trans>Preklad</Trans></span>),
                sortBy: false,
                sortable: true
            },
            {
                content: (<span><Trans>Úspešnosť</Trans></span>),
                sortBy: false,
                sortable: true,
                sortableType: 'number'
            }
        ]
    }

    getActions(): IActionType[] {
        return [
        ]
    }

    playWord = (word: string, language: ILanguageType) => {
        let voice: VoiceClass = VoiceClass.getInstance(language.flag_name, word)

        voice.speak(true)
    }

    render() {
        const { testResults, test } = this.props
        const { showResult, selectedResult } = this.state

        if(test === null) {
            return (null)
        }
        
        const results = testResults.filter(e => e.test_id === test.id)

        let timelineItems: ITimelineItem[] = []

        results.sort((a, b) => moment(((b.date_finished !== null)?b.date_finished:b.date_started)).valueOf() - moment(((a.date_finished !== null)?a.date_finished:a.date_started)).valueOf())

        results.forEach(r => {
            let testObject = new TestClass(test, r)

            let percentage = Math.round(Number(testObject.getTestRate())/testObject.getMaximumRate()*100)

            let rateTitle = i18n._('bodov')

            if((testObject.getTestRate() > 1) && (testObject.getTestRate() <= 4)) {
                rateTitle = i18n._('body')
            } else if (testObject.getTestRate() === 1) {
                rateTitle = i18n._('bod')
            }
            timelineItems.push({
                date: (r.date_finished !== null)?r.date_finished:r.date_started,
                content: i18n._('Výsledok') + ' (+' + testObject.getTestRate() + ' ' + rateTitle + ')',
                icon: (<CircularGraph percentage={percentage}/>),
                button: i18n._('Detail'),
                buttonAction: () => this.showResultInfo(r)
            })
        })
        //Table of words
        let headers: ITableHeaderCellType[] = this.getHeaders()
        let rows: ITableRowType[] = this.getRows(test, results)
        let actions: IActionType[] = this.getActions()


        const typeSelect: { label: string; value: string }[] = []

        Object.keys(ETestType).forEach(value => {
            typeSelect.push({
                label: getTestTypeString(ETestType[(value as ETestType)]),
                value: ETestType[(value as ETestType)]
            })
        })

        let wordsTitle = i18n._('Slovíčka cvičenia')
        let wordsTitleEmpty = i18n._('Toto cvičenie neobsahuje žiadne slová')
        let testResultsEmpty = i18n._('Cvičenie ste ešte neabsolvovali ani raz')
        let testResultText = i18n._('Výsledok cvičenia')

        if(test.usergroup_id !== null) {
            if(test.settings.ugType === ETestUgType.homework) {
                wordsTitle = i18n._('Slovíčka domácej úlohy')
                wordsTitleEmpty = i18n._('Táto domáca úloha neobsahuje žiadne slová')
                testResultsEmpty = i18n._('Domácu úlohu ste ešte neabsolvovali ani raz')
                testResultText = i18n._('Výsledok domácej úlohy')
            } else {
                wordsTitle = i18n._('Slovíčka písomky')
                wordsTitleEmpty = i18n._('Táto písomka neobsahuje žiadne slová')
                testResultsEmpty = i18n._('Písomku ste ešte neabsolvovali ani raz')
                testResultText = i18n._('Výsledok písomky')
            }
        }
        
        
        return (
            <div className="test-content">
                <div className="mainSectionBody">
                
                    <div className="rows">

                        <div className="row">
                                <div className="row-part">
                                    <ResultsGraphWidget
                                        results={results}
                                        openModal={(r: ITestResult) => this.showResultInfo(r)}
                                    />
                                </div>
                            </div>
                        <div className="row">
                            <div className="row-part">
                                <div className="widget-holdeer">
                                    <div className="widget-holder-header">
                                        <div className="widget-part">
                                            <h2>{ wordsTitle } ({test.settings.words.length})</h2>
                                        </div>
                                    </div>
                                    <div className="widget-holder-body">
                                        <div className="widget-part">
                                            <section className="widget">
                                                <div className="widget-body">
                                                    <TableContainer
                                                        headers={headers}
                                                        rows={rows}
                                                        actions={actions}
                                                        showActions={false}
                                                        showCheckbox={false}
                                                        showFilter={false}
                                                        pageSize={20}
                                                        emptyText={ wordsTitleEmpty }
                                                        showNew={false}
                                                        showGrid={false}
                                                        title={ wordsTitle }
                                                        mobileFixedGrid={true}
                                                    />
                                                </div>
                                            </section>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row-part">
                                <div className="widget-holdeer">
                                    <div className="widget-holder-header">
                                        <div className="widget-part">
                                            <h2><Trans>Moje výsledky</Trans> ({results.length})</h2>
                                        </div>
                                    </div>
                                    <div className="widget-holder-body">
                                        <div className="widget-part">
                                            <section className="widget">
                                                <div className="widget-body">
                                                    <TimelineContainer
                                                        show={true}
                                                        items={timelineItems}
                                                        emptyText={ testResultsEmpty}
                                                    />
                                                </div>
                                            </section>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                    </div>
                
                </div>

                {((selectedResult !== null) && (selectedResult.result !== null))?(
                    <InfoModal
                        title={testResultText + ' - ' + ((selectedResult.date_finished !== null)?moment(selectedResult.date_finished).format('DD.MM.YYYY HH:mm'):moment(selectedResult.date_started).format('DD.MM.YYYY HH:mm'))}
                        custom={true}
                        customShow={showResult}
                        customShowCloseFunction={this.handleToggleResult}
                    >
                        <TestResultInfo
                            test={test}
                            result={selectedResult}
                        />
                    </InfoModal>
                ):(null)}
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TestDetailBasic)