import { ETestType } from "../../../constants/types";
import { i18n } from '../../../App';

export default function getTestTypeString(type: ETestType) {
    let typeTest = i18n._('Náhodný typ')

    switch(type){
        case ETestType.select:
            typeTest = i18n._('Výber z možností')
            break
        case ETestType.folding:
            typeTest = i18n._('Skladanie slov')
            break
        case ETestType.connect:
            typeTest = i18n._('Spájanie slov')
            break
        case ETestType.filling:
            typeTest = i18n._('Dopĺňanie slov')
            break
    }

    return typeTest
}