import { setLoadingCreatedTests } from '../loadings/setLoading';
import config from '../../config';
import { handleErrors } from '../../utils/handleErrors';
import { MODIFY_USERGROUP_TEST_STARTED, MODIFY_USERGROUP_TEST_FAILED, MODIFY_USERGROUP_TEST } from '../../constants/action-types';
import { store } from '../../store';
import { ITestCreate, ETestUgType } from '../../constants/types';
import { addFloatingNotification } from '../notifications/floating';
import { i18n } from '../../App';
import { setShowEditTestUserGroupModal } from '../modals/setShowModal';

export function editUserGroupTest(id: number, data:ITestCreate) {
    return (dispatch: any) => {

        dispatch(setLoadingCreatedTests(true))
        dispatch(editUserGroupTestStarted())

        return fetch(config.api.tests + '/' + id, {
            method: 'PUT',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + store.getState().globalStore.token
            },
            body: JSON.stringify(data)
            
        })
            .then(handleErrors)
            .then(res => res.json())
            .then(res => {
                
                dispatch(setShowEditTestUserGroupModal(false))

                if((typeof data.settings.ugType !== 'undefined') && (data.settings.ugType === ETestUgType.homework)) {
                    dispatch(addFloatingNotification({
                        type:"success",
                        text:i18n._('Domáca úloha bola úspešne aktualizovaná'),
                        showBell: true
                    }))
                } else {
                    dispatch(addFloatingNotification({
                        type:"success",
                        text:i18n._('Písomka bola úspešne aktualizovaná'),
                        showBell: true
                    }))
                }
                
                dispatch(editUserGroupTestSuccess({test: res.data, ugId: res.data.usergroup_id}))
                dispatch(setLoadingCreatedTests(false))
                
            })
            .catch((error: any)  => {
                dispatch(editUserGroupTestFailed(error))
                dispatch(setLoadingCreatedTests(false))
            });
    };
}

export const editUserGroupTestStarted = () => ({
    type: MODIFY_USERGROUP_TEST_STARTED
});

export const editUserGroupTestSuccess = (data:  any) => ({
    type: MODIFY_USERGROUP_TEST,
    payload: data
});

export const editUserGroupTestFailed = (error: any) => ({
    type: MODIFY_USERGROUP_TEST_FAILED,
    payload: { error }
});