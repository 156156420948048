import React, {Component} from 'react'
import { connect } from 'react-redux'
import { IApplicationStore } from '../../constants/store-interfaces';

const mapStateToProps = (state: IApplicationStore) => ({
})

const mapDispatchToProps = (dispatch: any) => ({
})

interface IProps {
    loadingValue?: boolean
}
class LoadingElement extends Component<IProps> {
    
    render() {
        const { loadingValue } = this.props
        
        if((typeof loadingValue == 'undefined')||(loadingValue)) {
            return (
                <div className="preloader"></div>
            )
        } else  {
            return (null)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoadingElement)
