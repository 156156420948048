import React, {Component } from 'react'
import { CircularProgressbar } from 'react-circular-progressbar';

interface IProps {
    percentage: number
    big?: boolean
    disableClasses?: boolean
}

class CircularGraph extends Component<IProps, any> {

    render() {
        const { percentage }  = this.props

        let color = 'red';

        if(percentage > 30) {
            color = 'orange'
        }

        if(percentage > 55) {
            color = 'blue'
        }

        if(percentage > 80) {
            color = 'green'
        }

        let classes = '_small'

        if((typeof this.props.big !== 'undefined') && (this.props.big)) {
            classes = '_big'
        }

        if((typeof this.props.disableClasses !== 'undefined') && (this.props.disableClasses)) {
            classes = ''
        }

        return (
            <CircularProgressbar strokeWidth={2} className={"knob " + classes + " _" +  color} value={percentage} text={percentage + '%'} />
        )
    }
}

export default CircularGraph
