import { setLoadingCreatedWords } from '../loadings/setLoading';
import config from '../../config';
import { handleErrors } from '../../utils/handleErrors';
import { DELETE_CREATED_WORD_STARTED, DELETE_CREATED_WORD, DELETE_CREATED_WORD_FAILED } from '../../constants/action-types';
import { store } from '../../store';
import { addFloatingNotification } from '../notifications/floating';
import { i18n } from '../../App';

export function deleteWord(ids: number[]) {
    return (dispatch: any) => {

        dispatch(setLoadingCreatedWords(true))
        dispatch(deleteWordStarted())

        return fetch(config.api.words_delete, {
            method: 'POST',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + store.getState().globalStore.token
            },
            body: JSON.stringify({
                delete: true,
                words:ids
            })
        })
            .then(handleErrors)
            .then(res => res.json())
            .then(res => {

                for(let id of ids) {
                    dispatch(deleteWordSuccess(id))
                }

                dispatch(setLoadingCreatedWords(false))

                if(ids.length === 1) {
                    dispatch(addFloatingNotification({
                        type:"warning",
                        text:i18n._('Slovo odstránené'),
                        showBell: true
                    }))
                } else {
                    dispatch(addFloatingNotification({
                        type:"warning",
                        text:i18n._('Slová odstránené'),
                        showBell: true
                    }))
                }

            })
            .catch((error: any)  => {
                dispatch(deleteWordFailed(error))
                dispatch(setLoadingCreatedWords(false))
            });
    };
}

export const deleteWordStarted = () => ({
    type: DELETE_CREATED_WORD_STARTED
});

export const deleteWordSuccess = (data:  any) => ({
    type: DELETE_CREATED_WORD,
    payload: data
});

export const deleteWordFailed = (error: any) => ({
    type: DELETE_CREATED_WORD_FAILED,
    payload: { error }
});