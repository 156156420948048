import React from 'react';
import { Trans } from '@lingui/macro';
import { ITest, ETestUgType, IUserGroupType } from '../../../../constants/types';
import SummaryBlock from '../../parts/small/SummaryBlock';
import { i18n } from '../../../../App';
import getTestTypeString from '../../../simple/classes/getTestTypeString';
import TestTypeInfo from '../../parts/modals/modalParts/summary/TestTypeInfo';
import TestDifficulty from '../../../simple/tableElements/TestDifficulty';
import { NavLink } from 'react-router-dom';
import moment from 'moment';
import InformationBox from '../../parts/InformationBox';

interface IProps {
    test: ITest,
    initTest: () => any,
    usergroup?: IUserGroupType,
    alreadyDid?: boolean
}


const InitTest: React.FC<IProps> = ({test, initTest, usergroup, alreadyDid}) => {

    let title = i18n._('Cvičenie')
    let typeTest = i18n._('Typ cvičenia')
    let runTest = i18n._('Spustiť cvičenie')
    let notAvailable = i18n._('Toto cvičenie nie je pre Vás dostupné!')

    if(test.usergroup_id !== null) {
        if(test.settings.ugType === ETestUgType.homework) {
            title = i18n._('Domáca úloha')
            typeTest = i18n._('Typ domácej úlohy')
            runTest = i18n._('Spustiť domácu úlohu')
            notAvailable = i18n._('Táto domáca úloha nie je pre Vás dostupná!')
        } else {
            title = i18n._('Písomka')
            typeTest = i18n._('Typ písomky')
            runTest = i18n._('Spustiť písomku')
            notAvailable = i18n._('Táto písomka nie je pre Vás dostupná!')
        }
    }

    let isValid = true

    if(moment(test.valid_from).isAfter(moment())) {
        isValid = false
    }   

    if((test.valid_until !== null) && (moment(test.valid_until).isBefore(moment()))) {
        isValid = false
    }

    if(typeof alreadyDid !== 'undefined' && alreadyDid && isValid) {
        isValid = false
        notAvailable = i18n._('Túto písomku ste už vyplnili. Ak ju chcete vyplniť znova, požiadajte svojho učiteľa o vymazanie Vášho výsledku.')
    }

    return (
        <div className="rows">
            <div className="row">
                <div className="row-part">
                    
                    <div id="test">
                        
                        <div className="test-content">
                        
                            <div className="wrapper">
                                
                                <div className="test-block">
                                    <h1>{title}: <span>{test.name}</span></h1>
                                    <br/>
                                    <hr/>
                                    <br/>
                                    {(isValid)?(
                                        <>
                                            <div className="summary">
                                                {(typeof usergroup !== 'undefined')?(
                                                    <SummaryBlock title={i18n._('Trieda')} iconName='chevron-circle-right'>
                                                        <NavLink to={"/app/classes/" + usergroup.id} target="_self" className="bttn _small _orange">
                                                            {usergroup.name}
                                                        </NavLink>
                                                    </SummaryBlock>
                                                ):(null)}
                                                <SummaryBlock title={typeTest} iconName='chevron-circle-right'>
                                                    {getTestTypeString(test.settings.type) + (((typeof test.settings.diacritic !== 'undefined') && (test.settings.diacritic))?', ' + i18n._('s diakritikou'):', ' + i18n._('bez diakritiky'))}
                                                    <TestTypeInfo type={test.settings.type} />
                                                </SummaryBlock>
                                                <hr/>
                                                <div className="input-row">
                                                    <div className="part">
                                                        <SummaryBlock title={i18n._('Náročnosť')} iconName='bar-chart'>
                                                            <TestDifficulty difficulty={test.settings.difficulty} />
                                                        </SummaryBlock>            
                                                    </div>
                                                    <div className="part">
                                                        {(test.settings.time !== null)?(
                                                            <SummaryBlock title={i18n._('Časový limit')} iconName='clock-o'>
                                                                {test.settings.time} <Trans>min</Trans>
                                                            </SummaryBlock>
                                                        ):(null)}
                                                    </div>
                                                    <div className="part text-left">
                                                        <SummaryBlock title={(test.settings.words.length + ' ' + ((test.settings.words.length === 1)?(i18n._('slovíčko')):(test.settings.words.length <= 4)?(i18n._('slovíčka')):(i18n._('slovíčok'))))} iconName='language' className="text-left">
                                                            
                                                        </SummaryBlock>
                                                    </div>
                                                </div>
                                            </div>
                                            <br/>
                                            <hr/>
                                            <br/>
                                            <div className="test-nav">
                                                <ul>
                                                    <li className="text-center">
                                                        <button className="bttn _primary _next" onClick={initTest}>{runTest}</button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </>
                                    ):(
                                        <div className="summary">
                                            <InformationBox type="error" showBell={true}>
                                                { notAvailable }
                                            </InformationBox>
                                        </div>
                                    )}
                                </div>
                                
                            </div>
                        
                        </div>
                    
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

export default InitTest