import React, {Component } from 'react'
import { ITestResult, IUserBasic } from '../../../constants/types';
import Chart from "react-apexcharts";
import moment from 'moment';
import { Trans } from '@lingui/macro';

interface IProps {
    results: ITestResult[]
    openModal?: (tr: ITestResult) => any
    users: IUserBasic[]
}

class ResultsGraphUsersWidget extends Component<IProps, any> {
    
    render() {
        const { results, openModal, users }  = this.props

        let series: {
            name: string,
            userId: number,
            data: {x:number,y:number}[]
        }[] = []

        results.sort((a, b) => moment(((a.date_finished !== null)?a.date_finished:a.date_started)).valueOf() - moment(((b.date_finished !== null)?b.date_finished:b.date_started)).valueOf())

        results.forEach(r => {
            let date = (r.date_finished !== null)?r.date_finished:r.date_started
            let validAnswers = 0
    
            if(r.result !== null) {
                r.result.questions.forEach(q => {
                    validAnswers += (q.valid)?1:0
                })

                let serie = series.find(s => s.userId === r.user_id)

                if(typeof serie === 'undefined') {
                    let user = users.find(u => u.id === r.user_id)

                    if(typeof user !== 'undefined') {
                        serie = {
                            name: user.f_name,
                            userId: user.id,
                            data: []
                        }

                        series.push(serie)
                    }
                }

                if(typeof serie !== 'undefined') {
                    serie.data.push({
                        x:moment(date).toDate().getTime(),
                        y:Math.round(validAnswers/r.result.questions.length*100)
                    })
                }
            }
        })

        let options = {
            xaxis: {
                type: 'datetime'
            },
            yaxis: {
                min: 0,
                max: 100,
                tickAmount: 5,
                labels: {
                    formatter: (value:number) => value.toFixed(0) +' %',
                }
            },
            stroke: {
                curve: 'smooth'
            },
            dataLabels: {
                enabled: false
            },
            chart: {
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false
                },
                events: {
                    dataPointSelection: function (event:any, chartContext:any, config:any) {
                        if(typeof openModal !== 'undefined') {
                            let result = results[config.dataPointIndex]

                            openModal(result)
                        }
                    }
                }
            },
            legend: {
                show: true
            },
            markers: {
                size: 4
            },
            tooltip: {
                shared: false,
                intersect: true,
                x: {
                    format: 'dd. MM. yyyy HH:mm'
                }
            }
        }

        return (
            <div className="small-widget">
                <div className="_part _left">
                    <h3><Trans>Výsledky žiakov</Trans></h3>
                    <Chart
                        options={options}
                        series={series}
                        height="200"
                    />
                </div>
            </div>
        )
    }
}

export default ResultsGraphUsersWidget
