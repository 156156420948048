import React, {Component} from 'react';
import { connect } from 'react-redux';
import { IApplicationStore } from '../../../../../constants/store-interfaces';
import { Trans } from '@lingui/macro';
import { ILanguageType, IAddWordMapping } from '../../../../../constants/types';
import AllWordsSummary from './summary/AllWordsSummary';
import SummaryBlock from '../../small/SummaryBlock';
import { i18n } from '../../../../../App';


const mapStateToProps = (state: IApplicationStore) => ({
})

const mapDispatchToProps = (dispatch: any) => ({
})

interface IProps {
    languageFrom: ILanguageType,
    languageTo: ILanguageType,
    wordGroupName: string
    words: IAddWordMapping[],
    
}

interface IState {
}

class SummaryAddWordGroup extends Component<IProps, IState> {

    render() {
        const { languageFrom, languageTo, words, wordGroupName } = this.props
         
        return (
            <div className="input-row">
                <div className="part">
                
                    <h4 className="headline-divider"><Trans>Súhrn</Trans></h4>

                    <div className="summary">
                        <SummaryBlock title={i18n._('Názov skupiny')} iconName='map-signs'>
                            <span className="tag _green">
                                {wordGroupName}
                            </span>
                        </SummaryBlock>
                        <SummaryBlock title={(words.length === 1)?(i18n._('slovíčko')):(words.length <= 4)?(i18n._('slovíčka')):(i18n._('slovíčok'))} iconName='language'>
                            <AllWordsSummary
                                languageFrom={languageFrom}
                                languageTo={languageTo}
                                words={words}
                            />
                        </SummaryBlock>
                    </div>
                    
                </div>
            </div>
            
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SummaryAddWordGroup)