import React, {Component, PropsWithChildren} from 'react'
import moment from 'moment'

interface IProps {
    date: string,
    formatDate: string,
    formatTime: string,
    title: string,
    tag?: string,
    tooltip?: string
}

class DateText extends Component<PropsWithChildren<IProps>, any> {

    render() {
        const { date, formatDate, formatTime, title, tag, tooltip }  = this.props
        if(typeof tooltip !== 'undefined') {
            if(typeof tag !== 'undefined') {
                return (
                    <div className="part-list" data-title={title} data-tooltip={tooltip}>
                        <span className={"tag " + tag}>
                            <strong>{moment(date).format(formatDate)}</strong> <small>{moment(date).format(formatTime)}</small>
                        </span>
                    </div>
                )
            } else {
                return (
                    <div className="part-date" data-title={title} data-tooltip={tooltip}>
                        <strong>{moment(date).format(formatDate)}</strong> <small>{moment(date).format(formatTime)}</small>
                    </div>
                )
            } 
        } else {
            if(typeof tag !== 'undefined') {
                return (
                    <div className="part-list" data-title={title}>
                        <span className={"tag " + tag}>
                            <strong>{moment(date).format(formatDate)}</strong> <small>{moment(date).format(formatTime)}</small>
                        </span>
                    </div>
                )
            } else {
                return (
                    <div className="part-date" data-title={title}>
                        <strong>{moment(date).format(formatDate)}</strong> <small>{moment(date).format(formatTime)}</small>
                    </div>
                )
            }
        }
    }
}

export default DateText
