import { 
    SET_HIDE_ALL_MODALS,
    SET_SHOW_INFO_MODAL,
    SET_SHOW_CREATE_TEST_MODAL,
    SET_SHOW_CREATE_USERGROUP_MODAL,
    SET_SHOW_CREATE_WORD_MODAL,
    SET_SHOW_CREATE_WORD_MODAL_SIMPLE,
    SET_SHOW_CONFIRM_MODAL,
    SET_SHOW_EDIT_WORD_MODAL,
    SET_SHOW_CREATE_WORDGROUP_MODAL,
    SET_SHOW_EDIT_WORDGROUP_MODAL,
    SET_LOGIN_USER_DATA,
    SET_SHOW_EDIT_USERGROUP,
    SET_SHOW_CREATE_TEST_WG_MODAL,
    SET_SHOW_CREATE_TEST_UG_MODAL,
    SET_SHOW_EDIT_TEST_USERGROUP,
    SET_SHOW_DELETE_TEST_USERGROUP,
    SET_SHOW_EDIT_TRAINING,
    RESET_STATE,
    SET_SHOW_TUTORIAL
} from "../constants/action-types"
import { Reducer } from 'redux'
import { IModalsStore } from '../constants/store-interfaces';

const initialState: IModalsStore = {
    showConfirm: false,
    showInfo: false,
    showAddTest: false,
    showAddTestWG: false,
    showAddTestUG: false,
    showAddUserGroup: false,
    showAddWord: false,
    showAddWordSimple: false,
    showAddWordGroup: false,
    showEditWord: false,
    showEditWordGroup: false,
    showEditUserGroup: false,
    showEditTestUserGroup: false,
    showDeleteTestUserGroup: false,
    showEditTraining: false,
    showTutorial: false
}

const reducer: Reducer<IModalsStore> = (state = initialState, action) => {
    let was = false

    switch(action.type) {
        case SET_LOGIN_USER_DATA:
            state = initialState
            was = true
            break
        //Set hide all  modals
        case SET_HIDE_ALL_MODALS:
            state = {
                ...Object.keys(state).reduce((reduced, key) => ({ 
                    ...reduced, 
                    [key]: false
                }), {...state})
            }
            was = true
            break
        //Set show modal for add usergroup
        case SET_SHOW_CONFIRM_MODAL:
            state = {
                ...Object.keys(state).reduce((reduced, key) => ({ 
                    ...reduced, 
                    [key]: (key === 'showConfirm')?action.payload:false 
                }), {...state})
            }
            was = true
            break
        //Set show modal for info
        case SET_SHOW_INFO_MODAL:
            state = {
                ...Object.keys(state).reduce((reduced, key) => ({ 
                    ...reduced, 
                    [key]: (key === 'showInfo')?action.payload:false 
                }), {...state})
            }
            was = true
            break
        //Set show modal for add words
        case SET_SHOW_CREATE_WORD_MODAL:
            state = {
                ...Object.keys(state).reduce((reduced, key) => ({ 
                    ...reduced, 
                    [key]: (key === 'showAddWord')?action.payload:false 
                }), {...state})
            }
            was = true
            break
        //Function to add words under detail of wordgroup page
        case SET_SHOW_CREATE_WORD_MODAL_SIMPLE:
            state = {
                ...Object.keys(state).reduce((reduced, key) => ({ 
                    ...reduced, 
                    [key]: (key === 'showAddWordSimple')?action.payload:false 
                }), {...state})
            }
            was = true
            break
        //Set show modal for edit word
        case SET_SHOW_EDIT_WORD_MODAL:
            state = {
                ...Object.keys(state).reduce((reduced, key) => ({ 
                    ...reduced, 
                    [key]: (key === 'showEditWord')?action.payload:false 
                }), {...state})
            }
            was = true
            break
        //Set show modal for  add test
        case SET_SHOW_CREATE_TEST_MODAL:
            state = {
                ...Object.keys(state).reduce((reduced, key) => ({ 
                    ...reduced, 
                    [key]: (key === 'showAddTest')?action.payload:false 
                }), {...state})
            }
            was = true
            break
        //Set show modal for  add WG test
        case SET_SHOW_CREATE_TEST_WG_MODAL:
            state = {
                ...Object.keys(state).reduce((reduced, key) => ({ 
                    ...reduced, 
                    [key]: (key === 'showAddTestWG')?action.payload:false 
                }), {...state})
            }
            was = true
            break
        //Set show modal for add UG test
        case SET_SHOW_CREATE_TEST_UG_MODAL:
            state = {
                ...Object.keys(state).reduce((reduced, key) => ({ 
                    ...reduced, 
                    [key]: (key === 'showAddTestUG')?action.payload:false 
                }), {...state})
            }
             was = true
             break
        //Set show modal for add usergroup
        case SET_SHOW_CREATE_USERGROUP_MODAL:
            state = {
                ...Object.keys(state).reduce((reduced, key) => ({ 
                    ...reduced, 
                    [key]: (key === 'showAddUserGroup')?action.payload:false 
                }), {...state})
            }
            was = true
            break
        //Set show modal for add wordgroup
        case SET_SHOW_CREATE_WORDGROUP_MODAL:
            state = {
                ...Object.keys(state).reduce((reduced, key) => ({ 
                    ...reduced, 
                    [key]: (key === 'showAddWordGroup')?action.payload:false 
                }), {...state})
            }
            was = true
            break
        //Set show modal for edit wordgroup
        case SET_SHOW_EDIT_WORDGROUP_MODAL:
            state = {
                ...Object.keys(state).reduce((reduced, key) => ({ 
                    ...reduced, 
                    [key]: (key === 'showEditWordGroup')?action.payload:false 
                }), {...state})
            }
            was = true
            break
        //Set show modal for edit wordgroup
        case SET_SHOW_EDIT_USERGROUP:
            state = {
                ...Object.keys(state).reduce((reduced, key) => ({ 
                    ...reduced, 
                    [key]: (key === 'showEditUserGroup')?action.payload:false 
                }), {...state})
            }
            was = true
            break
        //Set show modal for edit test inside usergroup
        case SET_SHOW_EDIT_TEST_USERGROUP:
            state = {
                ...Object.keys(state).reduce((reduced, key) => ({ 
                    ...reduced, 
                    [key]: (key === 'showEditTestUserGroup')?action.payload:false 
                }), {...state})
            }
            was = true
            break
        //Set show modal for delete test inside usergroup
        case SET_SHOW_DELETE_TEST_USERGROUP:
            state = {
                ...Object.keys(state).reduce((reduced, key) => ({ 
                    ...reduced, 
                    [key]: (key === 'showDeleteTestUserGroup')?action.payload:false 
                }), {...state})
            }
            was = true
            break
        //Set show modal for edit training
        case SET_SHOW_EDIT_TRAINING:
            state = {
                ...Object.keys(state).reduce((reduced, key) => ({ 
                    ...reduced, 
                    [key]: (key === 'showEditTraining')?action.payload:false 
                }), {...state})
            }
            was = true
            break
        //Set show modal for tutorial
        case SET_SHOW_TUTORIAL:
            state = {
                ...Object.keys(state).reduce((reduced, key) => ({ 
                    ...reduced, 
                    [key]: (key === 'showTutorial')?action.payload:false 
                }), {...state})
            }
            was = true
            break
        case RESET_STATE:
            state = initialState
            was = true
            break
    }    

    if(action.type.endsWith("_FAILED") && was) {
        //TODO show error
    }

    return state
}

export { reducer as modalReducer }