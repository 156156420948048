import { setLoading } from '../loadings/setLoading';
import config from '../../config';
import { handleErrors } from '../../utils/handleErrors';
import { store } from '../../store';
import { history } from '../../../index';
import { addFloatingNotification } from '../notifications/floating';
import { i18n } from '../../App';

export function deleteSelfFromUserGroup(classId: number, className: string) {
        store.dispatch(setLoading(true))

        return fetch(config.api.usergroups + '/' + classId + '/remove-me', {
            method: 'POST',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + store.getState().globalStore.token
            }            
        })
            .then(handleErrors)
            .then(res => res.json())
            .then(res => {

                store.dispatch(setLoading(false))
                
                store.dispatch(addFloatingNotification({
                    type:"success",
                    text:i18n._('Odstránili ste sa z triedy')  + ' "'  +  className + '".',
                    showBell: true
                }))

                history.push({
                    pathname: '/app/classes'
                })
            })
            .catch((error: any)  => {
                //TODO: show error
                store.dispatch(setLoading(false))
            });
}