import React from 'react';

interface IProps {

}

const Languages: React.FC<IProps> = props => {
    // const dispatch = useDispatch()

    // const ref = useRef<HTMLInputElement>(null);

    // const showLanguage = useSelector((state: IApplicationStore) => state.globalStore.showLanguage)
    // const language = useSelector((state: IApplicationStore) => state.globalStore.language)

    // useOnClickOutside(ref, () => dispatch(setShowLanguages(false)))

    // const handleLanguages = () => {
    //     dispatch(setShowLanguages(!showLanguage))
    // }

    // const handleLanguageChange = (lang: string) => {
    //     dispatch(setShowLanguages(false))
    //     dispatch(setLanguage(lang))
    // }

    // let currentLanguageCode = 'sk'
    // let possibleLanguages: {lng: string, flag: string}[] = [
    //     {
    //         lng: 'sk',
    //         flag: 'sk'
    //     }, 
    //     {
    //         lng: 'en',
    //         flag: 'gb'
    //     }
    // ]

    // switch(language) {
    //     case 'en':
    //         currentLanguageCode = 'gb'
    //         break
    // }

    // possibleLanguages = possibleLanguages.filter(lng => lng.lng !== language)
    
    // return (
    //     <div className="part">
    //         <div className="langs" ref={ref}>
    //             <div className="lang-current" onClick={handleLanguages}>
    //                 <ReactCountryFlag className="flag" countryCode={currentLanguageCode} svg/>
    //             </div>
    //             <div className={"lang-switch " + ((showLanguage)?'active':'')}>
    //                 <ul>
    //                     {possibleLanguages.map((l,i) => (
    //                         <li key={i}><a href="/" target="_self" onClick={(e:any) => {e.preventDefault(); handleLanguageChange(l.lng)}}><ReactCountryFlag className="flag" countryCode={l.flag} svg/></a></li>
    //                     ))}
                        
    //                 </ul>
    //             </div>
    //         </div>
    //     </div>
    // )
    return (null)
}

export default Languages
