import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Trans } from '@lingui/macro';
import { i18n } from '../../App';
import LoadingElement from '../simple/LoadingElement';
import config from '../../config';
import { store } from '../../store';
import { handleErrors } from '../../utils/handleErrors';
import { addFloatingNotification } from '../../actions/notifications/floating';
import InformationBox from '../app/parts/InformationBox';
import { IFormData, validationService, getValuesObject } from '../simple/classes/validationService';
import AppInput from './AppInput';

interface IProps {

}

const RestoreRequestForm: React.FC<IProps> = props => {
    const [message, setMessage] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false)
    const [messageType, setMessageType] = useState<'warning'|'success'>('success')

    const [formRestore, setFormRestore] = useState<IFormData>({
        values: [
            {
                valid: true,
                name: 'login',
                value: '',
                invalidMessages: [],
                rules: [
                    {
                        type: 'required',
                        message: i18n._('Je potrebné zadať prihlasovacie meno!')
                    }
                ]
            }
        ],
        valid: true
    })

    const handleSubmitRequest = (e:any) => {

        e.preventDefault();

        validationService(formRestore).then(response => {
            setFormRestore(response)

            if(response.valid) {
                let values = getValuesObject(['login'], formRestore)

                setLoading(true)
                
                fetch(config.api.restorationRequest, {
                    method: 'post',
                    credentials: 'include',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + store.getState().globalStore.token
                    },
                    body: JSON.stringify(values)
                })
                    .then(handleErrors)
                    .then(res => res.json())
                    .then(json => {
                        setLoading(false)
                        if(json.status === 'failed') {
                            if(json.message === 'user_doesnt_exists') {
                                setMessageType('warning')
                                setMessage(i18n._('Používateľ s týmto prihlasovacím menom neexistuje!'))
                            }
                        } else {
                            setMessageType('success')
                            setMessage(i18n._('Na Váš e-mail bol odoslaný odkaz na obnovenie hesla.'))
                        }
                        
            
                    })
                    .catch((error: any)  => {
                        setLoading(false)
                        setMessage('')
                        store.dispatch(addFloatingNotification({
                            type:"warning",
                            text:i18n._('Nepodarilo sa odoslať formulár, skúste neskôr, alebo sa na nás obráťte prostredníctvom e-mailu na info@alphie.app'),
                            showBell: true
                        }))
                    })
            }
        })

    }
    
    return (
            <form onSubmit={handleSubmitRequest} className="form _register">
                
                <LoadingElement loadingValue={loading}/>
                
                <div className="input-row">
                    <div className="part">
                        <label htmlFor="login"><Trans>Prihlasovacie meno</Trans> <i>*</i></label>
                        
                        <div className="input-holder">
                            <AppInput
                                type="text"
                                id="login"
                                name="login"
                                formData={formRestore}
                                setFormData={setFormRestore}
                                placeholder={i18n._('Prihlasovacie meno')}
                            />
                        </div>
                        
                    </div>
                </div>

                {(message.length > 0)?(
                    <InformationBox type={messageType} showBell={true}>{message}</InformationBox>
                ):(null)}
                
                <div className="input-row">
                    <div className="part">
                        
                        <input type="submit" value={i18n._("Požiadať o obnovenie hesla")} className="ant-input bttn _primary _full"/>
                        
                    </div>
                </div>
                
                <div className="input-row">
                    <div className="part text-center">
                        
                        <p><Trans>Už máš účet?</Trans> <NavLink to="/login" className="link"><Trans>Prihlás sa!</Trans></NavLink></p>
                    
                        <p>
                           <Trans>Nemáš účet?</Trans> <NavLink to="/register" className="link"><Trans>Zaregistruj sa</Trans></NavLink>
                        </p>
                    </div>
                </div>
                
            </form>
    )
}

export default RestoreRequestForm