import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trans } from '@lingui/macro';
import { NavLink } from 'react-router-dom';
import LoadingElement from '../simple/LoadingElement';
import { IUSerInfo } from '../../constants/types';
import config from '../../config';
import InformationBox from '../app/parts/InformationBox';
import { IApplicationStore } from '../../constants/store-interfaces';
import RegisterFormOAuth from './RegisterFormOAuth';
import { createUserAuth } from '../../actions/global/createUserAuth';
import { loginAuthUser } from '../../actions/global/loginAuthUser';
import { setLoginUserData } from '../../actions/global/loginUser';

interface  IProps {
    type: 'success'|'error',
    token: string | null
}
export interface IData {
    type: 'first'|'more'|'one',
    users?: {
        user: IUSerInfo,
        access_token: string
    }[],
    user?: IUSerInfo,
    email: string
}

const AuthForm: React.FC<IProps> = props => {
    const dispatch = useDispatch()

    const registerLoading = useSelector((state: IApplicationStore) => state.loadingStore.loadingRegister)

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState<IData | null>(null)
    
    
    useEffect(() => {
        setLoading(true)

        let isRendered = true

        fetch(config.api.loginUser + '/user', {
            method: 'get',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.token
            }
        })
            .then(res => res.json())
            .then(json => {       
                if(isRendered) {
                    setLoading(false)  

                    if(typeof json.data === 'undefined') {
                        if(isRendered) {
                            setData(null)  
                        }
                    } else {
                        if(json.data.type === 'one' && props.token !== null && isRendered) {
                            dispatch(loginAuthUser(props.token, json.data.user))
                        }
                        if(isRendered) {
                            setData(json.data)
                        }
                    }
                }
                return null
            })
            .catch((error: any)  => {
                if(isRendered) {
                    setData(null)
                    setLoading(false)
                }
                console.warn(error)
                return null
            });
        return () => {
            isRendered = false
        }
    }, [dispatch, props.token])

    const handleSubmitNone = (values:any) => {
        if(data !== null) {
            values.email = data.email
            
            if(props.token !== null) {
                dispatch(createUserAuth(values, props.token))
            }
        }
    }

    return (
        <>
            {(loading || registerLoading)?(
                <LoadingElement loadingValue={loading || registerLoading}/>
            ):(
                <>
                    <h1 className="text-center"><Trans>Prihlásenie do aplikácie Alphie</Trans></h1>
                    {props.type === 'error' || (props.type === 'success' && !registerLoading && !loading && data === null)?(
                        <InformationBox type="warning" showBell={true}>
                            <Trans>Nepodarilo sa prihlásiť. Vyskúšajte prosím neskôr alebo nás kontaktujte na info@alphie.app</Trans>
                        </InformationBox>
                    ):(null)}
                    <div className="form _register">
                        
                        {(data === null)?(
                            <div className="input-row">
                                <div className="part text-center">
                                    
                                    <p><Trans>Už máš účet?</Trans> <NavLink to="/login" className="link"><Trans>Prihlás sa!</Trans></NavLink></p>
                                    
                                    
                                    <p><Trans>Zabudol si svoje heslo?</Trans>&nbsp;<NavLink to="/restore-password" className="link"><Trans>Obnov si ho tu</Trans></NavLink>!</p>
                                    
                                </div>
                            </div>
                        ):(
                            <div>
                                {data.type === 'first'?(
                                    <>
                                        <InformationBox type="info" showBell={true}>
                                            <Trans>Vitajte v aplikácii Alphie. Pre dokončenie registrácie je potrebné vyplniť niekoľko údajov.</Trans>
                                        </InformationBox>
                                        <RegisterFormOAuth                                
                                            data={data}
                                            onAuthSubmit={handleSubmitNone}
                                        />
                                    </>
                                ):(<div>
                                    {(typeof data.users !== 'undefined')?(
                                        <>
                                            <h4 className="text-center"><Trans>Tento e-mail patrí viacerým používateľom. Ktorý účet chcete použiť?</Trans></h4>
                                            <ul className="users-login-list">
                                                {data.users.map((u,i) => (
                                                    <li 
                                                        className="bttn _primary _outline"
                                                        key={i}
                                                        onClick={() => {
                                                            dispatch(setLoginUserData({
                                                                token: u.access_token,
                                                                user: u.user
                                                            }))
                                                        }}
                                                    >
                                                        <i className="la la-user"/>
                                                        {u.user.login}
                                                        <i className="la la-arrow-right"/>
                                                    </li>
                                                ))}
                                            </ul>
                                        </>
                                    ):(null)}
                                </div>)}
                            </div>
                        )}
                        
                    </div>
                </>
            )}
        </>
    )
}

export default AuthForm
