import { Trans } from '@lingui/macro';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { setShowMenu } from '../../../../actions/global/setShowMenu';
import { setShowCreateUserGroupModal } from '../../../../actions/modals/setShowModal';
import { loadAssignedUserGroups } from '../../../../actions/userGroups/loadAssignedUserGroups';
import { loadCreatedUserGroups } from '../../../../actions/userGroups/loadCreatedUserGroups';
import { IApplicationStore } from '../../../../constants/store-interfaces';
import { IUserGroupType } from '../../../../constants/types';
import LoadingElement from '../../../simple/LoadingElement';

interface IProps {
    assigned: boolean
}

const SidebarWidgetClasses: React.FC<IProps> = props => {

    const dispatch = useDispatch()

    const createdUserGroups = useSelector((state: IApplicationStore) => state.userGroupsStore.createdUserGroups)
    const assignedUserGroups = useSelector((state: IApplicationStore) => state.userGroupsStore.assignedUserGroups)
    const loadingCreated = useSelector((state: IApplicationStore) => state.loadingStore.loadingCreatedUserGroups)
    const loadingAssigned = useSelector((state: IApplicationStore) => state.loadingStore.loadingAssignedUserGroups)
    const showMenu = useSelector((state: IApplicationStore) => state.globalStore.showMenu)

    const [userGroups, setUserGroups] = useState<IUserGroupType[]>([])

    let ugLength = userGroups.length 

    useEffect(() => {
        if(ugLength === 0) {
            if(props.assigned) {
                dispatch(loadAssignedUserGroups())
            } else {
                    dispatch(loadCreatedUserGroups())
            }
        }
    }, [props.assigned, dispatch, ugLength])

    useEffect(() => {
        let newUg = createdUserGroups

        if(props.assigned) {
            newUg = assignedUserGroups
        }

        setUserGroups(newUg.sort((a,b) => a.name.localeCompare(b.name)))
    }, [createdUserGroups, assignedUserGroups,props.assigned])

    const handleCreateNewUserGroup = () => {

        if(showMenu) {
            dispatch(setShowMenu(false))
        }
        
        dispatch(setShowCreateUserGroupModal(true))
    }

    const hideMenu = () => {
        if(showMenu) {
            dispatch(setShowMenu(false))
        }
    }

    return (
        <div className="widget">
            <div className="widgetHead">
                <div className="part">
                    <h4><Trans>Moje triedy</Trans></h4>
                </div>
                <div className="part">
                    {(!props.assigned)?(
                        <span className="href addMore" onClick={handleCreateNewUserGroup}><i className="la la-plus-circle"/></span>
                    ):(null)}
                </div>
            </div>
            <div className="widgetBody">
                {(loadingCreated || loadingAssigned)?(
                    <ul className="list">
                        <li>
                            -
                            <LoadingElement loadingValue={loadingCreated}/>
                        </li>
                    </ul>
                ):(
                    <ul className="list">
                        {(userGroups.map((userGroup, index) => (
                            <li key={index}>
                                <NavLink to={"/app/classes/" + userGroup.id} target="_self" className="tag _primary  _alt" title={userGroup.name}>
                                    <div onClick={() => hideMenu()}>
                                        {userGroup.name}
                                    </div>
                                </NavLink>
                            </li>
                        )))}
                    </ul>
                )}
                
            </div>
            <div className="widgetFooter">
            </div>
        </div>
    )
}

export default SidebarWidgetClasses
