import config from '../../config';
import { handleErrors } from '../../utils/handleErrors';
import { setLoadingRegister } from '../loadings/setLoading';
import { i18n } from '../../App';
import { addFloatingNotification } from '../notifications/floating';
import { store } from '../../store';
import { setLoginUserData } from './loginUser';
import { history } from '../../../index';

//Functions to send c reate user r equest
export function createUserAuth(data: any, token: string) {
    return (dispatch: any) => {

        dispatch(setLoadingRegister(true))
        
        return fetch(config.api.createAuthUser, {
            method: 'put',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data)
        })
            .then(handleErrors)
            .then(res => res.json())
            .then(json => {
                dispatch(setLoadingRegister(false))

                dispatch(addFloatingNotification({
                    type:"success",
                    text:i18n._('Úspešne ste dokončili registráciu. Vitajte v aplikácii Alphie.'),
                    showBell: true
                }))

                dispatch(setLoginUserData({
                    token: json.access_token?json.access_token:store.getState().globalStore.token,
                    user: json.user?json.user:data
                }))

                history.push({
                    pathname: '/app'
                })
            })
            .catch((error: any)  => {
                dispatch(setLoadingRegister(false))
                dispatch(addFloatingNotification({
                    type:"warning",
                    text:i18n._('Používateľ s týmto menom už existuje!'),
                    showBell: true
                }))
            });
    };
}