import React, {Component} from 'react';
import { connect } from 'react-redux';
import { IApplicationStore } from '../../../../../constants/store-interfaces';
import { i18n } from '../../../../../App';
import { Trans } from '@lingui/macro';
import LoadingElement from '../../../../simple/LoadingElement';
import InformationBox from '../../InformationBox';
import ConfirmModal from '../../../../simple/ConfirmModal';
import { deleteUserFromUserGroup } from '../../../../../actions/userGroups/deleteUserFromUserGroup';

interface IStateProps {
    loading: boolean
}

interface IDispatchProps {
    deleteUserFromUserGroup: typeof deleteUserFromUserGroup
}

const mapStateToProps = (state: IApplicationStore): IStateProps => ({
    loading: state.loadingStore.loadingCreatedUserGroups
})

const mapDispatchToProps = (dispatch: any): IDispatchProps => ({
    deleteUserFromUserGroup: (id: number, data: any, redirect: boolean|undefined) => dispatch(deleteUserFromUserGroup(id, data, redirect))
})

interface IState {
}

interface IProps extends IDispatchProps, IStateProps {
    showModal: boolean,
    userName: string,
    userId: number,
    userGroupId: number,
    hideModalFunction: () => any,
    redirect?: boolean
}

class ModalDeleteUserFromUserGroup extends Component<IProps, IState> {

    constructor(props: any) {
        super(props)

        this.handleRemoveUser = this.handleRemoveUser.bind(this)
    }

    handleRemoveUser() {
        let { userId, userGroupId, redirect } = this.props

        this.props.deleteUserFromUserGroup(userGroupId, {delete: true, students:[Number(userId)]}, redirect)
        this.props.hideModalFunction()
    }

    render() {
        const { hideModalFunction, showModal, loading, userName } = this.props
        
        return (
            <ConfirmModal
                confirmFunction={this.handleRemoveUser}
                closeOnConfirm={false}
                yesOption={i18n._('Odstrániť žiaka')}
                noOption={i18n._('Zrušiť')}
                title={i18n._('Odstrániť žiaka zo skupiny')}
                custom={true}
                customFunctionToggle={hideModalFunction}
                customShow={showModal}
            >
                <LoadingElement loadingValue={loading} />
                <InformationBox type={'warning'} showBell={true}>
                    <Trans>
                        Potvrdením sa natrvalo odstráni používateľ
                    </Trans>
                    &nbsp; "{userName}". &nbsp;
                    <Trans>
                        Odstránením prídete nenávratne o jeho všetky doterajšie testy a výsledky. Žiak stratí možnosť učiť sa slovíčka z Vašej triedy. Chcete ho naozaj odstrániť?
                    </Trans>
                </InformationBox>
            </ConfirmModal>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalDeleteUserFromUserGroup)