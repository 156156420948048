import React, { Component } from 'react';

interface IProps {
    className?: string
}

class ModalBody extends Component<IProps>{
    render() {
        return (
            <div className={"modal-window-body " + this.props.className}>
            
                {this.props.children}
            
            </div>
        )
    }
}

export default ModalBody