import React, {Component} from 'react'
import { connect } from 'react-redux'
import { IApplicationStore } from '../../../constants/store-interfaces';
import { INotificationFull } from '../../../constants/types';
import Notification from './Notifications/Notification';
import { deleteFloatingNotification, updateNotifications } from '../../../actions/notifications/floating';

interface IStateProps {
    floatingNotifications: INotificationFull[]
}

interface IDispatchProps {
    deleteNotification: typeof  deleteFloatingNotification
    updateNotifications: typeof updateNotifications
}

const mapStateToProps = (state: IApplicationStore):IStateProps => ({
    floatingNotifications: state.notificationsStore.floatingNotifications
})

const mapDispatchToProps = (dispatch: any):IDispatchProps => ({
    deleteNotification: (id: number) => dispatch(deleteFloatingNotification(id)),
    updateNotifications: (notif: INotificationFull[]) => dispatch(updateNotifications(notif))
})

interface IProps extends IStateProps, IDispatchProps {

}

class FloatingNotificationsContainer extends Component<IProps> {
    private interval: any

    constructor(props:any){
        super(props)

        this.deleteNotification = this.deleteNotification.bind(this)
    }

    deleteNotification(id: number) {
        this.props.deleteNotification(id)
    }

    tick() {
        const { floatingNotifications } = this.props

        this.props.updateNotifications(floatingNotifications)
    }

    componentDidMount() {
        this.interval = setInterval(() => this.tick(), 250);
    }

    componentWillUnmount() {
        clearInterval(this.interval)
    }

    render() {
        const  { floatingNotifications } = this.props

        return (
            <div className="floating-notification">
            
                {floatingNotifications.map((notif: INotificationFull, index:number) => {
                    
                    return (
                        <Notification 
                            data={notif} 
                            key={index}
                            deleteFunction={this.deleteNotification}/>
                    )
                })}
                
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FloatingNotificationsContainer)
