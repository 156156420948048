import React, {Component} from 'react';
import { connect } from 'react-redux';
import { IApplicationStore } from '../../../../../constants/store-interfaces';
import { setShowCreateTestWGModal } from '../../../../../actions/modals/setShowModal';
import { IStepperStepType, ITestSettings, ETestDifficulty, ETestType, ITestCreate, IWordgroupType, ITest } from '../../../../../constants/types';
import { i18n } from '../../../../../App';
import ModalContainer from '../../../containers/ModalContainer';
import ModalHeader from '../../../containers/ModalElements/ModalHeader';
import ModalBody from '../../../containers/ModalElements/ModalBody';
import Stepper from '../../../containers/Stepper';
import TestAlternatives from '../modalParts/TestAlternatives';
import TestSettings from '../modalParts/TestSettings';
import TestSummary from '../modalParts/TestSummary';
import { createTest } from '../../../../../actions/tests/createTest';
import { loadTests } from '../../../../../actions/tests/loadTests';
import LoadingElement from '../../../../simple/LoadingElement';
import { history } from '../../../../../..';

interface IDispatchProps {
    setShowCreateTestWGModal: typeof setShowCreateTestWGModal,
    createTest: typeof createTest,
    loadTests: typeof loadTests
}

interface IStateProps {
    showModal: boolean,
    createdTests: ITest[],
    loadingCreated: boolean
}

const mapStateToProps = (state: IApplicationStore): IStateProps => ({
    showModal: state.modalsStore.showAddTestWG,
    createdTests: state.testsStore.createdTests,
    loadingCreated: state.loadingStore.loadingCreatedTests
})

const mapDispatchToProps = (dispatch: any): IDispatchProps => ({
    setShowCreateTestWGModal: (value: boolean) => dispatch(setShowCreateTestWGModal(value)),
    createTest: (test: ITestCreate, type: 'self'|'ug') => dispatch(createTest(test, type)),
    loadTests: () => dispatch(loadTests())
})

interface IState {
    allowPrev: boolean,
    allowNext: boolean,
    test: ITestCreate
}

interface IProps extends IDispatchProps, IStateProps {
    wordgroup: IWordgroupType
}

const initState: IState = {
    allowPrev: true,
    allowNext: true,
    test: {
        valid_from: new Date().toISOString(),
        valid_until: null,
        name: '',
        usergroup_id: null,
        language_ids: {
            language_from_id: 1,
            language_to_id: 1
        },
        settings: {
            difficulty: ETestDifficulty.easy,
            type: ETestType.random,
            time: null,
            words: [],
            wordGroups: [],
            diacritic: true
        }
    }
}

class ModalAddTestWordgroup extends Component<IProps, IState> {

    constructor(props:any) {
        super(props)

        this.state = this.getInitState()

        this.handleSaveTest = this.handleSaveTest.bind(this)
        this.handleTestSettings = this.handleTestSettings.bind(this)
        this.handleTest = this.handleTest.bind(this)
        this.getInitState = this.getInitState.bind(this)
        this.openTest = this.openTest.bind(this)
    }

    getInitState(): IState {
        return {
            ...initState,
            test: {
                ...initState.test,
                name: i18n._('Cvičenie') + ' ' + this.props.wordgroup.name,
                language_ids: {
                    language_from_id: this.props.wordgroup.language_from.id,
                    language_to_id: this.props.wordgroup.language_to.id
                },
                settings: {
                    ...initState.test.settings,
                    wordGroups: [this.props.wordgroup.id],
                    words: this.props.wordgroup.words.map(w => { return {from: w.word_text, to: w.translation}})
                }
            }
        }
    }

    componentDidMount() {
        this.setState(this.getInitState())
    }

    componentDidUpdate(prevProps: IProps, ) {
        if(prevProps.showModal && !this.props.showModal) {
            this.setState(this.getInitState())
        }

        if(!prevProps.showModal && this.props.showModal) {
            this.props.loadTests()
        }
    }

    submitFormHandler(e:  any)  {
        e.preventDefault()
    }

    handleSaveTest() {
        this.props.createTest(this.state.test, 'self')
        this.props.setShowCreateTestWGModal(false)
    }

    handleTestSettings(testSettings: ITestSettings) {
        this.setState({
            ...this.state,
            test: {
                ...this.state.test,
                settings: testSettings
            }
        })
    }

    handleTest(test: ITestCreate) {
        this.setState({
            ...this.state,
            test: test
        })
    }

    openTest(id: number) {
        this.props.setShowCreateTestWGModal(false)

        history.push({
            pathname: '/app/trainings/' + id + '/detail'
        })
    }

    render() {
        let { showModal, loadingCreated, createdTests } = this.props
        let { allowPrev, allowNext, test } = this.state

        let alternatives: ITest[] = createdTests.filter(t => {
            let insert = false
            
            if(
                (t.language_from.id === test.language_ids.language_from_id) &&
                (t.language_to.id === test.language_ids.language_to_id)
            ) {
                t.settings.words.forEach(w => {
                    let was = false

                    test.settings.words.forEach(tW => {
                        if((tW.from === w.from) && (tW.to === w.to)) {
                            was = true
                            return
                        }
                    })

                    if(was) {
                        insert = true
                        return
                    }
                })
            }

            return insert
        })
        
        const steps:IStepperStepType[] = []
        
        if(alternatives.length > 0) {
            steps.push(
                {
                    name: i18n._('Výber z cvičení'),
                    step: (
                        <TestAlternatives
                            tests={alternatives}
                            openTest={this.openTest}
                        />
                    )
                }
            )
        }

        steps.push({
            name: i18n._('Nastavenia cvičenia'),
            step: (
                <TestSettings
                    test={test}
                    handleTest={this.handleTest}
                    isTraining={true}
                />
            )
        })
        steps.push({
            name: i18n._('Zhrnutie'),
            step: (
                <TestSummary
                    test={test}
                    isTraining={true}
                />
            )
        })

        return (
            <section id="mainSection">
                <ModalContainer show={showModal}>
                    <ModalHeader title={i18n._('Nové cvičenie')}/>
                    
                    <ModalBody>
                        <LoadingElement loadingValue={loadingCreated}/>
                        <form className="form _alt" onSubmit={this.submitFormHandler}>
                            <Stepper 
                                steps={steps} 
                                finishText={i18n._('Spustiť cvičenie')}
                                allowPrev={allowPrev}
                                allowNext={allowNext}
                                finalSubmit={this.handleSaveTest}
                                showInModal={true}
                            />
                        </form>
                    
                    </ModalBody>
                </ModalContainer>
            </section>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalAddTestWordgroup)