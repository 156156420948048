import React from 'react'
import { IRanking } from '../../../../constants/types';
import { useSelector } from 'react-redux';
import { IApplicationStore } from '../../../../constants/store-interfaces';
import IconWidget from './IconWidget';
import { i18n } from '../../../../App';
import { FacebookShareButton, FacebookIcon } from 'react-share';

interface IProps {
}

const WorldPlaceWidget: React.FC<IProps> = props => {
    const userInfo = useSelector((state: IApplicationStore) => state.globalStore.userInfo)
    const loadingWorld = useSelector<IApplicationStore, boolean>(state => state.loadingStore.loadingRankingWorld)
    const rankingWorld = useSelector<IApplicationStore, {user: IRanking | null, world: IRanking[]}>(state => state.rankingStore.worldRankings)
    
    let sharePath = ''

    if(userInfo !== null) {
        sharePath = btoa(encodeURI(JSON.stringify({
            'r': rankingWorld.user?.rank,
            'p': rankingWorld.user?.ranking,
            'i': 'w'
        })))
    }

    let shareBtn = (
        <FacebookShareButton
            url={'https://certificate.alphie.app/share.php?c=' + sharePath}
            quote={i18n._("Aplikácia Alphie - učenie slovíčok z ľahkosťou")}
            hashtag="#alphieapp"
            className="bttn --share"
            data-tooltip={i18n._('Zdieľať')}
        >
            <FacebookIcon size={36} />
        </FacebookShareButton>
    )

    return (
        <IconWidget
            title={((rankingWorld.user !== null)?rankingWorld.user.rank + '. ':'- ') + i18n._('miesto')}
            subtitle={i18n._('Svet')}
            icon={'la-line-chart'}
            widgetColor='green'
            loading={loadingWorld}
            subContent={shareBtn}
        />
    )
}

export default WorldPlaceWidget