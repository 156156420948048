import React from 'react'
import { ITest, ITestResult, IUserGroupType } from '../../../../constants/types';
import { i18n } from '../../../../App';
import { Trans } from '@lingui/macro';
import { NavLink } from 'react-router-dom';
import ActivityContainer from '../../../app/containers/ActivityContainer';
import { useSelector } from 'react-redux';
import { IApplicationStore } from '../../../../constants/store-interfaces';

interface IProps {
}

const ActivityWidget: React.FC<IProps> = props => {
    const loadingAssignedUsergroups = useSelector<IApplicationStore, boolean>(state => state.loadingStore.loadingAssignedUserGroups)
    const loadingCreatedUsergroups = useSelector<IApplicationStore, boolean>(state => state.loadingStore.loadingCreatedUserGroups)
    const loadingTrainings = useSelector<IApplicationStore, boolean>(state => state.loadingStore.loadingCreatedTests)
    const loadingAssignedTests = useSelector<IApplicationStore, boolean>(state => state.loadingStore.loadingAssignedTests)
    const loadingTestResults = useSelector<IApplicationStore, boolean>(state => state.loadingStore.loadingTestResults)
    const createdUserGroups = useSelector<IApplicationStore, IUserGroupType[]>(state => state.userGroupsStore.createdUserGroups)
    const userTrainings = useSelector<IApplicationStore, ITest[]>(state => state.testsStore.createdTests)
    const assignedUserGroups = useSelector<IApplicationStore, IUserGroupType[]>(state => state.userGroupsStore.assignedUserGroups)
    const userGroupsTests = useSelector<IApplicationStore, {[key: number]: ITest[]}>(state => state.testsStore.usergroupTests)
    const userTestResults = useSelector<IApplicationStore, ITestResult[]>(state => state.testsStore.testResults)

    let title = i18n._('Moja aktivita')

    return (
        <div className="row">
            <div className="row-part">
            
                <div className="widget-holder">
                    
                    <div className="widget-holder-header">
                        <div className="widget-part">
                        
                            <h2>{title}</h2>
                        
                        </div>
                        <div className="widget-part">
                        
                            <NavLink to="/app/activity" target="_self"><Trans>Zobraziť viac</Trans></NavLink>
                        
                        </div>
                    </div>
                    
                    <div className="widget-holder-body">
                        <div className="widget-part">
                        
                            <section className="widget">
                                <div className="widget-body">
                                    <div className="_part">
                                    
                                        <ActivityContainer
                                            loading={loadingCreatedUsergroups || loadingAssignedUsergroups || loadingTestResults || loadingTrainings || loadingAssignedTests || loadingTestResults}
                                            createdUserGroups={createdUserGroups}
                                            createdTrainings={userTrainings}
                                            assignedUserGroups={assignedUserGroups}
                                            userGroupTests={userGroupsTests}
                                            trainingResults={userTestResults}
                                            activityLimit={10}
                                        />
                                    
                                    </div>
                                </div>
                            </section>
                            
                        
                        </div>

                    </div>
                    
                </div>
                
            </div>
        </div>
    )
}

export default ActivityWidget