import React from 'react';
import Languages from '../parts/header/Languages';

interface IProps {

}

const HeaderLogin: React.FC<IProps> = props => {
    
    return (
        <header className="login-header transparent">
            <Languages/>
        </header>
    )
}

export default HeaderLogin
