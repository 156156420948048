import { ILanguageType } from '../../../constants/types';
import { i18n } from '../../../App';

export default class AlphieLanguages {
    static supportedLanguages = [
        'sq-AL',
        'ru-RU',
        'de-DE',
        'fr-FR',
        'it-IT',
        'en-GB',
        'pl-PL',
        'es-ES',
        'uk-UA',
        'ro-RO',
        'nl-NL',
        'hu-HU',
        'tr-TR',
        'sv-SE',
        'el-GR',
        'cs-CZ',
        'pt-PT',
        'sr-RS',
        'bg-BG',
        'da-DK',
        'fi-FI',
        'nb-NO',
        'sk-SK',
        'ca-ES',
        'lt-LT',
        'sl-SI',
        'lv-LV',
        'mk-MK',
        'et-EE',
        'en-IE',
    ]

    public static filterLanguages(langs: ILanguageType[]): ILanguageType[] {
        return langs.filter(l => {
            let fullLangName = this.convertTwoCharToFullLang(l.flag_name)

            if(fullLangName !== null) {
                return true
            } else {
                return false
            }
        })        
    }

    public static convertTwoCharToFullLang(lang: string): string|null {
        if(lang.length > 2) {
            return lang
        }

        let converted = this.transformTwoCharLangToFull(lang)

        if(converted === null) {
            return converted
        } else {
            if(this.supportedLanguages.includes(converted)) {
                return converted
            } else {
                return null
            }
        }
    }

    private static transformTwoCharLangToFull(lang: string): string|null {
        switch(lang) {
            case 'AL':
                return 'sq-AL'
            case 'RU':
                return 'ru-RU'
            case 'DE':
                return 'de-DE'
            case 'FR':
                return 'fr-FR'
            case 'IT':
                return 'it-IT'
            case 'GB':
                return 'en-GB'
            case 'PL':
                return 'pl-PL'
            case 'ES':
                return 'es-ES'
            case 'UA':
                return 'uk-UA'
            case 'RO':
                return 'ro-RO'
            case 'NL':
                return 'nl-NL'
            case 'HU':
                return 'hu-HU'
            case 'TR':
                return 'tr-TR'
            case 'SE':
                return 'sv-SE'
            case 'GR':
                return 'el-GR'
            case 'CZ':
                return 'cs-CZ'
            case 'PT':
                return 'pt-PT'
            case 'RS':
                return 'sr-RS'
            case 'BG':
                return 'bg-BG'
            case 'HR':
                return 'hr-HR'
            case 'DK':
                return 'da-DK'
            case 'FI':
                return 'fi-FI'
            case 'NO':
                return 'nb-NO'
            case 'SK':
                return 'sk-SK'
            case 'CH':
                return 'de-DE'
            case 'AD':
                return 'ca-ES'
            case 'GE':
                return 'ka-GE'
            case 'LT':
                return 'lt-LT'
            case 'SI':
                return 'sl-SI'
            case 'LV':
                return 'lv-LV'
            case 'MK':
                return 'mk-MK'
            case 'EE':
                return 'et-EE'
            case 'AZ':
                return 'az-AZ'
            case 'IS':
                return 'is-IS'
            case 'IE':
                return 'en-IE'
            default:
                return null
        }
    }

    public static getLanguageVoiceName(lang: string): string|null {
        switch(lang) {
            case 'AL':
                return 'Albanian Male'
            case 'RU':
                return 'Russian Female'
            case 'DE':
                return 'Deutsch Female'
            case 'FR':
                return 'French Female'
            case 'IT':
                return 'Italian Female'
            case 'GB':
                return 'US English Female'
            case 'PL':
                return 'Polish Female'
            case 'ES':
                return 'Spanish Female'
            case 'UA':
                return 'Ukrainian Female'
            case 'RO':
                return 'Romanian Female'
            case 'NL':
                return 'Dutch Female'
            case 'HU':
                return 'Hungarian Female'
            case 'TR':
                return 'Turkish Female'
            case 'SE':
                return 'Swedish Female'
            case 'GR':
                return 'Greek Female'
            case 'CZ':
                return 'Czech Female'
            case 'PT':
                return 'Portuguese Female'
            case 'RS':
                return 'Serbian Male'
            case 'HR':
                return 'Croatian Male'
            case 'DK':
                return 'Danish Female'
            case 'FI':
                return 'Finnish Female'
            case 'NO':
                return 'Norwegian Female'
            case 'SK':
                return 'Slovak Female'
            case 'CH':
                return 'Deutsch Female'
            case 'AD':
                return 'Catalan Male'
            case 'LV':
                return 'Latvian Male'
            case 'MK':
                return 'Macedonian Male'
            case 'EE':
                return 'Estonian Male'
            case 'IS':
                return 'Icelandic Male'
            default:
                return null
        }
    }

    public static getNameForDictionaries(lang: string): string|null {
        let language = this.convertTwoCharToFullLang(lang)

        switch(language) {
            case 'sq-AL':
                return 'albanian'
            case 'ru-RU':
                return 'russian'
            case 'de-DE':
                return 'german'
            case 'fr-FR':
                return 'french'
            case 'it-IT':
                return 'italian'
            case 'en-GB':
                return 'english'
            case 'pl-PL':
                return 'polish'
            case 'es-ES':
                return 'spanish'
            case 'uk-UA':
                return 'ukrainian'
            case 'ro-RO':
                return 'romanian'
            case 'nl-NL':
                return 'dutch'
            case 'hu-HU':
                return 'hungarian'
            case 'tr-TR':
                return 'turkish'
            case 'sv-SE':
                return 'swedish'
            case 'el-GR':
                return 'greek'
            case 'cs-CZ':
                return 'czech'
            case 'pt-PT':
                return 'portuguese'
            case 'sr-RS':
                return 'serbian'
            case 'bg-BG':
                return 'bulgarian'
            case 'da-DK':
                return 'danish'
            case 'fi-FI':
                return 'finnish'
            case 'nb-NO':
                return 'norwegian'
            case 'sk-SK':
                return 'slovak'
            case 'ca-ES':
                return 'catalan'
            case 'lt-LT':
                return 'lithuanian'
            case 'sl-SI':
                return 'slovenian'
            case 'lv-LV':
                return 'latvian'
            case 'mk-MK':
                return 'macedonian'
            case 'et-EE':
                return 'estonian'
            case 'en-IE':
                return 'irish'
        }

        return null
    }


    public static getLanguageName = (language: string):string => {    
        
        let finalName = language

        switch(language) {
            case 'Albanian':
                finalName = i18n._('Albánčina')
                break
            case 'Azerbaijani':
                finalName = i18n._('Azerbajčina')
                break
            case 'Belarusian':
                finalName = i18n._('Bieloruština')
                break
            case 'Bosnian':
                finalName = i18n._('Bosniačtina')
                break
            case 'Bulgarian':
                finalName = i18n._('Bulharčina')
                break
            case 'Catalan':
                finalName = i18n._('Katalánčina')
                break
            case 'Croatian':
                finalName = i18n._('Chorvátčina')
                break
            case 'Czech':
                finalName = i18n._('Čeština')
                break
            case 'Danish':
                finalName = i18n._('Dánština')
                break
            case 'Dutch':
                finalName = i18n._('Holandčina')
                break
            case 'English':
                finalName = i18n._('Angličtina')
                break
            case 'Estonian':
                finalName = i18n._('Estónčina')
                break
            case 'Finnish':
                finalName = i18n._('Fínština')
                break
            case 'French':
                finalName = i18n._('Francúzština')
                break
            case 'Georgian':
                finalName = i18n._('Gruzínčina')
                break
            case 'German':
                finalName = i18n._('Nemčina')
                break
            case 'Greek':
                finalName = i18n._('Gréčtina')
                break
            case 'Hungarian':
                finalName = i18n._('Maďarčina')
                break
            case 'Icelandic':
                finalName = i18n._('Islandčina')
                break
            case 'Irish':
                finalName = i18n._('Írčina')
                break
            case 'Italian':
                finalName = i18n._('Taliančina')
                break
            case 'Kazakh':
                finalName = i18n._('Kazaština')
                break
            case 'Latin':
                finalName = i18n._('Latinčina')
                break
            case 'Latvian':
                finalName = i18n._('Lotyština')
                break
            case 'Lithuanian':
                finalName = i18n._('Litovčina')
                break
            case 'Luxembourgish':
                finalName = i18n._('Luxemburčina')
                break
            case 'Macedonian':
                finalName = i18n._('Macedónčina')
                break
            case 'Maltese':
                finalName = i18n._('Maltčina')
                break
            case 'Norwegian':
                finalName = i18n._('Nórčina')
                break
            case 'Polish':
                finalName = i18n._('Poľština')
                break
            case 'Portuguese':
                finalName = i18n._('Portugalčina')
                break
            case 'Romanian':
                finalName = i18n._('Rumunština')
                break
            case 'Russian':
                finalName = i18n._('Ruština')
                break
            case 'Serbian':
                finalName = i18n._('Srbština')
                break
            case 'Slovak':
                finalName = i18n._('Slovenčina')
                break
            case 'Slovene':
                finalName = i18n._('Slovinčina')
                break
            case 'Spanish':
                finalName = i18n._('Španielčina')
                break
            case 'Swedish':
                finalName = i18n._('Švédčina')
                break
            case 'Swiss German':
                finalName = i18n._('Švajčiarska nemčina')
                break
            case 'Turkish':
                finalName = i18n._('Turečtina')
                break
            case 'Ukrainian':
                finalName = i18n._('Ukrajinčina')
                break
        }

        return finalName
    }
}