import React, {Component} from 'react';
import { connect } from 'react-redux';
import { IApplicationStore } from "../../../constants/store-interfaces";
import { IWordgroupType, ITableHeaderCellType, ITableRowType, ITableCellType, IActionType, IWordCreatedType, IWordChecked, ILanguageType, ITabs, ITabsAction, ITestResult, ITest, IUWordGroupType } from '../../../constants/types';
import TableContainer from '../containers/TableContainer';
import { Trans } from '@lingui/macro';
import { i18n } from '../../../App';
import { loadCreatedWords } from '../../../actions/words/loadCreatedWords';
import { NavLink } from 'react-router-dom';
import TranslateLanguages from '../../simple/TranslateLanguages';
import ModalEditWord from '../parts/modals/word/ModalEditWord';
import { setShowEditWordModal, setShowCreateTestModal } from '../../../actions/modals/setShowModal';
import ModalDeleteWords from '../parts/modals/word/ModalDeleteWords';
import { setShowConfirmModal, setShowCreateWordModal } from '../../../actions/modals/setShowModal';
import { deleteWord } from '../../../actions/words/deleteWord';
import { editWord } from '../../../actions/words/editWord';
import { loadCreatedWordGroups } from '../../../actions/wordGroups/loadCreatedWordGroups';
import VoiceClass from '../../simple/classes/voiceClass';
import TabsContainer from '../containers/TabsContainer';
import { loadTestResults } from '../../../actions/tests/loadTestResults';
import { loadTests } from '../../../actions/tests/loadTests';
import { ReactNode } from 'react';
import CircularGraph from '../../simple/widgets/CircularGraph';
import { withRouter, RouteComponentProps } from 'react-router';
import { loadAssignedWordGroups } from '../../../actions/wordGroups/loadAssignedWordGroups';
import moment from 'moment';
import { loadAssignedTests } from '../../../actions/tests/loadAssignedTests';
import ReactGA from 'react-ga';

interface IStateProps {
    createdWords: IWordCreatedType[],
    createdWordGroups: IWordgroupType[]
    loading: boolean,
    loadingCreatedWordGroups: boolean,
    loadingTestResults: boolean,
    loadingAssignedWordGroups: boolean,
    loadingAssigned: boolean,
    assignedTests: ITest[],
    loadingTests: boolean,
    testResults: ITestResult[],
    createdTests: ITest[],
    assignedWordGroups: IUWordGroupType[]
}

interface IDispatchProps {
    loadCreatedWords: typeof loadCreatedWords,
    loadAssignedWordGroups: typeof loadAssignedWordGroups,
    loadAssignedTests: typeof loadAssignedTests,
    setShowCreateWordModal: typeof setShowCreateWordModal,
    setShowEditWordModal: typeof  setShowEditWordModal,
    setShowDeleteWordModal: typeof  setShowConfirmModal,
    deleteWord: typeof deleteWord,
    editWord: typeof editWord,
    loadCreatedWordGroups: typeof loadCreatedWordGroups,
    setShowCreateTestModal: typeof setShowCreateTestModal,
    loadTestResults: typeof loadTestResults,
    loadTests: typeof loadTests
}

const mapStateToProps = (state: IApplicationStore): IStateProps => ({
    createdWords: state.wordsStore.createdWords,
    createdWordGroups: state.wordGroupsStore.createdWordgroups,
    loading: state.loadingStore.loadingCreatedWords,
    loadingCreatedWordGroups: state.loadingStore.loadingCreatedWordGroups,
    loadingAssignedWordGroups: state.loadingStore.loadingAssignedWordGroups,
    loadingTestResults: state.loadingStore.loadingTestResult,
    assignedTests: state.testsStore.assignedTests,
    loadingAssigned: state.loadingStore.loadingAssignedTests,
    loadingTests: state.loadingStore.loadingCreatedTests,
    testResults: state.testsStore.testResults,
    createdTests: state.testsStore.createdTests,
    assignedWordGroups: state.wordGroupsStore.assignedWordgroups
})

const mapDispatchToProps = (dispatch: any): IDispatchProps => ({
    loadCreatedWords: () => dispatch(loadCreatedWords()),
    loadAssignedWordGroups: () => dispatch(loadAssignedWordGroups()),
    loadAssignedTests: () => dispatch(loadAssignedTests()),
    setShowCreateWordModal: (value: boolean) => dispatch(setShowCreateWordModal(value)),
    setShowEditWordModal: (value: boolean) => dispatch(setShowEditWordModal(value)),
    setShowDeleteWordModal: (value: boolean) => dispatch(setShowConfirmModal(value)),
    deleteWord: (ids: number[]) => dispatch(deleteWord(ids)),
    editWord: (id: number, data: {word_text: string,translation:string}) => dispatch(editWord(id, data)),
    loadCreatedWordGroups: () => dispatch(loadCreatedWordGroups()),
    setShowCreateTestModal: (val: boolean) => dispatch(setShowCreateTestModal(val)),
    loadTestResults: () => dispatch(loadTestResults()),
    loadTests: () => dispatch(loadTests())
})

interface IProps extends IStateProps, IDispatchProps, RouteComponentProps {

}

interface  IState {
    checkedWords: IWordChecked[],
    selectedEditWord: IWordCreatedType | null,
    selectedDeleteWord: IWordCreatedType | null
}

class WordsPage extends Component<IProps, IState> {

    constructor(props: any) {
        super(props)

        this.state =  {
            checkedWords: [],
            selectedEditWord: null,
            selectedDeleteWord: null
        }

        ReactGA.pageview('Zoznam slovíčok')

        this.handleEditWord = this.handleEditWord.bind(this)
        this.handleDeleteWord = this.handleDeleteWord.bind(this)
        this.handleDeleteWords = this.handleDeleteWords.bind(this)
        this.handleCheckedWords = this.handleCheckedWords.bind(this)
        this.handleCreateWord = this.handleCreateWord.bind(this)
        this.confirmDeleteWord = this.confirmDeleteWord.bind(this)
        this.confirmDeleteWords = this.confirmDeleteWords.bind(this)
        this.confirmEditWord = this.confirmEditWord.bind(this)
        this.openStatistic = this.openStatistic.bind(this)
        this.getRows = this.getRows.bind(this)
    }

    componentDidMount() {
        this.props.loadCreatedWords()
        this.props.loadCreatedWordGroups()
        this.props.loadAssignedWordGroups()
        this.props.loadAssignedTests()
        this.props.loadTestResults()
    }

    handleCreateWord() {
        this.props.setShowCreateWordModal(true)
    }

    handleEditWord(catchString: string) {
        let idParts = catchString.split('_')
        let wordId  = Number(idParts[1])

        let selectedWord = this.props.createdWords.find((word: IWordCreatedType) => word.id === wordId)

        if(typeof  selectedWord !==  'undefined') {
            this.setState({
                ...this.state,
                selectedEditWord: selectedWord
            })

            this.props.setShowEditWordModal(true)
        }
        
    }

    handleDeleteWord(catchString: string)  {
        let idParts = catchString.split('_')
        let wordId  = Number(idParts[1])

        let selectedWord = this.props.createdWords.find((word: IWordCreatedType) => word.id === wordId)

        if(typeof  selectedWord !==  'undefined') {
            this.setState({
                ...this.state,
                selectedDeleteWord: selectedWord
            })

            this.props.setShowDeleteWordModal(true)
        }
    }

    handleDeleteWords()  {
        if(this.state.selectedDeleteWord !== null) {
            this.setState({
                ...this.state,
                selectedDeleteWord: null
            })
        }

        this.props.setShowDeleteWordModal(true)
    }

    handleCheckedWords(catchStrings:  string[])  {
        const { createdWords } = this.props

        let checkedWords: IWordChecked[] =  []

        for(let catched  of catchStrings) {
            let id = Number(catched.split('_')[1])

            let word = createdWords.find((word) => word.id === id)

            if(typeof word !== 'undefined') {
                checkedWords.push({
                    id: word.id,
                    word_text: word.word_text,
                    translation: word.translation,
                    language_from: word.language_from,
                    language_to: word.language_to
                })
            }
        }

        checkedWords =Array.from(new Set(checkedWords))

        this.setState({
            ...this.state,
            checkedWords: checkedWords
        })
    }

    confirmDeleteWord() {
        const { selectedDeleteWord } = this.state

        if(selectedDeleteWord !== null) {
            this.props.deleteWord([selectedDeleteWord.id])
        }
    }

    confirmDeleteWords() {
        const { checkedWords  } = this.state

        let wordsToDelete: number[] = []

        for(let word of checkedWords) {
            wordsToDelete.push(word.id)
        }

        if(wordsToDelete.length > 0) {
            this.props.deleteWord(wordsToDelete)
        }
    }

    confirmEditWord(id: number, word_text: string, translation: string) {
        const { selectedEditWord } = this.state
        
        if(selectedEditWord  !== null) {
            this.props.editWord(id, {word_text: word_text, translation: translation})
        }
    }

    getRows(words: IWordCreatedType[], assignedWGs: IUWordGroupType[]): ITableRowType[] {
        let rows: ITableRowType[] = []

        let newWords: IWordCreatedType[] = []

        words.forEach(i => {
            if(typeof newWords.find(j => j.id === i.id) == 'undefined') {
                newWords.push(i)
            }
        })

        for(let word of newWords) {
            let cells: ITableCellType[] = []

            let selectableWordGroups: any[] = []
            let wordGroupsStringArray: string[] = []
            let wordGroupsString: string = ''

            let wordWGs: any[] = []

            for(let wordgroup of word.wordgroups) {
                let assignedWG = assignedWGs.find(wg => wg.id === wordgroup.id)

                let assign = true

                if(typeof assignedWG !== 'undefined') {
                    if(moment(assignedWG.valid_from).isAfter(moment())) {
                        assign = false
                    }
                }

                if(assign) {

                    selectableWordGroups.push({
                        object: (<span>{wordgroup.name}</span>),
                        value: wordgroup.name
                    })

                    wordWGs.push(wordgroup)

                    wordGroupsStringArray.push(wordgroup.name)
                }
            }

            wordGroupsString = wordGroupsStringArray.sort().join('')

            let availableVoiceFrom = true
            let availableVoiceTo = true
            let speechColumnFrom: ReactNode = (null)
            let speechColumnTo: ReactNode = (null)

            if(VoiceClass.getInstance(word.language_from.flag_name, '').isAvailable()) {
                speechColumnFrom = (
                    <i className="la la-volume-up" onClick={() => this.playWord(word.word_text, word.language_from)} data-tooltip={i18n._('Prehrať výslovnosť')}/>
                )
            } else {
                speechColumnFrom = (
                    <i className="la la-frown-o" data-tooltip={i18n._('Výslovnosť nedostupná')}/>
                )
            }
            
            if(VoiceClass.getInstance(word.language_to.flag_name, '').isAvailable()) {
                speechColumnTo = (
                    <i className="la la-volume-up" onClick={() => this.playWord(word.translation, word.language_to)} data-tooltip={i18n._('Prehrať výslovnosť')}/>
                )
            } else {
                speechColumnTo = (
                    <i className="la la-frown-o" data-tooltip={i18n._('Výslovnosť nedostupná')}/>
                )
            }

            let showActions = true

            // if(wordWGs.length) {
                // showActions = false
                
                // let createdWG = this.props.createdWordGroups

                // for(let wWg of word.wordgroups) {
                //     for(let wg of createdWG) {
                //         if(wg.id === wWg.id) {
                //             showActions = true
                //         }
                //     }
                // }

                cells = [
                    {
                        content: (<div className={"word " + ((availableVoiceFrom)?'_voice':'')} data-title={i18n._('Slovo')}>{word.word_text} {speechColumnFrom}</div>),
                        stringToOrder:  word.word_text,
                        filterString:  word.word_text,
                        filterByString:  true
                    },
                    {
                        content: (<div className={"word " + ((availableVoiceTo)?'_voice':'')} data-title={i18n._('Preklad')}>{word.translation} {speechColumnTo}</div>),
                        stringToOrder:  word.translation,
                        filterString:  word.translation,
                        filterByString: true
                    },
                    {
                        content: (
                            <div className="groups">
                                {
                                    word.wordgroups.map((wordgroup: IWordgroupType, wgIndex: number) => (
                                        <NavLink key={wgIndex} to={'/app/wordgroups/' + ((!showActions)?'assigned/':'')+ wordgroup.id} className="tag _silent">
                                            {wordgroup.name}
                                        </NavLink>
                                    ))
                                }
                            </div>
                        ),
                        stringToOrder: wordGroupsString,
                        filterString: wordGroupsString,
                        filterByString: false,
                        selectableFilter: true,
                        selectableName: i18n._('vybrať'),
                        selectableAllText: i18n._('Všetky'),
                        selectableTitle: i18n._('Skupiny slovíčok'),
                        selectableObject: selectableWordGroups,
                        hideClass: 'hide-s'
                    },
                    {
                        content: (
                            <TranslateLanguages
                                languageFrom={word.language_from}
                                languageTo={word.language_to}
                            />
                        ),
                        stringToOrder:  word.language_from.flag_name + word.language_to.flag_name,
                        filterString: word.language_from.flag_name + word.language_to.flag_name,
                        filterByString: false,
                        selectableFilter: true,
                        selectableName: i18n._('vybrať'),
                        selectableAllText: i18n._('Všetky'),
                        selectableTitle: i18n._('Preklad'),
                        selectableObject: [
                            {
                                object: (
                                        <TranslateLanguages
                                            languageFrom={word.language_from}
                                            languageTo={word.language_to}
                                        />
                                ),
                                value: word.language_from.flag_name + word.language_to.flag_name
                            }
                        ]
                    }
                ]
    
                rows.push({
                    cells: cells,
                    catchString: '_' +  word.id,
                    showAction: showActions
                })
            // }
        }

        return rows
    }

    getHeaders(): ITableHeaderCellType[] {
        return [
            {
                content: (<span><Trans>Slovo</Trans></span>),
                sortBy: false,
                sortable: true
            },
            {
                content: (<span><Trans>Preklad</Trans></span>),
                sortBy: false,
                sortable: true
            },
            {
                content: (<span><Trans>Skupina</Trans></span>),
                sortBy: false,
                sortable: true,
                hideClass: 'hide-s'
            },
            {
                content: (<span><Trans>Jazyk</Trans></span>),
                sortBy: false,
                sortable: true,
                hideClass: 'hide-s'
            }
        ]
    }

    getActions(): IActionType[] {

        return [
            {
                action: this.handleEditWord,
                name: i18n._('Upraviť')
            },
            {
                action: this.handleDeleteWord,
                name: i18n._('Odstrániť'),
                customClass: 'remove'
            }
        ]
    }

    getHeadersStatistics(): ITableHeaderCellType[] {
        return [
            {
                content: (<span><Trans>Slovo</Trans></span>),
                sortBy: false,
                sortable: false
            },
            {
                content: (<span><Trans>Preklad</Trans></span>),
                sortBy: false,
                sortable: false
            },
            {
                content: (<span><Trans>Jazyk</Trans></span>),
                sortBy: false,
                sortable: false
            },
            {
                content: (<span><Trans>Úspešnosť</Trans></span>),
                sortBy: true,
                sortable: false,
                sortableType: 'number'
            },
        ]
    }

    getRowsStatistic(words:  IWordCreatedType[], results: ITestResult[], tests: ITest[], type: 'good'|'bad'): ITableRowType[] {
        let rows: ITableRowType[] = []

        let newWords: IWordCreatedType[] = []

        interface IStatisticType {from: string, to: string, languageFrom: ILanguageType, languageTo: ILanguageType, valid: number, count: number}

        words.forEach(i => {
            if(typeof newWords.find(j => j.id === i.id) == 'undefined') {
                newWords.push(i)
            }
        })

        let wordStatistic: IStatisticType[] = []

        newWords.forEach(w => {
            let alreadyIs = wordStatistic.find(s => ((s.from === w.word_text) && (s.to === w.translation) && (s.languageFrom.id === w.language_from.id) && (s.languageTo.id === w.language_to.id)))

            if(typeof alreadyIs === 'undefined') {
                alreadyIs = {
                    from: w.word_text,
                    to: w.translation,
                    languageFrom: w.language_from,
                    languageTo: w.language_to,
                    valid: 0,
                    count: 0
                }
                wordStatistic.push(alreadyIs)
            }
        })

        results.forEach(r => {
            if(r.result !== null) {
                let test = tests.find(t => t.id === r.test_id)

                if(typeof test !== 'undefined') {
                    r.result.questions.forEach(q => {
                        let word = wordStatistic.find(w => (w.from === q.word.from) && (w.to === q.word.to) && (w.languageFrom.id === test?.language_from.id) && (w.languageTo.id === test?.language_to.id))

                        if(typeof word !== 'undefined') {
                            word.count += 1
                            word.valid += (q.valid)?1:0
                        }
                    })
                }
            }
        })

        let finalWordStatistic: IStatisticType[] = []

        let percentages: number[] = []

        wordStatistic.forEach(wS => {
            if(wS.count > 0) {
                percentages.push(Math.round(wS.valid/wS.count*100))
            }
        })

        let average = percentages.reduce(function(sum, a) { return sum + Number(a) }, 0) / (percentages.length || 1)

        wordStatistic.forEach(wS => {
            let percentage = Math.round(wS.valid/wS.count*100)

            if(type === 'good') {
                if(percentage >= average) {
                    finalWordStatistic.push(wS)
                }
            } else {
                if(percentage < average) {
                    finalWordStatistic.push(wS)
                }
            }
        })

        for(let word  of finalWordStatistic)  {
            let cells: ITableCellType[] = []

            let percentage = 0
            let percentageResult: ReactNode = (null)
            if(word.count > 0) {
                percentage = Math.round(word.valid/word.count*100)
                percentageResult = (<CircularGraph percentage={percentage}/>)

                cells = [
                    {
                        content: (<div className="word" data-title={i18n._('Slovo')}>{word.from}</div>),
                        stringToOrder:  word.from,
                        filterString:  word.from,
                        filterByString:  true
                    },
                    {
                        content: (<div className="word" data-title={i18n._('Preklad')}>{word.to}</div>),
                        stringToOrder:  word.to,
                        filterString:  word.to,
                        filterByString: true
                    },
                    {
                        content: (
                            <TranslateLanguages
                                languageFrom={word.languageFrom}
                                languageTo={word.languageTo}
                            />
                        ),
                        stringToOrder:  word.languageFrom.flag_name + word.languageTo.flag_name,
                        filterString: word.languageFrom.flag_name + word.languageTo.flag_name,
                        filterByString: false,
                        selectableFilter: true,
                        selectableName: i18n._('vybrať'),
                        selectableAllText: i18n._('Všetky'),
                        selectableTitle: i18n._('Preklad'),
                        selectableObject: [
                            {
                                object: (
                                        <TranslateLanguages
                                            languageFrom={word.languageFrom}
                                            languageTo={word.languageTo}
                                        />
                                ),
                                value: word.languageFrom.flag_name + word.languageTo.flag_name
                            }
                        ]
                    },
                    {
                        content: (percentageResult),
                        stringToOrder: percentage.toString(),
                        filterByString: true,
                        filterString: percentage.toString()
                    }
                ]

                rows.push({
                    cells: cells,
                    catchString: '_' +  word.from + '_' + word.to
                })
            }
        }

        return rows
    }

    playWord = (word: string, language: ILanguageType) => {
        let voice: VoiceClass = VoiceClass.getInstance(language.flag_name, word)

        voice.speak(true)
    }

    openStatistic() {
        this.props.loadTestResults()
        this.props.loadTests()
    }

    render() {
        const { createdWords, loading, loadingCreatedWordGroups, loadingTests, loadingTestResults, createdTests, testResults, assignedWordGroups, assignedTests } = this.props
        const { selectedEditWord, selectedDeleteWord, checkedWords } = this.state

        let headers: ITableHeaderCellType[] = this.getHeaders()
        let rows: ITableRowType[] = this.getRows(createdWords, assignedWordGroups)
        let actions: IActionType[] = this.getActions()

        let headersStatistic: ITableHeaderCellType[] = this.getHeadersStatistics()
        let rowsStatisticGood: ITableRowType[] = this.getRowsStatistic(createdWords, testResults, createdTests.concat(assignedTests), 'good')
        let rowsStatisticBad: ITableRowType[] = this.getRowsStatistic(createdWords, testResults, createdTests.concat(assignedTests), 'bad')

        let tableTabs: ITabs[] = [
            {
                header: {
                    icon: 'la-list-alt',
                    title: i18n._('Všetky slovíčka'),
                    titleTab: i18n._('Všetky moje slovíčka')
                },
                content: (
                    <TableContainer
                        headers={headers}
                        rows={rows}
                        actions={actions}
                        showActions={true}
                        showCheckbox={true}
                        checkboxFunction={this.handleCheckedWords}
                        showFilter={true}
                        pageSize={10}
                        title={i18n._('Moje slovíčka')}
                        showNew={true}
                        newTitle={i18n._('Pridať slovo')}
                        newFunction={this.handleCreateWord}
                        emptyText={i18n._('Zatiaľ nemáte vytvorené žiadne slová')}
                        emptySubtitle={i18n._('Ak si chcete pridať vlastné slová, stačí stlačiť tlačidlo "pridať slovo".')}
                        loading={(loading || loadingCreatedWordGroups)}
                        showGrid={false}
                        mobileFixedGrid={true}
                        footer={(
                            <div className="part">
                                {(checkedWords.length > 0)?(
                                    <div 
                                        className="bttn _icon _small _red" 
                                        title={i18n._('Odstrániť')}
                                        onClick={this.handleDeleteWords}
                                        data-tooltip={i18n._("Odstrániť vybraté slovíčka")}
                                    >
                                        <i className="la la-trash-o"/>
                                    </div>
                                ):(null)}
                                
                            </div>
                        )}
                    />
                )
            },
            {
                header: {
                    icon: 'la-bar-chart',
                    title: i18n._('Moja štatistika'),
                    titleTab: i18n._('Štatistika z mojích slovíčok'),
                    tutorialClass: 'step14'
                },
                content: (
                    <div className="rows">
                        <div className="row">
                            <div className="row-part gray-part">
                                <TableContainer
                                    headers={headersStatistic}
                                    rows={rowsStatisticGood}
                                    actions={[]}
                                    showActions={false}
                                    showCheckbox={false}
                                    showFilter={true}
                                    showHeader={true}
                                    pageSize={5}
                                    title={i18n._('Ľahké slovíčka')}
                                    showNew={false}
                                    emptyText={(rowsStatisticBad.length + rowsStatisticGood.length > 0)?i18n._('Nemáte žiadne ľahké slovíčka.'):i18n._('Zatiaľ ste netestovali žiadne slovíčka')}
                                    emptySubtitle={(rowsStatisticBad.length + rowsStatisticGood.length > 0)?i18n._('Mali by ste na sebe popracovať!'):i18n._('Ak si chcete zobraziť svoju štatistiku, stačí spustiť aspoň jeden test.')}
                                    loading={(loadingTestResults || loadingTests)}
                                    showGrid={false}
                                    defaultSort='desc'
                                    mobileFixedGrid={true}
                                />
                            </div>
                            <div className="row-part gray-part">
                                <TableContainer
                                    headers={headersStatistic}
                                    rows={rowsStatisticBad}
                                    actions={[]}
                                    showActions={false}
                                    showCheckbox={false}
                                    showFilter={true}
                                    showHeader={true}
                                    pageSize={5}
                                    title={i18n._('Ťažké slovíčka')}
                                    showNew={false}
                                    emptyText={(rowsStatisticBad.length + rowsStatisticGood.length > 0)?i18n._('Nemáte žiadne ťažké slovíčka.'):i18n._('Zatiaľ ste netestovali žiadne slovíčka')}
                                    emptySubtitle={(rowsStatisticBad.length + rowsStatisticGood.length > 0)?i18n._('Gratulujeme!'):i18n._('Ak si chcete zobraziť svoju štatistiku, stačí spustiť aspoň jeden test.')}
                                    loading={(loadingTestResults || loadingTests)}
                                    showGrid={false}
                                    mobileFixedGrid={true}
                                />
                            </div>
                        </div>
                    </div>
                ),
                onFirstOpen: () => this.openStatistic()
            }
        ]

        let tableFooterActions: ITabsAction[] = [
            {
                icon:'la-plus-circle',
                title:i18n._('Nové slovo'),
                action: this.handleCreateWord
            }
        ]

        return (
            <div className="rows" data-tour="step12">
                <div className="row">
                    <div className="row-part">
                        <div className="page-header">
                            <div className="part">
                                <h2><Trans>Všetky moje slovíčka</Trans></h2>
                                <p><Trans>Zoznam všetkých slovíčok, ktoré som si vytvoril, alebo som ich dostal priradené</Trans></p>
                            </div>
                            <div className="part">
                                <div className="bttn _small _primary _icon" onClick={() => this.handleCreateWord()} data-tooltip={i18n._("Nové slovo")} data-tour="step13">
                                    <i className="la la-plus-circle"/>
                                </div>
                                <button 
                                    className="bttn _small _green _icon"
                                    onClick={() => this.props.setShowCreateTestModal(true)}
                                >
                                    <i className="la la-mortar-board"/>
                                    <Trans>Vytvoriť cvičenie</Trans>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="row-part">
                        <TabsContainer
                            tabs={tableTabs}
                            footerActions={tableFooterActions}
                        />
                    </div>
                </div>
                
                {(selectedEditWord !== null)?(
                    <ModalEditWord 
                        id={selectedEditWord.id}
                        word_text={selectedEditWord.word_text}
                        translation={selectedEditWord.translation}
                        language_from={selectedEditWord.language_from}
                        language_to={selectedEditWord.language_to}
                        confirmFunction={this.confirmEditWord}
                    />
                ):(null)}
                {(selectedDeleteWord !== null)?(
                    <ModalDeleteWords
                        words={[{
                            id:  selectedDeleteWord.id,
                            word_text: selectedDeleteWord.word_text,
                            translation: selectedDeleteWord.translation,
                            language_from: selectedDeleteWord.language_from,
                            language_to: selectedDeleteWord.language_to
                        }]}
                        confirmFunction={this.confirmDeleteWord}
                    />
                ):(null)}

                {((checkedWords.length > 0) && (selectedDeleteWord == null))?(
                    <ModalDeleteWords
                        words={checkedWords}
                        confirmFunction={this.confirmDeleteWords}
                    />
                ):(null)}
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(WordsPage))