import React, {Component} from 'react';
import InformationBox from '../../InformationBox';
import { Trans } from '@lingui/macro';

interface IProps {
    isTeacher: boolean
    isHistory?: boolean
}

interface IState {
}

class AssignedHomeworkInfoPart extends Component<IProps, IState> {

    render() {
        const { isTeacher, isHistory } = this.props


        let showText = (
            <InformationBox type='info' showBell={true}>
                <Trans>Pre zlepšenie učenia slovíčok môžete Vaším žiakom priradiť domáce úlohy vytvorené z priradených skupín slovíčok.</Trans>
                <br/>
                <strong>
                    <Trans>Môžete vidieť ich úspešnosť vo vykonávaní domácich úloh, ich štatistiku, ktoré slovíčka im robia problémy, a akú majú úspešnosť pri jednotlivých slovíčkach.</Trans>
                </strong>
            </InformationBox>
        )

        if(!isTeacher) {
            showText = (
                <InformationBox type='info' showBell={true}>
                    <Trans>Zoznam domácich úloh, ktoré ste dostali pridelené od svojho učiteľa. Prostredníctvom domácich úloh zlepšujete svoju slovnú zásobu a učíte sa nové slovíčka.</Trans>
                    <br/>
                    <strong>
                        <Trans>Domáce úlohy môžete vykonávať viackrát a učiť sa tak koľko len chcete.</Trans>   
                    </strong> 
                </InformationBox>
            )

            if( (typeof isHistory !== 'undefined') && (isHistory) ) {
                showText = (
                    <InformationBox type='info' showBell={true}>
                        <Trans>Zoznam starších domácich úloh, ktoré ste dostali pridelené od svojho učiteľa, ale skončila im platnosť. Môžete si pozrieť svoje staršie výsledky, ako sa Vám darilo v učení slovíčok.</Trans>
                        <br/>
                        <strong>
                            <Trans>Tieto domáce úlohy sú už neaktívne.</Trans>   
                        </strong> 
                    </InformationBox>
                )
            }
        }

        return (
            <div className="row">
                <div className="row-part">
                    {showText}
                </div>
            </div>
        )
    }
}

export default AssignedHomeworkInfoPart