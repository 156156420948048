import React, {Component} from 'react'
import { IWordChecked } from '../../../../../constants/types';
import ConfirmModal from '../../../../simple/ConfirmModal';
import { IApplicationStore } from '../../../../../constants/store-interfaces';
import { connect } from 'react-redux';
import ReactCountryFlag from 'react-country-flag';
import { IoIosArrowRoundForward } from 'react-icons/io';
import { Trans } from '@lingui/macro';
import { setShowConfirmModal } from '../../../../../actions/modals/setShowModal';
import InformationBox from '../../InformationBox';


interface IStateProps {
}

interface IDispatchProps {
    setShowConfirmModal: typeof setShowConfirmModal,
}

const mapStateToProps = (state: IApplicationStore): IStateProps => ({
})

const mapDispatchToProps = (dispatch: any): IDispatchProps => ({
    setShowConfirmModal: (value: boolean) =>  dispatch(setShowConfirmModal(value)),
})

interface IProps extends IStateProps, IDispatchProps {
    words: IWordChecked[],
    confirmFunction: () => any
}

interface IState {
}

class ModalDeleteWords extends Component<IProps, IState> {
    constructor(props:  any)  {
        super(props)

        this.deleteWord = this.deleteWord.bind(this)
    }

    deleteWord() {
        this.props.confirmFunction()
        this.props.setShowConfirmModal(false)
    }

    render() {
        const { words } = this.props
        
        return (
            <ConfirmModal
                confirmFunction={this.deleteWord}
            >
                <div className="input-row">
                    <h4 className="headline-divider">
                        {(words.length === 1)?(
                            <Trans>Naozaj chcete odstrániť toto slovo?</Trans>
                        ):(
                            <Trans>Naozaj chcete odstrániť tieto slová?</Trans>
                        )}
                    </h4>
                </div>
                <InformationBox type='warning' showBell={true} showClose={false}>
                    {(words.length === 1)?(
                        <Trans>Ak je slovo priradené v skupine slov, odstráni sa aj z týchto skupín!</Trans>
                    ):(
                        <Trans>Ak sú slová priradené v skupine slov, odstránia sa aj z týchto skupín!</Trans>
                    )}
                </InformationBox>
                {words.map((word:IWordChecked, index: number) => (
                    <div className="input-row" key={index}>
                        <div className="part add-words">
                            <div className="language-selects">
                                <div className="language-selects-part">
                                    <div className="part-text"> 
                                        <div className="lang margin-center">
                                            <div className="part">
                                                <ReactCountryFlag className="flag" countryCode={word.language_from.flag_name} svg/>
                                            </div>
                                            <div className="part">
                                                {word.word_text}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="language-selects-part _arrow">
                                    <IoIosArrowRoundForward className="icon"/>
                                </div>
                                <div className="language-selects-part">
                                    <div className="part-text"> 
                                        <div className="lang margin-center">
                                            <div className="part">
                                                <ReactCountryFlag className="flag" countryCode={word.language_to.flag_name} svg/>
                                            </div>
                                            <div className="part">
                                                {word.translation}
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                            </div>
                        </div>
                    </div>
                ))}  
            </ConfirmModal>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalDeleteWords)