import React from 'react'
import { ITestResult, ITest, IUSerInfo } from '../../../../../constants/types';
import { IApplicationStore } from '../../../../../constants/store-interfaces';
import { useSelector } from 'react-redux';
import { Trans } from '@lingui/macro';
import TestClass from '../../../classes/testClass';
import Percentage from '../../../tableElements/Percentage';
import { i18n } from '../../../../../App';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import CountDownAction from '../../../tableElements/CountDownAction';
import CountDownDate from '../../../tableElements/CountDownDate';
import TranslateLanguages from '../../../TranslateLanguages';

interface IProps {
    results: ITestResult[]
    test: ITest
}

const TestDetailUser: React.FC<IProps> = props => {
    const userInfo = useSelector<IApplicationStore, IUSerInfo | null>(state => state.globalStore.userInfo)

    let results: ITestResult[] = []
    
    if(userInfo !== null) {
        results = props.results.filter(r => (r.user_id === userInfo.id) && (r.test_id === props.test.id))
    }

    let percentage:number|null = null
    let percentageContent = (null)

    if(results.length) {
        let cntPoints = 0
        let maxPoints = 0

        results.forEach(tR => {
            let testObject = new TestClass(props.test, tR)
            
            cntPoints += testObject.getTestRate()
            maxPoints += testObject.getMaximumRate()
        })

        percentage = Math.round(Number(cntPoints)/maxPoints*100)
    }

    if(percentage !== null) {
        percentageContent = (<Percentage percentage={percentage} title={i18n._('Úspešnosť')}/>)
    }

    let isValid = true

    let type: 'active'|'inactive' = 'active'

    if(moment(props.test.valid_from).isAfter(moment())) {
        isValid = false
    }   

    if((props.test.valid_until !== null) && (moment(props.test.valid_until).isBefore(moment()))) {
        isValid = false
        type = 'inactive'
    }

    let actions = (
        <div className="part">
            <CountDownAction date={props.test.valid_from}>
                <NavLink to={"/app/classes/" + props.test.usergroup_id + "/tests/" + props.test.id} target="_self" className="tag _green _alt">
                    <Trans>Spustiť</Trans>
                </NavLink>
            </CountDownAction>
        </div>
    )

    if(isValid) {
        if(results.length) {
            actions = (
                <div className="part">
                    <NavLink to={"/app/classes/" + props.test.usergroup_id + "/tests/" + props.test.id + "/detail"} target="_self" className="tag _primary _alt">
                        <Trans>Detail</Trans>
                    </NavLink>
                </div>
            )
        } else {
            actions = (
                <div className="part">
                    <NavLink to={"/app/classes/" + props.test.usergroup_id + "/tests/" + props.test.id} target="_self" className="tag _green _alt">
                        <Trans>Spustiť</Trans>
                    </NavLink>
                </div>
            )
        }
    }

    if(type === 'inactive') {
        actions = (
            <div className="part">
                <NavLink to={"/app/classes/" + props.test.usergroup_id + "/tests/" + props.test.id + "/detail"} target="_self" className="tag _primary _alt">
                    <Trans>Detail</Trans>
                </NavLink>
            </div>
        )
    }

    return (    
        <section className="item">
            <header className="item_header">
                <div className="part">
                    <h3>{props.test.name}</h3>
                </div>
            </header>
            <div className="item_body">

                <div className="item-types">
                    <ul>
                        <li>
                            
                            <div className="type">
                                <div className="type_title">
                                    <Trans>Termín písomky</Trans>
                                </div>
                                <div className="type_desc test-date-between">
                                    <span>{moment(props.test.valid_from).format('DD. MM. YYYY HH:mm')}</span> - <span>{(props.test.valid_until !== null)?moment(props.test.valid_until).format('DD. MM. YYYY HH:mm'):''}</span>
                                </div>
                            </div>

                        </li>
                        <li>
                            <div className="type">
                                <div className="type_title">
                                    <Trans>Preklad</Trans>
                                </div>
                                <div className="type_desc type-languages">
                                    <TranslateLanguages
                                        languageFrom={props.test.language_from}
                                        languageTo={props.test.language_to}
                                    />
                                </div>
                            </div>
                        </li>
                        {(percentageContent !== null)?(
                            <li>
                                <div className="type --graph">
                                    <div className="type_title">
                                        <Trans>Moja úspešnosť</Trans>
                                    </div>
                                    <div className="type_desc">
                                        {percentageContent}
                                    </div>
                                </div>
                                
                            </li>
                        ):(null)}
                    </ul>
                </div>

            </div>
            <footer className="item_footer">
                    
                {actions}

                <div className="part">
                    {(moment(new Date(props.test.valid_from)).isAfter(moment()))?(
                        <CountDownDate
                            date={props.test.valid_from}
                            finalText={i18n._('Môžete spustiť')}
                            title={i18n._('Začiatok o')}
                            color='primary'
                            showBefore={true}
                        />
                    ):
                    (
                        (props.test.valid_until !== null)?(
                            <CountDownDate
                                date={props.test.valid_until}
                                finalText={i18n._('Už nie je k dispozícii')}
                                title={i18n._('Koniec o')}
                                color='primary'
                                showBefore={true}
                            />
                        ):(null)
                    )}

                </div>
            </footer>
        </section>
    )
}

export default TestDetailUser