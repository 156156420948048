export interface IRule {
    type: 'required'|'regex'|'minLength'|'maxLength'|'function'|'email'
    message: string,
    regex?: RegExp,
    minValue?: number,
    maxValue?: number,
    validFunction?: (value: string) => Promise<boolean>
}

export interface IFormValue {
    rules: IRule[],
    value: string|boolean,
    valid: boolean,
    invalidMessages: string[],
    name: string,
    disabled?: boolean
}

export interface IFormData {
    values: IFormValue[],
    valid: boolean
}

export const validationService = async (data: IFormData, name?: string|string[], checkbox?: boolean): Promise<IFormData> => {
    for(let v of data.values) {
        if(typeof name !== 'undefined') {
            if(typeof name === 'string' && v.name !== name) {
                continue
            } else {
                if(!name.includes(v.name)) {
                    continue
                }
            }
        }

        let invalidMessagesValue: string[] = []

        let messages = await checkRule(v.rules, v.value)

        invalidMessagesValue = [
            ...invalidMessagesValue,
            ...messages
        ]

        if(invalidMessagesValue.length) {
            v.valid = false
        } else {
            v.valid = true
        }

        v.invalidMessages = invalidMessagesValue
    }
    
    let valid = true

    for(let v of data.values) {
        if(typeof name !== 'undefined' && typeof name !== 'string') {
            if(name.includes(v.name)) {
                if(!v.valid) {
                    valid = false
                }
            }
        } else {
            if(!v.valid) {
                valid = false
            }
        }
    }

    data.valid = valid

    return data
}

export const checkRule = async (rules: IRule[], value: string|boolean):Promise<string[]> =>  {
    const invalidMessages:string[] = []

    //If it is checkbox
    if(typeof value !== 'string') {
        for(let r of rules) {
            switch(r.type) {
                case 'required':
                    if(!value) {
                        invalidMessages.push(r.message)
                    }
                    break
            }
        }
        return invalidMessages
    }

    for(let r of rules) {
        switch(r.type) {
            case 'required':
                if(value.length === 0) {
                    invalidMessages.push(r.message)
                }
                break
            case 'regex':
                if(typeof r.regex !== 'undefined') {
                    try {
                        if(!r.regex.test(value)) {
                            invalidMessages.push(r.message)
                        }
                    } catch(e) {
                        console.warn(e)
                    }
                }
                break
            case 'minLength':
                if(typeof r.minValue !== 'undefined' && value.length < r.minValue) {
                    invalidMessages.push(r.message)
                }
                break
            case 'maxLength':
                if(typeof r.maxValue !== 'undefined' && value.length > r.maxValue) {
                    invalidMessages.push(r.message)
                }
                break
            case 'email':
                if(!/^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/.test(value)) {
                    invalidMessages.push(r.message)
                }
                break
            case 'function':
                if(typeof r.validFunction !== 'undefined') {
                    let result = await r.validFunction(value)

                    if(!result) {
                        invalidMessages.push(r.message)
                    }
                }
                break

        }
    }

    return invalidMessages
}

export const getValue = (name: string, formData: IFormData):IFormValue|undefined => {
    return formData.values.find(v => v.name === name) 
}

export const setValue = (value: IFormValue, formData: IFormData): IFormData => {
    let valueFound = formData.values.find(v => v.name === value.name) 

    if(typeof valueFound === 'undefined') {
        formData.values.push(value)
    } else {
        formData = {
            ...formData,
            values: formData.values.map(fv => fv.name === value.name?value:fv)
        }        
    }

    let valid = true

    for(let v of formData.values) {
        if(!v.valid) {
            valid = false
        }
    }

    formData.valid = valid

    return formData
}

export const getValuesObject = (names: string[], formData: IFormData) => {
    let object: any = {}

    formData.values.forEach(v => {
        if(names.includes(v.name)) {
            object[v.name] = v.value
        }
    })

    return object
}

export const setValues = (formData: IFormData, values: {name: string, value: string|boolean}[]): IFormData => {
    let newFormData = formData
    
    for(let v of values) {
        let oldV = getValue(v.name, newFormData)

        if(typeof oldV === 'undefined') {
            continue
        }

        oldV.value = v.value

        newFormData = setValue(oldV, newFormData)
    }

    return newFormData
}

export const addValue = (formData: IFormData, value: IFormValue): IFormData => {
    return {
        ...formData,
        values: [
            ...formData.values,
            value
        ]
    }
}

export const removeValue = (formData: IFormData, name: string): IFormData => {
    return {
        ...formData,
        values: formData.values.filter(fv => fv.name !== name)
    }
}