import React, {Component, PropsWithChildren} from 'react'

interface IProps {
    number?: number,
    text: string,
    title: string
}

class NumberText extends Component<PropsWithChildren<IProps>, any> {

    render() {
        const { number, text, title }  = this.props

        return (
            <div className="part-number" data-title={title}>
                {(typeof number !== 'undefined')?(
                    <>{number}</>
                ):(<>-</>)}
                <div className="detial">
                    {text}
                </div>
            </div>
        )
    }
}

export default NumberText
