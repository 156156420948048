import React, {Component} from 'react';
import { connect } from 'react-redux';
import { IApplicationStore } from "../../../constants/store-interfaces";
import { ITableRowType, IActionType, ITableHeaderCellType, ITableCellType } from '../../../constants/types';
import moment from 'moment'
import { Trans } from '@lingui/macro';
import { ReactNode } from 'react';
import { i18n } from '../../../App';
import Pagination from './TableElements/Pagination';
import noprojects from '../../../../assets/img/table/noprojects.svg'
import LoadingElement from '../../simple/LoadingElement';
import { v4 as uuidv4 } from 'uuid';
import TableFilter from './TableElements/TableFilter';

export type IFilterValueType = {
    id: number,
    value: string,
    label: ReactNode
}
export type ISelectableFilterType = {
    id: number, 
    allValues: string,
    placeholder: string,
    title: string,
    filtered: string[],
    values: IFilterValueType[]
}

interface IStateProps {
}

interface IDispatchProps {
}

const mapStateToProps = (state: IApplicationStore): IStateProps => ({
})

const mapDispatchToProps = (dispatch: any): IDispatchProps => ({
})


interface IProps extends IStateProps, IDispatchProps {
    showCheckbox: boolean,
    checkboxFunction?: any,
    showActions: boolean, 
    actions?: IActionType[],
    headers: ITableHeaderCellType[],
    rows: ITableRowType[],
    pageSize?: number,
    showFilter?: boolean,
    grid?: boolean,
    showGrid?: boolean,
    title?: string,
    showNew?: boolean,
    newTitle?: string,
    newFunction?: any,
    emptyText: string,
    emptySubtitle?: string,
    loading?: boolean,
    footer?: ReactNode,
    defaultSort?: 'asc' | 'desc',
    showForm?: boolean,
    customImage?: string,
    showHeader?: boolean,
    showCheckboxAll?: boolean,
    checkedValues?: string[],
    mobileFixedGrid?: boolean
}

interface IState {
    id: string,
    instanceId: string,
    sortByIndex: number | null,
    sortByHeader: ITableHeaderCellType | undefined,
    sortOrder: 'asc' | 'desc',
    currentPage: number,
    pageSize: number,
    checkedRows: string[],
    filter: string,
    isGrid: boolean,
    selectableFilters: ISelectableFilterType[]  | null,
    useDefaultCheckedRows: boolean,
    tableRef: React.RefObject<HTMLDivElement>
}

const findFilters = function(items: ISelectableFilterType[], i: number) {
    return items.find((item: ISelectableFilterType) => (item.id === i))
}

class TableContainer extends Component<IProps, IState> {
    constructor(props: any) {
        super(props)

        let sortByIndex = null
        let sortByHeader = this.props.headers.find((header: ITableHeaderCellType) => header.sortBy)

        if(typeof sortByHeader !== 'undefined') {
            sortByIndex = this.props.headers.indexOf(sortByHeader)
        }

        let pageSize = 10

        if(typeof this.props.pageSize  !== 'undefined')  {
            pageSize = this.props.pageSize
        }

        let defaultSort = ((typeof this.props.defaultSort ===  'undefined')?'asc':this.props.defaultSort)

        let filters = this.getFilters(props.rows)

        this.state ={
            id: uuidv4(),
            instanceId: Math.random().toString(19).substring(10),
            sortByIndex: sortByIndex,
            sortByHeader: sortByHeader,
            sortOrder: defaultSort,
            currentPage: 1,
            checkedRows: (typeof this.props.checkedValues !== 'undefined')?this.props.checkedValues:[],
            pageSize: pageSize,
            filter: '',
            isGrid: ((typeof  this.props.grid !==  'undefined') && this.props.grid)?true:false,
            selectableFilters: filters,
            useDefaultCheckedRows: true,
            tableRef: React.createRef<HTMLDivElement>()
        }

        if(typeof this.props.checkboxFunction  !==  'undefined') {
            this.props.checkboxFunction(this.state.checkedRows)
        }

        this.changeSortOrder = this.changeSortOrder.bind(this)
        this.changeGrid = this.changeGrid.bind(this)
        this.setPage = this.setPage.bind(this)
        this.handleFilter =  this.handleFilter.bind(this)
        this.handleSelectFilter = this.handleSelectFilter.bind(this)
        this.handleCheckRow = this.handleCheckRow.bind(this)
        this.handleSelectAll = this.handleSelectAll.bind(this)
        this.setCheckedRows = this.setCheckedRows.bind(this)
    }

    componentDidUpdate(prevProps: IProps, prevState: IState) {
        let {rows}  = this.props
        
        let filters = this.state.selectableFilters
        let checkedRows = this.state.checkedRows

        let update = false

        let cellsOld:string[] = []
        let cellsNew:string[] = []

        rows.forEach(r => {
            r.cells.forEach(c => {
                cellsNew.push(c.stringToOrder)
            })
        })

        prevProps.rows.forEach(r => {
            r.cells.forEach(c => {
                cellsOld.push(c.stringToOrder)
            })
        })

        if(
            (prevState.id !== this.state.id) ||
            (JSON.stringify(cellsNew) !== JSON.stringify(cellsOld))
        ) {
            update = true
        }


        if(JSON.stringify(prevProps.checkedValues) !== JSON.stringify(this.props.checkedValues)) {
            
            if(this.state.useDefaultCheckedRows && (typeof this.props.checkedValues !== 'undefined')) {
                checkedRows = this.props.checkedValues
            }

            update = true
        }

        if(update) {
            filters = this.getFilters(rows)

            this.setState({
                ...this.state,
                selectableFilters: filters,
                checkedRows: checkedRows
            })

            if(typeof this.props.checkboxFunction  !==  'undefined') {
                this.props.checkboxFunction(checkedRows)
            }
        }
    }

    getFilters(rows: ITableRowType[]):ISelectableFilterType[] {
        let filters: ISelectableFilterType[] = []

        for(let row of rows) {
            let _i = 0

            for(let cell of row.cells) {

                if((typeof cell.selectableFilter !== 'undefined') && (cell.selectableFilter)){
                    let addedFilters = findFilters(filters,_i)
             
                    if((typeof cell.selectableObject !== 'undefined') && (cell.selectableFilter)){

                        if(typeof addedFilters !== 'undefined') {
                            for(let  obj of cell.selectableObject) {
                                if(addedFilters.values.filter((item: any) => item.value === obj.value).length === 0) {

                                    addedFilters.values.push({
                                        id: addedFilters.id,
                                        label: obj.object,
                                        value: obj.value
                                    })

                                }
                            }
                        } else {
                            let newValues: IFilterValueType[] = []

                            for(let obj of cell.selectableObject) {
                                if(newValues.filter((item: any) => item.value === obj.value).length === 0) {
                                    newValues.push({
                                        id: _i,
                                        label: obj.object,
                                        value: obj.value
                                    })
                                }
                            }

                            filters.push({
                                id: _i,
                                allValues: ((typeof cell.selectableAllText !== 'undefined')?cell.selectableAllText:''),
                                placeholder: ((typeof cell.selectableName !== 'undefined')?cell.selectableName:''),
                                title: ((typeof cell.selectableTitle !== 'undefined')?cell.selectableTitle:''),
                                filtered: [],
                                values: newValues
                            })
                        }
                    }
                }

                _i++

            }
        }

        return filters
    }

    changeGrid()  {
        this.setState({
            ...this.state,
            isGrid: !this.state.isGrid
        })
    }

    changeSortOrder(index: number) {
        const {sortByIndex, sortByHeader, sortOrder} = this.state

        let sortByIndexNew  =  sortByIndex
        let sortByHeaderNew = sortByHeader
        let sortOrderNew  = sortOrder

        if(sortByIndex !== index)  {
            sortByIndexNew  = index
            sortByHeaderNew = this.props.headers[sortByIndexNew]
        } else {
            sortOrderNew = (sortOrder === 'asc')?'desc':'asc'
        }

        this.setState({
            ...this.state,
            sortByHeader: sortByHeaderNew,
            sortByIndex:  sortByIndexNew,
            sortOrder: sortOrderNew,
            currentPage: 1
        })

    }

    setPage(pageNum: number) {
        this.setState({
            ...this.state,
            currentPage: pageNum
        })

        let refCurrent = this.state.tableRef.current

        if(refCurrent !== null) {
            window.scrollTo(0, refCurrent.offsetTop)
        }
    }

    handleFilter(event: any)  {
        this.setState({
            ...this.state,
            filter: event.target.value,
            currentPage: 1
        })
    }

    handleSelectFilter(values: any, filterId: number, value: string | null) {

        let selectedFilters  = this.state.selectableFilters

        if(selectedFilters !== null) {
            let selectableFilter = selectedFilters.find(value => value.id === filterId )

            if(typeof selectableFilter !== 'undefined') {

                selectableFilter.filtered = []

                if(values !== null) {
                    if(value !== null) {
                        if(!selectableFilter.filtered.includes(value)) {
                            //selectableFilter.filtered.push(value.value) If we will use more than one filter, just uncomment
                            selectableFilter.filtered  = [value]
                        }
                    } else {
                        selectableFilter.filtered = []
                    }
                }
            }
        }

        this.setState({
            ...this.state,
            selectableFilters: selectedFilters,
            currentPage: 1
        })
    }

    getFilteredRows(rows: ITableRowType[]):ITableRowType[] {
        const { showFilter } = this.props
        const { filter, selectableFilters } = this.state

        let filteredRows: ITableRowType[] = []

        //If show filter
        if((typeof showFilter  !== 'undefined') && showFilter) {
            let newRows: ITableRowType[] = []

            //First iterate  rows  for  string filter
            for(let row of rows) {
                let add = false

                for(let cell of row.cells) {

                    if(filter.length > 0) {
                        //One cell can have more filterByStrings
                        if((typeof cell.filterByString  !==  'undefined') &&  (cell.filterByString)) {
                            if(typeof cell.filterString  === 'string') {
                                if(cell.filterString.toLowerCase().includes(filter.toLowerCase())) {
                                    add = true
                                }
                            } else {
                                if(cell.filterString.filter((item: string, index: number) => item.toLowerCase().includes(filter.toLowerCase())).length > 0)  {
                                    add = true
                                }
                            }
                        }
                    }  else {
                        add = true
                    }
                }

                if(add)  {
                    newRows.push(row)
                }
            }

            let filteredRows: ITableRowType[] = []

            //Iterate for selectable cells
            for(let row of newRows) {
                let add = true

                let _i = 0

                for(let  cell of row.cells) {

                    //Selectable filters
                    if((typeof cell.selectableFilter !== 'undefined') && cell.selectableFilter && (selectableFilters !== null)) {
                        let filterObject = findFilters(selectableFilters, _i) 

                        if((typeof filterObject !== 'undefined') && (filterObject.filtered.length > 0)) {
                            //Cell can  have  more filterStrings
                            if(typeof cell.filterString === 'string') {
                                if(filterObject.filtered.filter(item => item === cell.filterString).length === 0) {
                                    add = false
                                }
                            } else {
                                for(let searchString of cell.filterString) {
                                    if(filterObject.filtered.filter(item => item === searchString).length === 0) {
                                        add = false
                                    }
                                }
                            }
                        }
                    }

                    _i++
                }

                if(add)  {
                    filteredRows.push(row)
                }
            }

            return filteredRows
        } else {
            filteredRows = rows
        }

        return filteredRows
    }

    handleCheckRow(event: any) {
        let id = event.target.id
        
        //Remove _checkbox
        let rowId = id.slice(0, id.length-9)

        let newCheckedRows =  this.state.checkedRows

        if(!newCheckedRows.includes(rowId)) {
            newCheckedRows.push(rowId)
        } else {
            newCheckedRows = newCheckedRows.filter(item => item !== rowId)
        }

        this.setCheckedRows(newCheckedRows, false)
    }

    handleSelectAll(rows: ITableRowType[]) {
        let checkedRows = this.state.checkedRows

        let toCheckRows = rows.filter(i => (typeof i.showAction !== 'undefined')?i.showAction:true)

        let alreadyCheckedRows = checkedRows.filter(row => toCheckRows.find(i => i.catchString === row))

        if(alreadyCheckedRows.length === toCheckRows.length) {
            for(let  row of rows) {
                for(var i=0; i<checkedRows.length; i++) {
                    if(checkedRows[i] === row.catchString) {
                        checkedRows.splice(i, 1);
                        break
                    }
                }
            }
            this.setCheckedRows(checkedRows, false)
        } else  {
            for(let  row of rows) {
                if((typeof row.showAction !== 'undefined')?row.showAction:true) {
                    checkedRows.push(row.catchString)
                }
            }
            this.setCheckedRows(checkedRows, false)
        }
    }

    setCheckedRows(checkedRows: string[], useDefaultCheckedRows: boolean = true)  {
        checkedRows = checkedRows.filter((item, pos) => checkedRows.indexOf(item) === pos)

        this.setState({
            ...this.state,
            checkedRows:  checkedRows,
            useDefaultCheckedRows: useDefaultCheckedRows
        })

        if(typeof this.props.checkboxFunction  !==  'undefined') {
            this.props.checkboxFunction(checkedRows)
        }
    }

    render() {
        const { showCheckbox, showActions, headers, rows, actions, showFilter, title, showNew, newFunction, newTitle, emptySubtitle, emptyText, loading, footer, showGrid, showForm, customImage, showHeader, showCheckboxAll, mobileFixedGrid } = this.props
        const { sortByIndex, sortByHeader, sortOrder, pageSize,  currentPage, selectableFilters,  checkedRows, isGrid, instanceId } = this.state

        let loadingLive = false
        let showGridValue = true

        if(typeof  showGrid !== 'undefined') {
            showGridValue = showGrid
        }

        if(typeof loading !== 'undefined'){
            loadingLive = loading
        }

        let filteredSortedRows  = rows
        if((sortByIndex !== null) && (typeof sortByHeader !== 'undefined')) {
            
            if(sortOrder === 'asc') {
                if((typeof sortByHeader.sortableType === 'undefined') || (sortByHeader.sortableType === 'text')) {
                    filteredSortedRows.sort((a:ITableRowType, b:ITableRowType) => a.cells[sortByIndex].stringToOrder.localeCompare(b.cells[sortByIndex].stringToOrder))
                } else if(sortByHeader.sortableType === 'number') { //NUMBER SORT
                    filteredSortedRows.sort((a:ITableRowType, b:ITableRowType) => Number(a.cells[sortByIndex].stringToOrder) - Number(b.cells[sortByIndex].stringToOrder))
                } else { //DATE SORT
                    filteredSortedRows.sort((a:ITableRowType, b:ITableRowType) => moment(b.cells[sortByIndex].stringToOrder).valueOf() - moment(a.cells[sortByIndex].stringToOrder).valueOf())
                }
            } else {
                if((typeof sortByHeader.sortableType === 'undefined') || (sortByHeader.sortableType === 'text')) {
                    filteredSortedRows.sort((a:ITableRowType, b:ITableRowType) => b.cells[sortByIndex].stringToOrder.localeCompare(a.cells[sortByIndex].stringToOrder))
                } else if(sortByHeader.sortableType === 'number') { //NUMBER SORT
                    filteredSortedRows.sort((a:ITableRowType, b:ITableRowType) => Number(b.cells[sortByIndex].stringToOrder) - Number(a.cells[sortByIndex].stringToOrder))
                } else { //DATE SORT
                    filteredSortedRows.sort((a:ITableRowType, b:ITableRowType) => moment(a.cells[sortByIndex].stringToOrder).valueOf() - moment(b.cells[sortByIndex].stringToOrder).valueOf())
                }
            }
        }


        let filters: ReactNode[] = []

        //Get filtered rows (by selected filters)
        filteredSortedRows = this.getFilteredRows(filteredSortedRows)

        if((typeof showFilter !== 'undefined') && showFilter) {
            //Selectable filters
            if(selectableFilters  !== null) {
                let _i = 0

                for(let  filter of selectableFilters) {
                    filters.push((
                        <TableFilter
                            key={_i++}
                            filter={filter}
                            handleSelectFilter={this.handleSelectFilter}
                        />
                    ))
                }
            }
        }

        let modifiedRows = filteredSortedRows.slice((currentPage-1) * pageSize, (currentPage) * pageSize)

        let toCheckRows = modifiedRows.filter(i => (typeof i.showAction !== 'undefined')?i.showAction:true)
        let alreadyCheckedRows = checkedRows.filter(row => toCheckRows.find(i => i.catchString === row))

        let showFilterHeader = false

        if((typeof  showFilter !==  'undefined') &&  (showFilter) && (rows.length > 0)) {
            showFilterHeader = true
        }

        if(showGridValue && (rows.length > 0)) {
            showFilterHeader = true
        }

        if(showGridValue && (rows.length > 0)) {
            showFilterHeader = true
        }

        if((typeof showNew !== 'undefined') && showNew) {
            showFilterHeader = true
        }

        return (
            <div ref={this.state.tableRef}>
                {showFilterHeader?(
                    <div className="page-filter">
                        <div className="part _filters">
                            {((typeof  showFilter !==  'undefined') &&  (showFilter) && (rows.length > 0))?(
                                <div className="filters">
                                    {filters}
                                </div>
                            ):(null)}
                        </div>
                        <div className="part _rightpart">
                        
                            <div className={(mobileFixedGrid)?'filters mobile-hidden':'filters'}>
                                {(showGridValue && (rows.length > 0))?(
                                    <div className={"filter _tabletype _type1 " +  ((isGrid)?'':'active')} onClick={this.changeGrid}>
                                        <div className="filter-type toggle">
                                            <i className="la la-list-ol"/>
                                            <span><Trans>Tabuľka</Trans></span>
                                        </div>
                                    </div>
                                ):(null)}
                                {(showGridValue && (rows.length > 0))?(    
                                    <div className={"filter  _tabletype _type2 " +  ((isGrid)?'active':'')} onClick={this.changeGrid}>
                                        <div className="filter-type toggle">
                                            <i className="la la-list-ul"/>
                                            <span><Trans>Mriežka</Trans></span>
                                        </div>
                                    </div>
                                ):(null)}
                                {((typeof showNew !== 'undefined') && showNew)?(
                                    <div className="filter _newaction">
                                        <div className="filter-type add-new" onClick={() => newFunction()}>
                                            <i className="la la-plus-circle"/>
                                            <span>{newTitle}</span>
                                        </div>
                                    </div>
                                ):(null)}
                            </div>
                        
                        </div>
                    </div> 
                ):(null)}
                <div className={"table " + ((isGrid)?'grid ':' ') + ((mobileFixedGrid)?'mobile-grid':'')}>
                    <LoadingElement loadingValue={loadingLive}/>
                    {((typeof showHeader !== 'undefined') && showHeader)?(
                        <div className="table-options">
                            <div className="part">
                                <h2>{title}</h2>
                            </div>
                            {(rows.length > 0)?(
                                <div className="part">
                                    {(typeof showForm == 'undefined' || showForm)?(
                                        <form className="form">
                                            <input type="text" name="filter" className="ant-input" onChange={this.handleFilter} placeholder={i18n._('Začnite písať')}/>
                                        </form>
                                    ):(
                                        null
                                    )}
                                </div>
                            ):(null)}
                        </div>
                    ):(null)}
                    {(rows.length === 0)?(
                        <div className="empty-table _bgSilent">
                            <div className="wrapper">
                            
                                <img src={(typeof customImage !== 'undefined')?customImage:noprojects} alt=""/>

                                <h3>{emptyText}</h3>
                                
                                {(typeof emptySubtitle !== 'undefined')?(
                                    <p>{emptySubtitle}</p>
                                ):(null)}
                            
                                {((typeof showNew !== 'undefined') && showNew)?(
                                        <div className="bttn _primary _center _offsetTop" onClick={() => newFunction()}>
                                            {newTitle}
                                        </div>
                                ):(null)}
                            
                            </div>
                        </div>
                    ):(
                        <div>
                            <div className="table-head">
                                <div className="table-row">
                                    {(showCheckbox)?(
                                    <div className="table-col width-check">
                                        {((typeof showCheckboxAll == 'undefined') || showCheckboxAll)?(
                                            <div className="part-check form">
                                                <div className="check checkbox" title={i18n._('Všetky')}>
                                                    <input id={'selectAll' + instanceId} className="ant-input" type="checkbox" onChange={() => this.handleSelectAll(modifiedRows)} checked={(alreadyCheckedRows.length  === toCheckRows.length)?true:false}/>
                                                    <label htmlFor={'selectAll' +  instanceId}></label>
                                                </div>
                                            </div>
                                        ):(null)}
                                    </div>
                                    ):(null)}
                                    {(headers.map((item:ITableHeaderCellType, index: number) => (
                                        <div 
                                            className={
                                                'table-col ' + 
                                                ((item.sortable)?'_sort ':' ') +
                                                ((index === sortByIndex)?'active ':' ') + 
                                                ((typeof item.hideClass !== 'undefined')?item.hideClass:' ')
                                            } 
                                            key={index} 
                                            onClick={() => (item.sortable)?this.changeSortOrder(index):null}
                                        >
                                            <div className="part-text">
                                                {item.content}
                                                {(item.sortable)?(
                                                    (item.sortable && (sortOrder  === 'desc') && (index === sortByIndex))?(<i className="la la-angle-down"/>):<i className="la la-angle-up"/>
                                                ):(null)}
                                            </div>
                                        </div>
                                    )))}
                                    {(showActions)?(
                                        <div className="table-col width-auto">
                                            <Trans>Akcia</Trans>
                                        </div>
                                    ):(null)}
                                </div>
                            </div>
                            
                            <div className="table-body">
                                {(modifiedRows.map((row: ITableRowType, index: number) => (
                                    <div className="table-row" key={index}>
                                        {(showCheckbox)?(
                                            <div className="table-col width-check">
                                                {((typeof row.showAction !== 'undefined')?row.showAction:true)?(
                                                    <div className="part-check form">
                                                        <div className="check checkbox">
                                                            <input id={row.catchString + '_checkbox'} className="ant-input" type="checkbox" onChange={this.handleCheckRow} checked={(checkedRows.filter(checked => checked === row.catchString).length>0)?true:false}/>
                                                            <label htmlFor={row.catchString + '_checkbox'}></label>
                                                        </div>
                                                    </div>
                                                ):(null)}
                                            </div>
                                        ):(null)}
                                        {row.cells.map((cell: ITableCellType, index2: number) => (
                                            <div 
                                                className={
                                                    "table-col " + 
                                                    ((typeof cell.hideClass !== 'undefined')?cell.hideClass:' ')
                                                } 
                                                key={index2}
                                            >
                                                {cell.content}
                                            </div>
                                        ))}
                                        {(showActions && ((typeof row.showAction !== 'undefined')?row.showAction:true))?(
                                            <div className="table-col width-auto grid-edit">
                                                <div className="part-edit">
                                                    <i className="la la-ellipsis-h"/>
                                                    <div className="edit-content _radius _shadow">
                                                        <ul>
                                                            {(typeof actions !==  'undefined')?
                                                                (actions.map((action: IActionType, index3:number) => (
                                                                    <li key={index3}>
                                                                        <span
                                                                            className={(typeof action.customClass !== 'undefined')?'href ' + action.customClass:'href'} 
                                                                            onClick={() => action.action(row.catchString)}
                                                                        >
                                                                            {action.name}
                                                                        </span>
                                                                    </li>
                                                                )))
                                                                :
                                                                (null)
                                                            }
                                                        </ul>
                                                    </div> 
                                                </div>
                                            </div>
                                        ):(null)}
                                        {((typeof row.showAction !== 'undefined') && !row.showAction)?(
                                            <div className="table-col width-auto grid-edit"></div>
                                        ):(null)}
                                    </div>
                                )))}
                                
                            </div>
                            {(typeof footer !== 'undefined')?(
                                <div className="table-footer">
                                    {footer}
                                </div>
                            ):(null)}
                        </div>
                    )}
                    
                </div>
                    
                <Pagination
                    pages={Math.ceil(filteredSortedRows.length/pageSize)}
                    currentPage={currentPage}
                    pageClick={this.setPage}
                />
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TableContainer)