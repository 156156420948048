import React from 'react';
import { INotificationFull } from '../../../../constants/types';


interface IProps {
    data: INotificationFull,
    deleteFunction: (id: number) => any
}

const Notification = (props: IProps) => {
    const { data, deleteFunction } = props

    let color=''

    switch(data.type) {
        case 'success':
            color = 'green'                        
            break
        case 'warning':
            color = 'orange'                
            break
        case 'info':
            color = 'primary'                
            break
        case 'error':
            color = 'red'                
            break
    }

    return (
            
        <div className={"notification _" + color + ' show_' + data.goingToShow + ' hide_' + data.goingToHide}>
            
            {((typeof data.showBell !=='undefined')&&data.showBell)?(<i className="la la-bell icon"/>):(null)}
        
            <div className="notification-content">
                {data.text}
            </div>
            
            <i className="la la-times-circle close" onClick={() => deleteFunction(data.id)}/>
        
        </div>
    )
};

export default Notification
