import React, {Component} from 'react';

interface IProps {
    type: 'success' | 'info' |  'warning' | 'error',
    showClose?: boolean,
    closeFunction?: any,
    showBell?: boolean,
    customIcon?: string
}

class InformationBox extends Component<IProps, any> {

    render() {
        const { type, children, showClose, showBell, closeFunction, customIcon } = this.props

        let color = ''

        switch(type) {
            case 'success':
                color = 'green'                        
                break
            case 'warning':
                color = 'orange'                
                break
            case 'info':
                color = 'primary'                
                break
            case 'error':
                color = 'red'                
                break
        }
        return (
            <div className={"notification  _" +  color + ' ' + ((showClose)?'hasClose':'noClose')}>
            
                {(showBell)?(<i className="la la-bell icon"/>):(null)}
                {(typeof customIcon !== 'undefined')?(<i className={"la la-" + customIcon + " icon"}/>):(null)}
            
                <div className="notification-content">
                    {children}
                </div>

                {(showClose &&  (typeof closeFunction  !== 'undefined'))?(<i className="la la-times-circle close" onClick={closeFunction()}/>):(null)} 

            </div>
        )
    }
}

export default InformationBox