import React, {Component} from 'react';
import { connect } from 'react-redux';
import { IApplicationStore } from "../../../constants/store-interfaces";
import { history } from '../../../../index';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';

interface IStateProps extends RouteComponentProps {
    userLoggedIn: boolean
}

const mapStateToProps = (state: IApplicationStore) => ({
    userLoggedIn: state.globalStore.userLoggedIn
})

const mapDispatchToProps = (dispatch: any) => ({
})

class HomePage extends Component<IStateProps> {

    componentDidMount() {
        if(this.props.userLoggedIn) {
            history.push('/app')
        } else  {
            history.push('/login')
        }
    }

    render() {
      
        return (
            <div>
                
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HomePage))
