import { setLoading } from '../loadings/setLoading';
import config from '../../config';
import { handleErrors } from '../../utils/handleErrors';
import { store } from '../../store';

export function addUserToUserGroup(code:string) {
        store.dispatch(setLoading(true))

        return fetch(config.api.usergroups_add_user, {
            method: 'POST',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + store.getState().globalStore.token
            },
            body: JSON.stringify({access_code: code})
            
        })
            .then(handleErrors)
            .then(res => res.json())
            .then(res => {

                store.dispatch(setLoading(false))
                return res
                
            })
            .catch((error: any)  => {
                //TODO: show error
                store.dispatch(setLoading(false))
            });
}