import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import { IApplicationStore } from '../../../../constants/store-interfaces';
import useOnClickOutside from '../../../../hooks/useOnClickOutside';
import { setShowNotificationMenu } from '../../../../actions/global/setShowNotificationMenu';
import { Trans } from '@lingui/macro';
import { setShowCreateWordModal, setShowCreateWordGroupModal, setShowCreateUserGroupModal, setShowCreateTestModal } from '../../../../actions/modals/setShowModal';
import AuthTeacherWrapper from '../../containers/Authentification/AuthTeacherWrapper';

const mapStateToProps = (state: IApplicationStore ) => ({
})


function AddMenu({dispatch}:any): JSX.Element {

    const [showDropdown, setshowDropdown] = useState(false)

    const ref = useRef<HTMLInputElement>(null);

    const setDropdownState = (value:boolean) => {
        setshowDropdown(value)

        dispatch(setShowNotificationMenu(value))
    }

    //Open modal for add word
    const openAddWord = ()  => {
        setDropdownState(false)
        dispatch(setShowCreateWordModal(true))
    }

    //Open modal for add wordgroup
    const openAddWordGroup = ()  => {
        setDropdownState(false)
        dispatch(setShowCreateWordGroupModal(true))
    }

    //Open modal for add user group
    const openAddUserGroup = ()  => {
        setDropdownState(false)
        dispatch(setShowCreateUserGroupModal(true))
    }

    //Open modal for add test
    const openAddTest = () => {
        setDropdownState(false)
        dispatch(setShowCreateTestModal(true))
    }

    useOnClickOutside(ref, () => setDropdownState(false));

    return (
        <div 
            ref={ref} 
            className={"addContent dropdown " + ((showDropdown)?'active':'')}
        >
            <i className="la la-plus-circle" onClick={() => setDropdownState(!showDropdown)} data-tour="step2"/>
            
            <div className="dropdownContent _shadow _radius _right _small">
                <div className="wrapper">
                    <div className="list-nav">
                            <ul>
                                <li><div className="href" onClick={() => openAddWord()}><i className="la la-list-alt"/> <span><Trans>Nové slovo</Trans></span></div></li>
                                <li><div className="href" onClick={() => openAddWordGroup()}><i className="la la-th-list"/> <span><Trans>Nová skupina slov</Trans></span></div></li>
                                <li><div className="href" onClick={() => openAddTest()}><i className="la la-mortar-board"/> <span><Trans>Nové cvičenie</Trans></span></div></li>
                                <AuthTeacherWrapper>
                                    <li><div className="href" onClick={() => openAddUserGroup()}><i className="la la-group"/> <span><Trans>Nová trieda</Trans></span></div></li>
                                </AuthTeacherWrapper>
                            </ul>
                    </div>
                </div>
            </div>
            
        </div>
        
    )
}

export default connect(mapStateToProps)(AddMenu)