import React, {Component} from 'react';
import { connect } from 'react-redux';
import { ITest, ITableRowType, ITableCellType, ITableHeaderCellType, IActionType, ITestResult, IUWordGroupType } from '../../../../constants/types';
import { IApplicationStore } from '../../../../constants/store-interfaces';
import { i18n } from '../../../../App';
import { Trans } from '@lingui/macro';
import TableContainer from '../../containers/TableContainer';
import Percentage from '../../../simple/tableElements/Percentage';
import TestClass from '../../../simple/classes/testClass';
import { NavLink } from 'react-router-dom';
import moment from 'moment';


interface IStateProps {
}

interface IDispatchProps {
}

const mapStateToProps = (state: IApplicationStore): IStateProps => ({
})

const mapDispatchToProps = (dispatch: any): IDispatchProps => ({ 
})


interface IProps extends IStateProps, IDispatchProps {
    currentWordGroup?: IUWordGroupType,
    loading: boolean,
    tests: ITest[],
    results: ITestResult[]
}

interface IState {
}

class AssignedWordGroupHomeWorks extends Component<IProps, IState> {
    
    getRows(tests:  ITest[], results: ITestResult[]): ITableRowType[] {
        let rows: ITableRowType[] = []

        for(let test  of tests)  {
            let cells: ITableCellType[] = []

            let percentage:number|null = null
            let percentageContent = (null)

            //Find percentage
            let testResults = results.filter(tR => tR.test_id === test.id)

            if(testResults.length) {
                let cntPoints = 0
                let maxPoints = 0

                testResults.forEach(tR => {
                    let testObject = new TestClass(test, tR)
                    
                    cntPoints += testObject.getTestRate()
                    maxPoints += testObject.getMaximumRate()
                })

                percentage = Math.round(Number(cntPoints)/maxPoints*100)
            }

            if(percentage !== null) {
                percentageContent = (<Percentage percentage={percentage} title={i18n._('Úspešnosť')}/>)
            }

            let isValid = true

            if(moment(test.valid_from).isAfter(moment())) {
                isValid = false
            }   

            if((test.valid_until !== null) && (moment(test.valid_until).isBefore(moment()))) {
                isValid = false
            }

            let tooltipShow = i18n._('Neaktívna')
            let tooltipColor = '_red'
            let tooltip = i18n._('Bola platná do') + ' ' + ((test.valid_until !== null)?moment(test.valid_until).format('DD.MM.YYYY HH:mm'):moment(test.valid_from).format('DD.MM.YYYY HH:mm'))
            
            let actions = (
                <div className="part-list">
                    <NavLink to={"/app/classes/" + test.usergroup_id + "/homeworks/" + test.id + "/detail"} target="_self" className="bttn _small _primary _icon" data-tooltip={i18n._("Detail domácej úlohy")}>
                        <i className="la la-search-plus"/>
                    </NavLink>
                </div>
            )

            if(isValid) {
                tooltipColor = '_silent'
                tooltipShow = i18n._('Aktívna')
                tooltip = i18n._('Platná do') + ' ' + ((test.valid_until !== null)?moment(test.valid_until).format('DD.MM.YYYY HH:mm'):moment(test.valid_from).format('DD.MM.YYYY HH:mm'))

                actions = (
                    <div className="part-list">
                        <NavLink to={"/app/classes/" + test.usergroup_id + "/homeworks/" + test.id} target="_self" className="bttn _small _green _icon">
                            <Trans>Spustiť</Trans>
                        </NavLink>
                        <NavLink to={"/app/classes/" + test.usergroup_id + "/homeworks/" + test.id + "/detail"} target="_self" className="bttn _small _primary _icon" data-tooltip={i18n._("Detail domácej úlohy")}>
                            <i className="la la-search-plus"/>
                        </NavLink>
                    </div>
                )
            }
            
            cells = [
                {
                    content: (<div className="part-title">{test.name}</div>),
                    stringToOrder:  test.name,
                    filterString:  test.name,
                    filterByString:  true
                },
                {
                    content: percentageContent,
                    stringToOrder: test.created_at,
                    filterString: test.created_at,
                    filterByString: false
                },
                {
                    content: (
                        <div className={"tag " + tooltipColor} data-tooltip={tooltip}>
                            {tooltipShow}
                        </div>
                    ),
                    stringToOrder: test.valid_from,
                    filterString: test.valid_from,
                    filterByString: false
                },
                {
                    content: (
                        actions
                    ),
                    stringToOrder: test.updated_at,
                    filterString: test.updated_at,
                    filterByString: false
                }
            ]

            rows.push({
                cells: cells,
                catchString: '_' +  test.id
            })
        }

        return rows
    }

    getHeaders(): ITableHeaderCellType[] {
        return [
            {
                content: (<span><Trans>Názov</Trans></span>),
                sortBy: false,
                sortable: true
            },
            {
                content: (<span><Trans>Výsledok</Trans></span>),
                sortBy: false,
                sortable: false
            },
            {
                content: (<span><Trans>Platnosť</Trans></span>),
                sortBy: true,
                sortableType: 'date',
                sortable: true,
            },
            {
                content: (<span></span>),
                sortBy: false,
                sortable: false
            },
        ]
    }

    render() {
        const { loading, tests, results } = this.props
        
        let headers: ITableHeaderCellType[] = this.getHeaders()
        let rows: ITableRowType[] = this.getRows(tests, results)
        let actions: IActionType[] = []
        
        let emptyText = i18n._('Zatiaľ nemáte evidované žiadne domáce úlohy z tejto skupiny slovíčok. Požiadajte svojho učiteľa o nové domáce úlohy.')

        return (
            <div>
                <div className="rows">
                    <div className="row">
                        <div className="row-part">
                            <TableContainer
                                headers={headers}
                                rows={rows}
                                actions={actions}
                                showActions={false}
                                showCheckbox={false}
                                showFilter={false}
                                pageSize={12}
                                grid={false}
                                showGrid={false}
                                title={i18n._('Domáce úlohy')}
                                showNew={false}
                                emptyText={emptyText}
                                loading={loading}
                                defaultSort='asc'
                                mobileFixedGrid={true}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AssignedWordGroupHomeWorks)