import config from '../../config';
import { handleErrors } from '../../utils/handleErrors';
import { SAVE_TEST_RESULT_STARTED, SAVE_TEST_RESULT_FAILED, SAVE_TEST_RESULT } from '../../constants/action-types';
import { store } from '../../store';
import { addFloatingNotification } from '../notifications/floating';
import { i18n } from '../../App';
import { ITest, ITestResult, ETestUgType, ICurrentTest } from '../../constants/types';
import { setLoadingTestResult } from '../loadings/setLoading';
import moment from 'moment';
import { updateCurrentTest } from '../global/updateCurrentTest';

export function saveTestResult(data:ITestResult, test: ITest, currentTest: ICurrentTest) {
    console.trace()
    return (dispatch: any) => {

        data.date_finished = moment().toISOString()
        
        dispatch(setLoadingTestResult(true))
        dispatch(saveTestResultStarted())

        return fetch(config.api.testResults, {
            method: 'POST',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + store.getState().globalStore.token
            },
            body: JSON.stringify(data)
            
        })
            .then(handleErrors)
            .then(res => res.json())
            .then(res => {

                dispatch(saveTestResultSuccess(res.data))
                dispatch(setLoadingTestResult(false))
                dispatch(updateCurrentTest(currentTest))

                let title = i18n._('Cvičenie')

                if(test.usergroup_id !== null) {
                    if(test.settings.ugType === ETestUgType.homework) {
                        title = i18n._('Domácu úlohu')
                    } else {
                        title = i18n._('Písomku')
                    }
                }
                
                dispatch(addFloatingNotification({
                    type:"success",
                    text: title + ' ' + test.name + ' ' + i18n._('ste úspešne dokončili'),
                    showBell: true
                }))
            })
            .catch((error: any)  => {
                dispatch(saveTestResultFailed(error))
                dispatch(setLoadingTestResult(false))
            });
    };
}

export const saveTestResultStarted = () => ({
    type: SAVE_TEST_RESULT_STARTED
});

export const saveTestResultSuccess = (data:ITest) => ({
    type: SAVE_TEST_RESULT,
    payload: data
});

export const saveTestResultFailed = (error: any) => ({
    type: SAVE_TEST_RESULT_FAILED,
    payload: { error }
});