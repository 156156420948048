import config from '../../config';
import { setLoadingLogin } from '../loadings/setLoading';
import { history } from '../../../index';
import { i18n } from '../../App';
import { 
    SET_LOGIN_USER_DATA, 
    SET_LOGIN_USER_DATA_WRONG
} from '../../constants/action-types';
import { store } from '../../store';
import { addFloatingNotification } from '../notifications/floating';


//Functions to send login request
export function loginUser(data: any) {
    return (dispatch: any) => {

        dispatch(setLoadingLogin(true))
        
        return fetch(config.api.loginUser, {
            method: 'post',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(res => res.json())
            .then(json => {                    
                dispatch(setLoadingLogin(false))

                if(json.status  === 'success') {
                                        
                    dispatch(setLoginUserData({
                        token:  json.access_token,
                        user: json.data
                    }))

                    history.push({
                        pathname: '/app'
                    });
                } else {
                    if(typeof json.possible !== 'undefined') {
                        store.dispatch(addFloatingNotification({
                            type:"warning",
                            text:i18n._('Používateľ s týmto menom neexistuje. Skontrolujte zadané prihlasovacie meno (najmä veľké a malé písmená).') + ' ' + (json.possible.length?i18n._('Vyskúšajte:') + ' ' + json.possible.join(', '):''),
                            showBell: true
                        }))
                    } else {
                        store.dispatch(addFloatingNotification({
                            type:"warning",
                            text:i18n._('Nepodarilo sa prihlásiť, overte zadané heslo!'),
                            showBell: true
                        }))
                    }
                }
            })
            .catch((error: any)  => {
                dispatch(setLoadingLogin(false))
                //TODO: rôzne výpisy podľa odpovede (zlé heslo, chyba servera, ...)
                store.dispatch(addFloatingNotification({
                    type:"warning",
                    text:i18n._('Nepodarilo sa prihlásiť, overte zadané meno a heslo!'),
                    showBell: true
                }))
                dispatch(setLoginUserError(i18n._('Nepodarilo sa prihlásiť, overte zadané meno a heslo!')))
            });
    };
}

export function setLoginUserData(data: any) {
    return { type: SET_LOGIN_USER_DATA, payload: data }
}

export function setLoginUserError(data: string) {
    return { type: SET_LOGIN_USER_DATA_WRONG, payload: data }
}