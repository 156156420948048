import React, {Component} from 'react';
import { connect } from 'react-redux';
import { getLanguages } from '../../../../../actions/languages/getLanguages';
import { storeWordGroups } from '../../../../../actions/wordGroups/storeWordGroups';
import { ILanguageType, IAddWordMapping, IStepperStepType } from '../../../../../constants/types';
import { IApplicationStore } from '../../../../../constants/store-interfaces';
import AddWordRepeater from '../modalParts/AddWordRepeater';
import { i18n } from '../../../../../App';
import Stepper from '../../../containers/Stepper';
import ModalContainer from '../../../containers/ModalContainer';
import ModalHeader from '../../../containers/ModalElements/ModalHeader';
import ModalBody from '../../../containers/ModalElements/ModalBody';
import { setShowCreateWordGroupModal } from '../../../../../actions/modals/setShowModal';
import SummaryAddWordGroup from '../modalParts/SummaryAddWordGroup';
import LanguageChooserBothSides from '../modalParts/LanguageChooserBothSides';

interface IDispatchProps {
    getAllLanguages: typeof getLanguages
    storeWordGroups: typeof storeWordGroups
    setShowAddWordGroupModal: typeof setShowCreateWordGroupModal
}

interface IStateProps {
    languages: ILanguageType[],
    loading: boolean,
    showModal: boolean
}

const mapStateToProps = (state: IApplicationStore): IStateProps => ({
    languages: state.languageStore.languages,
    loading: state.loadingStore.loading,
    showModal: state.modalsStore.showAddWordGroup
})

const mapDispatchToProps = (dispatch: any): IDispatchProps => ({
    getAllLanguages:  ()  => dispatch(getLanguages()),
    storeWordGroups: (data: any) => dispatch(storeWordGroups(data)),
    setShowAddWordGroupModal: (value: boolean) => dispatch(setShowCreateWordGroupModal(value))
})

interface IState {
    languageFrom: any | null,
    languageTo: any | null,
    wordGroupName: string,
    words: IAddWordMapping[],
    allowPrev: boolean,
    allowNext: boolean
}

interface IProps extends IDispatchProps, IStateProps {
    
}

const initState: IState = {
    languageFrom: null,
    languageTo: null,
    wordGroupName: '',
    words: [{from:'', to:''}],
    allowPrev: true,
    allowNext: false
}

class ModalAddWord extends Component<IProps, IState> {

    constructor(props:any) {
        super(props)

        this.state = initState

        this.handleLanguageFrom = this.handleLanguageFrom.bind(this)
        this.handleLanguageTo = this.handleLanguageTo.bind(this)
        this.handleWordGroupName = this.handleWordGroupName.bind(this)
        this.handleWords = this.handleWords.bind(this)
        this.handleSaveWordGroups = this.handleSaveWordGroups.bind(this)
        this.stepperChange = this.stepperChange.bind(this)
    }

    componentDidMount() {
        if(this.props.languages.length === 0) {
            this.props.getAllLanguages()
        }
        this.setState(initState)
    }

    componentDidUpdate(prevProps: IProps, ) {
        if(prevProps.showModal && !this.props.showModal) {
            this.setState(initState)

            if(this.props.languages.length === 0) {
                this.props.getAllLanguages()
            }
        }
    }

    handleLanguageFrom(languageObject: any) {
        this.setState({
            ...this.state,
            languageFrom: languageObject,
            allowNext: ((this.state.languageTo === null) || (languageObject === null) || (this.state.wordGroupName.length === 0))?false:true
        })
    }

    handleLanguageTo(languageObject: any) {
        this.setState({
            ...this.state,
            languageTo: languageObject,
            allowNext: ((this.state.languageFrom === null) || (languageObject === null) || (this.state.wordGroupName.length === 0))?false:true
        })
    }

    handleWordGroupName(wordGroupName: string){
        this.setState({
            ...this.state,
            wordGroupName: wordGroupName,
            allowNext: ((this.state.languageFrom === null) || (this.state.languageTo === null) || (wordGroupName.length === 0))?false:true
        })
    }

    handleWords(words:  any[]){
        let isValidWords = true

        words.forEach((w, i) => {
            if((w.from === '' )||(w.to === '')) {
                isValidWords = false
            }
            
            
            if((w.from !== '') && (w.to !== '')) {
                words.forEach((w2, i2) => {
                    if(i2 !== i) {
                        if((w.from === w2.from) && (w.to === w2.to)) {
                            isValidWords = false
                        }
                    }
                })
            }
        })

        this.setState({
            ...this.state,
            words: words,
            allowNext: isValidWords
        })
    }

    submitFormHandler(e:  any)  {
        e.preventDefault()
    }

    handleSaveWordGroups() {
        const { wordGroupName, languageFrom, languageTo, words } = this.state

        let data = {}

        let wordGroupsArray = [
            {
                wordgroup_id: null,
                name: wordGroupName
            }
        ]

        let languages = {
            language_from_id: languageFrom.id,
            language_to_id: languageTo.id
        }

        let wordsArray = []

        for (let word of words) {
            wordsArray.push({
                word_text: word.from,
                translation: word.to
            })
        }

        data = {
            wordgroups: wordGroupsArray,
            language_ids: languages,
            words: wordsArray
        }

        console.log(data)
    
        this.props.storeWordGroups(data)
        this.props.setShowAddWordGroupModal(false)
    }

    stepperChange(type: 'prev'|'next', stepNumber: number) {
        if(type==='prev') {
            if(stepNumber === 0) {
                this.setState({
                    ...this.state,
                    allowNext: (((this.state.languageFrom === null) || (this.state.languageTo === null) || (this.state.wordGroupName.length === 0))?false:true)
                })
            }
        }
    }

    render() {
        let { languages, showModal } = this.props
        let { allowPrev, allowNext, languageFrom, languageTo, words, wordGroupName } = this.state

        const steps:IStepperStepType[] = [
            {
                name: i18n._('Základné informácie'),
                step: (
                    <LanguageChooserBothSides
                        setLanguageFrom={this.handleLanguageFrom} 
                        setLanguageTo={this.handleLanguageTo} 
                        defaultLanguageFrom={languageFrom}
                        defaultLanguageTo={languageTo}
                        wordGroupName={wordGroupName}
                        languages={languages}
                        showWordGroupNameField={true}
                        setWordGroupName={this.handleWordGroupName}
                    />
                )
            },
            {
                name: i18n._('Slovíčka'),
                step: (
                    <AddWordRepeater 
                        words={words}
                        handleWords={this.handleWords}
                        languageFrom={languageFrom}
                        languageTo={languageTo}
                        isValid={allowNext}
                    />
                )
            },
            {
                name: i18n._('Zhrnutie'),
                step: (
                    <SummaryAddWordGroup
                        languageFrom={languageFrom}
                        languageTo={languageTo}
                        words={words}
                        wordGroupName={wordGroupName}
                    />
                )
            }
        ]

        return (
            <section id="mainSection">
                <ModalContainer show={showModal}>
                    <ModalHeader title={i18n._('Nová skupina slovíčok')}/>
                    
                    <ModalBody>
                    
                        <form className="form _alt" onSubmit={this.submitFormHandler} autoComplete="off">
                            <Stepper 
                                steps={steps} 
                                finishText={i18n._('Uložiť')}
                                allowPrev={allowPrev}
                                allowNext={allowNext}
                                finalSubmit={this.handleSaveWordGroups}
                                showInModal={true}
                                stepChanged={this.stepperChange}
                            />
                        </form>
                    
                    </ModalBody>
                </ModalContainer>
            </section>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalAddWord)