import { setLoadingCreatedWordGroups } from '../loadings/setLoading';
import config from '../../config';
import { handleErrors } from '../../utils/handleErrors';
import { DELETE_WORDGROUP, DELETE_WORDGROUP_STARTED, DELETE_WORDGROUP_FAILED } from '../../constants/action-types';
import { store } from '../../store';
import { addFloatingNotification } from '../notifications/floating';
import { i18n } from '../../App';

export function deleteWordGroup(id:number, name: string) {
    return (dispatch: any) => {

        dispatch(setLoadingCreatedWordGroups(true))
        dispatch(deleteWordGroupStarted())

        return fetch(config.api.wordgroups + '/' + id + '/delete', {
            method: 'POST',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + store.getState().globalStore.token
            },
            body: JSON.stringify({remove_words: true})
            
        })
            .then(handleErrors)
            .then(res => res.json())
            .then(res => {

                dispatch(deleteWordGroupSuccess(id))
                
                dispatch(addFloatingNotification({
                    type:"warning",
                    text:i18n._('Úspešne ste odstránili skupinu slov') + ' - ' + name,
                    showBell: true
                }))

                dispatch(setLoadingCreatedWordGroups(false))

            })
            .catch((error: any)  => {
                dispatch(deleteWordGroupFailed(error))
                dispatch(setLoadingCreatedWordGroups(false))
            });
    };
}

export const deleteWordGroupStarted = () => ({
    type: DELETE_WORDGROUP_STARTED
});

export const deleteWordGroupSuccess = (id: number) => ({
    type: DELETE_WORDGROUP,
    payload: id
});

export const deleteWordGroupFailed = (error: any) => ({
    type: DELETE_WORDGROUP_FAILED,
    payload: { error }
});