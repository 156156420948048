import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IApplicationStore } from '../../../constants/store-interfaces';
import AddMenu from '../parts/header/AddMenu';
import UserBlock from '../parts/header/UserBlock';
import { setShowMenu } from '../../../actions/global/setShowMenu';
import TourDashboard from '../parts/tours/TourDashboard';
import Languages from '../parts/header/Languages';
import { NavLink } from 'react-router-dom';

interface IProps {

}

const Header: React.FC<IProps> = props => {
    const dispatch = useDispatch()

    const showMenu = useSelector((state: IApplicationStore) => state.globalStore.showMenu)
    const currentTest = useSelector((state: IApplicationStore) => state.globalStore.currentTest)

    const handleMenu = () => {
        dispatch(setShowMenu(!showMenu))
    }

    let showHeader = true

    if(currentTest !== null) {
        if(typeof currentTest.testResult !== 'undefined') {
            if(currentTest.testResult.date_finished === null) {
                showHeader = false
            }
        }
    }

    if(!showHeader) {
        return (null)
    }
    
    return (
        <aside className="app-toolbar">
            <div className="part">
                
                <div className="toolbarLeft">
                    <div className={"part menu-toggle " + ((showMenu)?'open':'close')}>
                    
                        <div className="hamburger" onClick={handleMenu}>
                            <i className="la la-bars main-button-toggle"/>
                        </div>
                    
                    </div>
                    <div className="part">
                    
                        <AddMenu/>
                    
                    </div>
                    <div className="part">
                    
                        <div className="pageTitle">
                            <NavLink to={"/app"} exact>
                                <div>
                                    <i className="la la-home"/>
                                </div>
                            </NavLink>
                        </div>
                    
                    </div>
                </div>
                
            </div>
            <div className="part">
                
                <div className="toolbarRight">
                    {/* <div className="part">
                    
                        <form className="site-search">
                            <input placeholder="Search for task and etc." type="text"/>
                            <button type="submit"><i className="la la-search"/></button>
                        </form>
                    
                    </div> */}
                    
                    <Languages/>
                    
                    <div className="part">
                    
                        <TourDashboard/>

                    </div>
                    <div className="part">
                    
                        <UserBlock/>
                    
                    </div>
                </div>
                
            </div>
        </aside>
    )
}

export default Header
