import React, {Component, PropsWithChildren} from 'react'

interface IProps {
    subtitle: string,
    text: string,
    title: string
}

class TitleText extends Component<PropsWithChildren<IProps>, any> {

    render() {
        const { subtitle, text, title }  = this.props

        return (
            <div className="part-text" data-title={title}>
                {text}
                <div className="detial">
                    {subtitle}
                </div>
            </div>
        )
    }
}

export default TitleText
