import React, {Component, PropsWithChildren } from 'react'

interface IProps {
    showIcon: boolean,
    icon?: string,
    type?: "orange" | "green",
    onClick?: boolean,
    onClickAction?: () => any,
    smallestText?: string,
    smallestTextColor?: "red" | "green"
}

class IconTextWidget extends Component<PropsWithChildren<IProps>, any> {

    render() {
        const { showIcon, icon, type, children, onClick, onClickAction, smallestText, smallestTextColor }  = this.props

        return (
            <div className="small-widget" onClick={((typeof  onClick !== 'undefined') && onClick && (typeof onClickAction !== 'undefined'))?(onClickAction):(() => null)}>
                {((typeof showIcon !== 'undefined') && showIcon && (typeof icon !== 'undefined'))?(
                    <div className="_part _left">
                        <div className={"widget-type " + ((typeof type !== 'undefined')?type:'')}>
                            <i className={"la " + icon}/>
                        </div>
                    </div>
                ):(null)}
                <div className="_part _body">
                    {children}
                </div>
                {(typeof smallestText !== 'undefined')?(
                    <div className="_part _right _align-bottom">
                        {(typeof smallestTextColor !== 'undefined')?(
                            <span className={smallestTextColor + "-text"}>{smallestText}</span>
                        ):(
                            <span>{smallestText}</span>
                        )}
                    </div>
                ):(null)}
            </div>
        )
    }
}

export default IconTextWidget
