import React, {useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import { IApplicationStore } from '../../../../constants/store-interfaces';
import config from '../../../../config';
import { handleErrors } from '../../../../utils/handleErrors';
import LoadingElement from '../../../simple/LoadingElement';
import { Trans } from '@lingui/macro';
import { i18n } from '../../../../App';
import moment from 'moment';
import { history } from '../../../../..';

interface IProps {
}

const UserWordGroupShares: React.FC<IProps> = props => {
    const [loading, setLoading] = useState<boolean>(false)
    const [shares, setShares] = useState<{
        id: number, 
        sender_id: number, 
        sender_name: string,
        sender_surname: string,
        sender_login: string,
        target_id: number,
        data: any,
        state: 'valid'|'accepted'|'rejected',
        created_at: string,
        updated_at: string,
        wgName: string
    }[]>([])
    const [refresh, setRefresh] = useState(0)

    const token = useSelector((state: IApplicationStore) => state.globalStore.token)

    useEffect(() => {
        if(token !== null) {
            setLoading(true)
            fetch(config.api.wordGroupShare, {
                method: 'get',
                credentials: 'include',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
                .then(handleErrors)
                .then(res => res.json())
                .then(json => {
                    setLoading(false)

                    if(json.status === 'success') {
                        setShares(json.data)
                    } else {
                        setShares([])

                    }
                })
                .catch((error: any)  => {
                    console.log(error)
                    setLoading(false)
                    setShares([])

                })
        }
    }, [token, refresh])

    const respond = (id: number, answer: boolean) => {
        if(token !== null) {
            setLoading(true)
            fetch(config.api.wordGroupShare + '/' + id, {
                method: 'put',
                credentials: 'include',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                body: JSON.stringify({
                    respond: answer
                })
            })
                .then(handleErrors)
                .then(res => res.json())
                .then(json => {
                    setLoading(false)

                    if(json.status === 'success' && typeof json.data !== 'undefined') {
                        if(typeof json.data[0] !== 'undefined' && typeof json.data[0].id !== 'undefined') {
                            history.push('/app/wordgroups/' + json.data[0].id)
                        } else {
                            setRefresh(refresh+1)
                        }
                    } else {
                        setRefresh(refresh+1)
                    }
                })
                .catch((error: any)  => {
                    console.log(error)
                    setLoading(false)
                    setShares([])

                })
        }
    }

    return (
        <>
            {shares.length?(
                <div className="wg-shares">
                    <LoadingElement loadingValue={loading}/>
                    <h4>
                        <Trans>Dostali ste zdieľané skupiny slovíčok</Trans>
                    </h4>
                    <ul>
                        {shares.map((s,i) => (
                            <li key={i}>
                                <div>
                                    {moment(s.created_at).format('DD.MM.YYYY HH:mm')} - <Trans>Od</Trans> <strong>{s.sender_name} {s.sender_surname}</strong> <Trans>ste dostali skupinu slovíčok</Trans> <strong>"{s.wgName}"</strong>
                                </div>
                                <div className="buttons">
                                    <div className="bttn _small _green" data-tooltip={i18n._('Prijať')} onClick={() => respond(s.id, true)}>
                                        <i className="la la-check"/>
                                    </div>
                                    <div className="bttn _small _red" data-tooltip={i18n._('Odmietnuť')} onClick={() => respond(s.id, false)}>
                                        <i className="la la-close"/>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            ):(null)}            
        </>
    )
}

export default UserWordGroupShares