import { 
    GET_WORDGROUPS_CREATED,
    GET_WORDGROUPS_ASSIGNED,
    STORE_WORDGROUPS,
    GET_WORDGROUP,
    EDIT_CREATED_WORD,
    MODIFY_WORDGROUP,
    DELETE_CREATED_WORD,
    DELETE_WORDGROUP,
    SET_LOGIN_USER_DATA,
    ASSIGN_WORD_GROUP_TO_USERGROUP,
    GET_ASSIGNED_USERGROUP_WORDGROUPS,
    MODIFY_WORDGROUP_FROM_USERGROUP,
    DELETE_WORDGROUP_FROM_USERGROUP,
    RESET_STATE,
    SET_LOGOUT_USER_DATA,
    SET_LOGOUT_USER_DATA_WRONG,
    SET_LOGIN_USER_DATA_WRONG
} from "../constants/action-types"
import { Reducer } from 'redux'
import { IWordgroupsStore } from '../constants/store-interfaces';
import { IWordgroupType, IWordType, IUWordGroupType } from '../constants/types';

const initialState: IWordgroupsStore = {
    createdWordgroups: [],
    assignedWordgroups: [],
    ugWordgroups: []
}

const reducer: Reducer<IWordgroupsStore> = (state = initialState, action) => {
    let was = false

    switch(action.type) {
        case SET_LOGIN_USER_DATA:
            state = initialState
            was = true
            break
        case GET_WORDGROUPS_CREATED: 
            state = {
                ...state,
                createdWordgroups:  action.payload,
            }
            was = true
            break
        case GET_WORDGROUPS_ASSIGNED: 
            state = {
                ...state,
                assignedWordgroups:  action.payload,
            }
            was = true
            break
        case STORE_WORDGROUPS:
            let existsWG = state.createdWordgroups.find((item: IWordgroupType) => item.id === action.payload.id)

            if(typeof existsWG  === 'undefined') {
                state = {
                    ...state,
                    createdWordgroups: state.createdWordgroups.concat([action.payload])
                }
            } else {
                state = {
                    ...state,
                    createdWordgroups: state.createdWordgroups.map(item => (item.id === action.payload.id)?action.payload:item)
                }
            }
            was = true
            break
        case GET_WORDGROUP:
            let newWordGroups = state.createdWordgroups

            let addGetted = false

            for(let UG in newWordGroups) {
                if(newWordGroups[UG].id  === action.payload.id){
                    newWordGroups[UG] = action.payload
                    addGetted = true
                }
            }

            if(!addGetted) {
                newWordGroups.push(action.payload)
            }

            state = {
                ...state,
                createdWordgroups: newWordGroups
            }
            was = true
            break
        case EDIT_CREATED_WORD:
            //Get edited word
            
            let newCreatedWordGroups: IWordgroupType[]  = []

            for(let wordGroup of state.createdWordgroups) {
                let newWordGroup = wordGroup
                
                let newWord: IWordType = {
                    id: action.payload.id,
                    word_text: action.payload.data.word_text,
                    translation: action.payload.data.translation
                }
                newWordGroup.words = newWordGroup.words.map(word  => (word.id === action.payload.id)?newWord:word)

                newCreatedWordGroups.push(newWordGroup)
            }
            
            state = {
                ...state,
                createdWordgroups: newCreatedWordGroups
            }
            was = true
            break
        case ASSIGN_WORD_GROUP_TO_USERGROUP:
            let ugs = state.ugWordgroups[action.payload.userGroupId]

            if(typeof ugs === 'undefined') {
                ugs = []
            }

            if(typeof ugs.find(i => i.id === action.payload.ug.id) !== 'undefined') {
                ugs = ugs.map(i => (i.id === action.payload.ug.id)?action.payload.ug:i)
            } else {
                ugs.push(action.payload.ug)
            }

            state = {
                ...state,
                ugWordgroups: {
                    ...state.ugWordgroups,
                    [action.payload.userGroupId]: ugs
                }
            }

            was = true
            break
        case GET_ASSIGNED_USERGROUP_WORDGROUPS:

            state = {
                ...state,
                ugWordgroups: {
                    ...state.ugWordgroups,
                    [action.payload.userGroupId]: action.payload.userGroups
                }
            }

            was = true
            break
        case DELETE_WORDGROUP_FROM_USERGROUP:
            let ugsDeleted = state.ugWordgroups[action.payload.userGroupId]

            if(typeof ugsDeleted !== 'undefined') {
                state = {
                    ...state,
                    ugWordgroups: {
                        ...state.ugWordgroups,
                        [action.payload.userGroupId]: ugsDeleted.filter((item: IUWordGroupType) => item.id !== action.payload.wgId )
                    }
                }
            }
            was = true
            break
        case MODIFY_WORDGROUP_FROM_USERGROUP: 
            let ugsModified = state.ugWordgroups[action.payload.userGroupId]

            if(typeof ugsModified !== 'undefined') {
                let wg = ugsModified.find(i => i.id === action.payload.wgId)

                if(typeof wg !== 'undefined') {
                    wg.valid_from = action.payload.valid_from

                    ugsModified = ugsModified.map(i => (i.id === action.payload.wgId)?((typeof wg !== 'undefined')?wg:i):i)
                }
            }

            state = {
                ...state,
                ugWordgroups: {
                    ...state.ugWordgroups,
                    [action.payload.userGroupId]: ugsModified
                }
            }
            was = true
            break
        case MODIFY_WORDGROUP:
            let newModifiedWordGroups: IWordgroupType[]  = [...state.createdWordgroups]

            let index = state.createdWordgroups.findIndex(item => item.id === action.payload.id)
            
            if(index > -1) {
                newModifiedWordGroups[index] = action.payload
            }
            
            state = {
                ...state,
                createdWordgroups: newModifiedWordGroups
            }
            was = true
            break
        case DELETE_WORDGROUP:
            state = {
                ...state,
                createdWordgroups: state.createdWordgroups.filter((item: IWordgroupType) => item.id !== action.payload )
            }
            was = true
            break
        case DELETE_CREATED_WORD:
            //Get edited word
            
            let modifiedCreatedWordGroups: IWordgroupType[]  = []

            for(let wordGroup of state.createdWordgroups) {
                let newWordGroup = wordGroup
                
                newWordGroup.words = newWordGroup.words.filter((word: IWordType) => word.id !== action.payload)

                modifiedCreatedWordGroups.push(newWordGroup)
            }
            
            state = {
                ...state,
                createdWordgroups: modifiedCreatedWordGroups
            }
            was = true
            break
        case SET_LOGOUT_USER_DATA:
        case SET_LOGOUT_USER_DATA_WRONG:
        case SET_LOGIN_USER_DATA_WRONG:
        case RESET_STATE:
            state = initialState
            was = true
            break
    }

    if(action.type.endsWith("_FAILED") && was) {
        state = {
            ...state,
            createdWordgroups: [],
            assignedWordgroups: []
        }
        //TODO show error
    }

    return state
}

export { reducer as wordgroupsReducer }