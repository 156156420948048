import React, { Component } from 'react';

class ModalFooter extends Component<any>{
    render() {
        return (
            <div className="modal-window-footer">
                {this.props.children}
            </div>
        )
    }
}

export default ModalFooter