import React, {Component, PropsWithChildren} from 'react'
import { IUserBasic } from '../../../constants/types';

interface IProps {
    users: IUserBasic[],
    limit: number,
    title: string
}

class UserList extends Component<PropsWithChildren<IProps>, any> {

    render() {
        const { users, limit, title }  = this.props

        let limitedUsers = users.slice(0,limit)
        return (
            <div className="part-users" data-title={title}>
                <ul>
                {limitedUsers.map((user: IUserBasic, index: number) => (
                    <li key={index}>
                        <div className="avatar">{user.login.charAt(0)}{user.login.charAt(1)}</div>
                    </li>
                ))}
                {(limitedUsers.length < users.length)?(
                    <li>
                        <div className="avatar">+{users.length - limitedUsers.length}</div>
                    </li>
                ):(null)}
                {(users.length === 0)?(
                    <li>-</li>
                ):(null)}
                </ul>
            </div>
        )
    }
}

export default UserList
