import React, {Component} from 'react';
import 'simplebar/dist/simplebar.min.css';
import './styles/line-awesome.css';
import "react-datepicker/dist/react-datepicker.css";
import 'swiper/swiper.scss'
import './App.scss'

import { connect } from 'react-redux';
import { IApplicationStore } from './constants/store-interfaces';
import { resetState } from './actions/global/resetState';

//Languages
import { I18nProvider } from '@lingui/react'
import { messages as catalogEN } from '../locales/en/messages.js'
import { messages as catalogSK } from '../locales/sk/messages.js'
import Main from './components/app/Main';
import { registerLocale } from 'react-datepicker';
import VoiceClass from './components/simple/classes/voiceClass';
import config from './config';
import ReactGA from 'react-ga';
import { i18n as i18nObject } from "@lingui/core"
import { sk } from 'date-fns/locale'

export let i18n = i18nObject

i18n.load('en', catalogEN)
i18n.load('nl', catalogSK)

i18n.activate('sk')


const mapStateToProps = (state: IApplicationStore) => ({
    loading: state.loadingStore.loading,
    userLoggedIn: state.globalStore.userLoggedIn,
    language: state.globalStore.language,
    showMenu: state.globalStore.showMenu,
    version: state.globalStore.version
})

const mapDispatchToProps = (dispatch: any) => ({
    resetState: () => dispatch(resetState())
})

export const monthsBG = [
    i18n._('Január'), 
    i18n._('Február'), 
    i18n._('Marec'), 
    i18n._('Apríl'), 
    i18n._('Máj'), 
    i18n._('Jún'), 
    i18n._('Júl'), 
    i18n._('August'), 
    i18n._('September'), 
    i18n._('Október'), 
    i18n._('November'), 
    i18n._('December')
]

class App extends Component<any> {
      
    constructor(props: any) {
        super(props)

        if(config.environment !== 'dev') {
            ReactGA.initialize('UA-175967951-1')
        }

        registerLocale('sk', sk)

        if(this.props.showMenu) {
            document.body.classList.add('menu-open')
        } else {
            document.body.classList.add('menu-closed')
        }

        VoiceClass.initialize()
    }

    componentDidUpdate(prevProps:any) {
        if((prevProps.version !== this.props.version) || (this.props.version !== config.version)) {
            this.props.resetState()
        }
    }

    componentDidMount() {
        if(config.version !== this.props.version) {
            this.props.resetState()
        }
    }

    render() {
        const { version } = this.props

        if(version !== config.version) {
            return (null)
        }
        
        return (
            <I18nProvider i18n={i18n}>
                <main className="App alphie">
                    <Main/>
                </main>
            </I18nProvider>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)

