import { setLoadingMonthRanking } from '../loadings/setLoading';
import config from '../../config';
import { handleErrors } from '../../utils/handleErrors';
import { 
    LOAD_RANKING_MONTH,
    LOAD_RANKING_MONTH_FAILED,
    LOAD_RANKING_MONTH_STARTED } from '../../constants/action-types';
import { store } from '../../store';

export function loadRankingMonths(months: string[]) {
    return (dispatch: any) => {

        dispatch(setLoadingMonthRanking(true))
        dispatch(loadMonthStarted())

        return fetch(config.api.rankingMonths, {
            method: 'post',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + store.getState().globalStore.token
            },
            body: JSON.stringify({
                months: months
            })
        })
            .then(handleErrors)
            .then(res => res.json())
            .then(res => {       
                    dispatch(setLoadingMonthRanking(false))

                    if(typeof res.data !== 'undefined') {
                        dispatch(loadMonthSuccess(res.data))
                    } else {
                        dispatch(loadMonthSuccess({}))
                    }
            })
            .catch((error: any)  => {
                dispatch(loadMonthFailed(error))
                dispatch(setLoadingMonthRanking(false))
            })
    };
}

export const loadMonthStarted = () => ({
    type: LOAD_RANKING_MONTH_STARTED
});

export const loadMonthSuccess = (data:any) => ({
    type: LOAD_RANKING_MONTH,
    payload: data
});

export const loadMonthFailed = (error: any) => ({
    type: LOAD_RANKING_MONTH_FAILED,
    payload: { error }
});