import React, {Component, PropsWithChildren} from 'react'
import { connect } from 'react-redux'
import { IApplicationStore } from '../../constants/store-interfaces';
import ModalContainer from '../app/containers/ModalContainer';
import { i18n } from '../../App';
import ModalHeader from '../app/containers/ModalElements/ModalHeader';
import ModalBody from '../app/containers/ModalElements/ModalBody';
import ModalFooter from '../app/containers/ModalElements/ModalFooter';
import { setShowConfirmModal } from '../../actions/modals/setShowModal';
import LoadingElement from './LoadingElement';

interface IStateProps {
    showConfirmModal: boolean
}

const mapStateToProps = (state: IApplicationStore): IStateProps => ({
    showConfirmModal: state.modalsStore.showConfirm
})


interface IDispatchProps {
    setShowConfirmModal: typeof setShowConfirmModal
}

const mapDispatchToProps = (dispatch: any): IDispatchProps => ({
    setShowConfirmModal: (value:boolean) => dispatch(setShowConfirmModal(value)),
})

interface IProps extends  IStateProps, IDispatchProps{
    confirmFunction: () => void,
    closeOnConfirm?: boolean,
    custom?: boolean,
    customShow?: boolean,
    customFunctionToggle?: () => any,
    title?: string,
    noOption?: string,
    yesOption?: string,
    loading?: boolean
}

interface IState {
    isCustom: boolean
}

class ConfirmModal extends Component<PropsWithChildren<IProps>, IState> {
    constructor(props:  any)  {
        super(props)

        this.state =  {
            isCustom: (typeof this.props.custom  !==  'undefined')?this.props.custom:false
        }

        this.handleConfirm = this.handleConfirm.bind(this)
        this.hideModal = this.hideModal.bind(this)
    }

    hideModal() {
        if(this.state.isCustom)  {
            if(typeof this.props.customFunctionToggle !== 'undefined') {
                this.props.customFunctionToggle()
                return
            }
        }

        this.props.setShowConfirmModal(false)
    }

    handleConfirm() {
        this.props.confirmFunction()


        if((typeof  this.props.closeOnConfirm === 'undefined') || this.props.closeOnConfirm) {
            this.hideModal()
        }
    }

    render() {
        const { children, loading, showConfirmModal, customShow, title, noOption, yesOption }  = this.props
        const { isCustom } = this.state

        let printTitle = i18n._('Upozornenie')
        let noValue = i18n._('Nie')
        let yesValue = i18n._('Áno')

        if(typeof title !== 'undefined') {
            printTitle = title
        }
        if(typeof noOption !== 'undefined') {
            noValue = noOption
        }
        if(typeof yesOption !== 'undefined') {
            yesValue = yesOption
        }

        return (
            <ModalContainer show={(isCustom && (typeof customShow !== 'undefined'))?customShow:showConfirmModal} customHide={() => this.hideModal()}>
                {(typeof loading !== 'undefined')?<LoadingElement loadingValue={loading}/>:(null)}
                <ModalHeader title={printTitle} />

                <ModalBody className="form _alt">
                    {children}
                </ModalBody>

                <ModalFooter>
                    <div className="part">
                        <button className="bttn _red href" onClick={this.hideModal}>{noValue}</button>
                    </div>
                    <div className="part">
                        <button className="bttn _full  href _green" onClick={this.handleConfirm}>{yesValue}</button>
                    </div>
                </ModalFooter>
            </ModalContainer>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmModal)
