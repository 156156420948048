import React, {Component} from 'react';
import { connect } from 'react-redux';
import { IApplicationStore } from '../../../../../constants/store-interfaces';
import { ITestResult, ITest, IUserBasic, IAddWordMapping } from '../../../../../constants/types';
import { Trans } from '@lingui/macro';
import moment from 'moment';
import AllWordsSummary from '../modalParts/summary/AllWordsSummary';
import ResultList from '../../../containers/TestElements/results/ResultList';
import Select from 'react-select';
import { i18n } from '../../../../../App';
import SummaryWordFromTo from '../../small/SummaryWordFromTo';
import Chart from 'react-apexcharts';
import TestClass from '../../../../simple/classes/testClass';

interface IDispatchProps {
}

interface IStateProps {
}

const mapStateToProps = (state: IApplicationStore): IStateProps => ({
})

const mapDispatchToProps = (dispatch: any): IDispatchProps => ({
})

interface ISelectResult {value: string, label: string, object: ITestResult | null}

interface IState {
    selectedResult: ISelectResult
}

interface IProps extends IDispatchProps, IStateProps {
    user: IUserBasic
    test: ITest
    results: ITestResult[]
}

class ModalUserDetailHomework extends Component<IProps, IState> {
    defaultValue = {
        value: '-1',
        label: i18n._('Všetky'),
        object: null
    }

    constructor(props: IProps) {
        super(props)

        this.state = {
            selectedResult: this.defaultValue
        }

        this.setResult = this.setResult.bind(this)
    }

    setResult = (inputValue:any) => {
        if(typeof inputValue === 'undefined') {
            this.setState({
                ...this.state,
                selectedResult: this.defaultValue
            })
        } else {
            this.setState({
                ...this.state,
                selectedResult: inputValue
            })
        }
    }

    render() {
        const { user, test, results } = this.props
        const { selectedResult } = this.state
        
        let latestDate: any = null

        results.forEach(r => {
            if(
                (r.result !== null) &&
                (r.user_id === user.id) &&
                (r.test_id === test.id)
            ) {
                let date = (r.date_finished !== null)?r.date_finished:r.date_started

                if(latestDate === null) {
                    latestDate = moment(date)
                } else if(latestDate.isBefore(moment(date))) {
                    latestDate = moment(date)
                }
            }
        })

        let hardWords: IAddWordMapping[] = []

        let wordResults: (IAddWordMapping & {dataX: string[], dataY: number[], percentage: number})[] = []

        results.sort((a, b) => moment(((a.date_finished !== null)?a.date_finished:a.date_started)).valueOf() - moment(((b.date_finished !== null)?b.date_finished:b.date_started)).valueOf())

        test.settings.words.forEach(w => {
            let percentage = 0
            let valid = 0
            let total = 0

            let allDataX: string[] = []
            let allDataY: number[] = []

            results.forEach(r => {
                if(
                    (r.result !== null) &&
                    (r.user_id === user.id) &&
                    (r.test_id === test.id)
                ) {
                    r.result.questions.forEach(q => {
                        if(
                            (q.word.from === w.from) &&
                            (q.word.to === w.to) 
                        ) {

                            let dataX = (r.date_finished !== null)?moment(r.date_finished).format('DD. MM. YYYY HH:mm'):moment(r.date_started).format('DD. MM. YYYY HH:mm')
                            let dataY = 0

                            if(q.valid) {
                                valid += 1
                                dataY = 1
                            }

                            total += 1

                            allDataX.push(dataX)
                            allDataY.push(dataY)
                        }
                    })
                }
            })

            if(total > 0) {
                percentage = Math.round(valid/total*100)
            }

            wordResults.push({
                ...w,
                dataX: allDataX,
                dataY: allDataY,
                percentage: percentage
            })

            if(percentage <= 40) {
                hardWords.push(w)
            }
        })

        let userResults = results.filter(r => {
            if(
                (r.result !== null) &&
                (r.user_id === user.id) &&
                (r.test_id === test.id)
            ) {
                return true
            } else {
                return false
            }
        })
        
        userResults.sort((a, b) => moment(((b.date_finished !== null)?b.date_finished:b.date_started)).valueOf() - moment(((a.date_finished !== null)?a.date_finished:a.date_started)).valueOf())

        let options: ISelectResult[] = [this.defaultValue]

        userResults.forEach(r => {
            if (typeof r.id !== 'undefined') {
                let testObject = new TestClass(test, r)

                let percentage = testObject.getPercentage()

                options.push({
                    value: r.id.toString(),
                    label: ((r.date_finished !== null)?moment(r.date_finished).format('DD. MM. YYYY HH:mm'):moment(r.date_started).format('DD. MM. YYYY HH:mm')) + ' (' + percentage + '%)',
                    object: r
                })
            }
        })

        let showResult = selectedResult

        if(userResults.length === 1) {
            let testObject = new TestClass(test, userResults[0])

            let percentage = testObject.getPercentage()

            let r = userResults[0]

            if((typeof r !== 'undefined') && (typeof r.id !== 'undefined')) {
                showResult = {
                    value: r.id.toString(),
                    label: ((r.date_finished !== null)?moment(r.date_finished).format('DD. MM. YYYY HH:mm'):moment(r.date_started).format('DD. MM. YYYY HH:mm')) + ' (' + percentage + '%)',
                    object: r
                }
            }
        }

        return (
            <div className="summary">
                <div className="summary-block">
                    <h4>
                        <i className="la la-history"/>
                        <Trans>Posledná aktivita</Trans>
                    </h4>
                    <div className="summary-content">
                        {(latestDate === null)?(
                            <Trans>Žiak nemá žiadne výsledky z tejto úlohy</Trans>
                        ):latestDate.format('DD. MM. YYYY HH:mm')}
                    </div>
                </div>
                {(userResults.length > 1)?(
                    <div className="summary-block">
                        <h4>
                            <i className="la la-language"/>
                            <Trans>Ťažké slovíčka (úspešnosť</Trans> &#60; 40%)
                        </h4>
                        <div className="summary-content">
                            {(hardWords.length > 0)?(
                                <AllWordsSummary
                                    words={hardWords}
                                    languageFrom={test.language_from}
                                    languageTo={test.language_to}
                                />
                            ):(
                                <Trans>Nemá žiadne ťažké slovíčka</Trans>
                            )}
                        </div>
                    </div>
                ):(null)}
                {(userResults.length)?(
                    <div className="summary-block">
                        {(userResults.length > 1)?(
                            <>
                                <h4>
                                    <i className="la la-bars"/>
                                    <Trans>Výsledky</Trans>
                                </h4>
                                <div className="summary-content">
                                    <Select
                                        options={options}
                                        className="customSelect"
                                        classNamePrefix="customSelectWrapper"
                                        defaultMenuIsOpen={false}  
                                        onChange={this.setResult}
                                        value={selectedResult}
                                        isSearchable={false}
                                    />
                                </div>
                            </>
                        ):(null)}
                        {(showResult !== null)?(
                            <div className="summary-content results-in-modal">
                                {(showResult.object !== null)?(
                                    <ResultList
                                        test={test}
                                        testResult={showResult.object}
                                    />
                                ):(
                                    <div className="question-results">
                                        {wordResults.map((w,i) => {
                                            let color = '#F54029'

                                            if(w.percentage > 30) {
                                                color = '#FFAB2B'
                                            }
                                            
                                            if (w.percentage >= 55) {
                                                color = '#4D7CFE'
                                            }
                                            
                                            if (w.percentage >= 80) {
                                                color = '#6DD230'
                                            }

                                            let graphOptions = {
                                                xaxis: {
                                                    categories: w.dataX,
                                                    labels: {
                                                        show: false
                                                    }
                                                },
                                                yaxis: {
                                                    min: 0,
                                                    max: 1,
                                                    tickAmount: 1,
                                                    labels: {
                                                        formatter: (value:number) => (value === 0)?i18n._('Nesprávne'):i18n._('Správne')
                                                    }
                                                },
                                                dataLabels: {
                                                    enabled: false
                                                },
                                                chart: {
                                                    toolbar: {
                                                        show: false
                                                    },
                                                    zoom: {
                                                        enabled: false
                                                    }
                                                },
                                                markers: {
                                                    size: 2
                                                },
                                                tooltip: {
                                                    shared: false,
                                                    intersect: true
                                                },
                                                colors: [color]
                                            }

                                            return (
                                                <div className="result-word-wrapper" key={i}>
                                                    <div className="langs">
                                                        <SummaryWordFromTo
                                                            word={w.from}
                                                            translation={w.to}
                                                            languageFrom={test.language_from}
                                                            languageTo={test.language_to}
                                                        />
                                                        <div className="word-chart">
                                                            <Chart
                                                                options={graphOptions}
                                                                series={[{
                                                                    name: i18n._('Výsledok'),
                                                                    data: w.dataY
                                                                }]}
                                                                type="area"
                                                                height="100"
                                                                key={i}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                )}
                            </div>
                        ):(null)}
                    </div>
                ):(null)}
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalUserDetailHomework)