import React, {Component} from 'react'
import { connect } from 'react-redux'
import { IApplicationStore } from '../../../../../constants/store-interfaces';
import ModalContainer from '../../../containers/ModalContainer';
import ModalHeader from '../../../containers/ModalElements/ModalHeader';
import { i18n } from '../../../../../App';
import ModalBody from '../../../containers/ModalElements/ModalBody';
import ModalFooter from '../../../containers/ModalElements/ModalFooter';
import { Trans } from '@lingui/macro';
import InformationBox from '../../InformationBox';
import { setShowEditUserGroupModal } from '../../../../../actions/modals/setShowModal';
import { ICreateUserGroup } from '../../../../../constants/types';
import { editUserGroup } from '../../../../../actions/userGroups/editUserGroup';
import LoadingElement from '../../../../simple/LoadingElement';

interface IStateProps {
    showEditUserGroup: boolean,
    loading: boolean
}

const mapStateToProps = (state: IApplicationStore): IStateProps => ({
    showEditUserGroup: state.modalsStore.showEditUserGroup,
    loading: state.loadingStore.loadingCreatedUserGroups
})


interface IDispatchProps {
    setShowEditUserGroupModal: typeof setShowEditUserGroupModal,
    editUserGroup: typeof editUserGroup,
}

const mapDispatchToProps = (dispatch: any): IDispatchProps => ({
    setShowEditUserGroupModal: (value:boolean) => dispatch(setShowEditUserGroupModal(value)),
    editUserGroup: (id: number, data:ICreateUserGroup) => dispatch(editUserGroup(id, data))
})

interface IProps extends  IStateProps, IDispatchProps{
    id: number,
    name: string,
    note: string,
}

interface IState {
    id: number
    new_name: string,
    new_note: string,
    isValid: boolean
}

class ModalEditUserGroup extends Component<IProps, IState> {
    constructor(props:  any)  {
        super(props)
            
        this.state = {
            id: this.props.id,
            new_name: this.props.name,
            new_note: this.props.note,
            isValid: true
        }

        this.editUserGroup = this.editUserGroup.bind(this)
        this.hideModal = this.hideModal.bind(this)
        this.handleNameChange = this.handleNameChange.bind(this)
        this.handleNoteChange = this.handleNoteChange.bind(this)
    }

    componentDidUpdate(prevProps:  IProps) {
        if(
            (prevProps.id !== this.props.id) ||
            (prevProps.name  !== this.props.name) ||
            (prevProps.note !== this.props.note)
        ){
            this.setState({
                id: this.props.id,
                new_name: this.props.name,
                new_note: this.props.note,
                isValid: true
            })
        }
    }

    editUserGroup() {
        const { new_name, new_note, id, isValid } = this.state

        if(isValid) {
            this.props.editUserGroup(id, {name: new_name,  comment: new_note})
        }
    }

    handleNameChange(e:any) {
        this.setState({
            ...this.state,
            new_name: e.target.value,
            isValid: (e.target.value.length > 0)
        })
    }

    handleNoteChange(e:any) {
        this.setState({
            ...this.state,
            new_note: e.target.value
        })
    }

    hideModal() {
        this.props.setShowEditUserGroupModal(false)
    }

    submitFormHandler(e:  any)  {
        e.preventDefault()
        this.editUserGroup()
    }

    render() {
        const { showEditUserGroup, loading }  = this.props
        const { isValid, new_name, new_note } =  this.state

        return (
            <section id="mainSection">
                <ModalContainer show={showEditUserGroup}>
                    <ModalHeader title={i18n._('Úprava triedy')}/>
                    
                    <ModalBody>
                        <LoadingElement loadingValue={loading} />
                        <form className="form _alt" onSubmit={this.submitFormHandler}>
                            <div className="form _alt">
                                <div className="input-row">
                                    <div className="part">
                                        <label htmlFor="wordgroup-name"><Trans>Názov triedy</Trans> <i>*</i></label>
                                        <div className="input-holder">
                                            <input 
                                                name={"userGroupName"}
                                                type="text"
                                                placeholder={i18n._('1.C')}
                                                onChange={this.handleNameChange}
                                                value={new_name}
                                                autoFocus={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="input-row">
                                    <div className="part">
                                        <label htmlFor="wordgroup-name"><Trans>Poznámka</Trans></label>
                                        <div className="input-holder">
                                            <input 
                                                name={"userGroupNote"}
                                                type="text"
                                                placeholder={i18n._('2. skupina')}
                                                onChange={this.handleNoteChange}
                                                value={new_note}
                                            />
                                            {(isValid)?(null):(
                                                <InformationBox type="warning" showClose={false} showBell={true}>
                                                    <Trans>Pre pokračovanie musíte zadať názov triedy!</Trans>
                                                </InformationBox>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    
                    </ModalBody>

                    <ModalFooter>
                        <div className="part">
                            <button type="button" className="ant-btn bttn _silent href" onClick={this.hideModal}><Trans>Zavrieť</Trans></button>
                        </div>
                        <div className="part">
                            <button type="button" className="ant-btn bttn _primary href" onClick={this.editUserGroup} disabled={!isValid}><Trans>Uložiť triedu</Trans></button>
                        </div>
                    </ModalFooter>
                </ModalContainer>
            </section>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalEditUserGroup)
