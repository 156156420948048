import React, {Component} from 'react';
import { connect } from 'react-redux';
import { ITableRowType, ITableCellType, ITableHeaderCellType, IActionType, IUSerInfo, IUWordGroupType } from '../../../../constants/types';
import { IApplicationStore } from '../../../../constants/store-interfaces';
import { Trans } from '@lingui/macro';
import { i18n } from '../../../../App';
import TableContainer from '../../containers/TableContainer';
import TranslateLanguages from '../../../simple/TranslateLanguages';
import NumberText from '../../../simple/tableElements/NumberText';
import DateText from '../../../simple/tableElements/DateText';
import moment from 'moment';
import ConfirmModal from '../../../simple/ConfirmModal';
import LoadingElement from '../../../simple/LoadingElement';
import InformationBox from '../InformationBox';
import { deleteWordGroupFromUserGroup } from '../../../../actions/userGroups/deleteWordGroupFromUserGroup';
import DatePicker from 'react-datepicker';
import { modifyWordGroupInUserGroup } from '../../../../actions/userGroups/modifyWordGroupInUserGroup';
import config from '../../../../config';
import AuthTeacherWrapper from '../../containers/Authentification/AuthTeacherWrapper';


interface IStateProps {
    loadingCreated: boolean,
    loadingAssigned: boolean,
    loadingWordGroups: boolean,
    userLoggedIn: boolean,
    userInfo: IUSerInfo | null,
    language: string
}

interface IDispatchProps {
    deleteWordGroupFromUserGroup: typeof deleteWordGroupFromUserGroup,
    modifyWordGroupInUserGroup: typeof modifyWordGroupInUserGroup
}

const mapStateToProps = (state: IApplicationStore): IStateProps => ({
    loadingCreated: state.loadingStore.loadingCreatedUserGroups,
    loadingAssigned: state.loadingStore.loadingAssignedUserGroups,
    loadingWordGroups: state.loadingStore.loadingAssignedWordGroups,
    userLoggedIn: state.globalStore.userLoggedIn,
    userInfo: state.globalStore.userInfo,
    language: state.globalStore.language
})

const mapDispatchToProps = (dispatch: any): IDispatchProps => ({ 
    deleteWordGroupFromUserGroup: (data: any, id: number) => dispatch(deleteWordGroupFromUserGroup(data, id)),
    modifyWordGroupInUserGroup: (ugId: number, wgId: number, data: any) =>  dispatch(modifyWordGroupInUserGroup(wgId, ugId,  data))
})


interface IProps extends IStateProps, IDispatchProps {
    currentUserGroupId: number,
    loadedWordGroups: IUWordGroupType[],
    showDetailWG: (wg: IUWordGroupType) => any,
    isTeacher: boolean,
    handleAddWG?: () => any
}

interface IState {
    showModalEditWordGroup: boolean,
    showModalRemoveWordGroup: boolean,
    selectedWordGroup: IUWordGroupType | null,
    selectedStartAssign: Date
}

class AssignedWordGroupsTable extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props)

        this.state = {
            showModalEditWordGroup: false,
            showModalRemoveWordGroup: false,
            selectedWordGroup: null,
            selectedStartAssign: new Date()
        }

        this.handleDeleteWordGroup = this.handleDeleteWordGroup.bind(this)
        this.handleEditWordGroup = this.handleEditWordGroup.bind(this)
        this.handleToggleEditWordGroup = this.handleToggleEditWordGroup.bind(this)
        this.handleToggleRemoveWordGroup = this.handleToggleRemoveWordGroup.bind(this)
        this.handleDeleteWordGroupSuccess = this.handleDeleteWordGroupSuccess.bind(this)
        this.handleEditWordGroupSuccess = this.handleEditWordGroupSuccess.bind(this)
        this.handleDateAssign = this.handleDateAssign.bind(this)
    }

    handleToggleEditWordGroup() {
        this.setState({
            ...this.state,
            showModalEditWordGroup: !this.state.showModalEditWordGroup
        })
    }


    handleToggleRemoveWordGroup() {
        this.setState({
            ...this.state,
            showModalRemoveWordGroup: !this.state.showModalRemoveWordGroup
        })
    }

    handleEditWordGroup(id: string) {
        let selected = this.props.loadedWordGroups.find(wg => wg.id === Number(id))

        if(typeof selected !== 'undefined') {
            this.setState({
                ...this.state,
                selectedWordGroup: selected,
                selectedStartAssign: moment((selected.valid_from == null)?new Date():selected.valid_from).toDate(),
                showModalEditWordGroup: true,
                showModalRemoveWordGroup: false
            })
        }
    }

    handleDeleteWordGroup(id: string) {
        let selected = this.props.loadedWordGroups.find(wg => wg.id === Number(id))

        if(typeof selected !== 'undefined') {
            this.setState({
                ...this.state,
                selectedWordGroup: selected,
                showModalEditWordGroup: false,
                showModalRemoveWordGroup: true
            })
        }
    }

    submitFormHandler(e:  any)  {
        e.preventDefault()
        this.handleEditWordGroupSuccess()
    }

    handleEditWordGroupSuccess() {
        const { selectedWordGroup } = this.state
        const { currentUserGroupId } = this.props

        let bodyRequest = {
            valid_from: this.state.selectedStartAssign.toISOString()
        }

        if(selectedWordGroup !== null) {
            this.props.modifyWordGroupInUserGroup(currentUserGroupId, selectedWordGroup.id, bodyRequest)

            this.handleToggleEditWordGroup()
        }
    }

    handleDeleteWordGroupSuccess() {
        const { selectedWordGroup } = this.state

        if(selectedWordGroup !== null) {
            this.props.deleteWordGroupFromUserGroup({wordgroups:[selectedWordGroup.id]}, this.props.currentUserGroupId)

            this.handleToggleRemoveWordGroup()
        }
    }

    handleDateAssign = (date:any) => {
        this.setState({
          selectedStartAssign: date
        });
    }

    getRows(wordGroups:  IUWordGroupType[] | undefined): ITableRowType[] {

        const { isTeacher } = this.props

        let rows: ITableRowType[]  = []

        if(typeof wordGroups !== 'undefined') {

            for(let wordgroup  of wordGroups)  {
                if(!isTeacher) {
                    //Check date
                    if(wordgroup.valid_from !== null) {
                        if(moment(wordgroup.valid_from).isAfter(moment())) {
                            continue
                        }
                    }

                    if(wordgroup.valid_until !== null) {
                        if(moment(wordgroup.valid_until).isBefore(moment())) {
                            continue
                        }
                    }
                }
                let cells: ITableCellType[] = []

                cells = [
                    {
                        content: (<div className="part-title">{wordgroup.name}</div>),
                        stringToOrder:  wordgroup.name,
                        filterString:  wordgroup.name,
                        filterByString:  true
                    },
                    {
                        content: (
                            <TranslateLanguages
                                languageFrom={wordgroup.language_from}
                                languageTo={wordgroup.language_to}
                            />
                        ),
                        stringToOrder:  wordgroup.language_from.flag_name + wordgroup.language_to.flag_name,
                        filterString: wordgroup.language_from.flag_name + wordgroup.language_to.flag_name,
                        filterByString: false,
                        selectableFilter: true,
                        selectableName: i18n._('vybrať'),
                        selectableAllText: i18n._('Všetky'),
                        selectableTitle: i18n._('Preklad'),
                        selectableObject: [
                            {
                                object: (
                                        <TranslateLanguages
                                            languageFrom={wordgroup.language_from}
                                            languageTo={wordgroup.language_to}
                                        />
                                ),
                                value: wordgroup.language_from.flag_name + wordgroup.language_to.flag_name
                            }
                        ]
                    },
                    {
                        content: (<NumberText number={wordgroup.words.length} text='' title={i18n._('Počet slovíčok')}/>),
                        stringToOrder:  Number(wordgroup.words.length).toString(),
                        filterString:  Number(wordgroup.words.length).toString(),
                        filterByString:  true
                    },
                    {
                        content: (
                            <>
                                {(wordgroup.valid_from !== null)?(
                                    <DateText 
                                        date={wordgroup.valid_from} 
                                        formatDate={'DD.MM.YYYY'} 
                                        formatTime={'HH:mm'}
                                        title={i18n._('Priradené')}
                                        tag={moment(wordgroup.valid_from).isBefore(moment())?'_green':'_red'}
                                        tooltip={moment(wordgroup.valid_from).isBefore(moment())?i18n._('Zobrazuje sa žiakom'):i18n._('Zatiaľ sa žiakom nezobrazuje')}
                                    />
                                ):(null)}
                            </>),
                        stringToOrder: (wordgroup.valid_from !== null)?wordgroup.valid_from:'',
                        filterString: (wordgroup.valid_from !== null)?wordgroup.valid_from:'',
                        filterByString: false,
                    },
                    {
                        content: (
                            <button className="bttn _small _primary _icon" onClick={() => this.props.showDetailWG(wordgroup)}>
                                <i className="la la-search-plus"/> 
                                <Trans>Detail</Trans>
                            </button>
                        ),
                        stringToOrder: wordgroup.updated_at,
                        filterString: wordgroup.updated_at,
                        filterByString: false
                    }
                ]

                rows.push({
                    cells: cells,
                    catchString: wordgroup.id.toString()
                })
            }
        }

        return rows
    }

    getHeaders(): ITableHeaderCellType[] {
        return [
            {
                content: (<span><Trans>Názov</Trans></span>),
                sortBy: false,
                sortable: true
            },
            {
                content: (<span><Trans>Preklad</Trans></span>),
                sortBy: false,
                sortable: true
            },
            {
                content: (<span><Trans>Počet slovíčok</Trans></span>),
                sortBy: false,
                sortableType: 'number',
                sortable: true
            },
            {
                content: (<span><Trans>Priradené</Trans></span>),
                sortBy: true,
                sortable: true,
                sortableType: 'date'
            },
            {
                content: (<span></span>),
                sortBy: false,
                sortable: false
            },
        ]
    }

    getActions(): IActionType[] {
        const { userInfo, userLoggedIn } = this.props

        if(userLoggedIn && (userInfo !== null) && (userInfo.role.name === config.roles.teacher)) {
            return [
                {
                    action: this.handleEditWordGroup,
                    name: i18n._('Upraviť')
                },
                {
                    action: this.handleDeleteWordGroup,
                    name: i18n._('Odstrániť'),
                    customClass: 'remove'
                }
            ]
        } else {
            return []
        }
    }

    render() {
        const { loadingAssigned, loadingCreated, loadingWordGroups, loadedWordGroups, userInfo, userLoggedIn, isTeacher } = this.props
        const { selectedWordGroup, showModalRemoveWordGroup, showModalEditWordGroup, selectedStartAssign } = this.state

        let headers: ITableHeaderCellType[] = this.getHeaders()
        let rows: ITableRowType[] = this.getRows(loadedWordGroups)
        let actions: IActionType[] = this.getActions()
        let showActions = (userLoggedIn && (userInfo !== null) && (userInfo.role.name === config.roles.teacher))?true:false

        let loading = loadingAssigned || loadingCreated || loadingWordGroups

        return (
            <div>
                <div className="rows">
                    <div className="row">
                        {(isTeacher)?(
                            <div className="row-part">
                                <InformationBox type='info' showBell={true}>
                                    <Trans>Tu sa nachádzajú všetky skupiny slovíčok ktoré sú priradené do triedy a ktoré si žiaci môžu precvičovať.</Trans>
                                    <br/>
                                    <strong>
                                        <Trans>Svojím žiakom pravidelne pridávajte skupiny slovíčok na precvičenie. Prostredníctvom aplikácie sa ich môžu učiť a precvičovať si ich. Následne im môžete vytvoriť test prostredníctvom ktorého si overíte ich pripravenosť.</Trans>
                                    </strong>
                                </InformationBox>
                            </div>
                        ):(
                            <div className="row-part">
                                <InformationBox type='info' showBell={true}>
                                    <Trans>Tieto skupiny slovíčok Vám boli priradené od učiteľa. Môžete sa ich učiť prostredníctvom testov.</Trans>
                                </InformationBox>
                            </div>
                        )}
                    </div>
                    <div className="row">
                        <div className="row-part">
                            <TableContainer
                                headers={headers}
                                rows={rows}
                                actions={actions}
                                showActions={showActions}
                                showCheckbox={false}
                                showFilter={true}
                                pageSize={12}
                                grid={true}
                                emptyText={i18n._('Zatiaľ sa vo Vašej triede nenachádzajú žiadne slovíčka.')}
                                showNew={this.props.isTeacher?true:false}
                                newTitle={i18n._('Priradiť novú skupinu slovíčok')}
                                newFunction={() => this.props.handleAddWG?this.props.handleAddWG():null}
                                showGrid={false}
                                loading={loading}
                                title={i18n._('Priradené skupiny slovíčok')}
                                defaultSort="asc"
                                mobileFixedGrid={true}
                            />
                        </div>
                    </div>
                    <AuthTeacherWrapper>
                        {(selectedWordGroup !== null)?(
                            <div>
                                <ConfirmModal
                                    confirmFunction={this.handleDeleteWordGroupSuccess}
                                    closeOnConfirm={false}
                                    yesOption={i18n._('Odstrániť')}
                                    noOption={i18n._('Zrušiť')}
                                    title={i18n._('Odstrániť skupinu slov z triedy')}
                                    custom={true}
                                    customFunctionToggle={this.handleToggleRemoveWordGroup}
                                    customShow={showModalRemoveWordGroup}
                                >
                                    <LoadingElement loadingValue={loading} />
                                    <InformationBox type={'warning'} showBell={true}>
                                        <Trans>
                                            Potvrdením odstránite skupinu slov
                                        </Trans>
                                        &nbsp; "{selectedWordGroup.name}". &nbsp;
                                        <Trans>
                                            z triedy.
                                        </Trans>&nbsp;
                                        <Trans>
                                            Všetky doterajšie výsledky testov súvisiace s touto skupinou slov sa stratia a žiaci stratia možnosť učiť sa túto skupinu slovíčok. Ste si istý že ju chcete odstrániť?
                                        </Trans>
                                    </InformationBox>
                                </ConfirmModal>

                                <ConfirmModal
                                    confirmFunction={this.handleEditWordGroupSuccess}
                                    closeOnConfirm={false}
                                    yesOption={i18n._('Upraviť')}
                                    noOption={i18n._('Zrušiť')}
                                    title={i18n._('Upraviť priradenie skupiny slov v triede')}
                                    custom={true}
                                    customFunctionToggle={this.handleToggleEditWordGroup}
                                    customShow={showModalEditWordGroup}
                                >
                                    <LoadingElement loadingValue={loading}/>
                                    <p><Trans>Môžete upraviť dátum priradenia do skupiny slovíčok.</Trans></p>

                                    <InformationBox type="warning" showBell={true}>
                                        <Trans>Úpravou sa upraví len dátum  priradenia skupiny slovíčok k tejto triede. Ak chcete upraviť slová v tejto skupine, je potrebné vykonať úpravy v detaile skupiny slovíčok.</Trans>
                                    </InformationBox>
                                    <br/>
                                    <form className="form _alt" onSubmit={this.submitFormHandler}>
                                        <div className="form _alt">
                                            <div className="input-row">
                                                <div className="part">
                                                    <label htmlFor="date-start"><Trans>Ukázať žiakom od</Trans></label>
                                                    <div className="input-holder">
                                                        <DatePicker
                                                            selected={selectedStartAssign}
                                                            onChange={this.handleDateAssign}
                                                            showTimeSelect
                                                            timeFormat="HH:mm"
                                                            timeIntervals={15}
                                                            timeCaption="Čas"
                                                            dateFormat="dd. MM. yyyy HH:mm"
                                                            locale={this.props.language}
                                                            popperClassName="with-time"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </ConfirmModal>
                            </div>
                        ):(null)}
                    </AuthTeacherWrapper>
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AssignedWordGroupsTable)