import React, { useEffect, useState } from 'react'
import { IRanking, IUSerInfo } from '../../../../constants/types';
import { useSelector } from 'react-redux';
import { IApplicationStore } from '../../../../constants/store-interfaces';
import IconWidget from './IconWidget';
import { i18n } from '../../../../App';
import { FacebookShareButton, FacebookIcon } from 'react-share';

var countries = require("i18n-iso-countries");

interface IProps {
}

const CountryPlaceWidget: React.FC<IProps> = props => {
    const loadingCountry = useSelector<IApplicationStore, boolean>(state => state.loadingStore.loadingRankingCountry)
    const rankingCountry = useSelector<IApplicationStore, {user: IRanking | null, world: IRanking[]}>(state => state.rankingStore.countryRankings)
    const userInfo = useSelector<IApplicationStore, IUSerInfo | null>(state => state.globalStore.userInfo)
    const language = useSelector<IApplicationStore, string>(state => state.globalStore.language)

    const [countryName, setCountryName] = useState(i18n._('Krajina'))

    useEffect(() => {
        countries.registerLocale(require("i18n-iso-countries/langs/" + language + ".json"))

        let newCountryName = i18n._('Krajina')

        if(userInfo !== null) {
            newCountryName = countries.getName(userInfo.country, language)
        }

        setCountryName(newCountryName)
    }, [language, userInfo])

    let sharePath = ''

    if(userInfo !== null) {
        sharePath = btoa(encodeURI(JSON.stringify({
            'r': rankingCountry.user?.rank,
            'p': rankingCountry.user?.ranking,
            'c': countryName,
            'i': 'c'
        })))
    }

    let shareBtn = (
        <FacebookShareButton
            url={'https://certificate.alphie.app/share.php?c=' + sharePath}
            quote={i18n._("Aplikácia Alphie - učenie slovíčok z ľahkosťou")}
            hashtag="#alphieapp"
            className="bttn --share"
            data-tooltip={i18n._('Zdieľať')}
        >
            <FacebookIcon size={36} />
        </FacebookShareButton>
    )
    

    return (
        <IconWidget
            title={((rankingCountry.user !== null)?rankingCountry.user.rank + '. ':'- ') + i18n._('miesto')}
            subtitle={countryName}
            icon={'la-line-chart'}
            widgetColor='orange'
            loading={loadingCountry}
            subContent={shareBtn}
        />
    )
}

export default CountryPlaceWidget