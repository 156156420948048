import React, {Component} from 'react';
import { connect } from 'react-redux';
import { IApplicationStore } from '../../../../../constants/store-interfaces';
import Select, { createFilter } from 'react-select';
import ReactCountryFlag from 'react-country-flag';
import { IoIosArrowRoundForward } from 'react-icons/io';
import { Trans } from '@lingui/macro';
import { ILanguageType } from '../../../../../constants/types';
import { i18n } from '../../../../../App';
import AlphieLanguages from '../../../../simple/classes/alphieLanguages';

interface IStateProps {
    loadingLanguages: boolean
}

const mapStateToProps = (state: IApplicationStore):IStateProps => ({
    loadingLanguages: state.loadingStore.loadingLanguages
})

const mapDispatchToProps = (dispatch: any) => ({
})

const formatOptionLabel = ({ value, label, flagName }:any) => {
    return (
        <div className={(value.disabled)?'disabled':''}>
            <ReactCountryFlag className="flag" countryCode={flagName} svg/>{label}
        </div>
    )
}

interface IProps extends IStateProps {
    setLanguageFrom: any,
    setLanguageTo: any,
    defaultLanguageFrom: any | null,
    defaultLanguageTo: any | null,
    wordGroupName: string,
    languages: ILanguageType[],
    showWordGroupNameField?: boolean,
    setWordGroupName?: any
}

class LanguageChooserBothSides extends Component<IProps> {

    onLanguageFromChange = (inputValue:any) => {
        this.props.setLanguageFrom(inputValue.object)
    }

    onLanguageToChange = (inputValue:any) => {
        this.props.setLanguageTo(inputValue.object)
    }

    onWordGroupNameChange = (event: any) => {
        let value = event.target.value

        if(
            (typeof this.props.setWordGroupName !== 'undefined') &&
            (typeof this.props.showWordGroupNameField !== 'undefined') &&
            (this.props.showWordGroupNameField)
        ) {
            this.props.setWordGroupName(value)
        }
    }

    render() {
        let { languages, defaultLanguageFrom, defaultLanguageTo, loadingLanguages, wordGroupName, showWordGroupNameField } = this.props

        //First languages
        const privates = [3, 6, 16, 24]

        let languagesOptions = []
        let privateLanguages = []
        
        for (let language of languages) {

            let optionObject = {
                value: language.id,
                object: language,
                label: AlphieLanguages.getLanguageName(language.name),
                flagName: language.flag_name
            }
            
            if(privates.includes(optionObject.value)) {
                privateLanguages.push(optionObject)
            } else {
                languagesOptions.push(optionObject)
            }
        }

        privateLanguages.sort((a: any, b:any) => a.label.localeCompare(b.label))
        languagesOptions.sort((a: any, b:any) => a.label.localeCompare(b.label))

        languagesOptions = privateLanguages.concat(languagesOptions)

        let defaultLanguageFromObject, defaultLanguageToObject = null
        let defaultLanguageFromId: any, defaultLanguageToId: any = null

        if(defaultLanguageFrom !== null) {
            defaultLanguageFromObject = languagesOptions.find((value:any, index:number) => Number(value.value) === defaultLanguageFrom.id)

            if(typeof defaultLanguageFromObject !== 'undefined') {
                defaultLanguageFromId = defaultLanguageFromObject.object.id
            }            
        }

        if(defaultLanguageTo !== null) {
            defaultLanguageToObject = languagesOptions.find((value:any, index:number) => Number(value.value) === defaultLanguageTo.id)

            if(typeof defaultLanguageToObject !== 'undefined') {
                defaultLanguageToId = defaultLanguageToObject.object.id
            }
            
        }

        return (
            <div className="form">
                <div className="input-row">
                    <h4 className="headline-divider"><Trans>Základné nastavenia</Trans></h4>
                    {((typeof showWordGroupNameField !== 'undefined') && (showWordGroupNameField))?
                    (
                        <div className="input-row">
                            <div className="part">
                                <label htmlFor="wordgroup-name"><Trans>Názov skupiny</Trans> <i>*</i></label>
                                
                                <div className="input-holder">

                                    <input type="text" placeholder={i18n._('Matrix, 1. lekcia')} onChange={this.onWordGroupNameChange} value={wordGroupName}/>
                                    
                                </div>
                            </div>
                        </div>
                    ):(null)}
                    <div className="language-selects">
                        <div className="language-selects-part">
    
                            <label><Trans>Základný jazyk</Trans></label>
    
                            <Select
                                defaultMenuIsOpen={false} 
                                formatOptionLabel={formatOptionLabel}
                                options={languagesOptions.filter(l => l.object.id !== defaultLanguageToId)}
                                className="customSelect"
                                classNamePrefix="customSelectWrapper"
                                onChange={this.onLanguageFromChange}
                                value={defaultLanguageFromObject}
                                // isOptionDisabled={option => option.value === defaultLanguageToId}
                                placeholder={i18n._('Preklad z') + '...'}
                                loadingMessage={(inputValue:any) => i18n._('Načítavam') +  '...'}
                                isLoading={loadingLanguages}
                                noOptionsMessage={() => i18n._('Nie sú k dispozícii  žiadne jazyky')}
                                filterOption={createFilter({
                                    matchFrom: 'any',
                                    stringify: option => `${option.label}`,
                                })}
                            />
                                    
                        </div>
                        <div className="language-selects-part _arrow">
                            
                            <IoIosArrowRoundForward className="icon"/>
                            
                        </div>
                        <div className="language-selects-part">
                        
                            <label><Trans>Jazyk prekladu</Trans></label>
                            <Select
                                formatOptionLabel={formatOptionLabel}
                                options={languagesOptions.filter(l => l.object.id !== defaultLanguageFromId)}
                                className="customSelect"
                                classNamePrefix="customSelectWrapper"
                                onChange={this.onLanguageToChange}
                                defaultMenuIsOpen={false}  
                                value={defaultLanguageToObject}
                                // isOptionDisabled={option => option.value === defaultLanguageFromId}
                                placeholder={i18n._('Preklad  do') + '...'}
                                loadingMessage={(inputValue:any) => i18n._('Načítavam') +  '...'}
                                isLoading={loadingLanguages}
                                noOptionsMessage={() => i18n._('Nie sú k dispozícii  žiadne jazyky')}
                                filterOption={createFilter({
                                    matchFrom: 'any',
                                    stringify: option => `${option.label}`,
                                })}
                            />
                        
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LanguageChooserBothSides)