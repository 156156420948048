import React, {Component} from 'react';
import { connect } from 'react-redux';
import { getLanguages } from '../../../../../actions/languages/getLanguages';
import { storeWordGroups } from '../../../../../actions/wordGroups/storeWordGroups';
import { ILanguageType, IWordGroupMapping, IAddWordMapping, IStepperStepType } from '../../../../../constants/types';
import { IApplicationStore } from '../../../../../constants/store-interfaces';
import LanguageChooserBothSides from '../modalParts/LanguageChooserBothSides';
import WordGroupSelection from '../modalParts/WordGroupSelection';
import AddWordRepeater from '../modalParts/AddWordRepeater';
import { i18n } from '../../../../../App';
import Stepper from '../../../containers/Stepper';
import ModalContainer from '../../../containers/ModalContainer';
import ModalHeader from '../../../containers/ModalElements/ModalHeader';
import ModalBody from '../../../containers/ModalElements/ModalBody';
import SummaryAddWords from '../modalParts/SummaryAddWords';
import LoadingElement from '../../../../simple/LoadingElement';

interface IDispatchProps {
    getAllLanguages: typeof getLanguages
    storeWordGroups: typeof storeWordGroups
}

interface IStateProps {
    languages: ILanguageType[],
    loading: boolean,
    showModal: boolean
}

const mapStateToProps = (state: IApplicationStore): IStateProps => ({
    languages: state.languageStore.languages,
    loading: state.loadingStore.loadingCreatedWordGroups,
    showModal: state.modalsStore.showAddWord
})

const mapDispatchToProps = (dispatch: any): IDispatchProps => ({
    getAllLanguages:  ()  => dispatch(getLanguages()),
    storeWordGroups: (data: any) => dispatch(storeWordGroups(data)),
})

interface IState {
    languageFrom: any | null,
    languageTo: any | null,
    wordGroups: IWordGroupMapping[],
    words: IAddWordMapping[],
    allowPrev: boolean,
    allowNext: boolean
}

interface IProps extends IDispatchProps, IStateProps {
    
}

const initState: IState = {
    languageFrom: null,
    languageTo: null,
    wordGroups: [],
    words: [{from:'', to:''}],
    allowPrev: true,
    allowNext: false
}

class ModalAddWord extends Component<IProps, IState> {

    constructor(props:any) {
        super(props)

        this.state = initState

        this.handleLanguageFrom = this.handleLanguageFrom.bind(this)
        this.handleLanguageTo = this.handleLanguageTo.bind(this)
        this.handleWordGroups  = this.handleWordGroups.bind(this)
        this.handleWords = this.handleWords.bind(this)
        this.handleSaveWordGroups = this.handleSaveWordGroups.bind(this)
        this.stepperChange = this.stepperChange.bind(this)
    }

    componentDidMount() {
        if(this.props.languages.length === 0) {
            this.props.getAllLanguages()
        }
    }

    componentDidUpdate(prevProps: IProps, ) {
        if(prevProps.showModal && !this.props.showModal) {
            this.setState(initState)
        }
    }

    handleLanguageFrom(languageObject: any) {
        let wordGroups = this.handleWordGroupRefactor(languageObject, this.state.languageTo)

        this.setState({
            ...this.state,
            languageFrom: languageObject,
            wordGroups: wordGroups,
            allowNext: ((this.state.languageTo === null) || (languageObject === null))?false:true
        })
    }

    handleLanguageTo(languageObject: any) {
        let wordGroups = this.handleWordGroupRefactor(this.state.languageFrom, languageObject)

        this.setState({
            ...this.state,
            languageTo: languageObject,
            wordGroups: wordGroups,
            allowNext: ((this.state.languageFrom === null) || (languageObject === null))?false:true
        })
    }

    //Opakovaný výber jazyka po výbere skupín (ak  skupiny nesedia z jazykom)
    handleWordGroupRefactor(languageFrom: any, languageTo: any) {
        const {wordGroups } = this.state

        let newWordGroups = []

        if((languageFrom !== null) && (languageTo !== null)) {
            let arrayOfLanguages = [languageFrom.value, languageTo.value]

            for(let wordGroup of wordGroups)  {
                if( typeof wordGroup.object !==  'undefined') {
            
                    if(
                        (arrayOfLanguages.includes(wordGroup.object.language_from.id)) &&
                        (arrayOfLanguages.includes(wordGroup.object.language_to.id))
                    ) {
                        newWordGroups.push(wordGroup)
                    }
                }  else {
                    newWordGroups.push(wordGroup)
                }
            }

            return newWordGroups
        }

        return wordGroups
    }

    handleWordGroups(wordgroups:  any[]){
        this.setState({
            ...this.state,
            wordGroups: wordgroups
        })
    }

    handleWords(words:  any[]){
        let isValidWords = true

        for(let word of  words) {
            if((word.from === '' )||(word.to === '')) {
                isValidWords = false
            }
        }

        this.setState({
            ...this.state,
            words: words,
            allowNext: isValidWords
        })
    }

    submitFormHandler(e:  any)  {
        e.preventDefault()
    }

    handleSaveWordGroups() {
        const { wordGroups, languageFrom, languageTo, words } = this.state

        let data = {}

        let wordGroupsArray = []

        for(let group of wordGroups) {
            if(group.__isNew__) {
                wordGroupsArray.push({
                    wordgroup_id: null,
                    name: group.label
                })
            } else if(typeof group.object !== 'undefined') {
                wordGroupsArray.push({
                    wordgroup_id: group.object.id,
                    name: group.label
                })
            }
        }

        let languages = {
            language_from_id: languageFrom.id,
            language_to_id: languageTo.id
        }

        let wordsArray = []

        for (let word of words) {
            wordsArray.push({
                word_text: word.from,
                translation: word.to
            })
        }

        data = {
            wordgroups: wordGroupsArray,
            language_ids: languages,
            words: wordsArray
        }
    
        this.props.storeWordGroups(data)
    }

    stepperChange(type: 'prev'|'next', stepNumber: number) {
        if(type==='prev') {
            if((stepNumber === 0) || (stepNumber === 1)) {
                this.setState({
                    ...this.state,
                    allowNext: (((this.state.languageFrom === null) || (this.state.languageTo === null))?false:true)
                })
            }
        }
    }

    render() {

        let { languages, showModal, loading } = this.props
        let { allowPrev, allowNext, languageFrom, languageTo, wordGroups, words } = this.state

        const steps:IStepperStepType[] = [
            {
                name: i18n._('Výber prekladu'),
                step: (
                    <LanguageChooserBothSides 
                        setLanguageFrom={this.handleLanguageFrom} 
                        setLanguageTo={this.handleLanguageTo} 
                        defaultLanguageFrom={languageFrom}
                        defaultLanguageTo={languageTo}
                        wordGroupName=''
                        languages={languages}/>
                )
            },
            {
                name: i18n._('Skupiny slovíčok'),
                step: (
                    <WordGroupSelection
                        selectedWordGroups={wordGroups}
                        handleWordGroups={this.handleWordGroups}
                        languageFromId={(languageFrom === null)?null:languageFrom.id}
                        languageToId={(languageTo === null)?null:languageTo.id}
                    />
                )
            },
            {
                name: i18n._('Slovíčka'),
                step: (
                    <AddWordRepeater 
                        words={words}
                        handleWords={this.handleWords}
                        languageFrom={languageFrom}
                        languageTo={languageTo}
                        isValid={allowNext}
                    />
                )
            },
            {
                name: i18n._('Zhrnutie'),
                step: (
                    <SummaryAddWords
                        languageFrom={languageFrom}
                        languageTo={languageTo}
                        words={words}
                        wordGroups={wordGroups}
                    />
                )
            }
        ]

        return (
            <section id="mainSection">
                <ModalContainer show={showModal}>
                    <ModalHeader title={i18n._('Vytvoriť nové slová')}/>
                    
                    <ModalBody>
                        <LoadingElement loadingValue={loading}/>
                        <form className="form _alt" onSubmit={this.submitFormHandler} autoComplete='off'>
                            <Stepper 
                                steps={steps} 
                                finishText={i18n._('Uložiť slovíčka')}
                                allowPrev={allowPrev}
                                allowNext={allowNext}
                                finalSubmit={this.handleSaveWordGroups}
                                showInModal={true}
                                stepChanged={this.stepperChange}
                            />
                        </form>
                    
                    </ModalBody>
                </ModalContainer>
            </section>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalAddWord)