import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Trans } from '@lingui/macro';
import { i18n } from '../../App';
import LoadingElement from '../simple/LoadingElement';
import config from '../../config';
import { store } from '../../store';
import { handleErrors } from '../../utils/handleErrors';
import { addFloatingNotification } from '../../actions/notifications/floating';
import { history } from '../../../index';
import { IFormData, getValue, validationService, getValuesObject } from '../simple/classes/validationService';
import AppInput from './AppInput';

interface IProps {

}

const RestoreForm: React.FC<IProps> = props => {
    const [loading, setLoading] = useState<boolean>(false)

    const [formPassword, setFormPassword] = useState<IFormData>({
        values: [
            {
                valid: true,
                name: 'new_password',
                value: '',
                invalidMessages: [],
                rules: [
                    {
                        type: 'required',
                        message: i18n._('Je potrebné zadať nové heslo!')
                    },
                    {
                        type: 'regex',
                        message: i18n._('Je potrebné zadať heslo, ktoré obsahuje aspoň jedno veľké písmeno, jedno malé písmeno, aspoň jedno číslo a má minimálne 5 znakov.'),
                        regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d[\]{};:=<>/_+^#$@!%*?&]{5,}$/
                    },
                    {
                        type: 'function',
                        message: i18n._('Musíte zadať rovnaké heslá!'),
                        validFunction: async (value: string) => {
                            return new Promise((resolve, reject) => {
                                let newPassword = getValue('new_password', formPassword)
                                let newPasswordCheck = getValue('new_password_check', formPassword)

                                if(
                                    typeof newPassword === 'undefined' ||
                                    typeof newPasswordCheck === 'undefined'
                                ) {
                                    resolve(true)
                                    return
                                }

                                if(typeof newPasswordCheck.value === 'string' && newPasswordCheck.value.length === 0) {
                                    resolve(true)
                                    return
                                }

                                if(newPasswordCheck.value !== newPassword.value) {
                                    resolve(false)
                                } else {
                                    resolve(true)
                                }
                            })
                        }
                    }
                ]
            },
            {
                valid: true,
                name: 'new_password_check',
                value: '',
                invalidMessages: [],
                rules: [
                    {
                        type: 'required',
                        message: i18n._('Prosím zopakujte Vaše zadané heslo!')
                    },
                    {
                        type: 'regex',
                        message: i18n._('Je potrebné zadať heslo, ktoré obsahuje aspoň jedno veľké písmeno, jedno malé písmeno, aspoň jedno číslo a má minimálne 5 znakov.'),
                        regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d[\]{};:=<>/_+^#$@!%*?&]{5,}$/
                    },
                    {
                        type: 'function',
                        message: i18n._('Musíte zadať rovnaké heslá!'),
                        validFunction: async (value: string) => {
                            return new Promise((resolve, reject) => {
                                let newPassword = getValue('new_password', formPassword)
                                let newPasswordCheck = getValue('new_password_check', formPassword)

                                if(
                                    typeof newPassword === 'undefined' ||
                                    typeof newPasswordCheck === 'undefined'
                                ) {
                                    resolve(true)
                                    return
                                }

                                if(typeof newPassword.value === 'string' && newPassword.value.length === 0) {
                                    resolve(true)
                                    return
                                }

                                if(newPasswordCheck.value !== newPassword.value) {
                                    resolve(false)
                                } else {
                                    resolve(true)
                                }
                            })
                        }
                    }
                ]
            }
        ],
        valid: true
    })

    const handleSubmitRequest = (e:any) => {

        e.preventDefault();

        validationService(formPassword).then(response => {
            setFormPassword(response)

            if(response.valid) {
                let values = getValuesObject(['new_password', 'new_password_check'], formPassword)

                setLoading(true)

                const params = new URLSearchParams(window.location.search);

                const code = params.get('code')

                if(code === null) {
                    return
                }
                
                let sendData = {
                    'new_password': values['new_password'],
                    'restoration_code': code
                }

                fetch(config.api.restoration, {
                    method: 'post',
                    credentials: 'include',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + store.getState().globalStore.token
                    },
                    body: JSON.stringify(sendData)
                })
                    .then(handleErrors)
                    .then(res => res.json())
                    .then(json => {
                        if(json.status === 'failed') {
                            setLoading(false)
                            if(json.message === 'restoration_invalid') {
                                store.dispatch(addFloatingNotification({
                                    type:"warning",
                                    text:i18n._('Použili ste expirovaný odkaz. Požiadajte o opätovné obnovenie hesla!'),
                                    showBell: true
                                }))
                            }

                            if(json.message === 'invalid_restoration_code' || json.message === 'user_doesnt_exists') {
                                store.dispatch(addFloatingNotification({
                                    type:"warning",
                                    text:i18n._('Použili ste neplatný odkaz!'),
                                    showBell: true
                                }))
                            }
                        } else {
                            store.dispatch(addFloatingNotification({
                                type:"success",
                                text:i18n._('Vaše heslo bolo úspešne zmenené, môžete sa prihlásiť.'),
                                showBell: true
                            }))
                            history.push('/login')
                        }
                    })
                    .catch((error: any)  => {
                        setLoading(false)
                        store.dispatch(addFloatingNotification({
                            type:"warning",
                            text:i18n._('Nepodarilo sa odoslať formulár, skúste neskôr, alebo sa na nás obráťte prostredníctvom e-mailu na info@alphie.app'),
                            showBell: true
                        }))
                    })
            }
        })

    }

    return (
            <form onSubmit={handleSubmitRequest} className="form _register">
                
                <LoadingElement loadingValue={loading}/>
                
                <div className="input-row">
                    <div className="part">
                        <label htmlFor="new_password"><Trans>Nové heslo</Trans> <i>*</i></label>
                        
                        <div className="input-holder">
                            <AppInput
                                type="password"
                                id="new_password"
                                name="new_password"
                                formData={formPassword}
                                setFormData={setFormPassword}
                                autoComplete={false}
                                checkOthers={['new_password_check']}
                            />
                        </div>
                        
                    </div>
                </div>
                <div className="input-row">
                    <div className="part">
                        <label htmlFor="new_password_check"><Trans>Zopakovať nové heslo</Trans> <i>*</i></label>
                        
                        <div className="input-holder">
                            <AppInput
                                type="password"
                                id="new_password_check"
                                name="new_password_check"
                                formData={formPassword}
                                setFormData={setFormPassword}
                                autoComplete={false}
                                checkOthers={['new_password']}
                            />
                        </div>
                        
                    </div>
                </div>
                
                <div className="input-row">
                    <div className="part">
                        
                        <input type="submit" value={i18n._("Požiadať o obnovenie hesla")} className="ant-input bttn _primary _full"/>
                        
                    </div>
                </div>
                
                <div className="input-row">
                    <div className="part text-center">
                        
                        <p><Trans>Už máš účet?</Trans> <NavLink to="/login" className="link"><Trans>Prihlás sa!</Trans></NavLink></p>
                    
                        <p>
                           <Trans>Nemáš účet?</Trans> <NavLink to="/register" className="link"><Trans>Zaregistruj sa</Trans></NavLink>
                        </p>
                    </div>
                </div>
                
            </form>
    )
}

export default RestoreForm