import React, { useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import TestResult from '../containers/TestElements/TestResult';
import ReactGA from 'react-ga';
import { useDispatch, useSelector } from 'react-redux';
import { updateCurrentTest } from '../../../actions/global/updateCurrentTest';
import { IApplicationStore } from '../../../constants/store-interfaces';
import NoMatchPage from './NoMatchPage';

type PathParamsType = {
    id: string
}

interface IProps extends RouteComponentProps<PathParamsType> {
}

const TestResultPage: React.FC<IProps> = props => {
    const dispatch = useDispatch()

    const currentTest = useSelector((state: IApplicationStore) => state.globalStore.currentTest)
    
    useEffect(() => {
        ReactGA.pageview('Výsledok cvičenia')

        return () => {
            dispatch(updateCurrentTest(null))
        }
    }, [dispatch])
    
    if(currentTest === null) {
        return (<NoMatchPage/>)
    }

    return (
        <section>
            <TestResult currentTest={currentTest}/>
        </section>
    )
}

export default withRouter(TestResultPage)