import React, {Component } from 'react'
import { ITestResult, IUserBasic } from '../../../constants/types';
import Chart from "react-apexcharts";
import { i18n } from '../../../App';
import { Trans } from '@lingui/macro';

interface IProps {
    results: ITestResult[]
    users: IUserBasic[]
    openModal: (id: number) => any
}

class ResultsGraphTeacherTestWidget extends Component<IProps, any> {
    
    render() {
        const { results, users, openModal }  = this.props

        let categories: string[] = []
        let values: number[] = []
        let colors: string[] = []

        let chartData: {
            user_id: number | undefined,
            name: string,
            value: number
        }[] = []

        results.forEach((r,i) => {
            let validAnswers = 0
    
            if(r.result !== null) {
                r.result.questions.forEach(q => {
                    validAnswers += (q.valid)?1:0
                })

                let user = users.find(u => u.id === r.user_id)

                let name = i18n._('Žiak') + ' ' + (i+1)

                if(typeof user !== 'undefined') {
                    name = user.f_name
                } else {
                    name = i18n._('Žiak') + ' ' + (i+1)
                }

                chartData.push({
                    user_id: r.user_id,
                    name: name,
                    value: Math.round(validAnswers/r.result.questions.length*100)
                })

            }
        })

        chartData.sort((a, b) => a.value-b.value)

        chartData.forEach(v => {
            values.push(v.value)
            categories.push(v.name)
        })

        values.forEach(v => {
            let color = '#F54029'

            if(v > 30) {
                color = '#FFAB2B'
            }
            
            if (v >= 55) {
                color = '#4D7CFE'
            }
            
            if (v >= 80) {
                color = '#6DD230'
            }

            colors.push(color)
        })

        let options = {
            xaxis: {
                categories: categories
            },
            yaxis: {
                min: 0,
                max: 100,
                tickAmount: 10,
                labels: {
                    formatter: (value:number) => value.toFixed(0) +' %',
                }
            },
            chart: {
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false
                },
                events: {
                    dataPointSelection: function (event:any, chartContext:any, config:any) {
                        let result = chartData[config.dataPointIndex]

                        if(typeof result !== 'undefined') {
                            if(typeof result.user_id !== 'undefined') {
                                openModal(result.user_id)
                            }
                        }
                    }
                }
            },
            colors: colors,
            markers: {
                size: 4
            },
            dataLabels: {
                enabled: false
            },
            plotOptions: {
                bar: {
                    columnWidth: '50%',
                    distributed: true,
                }
            },
            legend: {
                show: false
            }
        }

        return (
            <div className="small-widget">
                <div className="_part _left">
                    <h3><Trans>Výsledky žiakov</Trans></h3>
                    <Chart
                        options={options}
                        series={[{
                            name: i18n._('Výsledok'),
                            data: values
                        }]}
                        type="bar"
                        height="300"
                    />
                </div>
            </div>
        )
    }
}

export default ResultsGraphTeacherTestWidget
