import React, { useEffect, useState } from 'react'
import { INotificationApp } from '../../../../../constants/types';
import { useSelector } from 'react-redux';
import { IApplicationStore } from '../../../../../constants/store-interfaces';
import { getNotifications } from '../../../../../actions/global/getNotifications';
import moment from 'moment';
import InformationBox from '../../../../app/parts/InformationBox';

interface IProps {

}

const NotificationsApp: React.FC<IProps> = props => {
    const [notifications, setNotifications] = useState<INotificationApp[]>([])
    const language = useSelector<IApplicationStore, string>(state => state.globalStore.language)

    useEffect(() => {
        let isMounted = true

        getNotifications()
            .then((json: any) => {
                if(isMounted && typeof json !== 'undefined') {
                    setNotifications(json)
                }
            })

        return () => { isMounted = false }
    }, [])
    
    let activeNotifications = notifications.filter(n => {
        if(
            (moment(n.valid_from).isBefore(moment())) &&
            (moment(n.valid_to).isAfter(moment()))
        ) {
            return true
        } else {
            return false
        }
    })

    return (   
        <>
            {(activeNotifications.length > 0)?(
                <div className="rows">
                    <div className="row">
                        <div className="row-part">
                            {activeNotifications.map((n,i) => {                                        
                                if(typeof n.content[language] !== 'undefined') {
                                    return (
                                        <InformationBox type={n.notif_type} showBell={true} showClose={false} key={i}>
                                            {n.content[language]}
                                        </InformationBox>
                                    )
                                } else {
                                    return (null)
                                }
                            })}
                            <br/>
                        </div>
                    </div>
                </div>
            ):(null)}
        </>
    )
}

export default NotificationsApp