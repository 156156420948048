import React, {useEffect, useState} from 'react';
import { IApplicationStore } from '../../../../constants/store-interfaces';
import { ITestResult, ITest } from '../../../../constants/types';
import moment from 'moment';
import LoadingElement from '../../../simple/LoadingElement';
import { useSelector } from 'react-redux';
import Chart from 'react-apexcharts';
import { i18n } from '../../../../App';
import TestClass from '../../../simple/classes/testClass';

interface IProps {
    userResults: ITestResult[]
    tests: ITest[]
}

interface IGraphData {
    day: string,
    results: {
        test: ITest,
        result: ITestResult,
        ranking: number
    }[]
}

const UserActivityGraph: React.FC<IProps> = props => {

    const loading = useSelector((state: IApplicationStore) => state.loadingStore.loadingCreatedUserGroups)
    const loadingAssigned = useSelector((state: IApplicationStore) => state.loadingStore.loadingAssignedTests)
    const loadingTestResults = useSelector((state: IApplicationStore) => state.loadingStore.loadingTestResults)

    const [data, setData] = useState<IGraphData[]>([])

    useEffect(() => {
        let results = props.userResults
        let newData: IGraphData[] = []

        results.sort((a, b) => moment(((b.date_finished !== null)?b.date_finished:b.date_started)).valueOf() - moment(((a.date_finished !== null)?a.date_finished:a.date_started)).valueOf())

        results.forEach(r => {
            let test = props.tests.find(t=> t.id === r.test_id)

            if(typeof test !== 'undefined') {
                let testObject = new TestClass(test, r)

                let dayObject = newData.find(d => d.day === moment(r.date_finished).format('YYYY-MM-DD'))

                newData = newData.filter(d => d.day !== moment(r.date_finished).format('YYYY-MM-DD'))
            
                if(typeof dayObject === 'undefined') {
                    dayObject = {
                        day: moment(r.date_finished).format('YYYY-MM-DD'),
                        results: [{
                            test: test,
                            result: r,
                            ranking: testObject.getTestRate()
                        }]
                    }
                } else {
                    dayObject.results.push({
                        test: test,
                        result: r,
                        ranking: testObject.getTestRate()
                    })
                }

                newData.push(dayObject)
            }
        })

        newData.sort((a, b) => moment(a.day).valueOf() - moment(b.day).valueOf())

        var getDaysArray = function(s:any,e:any) {for(var a=[],d=new Date(s);d<=e;d.setDate(d.getDate()+1)){ a.push(new Date(d));}return a;}

        if(newData.length > 0) {
            let days = getDaysArray(new Date(newData[0].day), new Date())

            let dayStrings = days.map(d => moment(d).format('YYYY-MM-DD'))

            dayStrings.forEach(d => {
                if(typeof newData.find(nD => nD.day === d) === 'undefined') {
                    newData.push({
                        day: d,
                        results: []
                    })
                }
            })
        }

        newData.sort((a, b) => moment(a.day).valueOf() - moment(b.day).valueOf())

        setData(newData)
    }, [props.userResults, props.tests])

    let options = {
        chart: {
            type: 'line',
            toolbar: {
                show: false
            },
            zoom: {
                enabled: false
            },
        },
        stroke: {
            width: [0, 2],
            curve: 'smooth'
        },
        dataLabels: {
            enabled: false
        },
        labels: data.map(d => d.day),
        xaxis: {
            type: 'datetime'
        },
        yaxis: [{
            title: {
                text: i18n._('Získané body'),
            },
        }, {
          opposite: true,
          title: {
            text: i18n._('Aktivita')
          }
        }]
    }

    let series = [{
        name: i18n._('Získané body'),
        type: 'column',
        data: data.map(d => {
            let result = 0
            d.results.forEach(r => {
                result += r.ranking
            })

            return result
        })
      }, {
        name: i18n._('Aktivita (počet vykonaných cvičení/testov)'),
        type: 'line',
        data: data.map(d => d.results.length)
    }]

    return (
            <div className="widget-holdeer">
                <LoadingElement loadingValue={loading || loadingTestResults || loadingAssigned} />
                
                <Chart
                    options={options}
                    series={series}
                    type="area"
                    height="200"
                />
            </div>
    )
}

export default UserActivityGraph