import { setLoadingLanguages } from '../loadings/setLoading';
import { GET_LANGUAGES, GET_LANGUAGES_FAILED, GET_LANGUAGES_STARTED } from '../../constants/action-types';
import config from '../../config';
import { handleErrors } from '../../utils/handleErrors';
import { ILanguageType } from '../../constants/types';
import { store } from '../../store';
import AlphieLanguages from '../../components/simple/classes/alphieLanguages';

export function getLanguages() {
    return (dispatch: any) => {

        dispatch(setLoadingLanguages(true))
        dispatch(getLanguagesStarted())

        return fetch(config.api.languages, {
            method: 'get',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + store.getState().globalStore.token
            }
        })
            .then(handleErrors)
            .then(res => res.json())
            .then(res => { 

                let languagedata = AlphieLanguages.filterLanguages(res.data)

                dispatch(getLanguagesSuccess(languagedata))

                dispatch(setLoadingLanguages(false))

            })
            .catch((error: any)  => {
                dispatch(getLanguagesFailed(error))
                dispatch(setLoadingLanguages(false))
            });
    };
}

export const getLanguagesStarted = () => ({
    type: GET_LANGUAGES_STARTED
});

export const getLanguagesSuccess = (data:ILanguageType[]) => ({
    type: GET_LANGUAGES,
    payload: data
});

export const getLanguagesFailed = (error: any) => ({
    type: GET_LANGUAGES_FAILED,
    payload: { error }
});