import React, {Component} from 'react';
import { connect } from 'react-redux';
import { IUserGroupType, ITableRowType, ITableCellType, ITableHeaderCellType } from '../../../../constants/types';
import { loadAssignedUserGroups } from '../../../../actions/userGroups/loadAssignedUserGroups';
import { IApplicationStore } from '../../../../constants/store-interfaces';
import UserList from '../../../simple/tableElements/UserList';
import NumberText from '../../../simple/tableElements/NumberText';
import { i18n } from '../../../../App';
import { NavLink } from 'react-router-dom';
import { Trans } from '@lingui/macro';
import TableContainer from '../../containers/TableContainer';

interface IStateProps {
    assignedUserGroups: IUserGroupType[],
    loadingAssigned: boolean,
}

interface IDispatchProps {
    loadAssignedUserGroups: typeof loadAssignedUserGroups
}

const mapStateToProps = (state: IApplicationStore): IStateProps => ({
    assignedUserGroups: state.userGroupsStore.assignedUserGroups,
    loadingAssigned: state.loadingStore.loadingAssignedUserGroups
})

const mapDispatchToProps = (dispatch: any): IDispatchProps => ({
    loadAssignedUserGroups: () => dispatch(loadAssignedUserGroups())
})

interface IProps extends IStateProps, IDispatchProps {

}

interface IState {
}

class AssignedUserGroupsTable extends Component<IProps, IState> {
    constructor(props: any) {
        super(props) 

        this.state = {
        }
    }

    componentDidMount() {
        this.props.loadAssignedUserGroups()
    }

    getRows(userGroups:  IUserGroupType[]): ITableRowType[] {
        let rows: ITableRowType[] = []

        for(let userGroup  of userGroups)  {
            let cells: ITableCellType[] = []

            cells = [
                {
                    content: (<div className="part-title">{userGroup.name}</div>),
                    stringToOrder:  userGroup.name,
                    filterString:  userGroup.name,
                    filterByString:  true
                },
                {
                    content: (<UserList users={userGroup.assigned_users} limit={5} title={i18n._('Žiaci')}/>),
                    stringToOrder:  userGroup.name,
                    filterString:  userGroup.name,
                    filterByString:  true //TODO správny názov navyhľadávanie (mená žiakov!)
                },
                {
                    content: (<NumberText number={userGroup.assigned_wordgroups.length} text='' title={i18n._('Skupiny slovíčok')}/>),
                    stringToOrder:  userGroup.name,
                    filterString:  userGroup.name,
                    filterByString:  false
                },
                {
                    content: (
                        <NavLink to={"/app/classes/" + userGroup.id} target="_self" className="bttn _small _primary _icon">
                            <Trans>Detail</Trans>
                        </NavLink>
                    ),
                    stringToOrder: userGroup.name,
                    filterString: userGroup.name,
                    filterByString: false
                }
            ]

            rows.push({
                cells: cells,
                catchString: '_' +  userGroup.id
            })
        }

        return rows
    }

    getHeaders(): ITableHeaderCellType[] {
        return [
            {
                content: (<span><Trans>Názov</Trans></span>),
                sortBy: true,
                sortable: true
            },
            {
                content: (<span><Trans>Žiaci</Trans></span>),
                sortBy: false,
                sortable: false
            },
            {
                content: (<span><Trans>Skupiny slovíčok</Trans></span>),
                sortBy: false,
                sortable: false
            },
            {
                content: (<span><Trans>Detail skupiny</Trans></span>),
                sortBy: false,
                sortable: false
            }
        ]
    }

    render() {
        const { assignedUserGroups, loadingAssigned } = this.props

        let headers: ITableHeaderCellType[] = this.getHeaders()
        let rows: ITableRowType[] = this.getRows(assignedUserGroups)

        return (
            <TableContainer
                headers={headers}
                rows={rows}
                showActions={false}
                showCheckbox={false}
                showFilter={true}
                pageSize={12}
                grid={true}
                title={i18n._('Moje triedy')}
                showNew={false}
                emptyText={i18n._('Aktuálne sa nenachádzate v žiadnej triede')}
                emptySubtitle={i18n._('Pre efektívne učenie požiadajte svojho učiteľa, aby Vám vytvoril spoločnú triedu.')}
                loading={loadingAssigned}
                mobileFixedGrid={true}
            />
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AssignedUserGroupsTable)