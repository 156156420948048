import React, { useState, useEffect } from 'react'
import { ITestResult } from '../../../../constants/types';
import { useSelector } from 'react-redux';
import { IApplicationStore } from '../../../../constants/store-interfaces';
import IconWidget from './IconWidget';
import { i18n } from '../../../../App';

interface IProps {
}

const TotalPointsWidget: React.FC<IProps> = props => {
    const userTestResults = useSelector<IApplicationStore, ITestResult[]>(state => state.testsStore.testResults)
    const loadingTestResults = useSelector<IApplicationStore, boolean>(state => state.loadingStore.loadingTestResults)
    const loadingWorld = useSelector<IApplicationStore, boolean>(state => state.loadingStore.loadingRankingWorld)
    const loadingCountry = useSelector<IApplicationStore, boolean>(state => state.loadingStore.loadingRankingCountry)
    
    const [totalPoints, setTotalPoints] = useState(0)

    useEffect(() => {
        let newTotalPoints = 0

        userTestResults.forEach(r => {
            newTotalPoints += r.test_rate 
        })

        setTotalPoints(newTotalPoints)
    }, [userTestResults])

    let rateTitle = i18n._('bodov')

    if((totalPoints > 1) && (totalPoints <= 4)) {
        rateTitle = i18n._('body')
    } else if (totalPoints === 1) {
        rateTitle = i18n._('bod')
    }

    return (
        <IconWidget
            title={'+ ' + totalPoints + ' ' + rateTitle}
            subtitle={i18n._('Celkovo')}
            icon={'la-area-chart'}
            widgetColor='primary'
            loading={loadingTestResults || loadingWorld || loadingCountry}
        />
    )
}

export default TotalPointsWidget