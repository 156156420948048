import React, {useEffect} from 'react';
import { useSelector } from 'react-redux';
import { IApplicationStore } from '../../../constants/store-interfaces';
import LoginForm from '../../forms/LoginForm';
import { Trans } from '@lingui/macro';
import { Redirect, withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import ReactGA from 'react-ga';
import Logo from '../global/Logo';
import InformationBox from '../parts/InformationBox';
import AuthForm from '../../forms/AuthForm';

export interface iProps extends RouteComponentProps<{
    type: 'success'|'error'
}>{

}

const LoginPage: React.FC<iProps> = props => {    
    const userLoggedIn = useSelector((state: IApplicationStore) => state.globalStore.userLoggedIn)

    useEffect(() => {
        ReactGA.pageview('Prihlásenie')
    }, [])

    let redirect = null

    if(userLoggedIn) {
        redirect = (<Redirect
            to={{
                pathname:"/app"
            }}
        />)
    }

    const queryParams = new URLSearchParams(window.location.search)
    const token = queryParams.get("token")

    return (
        <div className="inner _radius">
            {redirect}
            {(typeof props.match.params.type !== 'undefined')?(
                <>
                    <div className="login-head">
                        <div className="login-logo">
                            <Logo/>

                        </div>
                    </div>
                    <AuthForm type={props.match.params.type} token={token}/>
                </>
            ):(
                <>
                    <div className="login-head">
                        <div className="login-logo">
                            <Logo/>

                        </div>
                        <h1 className="text-center"><Trans>Prihlásenie</Trans></h1>
                        {(props.match.params.type === 'error')?(
                            <InformationBox type="warning" showBell={true}>
                                <Trans>Nepodarilo sa prihlásiť. Vyskúšajte prosím neskôr alebo nás kontaktujte na info@alphie.app</Trans>
                            </InformationBox>
                        ):(null)}
                    </div>
                    <LoginForm/>
                </>
            )}
        </div>
    )
}

export default withRouter(LoginPage)
