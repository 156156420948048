import React from 'react'
import { useSelector } from 'react-redux';
import { IApplicationStore } from '../../../../constants/store-interfaces';
import LoadingElement from '../../../simple/LoadingElement';

interface IProps {

}

const LoaderMainApp: React.FC<IProps> = props => {
    const loading = useSelector((state: IApplicationStore) => state.loadingStore.loadingCreatedTests)

    return (
        <LoadingElement loadingValue={loading}/>
    )
}

export default LoaderMainApp
