import { 
    ADD_FLOATING_NOTIFICATION,
    REMOVE_FLOATING_NOTIFICATION,
    UPDATE_NOTIFICATIONS,
    RESET_STATE
} from "../constants/action-types"
import { Reducer } from 'redux'
import { INotificationsStore } from '../constants/store-interfaces';
import { INotificationFull } from '../constants/types';

const initialState: INotificationsStore = {
    floatingNotificationId: 1,
    floatingNotifications: []
}

const reducer: Reducer<INotificationsStore> = (state = initialState, action) => {
    let was = false

    switch(action.type) {
        case ADD_FLOATING_NOTIFICATION:
            let newNotification: INotificationFull = action.payload

            newNotification.id = state.floatingNotificationId+1
            newNotification.validFrom = new Date()
            newNotification.goingToShow = true
            newNotification.goingToHide = false

            state = {
                ...state,
                floatingNotifications: [
                    ...state.floatingNotifications,
                    newNotification
                ],
                floatingNotificationId: state.floatingNotificationId+1
            }
            was = true
            break
        case REMOVE_FLOATING_NOTIFICATION:
            let index = state.floatingNotifications.findIndex((notif: INotificationFull) => notif.id === action.payload)
            
            if(typeof index !== 'undefined') {
                state =  {
                    ...state,
                    floatingNotifications: [
                        ...state.floatingNotifications.slice(0, index),
                        ...state.floatingNotifications.slice(index+1)
                    ]
                }
            }
            was = true
            break
        case UPDATE_NOTIFICATIONS:
            state = {
                ...state,
                floatingNotifications: action.payload
            }
            was = true
            break
        //If wrong  login
        case RESET_STATE:
            state = initialState
            was = true
            break
    }

    if(action.type.endsWith("_FAILED") && was) {
        //TODO show error
    }

    return state
}

export { reducer as notificationsReducer }