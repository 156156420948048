import React, {Component} from 'react';
import { connect } from 'react-redux';
import { IApplicationStore } from '../../../../../constants/store-interfaces';
import { IStepperStepType, IWordType, ITestSettings, ETestDifficulty, ETestType, ITestCreate, IWordGroupMapping, IAddWordMapping, IUserGroupType, IWordgroupType, ETestUgType } from '../../../../../constants/types';
import { i18n } from '../../../../../App';
import ModalContainer from '../../../containers/ModalContainer';
import ModalHeader from '../../../containers/ModalElements/ModalHeader';
import ModalBody from '../../../containers/ModalElements/ModalBody';
import Stepper from '../../../containers/Stepper';
import TestWordSelection from '../modalParts/TestWordSelection';
import TestSettings from '../modalParts/TestSettings';
import TestSummary from '../modalParts/TestSummary';
import { createTest } from '../../../../../actions/tests/createTest';
import LoadingElement from '../../../../simple/LoadingElement';
import moment from 'moment';

interface IDispatchProps {
    createTest: typeof createTest,
}

interface IStateProps {
    loading: boolean
}

const mapStateToProps = (state: IApplicationStore): IStateProps => ({
    loading: state.loadingStore.loadingCreatedTests
})

const mapDispatchToProps = (dispatch: any): IDispatchProps => ({
    createTest: (test: ITestCreate, type: 'ug' | 'self') => dispatch(createTest(test, type))
})

interface IState {
    allowPrev: boolean,
    allowNext: boolean,
    test: ITestCreate
}

interface IProps extends IDispatchProps, IStateProps {
    userGroup: IUserGroupType,
    wordGroups: IWordgroupType[],
    showModal: boolean,
    testType: ETestUgType
    hideModal: () => any
}

const initState: IState = {
    allowPrev: true,
    allowNext: false,
    test: {
        valid_from: moment(new Date()).format(),
        valid_until: null,
        name: '',
        usergroup_id: null,
        language_ids: {
            language_from_id: 1,
            language_to_id: 1
        },
        settings: {
            difficulty: ETestDifficulty.easy,
            type: ETestType.random,
            time: null,
            words: [],
            wordGroups: [],
            diacritic: true,
            email: true
        }
    }
}

class ModalAddNewUserGroupTest extends Component<IProps, IState> {

    constructor(props:IProps) {
        super(props)

        this.state = this.getNewState(props)

        this.handleSaveTest = this.handleSaveTest.bind(this)
        this.handleWords = this.handleWords.bind(this)
        this.handleWordGroups = this.handleWordGroups.bind(this)
        this.handleTestSettings = this.handleTestSettings.bind(this)
        this.handleTest = this.handleTest.bind(this)
        this.getNewState = this.getNewState.bind(this)
    }

    componentDidMount() {
        this.setState(this.getNewState(this.props))
    }

    componentDidUpdate(prevProps: IProps, ) {
        if(prevProps.showModal !== this.props.showModal) {
            this.setState(this.getNewState(this.props))
        }
    }

    getNewState(props: IProps):IState {
        let validUntil = new Date()
        validUntil.setDate(new Date().getDate() + 7)

        return {
            ...initState,
            test: {
                ...initState.test,
                settings: {
                    ...initState.test.settings,
                    ugType: props.testType
                },
                usergroup_id: this.props.userGroup.id,
                valid_from: new Date().toISOString(),
                valid_until: validUntil.toISOString()
            }
        }
    }

    submitFormHandler(e:  any)  {
        e.preventDefault()
    }

    handleSaveTest() {
        this.props.createTest(this.state.test, 'ug')
    }

    handleWordGroups(wordgroups: IWordGroupMapping[]|null){
        
        let newName = i18n._('Písomka') + ' '

        if((typeof this.state.test.settings.ugType !== 'undefined') && (this.state.test.settings.ugType === ETestUgType.homework)) {
            newName = i18n._('Domáca úloha') + ' '
        }

        if(wordgroups !== null) {
            wordgroups.forEach(wg => {
                newName += wg.label + ', '
            })
        }

        newName = newName.slice(0, -2)

        if(wordgroups === null || (wordgroups.length === 0)) {
            this.setState({
                ...this.state,
                test: {
                    ...this.state.test,
                    settings: {
                        ...this.state.test.settings,
                        wordGroups: [],
                        words: []
                    },
                    name: newName
                }
            })
        } else {

            let wordgroupsId = wordgroups.map(i => i.object.id)

            let language_ids = {
                language_from_id: 1,
                language_to_id: 1
            }

            if(wordgroups.length) {
                language_ids.language_from_id = wordgroups[0].object.language_from.id
                language_ids.language_to_id = wordgroups[0].object.language_to.id
            }

            let words: IAddWordMapping[] = []

            wordgroups.forEach(wg => {
                wg.object.words.forEach(w => {
                    words.push({
                        from: w.word_text,
                        to: w.translation
                    })
                })
            })

            this.setState({
                ...this.state,
                test: {
                    ...this.state.test,
                    settings: {
                        ...this.state.test.settings,
                        wordGroups: wordgroupsId,
                        words: words
                    },
                    name: newName,
                    language_ids: language_ids
                },
                allowNext: (words.length)?true:false
            })
        }
        
    }

    handleWords(words:  IWordType[] | null){
        if(words === null) {
            this.setState({
                ...this.state,
                test: {
                    ...this.state.test,
                    settings: {
                        ...this.state.test.settings,
                        words: []
                    }
                },
                allowNext: false
            })
        } else {
            let wordsAdd: {from: string, to: string}[] = words.map(w => { return {from: w.word_text, to: w.translation}})

            this.setState({
                ...this.state,
                test: {
                    ...this.state.test,
                    settings: {
                        ...this.state.test.settings,
                        words: wordsAdd
                    }
                },
                allowNext: (words.length)?true:false
            })
        }
    }

    handleTestSettings(testSettings: ITestSettings) {
        this.setState({
            ...this.state,
            test: {
                ...this.state.test,
                settings: testSettings
            }
        })
    }

    handleTest(test: ITestCreate) {
        this.setState({
            ...this.state,
            test: test,
            allowNext: test.valid_until === null ? true : moment(test.valid_from).isBefore(test.valid_until)
        })
    }

    render() {
        let { showModal, wordGroups } = this.props
        let { allowPrev, allowNext, test } = this.state


        let newTest = i18n._('Nová písomka')
        let addTest = i18n._('Pridať písomku')
        let testSettings = i18n._('Nastavenia písomky')

        if((typeof test.settings.ugType !== 'undefined') && (test.settings.ugType === ETestUgType.homework)) {
            newTest = i18n._('Nová domáca úloha')
            addTest = i18n._('Pridať domácu úlohu')
            testSettings = i18n._('Nastavenia domácej úlohy')
        }

        const steps:IStepperStepType[] = [
            {
                name: i18n._('Výber slov'),
                step: (
                    <TestWordSelection
                        handleWordGroups={this.handleWordGroups}
                        test={test}
                        handleWords={this.handleWords}
                        definedWordGroups={wordGroups}
                    />
                )
            },
            {
                name: testSettings,
                step: (
                    <TestSettings
                        test={test}
                        handleTest={this.handleTest}
                    />
                )
            },
            {
                name: i18n._('Zhrnutie'),
                step: (
                    <TestSummary
                        test={test}
                    />
                )
            }
        ]

        return (
            <section id="mainSection">
                <ModalContainer show={showModal} customHide={this.props.hideModal}>
                    <ModalHeader title={newTest}/>
                    
                    <ModalBody>
                        <LoadingElement loadingValue={this.props.loading}/>
                        
                        <form className="form _alt" onSubmit={this.submitFormHandler}>
                            <Stepper 
                                steps={steps} 
                                finishText={addTest}
                                allowPrev={allowPrev}
                                allowNext={allowNext}
                                finalSubmit={this.handleSaveTest}
                                showInModal={true}
                            />
                        </form>
                    
                    </ModalBody>
                </ModalContainer>
            </section>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalAddNewUserGroupTest)