import React, {Component} from 'react';
import { connect } from 'react-redux';
import { storeWordGroups } from '../../../../../actions/wordGroups/storeWordGroups';
import { IAddWordMapping, IWordgroupType } from '../../../../../constants/types';
import { IApplicationStore } from '../../../../../constants/store-interfaces';
import AddWordRepeater from '../modalParts/AddWordRepeater';
import { i18n } from '../../../../../App';
import ModalContainer from '../../../containers/ModalContainer';
import ModalHeader from '../../../containers/ModalElements/ModalHeader';
import ModalBody from '../../../containers/ModalElements/ModalBody';
import ModalFooter from '../../../containers/ModalElements/ModalFooter';
import { Trans } from '@lingui/macro';
import { setShowCreateWordSimpleModal } from '../../../../../actions/modals/setShowModal';

interface IDispatchProps {
    storeWordGroups: typeof storeWordGroups
    setShowAddWordSimpleModal: typeof setShowCreateWordSimpleModal
}

interface IStateProps {
    loading: boolean,
    showModal: boolean
}

const mapStateToProps = (state: IApplicationStore): IStateProps => ({
    loading: state.loadingStore.loadingCreatedWordGroups,
    showModal: state.modalsStore.showAddWordSimple
})

const mapDispatchToProps = (dispatch: any): IDispatchProps => ({
    storeWordGroups: (data: any) => dispatch(storeWordGroups(data)),
    setShowAddWordSimpleModal: (value: boolean) => dispatch(setShowCreateWordSimpleModal(value))
})

interface IState {
    words: IAddWordMapping[],
    allowSubmit: boolean
}

interface IProps extends IDispatchProps, IStateProps {
    wordGroup: IWordgroupType
}

const initState: IState = {
    words: [{from:'', to:''}],
    allowSubmit: false
}

class ModalAddWordSimple extends Component<IProps, IState> {

    constructor(props:any) {
        super(props)

        this.state = initState

        this.handleWords = this.handleWords.bind(this)
        this.handleSaveWordGroups = this.handleSaveWordGroups.bind(this)
    }

    componentDidUpdate(prevProps: IProps, ) {
        if(prevProps.showModal && !this.props.showModal) {
            this.setState(initState)
        }
    }

    handleWords(words:  any[]){
        let isValidWords = true

        words.forEach((w, i) => {
            if((w.from === '' )||(w.to === '')) {
                isValidWords = false
            }
            
            
            if((w.from !== '') && (w.to !== '')) {
                words.forEach((w2, i2) => {
                    if(i2 !== i) {
                        if((w.from === w2.from) && (w.to === w2.to)) {
                            isValidWords = false
                        }
                    }
                })
            }
        })

        this.setState({
            ...this.state,
            words: words,
            allowSubmit: isValidWords
        })
    }

    submitFormHandler(e:  any)  {
        e.preventDefault()
    }

    handleSaveWordGroups() {
        const { words } = this.state
        const { wordGroup } = this.props

        let data = {}

        let wordGroupsArray = [{
            wordgroup_id: wordGroup.id,
            name: wordGroup.name
        }]

        let languages = {
            language_from_id: wordGroup.language_from.id,
            language_to_id: wordGroup.language_to.id
        }

        let wordsArray = []

        for (let word of words) {
            wordsArray.push({
                word_text: word.from,
                translation: word.to
            })
        }

        data = {
            wordgroups: wordGroupsArray,
            language_ids: languages,
            words: wordsArray,
            type: 'add'
        }
    
        this.props.storeWordGroups(data)
        this.props.setShowAddWordSimpleModal(false)
    }

    render() {

        let { showModal, wordGroup } = this.props
        let { allowSubmit, words } = this.state

        return (
            <section id="mainSection">
                <ModalContainer show={showModal}>
                    <ModalHeader title={i18n._('Vytvoriť nové slová')}/>
                    
                    <ModalBody>
                    
                        <form className="form _alt" onSubmit={this.submitFormHandler} autoComplete='off'>
                            <AddWordRepeater 
                                words={words}
                                handleWords={this.handleWords}
                                languageFrom={wordGroup.language_from}
                                languageTo={wordGroup.language_to}
                                isValid={allowSubmit}
                            />
                        </form>
                    
                    </ModalBody>
                    <ModalFooter>
                        <div className="part">
                            <button type="button" className="ant-btn bttn _silent href" onClick={() => this.props.setShowAddWordSimpleModal(false)}><Trans>Zavrieť</Trans></button>
                        </div>
                        <div className="part">
                            <button type="button" className={"ant-btn bttn href " + ((allowSubmit)?'_primary':'_silent')} onClick={() => this.handleSaveWordGroups()} disabled={!allowSubmit}><Trans>Uložiť</Trans></button>
                        </div>
                    </ModalFooter>
                </ModalContainer>
            </section>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalAddWordSimple)