import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IApplicationStore } from "../../../constants/store-interfaces";
import { IWordgroupType, ITableHeaderCellType, ITableRowType, ITableCellType, IActionType, IWordType, IWordChecked, IModifyWordGroup, ITestResult, ITimelineItem, ITest, ILanguageType, ETestUgType } from '../../../constants/types';
import TableContainer from '../containers/TableContainer';
import { Trans } from '@lingui/macro';
import LoadingElement from '../../simple/LoadingElement';
import { i18n } from '../../../App';
import { useRouteMatch } from 'react-router';
import moment  from 'moment';
import TranslateLanguages from '../../simple/TranslateLanguages';
import ModalEditWord from '../parts/modals/word/ModalEditWord';
import ModalDeleteWords from '../parts/modals/word/ModalDeleteWords';
import { setShowEditWordModal, setShowEditWordGroupModal, setShowCreateTestWGModal } from '../../../actions/modals/setShowModal';
import ModalAddWordSimple from '../parts/modals/wordGroup/ModalAddWordSimple';
import { setShowConfirmModal, setShowCreateWordSimpleModal } from '../../../actions/modals/setShowModal';
import { modifyWordGroup } from '../../../actions/wordGroups/modifyWordGroup';
import { loadCreatedWordGroups } from '../../../actions/wordGroups/loadCreatedWordGroups';
import ModalEditWordGroup from '../parts/modals/wordGroup/ModalEditWordGroup';
import TimelineContainer from '../containers/TimelineContainer';
import IconTextWidget from '../../simple/widgets/IconTextWidget';
import VoiceClass from '../../simple/classes/voiceClass';
import ModalAddTestWordgroup from '../parts/modals/tests/ModalAddTestWordgroup';
import { loadTestResults } from '../../../actions/tests/loadTestResults';
import CircularGraph from '../../simple/widgets/CircularGraph';
import { ReactNode } from 'react';
import InfoModal from '../../simple/InfoModal';
import TestResultInfo from '../parts/modals/tests/TestResultInfo';
import { loadTests } from '../../../actions/tests/loadTests';
import NoMatchPage from './NoMatchPage';
import config from '../../../config';
import { loadCreatedUserGroups } from '../../../actions/userGroups/loadCreatedUserGroups';
import AuthTeacherWrapper from '../containers/Authentification/AuthTeacherWrapper';
import Select from 'react-select';
import { loadUserGroupTests } from '../../../actions/tests/loadUserGroupTests';
import { loadTestResultsUserGroup } from '../../../actions/tests/loadTestResultsUserGroup';
import ReactGA from 'react-ga';
import { MdCreateNewFolder } from 'react-icons/md'
import ModalAddWordGroupSimple from '../parts/modals/wordGroup/ModalAddWordGroupSimple';
import ModalShareWordGroup from '../parts/modals/userGroup/ModalShareWordGroup';

type PathParamsType = {
    id: string,
    usergroupId?: string
}

interface IProps {

}


const WordGroupDetailPage: React.FC<IProps> = props => {    
    const match = useRouteMatch<PathParamsType>()
    const dispatch = useDispatch()

    const myResult = {value: 0, label: i18n._('Moje výsledky')}

    const [wordGroupId, setWordGroupId] = useState<number>(Number(match.params.id))
    const [, updateState] = React.useState<any>();
    const [checkedWords, setCheckedWords] = useState<IWordChecked[]>([])
    const [selectedEditWord, setSelectedEditWord] = useState<IWordType | null>(null)
    const [selectedDeleteWord, setSelectedDeleteWord] = useState<IWordType | null>(null)
    const [showResult, setShowResult] = useState<boolean>(false)
    const [selectedResult, setSelectedResult] = useState<ITestResult | null>(null)
    const [timelineItems, setTimelineItems] = useState<ITimelineItem[]>([])
    const [showTests, setShowTests] = useState<ITest[]>([])
    const [showResults, setShowResults] = useState<ITestResult[]>([])
    const [wordGroup, setWordGroup] = useState<IWordgroupType|undefined>(undefined)
    const [ugOptions, setUgOptions] = useState<{value: number, label: string}[]>([])
    const [selectedUg, setSelectedUg] = useState<{value: number, label: string}>(myResult)
    const [showAddNewWordGroup, setShowAddNewWordGroup] = useState(false)

    const loading = useSelector((state: IApplicationStore) => state.loadingStore.loadingCreatedWordGroups)
    const loadingResults = useSelector((state: IApplicationStore) => state.loadingStore.loadingTestResult)
    const loadingTests = useSelector((state: IApplicationStore) => state.loadingStore.loadingCreatedTests)
    const loadingUsergroups = useSelector((state: IApplicationStore) => state.loadingStore.loadingCreatedUserGroups)
    const myTestResults = useSelector((state: IApplicationStore) => state.testsStore.testResults)
    const createdWordGroups = useSelector((state: IApplicationStore) => state.wordGroupsStore.createdWordgroups)
    const myCreatedTests = useSelector((state: IApplicationStore) => state.testsStore.createdTests)
    const userInfo = useSelector((state: IApplicationStore) => state.globalStore.userInfo)
    const createdUserGroups = useSelector((state: IApplicationStore) => state.userGroupsStore.createdUserGroups)
    const ugTests = useSelector((state: IApplicationStore) => state.testsStore.usergroupTests)
    const ugTestResults = useSelector((state: IApplicationStore) => state.testsStore.usergroupResults)
    
    const forceUpdate = React.useCallback(() => updateState({}), []);

    useEffect(() => {
        ReactGA.pageview('Skupina slovíčok - detail')
    }, [])
    
    useEffect(() => {
        setWordGroupId(Number(match.params.id))
    }, [match.params.id])

    useEffect(() => {
        let newUgOptions: {value: number, label: string}[] = [
            {
                value: 0,
                label: i18n._('Moje výsledky')
            }
        ]

        createdUserGroups.forEach(ug => {
            if(typeof ug.assigned_wordgroups.find(wg => wg.id === wordGroupId) !== 'undefined') {
                newUgOptions.push({
                    value: ug.id,
                    label: ug.name
                })
            }
        })

        setUgOptions(newUgOptions)
    }, [createdUserGroups, wordGroupId])

    useEffect(() => {
        if(selectedUg.value === 0) {
            setShowTests(myCreatedTests)
            setShowResults(myTestResults)
        } else {
            let tests = ugTests[selectedUg.value]
            let testResults = ugTestResults[selectedUg.value]

            if(typeof tests !== 'undefined') {
                setShowTests(tests)
            } else {
                setShowTests([])
            }

            if(typeof testResults !== 'undefined') {
                setShowResults(testResults)
            } else {
                setShowResults([])
            }
        }
    }, [selectedUg, dispatch, ugTests, ugTestResults, myCreatedTests, myTestResults])

    useEffect(() => {
        if(selectedUg.value > 0) {
            dispatch(loadUserGroupTests(selectedUg.value))
            dispatch(loadTestResultsUserGroup(selectedUg.value))
        }
    }, [dispatch, selectedUg.value])

    useEffect(() => {
        dispatch(loadCreatedWordGroups())
        dispatch(loadTestResults())
        dispatch(loadTests())

        if(userInfo?.role.name === config.roles.teacher) {
            dispatch(loadCreatedUserGroups())
        }
    }, [dispatch, userInfo])

    useEffect(() => {
        let newWordGroup = createdWordGroups.find((wordGroup: IWordgroupType)  => wordGroup.id === wordGroupId)

        setWordGroup(newWordGroup)
        forceUpdate()
    }, [createdWordGroups, wordGroupId, forceUpdate])

    useEffect(() => {
        let newTimelineItems: ITimelineItem[] = []

        showResults.sort((a, b) => moment(((b.date_finished !== null)?b.date_finished:b.date_started)).valueOf() - moment(((a.date_finished !== null)?a.date_finished:a.date_started)).valueOf())

        showResults.forEach(r => {
            if(r.result !== null) {
                let add = false

                if(r.result !== null) {
                    r.result.questions.forEach(q => {
                        wordGroup?.words.forEach(w => {
                            if(
                                (w.word_text === q.word.from) &&
                                (w.translation === q.word.to) 
                            ) {
                                add = true
                                return
                            }
                        })

                        if(add) {
                            return
                        }
                    })
                }

                if(add) {

                    let points = 0
                
                    r.result.questions.forEach(q => (q.valid)?points += 1:0)

                    let percentage = Math.round(points/r.result.questions.length*100)

                    let resultTest = showTests.find(t => t.id === r.test_id)

                if(typeof resultTest !== 'undefined') {
                        let ug = createdUserGroups.find(u => u.id === selectedUg.value)

                        if(typeof ug === 'undefined') {
                            let title = resultTest.name

                            newTimelineItems.push({
                                date: (r.date_finished !== null)?r.date_finished:r.date_started,
                                content: title,
                                icon: (<CircularGraph percentage={percentage}/>),
                                button: i18n._('Detail'),
                                buttonAction: () => {
                                    dispatch(loadTests())
                                    setSelectedResult(r)
                                    setShowResult(true)
                                }
                            })
                        } else {
                            let user = ug.assigned_users.find(u => u.id === r.user_id)

                            let title = resultTest.name

                            if(resultTest.settings.ugType === ETestUgType.homework) {
                                title += ' (' + i18n._('Domáca úloha') + ')'
                            } else {
                                title += ' (' + i18n._('Test') + ')'
                            }

                            if(typeof user !== 'undefined') {
                                title = user.f_name + ' - ' + title
                            } 

                            newTimelineItems.push({
                                date: (r.date_finished !== null)?r.date_finished:r.date_started,
                                content: title,
                                icon: (<CircularGraph percentage={percentage}/>),
                                button: i18n._('Detail'),
                                buttonAction: () => {
                                    dispatch(loadTests())
                                    setSelectedResult(r)
                                    setShowResult(true)
                                }
                            })
                        }
                    }
                }
            }
        })

        setTimelineItems(newTimelineItems)
    }, [showResults, showTests, wordGroup, dispatch, selectedUg, createdUserGroups])

    const handleToggleResult = () => {
        setShowResult(!showResult)
    }

    const handleAddWords = () => {
        dispatch(setShowCreateWordSimpleModal(true))
    }

    const handleEditWord = (wordIdString: string) => {
        let wordId  = Number(wordIdString)

        let wordGroup = createdWordGroups.find((wordGroup: IWordgroupType)  => wordGroup.id === wordGroupId)

        if(typeof wordGroup !== 'undefined') {
            let selectedWord = wordGroup.words.find((word: IWordType) => word.id === wordId)

            if(typeof  selectedWord !== 'undefined') {
                setSelectedEditWord(selectedWord)
    
                dispatch(setShowEditWordModal(true))
            }
        }
    }

    const handleDeleteWord = (wordIdString: string) => {
        let wordId  = Number(wordIdString)

        let wordGroup = createdWordGroups.find((wordGroup: IWordgroupType)  => wordGroup.id === wordGroupId)

        if(typeof wordGroup !== 'undefined') {
            let selectedWord = wordGroup.words.find((word: IWordType) => word.id === wordId)

            if(typeof  selectedWord !== 'undefined') {
                setSelectedDeleteWord(selectedWord)
    
                dispatch(setShowConfirmModal(true))
            }
        }
    }

    const handleDeleteWords = () => {
        if(selectedDeleteWord !== null) {
            setSelectedDeleteWord(null)
        }

        dispatch(setShowConfirmModal(true))
    }

    const handleCreateNewWordGroup = () => {
        setShowAddNewWordGroup(true)
    }

    const confirmDeleteWords = () => {
        let wordGroup = createdWordGroups.find((wordGroup: IWordgroupType)  => wordGroup.id === wordGroupId)

        if(typeof wordGroup !== 'undefined') {
            let words: {word_text: string, translation: string}[] = []

            for(let word of wordGroup.words) {
                let containWords = checkedWords.find((wgWord: IWordChecked) => wgWord.id ===  word.id)

                if(typeof containWords == 'undefined') {
                    words.push({
                        word_text: word.word_text,
                        translation: word.translation
                    })
                }
            }

            const sendModifyGroup: IModifyWordGroup = {
                wordgroup_name: wordGroup.name,
                language_ids: {
                    language_from_id: wordGroup.language_from.id,
                    language_to_id: wordGroup.language_to.id
                },
                words: words
            }

            dispatch(modifyWordGroup(wordGroupId, sendModifyGroup))
        }

    }

    const confirmDeleteWord = () => {
        let wordGroup = createdWordGroups.find((wordGroup: IWordgroupType)  => wordGroup.id === wordGroupId)

        if((selectedDeleteWord !== null) && (typeof wordGroup !== 'undefined')) {
            let words: {word_text: string, translation: string}[] = []

            for(let word of wordGroup.words) {

                if(word.id !== selectedDeleteWord.id) {
                    words.push({
                        word_text: word.word_text,
                        translation: word.translation
                    })
                }
            }

            const sendModifyGroup: IModifyWordGroup = {
                wordgroup_name: wordGroup.name,
                language_ids: {
                    language_from_id: wordGroup.language_from.id,
                    language_to_id: wordGroup.language_to.id
                },
                words: words
            }

            dispatch(modifyWordGroup(wordGroupId, sendModifyGroup))
        }
    }

    const confirmEditWord = (id: number, word_text: string, translation: string) => {
        let wordGroup = createdWordGroups.find((wordGroup: IWordgroupType)  => wordGroup.id === wordGroupId)

        if(typeof wordGroup !== 'undefined') {
            let words: {word_text: string, translation: string}[] = []

            for(let word of wordGroup.words) {
                if(word.id === id) {
                    words.push({
                        word_text: word_text,
                        translation: translation
                    })
                } else {
                    words.push({
                        word_text: word.word_text,
                        translation: word.translation
                    })
                }
            }

            const sendModifyGroup: IModifyWordGroup = {
                wordgroup_name: wordGroup.name,
                language_ids: {
                    language_from_id: wordGroup.language_from.id,
                    language_to_id: wordGroup.language_to.id
                },
                words: words
            }

            dispatch(modifyWordGroup(wordGroupId, sendModifyGroup))
        }
    }

    const confirmEditWordGroup = (name: string) => {
        let wordGroup = createdWordGroups.find((wordGroup: IWordgroupType)  => wordGroup.id === wordGroupId)

        if(typeof wordGroup !== 'undefined') {
            let words: {word_text: string, translation: string}[] = []

            for(let word of wordGroup.words) {
                words.push({
                    word_text: word.word_text,
                    translation: word.translation
                })
            }

            const sendModifyGroup: IModifyWordGroup = {
                wordgroup_name: name,
                language_ids: {
                    language_from_id: wordGroup.language_from.id,
                    language_to_id: wordGroup.language_to.id
                },
                words: words
            }

            dispatch(modifyWordGroup(wordGroupId, sendModifyGroup))
        }
    }

    const handleCheckedWords = (catchStrings:  string[]) => {
        let wordGroup = createdWordGroups.find((wordGroup: IWordgroupType)  => wordGroup.id === wordGroupId)

        let checkedWords: number[] =  []

        for(let catched  of catchStrings) {
            checkedWords.push(Number(catched))
        }

        checkedWords =Array.from(new Set(checkedWords))

        if(typeof  wordGroup !== 'undefined') {
            let checkedWordsObjects: IWordChecked[] = []

            for(let word of wordGroup.words) {
                if(checkedWords.includes(word.id))  {
                    checkedWordsObjects.push({
                        id: word.id,
                        word_text: word.word_text,
                        translation: word.translation,
                        language_from: wordGroup.language_from,
                        language_to: wordGroup.language_to
                    })
                }
            }

            setCheckedWords(checkedWordsObjects)
        }
    }

    const getRows = (wordGroup:  IWordgroupType | undefined): ITableRowType[] => {
        let rows: ITableRowType[]  = []

        if(typeof wordGroup !== 'undefined') {
            for(let word of wordGroup.words) {
                let cells: ITableCellType[] = []

                let availableVoiceFrom = true
                let availableVoiceTo = true
                let speechColumnFrom: ReactNode = (null)
                let speechColumnTo: ReactNode = (null)

                if(VoiceClass.getInstance(wordGroup.language_from.flag_name, '').isAvailable()) {
                    speechColumnFrom = (
                        <i className="la la-volume-up" onClick={() => playWord(word.word_text, wordGroup.language_from)} data-tooltip={i18n._('Prehrať výslovnosť')}/>
                    )
                } else {
                    speechColumnFrom = (
                        <i className="la la-frown-o" data-tooltip={i18n._('Výslovnosť nedostupná')}/>
                    )
                }
                
                if(VoiceClass.getInstance(wordGroup.language_to.flag_name, '').isAvailable()) {
                    speechColumnTo = (
                        <i className="la la-volume-up" onClick={() => playWord(word.translation, wordGroup.language_to)} data-tooltip={i18n._('Prehrať výslovnosť')}/>
                    )
                } else {
                    speechColumnTo = (
                        <i className="la la-frown-o" data-tooltip={i18n._('Výslovnosť nedostupná')}/>
                    )
                }

                let percentage = 0
                let percentageResult: ReactNode = (null)
                let valid = 0
                let total = 0

                showResults.forEach(r => {
                    if(r.result !== null) {
                        r.result.questions.forEach(q => {
                            if(
                                (q.word.from === word.word_text) &&
                                (q.word.to === word.translation) 
                            ) {
                                if(q.valid) {
                                    valid += 1
                                }

                                total += 1
                            }
                        })
                    }
                })

                if(total > 0) {
                    percentage = Math.round(valid/total*100)
                    percentageResult = (<div data-title={i18n._('Úspešnosť')}><CircularGraph percentage={percentage}/></div>)
                }

                cells = [
                    {
                        content: (<div className={"word " + ((availableVoiceFrom)?'_voice':'')} data-title={i18n._('Slovo')}>{word.word_text} {speechColumnFrom}</div>),
                        stringToOrder:  word.word_text,
                        filterString:  word.word_text,
                        filterByString:  true
                    },
                    {
                        content: (<div className={"word " + ((availableVoiceTo)?'_voice':'')} data-title={i18n._('Preklad')}>{word.translation} {speechColumnTo}</div>),
                        stringToOrder:  word.translation,
                        filterString:  word.translation,
                        filterByString: true
                    },
                    {
                        content: (percentageResult),
                        stringToOrder: percentage.toString(),
                        filterByString: true,
                        filterString: percentage.toString()
                    }
                ]

                rows.push({
                    cells: cells,
                    catchString: word.id.toString()
                })
            }
        }

        return rows
    }

    const getHeaders = (): ITableHeaderCellType[] => {
        return  [
            {
                content: (<span><Trans>Slovo</Trans></span>),
                sortBy: false,
                sortable: true
            },
            {
                content: (<span><Trans>Preklad</Trans></span>),
                sortBy: false,
                sortable: true
            },
            {
                content: (<span><Trans>Úspešnosť</Trans></span>),
                sortBy: false,
                sortable: true,
                sortableType: 'number'
            }
        ]
    }

    const getActions = (): IActionType[] => {
        return [
            {
                action: handleEditWord,
                name: i18n._('Upraviť')
            },
            {
                action: handleDeleteWord,
                name: i18n._('Odstrániť'),
                customClass: 'remove'
            }
        ]
    }

    const playWord = (word: string, language: ILanguageType) => {
        let voice: VoiceClass = VoiceClass.getInstance(language.flag_name, word)

        voice.speak(true)
    }

    if(!loading && typeof wordGroup === 'undefined') {
        return (<NoMatchPage/>)
    }

    //Table of words
    let headers: ITableHeaderCellType[] = getHeaders()
    let rows: ITableRowType[] = getRows(wordGroup)
    let actions: IActionType[] = getActions()

    let selectedTest: ITest[] = []

    let resultTitle = i18n._('Výsledok cvičenia')

    if(selectedResult !== null) {
        selectedTest = showTests.filter(t => t.id === selectedResult.test_id)

        if(selectedTest.length) {
            resultTitle = selectedTest[0].name

            let ug = createdUserGroups.find(u => u.id === selectedUg.value)

            if(typeof ug !== 'undefined') {
                let user = ug.assigned_users.find(u => u.id === selectedResult.user_id)

                if(selectedTest[0].settings.ugType === ETestUgType.homework) {
                    resultTitle += ' (' + i18n._('Domáca úloha') + ')'
                } else {
                    resultTitle += ' (' + i18n._('Test') + ')'
                }

                if(typeof user !== 'undefined') {
                    resultTitle = user.f_name + ' - ' + resultTitle
                }
            }
        }

        resultTitle +=  ' - ' + ((selectedResult.date_finished !== null)?moment(selectedResult.date_finished).format('DD.MM.YYYY HH:mm'):moment(selectedResult.date_started).format('DD.MM.YYYY HH:mm'))
    }

    return (
        <section id="mainSection">
            <LoadingElement loadingValue={loading || loadingUsergroups || loadingResults || loadingTests} />

            <div className="mainSectionBody">
            
                <div className="rows">
                    <div className="row">
                        <div className="row-part">
                            <div className="page-header">
                                <div className="part">
                                    <h2><Trans>Skupina slovíčok</Trans> {(typeof wordGroup !== 'undefined')?'- ' + wordGroup.name:''}</h2>
                                    <p><Trans>Zoznam všetkých slovíčok zo skupiny</Trans></p>
                                </div>
                                <div className="part">
                                    <div className="bttn _small _primary _icon" onClick={() => dispatch(setShowEditWordGroupModal(true))}>Upraviť skupinu</div>

                                    {((typeof wordGroup !== 'undefined') && (wordGroup?.words.length > 0))?(
                                        <div className="bttn _small _green _icon" onClick={() => dispatch(setShowCreateTestWGModal(true))}>Vytvoriť cvičenie</div>
                                    ):(null)}
                                    <br/>
                                    <br/>
                                    {typeof wordGroup !== 'undefined'?(
                                        <ModalShareWordGroup
                                            createWGData={{
                                                language_ids: {
                                                    language_from_id: wordGroup.language_from.id,
                                                    language_to_id: wordGroup.language_to.id
                                                },
                                                wordgroups: [{
                                                    name: wordGroup.name,
                                                    wordgroup_id: null
                                                }],
                                                words: wordGroup.words.map(w => {
                                                    return {
                                                        translation: w.translation,
                                                        word_text: w.word_text
                                                    }
                                                })
                                            }}
                                        />
                                    ):(null)}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="row-part">
                            <div className="small-widgets">
                                {(typeof wordGroup !== 'undefined')?(
                                    <ul>
                                        <li>
                                            <IconTextWidget
                                                showIcon={true}
                                                icon="la-align-justify"
                                            >
                                                <h2>{wordGroup.words.length}</h2>
                                                <p><Trans>počet slovíčok</Trans></p>
                                            </IconTextWidget>
                                        </li>
                                        <li>
                                            <IconTextWidget
                                                showIcon={true}
                                                icon="la-flag"
                                            >
                                                <TranslateLanguages
                                                    languageFrom={wordGroup.language_from}
                                                    languageTo={wordGroup.language_to}
                                                />
                                            </IconTextWidget>
                                        </li>
                                        <li>
                                            <IconTextWidget
                                                showIcon={true}
                                                icon="la-calendar-plus-o"
                                            >
                                                <h3>{moment(wordGroup.created_at).format('DD.MM.YYYY HH:mm')}</h3>
                                                <p><Trans>dátum vytvorenia</Trans></p>
                                            </IconTextWidget>
                                        </li>
                                    </ul>
                                ):(null)}
                            </div>
                        </div>
                    </div>
                    <AuthTeacherWrapper>
                        <div className="row">
                            <div className="row-part">
                                <ul className="inline-list _full form">
                                    <li>
                                        <div className="input-row">
                                            <div className="part">
                                                <label htmlFor="results"><Trans>Zobrazené výsledky</Trans></label>

                                                <Select
                                                    id='language'
                                                    options={ugOptions}
                                                    className="customSelect"
                                                    classNamePrefix="customSelectWrapper"
                                                    defaultMenuIsOpen={false} 
                                                    isSearchable={false}
                                                    onChange={(e:any) => setSelectedUg(e)}
                                                    value={selectedUg}
                                                />
                                                
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </AuthTeacherWrapper>
                    <div className="row">
                        <div className="row-part">
                            <div className="widget-holdeer">
                                <div className="widget-holder-header">
                                    <div className="widget-part">
                                        <h2><Trans>Slovíčka skupiny</Trans> ({rows.length})</h2>
                                    </div>
                                </div>
                                <div className="widget-holder-body">
                                    <div className="widget-part">
                                        <section className="widget">
                                            <div className="widget-body">
                                                <TableContainer
                                                    headers={headers}
                                                    rows={rows}
                                                    actions={actions}
                                                    showActions={true}
                                                    showCheckbox={true}
                                                    checkboxFunction={handleCheckedWords}
                                                    showFilter={true}
                                                    pageSize={20}
                                                    emptyText={i18n._('Táto skupina neobsahuje žiadne slová')}
                                                    showNew={true}
                                                    showGrid={false}
                                                    mobileFixedGrid={true}
                                                    newFunction={handleAddWords}
                                                    newTitle={i18n._('Pridať nové slovíčka')}
                                                    title={i18n._('Slovíčka skupiny')}
                                                    loading={loading || loadingResults}
                                                    footer={(
                                                        <div className="part">
                                                            {(checkedWords.length > 0)?(
                                                                <>
                                                                    <div 
                                                                        className="bttn _icon _small _green"
                                                                        title={i18n._('Vytvoriť skupinu slovíčok')}
                                                                        onClick={handleCreateNewWordGroup}
                                                                        data-tooltip={i18n._('Nová skupina slovíčok')}
                                                                    >
                                                                        <MdCreateNewFolder/>
                                                                    </div>
                                                                    <div 
                                                                        className="bttn _icon _small _red" 
                                                                        title={i18n._('Odstrániť')}
                                                                        onClick={handleDeleteWords}
                                                                        data-tooltip={i18n._("Odstrániť vybraté slovíčka zo skupiny")}
                                                                    >
                                                                        <i className="la la-trash-o"/>
                                                                    </div>
                                                                </>
                                                            ):(null)}
                                                            
                                                        </div>
                                                    )}
                                                />
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row-part">
                            <div className="widget-holdeer">
                                <div className="widget-holder-header">
                                    <div className="widget-part">
                                        {(selectedUg.value !== 0)?(
                                            <h2><Trans>Výsledky súvisiace s touto skupinou</Trans> - <Trans>trieda</Trans> {selectedUg.label} ({timelineItems.length})</h2>
                                        ):(
                                            <h2><Trans>Moje výsledky súvisiace s touto skupinou</Trans> ({timelineItems.length})</h2>
                                        )}
                                    </div>
                                </div>
                                <div className="widget-holder-body">
                                    <div className="widget-part">
                                        <section className="widget">
                                            <div className="widget-body">
                                                <TimelineContainer
                                                    show={true}
                                                    items={timelineItems}
                                                    emptyText={i18n._('Nemáte k dispozícii žiadne výsledky.')}
                                                    loading={loadingResults}
                                                />
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                
                </div>
            
            </div>

            {((selectedEditWord !== null) && (typeof wordGroup !== 'undefined'))?(
                <ModalEditWord 
                    id={selectedEditWord.id}
                    word_text={selectedEditWord.word_text}
                    translation={selectedEditWord.translation}
                    language_from={wordGroup.language_from}
                    language_to={wordGroup.language_to}
                    confirmFunction={confirmEditWord}
                />
            ):(null)}
            {((selectedDeleteWord !== null) && (typeof wordGroup !== 'undefined'))?(
                <ModalDeleteWords
                    words={[{
                        id: selectedDeleteWord.id,
                        word_text: selectedDeleteWord.word_text,
                        translation: selectedDeleteWord.translation,
                        language_from: wordGroup.language_from,
                        language_to: wordGroup.language_to
                    }]}
                    confirmFunction={confirmDeleteWord}
                />
            ):(null)}

            {((checkedWords.length > 0) && (selectedDeleteWord == null))?(
                <ModalDeleteWords
                    words={checkedWords}
                    confirmFunction={confirmDeleteWords}
                />
            ):(null)}
            {(typeof wordGroup !== 'undefined')?(
                <ModalAddWordGroupSimple
                    languageFrom={wordGroup.language_from}
                    languageTo={wordGroup.language_to}
                    words={checkedWords}
                    onClose={() => setShowAddNewWordGroup(false)}
                    showModal={showAddNewWordGroup}
                />
            ):(null)}

            {(typeof wordGroup !== 'undefined')?(
                <>
                    <ModalAddWordSimple
                        wordGroup={wordGroup}
                    />
                    <ModalEditWordGroup
                        id={wordGroup.id}
                        wordGroupName={wordGroup.name}
                        confirmFunction={confirmEditWordGroup}
                    />
                    <ModalAddTestWordgroup
                        wordgroup={wordGroup}
                    />
                </>
            ):(null)}

            {((selectedResult !== null) && (selectedResult.result !== null) && (selectedTest.length > 0))?(
                <InfoModal
                    title={resultTitle}
                    custom={true}
                    customShow={showResult}
                    customShowCloseFunction={handleToggleResult}
                >
                    <LoadingElement loadingValue={loadingTests} />
                    <TestResultInfo
                        test={selectedTest[0]}
                        result={selectedResult}
                    />
                </InfoModal>
            ):(null)}
        </section>
    )
}

export default WordGroupDetailPage