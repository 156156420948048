import React, {Component} from 'react'
import { connect } from 'react-redux'
import { IApplicationStore } from '../../../../../constants/store-interfaces';
import ModalContainer from '../../../containers/ModalContainer';
import ModalHeader from '../../../containers/ModalElements/ModalHeader';
import { i18n } from '../../../../../App';
import ModalBody from '../../../containers/ModalElements/ModalBody';
import ModalFooter from '../../../containers/ModalElements/ModalFooter';
import { Trans } from '@lingui/macro';
import InformationBox from '../../InformationBox';
import { setShowEditWordGroupModal } from '../../../../../actions/modals/setShowModal';

interface IStateProps {
    showEditWordGroup: boolean
}

const mapStateToProps = (state: IApplicationStore): IStateProps => ({
    showEditWordGroup: state.modalsStore.showEditWordGroup
})


interface IDispatchProps {
    setShowEditWordGroupModal: typeof setShowEditWordGroupModal,
}

const mapDispatchToProps = (dispatch: any): IDispatchProps => ({
    setShowEditWordGroupModal: (value:boolean) => dispatch(setShowEditWordGroupModal(value)),
})

interface IProps extends  IStateProps, IDispatchProps{
    id: number,
    wordGroupName: string
    confirmFunction: (wordGroupName: string) => any
}

interface IState {
    id: number,
    name: string,
    isValid: boolean
}

class ModalEditWordGroup extends Component<IProps, IState> {
    constructor(props:  any)  {
        super(props)
            
        this.state = {
            id:this.props.id,
            name: this.props.wordGroupName,
            isValid: true
        }

        this.hideModal = this.hideModal.bind(this)
        this.editWordGroup = this.editWordGroup.bind(this)
        this.handleWordGroupNameChange = this.handleWordGroupNameChange.bind(this)
    }

    componentDidUpdate(prevProps:  IProps) {
        if(prevProps.id !== this.props.id) {
            this.setState({
                id: this.props.id,
                name: this.props.wordGroupName,
                isValid: true
            })
        }
    }

    handleWordGroupNameChange(e:any) {
        this.setState({
            ...this.state,
            name: e.target.value,
            isValid: (e.target.value.length > 0)?true:false
        })
    }

    hideModal() {
        this.props.setShowEditWordGroupModal(false)
    }

    editWordGroup() {
        if(this.state.isValid) {
            this.props.confirmFunction(this.state.name)
            this.hideModal()
        }
    }

    render() {
        const { showEditWordGroup, wordGroupName }  = this.props
        const { name, isValid } =  this.state

        return (
            <ModalContainer show={showEditWordGroup}>
                <ModalHeader title={i18n._('Úprava skupiny slov') + ' - ' + wordGroupName} />

                <ModalBody className="form _alt">
                    <div className="input-row">
                        <div className="part">
                            <input 
                                name={"name"}
                                type="text"
                                placeholder={i18n._('Názov skupiny slov')}
                                onChange={this.handleWordGroupNameChange}
                                value={name}
                            />

                            {(isValid)?(null):(
                                <InformationBox type="warning" showClose={false} showBell={true}>
                                    <Trans>Pre pokračovanie  musíte zadať názov skupiny slovíčok!</Trans>
                                </InformationBox>
                            )}
                            
                        </div>
                    </div>
                </ModalBody>

                <ModalFooter>
                    <div className="part">
                        <button className="bttn _red href" onClick={this.hideModal}><Trans>Zrušiť</Trans></button>
                    </div>
                    <div className="part">
                        <button className={"bttn _full  href " + ((!isValid)?"_dark":"_green")} onClick={this.editWordGroup} disabled={!isValid}><Trans>Uložiť</Trans></button>
                    </div>
                </ModalFooter>
            </ModalContainer>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalEditWordGroup)
