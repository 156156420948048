import React, {Component} from 'react';
import { connect } from 'react-redux';
import { IApplicationStore } from '../../../../../constants/store-interfaces';
import { i18n } from '../../../../../App';
import ModalContainer from '../../../containers/ModalContainer';
import ModalHeader from '../../../containers/ModalElements/ModalHeader';
import ModalBody from '../../../containers/ModalElements/ModalBody';
import { Trans } from '@lingui/macro';
import ModalFooter from '../../../containers/ModalElements/ModalFooter';
import { IWordgroupType, IUWordGroupType } from '../../../../../constants/types';
import { loadCreatedWordGroups } from '../../../../../actions/wordGroups/loadCreatedWordGroups';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import LoadingElement from '../../../../simple/LoadingElement';
import { assignWordGroupToUserGroup } from '../../../../../actions/userGroups/assignWordGroupToUserGroup';
import InformationBox from '../../InformationBox';
import { NavLink } from 'react-router-dom';

interface IStateProps {
    wordGroups: IWordgroupType[],
    loadingWordGroups: boolean,
    loading: boolean,
    language: string
}

interface IDispatchProps {
    loadCreatedWordGroups: typeof loadCreatedWordGroups,
    assignWordGroupToUserGroup: typeof assignWordGroupToUserGroup
}

const mapStateToProps = (state: IApplicationStore): IStateProps => ({
    wordGroups:  state.wordGroupsStore.createdWordgroups,
    loadingWordGroups: state.loadingStore.loadingCreatedWordGroups,
    loading: state.loadingStore.loading,
    language: state.globalStore.language
})

const mapDispatchToProps = (dispatch: any): IDispatchProps => ({
    loadCreatedWordGroups: () => dispatch(loadCreatedWordGroups()),
    assignWordGroupToUserGroup: (data: any, id: number, wg: IUWordGroupType)  => dispatch(assignWordGroupToUserGroup(data, id, wg))
})

interface IState {
    selectedWordGroup: any,
    selectedStart: Date
    valid: boolean
}

interface IProps extends IDispatchProps, IStateProps {
    showModal: boolean,
    userGroupId: number,
    userGroupName: string,
    hideModalFunction: () => any,
    assignedWordGroups: IUWordGroupType[]
}

const initState: IState = {
    selectedWordGroup: null,
    selectedStart: new Date(),
    valid: false
}

class ModalAddWordGroupToUserGroup extends Component<IProps, IState> {

    constructor(props:any) {
        super(props)

        this.state = initState

        this.toggleModal = this.toggleModal.bind(this)
        this.handleAssignWordGroup = this.handleAssignWordGroup.bind(this)
        this.onWordGroupChange = this.onWordGroupChange.bind(this)
        this.handleDateStart = this.handleDateStart.bind(this)
        this.handleDateStartRefresh = this.handleDateStartRefresh.bind(this)
    }

    componentDidMount() {
        this.setState(initState)
        this.props.loadCreatedWordGroups()
    }

    componentDidUpdate(prevProps: IProps, ) {
        if(prevProps.showModal && !this.props.showModal) {
            this.setState(initState)
        }
    }

    toggleModal() {
        this.props.hideModalFunction()
    }

    onWordGroupChange = (inputValue: any) => {
        this.setState({
            ...this.state,
            selectedWordGroup: inputValue,
            valid: true
        })
    }

    handleDateStart = (date:any) => {
        this.setState({
          selectedStart: date
        });
    };

    handleDateStartRefresh = () => {
        this.setState({
          selectedStart: new Date()
        });
    };

    submitFormHandler(e:  any)  {
        e.preventDefault()
        this.handleAssignWordGroup()
    }

    handleAssignWordGroup() {
        let bodyRequest = {
            wordgroup_id: this.state.selectedWordGroup.object.id,
            valid_from: this.state.selectedStart.toISOString()
        }

        let selectedWG: IUWordGroupType = this.state.selectedWordGroup.object

        selectedWG.valid_from = this.state.selectedStart.toISOString()
        
        selectedWG.usergroup = {
            usergroup_id: this.props.userGroupId,
            usergroup_name: this.props.userGroupName
        }

        this.props.assignWordGroupToUserGroup(bodyRequest, this.props.userGroupId, selectedWG)

        this.toggleModal()
    }

    render() {
        const { loadingWordGroups, showModal, wordGroups, loading, assignedWordGroups } = this.props
        const { selectedWordGroup, valid, selectedStart } = this.state

        let defaultOptions:any = []

        for(let wordGroup of wordGroups) {
            let alreadyAssigned = assignedWordGroups.find(wg => wg.id === wordGroup.id)

            if(typeof alreadyAssigned === 'undefined') {
                defaultOptions.push({
                    value: wordGroup.id,
                    object: wordGroup,
                    label:  wordGroup.name
                })
            }
        }

        return (
            <section id="mainSection">
                <ModalContainer show={showModal} customHide={() => this.props.hideModalFunction()}>
                    <ModalHeader title={i18n._('Priradiť slovíčka do triedy')}/>
                    
                    <ModalBody>
                        <LoadingElement loadingValue={loading}/>
                        <p><Trans>Vyberte skupinu, ktorú chcete pridať do triedy a dátum od ktorej bude táto skupina slovíčok dostupná pre študentov</Trans></p>
                        
                        <form className="form _alt" onSubmit={this.submitFormHandler}>
                            <div className="form _alt">
                                <div className="input-row">
                                    <div className="part">
                                        <label htmlFor="wordgroup-name"><Trans>Skupina slovíčok pre priradenie</Trans> <i>*</i></label>
                                        {(defaultOptions.length === 0)?(
                                            <div>
                                                <InformationBox type="info" showBell={true}>
                                                    <div className="part">
                                                        <Trans>
                                                            Zatiaľ nemáte vytvorenú žiadnu skupinu slovíčok ktorú by ste priradili Vašej triede.
                                                        </Trans>
                                                    </div>
                                                </InformationBox>
                                                <div className="part">
                                                    <NavLink to={"/app/wordgroups"} className="bttn _small _green">
                                                        <Trans>Vytvoriť novú skupinu slovíčok</Trans>
                                                    </NavLink>
                                                </div>
                                            </div>
                                        ):(
                                            <div className="input-holder">
                                                <Select
                                                    closeMenuOnSelect={true}
                                                    autoFocus={true}
                                                    options={defaultOptions}
                                                    className="customSelect"
                                                    classNamePrefix="customSelectWrapper"
                                                    onChange={this.onWordGroupChange}
                                                    value={selectedWordGroup}
                                                    noOptionsMessage={() => i18n._('Zatiaľ nemáte vytvorenú žiadnu skupinu slovíčok.')}
                                                    placeholder={i18n._("Vyberte skupinu pre priradenie")}
                                                    isLoading={loadingWordGroups}
                                                    isSearchable={false}
                                                />   
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="input-row">
                                    <div className="part">
                                        <label htmlFor="date-start"><Trans>Ukázať od</Trans></label>
                                        <div className="input-holder">
                                            <DatePicker
                                                selected={selectedStart}
                                                onChange={this.handleDateStart}
                                                showTimeSelect
                                                timeFormat="HH:mm"
                                                timeIntervals={15}
                                                timeCaption={i18n._("Čas")}
                                                dateFormat="dd. MM. yyyy HH:mm"
                                                locale={this.props.language}
                                                popperClassName="with-time"
                                            />
                                        </div>
                                    </div>
                                    <div className="part">
                                        <button type="button" className="ant-btn bttn _icon _small _green" onClick={this.handleDateStartRefresh}><i className="la la-refresh"></i></button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    
                    </ModalBody>

                    <ModalFooter>
                        <div className="part">
                            <button type="button" className="ant-btn bttn _silent href" onClick={this.props.hideModalFunction}><Trans>Zavrieť</Trans></button>
                        </div>
                        <div className="part">
                            <button type="button" className={"ant-btn bttn href " + ((valid)?'_primary':'_silent')} onClick={this.handleAssignWordGroup} disabled={!valid}><Trans>Priradiť triede</Trans></button>
                        </div>
                    </ModalFooter>
                </ModalContainer>
            </section>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalAddWordGroupToUserGroup)