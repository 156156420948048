import React, {Component, PropsWithChildren} from 'react'
import { connect } from 'react-redux'
import { IApplicationStore } from '../../constants/store-interfaces';
import ModalContainer from '../app/containers/ModalContainer';
import { i18n } from '../../App';
import ModalHeader from '../app/containers/ModalElements/ModalHeader';
import ModalBody from '../app/containers/ModalElements/ModalBody';
import { setShowInfoModal } from '../../actions/modals/setShowModal';

interface IStateProps {
    showInfoModal: boolean
}

const mapStateToProps = (state: IApplicationStore): IStateProps => ({
    showInfoModal: state.modalsStore.showInfo
})


interface IDispatchProps {
    setShowInfoModal: typeof setShowInfoModal
}

const mapDispatchToProps = (dispatch: any): IDispatchProps => ({
    setShowInfoModal: (value:boolean) => dispatch(setShowInfoModal(value)),
})

interface IProps extends  IStateProps, IDispatchProps{
    title?: string,
    custom?: boolean,
    customShowCloseFunction?: () => any,
    customShow?: boolean
}

interface IState {
    custom: boolean,
}

class EmptyModal extends Component<PropsWithChildren<IProps>, IState> {
    constructor(props:  any)  {
        super(props)

        this.state = {
            custom: (typeof this.props.custom === 'undefined')?false:this.props.custom
        }

        this.hideModal = this.hideModal.bind(this)
    }

    hideModal() {
        if(this.state.custom) {
            if(typeof  this.props.customShowCloseFunction !== 'undefined') {
                this.props.customShowCloseFunction()

                return
            }
        } 
        
        this.props.setShowInfoModal(false)
    }

    render() {
        const { children, showInfoModal, title, customShow}  = this.props

        return (
            <ModalContainer show={(typeof customShow === 'undefined')?showInfoModal:customShow} customHide={this.hideModal}>
                <ModalHeader title={(typeof title === 'undefined')?i18n._('Informácia'):title} />

                <ModalBody className="form _alt">
                    {children}
                </ModalBody>
            </ModalContainer>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EmptyModal)
