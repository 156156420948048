import React, {Component} from 'react';
import { connect } from 'react-redux';
import { IApplicationStore } from '../../../../../constants/store-interfaces';
import { i18n } from '../../../../../App';
import ModalContainer from '../../../containers/ModalContainer';
import ModalHeader from '../../../containers/ModalElements/ModalHeader';
import ModalBody from '../../../containers/ModalElements/ModalBody';
import InformationBox from '../../InformationBox';
import { Trans } from '@lingui/macro';
import ModalFooter from '../../../containers/ModalElements/ModalFooter';
import { IUserGroupType } from '../../../../../constants/types';
import { addUserToUserGroup } from '../../../../../actions/userGroups/addUserToUserGroup';
import { storeAssignedUserGroup } from '../../../../../actions/userGroups/storeAssignedUserGroup';
import LoadingElement from '../../../../simple/LoadingElement';
import { loadAssignedUserGroups } from '../../../../../actions/userGroups/loadAssignedUserGroups';

interface IDispatchProps {
    storeAssignedUserGroup: typeof storeAssignedUserGroup,
    loadAssignedUserGroups: typeof loadAssignedUserGroups
}

interface IStateProps {
    loading: boolean
}

const mapStateToProps = (state: IApplicationStore): IStateProps => ({
    loading: state.loadingStore.loading
})

const mapDispatchToProps = (dispatch: any): IDispatchProps => ({
    storeAssignedUserGroup: (group: IUserGroupType) => dispatch(storeAssignedUserGroup(group)),
    loadAssignedUserGroups: () => dispatch(loadAssignedUserGroups())
})

interface IState {
    code: string,
    valid: boolean,
    error: boolean
}

interface IProps extends IDispatchProps, IStateProps {
    showModal: boolean,
    hideModalFunction: () => any
}

const initState: IState = {
    code: '',
    valid: false,
    error: false
}

class ModalAddUserToUserGroup extends Component<IProps, IState> {

    constructor(props:any) {
        super(props)

        this.state = initState

        this.handleRegisterCode = this.handleRegisterCode.bind(this)
        this.toggleModal = this.toggleModal.bind(this)
        this.handleAddUserToUserGroup = this.handleAddUserToUserGroup.bind(this)
        this.submitFormHandler = this.submitFormHandler.bind(this)
    }

    componentDidMount() {
        this.setState(initState)
    }

    componentDidUpdate(prevProps: IProps, ) {
        if(prevProps.showModal && !this.props.showModal) {
            this.setState(initState)
        }
    }

    handleRegisterCode(e:any) {
        this.setState({
            ...this.state,
            code: e.target.value,
            valid: (e.target.value.length > 0)?true:false,
            error: false
        })
    }

    toggleModal() {
        this.props.hideModalFunction()
    }

    submitFormHandler(e:  any)  {
        e.preventDefault()
        this.handleAddUserToUserGroup()
    }

    handleAddUserToUserGroup() {
        if(this.state.valid) {
            addUserToUserGroup(this.state.code)
                .then(res => {
                    if(res.status === 'failed') {
                        this.setState({
                            ...this.state,
                            error: true
                        })
                    } else {
                        this.setState({
                            ...this.state,
                            error: false
                        })
                        
                        this.props.loadAssignedUserGroups()
                        this.props.hideModalFunction()
                    }
                })
                .catch(error => {
                    console.log(error)
                    this.setState({
                        ...this.state,
                        error: true
                    })
                })
        }
    }

    render() {
        const { loading, showModal } = this.props
        const { valid, code, error } = this.state

        return (
            <section id="mainSection">
                <ModalContainer show={showModal} customHide={() => this.props.hideModalFunction()}>
                    <ModalHeader title={i18n._('Priradiť sa do triedy')}/>
                    
                    <ModalBody>
                        <h4 className="headline-divider"><Trans>Registrácia do existujúcej triedy</Trans></h4>
                        
                        <p><Trans>Zadajte registračný kľúč, ktorý ste obdržali od Vášho učiteľa.</Trans></p>
                        
                        <form className="form _alt" onSubmit={this.submitFormHandler}>
                            <LoadingElement loadingValue={loading}/>
                            <div className="form _alt">
                                <div className="input-row">
                                    <div className="part">
                                        <label htmlFor="wordgroup-name"><Trans>Registračný kód</Trans> <i>*</i></label>
                                        <div className="input-holder">
                                            <input 
                                                name={"userGroupName"}
                                                type="text"
                                                placeholder={i18n._('Kód dostanete od Vášho učiteľa')}
                                                onChange={this.handleRegisterCode}
                                                value={code}
                                            />
                                            {(valid)?(null):(
                                                <InformationBox type="warning" showClose={false} showBell={true}>
                                                    <Trans>Pre pokračovanie musíte zadať registračný kód!</Trans>
                                                </InformationBox>
                                            )}
                                            {(!error)?(null):(
                                                <InformationBox type="error" showClose={false} showBell={true}>
                                                    <Trans>Pravdepodobne ste zadali neplatný kľúč!</Trans>
                                                </InformationBox>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    
                    </ModalBody>

                    <ModalFooter>
                        <div className="part">
                            <button type="button" className="ant-btn bttn _silent href" onClick={() => this.props.hideModalFunction()}><Trans>Zavrieť</Trans></button>
                        </div>
                        <div className="part">
                            <button type="button" className="ant-btn bttn _primary href" onClick={this.handleAddUserToUserGroup} disabled={!valid}><Trans>Priradiť sa do triedy</Trans></button>
                        </div>
                    </ModalFooter>
                </ModalContainer>
            </section>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalAddUserToUserGroup)