import config from '../../config';
import { handleErrors } from '../../utils/handleErrors';
import { setLoadingRegister } from '../loadings/setLoading';
import { history } from '../../../index';
import { i18n } from '../../App';
import { addFloatingNotification } from '../notifications/floating';


//Functions to send c reate user r equest
export function registerUser(data: any) {
    return (dispatch: any) => {

        dispatch(setLoadingRegister(true))
        
        return fetch(config.api.registerUser, {
            method: 'post',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(handleErrors)
            .then(res => res.json())
            .then(json => {
                dispatch(setLoadingRegister(false))

                history.push({
                    pathname: '/login',
                    state: {
                        message: i18n._('Používateľ') +  ' "' + json.data.f_name + '" '  + i18n._('bol úspešne vytvorený'),
                        type: "success"
                    }
                });

                dispatch(addFloatingNotification({
                    type:"success",
                    text:i18n._('Úspešne ste sa zaregistrovali. Teraz sa môžete prihlásiť.'),
                    showBell: true
                }))
            })
            .catch((error: any)  => {
                dispatch(setLoadingRegister(false))
                dispatch(addFloatingNotification({
                    type:"warning",
                    text:i18n._('Používateľ s týmto menom už existuje!'),
                    showBell: true
                }))
            });
    };
}