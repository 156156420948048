import { setLoadingCreatedUserGroups } from '../loadings/setLoading';
import config from '../../config';
import { handleErrors } from '../../utils/handleErrors';
import { 
    GET_USERGROUPS_CREATED_STARTED, 
    GET_USERGROUPS_CREATED, 
    GET_USERGROUPS_CREATED_FAILED } from '../../constants/action-types';
import { store } from '../../store';

export function loadCreatedUserGroups() {
    return (dispatch: any) => {

        dispatch(setLoadingCreatedUserGroups(true))
        dispatch(loadCreatedUserGroupsStarted())

        return fetch(config.api.usergroups_created, {
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + store.getState().globalStore.token
            }
            
        })
            .then(handleErrors)
            .then(res => res.json())
            .then(res => {
                dispatch(loadCreatedUserGroupsSuccess(res.data))

                dispatch(setLoadingCreatedUserGroups(false))
            })
            .catch((error: any)  => {
                dispatch(loadCreatedUserGroupsFailed(error))
                dispatch(setLoadingCreatedUserGroups(false))
            });
    };
}

export const loadCreatedUserGroupsStarted = () => ({
    type: GET_USERGROUPS_CREATED_STARTED
});

export const loadCreatedUserGroupsSuccess = (data:any) => ({
    type: GET_USERGROUPS_CREATED,
    payload: data
});

export const loadCreatedUserGroupsFailed = (error: any) => ({
    type: GET_USERGROUPS_CREATED_FAILED,
    payload: { error }
});