import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IApplicationStore } from '../../../../../constants/store-interfaces';
import { IUSerInfo } from '../../../../../constants/types';
import { loadTestResults } from '../../../../../actions/tests/loadTestResults';
import { loadTests } from '../../../../../actions/tests/loadTests';
import { loadCreatedUserGroups } from '../../../../../actions/userGroups/loadCreatedUserGroups';
import { loadAssignedUserGroups } from '../../../../../actions/userGroups/loadAssignedUserGroups';
import { loadAssignedTests } from '../../../../../actions/tests/loadAssignedTests';
import { loadUserGroupTestsAll } from '../../../../../actions/tests/loadUserGroupTests';
import { loadTestResultsUserGroupAll } from '../../../../../actions/tests/loadTestResultsUserGroup';
import config from '../../../../../config';

interface IProps {
}

const DataLoader: React.FC<IProps> = props => {
    const dispatch = useDispatch()
    
    const userInfo = useSelector<IApplicationStore, IUSerInfo | null>(state => state.globalStore.userInfo)
        
    useEffect(() => {
        dispatch(loadTestResults())
        dispatch(loadTests())
        dispatch(loadUserGroupTestsAll())
        dispatch(loadTestResultsUserGroupAll())
    }, [dispatch])

    useEffect(() => {
        if((userInfo !== null) && (userInfo.role.name === config.roles.teacher)) {
            dispatch(loadCreatedUserGroups())
        } else {
            dispatch(loadAssignedUserGroups())
            dispatch(loadAssignedTests())
        }
    }, [dispatch, userInfo])
    
    return (<></>)
}

export default DataLoader