import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IApplicationStore } from '../../../constants/store-interfaces';
import { ETestUgType, ITestResult } from '../../../constants/types';
import { RouteComponentProps, withRouter } from 'react-router';
import { loadAssignedTests } from '../../../actions/tests/loadAssignedTests';
import { loadAssignedUserGroups } from '../../../actions/userGroups/loadAssignedUserGroups';
import ReactGA from 'react-ga';
import { loadTestResultsAll } from '../../../actions/tests/loadTestResultsAll';
import TestContainer from '../containers/TestContainer';
import InformationBox from '../parts/InformationBox';
import { Trans } from '@lingui/macro';

type PathParamsType = {
    id: string,
    testId: string
}

interface IProps extends RouteComponentProps<PathParamsType> {

}

const TestDetailUserGroupPage: React.FC<IProps> = props => {
    const dispatch = useDispatch()

    const [results, setResults] = useState<ITestResult[]>([])

    const assignedTests = useSelector((state: IApplicationStore) => state.testsStore.assignedTests)
    const assignedUserGroups = useSelector((state: IApplicationStore) => state.userGroupsStore.assignedUserGroups)
    const loadingResults = useSelector((state: IApplicationStore) => state.loadingStore.loadingTestResult)
    const loadingAssignedTests = useSelector((state: IApplicationStore) => state.loadingStore.loadingAssignedTests)
    const loadingAssignedUserGroups = useSelector((state: IApplicationStore) => state.loadingStore.loadingAssignedUserGroups)
    const userInfo = useSelector((state: IApplicationStore) => state.globalStore.userInfo)

    useEffect(() => {
        ReactGA.pageview('Test/Domáca úloha')
    }, [])

    useEffect(() => {
        let newTest = assignedTests.find(t => t.id === Number(props.match.params.testId))
        let isRendered = true

        if(typeof newTest !== 'undefined' && newTest.usergroup_id !== null && newTest.settings.ugType === ETestUgType.test) {
            loadTestResultsAll(Number(props.match.params.testId))
                .then((res) => {
                    if(isRendered) {
                        setResults((typeof res !== 'undefined')?res.filter((r:ITestResult) => r.test_id === Number(props.match.params.testId) && r.user_id === userInfo?.id):[])
                    }
                }).catch(() => {
                    if(isRendered) {
                        setResults([])
                    }
                })
        }

        return () => {
            isRendered = false
        }
    }, [assignedTests, props.match.params.testId, userInfo])

    useEffect(() => {
        dispatch(loadAssignedTests())
        dispatch(loadAssignedUserGroups())
    }, [dispatch])

    let userGroup = assignedUserGroups.find(t => t.id === Number(props.match.params.id))
    let test = assignedTests.find(t => t.id === Number(props.match.params.testId))

    if(typeof test === 'undefined' && loadingAssignedTests) {
        return (null)
    } else {
        if(typeof test === 'undefined') {
            return (
                <InformationBox type='warning' showBell={true}>
                    <Trans>Uvedený test nie je možné spustiť.</Trans>
                </InformationBox>
            )
        }
    }

    let allowed = true
    
    if(
        test.usergroup_id !== null && 
        test.settings.ugType === ETestUgType.test && 
        results.length
    ) {
        allowed = false
    }

    return (
        <TestContainer
            test={test}
            allowed={allowed}
            loading={loadingResults || loadingAssignedTests || loadingAssignedUserGroups}
            usergroup={userGroup}
        />
    )
}

export default withRouter(TestDetailUserGroupPage)