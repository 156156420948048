import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import 'react-circular-progressbar/dist/styles.css';
import './index.css';
import App from './js/App';
import { store, persistor } from './js/store/index';
import { Provider }from 'react-redux';
import { Router } from "react-router-dom";
import { createBrowserHistory } from 'history';
import ScrollToTop from './js/components/stateless/ScrollTop';
import { PersistGate } from 'redux-persist/integration/react';

export const history = createBrowserHistory({ basename: process.env.PUBLIC_URL })

history.listen(_ => {
    window.scrollTo(0, 0)  
})

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <Router history={history}>
                <ScrollToTop>
                    <App />
                </ScrollToTop>
            </Router>
        </PersistGate>
    </Provider>, 
    document.getElementById('root')
)
