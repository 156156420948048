import React, {Component, PropsWithChildren} from 'react'
import { ETestDifficulty } from '../../../constants/types'
import { Trans } from '@lingui/macro'

interface IProps {
    difficulty: ETestDifficulty
}

class TestDifficulty extends Component<PropsWithChildren<IProps>, any> {

    render() {
        const { difficulty } = this.props
        
        let difficultyText = (
            <span className="tag _red _alt">
                <Trans>Ťažká</Trans>
            </span>
        )

        switch(difficulty) {
            case ETestDifficulty.easy:
                difficultyText = (
                    <span className="tag _green _alt">
                        <Trans>Ľahká</Trans>
                    </span>
                )
                break
            case ETestDifficulty.medium:
                difficultyText = (
                    <span className="tag _primary _alt">
                        <Trans>Stredná</Trans>
                    </span>
                )
                break
        }

        return difficultyText
    }
}

export default TestDifficulty
