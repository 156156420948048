import config from '../../config';
import { handleErrors } from '../../utils/handleErrors';
import { setLoadingLogin } from '../loadings/setLoading';
import { 
    UPDATE_USER_STARTED,
    UPDATE_USER,
    UPDATE_USER_FAILED
} from '../../constants/action-types';
import { store } from '../../store';
import { addFloatingNotification } from '../notifications/floating';
import { i18n } from '../../App';


//Functions to send login request
export function updateUser(data: any) {
    return (dispatch: any) => {

        dispatch(setLoadingLogin(true))
        dispatch(updateUserStarted(true))
        
        return fetch(config.api.updateUser, {
            method: 'put',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + store.getState().globalStore.token
            },
            body: JSON.stringify(data)
        })
            .then(handleErrors)
            .then(res => res.json())
            .then(json => {                    
                dispatch(setLoadingLogin(false))

                if(json.status  === 'success') {
                    dispatch(updateUserSuccess(data))

                    store.dispatch(addFloatingNotification({
                        type:"success",
                        text:i18n._('Informácie úspešne aktualizované!'),
                        showBell: true
                    }))
                } else {
                    throw Error(json.message);
                }
            })
            .catch((error: any)  => {
                dispatch(setLoadingLogin(false))
                dispatch(updateUserFailed(error))
            });
    };
}

export function updateUserStarted(data: any) {
    return { type: UPDATE_USER_STARTED, payload: data }
}

export function updateUserSuccess(data: any) {
    return { type: UPDATE_USER, payload: data }
}

export function updateUserFailed(data: any) {
    return { type: UPDATE_USER_FAILED, payload: data }
}