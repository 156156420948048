import { RESET_STATE, GET_CREATED_WORDS, EDIT_CREATED_WORD, DELETE_CREATED_WORD, SET_LOGIN_USER_DATA, STORE_WORDGROUPS, SET_LOGOUT_USER_DATA, SET_LOGOUT_USER_DATA_WRONG, SET_LOGIN_USER_DATA_WRONG } from '../constants/action-types';
import { Reducer } from 'redux'
import { IWordsStore } from '../constants/store-interfaces';
import { IWordCreatedType } from '../constants/types';

const initialState: IWordsStore = {
    createdWords: []
}

const reducer: Reducer<IWordsStore> = (state = initialState, action) => {
    let was = false

    switch(action.type) {
        case SET_LOGIN_USER_DATA:
            state = initialState
            was = true
            break
        case GET_CREATED_WORDS: 
            state = {
                ...state,
                createdWords:  action.payload,
            }
            was = true
            break
        case STORE_WORDGROUPS:
            let newWords: IWordCreatedType[] = []

            for(let word of action.payload.words) {
                let existWord = state.createdWords.find(value => (
                    (value.id === word.id) &&
                    (value.language_from.id === action.payload.language_from_id) &&
                    (value.language_to.id === action.payload.language_to_id)
                ))

                if(typeof existWord === 'undefined') {
                    newWords.push({
                        id: word.id,
                        created_at: action.payload.created_at,
                        is_active: true,
                        language_from: action.payload.language_from,
                        language_to: action.payload.language_to,
                        translation: word.translation,
                        word_text: word.word_text,
                        updated_at: action.payload.updated_at,
                        wordgroups: [action.payload]
                    })
                }
            }

            state = {
                ...state,
                createdWords: state.createdWords.concat(newWords)
            }
            was = true
            break
        case EDIT_CREATED_WORD:
            //Get edited word
            let editedIndex = state.createdWords.findIndex((word: IWordCreatedType) =>  word.id === action.payload.id)

            if(typeof editedIndex !== 'undefined') {
                let word = state.createdWords[editedIndex]
                
                word.word_text = action.payload.data.word_text
                word.translation = action.payload.data.translation

                let updatedWords = state.createdWords

                updatedWords[editedIndex] = word

                state = {
                    ...state,
                    createdWords: updatedWords
                }
            }
            was = true
            break
        case DELETE_CREATED_WORD:
            state =  {
                ...state,
                createdWords: state.createdWords.filter((word: IWordCreatedType) => word.id !== action.payload)
            }
            was = true
            break
        case SET_LOGOUT_USER_DATA:
        case SET_LOGOUT_USER_DATA_WRONG:
        case SET_LOGIN_USER_DATA_WRONG:
        case RESET_STATE:
            state = initialState
            was = true
            break
    }

    if(action.type.endsWith("_FAILED") && was) {
        state = {
            ...state,
            createdWords: []
        }
        //TODO show error
    }

    return state
}

export { reducer as wordsReducer }