import config from '../../config';
import { handleErrors } from '../../utils/handleErrors';
import { setLoadingLogin } from '../loadings/setLoading';
import { 
    SET_TUTORIAL_STARTED,
    SET_TUTORIAL,
    SET_TUTORIAL_FAILED
} from '../../constants/action-types';
import { store } from '../../store';


//Functions to send login request
export function setTutorial() {
    return (dispatch: any) => {

        dispatch(setLoadingLogin(true))
        dispatch(setTutorialtarted(true))
        
        return fetch(config.api.increaseTraining, {
            method: 'post',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + store.getState().globalStore.token
            }
        })
            .then(handleErrors)
            .then(res => res.json())
            .then(json => {                    
                dispatch(setLoadingLogin(false))

                if(json.status  === 'success') {
                    dispatch(setTutorialSuccess())
                } else {
                    throw Error(json.message);
                }
            })
            .catch((error: any)  => {
                dispatch(setLoadingLogin(false))
                dispatch(setTutorialFailed(error))
            });
    };
}

export function setTutorialtarted(data: any) {
    return { type: SET_TUTORIAL_STARTED, payload: data }
}

export function setTutorialSuccess() {
    return { type: SET_TUTORIAL, payload: null }
}

export function setTutorialFailed(data: any) {
    return { type: SET_TUTORIAL_FAILED, payload: data }
}