import React, {Component, PropsWithChildren} from 'react'
import moment from 'moment'

interface IProps {
    dateFrom: string,
    dateTo: string
    title: string,
    tag: string,
    tooltip: string
}

class DateTextBetween extends Component<PropsWithChildren<IProps>, any> {

    render() {
        const { dateFrom, dateTo, title, tag, tooltip }  = this.props

        return (
            <div className="part-list part-dates-between" data-title={title} data-tooltip={tooltip}>
                <span className={"tag " + tag}>
                    <strong>{moment(new Date(dateFrom)).format('DD.MM.YYYY')}</strong> <small>{moment(new Date(dateFrom)).format('HH:mm')}</small>
                </span>
                    - 
                <span className={"tag " + tag}>
                    <strong>{moment(new Date(dateTo)).format('DD.MM.YYYY')}</strong> <small>{moment(new Date(dateTo)).format('HH:mm')}</small>
                </span>
            </div>
        )
    }
}

export default DateTextBetween
