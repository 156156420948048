import config from '../../config';
import { handleErrors } from '../../utils/handleErrors';
import { store } from '../../store';

export function loadTranslations(word: string, from: string, to: string) {
    return fetch(config.api.words_translation, {
        method: 'POST',
        credentials: 'include',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + store.getState().globalStore.token
        },
        body: JSON.stringify({
            word: word,
            languageFrom: from,
            languageTo: to
        })        
    })
        .then(handleErrors)
        .then(res => res.json())
        .then(res => {
            return res
        })
        .catch((error: any)  => {
            return {
                status: 'failed'
            }
        });
}