import React from 'react';
import { IApplicationStore } from '../../../../constants/store-interfaces';
import moment from 'moment';
import { useSelector } from 'react-redux';

interface IProps {
    finishTest: () => any,
    currentTime: any
}

const Timer: React.FC<IProps> = (props) => {
    const currentTest = useSelector((state: IApplicationStore) => state.globalStore.currentTest)
        
    if(currentTest !== null) {
        if(currentTest.test.settings.time !== null) {
            if(typeof currentTest.testResult !== 'undefined') {
                if(currentTest.testResult.result !== null) {
                    let diffTime = Math.abs(moment(currentTest.testResult.date_started).diff(moment(props.currentTime), 'seconds'))
                    
                    let time = Math.max(0, (currentTest.test.settings.time*60) - diffTime)

                    let minutes = ('0' + (Math.floor(time / 60))).slice(-2)
                    let seconds = ('0' + (time - Number(minutes) * 60)).slice(-2)
                
                    let color = 'black'

                    if(time < 40) {
                        color = 'orange'
                    } else if(time < 10) {
                        color = 'red'
                    }

                    return (
                        <div className="test-time bttn" style={{color: color}}>
                            {minutes}:{seconds}
                        </div>
                    )
                }
            }
        }
    }
        
    return (null)
}

export default Timer