import React, {Component} from 'react'
import ConfirmModal from '../../../../simple/ConfirmModal';
import { IApplicationStore } from '../../../../../constants/store-interfaces';
import { connect } from 'react-redux';
import { Trans } from '@lingui/macro';
import { setShowConfirmModal } from '../../../../../actions/modals/setShowModal';
import InformationBox from '../../InformationBox';
import { IUserGroupType } from '../../../../../constants/types';


interface IStateProps {
}

interface IDispatchProps {
    setShowConfirmModal: typeof setShowConfirmModal,
}

const mapStateToProps = (state: IApplicationStore): IStateProps => ({
})

const mapDispatchToProps = (dispatch: any): IDispatchProps => ({
    setShowConfirmModal: (value: boolean) =>  dispatch(setShowConfirmModal(value)),
})

interface IProps extends IStateProps, IDispatchProps {
    userGroup: IUserGroupType,
    confirmFunction: () => any
}

interface IState {
}

class ModalDeleteUserGroups extends Component<IProps, IState> {
    constructor(props:  any)  {
        super(props)

        this.deleteUserGroup = this.deleteUserGroup.bind(this)
    }

    deleteUserGroup() {
        this.props.confirmFunction()
        this.props.setShowConfirmModal(false)
    }

    render() {
        const { userGroup } = this.props
        
        return (
            <ConfirmModal
                confirmFunction={this.deleteUserGroup}
            >
                <div className="input-row">
                    <h4 className="headline-divider">
                        <Trans>Naozaj chcete odstrániť túto triedu?</Trans>
                    </h4>
                </div>
                <div className="input-row">
                    <div className="summary">
                        <div className="summary-block">
                                                    
                            <div className="summary-content">
                                <div className="tag _primary">{userGroup.name}</div>
                            </div>
                        
                        </div>
                    </div>
                </div>
                    
                <InformationBox type="warning" showBell={true}>
                    <Trans>Upozornenie! Pri odstránení triedy prídete o všetkých žiakov ktorí sa v tejto triede nachádzajú a žiaci stratia slovíčka, ktoré ste im priradili!</Trans>
                </InformationBox>
            </ConfirmModal>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalDeleteUserGroups)