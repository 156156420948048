import React, { useState, useEffect } from 'react'
import { IRanking } from '../../../../constants/types';
import { useSelector } from 'react-redux';
import { IApplicationStore } from '../../../../constants/store-interfaces';
import IconWidget from './IconWidget';
import { i18n } from '../../../../App';
import { Trans } from '@lingui/macro';

interface IProps {
}

const TotalPointsThisMonthWidget: React.FC<IProps> = props => {
    const rankings = useSelector((state: IApplicationStore) => state.rankingStore.monthRankings)
    const loading = useSelector((state: IApplicationStore) => state.loadingStore.loadingMonthRanking)

    const [userRanking, setUserRanking] = useState<IRanking|undefined>(undefined)

    useEffect(() => {
        let month = new Date()

        let month1 = month.getFullYear() + '_' + (month.getMonth()+1)

        if(typeof rankings[month1] !== 'undefined') {
            if(typeof rankings[month1].user !== 'undefined') {
                setUserRanking(rankings[month1].user)
            }
        }
    }, [rankings])

    let title = (<span>-</span>)

    if(typeof userRanking !== 'undefined') {
        let rateTitle = i18n._('bodov')

        if((userRanking.ranking > 1) && (userRanking.ranking <= 4)) {
            rateTitle = i18n._('body')
        } else if (userRanking.ranking === 1) {
            rateTitle = i18n._('bod')
        }

        title = (
            <span>
                + {userRanking.ranking} {rateTitle}<br/>
                <small>
                    ({userRanking.rank}. <Trans>miesto</Trans>)
                </small>
            </span>
        )
    }
    return (
        <IconWidget
            title={title}
            subtitle={i18n._('Aktuálny mesiac')}
            icon={'la-area-chart'}
            widgetColor='green'
            loading={loading}
        />
    )
}

export default TotalPointsThisMonthWidget