import React, {Component} from 'react';
import { connect } from 'react-redux';
import { IApplicationStore } from '../../../../../constants/store-interfaces';
import { Trans } from '@lingui/macro';
import { i18n } from '../../../../../App';
import { ETestUgType } from '../../../../../constants/types';
import SummaryBlock from '../../small/SummaryBlock';

interface IStateProps {
}

interface IDispatchProps {
}

const mapStateToProps = (state: IApplicationStore): IStateProps => ({
})

const mapDispatchToProps = (dispatch: any): IDispatchProps => ({
})

interface IProps extends IStateProps, IDispatchProps{
    handleShowTestModal: (type: ETestUgType) => any
}

interface IState {
}


class TestType extends Component<IProps, IState> {
    constructor(props: any) {
        super(props)

        this.onClick = this.onClick.bind(this)
    }

    onClick(type: ETestUgType) {
        this.props.handleShowTestModal(type)
    }

    render() {

        return (
            <div className="form _alt">
                <div className="summary">
                    <SummaryBlock title={i18n._('Domáca úloha')} iconName={'cog'}>
                        <div className="input-row">
                            <div className="part">
                                <p>
                                    <Trans>Tento typ môžu žiaci opakovať viac krát, viete si zobraziť priebeh výsledkov, ako sa zlepšujú a štatistiky k zadanej domácej úlohe a k jednotlivým slovám. Môžete si prezrieť aktivitu svojich študentov v rámci zadanej domácej úlohy.</Trans>
                                </p>
                                <div className="bttn _primary _small" onClick={() => this.onClick(ETestUgType.homework)}>
                                    <Trans>Vytvoriť</Trans>
                                </div>
                            </div>
                        </div>
                    </SummaryBlock>
                    <SummaryBlock title={i18n._('Test')} iconName={'cog'}>
                        <div className="input-row">
                            <div className="part">
                                <p>
                                <Trans>Tento typ môžu žiaci spustiť iba jeden krát, nie je možné ho opakovať. Ihneď uvidíte výsledky žiakov a ich úspešnosť v prekladoch.</Trans>
                                </p>
                                <div className="bttn _primary _small" onClick={() => this.onClick(ETestUgType.test)}>
                                    <Trans>Vytvoriť</Trans>
                                </div>
                            </div>
                        </div>
                    </SummaryBlock>
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TestType)