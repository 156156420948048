import React, { PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import { IApplicationStore } from '../../../../constants/store-interfaces';
import { IUSerInfo } from '../../../../constants/types';
import config from '../../../../config';

interface IProps {
    
}

const AuthUserWrapper: React.FC<PropsWithChildren<IProps>> = props => {
    const userInfo = useSelector<IApplicationStore, IUSerInfo | null>(state => state.globalStore.userInfo)
    const userLoggedIn = useSelector<IApplicationStore, boolean>(state => state.globalStore.userLoggedIn)

    let authenticated = false

    if(userInfo !== null) {
        if((userInfo.role.name === config.roles.user) && (userLoggedIn)) {
            authenticated = true
        }
    }

    if(authenticated) {
        return (<>{props.children}</>)
    } else {
        return (null)
    }
}

export default AuthUserWrapper