import { setLoadingCreatedUserGroups } from '../loadings/setLoading';
import config from '../../config';
import { handleErrors } from '../../utils/handleErrors';
import { DELETE_WORDGROUP_FROM_USERGROUP_STARTED, DELETE_WORDGROUP_FROM_USERGROUP, DELETE_WORDGROUP_FROM_USERGROUP_FAILED } from '../../constants/action-types';
import { store } from '../../store';
import { addFloatingNotification } from '../notifications/floating';
import { i18n } from '../../App';


export function deleteWordGroupFromUserGroup(data:any, ugId: number) {
    return (dispatch: any) => {

        dispatch(setLoadingCreatedUserGroups(true))
        dispatch(deleteWordGroupFromUserGroupStarted())

        return fetch(config.api.usergroups + '/' + ugId + '/wordgroups/delete', {
            method: 'POST',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + store.getState().globalStore.token
            },
            body: JSON.stringify(data)
            
        })
            .then(handleErrors)
            .then(res => res.json())
            .then(res => {
                dispatch(deleteWordGroupFromUserGroupSuccess({userGroupId: ugId, wgId: data.wordgroups[0]}))
                dispatch(setLoadingCreatedUserGroups(false))

                dispatch(addFloatingNotification({
                    type:"success",
                    text:i18n._('Skupina slovíčok bola úspešne odstránená z triedy'),
                    showBell: true
                }))

            })
            .catch((error: any)  => {
                dispatch(deleteWordGroupFromUserGroupFailed(error))
                dispatch(setLoadingCreatedUserGroups(false))
            });
    };
}

export const deleteWordGroupFromUserGroupStarted = () => ({
    type: DELETE_WORDGROUP_FROM_USERGROUP_STARTED
});

export const deleteWordGroupFromUserGroupSuccess = (data:any) => ({
    type: DELETE_WORDGROUP_FROM_USERGROUP,
    payload: data
});

export const deleteWordGroupFromUserGroupFailed = (error: any) => ({
    type: DELETE_WORDGROUP_FROM_USERGROUP_FAILED,
    payload: { error }
});