import { setLoadingAssignedWordGroups } from '../loadings/setLoading';
import config from '../../config';
import { handleErrors } from '../../utils/handleErrors';
import { IUWordGroupType } from '../../constants/types';
import { ASSIGN_WORD_GROUP_TO_USERGROUP, ASSIGN_WORD_GROUP_TO_USERGROUP_FAILED, ASSIGN_WORD_GROUP_TO_USERGROUP_STARTED } from '../../constants/action-types';
import { store } from '../../store';
import { addFloatingNotification } from '../notifications/floating';
import { i18n } from '../../App';


export function assignWordGroupToUserGroup(data:any, ugId: number, wg: IUWordGroupType) {
    return (dispatch: any) => {

        dispatch(setLoadingAssignedWordGroups(true))
        dispatch(assignWordGroupToUserGroupStarted())

        return fetch(config.api.usergroups + '/' + ugId + '/assign-wordgroup', {
            method: 'POST',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + store.getState().globalStore.token
            },
            body: JSON.stringify(data)
            
        })
            .then(handleErrors)
            .then(res => res.json())
            .then(res => {
                dispatch(assignWordGroupToUserGroupSuccess({userGroupId: ugId, ug: wg}))
                dispatch(setLoadingAssignedWordGroups(false))

                dispatch(addFloatingNotification({
                    type:"success",
                    text:i18n._('Skupina slovíčok bola úspešne priradená do triedy'),
                    showBell: true
                }))

            })
            .catch((error: any)  => {
                dispatch(assignWordGroupToUserGroupFailed(error))
                dispatch(setLoadingAssignedWordGroups(false))
            });
    };
}

export const assignWordGroupToUserGroupStarted = () => ({
    type: ASSIGN_WORD_GROUP_TO_USERGROUP_STARTED
});

export const assignWordGroupToUserGroupSuccess = (data:any) => ({
    type: ASSIGN_WORD_GROUP_TO_USERGROUP,
    payload: data
});

export const assignWordGroupToUserGroupFailed = (error: any) => ({
    type: ASSIGN_WORD_GROUP_TO_USERGROUP_FAILED,
    payload: { error }
});