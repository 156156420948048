import React, {Component} from 'react';
import { connect } from 'react-redux';
import { ITest, ITableRowType, ITableCellType, ITableHeaderCellType, IActionType, ETestType, ITestResult, IUserGroupType } from '../../../../constants/types';
import { IApplicationStore } from '../../../../constants/store-interfaces';
import TranslateLanguages from '../../../simple/TranslateLanguages';
import { i18n } from '../../../../App';
import NumberText from '../../../simple/tableElements/NumberText';
import { Trans } from '@lingui/macro';
import TableContainer from '../../containers/TableContainer';
import TitleText from '../../../simple/tableElements/TitleText';
import TestDifficulty from '../../../simple/tableElements/TestDifficulty';
import getTestTypeString from '../../../simple/classes/getTestTypeString';
import Percentage from '../../../simple/tableElements/Percentage';
import moment from 'moment';
import DateTextBetween from '../../../simple/tableElements/DateTextBetween';
import { setShowEditTestUserGroupModal, setShowDeleteTestUserGroupModal } from '../../../../actions/modals/setShowModal';
import { deleteTest } from '../../../../actions/tests/deleteTest';
import ModalDeleteTestUserGroup from '../modals/tests/ModalDeleteTestUserGroup';
import ModalEditTestUserGroup from '../modals/tests/ModalEditTestUserGroup';
import AssignedHomeworkInfoPart from './parts/AssignedHomeworkInfoPart';
import TestClass from '../../../simple/classes/testClass';
import { NavLink } from 'react-router-dom';


interface IStateProps {
    loadingAssignedTests: boolean,
    loadingResults: boolean,
    loadingTestResults: boolean,
    usergroupResults: {[key: number]: ITestResult[]}
}

interface IDispatchProps {
    setShowEditTestUserGroupModal: typeof setShowEditTestUserGroupModal,
    setShowDeleteTestModal: typeof setShowDeleteTestUserGroupModal
    deleteTest: typeof deleteTest
}

const mapStateToProps = (state: IApplicationStore): IStateProps => ({
    loadingAssignedTests: state.loadingStore.loadingAssignedTests,
    loadingResults: state.loadingStore.loadingTestResult,
    loadingTestResults: state.loadingStore.loadingTestResults,
    usergroupResults: state.testsStore.usergroupResults
})

const mapDispatchToProps = (dispatch: any): IDispatchProps => ({ 
    setShowEditTestUserGroupModal: (val: boolean) => dispatch(setShowEditTestUserGroupModal(val)),
    setShowDeleteTestModal: (value: boolean) => dispatch(setShowDeleteTestUserGroupModal(value)),
    deleteTest: (test: ITest, type: 'ug'|'self') => dispatch(deleteTest(test, type))
})


interface IProps extends IStateProps, IDispatchProps {
    currentUserGroupId: number,
    loadedTests: ITest[],
    testResults: ITestResult[],
    addTest: () => any,
    isTeacher: boolean,
    userGroup: IUserGroupType
}

interface IState {
    selectedTest: ITest | null
}

class AssignedHomeworkTable extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props)

        this.state = {
            selectedTest: null
        }

        this.handleEditTest = this.handleEditTest.bind(this)
        this.handleDeleteTest = this.handleDeleteTest.bind(this)
        this.confirmDeleteTeset = this.confirmDeleteTeset.bind(this)
    }

    handleEditTest(catchString: string) {
        const { loadedTests } = this.props

        let testId = Number(catchString.split('_')[1])

        let testObject = loadedTests.find((item:ITest) => item.id === testId)

        if(typeof testObject !== 'undefined') {
            this.setState({
                ...this.state,
                selectedTest: testObject
            })  
            
            this.props.setShowEditTestUserGroupModal(true)
        }
    }

    handleDeleteTest(catchString: string)  {

        const { loadedTests } = this.props

        let testId = Number(catchString.split('_')[1])

        let testObject = loadedTests.find((item:ITest) => item.id === testId)

        if(typeof testObject !== 'undefined') {
            this.setState({
                ...this.state,
                selectedTest: testObject
            })  
            
            this.props.setShowDeleteTestModal(true)
        }

    }

    confirmDeleteTeset() {
        const { selectedTest } = this.state

        if(selectedTest !== null) {
            this.props.deleteTest(selectedTest, 'ug')
        }
    }

    getRowsTeacher(tests:  ITest[] | undefined,  testResults: ITestResult[] | undefined, userGroup: IUserGroupType): ITableRowType[] {

        let rows: ITableRowType[]  = []

        if(typeof tests !== 'undefined') {

            for(let test of tests)  {
                let cells: ITableCellType[] = []

                const typeSelect: { label: string; value: string }[] = []

                Object.keys(ETestType).forEach(value => {
                    typeSelect.push({
                        label: getTestTypeString(ETestType[(value as ETestType)]),
                        value: ETestType[(value as ETestType)]
                    })
                })

                const typeValue = typeSelect.find(value => value.value === test.settings.type)

                let totalPoints = 0
                let maxPoints = 0

                let finishedUsers: number[] = []

                if(typeof testResults !== 'undefined') {

                    testResults.forEach(tR => {
                        if(tR.test_id === test.id) {
                            let testObject = new TestClass(test, tR)
                            
                            totalPoints += testObject.getTestRate()
                            maxPoints += testObject.getMaximumRate()

                            if(typeof tR.user_id !== 'undefined') {
                                if(!finishedUsers.includes(tR.user_id)) {
                                    finishedUsers.push(tR.user_id)
                                }
                            }
                        }
                    })
                }

                let percentage = null
                
                if(maxPoints > 0) {
                    percentage = Math.round(Number(totalPoints)/maxPoints*100)
                }

                let percentageContent = (null)

                //Find percentage
                

                if(percentage !== null) {
                    percentageContent = (<Percentage percentage={percentage} title={i18n._('Úspešnosť')}/>)
                }

                let tooltipShow = i18n._('Už sa žiakom nezobrazuje')
                let tooltipColor = '_red'
                
                if(test.valid_until) {
                    if(moment(test.valid_from).isBefore(moment()) && moment().isBefore(moment(test.valid_until))) {
                        tooltipColor = '_green'
                        tooltipShow = i18n._('Zobrazuje sa žiakom')
                    }

                    if(moment().isBefore(moment(test.valid_from))) {
                        tooltipColor = '_orange'
                        tooltipShow = i18n._('Zatiaľ sa žiakom nezobrazuje')
                    }
                }

                let diacriticText = i18n._('bez diakritiky')
                
                if(
                    (typeof test.settings.diacritic !== 'undefined') &&
                    (test.settings.diacritic)
                ) {
                    diacriticText = i18n._('s diakritikou')
                }
                
                cells = [
                    {
                        content: (<div className="part-title">{test.name}</div>),
                        stringToOrder:  test.name,
                        filterString:  test.name,
                        filterByString:  true
                    },
                    {
                        content: (
                            <TranslateLanguages
                                languageFrom={test.language_from}
                                languageTo={test.language_to}
                            />
                        ),
                        stringToOrder:  test.language_from.flag_name + test.language_to.flag_name,
                        filterString: test.language_from.flag_name + test.language_to.flag_name,
                        filterByString: false,
                        selectableFilter: true,
                        selectableName: i18n._('vybrať'),
                        selectableAllText: i18n._('Všetky'),
                        selectableTitle: i18n._('Preklad'),
                        selectableObject: [
                            {
                                object: (
                                        <TranslateLanguages
                                            languageFrom={test.language_from}
                                            languageTo={test.language_to}
                                        />
                                ),
                                value: test.language_from.flag_name + test.language_to.flag_name
                            }
                        ]
                    },
                    {
                        content: (<NumberText number={test.settings.words.length} text='' title={i18n._('Počet slovíčok')}/>),
                        stringToOrder:  Number(test.settings.words.length).toString(),
                        filterString:  Number(test.settings.words.length).toString(),
                        filterByString:  true
                    },
                    {
                        content: (<TitleText text={(typeof typeValue !== 'undefined')?typeValue.label:''} subtitle={diacriticText + ((test.settings.time !== null)?', ' + test.settings.time + ' ' + i18n._('min'):'')} title={i18n._('Typ testu')}/>),
                        stringToOrder:  Number(test.settings.words.length).toString(),
                        filterString:  Number(test.settings.words.length).toString(),
                        filterByString:  true
                    },
                    {
                        content: (<TestDifficulty difficulty={test.settings.difficulty} />),
                        stringToOrder: test.settings.difficulty,
                        filterString: test.settings.difficulty,
                        filterByString: true
                    },
                    {
                        content: percentageContent,
                        stringToOrder: test.created_at,
                        filterString: test.created_at,
                        filterByString: false
                    },
                    {
                        content: (<TitleText text={finishedUsers.length + '/' + userGroup.assigned_users.length} subtitle={''} title={i18n._('Splnená úloha')}/>),
                        stringToOrder: test.created_at,
                        filterString: test.created_at,
                        filterByString: false
                    },
                    {
                        content: (
                            <>
                                {(test.valid_until !== null)?(
                                    <DateTextBetween
                                        dateFrom={test.valid_from} 
                                        dateTo={test.valid_until}
                                        title={i18n._('Priradené')}
                                        tag={tooltipColor}
                                        tooltip={tooltipShow}
                                    />
                                ):(null)}
                            </>),
                        stringToOrder: test.valid_from,
                        filterString: test.valid_from,
                        filterByString: false,
                    },
                    {
                        content: (
                            <NavLink to={"/app/classes/" + test.usergroup_id + "/homeworks/" + test.id + "/detail"} target="_self" className="bttn _small _primary _icon" data-tooltip={i18n._("Detail domácej úlohy")}>
                                <i className="la la-search-plus"/> 
                                <Trans>Detail</Trans>
                            </NavLink>
                        ),
                        stringToOrder: test.updated_at,
                        filterString: test.updated_at,
                        filterByString: false
                    }
                ]

                rows.push({
                    cells: cells,
                    catchString: 'test_' + test.id.toString()
                })
            }
        }

        return rows
    }

    getRowsStudent(tests:  ITest[], results: ITestResult[]): ITableRowType[] {
        let rows: ITableRowType[] = []

        for(let test  of tests)  {
            let cells: ITableCellType[] = []

            let percentage:number|null = null
            let percentageContent = (null)

            //Find percentage
            let testResults = results.filter(tR => tR.test_id === test.id)

            if(testResults.length) {
                let cntPoints = 0
                let maxPoints = 0

                testResults.forEach(tR => {
                    let testObject = new TestClass(test, tR)
                    
                    cntPoints += testObject.getTestRate()
                    maxPoints += testObject.getMaximumRate()
                })

                percentage = Math.round(Number(cntPoints)/maxPoints*100)
            }

            if(percentage !== null) {
                percentageContent = (<Percentage percentage={percentage} title={i18n._('Moja úspešnosť')}/>)
            }

            let isValid = true

            if(moment(test.valid_from).isAfter(moment())) {
                isValid = false
            }   

            if((test.valid_until !== null) && (moment(test.valid_until).isBefore(moment()))) {
                isValid = false
            }

            let tooltipShow = i18n._('Neaktívna')
            let tooltipColor = '_red'
            
            let actions = (
                <div className="part-list">
                    <NavLink to={"/app/classes/" + test.usergroup_id + "/homeworks/" + test.id + "/detail"} target="_self" className="bttn _small _primary _icon" data-tooltip={i18n._("Detail domácej úlohy")}>
                        <i className="la la-search-plus"/>
                    </NavLink>
                </div>
            )

            if(isValid) {
                tooltipColor = '_silent'
                tooltipShow = i18n._('Aktívna')

                actions = (
                    <div className="part-list">
                        <NavLink to={"/app/classes/" + test.usergroup_id + "/homeworks/" + test.id} target="_self" className="bttn _small _green _icon">
                            <Trans>Spustiť</Trans>
                        </NavLink>
                        <NavLink to={"/app/classes/" + test.usergroup_id + "/homeworks/" + test.id + "/detail"} target="_self" className="bttn _small _primary _icon" data-tooltip={i18n._("Detail domácej úlohy")}>
                            <i className="la la-search-plus"/>
                        </NavLink>
                    </div>
                )
            }
            
            cells = [
                {
                    content: (<div className="part-title">{test.name}</div>),
                    stringToOrder:  test.name,
                    filterString:  test.name,
                    filterByString:  true
                },
                {
                    content: (
                        <TranslateLanguages
                            languageFrom={test.language_from}
                            languageTo={test.language_to}
                        />
                    ),
                    stringToOrder:  test.language_from.flag_name + test.language_to.flag_name,
                    filterString: test.language_from.flag_name + test.language_to.flag_name,
                    filterByString: false,
                    selectableFilter: true,
                    selectableName: i18n._('vybrať'),
                    selectableAllText: i18n._('Všetky'),
                    selectableTitle: i18n._('Preklad'),
                    selectableObject: [
                        {
                            object: (
                                    <TranslateLanguages
                                        languageFrom={test.language_from}
                                        languageTo={test.language_to}
                                    />
                            ),
                            value: test.language_from.flag_name + test.language_to.flag_name
                        }
                    ]
                },
                {
                    content: (<NumberText 
                                    number={(test.settings.time !== null)?test.settings.time:undefined} 
                                    text={(test.settings.time !== null)?i18n._('min'):''}
                                    title={i18n._('Časový limit')}/>),
                    stringToOrder:  (test.settings.time !== null)?test.settings.time.toString():"0",
                    filterString:  (test.settings.time !== null)?test.settings.time.toString():"0",
                    filterByString:  true
                },
                {
                    content: percentageContent,
                    stringToOrder: test.created_at,
                    filterString: test.created_at,
                    filterByString: false
                },
                {
                    content: (
                        <>
                            {(test.valid_until !== null)?(
                                <DateTextBetween
                                    dateFrom={test.valid_from} 
                                    dateTo={test.valid_until}
                                    title={i18n._('Priradené')}
                                    tag={tooltipColor}
                                    tooltip={tooltipShow}
                                />
                            ):(null)}
                        </>),
                    stringToOrder: test.valid_from,
                    filterString: test.valid_from,
                    filterByString: false
                },
                {
                    content: (
                        actions
                    ),
                    stringToOrder: test.updated_at,
                    filterString: test.updated_at,
                    filterByString: false
                }
            ]

            rows.push({
                cells: cells,
                catchString: '_' +  test.id
            })
        }

        return rows
    }

    getHeadersTeacher(): ITableHeaderCellType[] {
        return [
            {
                content: (<span><Trans>Názov</Trans></span>),
                sortBy: false,
                sortable: true
            },
            {
                content: (<span><Trans>Preklad</Trans></span>),
                sortBy: false,
                sortable: true
            },
            {
                content: (<span><Trans>Slovíčka</Trans></span>),
                sortBy: false,
                sortable: true
            },
            {
                content: (<span><Trans>Typ</Trans></span>),
                sortBy: false,
                sortable: true
            },
            {
                content: (<span><Trans>Náročnosť</Trans></span>),
                sortBy: false,
                sortable: false
            },
            {
                content: (<span><Trans>Splnená úloha</Trans></span>),
                sortBy: false,
                sortable: false
            },
            {
                content: (<span><Trans>Úspešnosť</Trans></span>),
                sortBy: false,
                sortable: true,
                sortableType: 'number'
            },
            {
                content: (<span><Trans>Priradené</Trans></span>),
                sortBy: true,
                sortable: true,
                sortableType: 'date'
            },
            {
                content: (<span></span>),
                sortBy: false,
                sortable: false
            },
        ]
    }

    getHeadersStudent(): ITableHeaderCellType[] {
        return [
            {
                content: (<span><Trans>Názov</Trans></span>),
                sortBy: false,
                sortable: true
            },
            {
                content: (<span><Trans>Preklad</Trans></span>),
                sortBy: false,
                sortable: true
            },
            {
                content: (<span><Trans>Časový limit</Trans></span>),
                sortBy: false,
                sortable: false,
                sortableType: "number"
            },
            {
                content: (<span><Trans>Moja úspešnosť</Trans></span>),
                sortBy: false,
                sortable: false
            },
            {
                content: (<span><Trans>Platnosť</Trans></span>),
                sortBy: true,
                sortableType: 'date',
                sortable: true,
            },
            {
                content: (<span></span>),
                sortBy: false,
                sortable: false
            },
        ]
    }

    getActions(): IActionType[] {

        return [
            {
                action: this.handleEditTest,
                name: i18n._('Upraviť')
            },
            {
                action: this.handleDeleteTest,
                name: i18n._('Odstrániť'),
                customClass: 'remove'
            }
        ]

    }

    render() {
        const { loadingAssignedTests, loadingResults, loadingTestResults, isTeacher, loadedTests, usergroupResults, testResults, currentUserGroupId, userGroup } = this.props
        const { selectedTest } = this.state
        
        let headers: ITableHeaderCellType[] = []
        let rows: ITableRowType[] = []
        let actions: IActionType[] = this.getActions()
        
        if(isTeacher) {
            let results = usergroupResults[currentUserGroupId]

            headers = this.getHeadersTeacher()
            rows = this.getRowsTeacher(loadedTests, results, userGroup)
        } else {
            let tests = loadedTests.filter(t => moment(t.valid_from).isBefore(moment()))

            headers = this.getHeadersStudent()
            rows = this.getRowsStudent(tests, testResults)
            actions = []
        }

        let emptyText = i18n._('Požiadajte svojho učiteľa o priradenie domácich úloh, prostredníctvom ktorých sa môžete učiť slovíčka.')

        if(isTeacher) {
            emptyText = i18n._('Priraďte nové domáce úlohy svojím žiakom, aby sa mohli zlepšovať vo svojej slovnej zásobe.')
        }
        return (
            <div>
                <div className="rows">
                    <AssignedHomeworkInfoPart isTeacher={isTeacher}/>
                    <div className="row">
                        <div className="row-part">
                            <TableContainer
                                headers={headers}
                                rows={rows}
                                actions={actions}
                                showActions={isTeacher}
                                showCheckbox={false}
                                showFilter={true}
                                pageSize={12}
                                grid={true}
                                showGrid={false}
                                title={i18n._('Domáce úlohy')}
                                showNew={isTeacher}
                                newTitle={i18n._('Vytvoriť novú domácu úlohu')}
                                newFunction={() => this.props.addTest()}
                                emptyText={i18n._('Aktuálne nie sú vytvorené žiadne domáce úlohy')}
                                emptySubtitle={emptyText}
                                loading={loadingAssignedTests || loadingResults || loadingTestResults}
                                defaultSort='asc'
                                mobileFixedGrid={true}
                            />
                        </div>
                    </div>
                </div>

                {(selectedTest !== null)?(
                    <ModalDeleteTestUserGroup
                        test={selectedTest}
                        confirmFunction={this.confirmDeleteTeset}
                    />
                ):(null)}
                {(selectedTest !== null)?(
                <ModalEditTestUserGroup 
                    test={selectedTest}
                />):(null)}
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AssignedHomeworkTable)