import { setLoadingRankingWorld } from '../loadings/setLoading';
import config from '../../config';
import { handleErrors } from '../../utils/handleErrors';
import { 
    LOAD_RANKING_WORLD,
    LOAD_RANKING_WORLD_FAILED,
    LOAD_RANKING_WORLD_STARTED } from '../../constants/action-types';
import { store } from '../../store';

export function loadWorld() {
    return (dispatch: any) => {

        dispatch(setLoadingRankingWorld(true))
        dispatch(loadWorldStarted())

        return fetch(config.api.rankingWorld, {
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + store.getState().globalStore.token
            }
            
        })
            .then(handleErrors)
            .then(res => res.json())
            .then(res => {
                if(typeof res.data !== 'undefined') {
                    dispatch(loadWorldSuccess(res.data))
                }
                
                dispatch(setLoadingRankingWorld(false))
            })
            .catch((error: any)  => {
                dispatch(loadWorldFailed(error))
                dispatch(setLoadingRankingWorld(false))
            });
    };
}

export const loadWorldStarted = () => ({
    type: LOAD_RANKING_WORLD_STARTED
});

export const loadWorldSuccess = (data:any) => ({
    type: LOAD_RANKING_WORLD,
    payload: data
});

export const loadWorldFailed = (error: any) => ({
    type: LOAD_RANKING_WORLD_FAILED,
    payload: { error }
});