import { 
    SET_LANGUAGE,
    SET_SHOW_ADD_MENU,
    SET_SHOW_NOTIFICATION_MENU,
    SET_SHOW_USERMENU,
    SET_LOGIN_USER_DATA,
    SET_LOGIN_USER_DATA_WRONG,
    SET_LOGOUT_USER_DATA,
    SET_LOGOUT_USER_DATA_WRONG,
    SET_MENU,
    UPDATE_TEST_RESULT,
    SET_SHOW_LANGUAGE,
    RESET_STATE,
    UPDATE_USER,
    SET_TUTORIAL,
    UPDATE_LOGIN_TIME,
    UPDATE_KEY
} from "../constants/action-types"
import { Reducer } from 'redux'
import { IGlobalStore } from '../constants/store-interfaces'
import config from "../config"
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { history } from "../..";

const initialState: IGlobalStore = {
    version: config.version,
    showNotificationMenu: false,
    showAddMenu: false,
    showUserMenu: false,
    showMenu: false,
    showLanguage: false,
    language: 'sk',
    userLoggedIn: false,
    token: null,
    userInfo: null,
    userError: '',
    currentTest: null,
    appKey: uuidv4()
}

const reducer: Reducer<IGlobalStore> = (state = initialState, action) => {
    let was = false

    switch(action.type) {
        //Update key
        case UPDATE_KEY:
            state = {
                ...state,
                appKey: uuidv4()
            }
            was = true
            break
        //Set menu
        case SET_MENU:
            state = {
                ...state,
                showMenu: action.payload
            }

            if(action.payload) {
                document.body.classList.add('menu-open')
                document.body.classList.remove('menu-closed')
            } else {
                document.body.classList.add('menu-closed')
                document.body.classList.remove('menu-open')
            }

            was = true
            break
        //Set language short
        case SET_LANGUAGE:
            state = {
                ...state,
                language: action.payload,
                appKey: uuidv4()
            }
            was = true
            break
        //Set show notification menu
        case SET_SHOW_NOTIFICATION_MENU:
            state = {
                ...state,
                showNotificationMenu: action.payload
            }
            was = true
            break
        //Set show language
        case SET_SHOW_LANGUAGE:
            state = {
                ...state,
                showLanguage: action.payload
            }
            break
        //Set show add menu
        case SET_SHOW_ADD_MENU:
            state = {
                ...state,
                showAddMenu: action.payload
            }
            was = true
            break
        //Show user menu
        case SET_SHOW_USERMENU:
            state = {
                ...state,
                showUserMenu: action.payload
            }
            was = true
            break
        //User updated
        case UPDATE_USER:
            if(state.userInfo !== null) {
                state = {
                    ...state,
                    userInfo: {
                        ...state.userInfo,
                        login: action.payload.login,
                        name: action.payload.name,
                        surname: action.payload.surname,
                        language: action.payload.language,
                        email: action.payload.email,
                        telephone: action.payload.telephone,
                        school: action.payload.school
                    },
                    language: action.payload.language,
                    appKey: uuidv4()
                }
            }
            was = true
            break
        case UPDATE_LOGIN_TIME:
            if(state.userInfo !== null) {
                state = {
                    ...state,
                    userInfo: {
                        ...state.userInfo,
                        last_login: moment().toISOString()
                    }
                }
            }
            was = true
            break
        //Set finished tutorial
        case SET_TUTORIAL:
            if(state.userInfo !== null) {
                state = {
                    ...state,
                    userInfo: {
                        ...state.userInfo,
                        training_status: 1,
                        last_login: moment().toISOString()
                    }
                }
            }
            was = true
            break
        //Set user info
        case SET_LOGIN_USER_DATA:
            state = {
                ...state,
                token: action.payload.token,
                userInfo: action.payload.user,
                userLoggedIn: true,
                userError: '',
                language: action.payload.user.language,
                appKey: uuidv4()
            }
            was = true
            break
        //If wrong  login
        case SET_LOGOUT_USER_DATA:
        case SET_LOGOUT_USER_DATA_WRONG:
        case SET_LOGIN_USER_DATA_WRONG:
        case RESET_STATE:
            state = initialState
            was = true
            break
        case UPDATE_TEST_RESULT:
            state = {
                ...state,
                currentTest: action.payload
            }

            if(typeof action.path !== 'undefined') {
                history.push(action.path)
            }
            was = true
            break
    }

    if(action.type.endsWith("_FAILED") && was) {
        //TODO show error
        console.log(action)
    }

    return state
}

export { reducer as globalReducer }