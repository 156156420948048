import React, { Component } from 'react';
import { Trans } from '@lingui/macro';

interface IProps {
    pages: number,
    currentPage: number,
    pageClick: (number: number) => any
}

class Pagination extends Component<IProps>{
    pagination(c: number, m:number) {
        var current = c,
            last = m,
            delta = 1, 
            left = current - delta,
            right = current + delta + 1,
            range = [],
            rangeWithDots = [],
            l;
    
        for (let i = 1; i <= last; i++) {
            if ((i === 1) || (i === last) || ((i >= left) && (i < right))) {
                range.push(i);
            }
        }
    
        for (let i of range) {
            if (l) {
                if (i - l === 2) {
                    rangeWithDots.push(l + 1);
                } else if (i - l !== 1) {
                    rangeWithDots.push(-1)
                }
            }
            rangeWithDots.push(i);
            l = i;
        }
    
        return rangeWithDots;
    }

    render() {
        const  { pages, currentPage, pageClick } = this.props

        let pagination:number[] =  this.pagination(currentPage, pages)

        if(pages <= 1) {
            return  (
                <div className="tableFooter">
                    <div className="pagination"></div>
                </div>
            )
        }

        return (
            <div className="tableFooter">
                <div className="pagination">
                    {(currentPage > 1)?(
                        <button className="nav prev" onClick={(currentPage !== 1)?() => pageClick(currentPage-1):() => null}>
                            <i className="la la-angle-left"/>
                            <Trans>Späť</Trans>
                        </button>
                    ):(
                        <div className="nav prev empty">
                            <i className="la la-angle-left"/>
                            <Trans>Späť</Trans>
                        </div>
                    )}
                    <ul>
                        {pagination.map((item:number, index:  number) => (
                            <li className={(currentPage === item)?'current':''} onClick={(item !== -1)?() =>  pageClick(item):() => null} key={index}>
                                {(item === -1)?(
                                    <span>...</span>
                                ):(<span className="href">
                                    {item}
                                </span>)}
                            </li>
                            )
                        )}
                    </ul>
                    {(currentPage !== pages)?(
                        <button className="nav next" onClick={(currentPage !== pages)?() => pageClick(currentPage+1):() => null}>
                            <Trans>Ďalej</Trans>
                            <i className="la la-angle-right"/>
                        </button>
                    ):(
                        <div className="nav next empty">
                            <Trans>Ďalej</Trans>
                            <i className="la la-angle-right"/>
                        </div>
                    )}
                </div>
            </div>
        )
    }
}

export default Pagination