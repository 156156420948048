import React, {Component} from 'react';
import { IApplicationStore } from '../../../../constants/store-interfaces';
import { ITestResult, ITest, ITimelineItem } from '../../../../constants/types';
import moment from 'moment';
import TestClass from '../../../simple/classes/testClass';
import { i18n } from '../../../../App';
import CircularGraph from '../../../simple/widgets/CircularGraph';
import LoadingElement from '../../../simple/LoadingElement';
import TimelineContainer from '../../containers/TimelineContainer';
import InfoModal from '../../../simple/InfoModal';
import TestResultInfo from '../modals/tests/TestResultInfo';
import { connect } from 'react-redux';

interface IStateProps {
    loading: boolean,
    loadingAssigned: boolean,
    loadingTestResults: boolean
}

interface IDispatchProps {
}

const mapStateToProps = (state: IApplicationStore): IStateProps => ({
    loading: state.loadingStore.loadingCreatedUserGroups,
    loadingAssigned: state.loadingStore.loadingAssignedTests,
    loadingTestResults: state.loadingStore.loadingTestResults
})

const mapDispatchToProps = (dispatch: any): IDispatchProps => ({ 
})

interface IProps extends IStateProps, IDispatchProps {
    userResults: ITestResult[]
    tests: ITest[]
}

interface IState {
    showModalResult: boolean,
    selectedResult: ITestResult | null
}

class UserActivity extends Component<IProps, IState> {

    constructor(props: any) {
        super(props)

        this.state = {
            showModalResult: false,
            selectedResult: null
        }

        this.toggleModalResults = this.toggleModalResults.bind(this)
        this.showResultInfo = this.showResultInfo.bind(this)
    }

    toggleModalResults() {
        this.setState({
            ...this.state,
            showModalResult: !this.state.showModalResult
        })
    }

    showResultInfo(tr: ITestResult) {
        this.setState({
            ...this.state,
            selectedResult: tr,
            showModalResult: true
        })
    }

    render() {
        const { loading, loadingTestResults, loadingAssigned, userResults, tests } = this.props
        const { selectedResult, showModalResult } = this.state

        let timelineItems: ITimelineItem[] = []

        userResults.sort((a, b) => moment(((b.date_finished !== null)?b.date_finished:b.date_started)).valueOf() - moment(((a.date_finished !== null)?a.date_finished:a.date_started)).valueOf())

        userResults.forEach(r => {
            let test = tests.find(t=> t.id === r.test_id)

            if(typeof test !== 'undefined') {
                let testObject = new TestClass(test, r)

                let percentage = testObject.getPercentage()

                let rateTitle = i18n._('bodov')

                if((testObject.getTestRate() > 1) && (testObject.getTestRate() <= 4)) {
                    rateTitle = i18n._('body')
                } else if (testObject.getTestRate() === 1) {
                    rateTitle = i18n._('bod')
                }
                timelineItems.push({
                    date: (r.date_finished !== null)?r.date_finished:r.date_started,
                    content: test.name + ' - ' + i18n._('výsledok') + ' (+' + testObject.getTestRate() + ' ' + rateTitle + ')',
                    icon: (<CircularGraph percentage={percentage}/>),
                    button: i18n._('Detail'),
                    buttonAction: () => this.showResultInfo(r)
                })
            }
        })

        let selectedTest: ITest | undefined = undefined
        
        if(selectedResult !== null) {
            selectedTest = tests.find(t=> t.id === selectedResult.test_id)
        }
        
        return (
                <div className="widget-holdeer">
                    <LoadingElement loadingValue={loading || loadingTestResults || loadingAssigned} />
                    <div className="widget-holder-body">
                        <div className="widget-part">
                            <section className="widget">
                                <div className="widget-body">
                                    <TimelineContainer
                                        show={true}
                                        perPage={10}
                                        items={timelineItems}
                                        emptyText={ i18n._('Žiak zatiaľ nevyplnil žiadnu úlohu ani test.')}
                                    />
                                </div>
                            </section>
                        </div>
                    </div>
                    {((selectedResult !== null) && (selectedResult.result !== null) && (typeof selectedTest !== 'undefined'))?(
                        <InfoModal
                            title={selectedTest.name + ' - ' + ((selectedResult.date_finished !== null)?moment(selectedResult.date_finished).format('DD.MM.YYYY HH:mm'):moment(selectedResult.date_started).format('DD.MM.YYYY HH:mm'))}
                            custom={true}
                            customShow={showModalResult}
                            customShowCloseFunction={this.toggleModalResults}
                        >
                            <TestResultInfo
                                test={selectedTest}
                                result={selectedResult}
                            />
                        </InfoModal>
                    ):(null)}
                </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserActivity)