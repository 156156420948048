import React, { useEffect, useState } from 'react';
import { validationService, IFormData, getValue, setValue } from '../simple/classes/validationService';

interface IProps {
    name: string,
    id: string
    type: 'textarea'|'text'|'password'|'checkbox',
    formData: IFormData,
    setFormData: (data: IFormData) => any,
    placeholder?: string,
    enableAutoCapitalize?: boolean,
    autoFocus?: boolean,
    autoComplete?: boolean,
    checkOthers?: string[],
    label?: string
}


const AppInput: React.FC<IProps> = props => {
    const [loading, setLoading] = useState(false)
    const [key, setKey] = useState(0)

    useEffect(() => {
        setKey(k => {
            return k++
        })
    }, [props.formData])

    const detectValidity = async (value: string|boolean, checkbox: boolean) => {
        let formDataValue = getValue(props.name, props.formData)

        if(typeof formDataValue === 'undefined') {
            return
        }

        formDataValue.value = value

        let formData = setValue(formDataValue, props.formData)

        props.setFormData(formData)
        
        let validInput = true

        if(formDataValue.rules) {
            setLoading(true)
            
            let toCheck = [props.name]

            if(typeof props.checkOthers !== 'undefined') {
                toCheck=[
                    ...toCheck,
                    ...props.checkOthers
                ]
            }

            formData = await validationService(formData, toCheck, checkbox)

            formDataValue = getValue(props.name, props.formData)

            if(typeof formDataValue === 'undefined') {
                return
            }
            
            setLoading(false)

            if(formDataValue.invalidMessages.length) {
                validInput = false
            }
        }

        formDataValue.valid = validInput

        formData = setValue(formDataValue, props.formData)
        
        props.setFormData(formData)
    }

    async function checkValidity(event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) {
        let value = event.target.value
        
        detectValidity(value, false)
    }

    async function checkValidityCheckbox(event: React.ChangeEvent<HTMLInputElement>) {
        let value = event.target.checked
        
        detectValidity(value, true)
    }

    let formDataValue = getValue(props.name, props.formData)

    if(typeof formDataValue === 'undefined') {
        return null
    }

    return (
        <div className={"ant-form-item-control " + (!formDataValue.valid?'has-error':'')} key={key}>
            <span className="ant-form-item-children">
                {props.type ==='textarea'?(
                    <textarea
                        className={"ant-input " + (loading?'loading':'')}
                        autoCapitalize={props.enableAutoCapitalize?'on':'off'}
                        autoCorrect='off'
                        placeholder={props.placeholder}
                        name={props.name}
                        id={props.id}
                        onChange={checkValidity}
                        value={typeof formDataValue.value === 'string'?formDataValue.value:''}
                        autoFocus={props.autoFocus?true:false}
                        autoComplete={props.autoComplete?'on':'off'}
                        disabled={formDataValue.disabled}
                    >

                    </textarea>
                ):(props.type === 'checkbox')?(
                    <label className={"checkbox ant-checkbox-wrapper " + ((formDataValue.value === true)?'ant-checkbox-wrapper-checked':'')}>
                        <span className={"ant-checkbox " + ((formDataValue.value === true)?'ant-checkbox-checked':'')}>
                            <input 
                                type="checkbox" 
                                className={"ant-checkbox-input "  + (loading?'loading':'')}
                                name={props.name}
                                id={props.id}
                                onChange={checkValidityCheckbox}
                                checked={typeof formDataValue.value !== 'string'?formDataValue.value:false}
                                disabled={formDataValue.disabled}
                            />
                            <span className="ant-checkbox-inner"></span>
                        </span>     
                        <span>
                            {props.label}
                        </span>                   
                    </label>    
                ):(
                    <input 
                        value={typeof formDataValue.value === 'string'?formDataValue.value:''}
                        type={props.type === 'text'?'text':'password'} 
                        className={"ant-input " + (loading?'loading':'')}
                        autoCapitalize={props.enableAutoCapitalize?'on':'off'}
                        autoCorrect='off'
                        placeholder={props.placeholder}
                        name={props.name}
                        id={props.id}
                        onChange={checkValidity}
                        autoFocus={props.autoFocus?true:false}
                        autoComplete={props.autoComplete?'on':'off'}
                        disabled={formDataValue.disabled}
                    />
                )}
            </span>
            {!formDataValue.valid?(
                <div className="ant-form-explain">
                    {formDataValue.invalidMessages.map((im, i) => (
                        <p key={i}>{im}</p>
                    ))}
                </div>
            ):(null)}
        </div>
    )
}

export default AppInput