import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ITestResult, ETestType } from '../../../../constants/types';
import { loadAssignedTests } from '../../../../actions/tests/loadAssignedTests';
import { loadAssignedUserGroups } from '../../../../actions/userGroups/loadAssignedUserGroups';
import { IApplicationStore } from '../../../../constants/store-interfaces';
import { loadTestResultsAll } from '../../../../actions/tests/loadTestResultsAll';
import getTestTypeString from '../../../simple/classes/getTestTypeString';
import TestClass from '../../../simple/classes/testClass';
import moment from 'moment';
import LoadingElement from '../../../simple/LoadingElement';
import { Trans } from '@lingui/macro';
import { NavLink } from 'react-router-dom';
import IconTextWidget from '../../../simple/widgets/IconTextWidget';
import TranslateLanguages from '../../../simple/TranslateLanguages';
import PercentageSmallWidget from '../../../simple/widgets/PercentageSmallWidget';
import TestDetailTest from '../../containers/TestElements/TestDetailTest';
import { i18n } from '../../../../App';
import NoMatchPage from '../../pages/NoMatchPage';
import { generateTest } from '../../../../actions/tests/generateTest';

interface IProps {
    testId: number,
    classId: number
}

const TestDetailInfoPage: React.FC<IProps> = props => {
    const dispatch = useDispatch()
    const [results, setResults] = useState<ITestResult[]>([])
    const [preparedResult, setPreparedResult] = useState<ITestResult|undefined>(undefined)

    const assignedTests = useSelector((state: IApplicationStore) => state.testsStore.assignedTests)
    const assignedUserGroups = useSelector((state: IApplicationStore) => state.userGroupsStore.assignedUserGroups)
    const loadingAssigned = useSelector((state: IApplicationStore) => state.loadingStore.loadingAssignedTests)
    const loadingUserGroups = useSelector((state: IApplicationStore) => state.loadingStore.loadingAssignedUserGroups)
    const loadingResults = useSelector((state: IApplicationStore) => state.loadingStore.loadingTestResult)
    const userInfo = useSelector((state: IApplicationStore) => state.globalStore.userInfo)

    useEffect(() => {
        dispatch(loadAssignedTests())
        dispatch(loadAssignedUserGroups())
    },[dispatch])

    useEffect(() => {
        loadTestResultsAll(Number(props.testId))
            .then((res) => {
                setResults((typeof res !== 'undefined')?res:[])
            }).catch(() => {
                setResults([])
            })
    }, [props.testId])

    let test = assignedTests.find(t => t.id === props.testId)

    useEffect(() => {
        if(typeof test !== 'undefined') {
            generateTest(test).then(result => setPreparedResult(result))
        }
    }, [test])

    let userGroup = assignedUserGroups.find(t => t.id === props.classId)

    if(((typeof test === 'undefined') || (typeof userGroup === 'undefined')) && !loadingAssigned && !loadingUserGroups && !loadingResults) {
        return (<NoMatchPage/>)
    }
    
    if(typeof test === 'undefined' || typeof preparedResult === 'undefined') {
        return (null)
    }
    
    const typeSelect: { label: string; value: string }[] = []

    Object.keys(ETestType).forEach(value => {
        typeSelect.push({
            label: getTestTypeString(ETestType[(value as ETestType)]),
            value: ETestType[(value as ETestType)]
        })
    })

    const typeValue = typeSelect.find(value => ((typeof test !== 'undefined') && (value.value === test.settings.type)))

    const myResult = results.find(r => (
                                        (typeof test !== 'undefined') && 
                                        (r.test_id === test.id) && 
                                        (typeof r.user_id !== 'undefined') &&
                                        (userInfo !== null) &&
                                        (r.user_id === userInfo.id)
                                    ))
    
    let percentage:number = 0
    let totalPoints = 0
    let maxPoints = 0

    if(typeof myResult !== 'undefined') {

        if(typeof test !== 'undefined') {
            let testObject = new TestClass(test, myResult)
            
            totalPoints += testObject.getTestRate()
            maxPoints += testObject.getMaximumRate()
        }

        percentage = Math.round(Number(totalPoints)/maxPoints*100)
    }

    maxPoints = Math.max(maxPoints, new TestClass(test, preparedResult).getMaximumRate())
    
    let isValid = false

    if(typeof myResult === 'undefined') {
        isValid = true
    }

    if((test.valid_until !== null) && (moment(test.valid_until).isBefore(moment()))) {
        isValid = false
    }

    return (
        <section id="mainSection">
            <LoadingElement loadingValue={loadingAssigned || loadingResults || loadingUserGroups} />

            <div className="mainSectionBody">
            
                <div className="rows">
                    <div className="row">
                        <div className="row-part">
                            <div className="page-header">
                                <div className="part">
                                    <h2>
                                        <Trans>Písomka</Trans>{(typeof userGroup !== 'undefined')?' (' + userGroup.name + ')':''} - {test.name}
                                    </h2>
                                    <small><Trans>Platnosť:</Trans> {moment(test.valid_from).format('DD.MM.YYYY HH:mm')} - {(test.valid_until !== null)?moment(test.valid_until).format('DD.MM.YYYY HH:mm'):(null)}</small>
                                    <p><Trans>Detail písomky s jej výsledkom</Trans></p>
                                </div>
                                <div className="part">
                                    <NavLink to={"/app/classes/" + props.classId} target="_self" className="bttn _small _orange _icon" data-tooltip={i18n._("Späť do triedy")}>
                                        <i className="la la-angle-left"/>
                                    </NavLink>
                                    {(isValid)?(
                                        <NavLink to={"/app/classes/" + props.classId + "/tests/" + test.id} target="_self" className="bttn _small _green _icon">
                                            <Trans>Spustiť</Trans>
                                        </NavLink>
                                    ):(null)}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="row-part">
                            <div className="small-widgets">
                                <ul>
                                    <li>
                                        <IconTextWidget
                                            showIcon={true}
                                            icon="la-gear"
                                        >
                                            <h3>{(typeof typeValue !== 'undefined')?typeValue.label:''}{(typeof test.settings.diacritic !== 'undefined')?(test.settings.diacritic)?', ' + i18n._('s diakritikou'):', ' + i18n._('bez diakritiky'):', ' + i18n._('bez diakritiky')} {(test.settings.time !== null)?'(' + test.settings.time + ' ' + i18n._('min') + ')':''}</h3>
                                            <p><Trans>Typ testu</Trans></p>
                                        </IconTextWidget>
                                    </li>
                                    <li>
                                        <IconTextWidget
                                            showIcon={true}
                                            icon="la-flag"
                                        >
                                            <TranslateLanguages
                                                languageFrom={test.language_from}
                                                languageTo={test.language_to}
                                            />
                                        </IconTextWidget>
                                    </li>
                                    <li>
                                        <IconTextWidget
                                            showIcon={true}
                                            icon="la-bar-chart"
                                        >
                                            <h3>{ totalPoints } / { maxPoints }</h3>
                                            {(totalPoints === 1)?(
                                                <p><Trans>Získaný bod</Trans></p>
                                            ):((totalPoints > 1) && (totalPoints <= 4))?(
                                                <p><Trans>Získané body</Trans></p>
                                            ):(
                                                <p><Trans>Získaných bodov</Trans></p>
                                            )}
                                            
                                        </IconTextWidget>
                                    </li>
                                    <li>
                                        <PercentageSmallWidget
                                            percentage={percentage}
                                        >
                                            <h3><Trans>Výsledok</Trans></h3>
                                        </PercentageSmallWidget>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="row-part">

                        </div>
                    </div>
                    <div className="row">
                        <div className="row-part">
                            <TestDetailTest
                                test={test}
                                myResult={myResult}
                                testResults={results}
                            />
                        </div>
                    </div>
                </div>
            
            </div>

        </section>
    )
}

export default TestDetailInfoPage