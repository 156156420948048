import { useEffect } from 'react';

function useOnClickOutside(ref:any, handler:any) {
  useEffect(
    () => {
        const listener = (event:any) => {
            if (!ref.current || ref.current.contains(event.target)) {
                if(typeof event.target.className === 'string') {
                    if(event.target.className.includes('wrapper'))  {
                        return 
                    } else if(ref.current.contains(event.target)) {
                        return
                    }
                }
            }      
            
            if(typeof event.target.className === 'string') {
                if(typeof event.target.className === 'string') {
                    if(event.target.className.includes('main-button-toggle')) {
                        return
                    }
                }
            }

            handler(event)
        }

        document.addEventListener('mousedown', listener)
        document.addEventListener('touchstart', listener)

        return () => {
            document.removeEventListener('mousedown', listener)
            document.removeEventListener('touchstart', listener)
        }
    },
    
    [ref, handler]
  )
}

export default useOnClickOutside