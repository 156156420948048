import React, {Component} from 'react';
import { connect } from 'react-redux';
import { Trans } from '@lingui/macro';
import { IApplicationStore } from '../../../../../constants/store-interfaces';
import { IQuestionVariantFilling, ILanguageType } from '../../../../../constants/types';
import SummaryWordFromTo from '../../../parts/small/SummaryWordFromTo';

interface IStateProps {

}

const mapStateToProps = (state: IApplicationStore) => ({
})

interface IDispatchProps {

}

const mapDispatchToProps = (dispatch: any) => ({
})

interface IProps extends IStateProps, IDispatchProps{
    question: IQuestionVariantFilling,
    languageFrom: ILanguageType,
    languageTo: ILanguageType
}

interface IState {
}


class FillingAnswer extends Component<IProps, IState> {

    render() {
        const { question, languageFrom, languageTo } = this.props
        
        let answerFrom = (question.wType === 'to')?question.word.to:question.word.from
        let answerTo = (question.wType === 'to')?question.word.from:question.word.to
        let langNameFrom = (question.wType === 'to')?languageTo:languageFrom
        let langNameTo = (question.wType === 'to')?languageFrom:languageTo

        return (
            <div className="lang-result">
                <Trans>Správna odpoveď</Trans>
                <SummaryWordFromTo
                    languageFrom={langNameFrom}
                    languageTo={langNameTo}
                    word={answerFrom}
                    translation={answerTo}
                />
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FillingAnswer)