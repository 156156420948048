import { setLoadingCreatedTests } from '../loadings/setLoading';
import config from '../../config';
import { handleErrors } from '../../utils/handleErrors';
import { store } from '../../store';
import { i18n } from '../../App';
import { loadTestsFailed } from './loadTests';

export function loadTest(id: number) {
    store.dispatch(setLoadingCreatedTests(true))

    return fetch(config.api.tests + '/' + id, {
        method: 'GET',
        credentials: 'include',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + store.getState().globalStore.token
        }
        
    })
        .then(handleErrors)
        .then(res => res.json())
        .then(res => {
            if(res.data.valid) {
                store.dispatch(setLoadingCreatedTests(false))

                return res.data
            } else {
                throw i18n._('Snažíte sa načítať neaktívny test/cvičenie/domácu úlohu!')
            }
        })
        .catch((error: any)  => {
            store.dispatch(loadTestsFailed(error))
            store.dispatch(setLoadingCreatedTests(false))
        });
}