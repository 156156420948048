import { Reducer } from 'redux'
import { ITestsStore } from '../constants/store-interfaces';
import { ITest } from '../constants/types';

import { 
    CREATE_TEST,
    GET_TESTS,
    DELETE_TEST,
    GET_TEST_RESULTS,
    SET_LOGIN_USER_DATA,
    MODIFY_USERGROUP_TEST,
    GET_TESTS_USERGROUP,
    GET_ASSIGNED_TESTS,
    RESET_STATE,
    MODIFY_TRAINING,
    GET_TEST_RESULTS_USERGROUP,
    SET_LOGOUT_USER_DATA,
    SET_LOGOUT_USER_DATA_WRONG,
    SET_LOGIN_USER_DATA_WRONG
} from '../constants/action-types';

const initialState: ITestsStore = {
    createdTests: [],
    assignedTests: [],
    usergroupTests: [],
    usergroupResults: [],
    testResults: []
}

const reducer: Reducer<ITestsStore> = (state = initialState, action) => {
    let was = false

    switch(action.type) {
        //If wrong  login
        case SET_LOGIN_USER_DATA:
            state = initialState
            was = true
            break
        case CREATE_TEST:
            if(action.payload.type === 'self') {
                state = {
                    ...state,
                    createdTests: state.createdTests.concat([action.payload.test])
                }
            } else {
                let ugs = state.usergroupTests[action.payload.test.usergroup_id]

                if(typeof ugs === 'undefined') {
                    ugs = []
                }

                if(typeof ugs.find(i => i.id === action.payload.test.usergroup_id) !== 'undefined') {
                    ugs = ugs.map(i => (i.id === action.payload.test.usergroup_id)?action.payload.test:i)
                } else {
                    ugs.push(action.payload.test)
                }

                state = {
                    ...state,
                    usergroupTests: {
                        ...state.usergroupTests,
                        [action.payload.test.usergroup_id]: ugs
                    }
                }
            }
            was = true
            break
        case GET_TESTS:
            state = {
                ...state,
                createdTests: action.payload
            }
            was = true
            break
        case GET_TESTS_USERGROUP:
            state = {
                ...state,
                usergroupTests: {
                    ...state.usergroupTests,
                    [action.payload.userGroupId]: action.payload.tests
                }
            }

            was = true
            break
        case DELETE_TEST:
            if(action.payload.type === 'self') {
                state = {
                    ...state,
                    createdTests: state.createdTests.filter(test => test.id !== action.payload.test.id)
                }
            } else {
                let ugsDeleted = state.usergroupTests[action.payload.test.usergroup_id]

                if(typeof ugsDeleted !== 'undefined') {
                    state = {
                        ...state,
                        usergroupTests: {
                            ...state.usergroupTests,
                            [action.payload.test.usergroup_id]: ugsDeleted.filter((item: ITest) => item.id !== action.payload.test.id )
                        }
                    }
                }
            }
            was = true
            break
        case GET_TEST_RESULTS:
            state = {
                ...state,
                testResults: action.payload
            }
            was = true
            break
        case MODIFY_USERGROUP_TEST:
            let testModified = state.usergroupTests[action.payload.ugId]

            if(typeof testModified !== 'undefined') {
                let test = testModified.find(i => i.id === action.payload.test.id)

                if(typeof test !== 'undefined') {
                    test.name = action.payload.test.name
                    test.valid_from = new Date(action.payload.test.valid_from).toUTCString()
                    test.valid_until = (action.payload.test.valid_until !== null)?new Date(action.payload.test.valid_until).toUTCString():null

                    testModified = testModified.map(i => (i.id === action.payload.test.id)?((typeof test !== 'undefined')?test:i):i)
                }
            }

            state = {
                ...state,
                usergroupTests: {
                    ...state.usergroupTests,
                    [action.payload.ugId]: testModified
                }
            }
            was = true
            break
        case MODIFY_TRAINING:
            let tests = state.createdTests
            let testUpdated = tests.find(t => t.id === action.payload.test.id)

            if(typeof testUpdated !== 'undefined') {
                testUpdated.name = action.payload.test.name

                tests = tests.map(i => (i.id === action.payload.test.id)?((typeof testUpdated !== 'undefined')?testUpdated:i):i)

                state = {
                    ...state,
                    createdTests: tests
                }
            }

            was = true
            break
        case GET_ASSIGNED_TESTS:
            state = {
                ...state,
                assignedTests: action.payload
            }
            was = true
            break
        case GET_TEST_RESULTS_USERGROUP:
            state = {
                ...state,
                usergroupResults: {
                    ...state.usergroupResults,
                    [action.payload.userGroupId]: action.payload.results
                }
            }

            was = true
            break
        case SET_LOGOUT_USER_DATA:
        case SET_LOGOUT_USER_DATA_WRONG:
        case SET_LOGIN_USER_DATA_WRONG:
        case RESET_STATE:
            state = initialState
            was = true
            break
    }

    if(action.type.endsWith("_FAILED") && was) {
        state = {
            ...state,
            createdTests: [],
            assignedTests: []
        }
        //TODO show error
    }

    return state
}

export { reducer as testsReducer }