import React, { useState, useRef } from 'react'
import { IApplicationStore } from '../../../../constants/store-interfaces';
import useOnClickOutside from '../../../../hooks/useOnClickOutside';
import { setShowUserMenu } from '../../../../actions/global/setShowUserMenu';
import { connect } from 'react-redux';
import { IUSerInfo } from '../../../../constants/types';
import { Trans } from '@lingui/macro';
import { logoutUser } from '../../../../actions/global/logoutUser';
import navigateTo from '../../../../hooks/navigateTo';

interface IStateProps  {
    user: IUSerInfo | null
}

const mapStateToProps = (state: IApplicationStore):IStateProps => ({
    user: state.globalStore.userInfo
})

interface IProps extends IStateProps{
    dispatch: any
}

function UserBlock(props: IProps): JSX.Element {

    const { user, dispatch } = props

    const [showDropdown, setshowDropdown] = useState(false)

    const ref = useRef<HTMLInputElement>(null);

    const setDropdownState = (value:boolean) => {
        setshowDropdown(value)
        dispatch(setShowUserMenu(value))
    }

    const logoutUserFunction = (user: IUSerInfo | null ) =>  {
        if(user !== null) {
            dispatch(logoutUser(user.f_name))
        } else {
            dispatch(logoutUser(''))
        }
    }

    useOnClickOutside(ref, () => setDropdownState(false));

    let shortName = ''

    if(
        (user !== null) &&
        (user.name.length > 0) &&
        (user.surname.length > 0)
    ) {
        shortName =  user.name[0] + user.surname[0]
    } else if (user !== null) {
        shortName = user.login[0] + user.login[1]
    }

    return (
        <div 
            ref={ref}
            className={"user  dropdown " + ((showDropdown)?'active':'')}
        >

            <div className="avatar" onClick={() => setDropdownState(!showDropdown)} data-tour="step3">{shortName}</div>
            <div className="dropdownContent _shadow _radius _small _user">
                <div className="wrapper">
                    <div className="list-nav">
                        <ul>
                            <li><div className="href" onClick={() => navigateTo('/app/settings', setDropdownState(false))} ><i className="la la-cog"/> <span><Trans>Nastavenia</Trans></span></div></li>                            
                            <li><div className="href" onClick={() => logoutUserFunction(user)}><i className="la la-sign-out"/><span><Trans>Odhlásiť</Trans></span></div></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default connect(mapStateToProps)(UserBlock)