import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { IApplicationStore } from "../../../constants/store-interfaces";
import { ITest, IUserGroupType, ETestUgType, ITestResult } from '../../../constants/types';
import InitTest from '../containers/TestElements/InitTest';
import ConfirmModal from '../../simple/ConfirmModal';
import { i18n } from '../../../App';
import { updateCurrentTest } from '../../../actions/global/updateCurrentTest';
import TestClass from '../../simple/classes/testClass';
import { store } from '../../../store/index';
import { addFloatingNotification } from '../../../actions/notifications/floating';
import { generateTest } from '../../../actions/tests/generateTest';
import LoadingElement from '../../simple/LoadingElement';
import moment from 'moment';
import Questions from './TestElements/Questions';
import { saveTestResult } from '../../../actions/tests/saveTestResult';
import { history } from '../../../..';

interface IProps {
    test: ITest,
    allowed: boolean,
    loading: boolean,
    usergroup?: IUserGroupType
}


const TestContainer: React.FC<IProps> = props => {
    const dispatch = useDispatch()

    const [ currentTime ] = useState(Date.now())
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false)

    const loadingTestGenerate = useSelector((state: IApplicationStore) => state.loadingStore.loadingGenerateTest)
    const loadingTestResult = useSelector((state: IApplicationStore) => state.loadingStore.loadingTestResult)
    const currentTest = useSelector((state: IApplicationStore) => state.globalStore.currentTest)
    const userInfo = useSelector((state: IApplicationStore) => state.globalStore.userInfo)

    useEffect(() => {
        if(currentTest !== null && typeof currentTest.testResult !== 'undefined' && currentTest.testResult.date_finished !== null) {
            history.push(window.location.pathname + '/result')
        }
    }, [currentTest])
    
    //Finish test
    const finishTest = useCallback(() => {
        console.log('1')
        if(currentTest !== null && typeof currentTest.testResult !== 'undefined') {
            let testResult:ITestResult = currentTest.testResult

            let test = new TestClass(currentTest.test, testResult)

            let testResultNew = testResult

            if((typeof testResultNew !== 'undefined') && (testResultNew.result !== null)) {
                testResultNew = {
                    ...testResultNew,
                    test_rate: new TestClass(currentTest.test, testResult).getTestRate(),
                    result: {
                        ...testResultNew.result,
                        current: testResultNew.result.questions.length
                    }
                }

                test.setResult(testResultNew)

                dispatch(saveTestResult(testResultNew, currentTest.test, test.getCurrentTest()))
            }
        }
    }, [currentTest, dispatch])

    useEffect(() => {
        const interval = setInterval(() => {
            let newTime = Date.now()
            
            if(currentTest !== null) {
                if(currentTest.test.settings.time !== null) {
                    if(typeof currentTest.testResult !== 'undefined') {
                        if(currentTest.testResult.result !== null) {
                            let diffTime = Math.abs(moment(currentTest.testResult.date_started).diff(moment(newTime), 'seconds'))
                            
                            let time = (currentTest.test.settings.time*60) - diffTime

                            if(time <= 0) {
                                finishTest()
                            }
                        }
                    }
                }
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [currentTest, finishTest])

    

    const handleStartRun = async () => {
        setShowConfirmModal(false)

        if(userInfo !== null) {
            let newCurrentTest = await generateTest(props.test)

            try {
                let testGenerate = new TestClass(props.test, newCurrentTest)

                //Set new test
                dispatch(updateCurrentTest(testGenerate.getCurrentTest()))

            } catch(e) {
                store.dispatch(addFloatingNotification({
                    type:"warning",
                    text:i18n._('Cvičenie s takýmito nastaveniami zatiaľ nie je možné vygenerovať!'),
                    showBell: true
                }))
            }
        }
    }

    //Force quit test
    const handleQuitTest = () => {
        if(currentTest !== null) {
            if(currentTest.test.usergroup_id !== null) {
                if(currentTest.test.settings.ugType === ETestUgType.test) {
                    finishTest()
                    return
                }
            }
        }

        dispatch(updateCurrentTest(null))
    }

    const handleToggleModal = () => {
        setShowConfirmModal(!showConfirmModal)
    }

    let title = i18n._('Spustiť cvičenie')
    let subtitle = i18n._('Naozaj chcete spustiť cvičenie?')

    if(props.test.usergroup_id !== null) {
        if (props.test.settings.ugType === ETestUgType.homework) {
            title = i18n._('Spustiť domácu úlohu')
            subtitle = i18n._('Naozaj chcete spustiť domácu úlohu?')
        }else {
            title = i18n._('Spustiť písomku')
            subtitle = i18n._('Naozaj chcete spustiť písomku? Môžete spustiť iba raz!')
        }
    }

    return (
        <section>
            <LoadingElement loadingValue={loadingTestGenerate || props.loading || loadingTestResult}/>
            {(currentTest == null)?(
                <InitTest test={props.test} initTest={handleToggleModal} alreadyDid={!props.allowed}/>
            ):(
                <>
                    <LoadingElement loadingValue={loadingTestResult||loadingTestGenerate}/>
                    <Questions test={props.test} quitTest={handleQuitTest} finishTest={finishTest} currentTime={currentTime}/>
                </>
            )}
            <ConfirmModal
                confirmFunction={handleStartRun}
                closeOnConfirm={true}
                yesOption={i18n._('Spustiť')}
                noOption={i18n._('Zrušiť')}
                title={title}
                custom={true}
                customFunctionToggle={handleToggleModal}
                customShow={showConfirmModal}
                loading={loadingTestGenerate}
            >
                {subtitle}
            </ConfirmModal>
        </section>
    )
}

export default TestContainer