import React, {Component} from 'react';
import { connect } from 'react-redux';
import { Trans } from '@lingui/macro';
import { IApplicationStore } from '../../../../../constants/store-interfaces';
import { IQuestionVariantSelect } from '../../../../../constants/types';
import posed from 'react-pose';

interface IStateProps {

}

const mapStateToProps = (state: IApplicationStore) => ({
})

interface IDispatchProps {

}

const mapDispatchToProps = (dispatch: any) => ({
})

interface IProps extends IStateProps, IDispatchProps{
    question: IQuestionVariantSelect,
    response: (answer: string) => any,
    questionId: number,
    disabled: boolean,
    answer: string
}

interface IState {
}

const Item = posed.div({
    enter: { y: 0, opacity: 1},
    exit: { y: 50, opacity: 0 }
})

const ItemTitle = posed.div({
    enter: { opacity: 1},
    exit: { opacity: 0 }
})

class SelectingQuestion extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props)

        this.handleChange = this.handleChange.bind(this)
    }

    handleChange = (i: number) => (event: React.FormEvent<HTMLInputElement>) => {
        const { question } = this.props

        this.props.response(question.answers[i])
    }

    render() {
        const { question, questionId, disabled } = this.props

        return (
            <>
                <ItemTitle>
                    <h1><Trans>Preložte slovo</Trans> <span>{(question.wType === 'from')?question.word.from:question.word.to}</span> ?</h1>
                </ItemTitle>
                <Item>
                    <div className="test-answer">
                        <div className="answer-list _full form">
                            {(question.answers.map((a:string, i: number) => (
                                <div key={i}>
                                    
                                    <div className="check radio">
                                        <input 
                                            type="radio" 
                                            id={"answer" + questionId + '_' + i} 
                                            disabled={disabled?true:false}
                                            name={"answer" + questionId}
                                            checked={question.response === a}
                                            onChange={this.handleChange(i)}
                                        />
                                        <label htmlFor={"answer" + questionId + '_' + i}>{a}</label>
                                    </div>
                                    
                                </div>
                            )))}
                        </div>
                    </div>
                </Item>
            </>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectingQuestion)