import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { IApplicationStore } from '../../../constants/store-interfaces';
import { i18n } from '../../../App';
import { ITabs, IUSerInfo } from '../../../constants/types';
import WorldRankings from '../parts/rankings/WorldRankings';
import { loadCountry } from '../../../actions/rankings/loadCountry';
import { Trans } from '@lingui/macro';
import TabsContainer from '../containers/TabsContainer';
import { loadWorld } from '../../../actions/rankings/loadWorld';
import CountryRankings from '../parts/rankings/CountryRankings';
import { withRouter } from 'react-router-dom';
import { GiEarthAfricaEurope } from "react-icons/gi";
import { FaRegFlag } from 'react-icons/fa';
import ReactGA from 'react-ga';

interface IProps {

}

const RankingPage: React.FC<IProps> = props => {
    const dispatch = useDispatch()
    
    const userInfo = useSelector<IApplicationStore, IUSerInfo | null>(state => state.globalStore.userInfo)
    

    useEffect(() => {
        ReactGA.pageview('Rebríček')
    }, [])
    

    let tableTabs: ITabs[] = [
        {
            header: {
                icon: (<GiEarthAfricaEurope/>),
                customIcon: true,
                title: i18n._('Svet'),
                titleTab: i18n._('Najlepší na svete')
            },
            content: (
                <WorldRankings refresh={false} hardNone={true} showHeader={false}/>
            ),
            onFirstOpen: () => dispatch(loadWorld())
        },
        {
            header: {
                icon: (<FaRegFlag/>),
                customIcon: true,
                title: i18n._('Krajina'),
                titleTab: i18n._('Najlepší v mojej krajine')
            },
            content: (
                <CountryRankings refresh={false} hardNone={true} showHeader={false}/>
            ),
            onFirstOpen: () => (userInfo !== null)?dispatch(loadCountry(userInfo.country)):null
        }
    ]

    return (
        <div className="rows">
            <div className="row">
                <div className="row-part">
                    <div className="page-header">
                        <div className="part">
                            <h2><Trans>Rebríček najlepších</Trans></h2>
                            <p><Trans>Zoznam najlepších na svete a v mojej krajine</Trans></p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="row-part">
                    <TabsContainer
                        tabs={tableTabs}
                        footerActions={[]}
                    />
                </div>
            </div>
        </div>
    )
}

export default withRouter(RankingPage)