import React, {useState, useEffect, useCallback} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trans } from '@lingui/macro';
import posed, { PoseGroup } from 'react-pose';
import { ITest, ETestType, ETestUgType, ITestResult } from '../../../../constants/types';
import { IApplicationStore } from '../../../../constants/store-interfaces';
import TestClass from '../../../simple/classes/testClass';
import FillingQuestion from './questions/FillingQuestion';
import FillingAnswer from './answers/FillingAnswer';
import Timer from './Timer';
import SelectingQuestion from './questions/SelectingQuestion';
import SelectingAnswer from './answers/SelectingAnswer';
import FoldingQuestion from './questions/FoldingQuestion';
import FoldingAnswer from './answers/FoldingAnswer';
import { i18n } from '../../../../App';
import ConfirmModal from '../../../simple/ConfirmModal';
import { generateTest } from '../../../../actions/tests/generateTest';
import LoadingElement from '../../../simple/LoadingElement';
import { updateCurrentTest } from '../../../../actions/global/updateCurrentTest';

interface IProps {
    test: ITest,
    quitTest: () => any,
    finishTest: () => any,
    currentTime: any
}

const Modal = posed.div({
    enter: {
        y: 0,
        opacity: 1
    },
    exit: {
        y: 50,
        opacity: 0
    }
});

const QuestionContainer = posed.div({
    enter: { opacity: 1, delay: 300, beforeChildren: 150, staggerChildren: 50},
    exit: { opacity: 0}
});

const Questions: React.FC<IProps> = props => {    
    const dispatch = useDispatch()

    const [showAnswer, setShowAnswer] = useState<boolean>(false)
    const [showModalExit, setShowModalExit] = useState<boolean>(false)
    const [preparedTestResult, setPreparedTestResult] = useState<ITestResult|undefined>(undefined)
    
    const currentTest = useSelector((state: IApplicationStore) => state.globalStore.currentTest)
    const loadingTestGenerate = useSelector((state: IApplicationStore) => state.loadingStore.loadingGenerateTest)

    let runningTestResult = currentTest?.testResult
    let runningTestTest = currentTest?.test

    useEffect(() => {
        if(runningTestResult) {
            setPreparedTestResult(runningTestResult)
        } else {
            if(typeof runningTestTest !== 'undefined') {
                generateTest(runningTestTest)
                    .then(result => {
                        setPreparedTestResult(result)
                    })
            }
        }
    }, [runningTestResult, runningTestTest])

    useEffect(() => {
        if(typeof runningTestTest !== 'undefined' && typeof preparedTestResult !== 'undefined') {
            let test = new TestClass(runningTestTest, preparedTestResult)

            let result = test.getResult()

            if((result !== null) && (result.result !== null) && typeof result.result.questions[result.result.current] !== 'undefined') {
                if(typeof result.result.questions[result.result.current].valid !== 'undefined') {
                    let currentQuestion = result.result.current + 1

                    result = {
                        ...result,
                        result: {
                            ...result.result,
                            current: currentQuestion
                        }
                    }

                    test.setResult(result)

                    dispatch(updateCurrentTest(test.getCurrentTest()))
                }
            }

        }
    }, [runningTestTest, preparedTestResult, dispatch])

    const finishTest = useCallback((e?: React.FormEvent<HTMLButtonElement>) => {
        if(typeof e !== 'undefined') {
            e.preventDefault()
            e.stopPropagation()
        }

        setShowAnswer(false)

        props.finishTest()
    }, [props])

    const nextQuestion = useCallback((e?: React.FormEvent<HTMLButtonElement>) => {
        if(typeof e !== 'undefined') {
            e.preventDefault()
            e.stopPropagation()
        }

        setShowAnswer(false)

        if(currentTest !== null && typeof preparedTestResult !== 'undefined') {
            let test = new TestClass(currentTest.test, preparedTestResult)

            let result = test.getResult()

            if((result !== null) && (result.result !== null)) {
                
                let currentQuestion = result.result.current + 1

                result = {
                    ...result,
                    result: {
                        ...result.result,
                        current: currentQuestion
                    }
                }

                test.setResult(result)

                dispatch(updateCurrentTest(test.getCurrentTest()))
            }
        }

    }, [currentTest, preparedTestResult, dispatch])

    const handleToggleModal = useCallback(() => {
        setShowModalExit(!showModalExit)
    }, [showModalExit])

    const enterKeyPress = useCallback((event: any) => {
        if(showAnswer) {
            if(event.key === 'Enter') {
                event.stopPropagation()
                event.preventDefault()
                
                if(currentTest !== null && typeof preparedTestResult !== 'undefined') {
                    let runningTestObject = new TestClass(currentTest.test, preparedTestResult)

                    let result = runningTestObject.getResult()

                    if((result.result !== null)) {

                        if(result.result.current+1 === result.result.questions.length) {
                            finishTest()
                        } else {
                            nextQuestion()
                        }
                    }
                }
            }
        }

        if(event.keyCode === 27) {
            handleToggleModal()
        }
    }, [handleToggleModal, finishTest, nextQuestion, preparedTestResult, currentTest, showAnswer])

    useEffect(
        () => {
          document.addEventListener('keydown', enterKeyPress, false)
          
          return () => {
            document.removeEventListener('keydown', enterKeyPress, false)
          };
        },
        [enterKeyPress]
    )

    const answerQuestion = (value: string | {left:string, right:string}[]) => {
        if(typeof value === 'string') {
            if(currentTest !== null && typeof preparedTestResult !== 'undefined') {
                let test = new TestClass(currentTest.test, preparedTestResult)
            
                let result = test.getResult()

                if((result !== null) && (result.result !== null)) {
                    result.result.questions[result.result.current].response = value
                }

                test.setResult(result)
    
                dispatch(updateCurrentTest(test.getCurrentTest()))
            }
        }
    }

    const saveAnswer = (e: React.FormEvent<HTMLButtonElement>) => {
        e.preventDefault()
        e.stopPropagation()
        
        if(currentTest !== null && typeof preparedTestResult !== 'undefined') {
            let test = new TestClass(currentTest.test, preparedTestResult)
        
            let result = test.getResult()

            if((result !== null) && (result.result !== null)) {

                setShowAnswer(true)

                test.setResult(result)
                test.saveAnswer(result.result.current)

                dispatch(updateCurrentTest(test.getCurrentTest()))

            }
        }
    }

    let question: JSX.Element | null = null
    
    let answerCorrect = false

    let correctAnswer = (null)
    
    let percentageWidth = 0

    if(currentTest !== null && typeof preparedTestResult !== 'undefined') {
        let runningTestObject = new TestClass(currentTest.test, preparedTestResult)

        let result = runningTestObject.getResult()

        let { test } = props

        if((result.result !== null)) {

            if(result.result.questions.length >= result.result.current) {
                let questionObject = result.result.questions[result.result.current]

                if(typeof questionObject === 'undefined') {
                    return null
                }
                if(questionObject.type === ETestType.filling) {
                    question = (
                        <FillingQuestion question={questionObject} response={answerQuestion} questionId={result.result.current} disabled={showAnswer}/>
                    )

                    correctAnswer = (
                        <FillingAnswer question={questionObject} languageFrom={currentTest.test.language_from} languageTo={currentTest.test.language_to} />
                    )
                }

                if(questionObject.type === ETestType.select) {
                    question = (
                        <SelectingQuestion 
                            question={questionObject} 
                            response={answerQuestion} 
                            questionId={result.result.current} 
                            disabled={showAnswer}
                            answer={questionObject.response}
                        />
                    )

                    correctAnswer = (
                        <SelectingAnswer 
                            question={questionObject} 
                            languageFrom={currentTest.test.language_from} 
                            languageTo={currentTest.test.language_to} 
                        />
                    )
                }

                if(questionObject.type === ETestType.folding) {
                    question = (
                        <FoldingQuestion 
                            question={questionObject} 
                            response={answerQuestion} 
                            questionId={result.result.current} 
                            disabled={showAnswer}
                            answer={questionObject.response}
                        />
                    )

                    correctAnswer = (
                        <FoldingAnswer 
                            question={questionObject} 
                            languageFrom={currentTest.test.language_from} 
                            languageTo={currentTest.test.language_to} 
                        />
                    )
                }
            }
    
            if(showAnswer) {
                answerCorrect = runningTestObject.validAnswer(result.result.current)
            }

            percentageWidth = ((result.result.current+1)/(result.result.questions.length+1))*100
        }

        let current = i18n._('Spustené')
        let endTest = i18n._('Ukončiť')
        let finishTestText = i18n._('Ukončiť test')

        if(test.usergroup_id !== null) {
            if(test.settings.ugType === ETestUgType.homework) {
                finishTestText = i18n._('Ukončiť domácu úlohu')
            } else {
                finishTestText = i18n._('Ukončiť písomku')
            }
        }

        let title = i18n._('Ukončiť cvičenie?')
        let subtitle = i18n._('Po ukončení cvičenia stratíte získané body!')

        if(test.usergroup_id !== null) {
            if (test.settings.ugType === ETestUgType.homework) {
                title = i18n._('Ukončiť domácu úlohu?')
                subtitle = i18n._('Po ukončení domácej úlohy stratíte získané body!')
            }else {
                title = i18n._('Ukončiť písomku?')
                subtitle = i18n._('Písomku môžete vykonať iba raz, a teda jej ukončením odošlete svoje doterajšie výsledky svojmu učiteľovi!')
            }
        }

        return (
            <div className="rows">
                <LoadingElement loadingValue={loadingTestGenerate}/>
                <div className="row">
                    <div className="row-part">
                        <form id="test" autoComplete="off">
                            <div className="test-progress">
                                <div className="progress-bar _orange">
                                    <div className="runner" style={{width: percentageWidth + "%"}}></div>
                                </div>
                            </div>
                            <div className="test-header">
                                <div className="part">
                                    <div className="test-name">
                                        {current}: <span>{ test.name }</span>
                                    </div>
                                </div>
                                <div className="part text-right">
                                    <div className="bttns-group">
                                        
                                        <Timer finishTest={finishTest} currentTime={props.currentTime} />
                                        <div className="bttn _red _small" onClick={handleToggleModal}>{endTest}</div>

                                    </div>
                                </div>
                            </div>
                            
                            {((result !== null) && (result.result !== null))?(
                                <div className="test-content">
                                
                                    <div className="wrapper">
                                        
                                        <div className="test-block">
                                            {showAnswer?(
                                                <div className="shadow-hidden"></div>
                                            ):(null)}
                                            <div className="test-question"><Trans>Otázka</Trans> {result.result.current+1} / {result.result.questions.length}</div>
                                            <div>
                                                <PoseGroup>
                                                    <QuestionContainer key={result.result.current}>
                                                        {question}
                                                    </QuestionContainer>
                                                </PoseGroup>
                                            </div>
                                            <div className={showAnswer?"answer-holder __active":"answer-holder "}>
                                                <div className="test-nav test-result answer-modal">
                                                    <div className="wrapper">
                                                        <PoseGroup>
                                                            {showAnswer && 
                                                                <Modal key="modal" className="table-cell">
                                                                    {(answerCorrect)?(
                                                                        <div className="question-answer _corrent">
                                                                        
                                                                            <div className="question-answer_content">
                                                                                <Trans>Vaša odpoveď je správna!</Trans>
                                                                            </div>
                                                            
                                                                        </div>
                                                                    ):(
                                                                        <div className="question-answer _wrong">
                                                                        
                                                                            <div className="question-answer_content">
                                                                                <Trans>Vaša odpoveď je nesprávna!</Trans>
                                                                                <div className="question-answer_answer">
                                                                                    { correctAnswer }
                                                                                </div>
                                                                            </div>
                                                            
                                                                        </div>
                                                                    )}
                                                                </Modal>
                                                            }    
                                                        </PoseGroup>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="test-nav _next-question" key="modal-answer">
                                                <ul>
                                                    {(showAnswer)?(
                                                        <>
                                                            {(result.result.current+1 === result.result.questions.length)?(
                                                                <li><button onClick={finishTest} className="bttn _primary _next">{finishTestText} <i className="la la-check"/></button></li>
                                                            ):(
                                                                <li><button onClick={nextQuestion} className="bttn _primary _next"><Trans>Ďalšia otázka</Trans> <i className="la la-arrow-right"/></button></li>
                                                            )}
                                                        </>
                                                    ):(
                                                        <li><button onClick={saveAnswer} className="bttn _primary _next"><Trans>Odpovedať</Trans> <i className="la la-arrow-right"/></button></li>
                                                    )}
                                                </ul>
                                            </div>
                                            
                                        </div>
                                        
                                    </div>
                                
                                </div>
                            ):(null)}
                            
                            <div className="test-footer">
                                <div className="part">
                                
                                    <div className="test-controls">
                                        <ul>
                                            <li>
                                            
                                                <div className="control">
                                                    <div className="key">Esc</div>
                                                    <Trans>Ukončiť</Trans>
                                                </div>
                                                
                                            </li>
                                            <li>
                                            
                                                <div className="control">
                                                    <div className="key">Enter</div>
                                                    <Trans>Ďalej</Trans>
                                                </div>
                                                
                                            </li>
                                        </ul>
                                    </div>
                                
                                </div>
                            </div>
                        
                            <ConfirmModal
                                confirmFunction={props.quitTest}
                                closeOnConfirm={true}
                                yesOption={i18n._('Ukončiť')}
                                noOption={i18n._('Zrušiť')}
                                title={title}
                                custom={true}
                                customFunctionToggle={handleToggleModal}
                                customShow={showModalExit}
                            >
                                {subtitle}
                            </ConfirmModal>
                        </form>
                        
                    </div>
                </div>
            </div>
        )
    } else {
        return (null)
    }
}

export default Questions