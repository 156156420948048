import { 
    SET_LOGIN_USER_DATA, 
    GET_USERGROUPS_CREATED, 
    GET_USERGROUPS_ASSIGNED, 
    GET_USERGROUPS_ASSIGNED_FAILED,
    STORE_USERGROUP, 
    DELETE_USERGROUP, 
    DELETE_USER_FROM_USERGROUP,
    MODIFY_USERGROUP,
    GET_USERGROUP,
    GET_USERGROUP_ASSIGNED,
    GENERATE_NEW_CODE,
    RESET_STATE,
    SET_LOGOUT_USER_DATA,
    SET_LOGOUT_USER_DATA_WRONG,
    SET_LOGIN_USER_DATA_WRONG
} from '../constants/action-types';
import { Reducer } from 'redux'
import { IUserGroupStore } from '../constants/store-interfaces';

const initialState: IUserGroupStore = {
    createdUserGroups: [],
    assignedUserGroups: []
}

const reducer: Reducer<IUserGroupStore> = (state = initialState, action) => {
    let was = false

    switch(action.type) {
        case SET_LOGIN_USER_DATA:
            state = initialState
            was = true
            break
        case GET_USERGROUPS_CREATED: 
            state = {
                ...state,
                createdUserGroups:  action.payload,
            }
            was = true
            break
        case GET_USERGROUPS_ASSIGNED: 
            state = {
                ...state,
                assignedUserGroups:  action.payload,
            }
            was = true
            break
        case GET_USERGROUPS_ASSIGNED_FAILED:
            state = {
                ...state,
                assignedUserGroups: []
            }
            was = true
            break
        case STORE_USERGROUP:
            let existsWG = state.createdUserGroups.find((item: any) => item.id === action.payload.id)

            if(typeof existsWG  === 'undefined')  {
                state = {
                    ...state,
                    createdUserGroups: state.createdUserGroups.concat([action.payload])
                }
            } else {
                state = {
                    ...state,
                    createdUserGroups: state.createdUserGroups.map((item:any) => (item.id === action.payload.id)?action.payload:item)
                }
            }
            was = true
            break
        case MODIFY_USERGROUP:
            let existsEditWG = state.createdUserGroups.find((item: any) => item.id === action.payload.id)

            if(typeof existsEditWG  === 'undefined')  {
                state = {
                    ...state,
                    createdUserGroups: state.createdUserGroups.concat([action.payload])
                }
            } else {
                state = {
                    ...state,
                    createdUserGroups: state.createdUserGroups.map((item:any) => (item.id === action.payload.id)?action.payload:item)
                }
            }
            was = true
            break
        case GENERATE_NEW_CODE:
            let existsGeneratedCodeWG = state.createdUserGroups.find((item: any) => item.id === action.payload.ugId)

            if(typeof existsGeneratedCodeWG  !== 'undefined')  {
                existsGeneratedCodeWG.access_code = action.payload.code

                state = {
                    ...state,
                    createdUserGroups: state.createdUserGroups.map((item:any) => (item.id === action.payload.ugId)?existsGeneratedCodeWG:item)
                }
            }
            was = true
            break
        case DELETE_USERGROUP:
            state = {
                ...state,
                createdUserGroups: state.createdUserGroups.filter((item: any) => item.id !== action.payload )
            }
            was = true
            break
        case GET_USERGROUP:
            let newUserGroups = state.createdUserGroups

            let addGetted = false

            for(let UG in newUserGroups) {
                if(newUserGroups[UG].id  === action.payload.id){
                    newUserGroups[UG] = action.payload
                    addGetted = true
                }
            }

            if(!addGetted) {
                newUserGroups.push(action.payload)
            }

            state = {
                ...state,
                createdUserGroups: newUserGroups
            }
            was = true
            break
        case GET_USERGROUP_ASSIGNED:
            let newAssignedUserGroups = state.assignedUserGroups

            let addAssigned = false

            for(let UG in newAssignedUserGroups) {
                if(newAssignedUserGroups[UG].id  === action.payload.id){
                    newAssignedUserGroups[UG] = action.payload
                    addAssigned = true
                }
            }

            if(!addAssigned) {
                newAssignedUserGroups.push(action.payload)
            }

            state = {
                ...state,
                assignedUserGroups: newAssignedUserGroups
            }
            was = true
            break
        case DELETE_USER_FROM_USERGROUP:
            let oldUg = state.createdUserGroups.find(i => i.id === action.payload.ug)

            if(typeof oldUg !== 'undefined') {

                oldUg.assigned_users = oldUg.assigned_users.filter(i => i.id !== action.payload.uId)
                
                state = {
                    ...state,
                    createdUserGroups: state.createdUserGroups.map(v => (v.id === action.payload.ug)?((typeof oldUg !== 'undefined')?oldUg:v):v)
                }
            }

            was = true
            break
        case SET_LOGOUT_USER_DATA:
        case SET_LOGOUT_USER_DATA_WRONG:
        case SET_LOGIN_USER_DATA_WRONG:
        case RESET_STATE:
            state = initialState
            was = true
            break
    }

    if(action.type.endsWith("_FAILED") && was) {
        state = {
            ...state,
            createdUserGroups: [],
            assignedUserGroups: []
        }
        //TODO show error
    }

    return state
}

export { reducer as userGroupReducer }