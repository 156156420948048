import { SET_SHOW_CONFIRM_MODAL, SET_SHOW_CREATE_TEST_MODAL, SET_SHOW_CREATE_USERGROUP_MODAL, SET_SHOW_CREATE_WORD_MODAL, SET_SHOW_CREATE_WORD_MODAL_SIMPLE, SET_SHOW_CREATE_WORDGROUP_MODAL, SET_SHOW_EDIT_WORD_MODAL, SET_SHOW_EDIT_WORDGROUP_MODAL, SET_SHOW_EDIT_USERGROUP, SET_SHOW_INFO_MODAL, SET_SHOW_CREATE_TEST_WG_MODAL, SET_SHOW_CREATE_TEST_UG_MODAL, SET_SHOW_EDIT_TEST_USERGROUP, SET_SHOW_DELETE_TEST_USERGROUP, SET_SHOW_EDIT_TRAINING, SET_SHOW_TUTORIAL } from '../../constants/action-types';

export function setShowConfirmModal(payload: boolean) {
    return { type: SET_SHOW_CONFIRM_MODAL, payload }
}

export function setShowCreateTestModal(payload: boolean) {
    return { type: SET_SHOW_CREATE_TEST_MODAL, payload }
}

export function setShowCreateUserGroupModal(payload: boolean) {
    return { type: SET_SHOW_CREATE_USERGROUP_MODAL, payload }
}

export function setShowCreateWordModal(payload: boolean) {
    return { type: SET_SHOW_CREATE_WORD_MODAL, payload }
}

export function setShowCreateWordSimpleModal(payload: boolean) {
    return { type: SET_SHOW_CREATE_WORD_MODAL_SIMPLE, payload }
}

export function setShowCreateWordGroupModal(payload: boolean) {
    return { type: SET_SHOW_CREATE_WORDGROUP_MODAL, payload}
}

export function setShowEditWordModal(payload: boolean) {
    return { type: SET_SHOW_EDIT_WORD_MODAL, payload }
}

export function setShowEditWordGroupModal(payload: boolean) {
    return { type: SET_SHOW_EDIT_WORDGROUP_MODAL, payload }
}

export function setShowEditUserGroupModal(payload: boolean) {
    return { type: SET_SHOW_EDIT_USERGROUP, payload }
}

export function setShowInfoModal(payload: boolean) {
    return { type: SET_SHOW_INFO_MODAL, payload }
}

export function setShowCreateTestWGModal(payload: boolean) {
    return { type: SET_SHOW_CREATE_TEST_WG_MODAL, payload}
}

export function setShowCreateTestUGModal(payload: boolean) {
    return { type: SET_SHOW_CREATE_TEST_UG_MODAL, payload}
}

export function setShowEditTestUserGroupModal(payload: boolean) {
    return { type: SET_SHOW_EDIT_TEST_USERGROUP, payload}
}

export function setShowDeleteTestUserGroupModal(payload: boolean) {
    return { type: SET_SHOW_DELETE_TEST_USERGROUP, payload}
}

export function setShowEditTrainingModal(payload: boolean) {
    return { type: SET_SHOW_EDIT_TRAINING, payload}
}

export function setShowTutorial(payload: boolean) {
    return  { type: SET_SHOW_TUTORIAL, payload }
}