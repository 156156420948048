import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trans } from '@lingui/macro';
import { IApplicationStore } from '../../../constants/store-interfaces';
import { IUSerInfo, ITestResult, ITest, IUserGroupType } from '../../../constants/types';
import { useEffect } from 'react';
import { loadTestResults } from '../../../actions/tests/loadTestResults';
import { loadTests } from '../../../actions/tests/loadTests';
import { loadAssignedUserGroups } from '../../../actions/userGroups/loadAssignedUserGroups';
import { loadAssignedTests } from '../../../actions/tests/loadAssignedTests';
import { withRouter } from 'react-router-dom';
import config from '../../../config';
import { loadCreatedUserGroups } from '../../../actions/userGroups/loadCreatedUserGroups';
import { loadTestResultsUserGroup } from '../../../actions/tests/loadTestResultsUserGroup';
import { loadUserGroupTests } from '../../../actions/tests/loadUserGroupTests';
import ActivityContainer from '../containers/ActivityContainer';
import ReactGA from 'react-ga';

interface IProps {

}

const ActivityPage: React.FC<IProps> = props => {
    const dispatch = useDispatch()
    
    const userInfo = useSelector<IApplicationStore, IUSerInfo | null>(state => state.globalStore.userInfo)
    const loadingTestResults = useSelector<IApplicationStore, boolean>(state => state.loadingStore.loadingTestResults)
    const userTestResults = useSelector<IApplicationStore, ITestResult[]>(state => state.testsStore.testResults)
    const loadingTrainings = useSelector<IApplicationStore, boolean>(state => state.loadingStore.loadingCreatedTests)
    const userTrainings = useSelector<IApplicationStore, ITest[]>(state => state.testsStore.createdTests)
    const loadingAssignedTests = useSelector<IApplicationStore, boolean>(state => state.loadingStore.loadingAssignedTests)
    const loadingAssignedUsergroups = useSelector<IApplicationStore, boolean>(state => state.loadingStore.loadingAssignedUserGroups)
    const loadingCreatedUsergroups = useSelector<IApplicationStore, boolean>(state => state.loadingStore.loadingCreatedUserGroups)
    const assignedUserGroups = useSelector<IApplicationStore, IUserGroupType[]>(state => state.userGroupsStore.assignedUserGroups)
    const createdUserGroups = useSelector<IApplicationStore, IUserGroupType[]>(state => state.userGroupsStore.createdUserGroups)
    const userGroupsTests = useSelector<IApplicationStore, {[key: number]: ITest[]}>(state => state.testsStore.usergroupTests)

    useEffect(() => {
        ReactGA.pageview('Moja aktivita')
    }, [])

    useEffect(() => {
        dispatch(loadTestResults())
        dispatch(loadTests())
    }, [dispatch])

    useEffect(() => {
        if((userInfo !== null) && (userInfo.role.name === config.roles.teacher)) {
            dispatch(loadCreatedUserGroups())
        } else {
            dispatch(loadAssignedUserGroups())
            dispatch(loadAssignedTests())
        }
    }, [dispatch, userInfo])

    useEffect(() => {
        createdUserGroups.forEach(uG => {
            dispatch(loadUserGroupTests(uG.id))
            dispatch(loadTestResultsUserGroup(uG.id))
        })
        // eslint-disable-next-line
    }, [dispatch, createdUserGroups.length])

    useEffect(() => {
        assignedUserGroups.forEach(uG => {
            dispatch(loadUserGroupTests(uG.id))
            dispatch(loadTestResultsUserGroup(uG.id))
        })
        // eslint-disable-next-line
    }, [dispatch, assignedUserGroups.length])

    return (
        <div className="rows">
            <div className="row">
                <div className="row-part">
                    <div className="page-header">
                        <div className="part">
                            <h2><Trans>Moja aktivita</Trans></h2>
                            <p><Trans>Všetky moje aktivity ktoré som vykonal.</Trans></p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="row-part">
                    <ActivityContainer
                        loading={loadingCreatedUsergroups || loadingAssignedUsergroups || loadingTestResults || loadingTrainings || loadingAssignedTests}
                        createdUserGroups={createdUserGroups}
                        createdTrainings={userTrainings}
                        assignedUserGroups={assignedUserGroups}
                        userGroupTests={userGroupsTests}
                        trainingResults={userTestResults}
                        activityLimit={10}
                        pagination={true}
                    />
                </div>
            </div>
        </div>
    )
}

export default withRouter(ActivityPage)