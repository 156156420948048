import React, {Component} from 'react';
import { connect } from 'react-redux';
import { IApplicationStore } from "../../../constants/store-interfaces";
import { ITest, ITableRowType, ITableCellType, ITableHeaderCellType, IActionType, ETestType, ITestResult } from '../../../constants/types';
import { loadTests } from '../../../actions/tests/loadTests';
import TranslateLanguages from '../../simple/TranslateLanguages';
import { i18n } from '../../../App';
import NumberText from '../../simple/tableElements/NumberText';
import DateText from '../../simple/tableElements/DateText';
import { NavLink } from 'react-router-dom';
import { Trans } from '@lingui/macro';
import TableContainer from '../containers/TableContainer';
import { setShowCreateTestModal, setShowConfirmModal, setShowEditTrainingModal } from '../../../actions/modals/setShowModal';
import TitleText from '../../simple/tableElements/TitleText';
import ModalDeleteTest from '../parts/modals/tests/ModalDeleteTest';
import { deleteTest } from '../../../actions/tests/deleteTest';
import getTestTypeString from '../../simple/classes/getTestTypeString';
import TestDifficulty from '../../simple/tableElements/TestDifficulty';
import { loadTestResults } from '../../../actions/tests/loadTestResults';
import Percentage from '../../simple/tableElements/Percentage';
import TestClass from '../../simple/classes/testClass';
import { withRouter, RouteComponentProps } from 'react-router';
import ModalEditTraining from '../parts/modals/tests/ModalEditTraining';
import ReactGA from 'react-ga';

interface IStateProps {
    createdTests: ITest[],
    testResults: ITestResult[],
    loadingCreated: boolean,
    loadingResults: boolean
}

interface IDispatchProps {
    loadTests: typeof loadTests,
    loadTestResults: typeof loadTestResults,
    deleteTest: typeof deleteTest,
    setShowCreateTestModal: typeof setShowCreateTestModal,
    setShowDeleteTestModal: typeof setShowConfirmModal,
    setShowEditTrainingModal: typeof setShowEditTrainingModal
}

const mapStateToProps = (state: IApplicationStore): IStateProps => ({
    createdTests: state.testsStore.createdTests,
    testResults: state.testsStore.testResults,
    loadingCreated: state.loadingStore.loadingCreatedTests,
    loadingResults: state.loadingStore.loadingTestResult
})

const mapDispatchToProps = (dispatch: any): IDispatchProps => ({
    loadTests: () => dispatch(loadTests()),
    loadTestResults: () => dispatch(loadTestResults()),
    deleteTest: (test: ITest, type: 'ug'|'self') => dispatch(deleteTest(test, type)),
    setShowCreateTestModal: (value: boolean) => dispatch(setShowCreateTestModal(value)),
    setShowDeleteTestModal: (value: boolean) => dispatch(setShowConfirmModal(value)),
    setShowEditTrainingModal: (value: boolean) => dispatch(setShowEditTrainingModal(value))
})

interface IProps extends IStateProps, IDispatchProps, RouteComponentProps {

}

interface IState {
    selectedTraining: ITest | null
}

class TrainingsPage extends Component<IProps, IState> {
    constructor(props: any) {
        super(props)

        this.state = {
            selectedTraining: null
        }

        this.handleCreateTest = this.handleCreateTest.bind(this)
        this.confirmDeleteTest = this.confirmDeleteTest.bind(this)
        this.handleDeleteTest = this.handleDeleteTest.bind(this)
        this.handleEditTest = this.handleEditTest.bind(this)

        ReactGA.pageview('Cvičenia')
    }

    componentDidMount() {
        this.props.loadTests()
        this.props.loadTestResults()
    }

    handleCreateTest() {
        this.props.setShowCreateTestModal(true)
    }

    getRows(tests:  ITest[], results: ITestResult[]): ITableRowType[] {
        let rows: ITableRowType[] = []

        for(let test  of tests)  {
            let cells: ITableCellType[] = []

            const typeSelect: { label: string; value: string }[] = []

            Object.keys(ETestType).forEach(value => {
                typeSelect.push({
                    label: getTestTypeString(ETestType[(value as ETestType)]),
                    value: ETestType[(value as ETestType)]
                })
            })

            const typeValue = typeSelect.find(value => value.value === test.settings.type)

            let percentage:number|null = null
            let percentageContent = (null)

            //Find percentage
            let testResults = results.filter(tR => tR.test_id === test.id)

            if(testResults.length) {
                let cntPoints = 0
                let maxPoints = 0

                testResults.forEach(tR => {
                    let testObject = new TestClass(test, tR)
                    
                    cntPoints += testObject.getTestRate()
                    maxPoints += testObject.getMaximumRate()
                })

                percentage = Math.round(Number(cntPoints)/maxPoints*100)
            }

            if(percentage !== null) {
                percentageContent = (<Percentage percentage={percentage} title={i18n._('Úspešnosť')}/>)
            }

            let diacriticText = i18n._('bez diakritiky')

            if(
                (typeof test.settings.diacritic !== 'undefined') &&
                (test.settings.diacritic)
            ) {
                diacriticText = i18n._('s diakritikou')
            }

            cells = [
                {
                    content: (<div className="part-title">{test.name}</div>),
                    stringToOrder:  test.name,
                    filterString:  test.name,
                    filterByString:  true
                },
                {
                    content: (
                        <TranslateLanguages
                            languageFrom={test.language_from}
                            languageTo={test.language_to}
                        />
                    ),
                    stringToOrder:  test.language_from.flag_name + test.language_to.flag_name,
                    filterString: test.language_from.flag_name + test.language_to.flag_name,
                    filterByString: false,
                    selectableFilter: true,
                    selectableName: i18n._('vybrať'),
                    selectableAllText: i18n._('Všetky'),
                    selectableTitle: i18n._('Preklad'),
                    selectableObject: [
                        {
                            object: (
                                    <TranslateLanguages
                                        languageFrom={test.language_from}
                                        languageTo={test.language_to}
                                    />
                            ),
                            value: test.language_from.flag_name + test.language_to.flag_name
                        }
                    ]
                },
                {
                    content: (<NumberText number={test.settings.words.length} text='' title={i18n._('Počet slovíčok')}/>),
                    stringToOrder:  Number(test.settings.words.length).toString(),
                    filterString:  Number(test.settings.words.length).toString(),
                    filterByString:  true
                },
                {
                    content: (<TitleText text={((typeof typeValue !== 'undefined')?typeValue.label:'')} subtitle={diacriticText + ((test.settings.time !== null)?', ' + test.settings.time + ' ' + i18n._('min'):'')} title={i18n._('Typ cvičenia')}/>),
                    stringToOrder:  Number(test.settings.words.length).toString(),
                    filterString:  Number(test.settings.words.length).toString(),
                    filterByString:  true
                },
                {
                    content: (<TestDifficulty difficulty={test.settings.difficulty} />),
                    stringToOrder: test.settings.difficulty,
                    filterString: test.settings.difficulty,
                    filterByString: true
                },
                {
                    content: percentageContent,
                    stringToOrder: test.created_at,
                    filterString: test.created_at,
                    filterByString: false
                },
                {
                    content: (<DateText date={test.valid_from} formatDate={'DD.MM.YYYY'} formatTime={'HH:mm'} title={i18n._('Vytvorené')}/>),
                    stringToOrder: test.created_at,
                    filterString: test.created_at,
                    filterByString: false,
                    hideClass: 'hide-m'
                },
                {
                    content: (
                        <div className="part-list">
                            <NavLink to={"/app/trainings/" + test.id} target="_self" className="bttn _small _green _icon">
                                <Trans>Spustiť</Trans>
                            </NavLink>
                            <NavLink to={"/app/trainings/" + test.id + "/detail"} target="_self" className="bttn _small _primary _icon" data-tooltip={i18n._("Detail cvičenia")}>
                                <i className="la la-search-plus"/>
                            </NavLink>
                        </div>
                    ),
                    stringToOrder: test.updated_at,
                    filterString: test.updated_at,
                    filterByString: false
                }
            ]

            rows.push({
                cells: cells,
                catchString: '_' +  test.id
            })
        }

        return rows
    }

    getHeaders(): ITableHeaderCellType[] {
        return [
            {
                content: (<span><Trans>Názov</Trans></span>),
                sortBy: false,
                sortable: true
            },
            {
                content: (<span><Trans>Preklad</Trans></span>),
                sortBy: false,
                sortable: true
            },
            {
                content: (<span><Trans>Slovíčka</Trans></span>),
                sortBy: false,
                sortable: true,
                sortableType: 'number'
            },
            {
                content: (<span><Trans>Typ cvičenia</Trans></span>),
                sortBy: false,
                sortable: false
            },
            {
                content: (<span><Trans>Náročnosť</Trans></span>),
                sortBy: false,
                sortable: false,
            },
            {
                content: (<span><Trans>Úspešnosť</Trans></span>),
                sortBy: false,
                sortable: false
            },
            {
                content: (<span><Trans>Dátum vytvorenia</Trans></span>),
                sortBy: true,
                sortableType: 'date',
                sortable: true,
                hideClass: 'hide-m'
            },
            {
                content: (<span></span>),
                sortBy: false,
                sortable: false
            },
        ]
    }

    getActionsCreated(): IActionType[] {

        return [
            {
                action: this.handleEditTest,
                name: i18n._('Upraviť')
            },
            {
                action: this.handleDeleteTest,
                name: i18n._('Odstrániť'),
                customClass: 'remove'
            }
        ]
    }
    
    handleDeleteTest(catchString: string) {
        const { createdTests } = this.props

        let testId = Number(catchString.split('_')[1])

        let testObject = createdTests.find((item:ITest) => item.id === testId)

        if(typeof testObject !== 'undefined') {
            this.setState({
                ...this.state,
                selectedTraining: testObject
            })
        }
            
        this.props.setShowDeleteTestModal(true)
    }

    handleEditTest(catchString: string) {
        const { createdTests } = this.props

        let testId = Number(catchString.split('_')[1])

        let testObject = createdTests.find((item:ITest) => item.id === testId)

        if(typeof testObject !== 'undefined') {
            this.setState({
                ...this.state,
                selectedTraining: testObject
            })
        }
            
        this.props.setShowEditTrainingModal(true)
    }

    confirmDeleteTest() {
        const { selectedTraining } = this.state

        if(selectedTraining !== null) {
            this.props.deleteTest(selectedTraining, 'self')
        }
    
    }

    render() {
        const { createdTests, loadingCreated, testResults } = this.props
        const { selectedTraining } = this.state

        let createdHeaders: ITableHeaderCellType[] = this.getHeaders()
        let createdRows: ITableRowType[] = this.getRows(createdTests, testResults)
        let createdActions: IActionType[] = this.getActionsCreated()

        let createdTestsTable = (<TableContainer
            headers={createdHeaders}
            rows={createdRows}
            actions={createdActions}
            showActions={true}
            showCheckbox={false}
            showFilter={true}
            pageSize={12}
            title={i18n._('Cvičenia')}
            showNew={true}
            grid={false}
            newTitle={i18n._('Pridať cvičenie')}
            newFunction={this.handleCreateTest}
            emptyText={i18n._('Zatiaľ nemáte vytvorené žiadne cvičenia')}
            emptySubtitle={i18n._('Ak si chcete pridať nové cvičenia, stačí stlačiť tlačidlo "pridať cvičenie".')}
            loading={loadingCreated}
            defaultSort='asc'
            mobileFixedGrid
        />)

        return (
            <div className="rows">
                <div className="row">
                    <div className="row-part">
                        <div className="page-header">
                            <div className="part">
                                <h2><Trans>Moje cvičenia</Trans></h2>
                                <p><Trans>Zoznam cvičení, ktoré som si vytvoril.</Trans></p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="row-part">
                        {createdTestsTable}
                    </div>
                </div>
                {(selectedTraining !== null)?(
                    <>
                        <ModalDeleteTest
                            test={selectedTraining}
                            confirmFunction={this.confirmDeleteTest}
                        />
                    </>
                ):(null)}

                {(selectedTraining !== null)?(
                    <ModalEditTraining
                        test={selectedTraining}
                    />
                ):(null)}
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TrainingsPage))