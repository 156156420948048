import React, {Component} from 'react'
import { ITest, ITestCreate } from '../../../../../constants/types';
import { IApplicationStore } from '../../../../../constants/store-interfaces';
import { connect } from 'react-redux';
import { Trans } from '@lingui/macro';
import { setShowEditTrainingModal } from '../../../../../actions/modals/setShowModal';
import { i18n } from '../../../../../App';
import LoadingElement from '../../../../simple/LoadingElement';
import ModalContainer from '../../../containers/ModalContainer';
import ModalHeader from '../../../containers/ModalElements/ModalHeader';
import ModalBody from '../../../containers/ModalElements/ModalBody';
import ModalFooter from '../../../containers/ModalElements/ModalFooter';
import { editTraining } from '../../../../../actions/tests/editTraining';
import moment from 'moment';


interface IStateProps {
    showModal: boolean,
    loading: boolean

}

interface IDispatchProps {
    setShowEditTrainingModal: typeof setShowEditTrainingModal,
    editTest: typeof editTraining
}

const mapStateToProps = (state: IApplicationStore): IStateProps => ({
    showModal: state.modalsStore.showEditTraining,
    loading: state.loadingStore.loadingCreatedTests
})

const mapDispatchToProps = (dispatch: any): IDispatchProps => ({
    setShowEditTrainingModal: (value: boolean) =>  dispatch(setShowEditTrainingModal(value)),
    editTest: (id: number, data:ITestCreate) => dispatch(editTraining(id, data))
})

interface IProps extends  IStateProps, IDispatchProps{
    test: ITest
}

interface IState {
    id: number
    new_name: string,
    isValid: boolean
}

class ModalEditTraining extends Component<IProps, IState> {
    constructor(props:  any)  {
        super(props)
                    
        this.state = {
            id: this.props.test.id,
            new_name: this.props.test.name,
            isValid: true
        }

        this.editTest = this.editTest.bind(this)
        this.hideModal = this.hideModal.bind(this)
        this.submitFormHandler = this.submitFormHandler.bind(this)
        this.handleNameChange = this.handleNameChange.bind(this)
    }

    componentDidUpdate(prevProps:  IProps) {
        if(
            (prevProps.test.id !== this.props.test.id) ||
            (prevProps.test.name  !== this.props.test.name)
        ){
            this.setState({
                id: this.props.test.id,
                new_name: this.props.test.name
            })
        }
    }

    editTest() {
        const { new_name, id, isValid } = this.state
        const { test } = this.props

        if(isValid) {
            let updatedTest: ITestCreate = {
                language_ids: {
                    language_from_id: test.language_from_id,
                    language_to_id: test.language_to_id
                },
                name: new_name,
                settings: test.settings,
                valid_from: moment(test.valid_from).toISOString(),
                valid_until: (test.valid_until !== null)?moment(test.valid_until).toISOString():null,
                usergroup_id: test.usergroup_id
            }

            this.props.editTest(id, updatedTest)
        }
    }

    handleNameChange(e:any) {
        this.setState({
            ...this.state,
            new_name: e.target.value,
            isValid: (e.target.value.length > 0)
        })
    }

    hideModal() {
        this.props.setShowEditTrainingModal(false)
    }

    submitFormHandler(e:  any)  {
        e.preventDefault()
        this.editTest()
    }

    render() {
        const { showModal, loading, test }  = this.props
        const { isValid, new_name } =  this.state

        let editText = i18n._('Úprava cvičenia')
        let title = i18n._('Názov cvičenia')

        return (
            <section id="mainSection">
                <ModalContainer show={showModal}>
                    <ModalHeader title={editText + ' - ' + test.name }/>
                    
                    <ModalBody>
                        <LoadingElement loadingValue={loading}/>

                        <form className="form _alt" onSubmit={this.submitFormHandler}>
                            <div className="form _alt">
                                <div className="input-row">
                                    <div className="part">
                                        <label htmlFor="wordgroup-name">{title} <i>*</i></label>
                                        <div className="input-holder">
                                            <input
                                                name={"testName"}
                                                type="text"
                                                placeholder={i18n._('Test č.1')}
                                                onChange={this.handleNameChange}
                                                value={new_name}
                                                required
                                                autoFocus={true}
                                            />
                                        </div>
                                    </div>
                                </div>     
                            </div>
                        </form>
                    
                    </ModalBody>

                    <ModalFooter>
                        <div className="part">
                            <button type="button" className="ant-btn bttn _silent href" onClick={this.hideModal}><Trans>Zavrieť</Trans></button>
                        </div>
                        <div className="part">
                            <button type="button" className="ant-btn bttn _primary href" onClick={this.editTest} disabled={!isValid}><Trans>Uložiť zmeny</Trans></button>
                        </div>
                    </ModalFooter>
                </ModalContainer>
            </section>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalEditTraining)