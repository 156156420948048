import { setLoadingRankingCountry } from '../loadings/setLoading';
import config from '../../config';
import { handleErrors } from '../../utils/handleErrors';
import { 
    LOAD_RANKING_COUNTRY,
    LOAD_RANKING_COUNTRY_FAILED,
    LOAD_RANKING_COUNTRY_STARTED } from '../../constants/action-types';
import { store } from '../../store';

export function loadCountry(cId: string) {
    return (dispatch: any) => {

        dispatch(setLoadingRankingCountry(true))
        dispatch(loadCountryStarted())

        return fetch(config.api.rankingCountry + '/' + cId, {
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + store.getState().globalStore.token
            }
            
        })
            .then(handleErrors)
            .then(res => res.json())
            .then(res => {
                if(typeof res.data !== 'undefined') {
                    dispatch(loadCountrySuccess(res.data))
                }
                
                dispatch(setLoadingRankingCountry(false))
            })
            .catch((error: any)  => {
                dispatch(loadCountryFailed(error))
                dispatch(setLoadingRankingCountry(false))
            });
    };
}

export const loadCountryStarted = () => ({
    type: LOAD_RANKING_COUNTRY_STARTED
});

export const loadCountrySuccess = (data:any) => ({
    type: LOAD_RANKING_COUNTRY,
    payload: data
});

export const loadCountryFailed = (error: any) => ({
    type: LOAD_RANKING_COUNTRY_FAILED,
    payload: { error }
});