import React, {Component, PropsWithChildren} from 'react'
import { connect } from 'react-redux'
import { IApplicationStore } from '../../../constants/store-interfaces';
import { ITimelineItem } from '../../../constants/types';
import moment from 'moment';
import Pagination from './TableElements/Pagination';
import LoadingElement from '../../simple/LoadingElement';

interface IStateProps  {
}

const mapStateToProps = (state: IApplicationStore):IStateProps => ({
})

interface IDispatchProps {
}

const mapDispatchToProps = (dispatch: any):IDispatchProps => ({
})

interface IProps extends IStateProps, IDispatchProps {
    items: ITimelineItem[],
    show: boolean,
    emptyText?: string,
    loading?: boolean,
    perPage?: number
}

interface IState {
    page: number,
    perPage: number
}

class TimelineContainer extends Component<PropsWithChildren<IProps>, IState> {

    constructor(props: IProps) {
        super(props)

        this.state = {
            page: 1,
            perPage: (typeof this.props.perPage !== 'undefined')?this.props.perPage:5
        }

        this.setPage = this.setPage.bind(this)
    }

    setPage(num: number) {
        this.setState({
            ...this.state,
            page: num
        })
    }
    
    render() {
        const { show, items, emptyText, loading } = this.props
        const { page, perPage } = this.state

        if(!show) {
            return (null)
        }
        
        let modifiedItems = items.slice((page-1) * perPage, (page) * perPage)

        return (
            <div>
                <div className="timeline">
                    {(typeof loading !== 'undefined')?(loading)?(
                        <LoadingElement loadingValue={loading}/>
                    ):(null):(null)}
                    {(items.length === 0)?(
                        <div className="nothing">
                            <h4>{emptyText}</h4>
                        </div>
                    ):(null)}

                    {modifiedItems.map((item, index) => (
                        <div className="timeline-entry" key={index}>
                            <div className="timeline-part _date">
                                {moment(item.date).format('DD.MM.YYYY HH:mm')}
                            </div>
                            <div className="timeline-part _text">
                                <div className="wrapper">
                                    <div className="part">
                                    
                                        <div className="timeline-status">
                                            <div className="status-left">
                                                {(typeof item.icon !== 'undefined')?
                                                    item.icon
                                                :(null)}
                                            </div>
                                            <div className="status-body">
                                                {item.content}
                                            </div>
                                        </div>
                                    
                                    </div>
                                    {(typeof item.button !== 'undefined')?(
                                        <div className="part _part-options">
                                            <div 
                                                onClick={item.buttonAction} 
                                                className={"bttn  _small _" + ((typeof item.buttonColor !== 'undefined')?item.buttonColor:'primary')}
                                            >
                                                {item.button}
                                            </div>
                                        </div>
                                    ):(null)}
                                </div>
                            </div>
                        </div>
                            
                    ))} 
                </div> 
                    
                <Pagination
                    pages={Math.ceil(items.length/perPage)}
                    currentPage={page}
                    pageClick={this.setPage}
                />              
                
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TimelineContainer)
