import React, {Component} from 'react';
import { connect } from 'react-redux';
import { IApplicationStore } from "../../../constants/store-interfaces";
import { IWordgroupType, ITableRowType, IActionType, ITableHeaderCellType, ITableCellType, IModifyWordGroup, IUWordGroupType, IUSerInfo } from '../../../constants/types';
import { loadCreatedWordGroups } from '../../../actions/wordGroups/loadCreatedWordGroups';
import { Trans } from '@lingui/macro';
import { loadAssignedWordGroups } from '../../../actions/wordGroups/loadAssignedWordGroups';
import TableContainer from '../containers/TableContainer';
import TranslateLanguages from '../../simple/TranslateLanguages';
import { i18n } from '../../../App';
import { NavLink } from 'react-router-dom';
import { setShowCreateWordGroupModal, setShowConfirmModal, setShowEditWordGroupModal } from '../../../actions/modals/setShowModal';
import ModalEditWordGroup from '../parts/modals/wordGroup/ModalEditWordGroup';
import ModalDeleteWordGroups from '../parts/modals/wordGroup/ModalDeleteWordGroups';
import { deleteWordGroup } from '../../../actions/wordGroups/deleteWordGroup';
import { modifyWordGroup } from '../../../actions/wordGroups/modifyWordGroup';
import NumberText from '../../simple/tableElements/NumberText';
import DateText from '../../simple/tableElements/DateText';
import TabsContainer from '../containers/TabsContainer';
import config from '../../../config';
import { withRouter, RouteComponentProps } from 'react-router';
import moment from 'moment';
import ReactGA from 'react-ga';
import UserWordGroupShares from '../parts/user/UserWordGroupShares';

interface IStateProps {
    createdWordGroups: IWordgroupType[],
    assignedWordGroups: IUWordGroupType[],
    loadingCreated: boolean,
    loadingAssigned: boolean,
    userLoggedIn: boolean,
    userInfo: IUSerInfo | null,
}

interface IDispatchProps {
    loadCreatedWordGroups: typeof loadCreatedWordGroups,
    loadAssignedWordGroups: typeof loadAssignedWordGroups,
    setShowDeleteWordGroupModal: typeof setShowConfirmModal,
    setShowCreateWordGroupModal: typeof setShowCreateWordGroupModal,
    setShowEditWordGroupModal: typeof setShowEditWordGroupModal,
    deleteWordGroup: typeof deleteWordGroup,
    modifyWordGroup: typeof modifyWordGroup
}

const mapStateToProps = (state: IApplicationStore): IStateProps => ({
    createdWordGroups: state.wordGroupsStore.createdWordgroups,
    assignedWordGroups: state.wordGroupsStore.assignedWordgroups,
    loadingCreated: state.loadingStore.loadingCreatedWordGroups,
    loadingAssigned: state.loadingStore.loadingAssignedWordGroups,
    userLoggedIn: state.globalStore.userLoggedIn,
    userInfo: state.globalStore.userInfo
})

const mapDispatchToProps = (dispatch: any): IDispatchProps => ({
    loadCreatedWordGroups: () => dispatch(loadCreatedWordGroups()),
    loadAssignedWordGroups: () => dispatch(loadAssignedWordGroups()),
    setShowDeleteWordGroupModal: (value: boolean) => dispatch(setShowConfirmModal(value)),
    setShowCreateWordGroupModal: (value: boolean) => dispatch(setShowCreateWordGroupModal(value)),
    setShowEditWordGroupModal: (value: boolean) => dispatch(setShowEditWordGroupModal(value)),
    deleteWordGroup: (id: number, name: string) => dispatch(deleteWordGroup(id, name)),
    modifyWordGroup: (id: number, data: IModifyWordGroup) => dispatch(modifyWordGroup(id, data))
})

interface IProps extends IStateProps, IDispatchProps, RouteComponentProps {

}

interface IState {
    checkedWordGroups: IWordgroupType[],
    selectedEditWordGroup: IWordgroupType | null,
    selectedDeleteWordGroup: IWordgroupType | null
}

class WordGroupsPage extends Component<IProps, IState> {
    constructor(props: any) {
        super(props) 

        this.state = {
            checkedWordGroups: [],
            selectedEditWordGroup: null,
            selectedDeleteWordGroup: null
        }

        ReactGA.pageview('Skupina slovíčok - zoznam')

        this.handleCreateWordGroup = this.handleCreateWordGroup.bind(this)
        this.handleEditWordGroup = this.handleEditWordGroup.bind(this)
        this.handleDeleteWordGroup = this.handleDeleteWordGroup.bind(this)
        this.handleCheckedWordGroups = this.handleCheckedWordGroups.bind(this)
        this.handleDeleteWordGroups = this.handleDeleteWordGroups.bind(this)
        this.confirmEditWordGroup = this.confirmEditWordGroup.bind(this)
        this.confirmDeleteWordGroup = this.confirmDeleteWordGroup.bind(this)
        this.confirmDeleteWordGroups = this.confirmDeleteWordGroups.bind(this)
    }

    componentDidMount() {
        this.props.loadCreatedWordGroups()
        this.props.loadAssignedWordGroups()
    }

    handleCreateWordGroup() {
        this.props.setShowCreateWordGroupModal(true)
    }

    handleEditWordGroup(catchString: string) {
        const { createdWordGroups } = this.props

        let wordGroupId = Number(catchString.split('_')[1])

        let wordGroupObject = createdWordGroups.find((item:IWordgroupType) => item.id === wordGroupId)

        if(typeof wordGroupObject !== 'undefined') {
            this.setState({
                ...this.state,
                selectedEditWordGroup: wordGroupObject
            })  
            
            this.props.setShowEditWordGroupModal(true)
        }
    }

    handleDeleteWordGroup(catchString: string) {

        const { createdWordGroups } = this.props

        let wordGroupId = Number(catchString.split('_')[1])

        let wordGroupObject = createdWordGroups.find((item:IWordgroupType) => item.id === wordGroupId)

        if(typeof wordGroupObject !== 'undefined') {
            this.setState({
                ...this.state,
                selectedDeleteWordGroup: wordGroupObject
            })  
            
            this.props.setShowDeleteWordGroupModal(true)
        }
    }

    handleCheckedWordGroups(catchStrings: string[]) {
        const { createdWordGroups } = this.props

        let checkedWordGroups: IWordgroupType[] =  []

        for(let catched  of catchStrings) {
            let wordGroupId = Number(catched.split('_')[1])

            let wordGroupObject = createdWordGroups.find((item:IWordgroupType) => item.id === wordGroupId)

            if(typeof wordGroupObject !== 'undefined') {
                checkedWordGroups.push(wordGroupObject)
            }
        }

        this.setState({
            ...this.state,
            checkedWordGroups: checkedWordGroups
        })
    }

    handleDeleteWordGroups() {
        if(this.state.selectedDeleteWordGroup !== null) {
            this.setState({
                ...this.state,
                selectedDeleteWordGroup: null
            })
        }

        this.props.setShowDeleteWordGroupModal(true)
    }

    confirmEditWordGroup(name: string) {
        const { selectedEditWordGroup } = this.state

        if(selectedEditWordGroup !== null) {
            let words: {word_text: string, translation: string}[] = []

            for(let word of selectedEditWordGroup.words) {
                words.push({
                    word_text: word.word_text,
                    translation: word.translation
                })
            }

            const sendModifyGroup: IModifyWordGroup = {
                wordgroup_name: name,
                language_ids: {
                    language_from_id: selectedEditWordGroup.language_from.id,
                    language_to_id: selectedEditWordGroup.language_to.id
                },
                words: words
            }

            this.props.modifyWordGroup(selectedEditWordGroup.id, sendModifyGroup)
        }
    }

    confirmDeleteWordGroup() {
        const { selectedDeleteWordGroup } = this.state

        if(selectedDeleteWordGroup !== null) {
            this.props.deleteWordGroup(selectedDeleteWordGroup.id, selectedDeleteWordGroup.name)
        }
    
    }

    confirmDeleteWordGroups() {
        const { checkedWordGroups } = this.state

        for(let wordGroup of checkedWordGroups) {
            this.props.deleteWordGroup(wordGroup.id, wordGroup.name)
        }
    }

    getRows(wordGroups:  IWordgroupType[]): ITableRowType[] {
        let rows: ITableRowType[] = []

        for(let wordgroup  of wordGroups)  {
            let cells: ITableCellType[] = []

            cells = [
                {
                    content: (<div className="part-title">{wordgroup.name}</div>),
                    stringToOrder:  wordgroup.name,
                    filterString:  wordgroup.name,
                    filterByString:  true
                },
                {
                    content: (
                        <TranslateLanguages
                            languageFrom={wordgroup.language_from}
                            languageTo={wordgroup.language_to}
                        />
                    ),
                    stringToOrder:  wordgroup.language_from.flag_name + wordgroup.language_to.flag_name,
                    filterString: wordgroup.language_from.flag_name + wordgroup.language_to.flag_name,
                    filterByString: false,
                    selectableFilter: true,
                    selectableName: i18n._('vybrať'),
                    selectableAllText: i18n._('Všetky'),
                    selectableTitle: i18n._('Preklad'),
                    selectableObject: [
                        {
                            object: (
                                    <TranslateLanguages
                                        languageFrom={wordgroup.language_from}
                                        languageTo={wordgroup.language_to}
                                    />
                            ),
                            value: wordgroup.language_from.flag_name + wordgroup.language_to.flag_name
                        }
                    ]
                },
                {
                    content: (<NumberText number={wordgroup.words.length} text='' title={i18n._('Počet slovíčok')}/>),
                    stringToOrder:  Number(wordgroup.words.length).toString(),
                    filterString:  Number(wordgroup.words.length).toString(),
                    filterByString:  true,
                    hideClass: 'hide-s'
                },
                {
                    content: (<DateText date={wordgroup.created_at} formatDate={'DD.MM.YYYY'} formatTime={'HH:mm'} title={i18n._('Vytvorená')}/>),
                    stringToOrder: wordgroup.updated_at,
                    filterString: wordgroup.updated_at,
                    filterByString: false,
                    hideClass: 'hide-m'
                },
                {
                    content: (
                        <NavLink to={"/app/wordgroups/" + wordgroup.id} target="_self" className="bttn _small _primary _icon">
                            <Trans>Detail</Trans>
                        </NavLink>
                    ),
                    stringToOrder: wordgroup.updated_at,
                    filterString: wordgroup.updated_at,
                    filterByString: false
                }
            ]

            rows.push({
                cells: cells,
                catchString: '_' +  wordgroup.id
            })
        }

        return rows
    }

    getRowsAssigned(wordGroups:  IUWordGroupType[]): ITableRowType[] {
        let rows: ITableRowType[] = []

        for(let wordgroup  of wordGroups)  {
            let cells: ITableCellType[] = []

            if(moment(wordgroup.valid_from).isBefore(moment())) {
                cells = [
                    {
                        content: (<div className="part-title">{wordgroup.name}</div>),
                        stringToOrder:  wordgroup.name,
                        filterString:  wordgroup.name,
                        filterByString:  true
                    },
                    {
                        content: (
                            <TranslateLanguages
                                languageFrom={wordgroup.language_from}
                                languageTo={wordgroup.language_to}
                            />
                        ),
                        stringToOrder:  wordgroup.language_from.flag_name + wordgroup.language_to.flag_name,
                        filterString: wordgroup.language_from.flag_name + wordgroup.language_to.flag_name,
                        filterByString: false,
                        selectableFilter: true,
                        selectableName: i18n._('vybrať'),
                        selectableAllText: i18n._('Všetky'),
                        selectableTitle: i18n._('Preklad'),
                        selectableObject: [
                            {
                                object: (
                                        <TranslateLanguages
                                            languageFrom={wordgroup.language_from}
                                            languageTo={wordgroup.language_to}
                                        />
                                ),
                                value: wordgroup.language_from.flag_name + wordgroup.language_to.flag_name
                            }
                        ]
                    },
                    {
                        content: (<NumberText number={wordgroup.words.length} text='' title={i18n._('Počet slovíčok')}/>),
                        stringToOrder:  Number(wordgroup.words.length).toString(),
                        filterString:  Number(wordgroup.words.length).toString(),
                        filterByString:  true
                    }
                ]

                if(wordgroup.usergroup !== null) {
                    cells.push({
                        content: (<NavLink to={'/app/classes/' + wordgroup.usergroup.usergroup_id} className="tag _orange">{wordgroup.usergroup.usergroup_name}</NavLink>),
                        stringToOrder:  wordgroup.usergroup.usergroup_name,
                        filterString:  wordgroup.usergroup.usergroup_name,
                        filterByString:  true
                    })
                } else {
                    cells.push({
                        content: (<span></span>),
                        stringToOrder: wordgroup.name,
                        filterString: wordgroup.name,
                        filterByString: false
                    })
                }

                cells.push({
                    content: (<DateText date={(wordgroup.valid_from !== null)?wordgroup.valid_from:''} title={i18n._('Priradené')} formatDate={'DD.MM.YYYY'} formatTime={'HH:mm'}/>),
                    stringToOrder: (wordgroup.valid_from !== null)?wordgroup.valid_from:'',
                    filterString: (wordgroup.valid_from !== null)?wordgroup.valid_from:'',
                    filterByString: false
                })
                cells.push({
                    content: (
                        <NavLink to={"/app/wordgroups/assigned/" + wordgroup.id} target="_self" className="bttn _small _primary _icon">
                            <Trans>Detail</Trans>
                        </NavLink>
                    ),
                    stringToOrder: wordgroup.name,
                    filterString: wordgroup.name,
                    filterByString: false
                })

                rows.push({
                    cells: cells,
                    catchString: '_' +  wordgroup.id
                })
            }
        }

        return rows
    }

    getHeadersCreated(): ITableHeaderCellType[] {
        return [
            {
                content: (<span><Trans>Názov</Trans></span>),
                sortBy: false,
                sortable: true
            },
            {
                content: (<span><Trans>Preklad</Trans></span>),
                sortBy: false,
                sortable: true
            },
            {
                content: (<span><Trans>Počet slovíčok</Trans></span>),
                sortBy: false,
                sortable: true,
                sortableType: 'number',
                hideClass: 'hide-s'
            },
            {
                content: (<span><Trans>Posledná zmena</Trans></span>),
                sortBy: true,
                sortable: true,
                sortableType: 'date',
                hideClass: 'hide-m'
            },
            {
                content: (<span></span>),
                sortBy: false,
                sortable: false
            },
        ]
    }

    getHeadersAssigned(): ITableHeaderCellType[] {
        return [
            {
                content: (<span><Trans>Názov</Trans></span>),
                sortBy: false,
                sortable: true
            },
            {
                content: (<span><Trans>Preklad</Trans></span>),
                sortBy: false,
                sortable: true
            },
            {
                content: (<span><Trans>Počet slovíčok</Trans></span>),
                sortBy: false,
                sortable: true,
                sortableType: 'number',
                hideClass: 'hide-s'
            },
            {
                content: (<span><Trans>Trieda</Trans></span>),
                sortBy: false,
                sortable: true,
            },
            {
                content: (<span><Trans>Vytvorená</Trans></span>),
                sortBy: true,
                sortable: true,
                sortableType: 'date',
                hideClass: 'hide-m'
            },
            {
                content: (<span></span>),
                sortBy: false,
                sortable: false
            },
        ]
    }
    getActionsCreated(): IActionType[] {

        return [
            {
                action: this.handleEditWordGroup,
                name: i18n._('Upraviť')
            },
            {
                action: this.handleDeleteWordGroup,
                name: i18n._('Odstrániť'),
                customClass: 'remove'
            }
        ]
    }

    render() {
        const { userLoggedIn, userInfo, createdWordGroups, loadingAssigned, loadingCreated, assignedWordGroups } = this.props
        const { checkedWordGroups, selectedDeleteWordGroup, selectedEditWordGroup } = this.state

        let createdHeaders: ITableHeaderCellType[] = this.getHeadersCreated()
        let createdRows: ITableRowType[] = this.getRows(createdWordGroups)
        let createdActions: IActionType[] = this.getActionsCreated()

        let assignedRows: ITableRowType[] = this.getRowsAssigned(assignedWordGroups)
        let assignedHeaders: ITableHeaderCellType[] = this.getHeadersAssigned()

        let createdWordGroupsTable = (<TableContainer
            headers={createdHeaders}
            rows={createdRows}
            actions={createdActions}
            showActions={true}
            showCheckbox={true}
            checkboxFunction={this.handleCheckedWordGroups}
            showFilter={true}
            pageSize={12}
            title={i18n._('Skupiny slov')}
            showNew={true}
            grid={true}
            newTitle={i18n._('Pridať skupinu slov')}
            newFunction={this.handleCreateWordGroup}
            emptyText={i18n._('Zatiaľ nemáte vytvorené žiadne skupiny slov')}
            emptySubtitle={i18n._('Ak si chcete pridať nové skupiny slov, stačí stlačiť tlačidlo "pridať skupinu slov".')}
            loading={loadingCreated}
            mobileFixedGrid={true}
            footer={(
                <div className="part">
                    {(checkedWordGroups.length > 0)?(
                        <div 
                            className="bttn _icon _small _red" 
                            title={i18n._('Odstrániť')}
                            onClick={this.handleDeleteWordGroups}
                        >
                            <i className="la la-trash-o"/>
                        </div>
                    ):(null)}
                    
                </div>
            )}
        />)

        let assignedWordGroupsTable = (
            <TableContainer
                headers={assignedHeaders}
                rows={assignedRows}
                actions={createdActions}
                showActions={false}
                showCheckbox={false}
                showFilter={true}
                pageSize={12}
                grid={true}
                title={i18n._('Skupiny slov')}
                showNew={false}
                emptyText={i18n._('Zatiaľ nemáte priradené žiadne skupiny slov')}
                emptySubtitle={i18n._('Ak sa chcete učiť efektívne, využite možnosť pridávania skupín slov od svojho učiteľa.')}
                loading={loadingAssigned}
                defaultSort="asc"
                mobileFixedGrid={true}
            />
        )

        return (
            <div className="rows" data-tour="step10">
                <div className="row">
                    <div className="row-part">
                        <div className="page-header">
                            <div className="part">
                                <h2><Trans>Skupiny slovíčok</Trans></h2>
                                {(userLoggedIn && (userInfo !== null) && (userInfo.role.name === config.roles.user))?( 
                                    <p><Trans>Zoznam skupín slovíčok, ktoré som mi boli priradené, alebo som si ich vytvoril sám.</Trans></p>
                                ):(
                                    <p><Trans>Zoznam skupín slovíčok, ktoré som vytvoril a môžem ich priradiť do tried.</Trans></p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="row-part">
                        <UserWordGroupShares/>
                    </div>
                </div>

                <div className="row">
                    <div className="row-part">
                        {(userLoggedIn && (userInfo !== null) && (userInfo.role.name === config.roles.user))?( 
                            <TabsContainer
                                tabs={[
                                    {
                                        header: {
                                            icon: 'la-edit',
                                            title: i18n._('Vytvorené'),
                                            description: i18n._('skupiny slovíčok'),
                                            titleTab: i18n._('Vytvorené skupiny slovíčok')
                                        },
                                        content: createdWordGroupsTable
                                    },
                                    {
                                        header: {
                                            icon: 'la-group',
                                            title: i18n._('Priradené'),
                                            description: i18n._('skupiny slovíčok'),
                                            titleTab: i18n._('Priradené skupiny slovíčok')
                                        },
                                        content: assignedWordGroupsTable
                                    }
                                ]}
                                footerActions={[]}
                            />
                        ):(
                            <>
                                {createdWordGroupsTable}
                            </>
                        )}
                    </div>
                </div>
                
                {(selectedEditWordGroup !== null)?(
                    <ModalEditWordGroup
                        id={selectedEditWordGroup.id}
                        wordGroupName={selectedEditWordGroup.name}
                        confirmFunction={this.confirmEditWordGroup}
                    />
                ):(null)}

                {(selectedDeleteWordGroup !== null)?(
                    <ModalDeleteWordGroups
                        wordGroups={[selectedDeleteWordGroup]}
                        confirmFunction={this.confirmDeleteWordGroup}
                    />
                ):(null)}

                {(checkedWordGroups.length > 0)?(
                    <ModalDeleteWordGroups
                        wordGroups={checkedWordGroups}
                        confirmFunction={this.confirmDeleteWordGroups}
                    />
                ):(null)}
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(WordGroupsPage))