import { setLoadingTestResult } from '../loadings/setLoading';
import config from '../../config';
import { handleErrors } from '../../utils/handleErrors';
import { store } from '../../store';
import { i18n } from '../../App';
import { loadTestResultsFailed } from './loadTestResults';

export function loadTestResultsAll(testId: number) {
    store.dispatch(setLoadingTestResult(true))

    return fetch(config.api.testResultsAll + '/' + testId, {
        method: 'GET',
        credentials: 'include',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + store.getState().globalStore.token
        }
        
    })
        .then(handleErrors)
        .then(res => res.json())
        .then(res => {
            if(res.status === 'success') {
                store.dispatch(setLoadingTestResult(false))

                return res.data
            } else {
                throw i18n._('Neexistujú dáta pre tento test!')
            }
        })
        .catch((error: any)  => {
            store.dispatch(loadTestResultsFailed(error))
            store.dispatch(setLoadingTestResult(false))
        });
}