import { setLoadingCreatedUserGroups } from '../loadings/setLoading';
import config from '../../config';
import { handleErrors } from '../../utils/handleErrors';
import { MODIFY_WORDGROUP_FROM_USERGROUP_FAILED, MODIFY_WORDGROUP_FROM_USERGROUP, MODIFY_WORDGROUP_FROM_USERGROUP_STARTED } from '../../constants/action-types';
import { store } from '../../store';
import { addFloatingNotification } from '../notifications/floating';
import { i18n } from '../../App';


export function modifyWordGroupInUserGroup(wgId:number, ugId: number, data: any) {
    return (dispatch: any) => {

        dispatch(setLoadingCreatedUserGroups(true))
        dispatch(modifyWordGroupInUserGroupStarted())

        return fetch(config.api.usergroups + '/' + ugId + '/wordgroups/' + wgId, {
            method: 'PUT',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + store.getState().globalStore.token
            },
            body: JSON.stringify(data)
            
        })
            .then(handleErrors)
            .then(res => res.json())
            .then(res => {
                dispatch(modifyWordGroupInUserGroupSuccess({valid_from: data.valid_from, wgId: wgId, userGroupId: ugId}))
                dispatch(setLoadingCreatedUserGroups(false))

                dispatch(addFloatingNotification({
                    type:"success",
                    text:i18n._('Skupina slovíčok bola úspešne aktualizovaná'),
                    showBell: true
                }))

            })
            .catch((error: any)  => {
                dispatch(modifyWordGroupInUserGroupFailed(error))
                dispatch(setLoadingCreatedUserGroups(false))
            });
    };
}

export const modifyWordGroupInUserGroupStarted = () => ({
    type: MODIFY_WORDGROUP_FROM_USERGROUP_STARTED
});

export const modifyWordGroupInUserGroupSuccess = (data:any) => ({
    type: MODIFY_WORDGROUP_FROM_USERGROUP,
    payload: data
});

export const modifyWordGroupInUserGroupFailed = (error: any) => ({
    type: MODIFY_WORDGROUP_FROM_USERGROUP_FAILED,
    payload: { error }
});