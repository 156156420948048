import React, {Component} from 'react';
import { IApplicationStore } from "../../../constants/store-interfaces";
import { IUserGroupType, IAddWordMapping, IUserBasic, ITabs, ITabsAction, IUSerInfo, IUWordGroupType, ITest, ETestUgType, ITestResult } from '../../../constants/types';
import { Trans } from '@lingui/macro';
import LoadingElement from '../../simple/LoadingElement';
import { i18n } from '../../../App';
import {RouteComponentProps} from "react-router";
import { loadUserGroup } from '../../../actions/userGroups/loadUserGroup';
import InfoModal from '../../simple/InfoModal';
import ModalEditUserGroup from '../parts/modals/userGroup/ModalEditUserGroup';
import ModalAddWordGroupToUserGroup from '../parts/modals/userGroup/ModalAddWordGroupToUserGroup';
import { setShowEditUserGroupModal, setShowConfirmModal, setShowCreateTestUGModal } from '../../../actions/modals/setShowModal';
import ConfirmModal from '../../simple/ConfirmModal';
import InformationBox from '../parts/InformationBox';
import IconTextWidget from '../../simple/widgets/IconTextWidget';
import { regenerateUserGroupKey } from '../../../actions/userGroups/regenerateUserGroupKey';
import TabsContainer from '../containers/TabsContainer';
import CreatedUsersTable from '../parts/userGroups/CreatedUsersTable';
import { connect } from 'react-redux';
import { withRouter, NavLink } from 'react-router-dom';
import AssignedWordGroupsTable from '../parts/userGroups/AssignedWordGroupsTable';
import AllWordsSummary from '../parts/modals/modalParts/summary/AllWordsSummary';
import moment from 'moment';
import AuthTeacherWrapper from '../containers/Authentification/AuthTeacherWrapper';
import config from '../../../config';
import RecentUserGroupActivity from '../parts/userGroups/RecentUserGroupActivity';
import SchoolMatesTable from '../parts/userGroups/SchoolMatesTable';
import AuthUserWrapper from '../containers/Authentification/AuthUserWrapper';
import { deleteSelfFromUserGroup } from '../../../actions/userGroups/deleteSelfFromUserGroup';
import ModalDeleteUserFromUserGroup from '../parts/modals/userGroup/ModalDeleteUserFromUserGroup';
import { loadAssignedWordGroups } from '../../../actions/userGroups/loadAssignedWordGroups';
import AssignedTestsTable from '../parts/userGroups/AssignedTestsTable';
import { loadUserGroupTests } from '../../../actions/tests/loadUserGroupTests';
import EmptyModal from '../../simple/EmptyModal';
import TestType from '../parts/modals/modalParts/TestType';
import ModalAddNewUserGroupTest from '../parts/modals/tests/ModalAddNewUserGroupTest';
import AssignedHomeworkTable from '../parts/userGroups/AssignedHomeworkTable';
import { loadTestResults } from '../../../actions/tests/loadTestResults';
import { loadTestResultsUserGroup } from '../../../actions/tests/loadTestResultsUserGroup';
import CopyToClipboard from 'react-copy-to-clipboard';
import ReactGA from 'react-ga';

interface IStateProps {
    loadingCreated: boolean,
    loadingAssigned: boolean,
    loadingTestResults: boolean,
    testResults: ITestResult[],
    usergroupResults: {[key: number]: ITestResult[]},
    createdUserGroups: IUserGroupType[],
    assignedUserGroups: IUserGroupType[],
    ugWordGroups: {[key: number]: IUWordGroupType[]},
    userLoggedIn: boolean,
    userInfo: IUSerInfo | null,
    ugTests:  {[key: number]: ITest[]},
    showRegenerateModal: boolean,
    showAddTestModal: boolean
}

interface IDispatchProps {
    loadCreatedUserGroup: typeof loadUserGroup,
    loadTestResults: typeof loadTestResults,
    loadAssignedWordGroups: typeof loadAssignedWordGroups,
    setShowEditUserGroupModal: typeof  setShowEditUserGroupModal,
    regenerateUserGroupKey: typeof regenerateUserGroupKey,
    loadUserGroupTests: typeof loadUserGroupTests,
    setShowConfirmModal: typeof setShowConfirmModal,
    setShowCreateTestUGModal: typeof setShowCreateTestUGModal,
    loadTestResultsUserGroup: typeof loadTestResultsUserGroup
}

const mapStateToProps = (state: IApplicationStore): IStateProps => ({
    loadingCreated: state.loadingStore.loadingCreatedUserGroups,
    loadingAssigned: state.loadingStore.loadingAssignedUserGroups,
    loadingTestResults: state.loadingStore.loadingTestResults,
    testResults: state.testsStore.testResults,
    usergroupResults: state.testsStore.usergroupResults,
    createdUserGroups: state.userGroupsStore.createdUserGroups,
    assignedUserGroups: state.userGroupsStore.assignedUserGroups,
    ugWordGroups: state.wordGroupsStore.ugWordgroups,
    userLoggedIn: state.globalStore.userLoggedIn,
    userInfo: state.globalStore.userInfo,
    ugTests: state.testsStore.usergroupTests,
    showRegenerateModal: state.modalsStore.showConfirm,
    showAddTestModal: state.modalsStore.showAddTestUG
})

const mapDispatchToProps = (dispatch: any): IDispatchProps => ({ 
    loadCreatedUserGroup: (id: number) => dispatch(loadUserGroup(id)),
    loadTestResults: () => dispatch(loadTestResults()),
    loadAssignedWordGroups: (id: number) => dispatch(loadAssignedWordGroups(id)),
    setShowEditUserGroupModal: (value: boolean) => dispatch(setShowEditUserGroupModal(value)),
    regenerateUserGroupKey: (id: number) => dispatch(regenerateUserGroupKey(id)),
    loadUserGroupTests: (id: number) => dispatch(loadUserGroupTests(id)),
    setShowConfirmModal: (val: boolean) => dispatch(setShowConfirmModal(val)),
    setShowCreateTestUGModal: (val: boolean) => dispatch(setShowCreateTestUGModal(val)),
    loadTestResultsUserGroup: (ugId: number) => dispatch(loadTestResultsUserGroup(ugId))
})

type PathParamsType = {
    id: string,
}

interface IProps extends IStateProps, IDispatchProps, RouteComponentProps<PathParamsType> {

}

interface IState {
    userGroupId: number,
    showModalInfoUsers: boolean,
    showModalInfoWordGroup: boolean,
    showModalAddWordGroup: boolean,
    showModalDeleteUserFromUserGroup: boolean,
    showModalDeleteSelfFromUserGroup: boolean,
    showModalAddTesttype: boolean
    selectedUser: IUserBasic | null,
    selectShowWordGroup: IUWordGroupType | null,
    ugType: ETestUgType,
    copied: boolean
    showCopiedString: string
}

class WordGroupDetailPage extends Component<IProps, IState> {

    constructor(props: any) {
        super(props)

        ReactGA.pageview('Detail triedy')

        this.state = {
            userGroupId: Number(this.props.match.params.id),
            showModalInfoUsers: false,
            showModalInfoWordGroup: false,
            showModalAddWordGroup: false,
            showModalDeleteUserFromUserGroup: false,
            showModalDeleteSelfFromUserGroup: false,
            showModalAddTesttype: false,
            selectedUser: null,
            selectShowWordGroup: null,
            ugType: ETestUgType.homework,
            copied: false,
            showCopiedString: i18n._('Skopírovať kód')
        }

        this.handleToggleInfoUsers = this.handleToggleInfoUsers.bind(this)
        this.handleToggleInfoWG = this.handleToggleInfoWG.bind(this)
        this.handleToggleGenerateCode = this.handleToggleGenerateCode.bind(this)
        this.handleToggleAddWordGroup = this.handleToggleAddWordGroup.bind(this)
        this.handleRegenerateCode = this.handleRegenerateCode.bind(this)
        this.handleAddWordGroup = this.handleAddWordGroup.bind(this)
        this.handleToggleRemoveUserFromUserGroup = this.handleToggleRemoveUserFromUserGroup.bind(this)
        this.showWordGroupInfo = this.showWordGroupInfo.bind(this)
        this.showDeleteUserFromUserGroup = this.showDeleteUserFromUserGroup.bind(this)
        this.handleToggleRemoveSelfFromUserGroup = this.handleToggleRemoveSelfFromUserGroup.bind(this)
        this.handleRemoveSelfFromUserGroup = this.handleRemoveSelfFromUserGroup.bind(this)
        this.handleShowAddUserGroupTestModal = this.handleShowAddUserGroupTestModal.bind(this)
        this.handleShowAddUserGroupTestModalType = this.handleShowAddUserGroupTestModalType.bind(this)
        this.copyText = this.copyText.bind(this)
    }

    componentDidMount() {
        this.props.loadCreatedUserGroup(this.state.userGroupId)

        this.props.loadAssignedWordGroups(this.state.userGroupId)

        this.props.loadUserGroupTests(this.state.userGroupId)

        this.props.loadTestResults()

        this.props.loadTestResultsUserGroup(this.state.userGroupId)
    }


    handleToggleInfoUsers() {
        this.setState({
            ...this.state,
            showModalInfoUsers: !this.state.showModalInfoUsers
        })
    }

    handleRemoveSelfFromUserGroup() {
        const { createdUserGroups, assignedUserGroups, userLoggedIn, userInfo } = this.props
        const { userGroupId } = this.state

        let userGroup = createdUserGroups.find((userGroup: IUserGroupType)  => userGroup.id === userGroupId)

        if(userLoggedIn && (userInfo !== null) && (userInfo.role.name === config.roles.user)) {
            userGroup = assignedUserGroups.find((userGroup: IUserGroupType)  => userGroup.id === userGroupId)
        }
        if(typeof userGroup !== 'undefined') {
            deleteSelfFromUserGroup(userGroup.id, userGroup.name)
                .then(() => {
                    this.setState({
                        ...this.state,
                        showModalDeleteSelfFromUserGroup: false
                    })
                })
        }
    }

    handleToggleRemoveSelfFromUserGroup() {
        this.setState({
            ...this.state,
            showModalDeleteSelfFromUserGroup: !this.state.showModalDeleteSelfFromUserGroup
        })
    }

    showWordGroupInfo(wg: IUWordGroupType) {
        this.setState({
            ...this.state,
            selectShowWordGroup: wg,
            showModalInfoWordGroup: true
        })
    }

    showDeleteUserFromUserGroup(userId: string) {
        let userGroup = this.props.createdUserGroups.find((userGroup: IUserGroupType)  => userGroup.id === this.state.userGroupId)

        if(typeof userGroup !== 'undefined') {
            let user = userGroup.assigned_users.find(user => user.id === Number(userId))

            if(typeof user !== 'undefined') {
                this.setState({
                    ...this.state,
                    selectedUser: user,
                    showModalDeleteUserFromUserGroup: true
                })
            }
        }
    }

    handleToggleRemoveUserFromUserGroup() {
        this.setState({
            ...this.state,
            showModalDeleteUserFromUserGroup: !this.state.showModalDeleteUserFromUserGroup
        })
    }

    handleToggleInfoWG() {
        this.setState({
            ...this.state,
            showModalInfoWordGroup: !this.state.showModalInfoWordGroup,
        })
    }

    handleToggleGenerateCode() {
        this.props.setShowConfirmModal(!this.props.showRegenerateModal)
    }

    handleToggleAddWordGroup() {
        this.setState({
            ...this.state,
            showModalAddWordGroup: !this.state.showModalAddWordGroup
        })
    }

    handleRegenerateCode() {
        this.props.regenerateUserGroupKey(this.state.userGroupId)
    }

    handleAddWordGroup() {
        this.handleToggleAddWordGroup()
    }

    handleShowAddUserGroupTestModalType() {
        this.setState({
            ...this.state,
            showModalAddTesttype: !this.state.showModalAddTesttype
        })
    }

    handleShowAddUserGroupTestModal(type: ETestUgType) {
        this.setState({
            ...this.state,
            showModalAddTesttype: false,
            ugType: type
        })

        this.props.setShowCreateTestUGModal(true)
    }

    copyText(text: string) {
        this.setState({
            ...this.state,
            copied: true,
            showCopiedString: i18n._('Skopírované')
        })

        setTimeout(() => {
            this.setState({
                ...this.state,
                copied: false,
                showCopiedString: i18n._('Skopírovať kód')
            })
        }, 1500)
    }

    render() {
        const { loadingAssigned, loadingCreated, createdUserGroups, assignedUserGroups, ugWordGroups, setShowEditUserGroupModal, userLoggedIn, userInfo, ugTests, showRegenerateModal, showAddTestModal, testResults } = this.props
        const { userGroupId, showModalInfoUsers, showModalAddWordGroup, showModalInfoWordGroup, selectShowWordGroup, showModalDeleteUserFromUserGroup, selectedUser, showModalDeleteSelfFromUserGroup, ugType, showModalAddTesttype } = this.state

        let userGroup = createdUserGroups.find((userGroup: IUserGroupType)  => userGroup.id === userGroupId)

        if(userLoggedIn && (userInfo !== null) && (userInfo.role.name === config.roles.user)) {
            userGroup = assignedUserGroups.find((userGroup: IUserGroupType)  => userGroup.id === userGroupId)
        }

        let registerLink = window.location.origin + "/register?code=" + ((typeof userGroup !== 'undefined')?userGroup.access_code:'')

        let selectedWordGroupWords: IAddWordMapping[] = []

        if(selectShowWordGroup !== null) {
            for(let word of selectShowWordGroup.words) {
                selectedWordGroupWords.push({
                    from: word.word_text,
                    to: word.translation
                })
            }
        }

        let tableTabs:ITabs[] = []
        let tableFooterActions:ITabsAction[] = []

        let wordGroups: IUWordGroupType[] = []
        let allTests: ITest[] = []        
        
        if(typeof userGroup !== 'undefined') {
            allTests = ugTests[userGroup.id]

            if(typeof allTests == 'undefined') {
                allTests = []
            }

            wordGroups = ugWordGroups[userGroup.id]

            if(typeof wordGroups == 'undefined') {
                wordGroups = []
            }
        }

        let homeworks: ITest[] = []
        let tests: ITest[] = []
        
        allTests.forEach(t => {
            if(typeof t.settings.ugType !== 'undefined') {
                if(t.settings.ugType === ETestUgType.homework) {
                    homeworks.push(t)
                } else if (t.settings.ugType === ETestUgType.test) {
                    tests.push(t)
                }
            }

        })

        if(userLoggedIn && (userInfo !== null) && (userInfo.role.name === config.roles.user)) {
            homeworks = homeworks.filter(t => moment(new Date(t.valid_from)).isBefore(moment()))
        }

        if(typeof userGroup !== 'undefined') {
            if(userLoggedIn && (userInfo !== null) && (userInfo.role.name === config.roles.user)) {
                //Tabs for user
                tableTabs = [
                    {
                        header: {
                            icon: 'la-history',
                            title: i18n._('Novinky'),
                            titleTab: i18n._('Novinky z triedy')
                        },
                        content: (
                            <RecentUserGroupActivity
                                wordGroups={wordGroups}
                                homeWorks={homeworks}
                                tests={tests}
                                results={testResults}
                                classId={userGroupId}
                            />
                        )
                    },
                    {
                        header: {
                            icon: 'la-th-list',
                            title: i18n._('Slovíčka'),
                            titleTab: i18n._('Skupiny slovíčok v triede')
                        },
                        content: (<AssignedWordGroupsTable 
                                    loadedWordGroups={wordGroups}
                                    showDetailWG={this.showWordGroupInfo}
                                    currentUserGroupId={userGroupId}
                                    isTeacher={false}
                                />)
                    },
                    {
                        header: {
                            icon: 'la-mortar-board',
                            title: i18n._('Domáce úlohy'),
                            titleTab: i18n._('Domáce úlohy v triede')
                        },
                        content: (<AssignedHomeworkTable 
                                    loadedTests={homeworks}
                                    userGroup={userGroup}
                                    currentUserGroupId={userGroupId}
                                    addTest={() => this.handleShowAddUserGroupTestModal(ETestUgType.homework)}
                                    isTeacher={false}
                                    testResults={testResults}
                                />)
                    },
                    {
                        header: {   
                            icon: 'la-edit',
                            title: i18n._('Písomky'),
                            titleTab: i18n._('Písomky v triede')
                        },
                        content: (<AssignedTestsTable 
                                    loadedTests={tests}
                                    userGroup={userGroup}
                                    currentUserGroupId={userGroupId}
                                    addTest={() => this.handleShowAddUserGroupTestModal(ETestUgType.test)}
                                    isTeacher={false}
                                    results={testResults}
                                />)
                    },
                    {
                        header: {
                            icon: 'la-users',
                            title: i18n._('Spolužiaci'),
                            titleTab: i18n._('Zoznam spolužiakov')
                        },
                        content: (<SchoolMatesTable userGroup={userGroup}/>)
                    }
                ]
            } else {
                //Tabs for teacher
                tableTabs = [
                    {
                        header: {
                            icon: 'la-users',
                            title: i18n._('Žiaci'),
                            titleTab: i18n._('Zoznam žiakov')
                        },
                        content: (<CreatedUsersTable userGroup={userGroup} showModalDeleteUserFromUserGroup={this.showDeleteUserFromUserGroup}/>)
                    },
                    {
                        header: {
                            icon: 'la-th-list',
                            title: i18n._('Slovíčka'),
                            titleTab: i18n._('Skupiny slovíčok v triede')
                        },
                        content: (<AssignedWordGroupsTable 
                                    loadedWordGroups={wordGroups}
                                    showDetailWG={this.showWordGroupInfo}
                                    currentUserGroupId={userGroupId}
                                    handleAddWG={this.handleAddWordGroup}
                                    isTeacher={true}
                                />)
                    },
                    {
                        header: {
                            icon: 'la-mortar-board',
                            title: i18n._('Domáce úlohy'),
                            titleTab: i18n._('Domáce úlohy v triede')
                        },
                        content: (<AssignedHomeworkTable 
                                    loadedTests={homeworks}
                                    userGroup={userGroup}
                                    currentUserGroupId={userGroupId}
                                    addTest={() => this.handleShowAddUserGroupTestModal(ETestUgType.homework)}
                                    isTeacher={true}
                                    testResults={testResults}
                                />)
                    },
                    {
                        header: {
                            icon: 'la-edit',
                            title: i18n._('Písomky'),
                            titleTab: i18n._('Písomky v triede')
                        },
                        content: (<AssignedTestsTable 
                                    loadedTests={tests}
                                    userGroup={userGroup}
                                    currentUserGroupId={userGroupId}
                                    addTest={() => this.handleShowAddUserGroupTestModal(ETestUgType.test)}
                                    isTeacher={true}
                                    results={testResults}
                                />)
                    }
                ]

                tableFooterActions = [
                    {
                        icon:'la-plus-circle',
                        title:i18n._('Prideliť skupinu slovíčok'),
                        action: this.handleAddWordGroup
                    }
                ]
            }
        }

        return (
            <section id="mainSection">
                <LoadingElement loadingValue={(loadingAssigned || loadingCreated)} />
                {(typeof userGroup !== 'undefined')?(
                    <div>
                        <div className="mainSectionBody">
                        
                            <div className="page-header">
                                <div className="part">
                                    <h1><Trans>Trieda</Trans> - {userGroup.name}</h1>
                                    <p>{userGroup.comment}</p>
                                </div>
                                <AuthTeacherWrapper>
                                    <div className="part">
                                        <div className="class-code">
                                            <div className="class-code_title _part"><Trans>Registračný kód</Trans></div>
                                            <div className="class-code_number _part" data-tooltip={this.state.showCopiedString}>
                                                <CopyToClipboard text={userGroup.access_code} onCopy={this.copyText}>
                                                    <span className="pointer">{userGroup.access_code}</span>
                                                </CopyToClipboard>
                                            </div>
                                            <div className="class-code_action _part">
                                                <i className="la la-refresh" data-tooltip={i18n._('Vygenerovať nový kód')} onClick={this.handleToggleGenerateCode}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="part text-right">
                                        <div className="bttn _small _green _icon" onClick={this.handleToggleInfoUsers}>
                                            <i className="la la-plus-circle"/>
                                            <Trans>Pozvať žiakov</Trans>
                                        </div>
                                        <div className="bttn _small _primary _icon" onClick={() => setShowEditUserGroupModal(true)} data-tooltip={i18n._("Editácia triedy")}>
                                            <i className="la la-pencil"/>
                                        </div>
                                    </div>
                                </AuthTeacherWrapper>
                                <AuthUserWrapper>
                                    <div className="part text-right">
                                        <div className="bttn _small _red _icon" onClick={() => this.handleToggleRemoveSelfFromUserGroup()}>
                                            <i className="la la-trash"/>
                                            <Trans>Odstrániť sa z triedy</Trans>
                                        </div>
                                    </div>
                                </AuthUserWrapper>
                            </div>
                            <div className="rows">
                                <div className="row">
                                    <div className="row-part">
                                        <div className="small-widgets">
                                            <ul>
                                                <li>
                                                    <IconTextWidget
                                                        showIcon={true}
                                                        icon="la-group"
                                                    >
                                                        <h2>{userGroup.assigned_users.length}</h2>
                                                        <p><Trans>počet žiakov</Trans></p>
                                                    </IconTextWidget>
                                                </li>
                                                <li>
                                                    <IconTextWidget
                                                        showIcon={true}
                                                        icon="la-align-justify"
                                                    >
                                                        <h2>{wordGroups.length}</h2>
                                                        <p><Trans>počet priradených skupín slovíčok</Trans></p>
                                                    </IconTextWidget>
                                                </li>
                                                <li>
                                                    <IconTextWidget
                                                        showIcon={true}
                                                        icon="la-edit"
                                                    >
                                                        <h2>{homeworks.length }</h2>
                                                        <p><Trans>počet domácich úloh</Trans></p>
                                                    </IconTextWidget>
                                                </li>
                                                <li>
                                                    <IconTextWidget
                                                        showIcon={true}
                                                        icon="la-mortar-board"
                                                    >
                                                        <h2>{tests.length }</h2>
                                                        <p><Trans>počet písomiek</Trans></p>
                                                    </IconTextWidget>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="row-part">
                                        <TabsContainer
                                            tabs={tableTabs}
                                            footerActions={tableFooterActions}
                                        />
                                    </div>
                                </div>
                            
                            </div>
                        
                        </div>

                        <AuthTeacherWrapper>
                            <InfoModal
                                title={i18n._('Pozvať žiakov')}
                                custom={true}
                                customShow={showModalInfoUsers}
                                customShowCloseFunction={this.handleToggleInfoUsers}
                            >
                                <div>
                                    <h4 className="headline-divider"><Trans>Ako pozvať žiakov do triedy</Trans> {userGroup.name}</h4>
                                    <p>
                                        <Trans>
                                            Sú dva spôsoby:
                                        </Trans>
                                    </p>
                                    <ol>
                                        <li><Trans>Stačí, ak Vaším žiakom rozdáte kódy, ktoré sami vedia zadať v aplikácii. Na základe prideleného kódu budú priradený do Vašej triedy.</Trans></li>
                                        <li><Trans>Odošlete Vaším žiakom odkaz na priradenie do triedy.</Trans></li>
                                    </ol>
                                    <p><Trans>Priradenie prebieha automaticky.</Trans></p>

                                    <div className="summary">
                                        <div className="summary-block">
                                            <h4>
                                                <i className="la la-key"/>
                                                <Trans>Registračný kód pre triedu</Trans> {userGroup.name}
                                            </h4>
                                            <div className="summary-content">
                                                {userGroup.access_code}
                                            </div>
                                        </div>
                                        <div className="summary-block">
                                            <h4>
                                                <i className="la la-chain"/>
                                                <Trans>Pozvanie žiakov cez odkaz</Trans>
                                            </h4>
                                            <div className="summary-content">
                                                <a href={registerLink} target="_blank" rel="noopener noreferrer" >{registerLink}</a>&nbsp;
                                                <CopyToClipboard text={registerLink} onCopy={this.copyText}>
                                                    <span className="bttn _primary _small _icon" data-tooltip={i18n._('Skopírovať odkaz')}>
                                                        <i className="la la-copy"/>
                                                    </span>
                                                </CopyToClipboard>

                                                {(this.state.copied)?(
                                                    <InformationBox type="success" showBell={true} showClose={true}>
                                                        <Trans>Skopírované do schránky</Trans>
                                                    </InformationBox>
                                                ):(null)}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </InfoModal>
                        </AuthTeacherWrapper>

                        {(selectShowWordGroup !== null)?(
                            <InfoModal
                                title={i18n._('Skupina slov') + ' - ' + selectShowWordGroup.name}
                                custom={true}
                                customShow={showModalInfoWordGroup}
                                customShowCloseFunction={this.handleToggleInfoWG}
                            >
                                <div>
                                    <div className="summary">
                                        <div className="summary-block">
                                            <h4>
                                                <i className="la la-history"/>
                                                <Trans>Dátum priradenia</Trans>
                                            </h4>
                                            <div className="summary-content">
                                                {(selectShowWordGroup.valid_from !== null)?(
                                                    <span 
                                                        className={"tag " + ((moment(selectShowWordGroup.valid_from).isBefore(moment()))?'_green':'_red')}
                                                        data-tooltip={moment(selectShowWordGroup.valid_from).isBefore(moment())?i18n._('Zobrazuje sa žiakom'):i18n._('Zatiaľ sa žiakom nezobrazuje')}
                                                    >
                                                        <Trans>od</Trans> {moment(selectShowWordGroup.valid_from).format('DD.MM.YYYY HH:mm')}
                                                    </span>
                                                ):(null)}
                                            </div>
                                        </div>
                                        <div className="summary-block">
                                            <h4>
                                                <i className="la la-language"/>
                                                {selectShowWordGroup.words.length + ' '}
                                                {(selectShowWordGroup.words.length === 1)?(<Trans>slovíčko</Trans>):(selectShowWordGroup.words.length <= 4)?(<Trans>slovíčka</Trans>):(<Trans>slovíčok</Trans>)}
                                            </h4>
                                            <div className="summary-content">
                                                <AllWordsSummary
                                                    words={selectedWordGroupWords}
                                                    languageFrom={selectShowWordGroup.language_from}
                                                    languageTo={selectShowWordGroup.language_to}
                                                    results={testResults}
                                                    currentUserGroup={userGroup.id}
                                                />
                                            </div>
                                        </div>
                                        <div className="summary-block">
                                            <h4>
                                                <i className="la la-language"/>
                                                <Trans>Detail skupiny slov</Trans>
                                            </h4>
                                            <div className="summary-content">
                                                <AuthTeacherWrapper>
                                                    <NavLink to={"/app/wordgroups/" + selectShowWordGroup.id + '/' + userGroup.id} className="bttn _small _primary _icon"  data-tooltip={i18n._("Detail skupiny")}>
                                                        <i className="la la-search-plus"/>
                                                    </NavLink>
                                                </AuthTeacherWrapper>
                                                <AuthUserWrapper>
                                                    <NavLink to={"/app/wordgroups/assigned/" + selectShowWordGroup.id} className="bttn _small _primary _icon"  data-tooltip={i18n._("Detail skupiny")}>
                                                        <i className="la la-search-plus"/>
                                                    </NavLink>
                                                </AuthUserWrapper>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </InfoModal>
                        ):(null)}

                        <AuthTeacherWrapper>
                            <ConfirmModal
                                confirmFunction={this.handleRegenerateCode}
                                closeOnConfirm={false}
                                yesOption={i18n._('Vygenerovať')}
                                noOption={i18n._('Zrušiť')}
                                title={i18n._('Nový registračný kód')}
                                custom={true}
                                customFunctionToggle={this.handleToggleGenerateCode}
                                customShow={showRegenerateModal}
                            >
                                <LoadingElement loadingValue={loadingCreated} />
                                <InformationBox type={'warning'} showBell={true}>
                                    <Trans>
                                        Potvrdením sa vygeneruje nový registračný kód. Aktuálny kód stratí platnosť!
                                    </Trans>
                                </InformationBox>
                            </ConfirmModal>
                            
                            {(selectedUser !== null)?(
                                <ModalDeleteUserFromUserGroup
                                    showModal={showModalDeleteUserFromUserGroup}
                                    userName={selectedUser.f_name}
                                    userId={selectedUser.id}
                                    userGroupId={userGroup.id}
                                    hideModalFunction={this.handleToggleRemoveUserFromUserGroup}
                                />
                            ):(null)}
                            
                            <ModalEditUserGroup 
                                id={userGroup.id}
                                name={userGroup.name}
                                note={userGroup.comment}
                            />

                            <ModalAddWordGroupToUserGroup
                                showModal={showModalAddWordGroup}
                                userGroupId={userGroup.id}
                                userGroupName={userGroup.name}
                                hideModalFunction={this.handleToggleAddWordGroup}
                                assignedWordGroups={wordGroups}
                            />

                            <ModalAddNewUserGroupTest
                                userGroup={userGroup}
                                wordGroups={wordGroups}
                                showModal={showAddTestModal}
                                testType={ugType}
                                hideModal={() => this.props.setShowCreateTestUGModal(false)}
                            />

                            <EmptyModal
                                customShow={showModalAddTesttype}
                                custom={true}
                                customShowCloseFunction={this.handleShowAddUserGroupTestModalType}
                                title={i18n._('Aký typ testu chcete vytvoriť?')}
                            >
                                <TestType
                                    handleShowTestModal={this.handleShowAddUserGroupTestModal}
                                />
                            </EmptyModal>
                        </AuthTeacherWrapper>
                        <AuthUserWrapper>
                            <ConfirmModal
                                    confirmFunction={this.handleRemoveSelfFromUserGroup}
                                    closeOnConfirm={false}
                                    yesOption={i18n._('Odstrániť sa')}
                                    noOption={i18n._('Zrušiť')}
                                    title={i18n._('Odstrániť sa z triedy')}
                                    custom={true}
                                    customFunctionToggle={this.handleToggleRemoveSelfFromUserGroup}
                                    customShow={showModalDeleteSelfFromUserGroup}
                                >
                                <InformationBox type={'warning'} showBell={true}>
                                    <Trans>
                                        Potvrdením sa natrvalo odstránite z triedy
                                    </Trans>
                                    &nbsp; "{userGroup.name}". &nbsp;
                                    <Trans>
                                        Odstránením prídete nenávratne o všetky doterajšie testy a výsledky. Stratíte možnosť učiť sa slovíčka z tejto triedy. Chcete sa naozaj odstrániť?
                                    </Trans>
                                </InformationBox>

                                <InformationBox type={'error'} showBell={true}>
                                    <Trans>Operácia je nezvratná!</Trans>
                                </InformationBox>
                                </ConfirmModal>
                        </AuthUserWrapper>
                    </div>
                ):(null)}
            </section>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(WordGroupDetailPage))