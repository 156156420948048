import { setLoadingTestResults } from '../loadings/setLoading';
import config from '../../config';
import { handleErrors } from '../../utils/handleErrors';
import { 
    GET_TEST_RESULTS_USERGROUP_STARTED, 
    GET_TEST_RESULTS_USERGROUP, 
    GET_TEST_RESULTS_USERGROUP_FAILED } from '../../constants/action-types';
import { store } from '../../store';
import { ITestResult } from '../../constants/types';

export function loadTestResultsUserGroup(ug: number) {
    return (dispatch: any) => {

        dispatch(setLoadingTestResults(true))
        dispatch(loadTestResultsUserGroupStarted())

        return fetch(config.api.testResultsUserGroup + '/' + ug, {
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + store.getState().globalStore.token
            }
            
        })
            .then(handleErrors)
            .then(res => res.json())    
            .then(res => {
                
            if(typeof res.data !== 'undefined') {
                let results: ITestResult[] = []

                res.data.forEach((d:any) => {
                    results = [
                        ...results,
                        ...d.test_results
                    ]
                })

                dispatch(loadTestResultsUserGroupSuccess({userGroupId: ug, results: results}))
            }

                dispatch(setLoadingTestResults(false))
            })
            .catch((error: any)  => {
                dispatch(loadTestResultsUserGroupFailed(error))
                dispatch(setLoadingTestResults(false))
            });
    };
}

export const loadTestResultsUserGroupStarted = () => ({
    type: GET_TEST_RESULTS_USERGROUP_STARTED
});

export const loadTestResultsUserGroupSuccess = (data:any) => ({
    type: GET_TEST_RESULTS_USERGROUP,
    payload: data
});

export const loadTestResultsUserGroupFailed = (error: any) => ({
    type: GET_TEST_RESULTS_USERGROUP_FAILED,
    payload: { error }
});

export function loadTestResultsUserGroupAll() {
    return (dispatch: any) => {

        dispatch(setLoadingTestResults(true))
        dispatch(loadTestResultsUserGroupStarted())

        return fetch(config.api.testResultsUserGroup, {
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + store.getState().globalStore.token
            }
            
        })
            .then(handleErrors)
            .then(res => res.json())    
            .then(res => {
                
            if(typeof res.data !== 'undefined') {
                res.data.forEach((r:any) => {
                    dispatch(loadTestResultsUserGroupSuccess({userGroupId: r.id, results: r.results}))
                })
            }

                dispatch(setLoadingTestResults(false))
            })
            .catch((error: any)  => {
                dispatch(loadTestResultsUserGroupFailed(error))
                dispatch(setLoadingTestResults(false))
            });
    };
}