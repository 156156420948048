import React, {Component} from 'react';
import { connect } from 'react-redux';
import { IApplicationStore } from "../../../constants/store-interfaces";
import { ITabs, ITabsAction } from '../../../constants/types';

interface IStateProps {

}

const mapStateToProps = (state: IApplicationStore) => ({
})

interface IDispatchProps {

}

const mapDispatchToProps = (dispatch: any) => ({
})

interface IProps extends IStateProps, IDispatchProps{
    tabs: ITabs[],
    footerActions: ITabsAction[]
}

interface IState {
    currentTabId: number,
    openedTabs: number[]
}

class TabsContainer extends Component<IProps, IState> {
    constructor(props: any) {
        super(props)

        this.state = {
            currentTabId: 0,
            openedTabs: [0]
        }

        if(this.props.tabs.length > 0) {
            if(typeof this.props.tabs[0].onOpen !== 'undefined') {
                this.props.tabs[0].onOpen()
            }
            if(typeof this.props.tabs[0].onFirstOpen !== 'undefined') {
                this.props.tabs[0].onFirstOpen()
            }
        }

        this.changeTab = this.changeTab.bind(this)
    }

    changeTab(id: number) {

        if(typeof this.props.tabs[id] !== 'undefined') {
            let tab = this.props.tabs[id]

            if(typeof tab.onOpen !== 'undefined') {
                tab.onOpen()
            }

            if(typeof tab.onFirstOpen !== 'undefined') {
                if(!this.state.openedTabs.includes(id)) {
                    tab.onFirstOpen()
                }
            }
        }

        let openedTabs = this.state.openedTabs

        if(!openedTabs.includes(id)) {
            openedTabs.push(id)
        }

        this.setState({
            ...this.state,
            currentTabId: id,
            openedTabs: openedTabs
        })
    }

    render() {
        const { currentTabId } = this.state
        const { tabs, footerActions } = this.props

        if((tabs.length === 0) || (tabs.length <= currentTabId)) {
            return (
                null
            )
        }

        let currentTab = tabs[currentTabId]

        return (
            <div className="tabs">
                <div className="tabs-content">
                    
                    <section className="tab">
                        
                        <header className="tab-header">
                            <div className="part">
                                <h2>{currentTab.header.titleTab}</h2>
                            </div>
                        </header>
                    
                        <div className="tab-body">
                            
                            {currentTab.content}
                            
                        </div>
                        
                        {(typeof currentTab.footer !== 'undefined')?(
                            <footer className="tab-footer">
                                {currentTab.footer}
                            </footer>
                        ):(null)}
                    </section>
                    
                </div>
                <div className="tabs-nav">
                    <div className="tabs-nav-top">
                        <div className="tabs-nav-list">
                            <ul>
                                {tabs.map((tab: ITabs, index: number) => (
                                    <li className={(index === currentTabId)?'active':''} key={index} onClick={()=>this.changeTab(index)}>
                                        <span className="href" data-tour={(typeof tab.header.tutorialClass !== 'undefined')?tab.header.tutorialClass:''}>
                                            {(tab.header.customIcon === true)?(<>{tab.header.icon}</>):(<i className={"la " + tab.header.icon}/>)}

                                            <div className="tab-name">{tab.header.title}</div>
                                            {(typeof tab.header.description !== 'undefined')?(
                                                <div className="tab-desc">{tab.header.description}</div>
                                            ):(null)}
                                        </span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    {(footerActions.length > 0)?(
                        <div className="tabs-nav-bottom">
                            <div className="tabs-nav-list">
                                <ul>
                                    {footerActions.map((action: ITabsAction, index: number) => (
                                        <li key={index}>
                                            <span className="href" onClick={() => action.action()}><i className={"la " + action.icon}/>{action.title}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    ):(null)}
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TabsContainer)