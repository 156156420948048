import React, {useState, useEffect} from 'react'
import { IRankingMonth } from '../../../../constants/types';
import ReactCountryFlag from 'react-country-flag';
import { i18n, monthsBG } from '../../../../App';
import { useSelector, useDispatch } from 'react-redux';
import { IApplicationStore } from '../../../../constants/store-interfaces';
import LoadingElement from '../../LoadingElement';
import { Trans } from '@lingui/macro';
import { loadRankingMonths } from '../../../../actions/rankings/loadMonths';

interface IProps {
}

const RankingMonthWidget: React.FC<IProps> = props => {
    const dispatch = useDispatch()

    let [months, setMonths] = useState<string[]>([])

    const rankings = useSelector((state: IApplicationStore) => state.rankingStore.monthRankings)
    const loading = useSelector((state: IApplicationStore) => state.loadingStore.loadingMonthRanking)

    useEffect(() => {
        let month = new Date()

        let month1 = month.getFullYear() + '_' + (month.getMonth()+1)
        let month2 = month.getFullYear() + '_' + (month.getMonth())
        let month3 = month.getFullYear() + '_' + (month.getMonth()-1)
        

        if(month.getMonth() === 0) {
            month2 = month.getFullYear()-1 + '_12'
            month3 = month.getFullYear()-1 + '_11'
        } else if (month.getMonth() === 1) {
            month3 = month.getFullYear()-1 + '_12'
        }

        let months: string[] = [month1, month2, month3]

        setMonths(months)
        dispatch(loadRankingMonths(months))
    }, [dispatch])

    let filteredRankings: IRankingMonth = {}

    Object.keys(rankings).forEach(k => {
        if(months.includes(k)) {
            filteredRankings[k] = rankings[k]
        }
    })

    return (
        <div className="ranking-month">
            <div className="row">
                <div className="row-part">
                    {Object.keys(rankings).length?(
                        <h3><Trans>Najviac bodov za mesiac</Trans></h3>
                    ):(null)}
                </div>
            </div>
            <div className="widget-body n-p-m row">
                <LoadingElement loadingValue={loading}/>

                {Object.keys(filteredRankings).map((r,i) => {
                    let rankingList = filteredRankings[r]

                    let monthName = monthsBG[Number(r.split('_')[1])-1]

                    if(typeof monthName !== 'undefined') {
                        monthName = monthName + ' ' + r.split('_')[0]
                    }

                    return (
                        <div className="row-part" key={i}>
                            <h5>{monthName}</h5>
                            <div className="table _full">
        
                                <div className="table-body">
                                    {rankingList.world.slice(0,3).map((ranking,j) => {

                                        let rateTitle = i18n._('bodov')

                                        if((ranking.ranking > 1) && (ranking.ranking <= 4)) {
                                            rateTitle = i18n._('body')
                                        } else if (ranking.ranking === 1) {
                                            rateTitle = i18n._('bod')
                                        }

                                        let medal = null

                                        if(ranking.rank === 1) {
                                            medal = (<span role="img" aria-label={i18n._('1. miesto')}>🥇</span>)
                                        } else if (ranking.rank === 2) {
                                            medal = (<span role="img" aria-label={i18n._('2. miesto')}>🥈</span>)
                                        } else if (ranking.rank === 3) {
                                            medal = (<span role="img" aria-label={i18n._('3. miesto')}>🥉</span>)
                                        }
                                        return (
                                            <div className={"table-row"} key={j}>
                                                <div className="table-col n-p">
                                                    <div className="part-position">
                                                    
                                                        <div className="position">{(ranking.rank === -1)?'-':ranking.rank}</div>
                                                    
                                                    </div>
                                                </div>
                                                <div className="table-col _full">
                                                    <div className="part-text part-user">
                                                        <div className="part avatar _flag">
                                                            <ReactCountryFlag className="flag" countryCode={ranking.country} svg/>
                                                        </div>
                                                        <div className="part name">
                                                            {medal} {ranking.login}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="table-col">
                                                    <div className="part-points">
                                                    
                                                        {ranking.ranking} <strong>{rateTitle}</strong>
                                                    
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default RankingMonthWidget