import config from '../../config';
import { handleErrors } from '../../utils/handleErrors';
import { CREATE_TEST_STARTED, CREATE_TEST, CREATE_TEST_FAILED } from '../../constants/action-types';
import { store } from '../../store';
import { history } from '../../../index';
import { addFloatingNotification } from '../notifications/floating';
import { i18n } from '../../App';
import { ITestCreate, ETestUgType } from '../../constants/types';
import { setLoadingCreatedTests } from '../loadings/setLoading';
import { setShowCreateTestUGModal } from '../modals/setShowModal';


export function createTest(data:ITestCreate, type: 'ug' | 'self') {
    return (dispatch: any) => {

        dispatch(setLoadingCreatedTests(true))
        dispatch(createTestStarted())

        return fetch(config.api.tests, {
            method: 'POST',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + store.getState().globalStore.token
            },
            body: JSON.stringify(data)
            
        })
            .then(handleErrors)
            .then(res => res.json())
            .then(res => {

                dispatch(createTestSuccess({test: res.data, type: type}))
                dispatch(setLoadingCreatedTests(false))

                if(type === 'ug') {
                    dispatch(setShowCreateTestUGModal(false))

                    if((typeof data.settings.ugType !== 'undefined') && (data.settings.ugType === ETestUgType.homework)) {
                        dispatch(addFloatingNotification({
                            type:"success",
                            text:i18n._('Nová domáca úloha bola úspešne vytvorená.'),
                            showBell: true
                        }))
                    } else {
                        dispatch(addFloatingNotification({
                            type:"success",
                            text:i18n._('Nová písomka bola úspešne pridaná.'),
                            showBell: true
                        }))
                    }
                } else {
                    dispatch(addFloatingNotification({
                        type:"success",
                        text:i18n._('Nové cvičenie bolo úspešne vytvorené.'),
                        showBell: true
                    }))

                    if(data.usergroup_id === null) {
                        history.push({
                            pathname: '/app/trainings/' + res.data.id
                        })
                    } else {
                        if(data.settings.ugType === ETestUgType.homework) {
                            history.push({
                                pathname: '/app/homeworks/' + res.data.id
                            })
                        } else {
                            history.push({
                                pathname: '/app/tests/' + res.data.id
                            })
                        }
                    }
                }

            })
            .catch((error: any)  => {
                dispatch(createTestFailed(error))
                dispatch(setLoadingCreatedTests(false))
            });
    };
}

export const createTestStarted = () => ({
    type: CREATE_TEST_STARTED
});

export const createTestSuccess = (data: any) => ({
    type: CREATE_TEST,
    payload: data
});

export const createTestFailed = (error: any) => ({
    type: CREATE_TEST_FAILED,
    payload: { error }
});